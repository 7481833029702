import React, { useState, useEffect } from 'react';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import {
  Times,
  Save,
  Trash,
  AddressCard,
  AngleDoubleLeft,
  Search,
  Check,
  AngleDoubleRight,
} from '../utils/Fontawesome';
import { removeAcento } from '../utils/Mascara';
const AlergiaCad = (props) => {
  let history = useHistory();

  const [memed_alergia_id, setMemed_alergia_id] = useState(0);
  const [alergia, setAlergia] = useState(0);
  const [itensSelecionados, setItensSelecionados] = useState(
    props.pacienteAlergias ? props.pacienteAlergias : [],
  );

  const [terms, setTerms] = useState('');
  const [nomeModal, setNomeModal] = useState('');
  const [idModal, setIdModal] = useState('');
  const [keyModal, setKeyModal] = useState('');
  const [convenio, setConvenio] = useState([]);

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    window.$('#sidebarCollapse').on('click', function () {
      window.$('#sidebar').toggleClass('active');
      window.$(this).toggleClass('active');
    });
  }, []);
  useEffect(() => {
    if (props.pacienteAlergias.length > 0) {
      setItensSelecionados(props.pacienteAlergias);
    }
  }, [props.pacienteAlergias]);
  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limparConvenioCad')) {
          document.getElementById('btn_limparConvenioCad').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  const save = (e, metodo, status) => {
    e.preventDefault();
    e.stopPropagation();

    let data,
      method = 'put',
      url;

    if (!itensSelecionados) {
      handleInfo('Pesquise e assinale algum princípio ativo.');
      return false;
    }

    if (method === 'put') {
      data = {
        itensSelecionados: itensSelecionados,
        pac_id: props.paciente.value,
      };
      url = 'api/memed/alergias';
    }

    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        //history.push('/prontuario/117465/8088');
        if (document.getElementById('btn_limparConvenioCad')) {
          document.getElementById('btn_limparConvenioCad').click();
        }
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const fecharSegundoModal = () => {
    if (document.getElementById('exampleModalCenter')) {
      document.getElementById('exampleModalCenter').style.display = 'none';
    }

    if (document.getElementsByClassName('modal-backdrop')[2]) {
      var no = document.getElementsByClassName('modal-backdrop')[2];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
  };
  const limpar = () => {
    if (document.getElementsByClassName('modal-backdrop')[1]) {
      var no = document.getElementsByClassName('modal-backdrop')[1];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    if (document.getElementsByClassName('modal-backdrop')[2]) {
      var no = document.getElementsByClassName('modal-backdrop')[2];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
  };
  const getIngredientes = async (terms) => {
    await api
      .get('api/memed/ingredientes?terms=' + terms, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setAlergia(response.data.data);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const selecionarItem = async (itemId, itemName, key) => {
    const arrayItens = itensSelecionados;

    arrayItens.push({ id: itemId, name: itemName });

    let arrayUnicos = arrayItens.filter(function (a) {
      return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
    }, Object.create(null));

    setItensSelecionados(arrayUnicos);

    if (document.getElementById('filtro')) {
      document.getElementById('filtro').click();
    }
  };
  const cancelarItem = async (key) => {
    fecharSegundoModal();
    const arrayItens = itensSelecionados;

    arrayItens.splice(key, 1);

    setItensSelecionados(arrayItens);
    if (document.getElementById('filtro')) {
      document.getElementById('filtro').click();
    }
  };
  const dadosModal = (nome, id, key) => {
    setNomeModal(nome);
    setIdModal(id);
    setKeyModal(key);
  };
  //console.log(props);
  // console.log(props.pacienteAlergias[0]);
  //console.log('itens selecionados ' + itensSelecionados);
  return (
    <>
      <div className="card ">
        <div className="card-header">
          {' '}
          {AddressCard} Cadastro de alergia do paciente {props.paciente.label}
        </div>

        <div id="triangulo-para-baixo"></div>
        <div style={{ padding: '8px' }} className="card-body">
          <form id="form_alergia" onSubmit={(e) => save(e)}>
            <div
              className=" col-md-12 col-12 col-sm-12 mb-3"
              style={{ paddingRight: '0px' }}
            >
              <label htmlFor="filtro">Pesquise pelo princípio ativo:</label>
              <div
                className="input-group mb-3"
                style={{ position: 'relative', right: '50px' }}
                onClick={(e) => getIngredientes(terms)}
              >
                <div className="input-group-prepend">
                  <span
                    style={{
                      display: 'inline',
                      borderRadius: '50px',
                      background: '#a7c4d2 ',
                      color: 'white',
                      position: 'relative',
                      right: '-37px',
                      zIndex: '99',
                    }}
                    className="input-group-text"
                    id="basic-addon1"
                  >
                    {Search}
                  </span>
                </div>
                <input
                  id="filtro"
                  className="form-control"
                  style={{
                    display: 'inline',
                    padding: '7px 29px 7px 52px',
                    borderRadius: '50px 0px 0px 50px',
                    borderBottom: '6px solid #a7c4d2 ',
                  }}
                  type="text"
                  onChange={(e) => setTerms(removeAcento(e.target.value))}
                  value={terms}
                />
              </div>

              <div className="row">
                <p>
                  Assinale o princípio ativo no qual o paciente é alérgico na
                  tabela a esquerda que por consequente seram listados os
                  selecionados na tabela a direita
                </p>
                <br />
                <div className="col-md-6 col-6 col-sm-6">
                  {alergia?.length > 0 && (
                    <div className="table-responsive">
                      <table className="table table-hover table-striped table-light">
                        <thead className="text-white bg-dark">
                          <tr>
                            <th scope="col">Alergia a</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {alergia?.length > 0 &&
                            alergia.map((principioAtivo, i) => {
                              // console.log(principioAtivo);
                              return (
                                <>
                                  <tr key={principioAtivo.id}>
                                    <th
                                      style={{
                                        wordBreak: 'break-all',
                                        minWidth: '57px',
                                      }}
                                    >
                                      {principioAtivo.attributes.name}
                                    </th>
                                    <td>
                                      {' '}
                                      <div>
                                        <span
                                          onClick={(e) =>
                                            selecionarItem(
                                              principioAtivo.id,
                                              principioAtivo.attributes.name,
                                              i,
                                            )
                                          }
                                          id={'selecionar' + i}
                                          style={{
                                            fontSize: '22px',
                                            color: '#469fc9',
                                          }}
                                        >
                                          {AngleDoubleRight}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <div className="col-md-6 col-6 col-sm-6">
                  {itensSelecionados?.length > 0 && (
                    <div className="table-responsive">
                      <table className="table table-hover table-striped table-light">
                        <thead className="text-white bg-dark">
                          <tr>
                            <th style={{ maxWidth: '5ch' }} scope="col">
                              Selecionados
                            </th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {itensSelecionados?.length > 0 &&
                            itensSelecionados.map((principioAtivos, i) => {
                           
                              if (principioAtivos.id) {
                                return (
                                  <>
                                    <tr key={principioAtivos.id}>
                                      <th
                                        style={{
                                          wordBreak: 'break-all',
                                          minWidth: '57px',
                                        }}
                                      >
                                        {principioAtivos.name}
                                      </th>
                                      <td>
                                        {' '}
                                        <div>
                                          <span
                                            onClick={(e) =>
                                              dadosModal(
                                                principioAtivos.name,
                                                principioAtivos.id,
                                                i,
                                              )
                                            }
                                            data-toggle="modal"
                                            data-target="#exampleModalCenter"
                                            id={' cancelar' + i}
                                            style={{
                                              fontSize: '22px',
                                              color: 'red',
                                            }}
                                          >
                                            {AngleDoubleLeft}
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="text-left ">
              <button
                className="btn btn-sm btn_Cadastrar "
                type="submit"
                id="btnCadastrar"
                onClick={(e) => props.definirAlergia(itensSelecionados)}
              >
                {Save} Cadastrar
              </button>
              &nbsp;&nbsp;{' '}
              <button
                className="btn btn-sm btn-secondary "
                onClick={(e) => limpar(e)}
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                id="btn_limparConvenioCad"
              >
                {Times} Fechar
              </button>
            </div>
          </form>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card card-red">
              <div className="card-header">{Trash} Excluir Alergia</div>

              <div className="card-body">
                Tem certeza que deseja excluir a alergia{' '}
                <i>
                  <b className="text-danger">"</b>
                  {nomeModal}
                  <b className="text-danger">"</b>
                </i>{' '}
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-sm btn-secondary "
                  type="button"
                  onClick={(e) => fecharSegundoModal()}
                >
                  {Times}
                  &nbsp;Cancelar
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-sm btn_excluir "
                  onClick={(e) => cancelarItem(keyModal)}
                  type="button"
                >
                  {Trash} Excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AlergiaCad;
