import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { UserContext, UserStorage } from './components/pages/UserContext';
import PrivateRoute from './auth';
// React Boostrap e outros  css Import

//import PrivateRoute from './auth';
// React Boostrap e outros  css Import

import 'bootstrap/dist/css/bootstrap.min.css';
//import 'jquery/dist/jquery.slim.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'popper.js/dist/popper.min.js';

import GlobalStyle from './components/styles/toastify';
// React Router Import
import { Switch, Route, BrowserRouter, Link } from 'react-router-dom';

// React Menu e Footer Import
//import Navbar from "./components/layouts/NavBar";
import Footer from './components/layouts/Footer';
// Import Pages

//  Componentes de login
import LoginAdmin from './components/pages/LoginAdmin.js';
import Login from './components/pages/Login';
import Logout from './components/pages/Logout';

//  Componentes de Cadastros

import FilaEspera from './components/pages/FilaEspera';
import Comunicado from './components/pages/Comunicado';
import Cidade from './components/pages/Cidade';
import Medico from './components/pages/Medico';
import Secretaria from './components/pages/Secretaria';
import Convenio from './components/pages/Convenio';
import Paciente from './components/pages/Paciente';
import Consulta from './components/pages/Consulta';
import Atendimento from './components/pages/Atendimento';
import Prontuario from './components/pages/Prontuario';
import HistoricoConsultas from './components/pages/HistoricoConsultas';
import ImprimirProntuario from './components/pages/ImprimirProntuario';
import Exame from './components/pages/Exame';
import ExameConvenio from './components/pages/ExameConvenio';
import PageConfig from './components/pages/PageConfig';
import AlterarSenha from './components/pages/AlterarSenha';
import RecuperarSenha from './components/pages/RecuperarSenha';
import Atestado from './components/pages/Atestado';
import Premium from './components/pages/Premium';
import Pendente from './components/pages/Pendente';
import Navbar from './components/layouts/NavBar';

import './components/styles/main.css';

const Routes = () => {
  // const global = useContext(UserContext);

  const Layout = ({ children }) => (
    <>
      <Navbar />
      <main className="content">
        <Switch>{children}</Switch>
      </main>

      <Footer />
    </>
  );

  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <BrowserRouter>
        <UserStorage>
          <Switch>
            {/*   Componentes de login   */}
            <Route path="/logout" component={Logout} />
            <Route path="/admin" component={LoginAdmin} />
            <Route path="/login" component={Login} />
            <Route exact path="/" component={Login} />
            <Route path="/recuperarsenha" component={RecuperarSenha} />
            <PrivateRoute path="/pendente" component={Pendente} />
            <Layout>
              {/*   Cadastros   */}

              <PrivateRoute path="/alterarsenha" component={AlterarSenha} />
              <PrivateRoute path="/cidade" component={Cidade} />
              <PrivateRoute path="/medico" component={Medico} />
              <PrivateRoute path="/paciente" component={Paciente} />
              <PrivateRoute
                path="/paciente/:cons_paciente/:med_id"
                component={Paciente}
              />
              <PrivateRoute path="/secretaria" component={Secretaria} />
              <PrivateRoute path="/convenio" component={Convenio} />
              <PrivateRoute path="/exame" component={Exame} />
              <PrivateRoute path="/exameConvenio" component={ExameConvenio} />
              <PrivateRoute path="/consulta" component={Consulta} />
              <PrivateRoute path="/atendimento" component={Atendimento} />
              {/* <PrivateRoute path="/pageconfig" component={PageConfig} />*/}
              <PrivateRoute path="/comunicado" component={Comunicado} />
              <PrivateRoute path="/filaEspera" component={FilaEspera} />

              <PrivateRoute path="/premium" component={Premium} />
              <PrivateRoute
                path="/atestado/:cons_codigo/:cons_paciente"
                component={Atestado}
              />

              <PrivateRoute
                path="/prontuario/:cons_codigo/:cons_paciente"
                component={Prontuario}
              />
              <PrivateRoute
                path="/historico/:cons_paciente"
                component={HistoricoConsultas}
              />

              <PrivateRoute
                path="/imprimirprontuario/:cons_codigo/:cons_paciente"
                component={ImprimirProntuario}
              />

              {/*  <PrivateRoute path="/pontodemidia" component={PontoDeMidia} />*/}
            </Layout>
          </Switch>
        </UserStorage>
      </BrowserRouter>
    </>
  );
};

export default Routes;
