export const Padrao = (
  marginTop,
  marginbottom,
  marginLeft,
  marginRight,
  datatime,
  cons_data,
  paciente,
  medico,
  conselho,
  logo,
  hora_ini,
  hora_fim,
  cid,
  radio_consulta,
  cons_event_text,
  afastado_ini,
  afastado_fim,
) => {
  var radio1 = '⠀⠀';
  var radio2 = '⠀⠀';
  var radio3 = '⠀⠀';
  if (radio_consulta == 1) {
    radio1 = ' X ';
  }
  if (radio_consulta == 2) {
    radio2 = ' X ';
  }
  if (radio_consulta == 3) {
    radio3 = ' X ';
  }
  var dayName = new Array(
    'domingo',
    'segunda',
    'terça',
    'quarta',
    'quinta',
    'sexta',
    'sábado',
  );
  var monName = new Array(
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Agosto',
    'Outubro',
    'Novembro',
    'Dezembro',
  );
  var now = new Date();

  if (logo) {
    logo = `<img src="` + logo + `" alt="logo" style="width:100%">`;
  } else {
    logo = '';
  }
  return (
    `<html><head>
  <style>/* Grid */
  @page 
  {
      size: auto;   /* auto is the initial value */
      margin: 0mm;  /* this affects the margin in the printer settings */
  }
html{
  margin-bottom: ` +
    marginbottom +
    `cm;
  margin-left:  ` +
    marginLeft +
    `cm;
  margin-top: ` +
    marginTop +
    `cm;
  margin-right: ` +
    marginRight +
    `cm;
}
body, h2
{
  margin: 0;
  padding: 0;
  color:#000;
  max-width: 100%;
  max-height:  100%;
}
 
  .grid {
    display: grid;
  }
  .grid-template {
    grid-template:
      'logo nav nav .' 0.5fr
      'sidenav sidenav sidenav .' 0.5fr
      'content content content  .' 3fr    
      'footer  footer   footer footer  ' 0.6fr
      'advert  advert advert  advert' 0.5fr
      / 0.5fr 0.5fr 0.5fr 0.5fr ;
  }
  
  .gap {
    gap: 20px;
  }
  
  .logo {
    grid-area: logo;
      text-align:center;
  }
  .logo img {
    max-width:80px;
      max-height:80px;
 }
  .nav {
    grid-area: nav;
  }
  
  .content {
    grid-area: content;
    text-align:left;
    text-transform: uppercase; 
     font-size: 12px;
     margin-left: 1cm;
  }
   

  .sidenav {
    grid-area: sidenav;
    font-size: 12px;
  }

  
  .advert {
    grid-area: advert;
     align-self: bottom;
         align-self: end;
  }
  
  .footer {
    grid-area: footer;
    align-self: center;

  }
  
  /* Grid Item */
  .item {
    background: #9e9e9e;
    
  
   
  }
  
  .grid {
    width: 100%;
    height:  100%;
   /* border: 1px solid #000;*/
    margin: 0px;
    padding: 0px;
  
  }
  
  h1 {
    text-align: center;
    font-size: 1.25em;
    font-weight: normal;
  }
  </style>


 </head>
  
  <body>
  
  
  
    <section class="grid grid-template gap">
    
 
      <div class="item nav">
        <div style="text-align:center;font-size: 24px;">
          <span>Atestado Médico</span>
          <br />
        </div>
      </div>
    
      <div class="item sidenav">
        <div style="text-align:left;font-size: 14px;">
          <p style="text-align:left;" >
          Atesto que o Sr.(a): <b>  <span style='text-transform: capitalize;'>` +
    paciente +
    `</p></b>
          </p>         
          <p style="text-align:left;margin-left:15px" >esteve em: </p>       
          <p style="text-align:left;margin-left:15px" >` +
    cons_event_text +
    `</p> 
          <p style="text-align:left;margin-left:15px">em: ` +
    cons_data.split('-').reverse().join('/') +
    ` das ` +
    hora_ini +
    ` às ` +
    hora_fim +
    ` horas, devendo</p>
          
             <p>[` +
    radio1 +
    `] - Retornar ao trabalho </p>
             <p> [` +
    radio2 +
    `] - Permanecer em repouso no dia de hoje </p>
             <p>[` +
    radio3 +
    `] - Permanecer afastado no período de: ` +
    afastado_ini +
    ` à ` +
    afastado_fim +
    ` 
             </p>
             <br/>
             <p>CID: ` +
    cid +
    `</p>   
        </div>
      </div>
         <div class="item content">
       
        

      </div>
     
      <div class="item advert">
            <div style="text-align:center;font-size: 10px;    margin-bottom: 10px;border-bottom: 1px solid;
            border-top: 1px solid;
            padding:2px">
   
         
          Este atestado é válido para as finalidades previstas no artigo 86 do RCPS, aprovado pelo DEC 60501 de
          14/03/1967 e será expedido para justificar de 01 a 15 dias de afastamento do trabalho.
      
   
        </div>
     
      </div>
      <div style="border: 0px solid black ;display:flex;flex-direction: row;" class="item footer">
     <div >LEME, ` +
    now.getDate() +
    ` de ` +
    monName[now.getMonth()] +
    ` de ` +
    now.getFullYear() +
    ` </div>
     <div style='width:40px'></div>
      <div style="text-align:center;font-size: 12px;width:220px"><hr>
        <span> Dr.(a)  <span style='text-transform: capitalize;'>` +
    medico +
    `</span></span><br />
        <span>
         ` +
    conselho +
    `
        </span>
      </div>
    </div>
    </section>
  </body>
  
  </html>`
  );
};
