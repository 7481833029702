export const Unimed = (
  marginTop,
  marginbottom,
  marginLeft,
  marginRight,
  datatime,
  cons_data,
  paciente,
  medico,
  conselho,
  logo,
  hora_ini,
  hora_fim,
  cid,
  radio_consulta_unimed,
  radio_periodo,
  radioUnimedAfastamento,
  cons_event_text,
  afastado_ini,
  afastado_fim,
) => {
  var radio1 = '⠀⠀';
  var radio2 = '⠀⠀';
  var radio3 = '⠀⠀';
  var radio4 = '⠀⠀';
  var radio5 = '⠀⠀';

  var radioaAfastado1 = '⠀⠀';
  var radioaAfastado2 = '⠀⠀';
  var radioaAfastado3 = '⠀⠀';
  var radioaAfastado4 = '⠀⠀';
  var radioaAfastado5 = '⠀⠀';
  var radioaAfastado6 = '⠀⠀';

  var radioaperiodo1 = '⠀⠀';
  var radioaperiodo2 = '⠀⠀';
  var radioaperiodo3 = '⠀⠀';

  switch (radio_consulta_unimed) {
    case '1':
      radio1 = ' X ';
      break;
    case '2':
      radio2 = ' X ';
      break;
    case '3':
      radio3 = ' X ';
      break;
    case '4':
      radio4 = ' X ';
      break;
    case '5':
      radio5 = ' X ';
      break;
  }

  switch (radio_periodo) {
    case 'm':
      radioaperiodo1 = ' X ';
      break;
    case 't':
      radioaperiodo2 = ' X ';
      break;
    case 'n':
      radioaperiodo3 = ' X ';
      break;
  }

  switch (radioUnimedAfastamento) {
    case '1':
      radioaAfastado1 = ' X ';
      break;
    case '2':
      radioaAfastado2 = ' X ';
      break;
    case '3':
      radioaAfastado3 = ' X ';
      break;
    case '4':
      radioaAfastado4 = ' X ';
      break;
    case '5':
      radioaAfastado5 = ' X ';
      break;
    case '6':
      radioaAfastado6 = ' X ';
      break;
  }

  var dayName = new Array(
    'domingo',
    'segunda',
    'terça',
    'quarta',
    'quinta',
    'sexta',
    'sábado',
  );
  var monName = new Array(
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Agosto',
    'Outubro',
    'Novembro',
    'Dezembro',
  );
  var now = new Date();

  if (logo) {
    logo = `<img src="` + logo + `" alt="logo" style="width:100%">`;
  } else {
    logo = '';
  }
  return (
    `<html><head>
  <style>/* Grid */
  @page 
  {
      size: auto;   /* auto is the initial value */
      margin: 0mm;  /* this affects the margin in the printer settings */
  }
html{
  margin-bottom: ` +
    marginbottom +
    `cm;
  margin-left:  ` +
    marginLeft +
    `cm;
  margin-top: ` +
    marginTop +
    `cm;
  margin-right: ` +
    marginRight +
    `cm;
}
body, h2
{
  margin: 0;
  padding: 0;
  color:#000;
  max-width: 100%;
  max-height:  100%;
}
 
  .grid {
    display: grid;
  }
  .grid-template {
    grid-template:
      'logo nav nav .' 0.5fr
      'sidenav sidenav sidenav .' 0.5fr
      'content content content  .' 3fr    
      'footer  footer   footer footer  ' 0.6fr
      'advert  advert advert  advert' 0.5fr
      / 0.5fr 0.5fr 0.5fr 0.5fr ;
  }
  
  .gap {
    gap: 20px;
  }
  
  .logo {
    grid-area: logo;
      text-align:center;
  }
  .logo img {
    max-width:80px;
      max-height:80px;
 }
  .nav {
    grid-area: nav;
  }
  
  .content {
    grid-area: content;
    text-align:left;
    /*text-transform: uppercase; */
     font-size: 12px;
     margin-left: 1cm;
  }
   

  .sidenav {
    grid-area: sidenav;
    font-size: 12px;
  }

  
  .advert {
    grid-area: advert;
     align-self: bottom;
         align-self: end;
  }
  
  .footer {
    grid-area: footer;
    align-self: center;

  }
  
  /* Grid Item */
  .item {
    background: #9e9e9e;
    
  
   
  }
  
  .grid {
    width: 100%;
    height:  100%;
   /* border: 1px solid #000;*/
    margin: 0px;
    padding: 0px;
  
  }
  
  h1 {
    text-align: center;
    font-size: 1.25em;
    font-weight: normal;
  }
  </style>


 </head>
  
  <body>
  
  
  
    <section class="grid grid-template gap">
    
 
      <div class="item nav">
        <div style="text-align:center;font-size: 24px;">
          <span style='border-bottom:1px solid black'><b>Atestado Médico</b></span>
          <br />
        </div>
      </div>
    
      <div class="item sidenav">
        <div style="text-align:left;font-size: 14px;">
          <span style="text-align:left;" >
          O(a) Sr.(a) : <b>  <span style='text-transform: capitalize;'>` +
    paciente +
    `</span></b>
          </span>         
          <p style="text-align:left;" >COMPARECEU A ESTA CLÍNICA:⠀⠀⠀⠀⠀⠀⠀⠀CHEGOU ÁS: ` +
    hora_ini +
    `</p>       
          <p style="text-align:left;" >⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀SAIU ÁS:⠀⠀⠀` +
    hora_fim +
    `</p> 
    <br/><br/> <br/> <br/> 
          <span style="text-align:left;">PARA</span><br/><br/> 
          
    <span>(` +
    radio1 +
    `) CONSULTA </span><br/>
    <span>(` +
    radio2 +
    `) FISIOTERAPIA </span><br/>
    <span>(` +
    radio3 +
    `) ACOMPANHAR FAMILIAR </span><br/>
    <span>(` +
    radio4 +
    `) RETORNO, ENTREGAR EXAMES ETC. </span><br/>
    <span>(` +
    radio5 +
    `) REALIZAR EXAMES COMPLEMENTARES </span>
    <br/><br/>
    <span>(` +
    radioaAfastado1 +
    `) DEVENDO RETORNAR AO TRABALHO DO DIA DE HOJE </span><br/>
    <span>(` +
    radioaAfastado2 +
    `) DEVERÁ SER DISPENSADO DO TRABALHO NO DIA DE HOJE </span><br/>
    <span>(` +
    radioaAfastado3 +
    `) DEVERÁ SER DISPENSADO NO PERÍODO: <br/> ⠀⠀⠀⠀(` +
    radioaperiodo1 +
    ` ) MANHÃ (` +
    radioaperiodo2 +
    ` ) TARDE (` +
    radioaperiodo3 +
    ` ) NOITE
    </span><br/>
    <span>(` +
    radioaAfastado4 +
    `) DEVERÁ SER DISPENSADO NO PERÍODO DE ` +
    afastado_ini +
    `  À  ` +
    afastado_fim +
    `</span><br/>
    <span>(` +
    radioaAfastado5 +
    `) DEVERÁ SER DISPENSADO POR PERÍODO INDETERMINADO </span><br/>
    <span>(` +
    radioaAfastado6 +
    `) É ACIDENTE DE TRABALHO, DEVERÁ SER ENCAMINHADO AO SEGURO </span><br/><br/><br/>
    <span>CID:` +
    cid +
    `</span>  <br/> 
    </div>
      </div>
         <div style="text-align:left;display:flex;flex-direction: row;" class="item content">
       
    
         
      <div style="align-content: end;text-align:center;font-size: 12px;width:220px">
      </div>
     <br/><br/> <br/><br/><br/><br/>⠀⠀⠀Data : ` +
    cons_data.split('-').reverse().join('/') +
    `
   
     </div>

      </div>
     
      <div class="item advert">
      <div style="border-top:1px solid black;text-align:center;font-size: 12px;width:220px">
      <span> Dr.(a)  <span style='text-transform: capitalize;'>` +
    medico +
    `</span></span><br />
      <span>
       ` +
    conselho +
    `
      </span>
    </div>
     
      </div>
      <div style="text-align:left;display:flex;flex-direction: row;" class="item footer">
  
      <div style="text-align:center;font-size: 12px;width:220px">
     </div>
      <div style="border-top:1px solid black;text-align:center;font-size: 12px;width:220px">
       Assinatura do Paciente
   
    </div>
   
    </div>
    </section>
   
  </body>
  
  </html>`
  );
};
