export const Prontuario = (
  marginTop,
  marginbottom,
  marginLeft,
  marginRight,
  datatime,
  paciente,
  medico,
  conselho,
  endereco,
  telefone,
  cep,
  texto,
  logo,
) => {
  if (logo) {
    logo = `<img src="` + logo + `" alt="logo" style="width:100%">`;
  } else {
    logo = '';
  }
  return (
    `<html><head>
  <style>/* Grid */
  @page 
  {
      size: auto;   /* auto is the initial value */
      margin: 0mm;  /* this affects the margin in the printer settings */
  }
html{
  margin-bottom: ` +
    marginbottom +
    `cm;
  margin-left:  ` +
    marginLeft +
    `cm;
  margin-top: ` +
    marginTop +
    `cm;
  margin-right: ` +
    marginRight +
    `cm;
}
body, h2
{
  margin: 0;
  padding: 0;
  color:#000;
  max-width: 100%;
  max-height:  100%;
}
 
  .grid {
    display: grid;
  }
  .grid-template {
    grid-template:
      'logo nav nav .' 0.5fr
      'sidenav sidenav . .' 0.5fr
      'content content content  .' 3fr    
      '.  .   footer footer  ' 0.6fr
      '.  advert advert  .' 0.5fr
      / 0.5fr 0.5fr 0.5fr 0.5fr ;
  }
  
  .gap {
    gap: 20px;
  }
  
  .logo {
    grid-area: logo;
      text-align:center;
  }
  .logo img {
    max-width:80px;
      max-height:80px;
 }
  .nav {
    grid-area: nav;
  }
  
  .content {
    grid-area: content;
    text-align:left;
    text-transform: uppercase; 
     font-size: 12px;
     margin-left: 1cm;
  }
   

  .sidenav {
    grid-area: sidenav;
    font-size: 12px;
  }

  
  .advert {
    grid-area: advert;
     align-self: bottom;
         align-self: end;
  }
  
  .footer {
    grid-area: footer;
    align-self: center;

  }
  
  /* Grid Item */
  .item {
    background: #9e9e9e;
    
  
   
  }
  
  .grid {
    width: 100%;
    height:  100%;
   /* border: 1px solid #000;*/
    margin: 0px;
    padding: 0px;
  
  }
  
  h1 {
    text-align: center;
    font-size: 1.25em;
    font-weight: normal;
  }
  </style>


 </head>
  
  <body>
  
  
  
    <section class="grid grid-template gap">
    
      <div class="item logo">` +
    logo +
    `</div>
      <div class="item nav">
        <div style="text-align:center;font-size: 24px;">
          <span>Prontuário Médico</span>
          <br />
        </div>
      </div>
    
      <div class="item sidenav">
        <div style="text-align:left;font-size: 14px;">
          <span style="text-align:left;" >
          Paciente: <b>  <span style='text-transform: capitalize;'>` +
    paciente +
    `</span></b>
          </span>
          <br />        
          <br />
          <span>Data:` +
    datatime +
    `</span>
          <br />
             <span>Procedimento:</span>
        </div>
      </div>
         <div class="item content">
       
         ` +
    texto +
    `

      </div>
      <div class="item advert">
            <div style="text-align:center;font-size: 10px;text-transform: capitalize;">
        
          <span>
           ` +
    endereco +
    `
          </span>
          <br />
          <span>Cep ` +
    cep +
    ` Telefone ` +
    telefone +
    `</span>
          <br />
        </div>
      </div>
      <div class="item footer">
        <div style="text-align:center;font-size: 12px;"><hr>
          <span> Dr.(a)  <span style='text-transform: capitalize;'>` +
    medico +
    `</span></span><br />
          <span>
            <b>` +
    conselho +
    `</b>
          </span>
        </div>
      </div>
    </section>
  </body>
  
  </html>`
  );
};
