import React, { useState, useEffect } from 'react';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { TablePagination } from '@material-ui/core';
import { sqlLIKE, removeAcento, removeEspacos } from '../utils/Mascara';
import { useHistory } from 'react-router-dom';

import {
  Times,
  Search,
  Save,
  Edit,
  Trash,
  User,
  Eye,
  AngleDoubleLeft,
  UserNurse,
} from '../utils/Fontawesome';

const Secretaria = () => {
  let history = useHistory();

  const [secretaria_id, setSecretaria_id] = useState('');
  const [secretaria_email, setSecretaria_email] = useState('');
  const [secretaria_senha, setSecretaria_senha] = useState('');
  const [secretaria_repetirSenha, setSecretaria_repetirSenha] = useState('');
  const [secretaria_status, setSecretaria_status] = useState('');
  const [ms_id, setMs_id] = useState('');

  const [secretarias, setSecretarias] = useState([]);
  const [nomeModal, setNomeModal] = useState('');
  const [idModal, setIdModal] = useState('');
  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [secretariasPag, setSecretariasPag] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
       "rowsPerPage:" +
         rowsPerPage +
         " newPage " +
         newPage +
         " " +
         inicio +
         " ---- " +
         fim
     );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setSecretariasPag(cidadePag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setSecretariasPag(cidadePag);
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    window.$('#sidebarCollapse').on('click', function () {
      window.$('#sidebar').toggleClass('active');
      window.$(this).toggleClass('active');
    });
    getSecretaria();
  }, []);

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  const getSecretaria = () => {
    api
      .get('api/secretarias?medico_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setSecretarias(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        if (fim > response.data.length) {
          fim = response.data.length;
        }

        const usuarioPag = [];
        for (let i = inicio; i < fim; i++) {
          usuarioPag.push(response.data[i]);
        }
        setSecretariasPag(usuarioPag);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const editar = (item) => {
    document.getElementById('secretaria_email').focus();
    setSecretaria_email(item.secretaria_email);
    // setSecretaria_senha(item.secretaria_senha);
    setSecretaria_id(item.secretaria_id);
    setSecretaria_status(item.ms_status);

    if (item.ms_status) {
      document.getElementById('secretaria_status').checked = true;
    } else {
      document.getElementById('secretaria_status').checked = false;
    }
    setMs_id(item.ms_id);
  };

  const dadosModal = (nome, id) => {
    setNomeModal(nome);
    setIdModal(id);
  };

  const associar = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let data = {
      secretaria_email: secretaria_email,
      med_id: sessionStorage.getItem('med_id'),
    };

    api({
      method: 'PUT',
      url: 'api/secretarias/associar',
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        getSecretaria();
      })
      .catch((error) => {
        //  catchResponse();
        if (error?.response?.data?.error?.description) {
          handleError(error.response.data.error.description);
        } else {
          catchResponse(error.response.status);
        }
      });
  };
  const save = (e, metodo) => {
    e.preventDefault();
    e.stopPropagation();
    let data, method, url;

    if (metodo) {
      method = 'delete';
    } else {
      if (secretaria_senha !== secretaria_repetirSenha) {
        handleInfo('A senha repetida não confere com a senha');
        return false;
      }
      /* let existeCidade = secretarias.findIndex((val) => val.secretaria_email == secretaria_email);
      if (existeCidade !== -1) {
        handleInfo("Região já existente");
      
        //  alert(existeCidade);
        return false;
      }*/
      if (!secretaria_id) {
        method = 'post';
        if (!setSecretaria_email) {
          handleInfo('Preencha o campo senha.');
          return false;
        }
      } else {
        method = 'put';
      }
    }

    if (method === 'put') {
      data = {
        secretaria_id: secretaria_id,
        secretaria_email: secretaria_email,
        secretaria_status: parseInt(secretaria_status),
        secretaria_senha: secretaria_senha,
        ms_id: parseInt(ms_id),
      };
      url = 'api/secretarias/' + secretaria_id;
    } else if (method === 'post') {
      data = {
        secretaria_email: secretaria_email,
        secretaria_status: parseInt(secretaria_status),
        secretaria_senha: secretaria_senha,
        med_id: parseInt(sessionStorage.getItem('med_id')),
      };
      console.log(data);
      //  return false;
      url = 'api/secretarias';
    } else if (method === 'delete') {
      url = 'api/secretarias/' + idModal;
      data = {
        id: idModal,
      };
      setNomeModal('');
      setIdModal('');
    }

    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        getSecretaria();
      })
      .catch((error) => {
        //  catchResponse();
        if (error?.response?.data?.error?.description) {
          handleError(error.response.data.error.description);
          if (
            error?.response?.data?.error?.description ==
            'O email ja esta em uso no BlueDoctor'
          ) {
            document.getElementById('btnAddSecretaria').click();
          }
        } else {
          catchResponse(error.response.status);
        }
      });
  };

  const limpar = () => {
    setSecretaria_repetirSenha('');
    setSecretaria_id('');
    setSecretaria_email('');
    setSecretaria_senha('');
    setSecretaria_status('');
    setMs_id('');
    setNomeModal('');
    setIdModal('');
    if (document.getElementById('secretaria_status')) {
      document.getElementById('secretaria_status').checked = false;
    }
  };

  const buscar = async () => {
    let filtrado;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = secretarias;
      } else {
        filtrado = arrayFiltrado.filter(function (obj) {
          return sqlLIKE(obj.secretaria_email, '%' + busca + '%');
          // return ilikeToRegExp('%' + busca + '%').test(obj.secretaria_email);
        });
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      if (!filtrado.length) {
        filtrado = secretarias;
        handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const cidadePag = [];
      for (let i = inicio - 1; i < fim; i++) {
        cidadePag.push(filtrado[i]);
      }

      await setSecretariasPag(cidadePag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo('Nenhum registro encontrado');
    }
  };
  const checkboxAtivo = () => {
    if (secretaria_status === 1) {
      document.getElementById('secretaria_status').checked = false;
      setSecretaria_status(0);
    } else {
      setSecretaria_status(1);
      document.getElementById('secretaria_status').checked = true;
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div
          className="modal fade bd-example-modal-md"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="card ">
                <div className="card-header">
                  {' '}
                  {User} Controle de secretárias {AngleDoubleLeft}
                </div>

                <div className="card-body">
                  <form id="form_secretaria" onSubmit={(e) => save(e)}>
                    <div className="form-row mb-3">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="secretaria_email">email:</label>
                        {secretaria_id ? (
                          <input
                            type="text"
                            className="form-control"
                            id="secretaria_email"
                            readOnly
                            placeholder="Digite o nome do email"
                            onChange={(e) =>
                              setSecretaria_email(
                                removeEspacos(e.target.value.toLowerCase()),
                              )
                            }
                            value={secretaria_email}
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            id="secretaria_email"
                            placeholder="Digite o nome do email"
                            onChange={(e) =>
                              setSecretaria_email(
                                removeEspacos(e.target.value.toLowerCase()),
                              )
                            }
                            value={secretaria_email}
                          />
                        )}
                      </div>

                      <div class=" ml-2  col-md-12 col-12 mb-3">
                        <label
                          htmlFor="secretaria_senha"
                          style={{ width: '100%', color: '#7c7f82' }}
                        >
                          Senha:
                        </label>
                        <div
                          class="input-group mb-3"
                          style={{
                            position: 'relative',
                            right: '50px',
                          }}
                        >
                          <div
                            class="input-group-prepend"
                            onClick={(e) =>
                              (document.getElementById(
                                'secretaria_senha',
                              ).type =
                                document.getElementById('secretaria_senha')
                                  .type == 'text'
                                  ? 'password'
                                  : 'text')
                            }
                          >
                            {' '}
                            <div class="wrapper-tooltip">
                              <span
                                style={{
                                  display: 'inline',
                                  borderRadius: '50px',
                                  background: '#a7c4d2 ',
                                  color: 'white',
                                  position: 'relative',
                                  right: '-37px',
                                  zIndex: '99',
                                }}
                                class="input-group-text"
                                id="basic-addon1"
                              >
                                {Eye}
                              </span>
                              <div
                                className="tooltip"
                                style={{
                                  left: '45px',
                                  width: '280px',
                                }}
                              >
                                Mínimo de 6 e máximo de 12 caracteres, pelo
                                menos uma letra maiúscula, uma letra minúscula,
                                um número e um caractere especial
                              </div>
                            </div>{' '}
                          </div>
                          <input
                            type="password"
                            class="form-control"
                            id="secretaria_senha"
                            maxLength="12"
                            minLength="6"
                            placeholder="Digite a nova senha"
                            onChange={(e) =>
                              setSecretaria_senha(removeEspacos(e.target.value))
                            }
                            value={secretaria_senha}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                            style={{
                              position: 'absolute',
                              minWidth: '100%',
                              marginLeft: '39px',
                              padding: '7px 29px 7px 52px',
                              borderRadius: '50px 0px 0px 50px',
                              borderBottom: '6px solid #a7c4d2 ',
                            }}
                          />
                        </div>
                      </div>
                      <div class="ml-2 col-md-12 col-12 mb-3">
                        <label
                          htmlFor="secretaria_repetirSenha"
                          style={{ width: '100%', color: '#7c7f82' }}
                        >
                          Repetir Senha:
                        </label>
                        <div
                          class="input-group mb-3"
                          style={{
                            position: 'relative',
                            right: '50px',
                          }}
                        >
                          <div
                            class="input-group-prepend"
                            onClick={(e) =>
                              (document.getElementById(
                                'secretaria_repetirSenha',
                              ).type =
                                document.getElementById(
                                  'secretaria_repetirSenha',
                                ).type == 'text'
                                  ? 'password'
                                  : 'text')
                            }
                          >
                            {' '}
                            <div class="wrapper-tooltip">
                              <span
                                style={{
                                  display: 'inline',
                                  borderRadius: '50px',
                                  background: '#a7c4d2 ',
                                  color: 'white',
                                  position: 'relative',
                                  right: '-37px',
                                  zIndex: '99',
                                }}
                                class="input-group-text"
                                id="basic-addon1"
                              >
                                {Eye}
                              </span>
                              <div
                                className="tooltip"
                                style={{
                                  left: '45px',
                                  width: '280px',
                                }}
                              >
                                Mínimo de 6 e máximo de 12 caracteres, pelo
                                menos uma letra maiúscula, uma letra minúscula,
                                um número e um caractere especial
                              </div>
                            </div>{' '}
                          </div>
                          <input
                            type="password"
                            class="form-control"
                            id="secretaria_repetirSenha"
                            maxLength="12"
                            minLength="6"
                            placeholder="Repita a senha "
                            onChange={(e) =>
                              setSecretaria_repetirSenha(
                                removeEspacos(e.target.value),
                              )
                            }
                            value={secretaria_repetirSenha}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                            style={{
                              position: 'absolute',
                              minWidth: '100%',
                              marginLeft: '39px',
                              padding: '7px 29px 7px 52px',
                              borderRadius: '50px 0px 0px 50px',
                              borderBottom: '6px solid #a7c4d2 ',
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 col-2 ml-3 mb-3">
                        <label htmlFor="med_status">
                          {secretaria_status === 1 ? (
                            <span className="text-secondary">Status</span>
                          ) : (
                            <span className="text-secondary">Status</span>
                          )}
                        </label>

                        <div className="material-switch  mt-2  pull-right">
                          <input
                            onClick={(e) => checkboxAtivo()}
                            id="secretaria_status"
                            name="ativo"
                            type="checkbox"
                          />
                          <label
                            htmlFor="secretaria_status"
                            className="badge-primary"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="text-left ">
                      {secretaria_id ? (
                        <button
                          className="btn btn-sm btn_Cadastrar "
                          type="submit"
                          id="btnAlterar"
                        >
                          {Edit} Alterar
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn_Cadastrar"
                          type="submit"
                          id="btnCadastrar"
                        >
                          {Save} Cadastrar
                        </button>
                      )}{' '}
                      &nbsp;&nbsp;{' '}
                      <button
                        className="btn btn-sm btn-secondary "
                        onClick={(e) => limpar(e)}
                        type="button"
                        id="btn_limpar"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        {Times} Fechar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            {' '}
            {User} Lista de Secretárias {AngleDoubleLeft}
          </div>

          <div id="triangulo-para-baixo"></div>
          <div className="card-body ">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      <div
                        className="col-md-10 col-12 col-sm-12"
                        style={{ paddingRight: '0px' }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <div
                          class="input-group mb-3"
                          style={{ position: 'relative', right: '50px' }}
                          onClick={(e) => buscar()}
                        >
                          <div class="input-group-prepend">
                            <span
                              style={{
                                display: 'inline',
                                borderRadius: '50px',
                                background: '#a7c4d2 ',
                                color: 'white',
                                position: 'relative',
                                right: '-37px',
                                zIndex: '99',
                              }}
                              class="input-group-text"
                              id="basic-addon1"
                            >
                              {Search}
                            </span>
                          </div>
                          <input
                            id="filtro"
                            className="form-control"
                            style={{
                              display: 'inline',
                              padding: '7px 29px 7px 52px',
                              borderRadius: '50px 0px 0px 50px',
                              borderBottom: '6px solid #a7c4d2 ',
                            }}
                            type="text"
                            placeholder=""
                            onChange={(e) =>
                              setBusca(removeAcento(e.target.value))
                            }
                            value={busca}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <label htmlFor="btn_cadastar">&nbsp;&nbsp;&nbsp;</label>
                    <button
                      type="button"
                      className="btn  btn_Cadastrar btn-block "
                      id="btn_cadastar"
                      onClick={(e) => limpar(e)}
                      data-toggle="modal"
                      data-target=".bd-example-modal-md"
                    >
                      {Save} Cadastrar
                    </button>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </form>

            <div className="table-responsive">
              <table className="table table-hover table-striped table-light">
                <thead className="text-white bg-dark">
                  <tr>
                    <th style={{ width: '85%' }} scope="col">
                      Email
                    </th>
                    <th style={{ width: '15%' }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {secretariasPag.length > 0
                    ? secretariasPag.map(function (item, i) {
                        return (
                          <>
                            <tr key={item.secretaria_id}>
                              <th>{item.secretaria_email}</th>
                              <td>
                                <img
                                  src="alterar.png"
                                  onClick={(e) => editar(item)}
                                  className="btn-acao mb-2 mr-2"
                                  data-toggle="modal"
                                  data-target=".bd-example-modal-md"
                                />
                                <img
                                  src="excluir.png"
                                  className="btn-acao mb-2 mr-2"
                                  data-toggle="modal"
                                  data-target="#exampleModalCenter"
                                  onClick={(e) =>
                                    dadosModal(
                                      item.secretaria_email,
                                      item.ms_id,
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ''}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Registros por página'}
              />
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="card card-red">
                <div className="card-header"> {Trash} Excluir Secretaria</div>

                <div className="card-body">
                  Tem certeza que deseja excluir a secretaria{' '}
                  <i>
                    <b className="text-danger">"</b>
                    {nomeModal}
                    <b className="text-danger">"</b>
                  </i>{' '}
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-sm btn-secondary "
                    type="button"
                    data-dismiss="modal"
                  >
                    {Times}
                    &nbsp;Cancelar
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-sm btn_excluir"
                    onClick={(e) => save(e, 'delete', 0)}
                    type="button"
                    data-dismiss="modal"
                  >
                    {Trash} Excluir
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="AddSecretaria"
          tabIndex="1"
          role="dialog"
          aria-labelledby="AddSecretariaTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="card card-success">
                <div className="card-header">
                  {' '}
                  {UserNurse} Adicionar secretaria
                </div>

                <div className="card-body">
                  Tem certeza que deseja vincular o email da secretária com
                  email{' '}
                  <i>
                    <b className="text-danger">"</b>
                    {secretaria_email}
                    <b className="text-danger">"</b>
                  </i>{' '}
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-sm btn-secondary "
                    type="button"
                    data-dismiss="modal"
                  >
                    {Times}
                    &nbsp;Cancelar
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-sm btn_Cadastrar"
                    onClick={(e) => associar(e)}
                    type="button"
                    data-dismiss="modal"
                  >
                    {Save} SIM
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        id="btnAddSecretaria"
        type="button"
        hidden
        data-toggle="modal"
        data-target="#AddSecretaria"
      ></button>
    </>
  );
};
export default Secretaria;
