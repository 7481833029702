import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";
import * as serviceWorker from "./serviceWorker";
//import { registerServiceWorker } from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <Router>
    <Routes />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//registerServiceWorker();
serviceWorker.unregister();
