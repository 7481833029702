import React, { createContext, useState } from 'react';
import { api } from '../utils/WebApiConfig';
import { handleError } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
export const UserContext = createContext();

export const UserStorage = ({ children }) => {
  const [user, setUser] = useState('');
  let history = useHistory();
  const logar = async (username, password) => {
    if (!username && !password) {
      handleError('Preencha todos os campos');
    }

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    await api({
      method: 'POST',
      url: 'api/tokens',
      data: { username: username, password: password },
    })
      .then((response) => {
        sessionStorage.setItem('med_id', response.data.med_id);
        sessionStorage.setItem('med_email', response.data.med_email);
        sessionStorage.setItem('med_nome', response.data.med_nome);
        sessionStorage.setItem('med_status', response.data.med_status);
        sessionStorage.setItem(
          'secretaria_email',
          response.data.secretaria_email,
        );
        sessionStorage.setItem('secretaria_id', response.data.secretaria_id);
        sessionStorage.setItem('cargo', response.data.cargo);
        //  console.log(response.data);
        setUser(response.data);
        history.push('/medico');
      })
      .catch((error) => {
        //  catchResponse();
        //  catchResponse(error.response.status);
        //  console.log(error);
      });
  };
  /* 
        await fetch("http://localhost:8080/api/tokens", {
          method: "POST",
          body: JSON.stringify({username:username,
            password:password}),
          headers: {
            "Content-Type": "application/json",
            
          },
        }).then((response) => {
          console.log(response);
       
          setUser(response);
        }).catch((error) => {
          console.log('eerro '+error);
        });
      }*/

  return (
    <UserContext.Provider value={{ logar, user }}>
      {children}
    </UserContext.Provider>
  );
};

/*

Date.prototype.addMinutos = function(minutos){
    this.setMinutes(this.getMinutes() + minutos)
};

Date.prototype.addHoras = function(horas){
    this.setHours(this.getHours() + horas)
};
function adicionaZero(numero){
    if (numero <= 9) 
        return "0" + numero;
    else
        return numero; 
}

function adicionaZeroMinutes(numero){
    if (numero === 0) 
        return "0" + numero;
    else
        return numero; 
}


const arrayData = [];
var datas = "2021-01-19 10:30";
var datafim = "2021-01-19 17:30";
var dataAtual = '';
  arrayData.unshift({value:datas.substring(11, 16),label:datas.substring(11, 16)});
  for (let i = 0; i < 100; i++) {
    if(datas <= datafim){
          dataAtual = new Date(datas); //29/01/2020
   dataAtual.addMinutos(30);
datas = (dataAtual.getFullYear()  + "-" +  (adicionaZero(dataAtual.getMonth()+1).toString()) + "-" +adicionaZero(dataAtual.getDate().toString()) +' '+dataAtual.getHours()+':'+adicionaZeroMinutes(dataAtual.getMinutes()));
          arrayData.push({value:datas.substring(11, 16),label:datas.substring(11, 16)} );
    }else{
      arrayData.pop();
     break;
    }
   
  }

let array2 = [{"value":"12:00","label":"12:00"},{"value":"12:30","label":"12:30"},{"value":"13:00","label":"13:00"},{"value":"13:30","label":"13:30"},{"value":"14:00","label":"14:00"},{"value":"15:30","label":"15:30"},{"value":"16:30","label":"16:30"},{"value":"17:00","label":"17:00"},{"value":"17:30","label":"17:30"}];


const arr3 = arrayData.filter( x => { 
  return JSON.stringify(array2).indexOf(JSON.stringify(x)) < 0;
});
console.log(JSON.stringify(arr3));



*/
