import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Times,
  Save,
  Edit,
  HeadSideMask,
  AngleDoubleLeft,
  Plus,
  Minus,
} from '../utils/Fontawesome';
import axios from 'axios';

import {
  mask_tel_celular_area,
  mask_CEP,
  mask_CPF,
  validar_CPF,
  removeAcento,
  mask_Numeros,
  capitalize,
} from '../utils/Mascara';

import { UserContext } from './UserContext';

const UFtoInt = (uf) => {
  switch (uf) {
    case 'TO':
      uf = 1;
      break;
    case 'SP':
      uf = 2;
      break;
    case 'SE':
      uf = 3;
      break;
    case 'SC':
      uf = 4;
      break;
    case 'RS':
      uf = 5;
      break;
    case 'RR':
      uf = 6;
      break;
    case 'RO':
      uf = 7;
      break;
    case 'RN':
      uf = 8;
      break;
    case 'RJ':
      uf = 9;
      break;
    case 'PR':
      uf = 10;
      break;
    case 'PI':
      uf = 11;
      break;
    case 'PE':
      uf = 12;
      break;
    case 'PB':
      uf = 13;
      break;
    case 'PA':
      uf = 14;
      break;
    case 'MT':
      uf = 15;
      break;
    case 'MS':
      uf = 16;
      break;
    case 'MG':
      uf = 17;
      break;
    case 'MA':
      uf = 18;
      break;
    case 'GO':
      uf = 19;
      break;
    case 'ES':
      uf = 20;
      break;
    case 'DF':
      uf = 21;
      break;
    case 'CE':
      uf = 22;
      break;
    case 'BA':
      uf = 23;
      break;
    case 'AP':
      uf = 24;
      break;
    case 'AM':
      uf = 25;
      break;
    case 'AL':
      uf = 26;
      break;
    // case "AC":uf = 27;break;
    default:
      uf = 27;
  }
  return uf;
};
const PacienteCad = (props) => {
  let history = useHistory();
  const location = useLocation();
  const { user } = useContext(UserContext);

  const [cid_uf, setCid_uf] = useState([]);
  const [statusCid_uf, setStatusCid_uf] = useState([
    { value: 'TO', label: 'TO - Tocantins' },
    { value: 'SP', label: 'SP - São Paulo' },
    { value: 'SE', label: 'SE - Sergipe' },
    { value: 'SC', label: 'SC - Santa Catarina' },
    { value: 'RS', label: 'RS - Rio Grande do Sul' },
    { value: 'RR', label: 'RR - Roraima' },
    { value: 'RO', label: 'RO - Rondônia' },
    { value: 'RN', label: 'RN - Rio Grande do Norte' },
    { value: 'RJ', label: 'RJ - Rio de Janeiro' },
    { value: 'PR', label: 'PR - Paraná' },
    { value: 'PI', label: 'PI - Piauí' },
    { value: 'PE', label: 'PE - Pernambuco' },
    { value: 'PB', label: 'PB - Paraíba' },
    { value: 'PA', label: 'PA - Pará' },
    { value: 'MT', label: 'MT - Mato Grosso' },
    { value: 'MS', label: 'MS - Mato Grosso do Sul' },
    { value: 'MG', label: 'MG - Minas Gerais' },
    { value: 'MA', label: 'MA - Maranhão' },
    { value: 'GO', label: 'GO - Goiás' },
    { value: 'ES', label: 'ES - Espírito Santo' },
    { value: 'DF', label: 'DF - Distrito Federal' },
    { value: 'CE', label: 'CE - Ceará' },
    { value: 'BA', label: 'BA - Bahia' },
    { value: 'AP', label: 'AP - Amapá' },
    { value: 'AM', label: 'AM - Amazonas' },
    { value: 'AL', label: 'AL - Alagoas' },
    { value: 'AC', label: 'AC - Acre' },
  ]);
  const [displayCampos, setDisplayCampos] = useState('none');
  const [pac_id, setPac_id] = useState('');
  const [pac_nome, setPac_nome] = useState('');
  const [pac_endereco, setPac_endereco] = useState('');
  const [pac_bairro, setPac_bairro] = useState('');
  const [pac_nascimento, setPac_nascimento] = useState('');
  const [pac_carteira_numero, setPac_carteira_numero] = useState('');
  const [pac_carteira_validade, setPac_carteira_validade] = useState('');
  const [pac_cep, setPac_cep] = useState('');
  const [pac_cidade, setPac_cidade] = useState('');
  const [statusPac_cidade, setStatusPac_cidade] = useState([]);
  const [pac_fone, setPac_fone] = useState('');
  const [pac_cpf, setPac_cpf] = useState('');
  const [pac_celular, setPac_celular] = useState('');
  const [convenio, setConvenio] = useState([]);
  const [medico, setMedico] = useState([]);
  const [statusMedico, setStatusMedico] = useState([]);
  const [pac_convenio, setPac_convenio] = useState('');
  const [statusConvenio, setStatusConvenio] = useState([]);
  const [pacienteExcel, setPacienteExcel] = useState([]);

  const [pacientes, setPacientes] = useState([]);
  const [idModal, setIdModal] = useState('');
  const [nomeModal, setNomeModal] = useState('');

  // select medico
  const [cons_medico, setCons_medico] = useState([]);
  const [statusCons_medico, setStatusCons_medico] = useState([]);

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [pacientesPag, setPacientesPag] = useState([]);

  const [totalPages, setTotalPages] = useState(0);

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    window.$('#sidebarCollapse').on('click', function () {
      window.$('#sidebar').toggleClass('active');
      window.$(this).toggleClass('active');
    });

    /*
    if (!sessionStorage.getItem("refresh")) {
      sessionStorage.setItem("refresh", 1);
      window.location.reload(false);
    }*/
    var res = location.pathname.split('/');
    sessionStorage.setItem('pac_id', res[2]);
    if (res) {
      if (res[3] && res[2]) {
        getPaciente(res[3], res[2]);
      }
    }

    getMedico();
    getConvenio();
  }, []);

  useEffect(() => {
    setConvenio([]);
    setPac_convenio([]);
    setPac_cidade([]);
    setPac_celular('');
    setCid_uf([]);
    setPac_bairro('');
    setPac_id('');
    setPac_endereco('');
    setPac_nome('');
    setPac_fone('');
    setPac_cep('');
    setPac_cpf('');
    setNomeModal('');
    setIdModal('');
  }, [props.displayPaciente]);

  const getConvenio = () => {
    api
      .get('api/convenios?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayConvenios = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayConvenios.push({
            value: response.data[i].conv_id,
            label:
              response.data[i].conv_id + ' - ' + response.data[i].conv_nome,
          });
        }

        setStatusConvenio(arrayConvenios);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getMedico = async () => {
    let parametro = '?secretaria=' + sessionStorage.getItem('secretaria_id');

    /*  if (sessionStorage.getItem('cargo') === 'M') {
      parametro = '?medico=' + sessionStorage.getItem('med_id');
    }
    const medicoArray = [];*/
    const medicoArray = [];
    if (sessionStorage.getItem('cargo') === 'M') {
      setMedico({
        label: sessionStorage.getItem('med_nome'),
        value: sessionStorage.getItem('med_id'),
      });
      medicoArray.push({
        label: sessionStorage.getItem('med_nome'),
        value: sessionStorage.getItem('med_id'),
      });
      getPaciente(sessionStorage.getItem('med_id'));
      setStatusMedico(medicoArray);
      setCons_medico(medicoArray);
    } else {
      await api
        .get('api/medicos' + parametro, {
          headers: {
            Authorization: 'bearer ' + sessionStorage.getItem('token'),
            tcdocpw: sessionStorage.getItem('senha'),
            tcdocuserlogin:
              sessionStorage.getItem('cargo') === 'M'
                ? sessionStorage.getItem('med_email')
                : sessionStorage.getItem('secretaria_email'),
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            medicoArray.push({
              label: capitalize(
                response.data[i].med_nome +
                  ' ' +
                  response.data[i].med_sobrenome,
              ),
              value: response.data[i].med_id,
            });
          }
          console.log(medicoArray);

          setStatusMedico(medicoArray);

          if (medicoArray.length === 1) {
            setCons_medico(medicoArray);
            setStatusCons_medico(medicoArray);
            getPaciente(sessionStorage.getItem('med_id'));
          } else {
            setCons_medico(medicoArray);
            setStatusCons_medico(medicoArray);
          }
        })
        .catch((error) => {
          if (error.response) {
            catchResponse(error.response.status);
          }
        });
    }
  };

  const getPaciente = (medico, paciente) => {
    api
      .get('api/pacientes?medico=' + medico, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setPacientes(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        const cidadePag = [];
        if (fim > response.data.length) {
          fim = response.data.length;
        }
        for (let i = inicio; i < fim; i++) {
          cidadePag.push(response.data[i]);
        }
        setPacientesPag(cidadePag);

        if (paciente) {
          var pacienteArray = response.data.filter(
            (produto) => produto.pac_id == paciente,
          );
          if (pacienteArray.length > 0) {
            editar(pacienteArray[0]);
            document.getElementById('cadastrarViaConsulta').click();
          }
        }
        let pacienteExcel = [];
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].pac_fone = mask_tel_celular_area(
            response.data[i].pac_fone,
          );
          let convenio_nome = response.data[i].nome_convenio.split(',');

          let Convenio = '';

          for (let i = 0; i < convenio_nome.length; i++) {
            Convenio += RemoveAspasChaves(convenio_nome[i]) + ',';
          }

          response.data[i].nome_convenio = Convenio.substr(
            0,
            Convenio.length - 1,
          );
          pacienteExcel.push(response.data[i]);

          setPacienteExcel(pacienteExcel);
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const handleChangeUF = (cid_uf) => {
    setCid_uf(cid_uf);
    getCidade(UFtoInt(cid_uf.value));
  };
  const handleChangeMedico = (medico) => {
    setMedico(medico);
  };
  const handleChange = (pac_cidade) => {
    setPac_cidade(pac_cidade);
  };
  const handleChangeConvenio = (pac_convenio) => {
    setPac_convenio(pac_convenio);
  };

  const RemoveAspasChaves = (value) => {
    value = value.replace(/"/gi, '');
    value = value.replace('}', '');
    value = value.replace('{', '');
    return value;
  };

  const editar = (paciente) => {
    //  console.log(paciente);
    document.getElementById('pac_nome').focus();

    setPac_bairro(paciente.pac_bairro);
    setPac_id(paciente.pac_id);
    setPac_endereco(paciente.pac_endereco);
    setPac_nome(paciente.pac_nome);
    setPac_cep(paciente.pac_cep);
    setPac_cpf(paciente.pac_cpf);
    setPac_fone(paciente.pac_fone);
    setPac_celular(paciente.pac_celular);
    setPac_nascimento(paciente.pac_nascimento);
    setCid_uf({ value: paciente.cid_uf, label: paciente.cid_uf });
    let med_nome = statusMedico.filter((el) => {
      return el.value === paciente.pac_medico;
    });
    if (med_nome[0]) {
      setMedico({ value: paciente.pac_medico, label: med_nome[0].label });
    }

    setCid_uf({ value: paciente.cid_uf, label: paciente.cid_uf });
    if (paciente.cid_uf) {
      getCidade(UFtoInt(paciente.cid_uf));
    }
    setPac_cidade({
      value: paciente.pac_cidade,
      label: paciente.cid_municipio,
    });
    setPac_convenio({
      value: paciente.pac_convenio,
      label: paciente.conv_nome,
    });
  };

  const save = (e, metodo, status) => {
    e.preventDefault();
    e.stopPropagation();

    let data, method, url;
    if (pac_cpf) {
      if (!validar_CPF(pac_cpf)) {
        handleInfo('Preencha um CPF válido');
        return false;
      }
    }

    if (metodo) {
      method = 'delete';
    } else {
      if (pac_celular.length < 15) {
        handleInfo('Preencha  o campo celular corretamente');
        return false;
      }
      if (!pac_id) {
        method = 'post';
      } else {
        method = 'put';
      }
    }
    if (pac_cidade.label) {
      var cidade_nome = removeAcento(pac_cidade.label);
    }

    if (method === 'put') {
      data = {
        pac_cidade: pac_cidade.value,
        cid_municipio: cidade_nome,
        pac_estado: cid_uf.value,
        cid_uf: cid_uf.value,
        pac_id: pac_id,
        pac_bairro: pac_bairro,
        pac_nome: pac_nome,
        pac_endereco: pac_endereco,
        pac_cep: pac_cep,
        pac_fone: pac_fone,
        pac_celular: pac_celular,
        pac_nascimento: pac_nascimento,
        pac_convenio: pac_convenio.value,
        pac_medico: medico.value,
        pac_cpf: pac_cpf,
        pac_carteira_numero: pac_carteira_numero,
        pac_carteira_validade: pac_carteira_validade,
      };

      url = 'api/pacientes/' + pac_id;
    } else if (method === 'post') {
      data = {
        pac_cidade: pac_cidade.value,
        cid_municipio: cidade_nome,
        cid_uf: cid_uf.value,
        pac_estado: cid_uf.value,
        pac_bairro: pac_bairro,
        pac_nome: pac_nome,
        pac_endereco: pac_endereco,
        pac_cep: pac_cep,
        pac_fone: pac_fone,
        pac_celular: pac_celular,
        pac_nascimento: pac_nascimento,
        pac_convenio: pac_convenio.value,
        pac_medico: medico.value,
        pac_cpf: pac_cpf,
        pac_carteira_numero: pac_carteira_numero,
        pac_carteira_validade: pac_carteira_validade,
      };
      url = 'api/pacientes';
    }

    // return false;
    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        /* if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }        
        getPaciente(medico.value);*/

        let statusCons_paciente = props.statusCons_paciente;

        statusCons_paciente.push({
          value: response.data,
          label: response.data + ' - ' + pac_nome,
        });
        props.setStatusCons_paciente(statusCons_paciente);

        props.setCons_paciente({
          value: response.data,
          label: response.data + ' - ' + pac_nome,
        });
        props.setCons_convenio({
          value: pac_convenio.value,
          label: pac_convenio.label,
        });
        limpar();
        document.getElementById('buscar_paciente').style.display = 'none';
        document.getElementById('select_paciente').style.display = '';
        if (document.getElementById('cons_paciente')) {
          document.querySelector(
            '#cons_paciente > div > div.css-1hwfws3 > div.css-1wa3eu0-placeholder > div',
          ).style.marginLeft = '35px';
        }
      })
      .catch((error) => {
        //  catchResponse();

        //  catchResponse(error);
        console.log(error);
      });
  };

  const limpar = () => {
    props.atualizarDisplay('none');
    setConvenio([]);
    setPac_cidade([]);
    setPac_celular('');
    setCid_uf([]);
    setPac_bairro('');
    setPac_id('');
    setPac_endereco('');
    setPac_nome('');
    setPac_fone('');
    setPac_cep('');
    setPac_cpf('');
    setNomeModal('');
    setIdModal('');
  };

  const getCidade = (cid_uf) => {
    setPac_cidade([]);
    api
      .get('api/cidades?UF=' + cid_uf, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayCidade = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayCidade.push({
            value: response.data[i].cid_id,
            label: response.data[i].cid_municipio,
          });
        }

        setStatusPac_cidade(arrayCidade);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const viacep = (value) => {
    if (value) {
      if (value.length === 10) {
        axios
          .get('https://viacep.com.br/ws/' + mask_Numeros(value) + '/json/')
          .then((response) => {
            if (response.data.ibge) {
              setPac_bairro(removeAcento(response.data.bairro));
              setPac_endereco(removeAcento(response.data.logradouro));
              setCid_uf({
                value: response.data.uf,
                label: response.data.uf,
              });
              if (response.data.uf) {
                getCidade(response.data.uf);
              }
              setPac_cidade({
                value: response.data.ibge,
                label: removeAcento(response.data.localidade),
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              catchResponse(error.response.status);
            }
          });
      }
    }
    return value;
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{ display: props.displayPaciente }}
      >
        <div className="card">
          <div className="card-header">
            {' '}
            {HeadSideMask} Cadastro de Paciente {AngleDoubleLeft}
          </div>

          <div id="triangulo-para-baixo"></div>
          <div className="card-body ">
            <form id="form_paciente" onSubmit={(e) => save(e)}>
              <div className="row mb-3">
                <div className="col-md-3 mb-3">
                  <strong className="fild-required">
                    {' '}
                    <label htmlFor="pac_nome">Nome:</label>
                  </strong>
                  <input
                    type="text"
                    className="form-control"
                    id="pac_nome"
                    placeholder="Nome do paciente"
                    onChange={(e) => setPac_nome(removeAcento(e.target.value))}
                    value={pac_nome}
                    required
                    maxLength="80"
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <strong className="fild-required">
                    <label htmlFor="pac_celular">Celular:</label>{' '}
                  </strong>
                  <input
                    type="text"
                    className="form-control"
                    id="pac_celular"
                    placeholder="celular com DDD"
                    maxLength="20"
                    onChange={(e) =>
                      setPac_celular(mask_tel_celular_area(e.target.value))
                    }
                    value={pac_celular}
                    required
                  />
                  {
                    // regra para deixar  telefone OBRIGATORIO
                    /* <input
                          style={{
                            top: "43px",
                            position: "absolute",
                            zIndex: "-1",
                          }}
                          type="password"
                          id="pac_fone_html5_validate"
                          
                          value={pac_fone}
                          required
                        />*/
                  }
                </div>
                <div className="col-md-3 mb-3">
                  <strong className="fild-required">
                    {' '}
                    <label htmlFor="pac_convenio">Convênio:</label>
                  </strong>
                  <Select
                    id="pac_convenio"
                    value={pac_convenio}
                    onChange={handleChangeConvenio}
                    options={statusConvenio}
                    placeholder={<div>Selecione</div>}
                  />
                  <input
                    style={{
                      top: '43px',
                      position: 'absolute',
                      zIndex: '-1',
                      width: '10%',
                    }}
                    type="password"
                    id="pac_convenio_html5_validate"
                    value={pac_convenio}
                    required
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <strong className="fild-required">
                    {' '}
                    <label htmlFor="medico">Médico:</label>{' '}
                  </strong>
                  <Select
                    id="medico"
                    value={medico}
                    onChange={handleChangeMedico}
                    options={statusMedico}
                    placeholder={<div>Selecione</div>}
                  />
                  <input
                    style={{
                      top: '43px',
                      position: 'absolute',
                      zIndex: '-1',
                      width: '10%',
                    }}
                    type="password"
                    id="medico_html5_validate"
                    value={medico}
                    required
                  />
                </div>{' '}
              </div>

              <div className="row mb-3">
                <div
                  className="col-md-3 mb-3"
                  style={{ display: displayCampos }}
                >
                  <label htmlFor="pac_nascimento">Data de nascimento:</label>
                  <input
                    type="date"
                    className="form-control"
                    id="pac_nascimento"
                    placeholder="Data de nascimento"
                    onChange={(e) => setPac_nascimento(e.target.value)}
                    value={pac_nascimento}
                  />
                </div>
                {sessionStorage.getItem('med_conselho')?.toLowerCase() ==
                'crm' ? (
                  <div
                    className="col-md-3 mb-3"
                    style={{ display: displayCampos }}
                  >
                    <label htmlFor="pac_cpf">CPF:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="pac_cpf"
                      placeholder="Digite seu CPF"
                      maxLength="14"
                      onChange={(e) =>
                        setPac_cpf(mask_CPF(removeAcento(e.target.value)))
                      }
                      value={pac_cpf}
                    />
                  </div>
                ) : (
                  <div
                    className="col-md-3 mb-3"
                    style={{ display: displayCampos }}
                  >
                    <label htmlFor="pac_cpf">CPF:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="pac_cpf"
                      placeholder="Digite seu CPF"
                      maxLength="14"
                      onChange={(e) =>
                        setPac_cpf(mask_CPF(removeAcento(e.target.value)))
                      }
                      value={pac_cpf}
                    />
                  </div>
                )}
                <div
                  className="col-md-3 mb-3"
                  style={{ display: displayCampos }}
                >
                  <label htmlFor="pac_cep">CEP:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="pac_cep"
                    maxLength="35"
                    placeholder="Digite o CEP"
                    onChange={(e) =>
                      setPac_cep(viacep(mask_CEP(e.target.value)))
                    }
                    value={pac_cep}
                  />
                </div>
                <div
                  className="col-md-3 mb-3"
                  style={{ display: displayCampos }}
                >
                  <label htmlFor="pac_endereco">Endereço:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="pac_endereco"
                    placeholder="Digite seu Endereço"
                    maxLength="150"
                    onChange={(e) =>
                      setPac_endereco(removeAcento(e.target.value))
                    }
                    value={pac_endereco}
                  />
                </div>{' '}
              </div>

              <div className="row mb-3">
                <div
                  className="col-md-3 mb-3"
                  style={{ display: displayCampos }}
                >
                  <label htmlFor="pac_bairro">Bairro:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="pac_bairro"
                    placeholder="Digite o seu Bairro"
                    maxLength="80"
                    onChange={(e) =>
                      setPac_bairro(removeAcento(e.target.value))
                    }
                    value={pac_bairro}
                  />
                </div>
                <div
                  className="col-md-3 mb-3"
                  style={{ display: displayCampos }}
                >
                  <label htmlFor="cid_uf">Estado / UF:</label>
                  <Select
                    id="cid_uf"
                    value={cid_uf}
                    onChange={handleChangeUF}
                    options={statusCid_uf}
                    placeholder={<div>Selecione</div>}
                  />
                  <input
                    style={{
                      top: '43px',
                      position: 'absolute',
                      zIndex: '-1',
                      width: '10%',
                    }}
                    type="password"
                    id="cid_uf_html5_validate"
                    value={cid_uf}
                  />
                </div>
                <div
                  className="col-md-3 mb-3"
                  style={{ display: displayCampos }}
                >
                  <label htmlFor="pac_cidade">Cidade:</label>
                  <Select
                    id="pac_cidade"
                    value={pac_cidade}
                    onChange={handleChange}
                    options={statusPac_cidade}
                    placeholder={<div>Selecione o estado antes</div>}
                  />
                  <input
                    style={{
                      top: '43px',
                      position: 'absolute',
                      zIndex: '-1',
                      width: '10%',
                    }}
                    type="password"
                    id="pac_cidade_html5_validate"
                    value={pac_cidade}
                  />
                </div>
                <div
                  className="col-md-3 mb-3"
                  style={{ display: displayCampos }}
                >
                  <label htmlFor="pac_fone">Telefone:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="pac_fone"
                    placeholder="Telefone com DDD"
                    maxLength="20"
                    onChange={(e) =>
                      setPac_fone(mask_tel_celular_area(e.target.value))
                    }
                    value={pac_fone}
                  />
                  {
                    // regra para deixar  telefone OBRIGATORIO
                    /* <input
                          style={{
                            top: "43px",
                            position: "absolute",
                            zIndex: "-1",
                          }}
                          type="password"
                          id="pac_fone_html5_validate"
                          
                          value={pac_fone}
                          required
                        />*/
                  }
                </div>
                <div
                  className="col-md-3 mb-3"
                  style={{ display: displayCampos }}
                >
                  <label htmlFor="pac_carteira_numero">Carteira:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="pac_carteira_numero"
                    placeholder="Nº da carteira"
                    maxLength="20"
                    onChange={(e) => setPac_carteira_numero(e.target.value)}
                    value={pac_carteira_numero}
                  />
                </div>
                <div
                  className="col-md-3 mb-3"
                  style={{ display: displayCampos }}
                >
                  <label htmlFor="pac_carteira_validade">
                    Carteira Validade:
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="pac_carteira_validade"
                    placeholder="Nº da carteira"
                    maxLength="20"
                    onChange={(e) => setPac_carteira_validade(e.target.value)}
                    value={pac_carteira_validade}
                  />
                </div>{' '}
                {displayCampos === 'none' ? (
                  <button
                    className="btn btn-sm btn_Cadastrar "
                    type="button"
                    onClick={(e) => setDisplayCampos('block')}
                    style={{
                      alignSelf: 'center',
                      marginTop: '8px',
                      marginLeft: '16px',
                      width: '35px',
                      background: '#a7c4d2',
                    }}
                    id="btn_limparCad"
                  >
                    {Plus}
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn_Cadastrar "
                    type="button"
                    onClick={(e) => setDisplayCampos('none')}
                    style={{
                      alignSelf: 'center',
                      marginTop: '8px',
                      marginLeft: '16px',
                      width: '35px',
                      background: '#f38692',
                    }}
                    id="btn_limparCad"
                  >
                    {Minus}
                  </button>
                )}
              </div>

              <div className="text-left mb-3 mt-3 ">
                {pac_id ? (
                  <>
                    <button
                      className="btn btn-sm btn_Cadastrar "
                      type="submit"
                      id="btnAlterar"
                    >
                      {Edit} Alterar
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-sm btn_Cadastrar "
                    type="submit"
                    id="btnCadastrar"
                  >
                    {Save} Cadastrar
                  </button>
                )}{' '}
                &nbsp;&nbsp;{' '}
                <button
                  className="btn btn-sm btn-secondary "
                  onClick={(e) => limpar(e)}
                  type="button"
                  id="btn_limparCad"
                >
                  {Times} Fechar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default PacienteCad;
