import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { clone } from 'lodash';
import { api } from '../utils/WebApiConfig';
import { TablePagination } from '@material-ui/core';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import {
  Times,
  FileExcel,
  Clock,
  ImageUpload,
  UserClock,
  Search,
  Save,
  Edit,
  Trash,
  BriefcaseMedical,
  Eye,
  AngleDoubleLeft,
} from '../utils/Fontawesome';
import axios from 'axios';

import {
  mask_tel_celular_area,
  mask_CEP,
  removeAcento,
  mask_Numeros,
  sqlLIKE,
  removeEspacos,
} from '../utils/Mascara';

import ConvenioCad from './ConvenioCad';
import { UserContext } from './UserContext';



const isIncludingString = (string, option) => {
  let result = false;
  if (
    !string ||
    option.label.toString().includes(string) ||
    option.value.toString().includes(string)
  ) {
    result = true;
  }
  return result;
};

const UFtoInt = (uf) => {
  switch (uf) {
    case 'TO':
      uf = 1;
      break;
    case 'SP':
      uf = 2;
      break;
    case 'SE':
      uf = 3;
      break;
    case 'SC':
      uf = 4;
      break;
    case 'RS':
      uf = 5;
      break;
    case 'RR':
      uf = 6;
      break;
    case 'RO':
      uf = 7;
      break;
    case 'RN':
      uf = 8;
      break;
    case 'RJ':
      uf = 9;
      break;
    case 'PR':
      uf = 10;
      break;
    case 'PI':
      uf = 11;
      break;
    case 'PE':
      uf = 12;
      break;
    case 'PB':
      uf = 13;
      break;
    case 'PA':
      uf = 14;
      break;
    case 'MT':
      uf = 15;
      break;
    case 'MS':
      uf = 16;
      break;
    case 'MG':
      uf = 17;
      break;
    case 'MA':
      uf = 18;
      break;
    case 'GO':
      uf = 19;
      break;
    case 'ES':
      uf = 20;
      break;
    case 'DF':
      uf = 21;
      break;
    case 'CE':
      uf = 22;
      break;
    case 'BA':
      uf = 23;
      break;
    case 'AP':
      uf = 24;
      break;
    case 'AM':
      uf = 25;
      break;
    case 'AL':
      uf = 26;
      break;
    // case "AC":uf = 27;break;
    default:
      uf = 27;
  }
  return uf;
};
const Medico = () => {
  let history = useHistory();
  const { user } = useContext(UserContext);
  const [cid_uf, setCid_uf] = useState([]);
  const [statusCid_uf, setStatusCid_uf] = useState([
    { value: 'TO', label: 'TO - Tocantins' },
    { value: 'SP', label: 'SP - São Paulo' },
    { value: 'SE', label: 'SE - Sergipe' },
    { value: 'SC', label: 'SC - Santa Catarina' },
    { value: 'RS', label: 'RS - Rio Grande do Sul' },
    { value: 'RR', label: 'RR - Roraima' },
    { value: 'RO', label: 'RO - Rondônia' },
    { value: 'RN', label: 'RN - Rio Grande do Norte' },
    { value: 'RJ', label: 'RJ - Rio de Janeiro' },
    { value: 'PR', label: 'PR - Paraná' },
    { value: 'PI', label: 'PI - Piauí' },
    { value: 'PE', label: 'PE - Pernambuco' },
    { value: 'PB', label: 'PB - Paraíba' },
    { value: 'PA', label: 'PA - Pará' },
    { value: 'MT', label: 'MT - Mato Grosso' },
    { value: 'MS', label: 'MS - Mato Grosso do Sul' },
    { value: 'MG', label: 'MG - Minas Gerais' },
    { value: 'MA', label: 'MA - Maranhão' },
    { value: 'GO', label: 'GO - Goiás' },
    { value: 'ES', label: 'ES - Espírito Santo' },
    { value: 'DF', label: 'DF - Distrito Federal' },
    { value: 'CE', label: 'CE - Ceará' },
    { value: 'BA', label: 'BA - Bahia' },
    { value: 'AP', label: 'AP - Amapá' },
    { value: 'AM', label: 'AM - Amazonas' },
    { value: 'AL', label: 'AL - Alagoas' },
    { value: 'AC', label: 'AC - Acre' },
  ]);
  const [med_id, setMed_id] = useState('');
  const [med_nome, setMed_nome] = useState('');
  const [med_sobrenome, setMed_sobrenome] = useState('');
  const [external_id, setExternal_id] = useState('');
  const [conselho_memed, setConselho_memed] = useState('');
  const [conselho_id, setConselho_id] = useState('');
  const [med_email, setMed_email] = useState('');
  const [med_conselho, setMed_conselho] = useState([]);

  const [exp_domingo, setExp_domingo] = useState('');
  const [exp_segunda, setExp_segunda] = useState('');
  const [exp_terca, setExp_terca] = useState('');
  const [exp_quarta, setExp_quarta] = useState('');
  const [exp_quinta, setExp_quinta] = useState('');
  const [exp_sexta, setExp_sexta] = useState('');
  const [exp_sabado, setExp_sabado] = useState('');

  const [med_conselho_codigo, setMed_conselho_codigo] = useState('');

  const [med_status, setMed_status] = useState(1);
  const [med_horaini, setMed_horaini] = useState(null);
  const [med_horafim, setMed_horafim] = useState(null);
  const [med_sessao, setMed_sessao] = useState(null);
  const [med_img, setMed_img] = useState([]);

  const [med_data_nascimento, setMed_data_nascimento] = useState('');
  const [med_endereco, setMed_endereco] = useState('');
  const [med_numero, setMed_numero] = useState('');
  const [med_bairro, setMed_bairro] = useState('');
  const [med_cep, setMed_cep] = useState('');
  const [med_cidade, setMed_cidade] = useState('');
  const [statusMed_cidade, setStatusMed_cidade] = useState([]);
  const [med_telefone, setMed_telefone] = useState('');
  const [med_senha, setMed_senha] = useState('');
  const [secretaria_senha, setSecretaria_senha] = useState('');
  const [secretaria_email, setSecretaria_email] = useState('');
  const [convenio, setConvenio] = useState('');
  const [statusConvenio, setStatusConvenio] = useState('');
  const [statusMed_conselho, setStatusMed_conselho] = useState('');
  const [conv_refresh, setConv_refresh] = useState(false);
  const [medicoExcel, setMedicoExcel] = useState([]);

  const [medicos, setMedicos] = useState([]);
  const [idModal, setIdModal] = useState('');
  const [nomeModal, setNomeModal] = useState('');
  const [idStatusModal, setStatusModal] = useState('');

  // select multiple
  const [searchField, setSearchField] = useState('');
  const [values, setValues] = useState([]);
  const [valuesId, setValuesId] = useState([]);

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [medicosPag, setMedicosPag] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
      "rowsPerPage:" +
        rowsPerPage +
        " newPage " +
        newPage +
        " " +
        inicio +
        " ---- " +
        fim
    );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setMedicosPag(cidadePag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setMedicosPag(cidadePag);
  };

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    } else {
      console.log('nao tem');
    }
    window.$('#sidebarCollapse').on('click', function () {
      window.$('#sidebar').toggleClass('active');
      window.$(this).toggleClass('active');
    });

    /*
    if (!sessionStorage.getItem("refresh")) {
      sessionStorage.setItem("refresh", 1);
      window.location.reload(false);
    }*/

    document.getElementById('med_status').checked = true;
    getMedico();
    getConvenio();
    getConselho();

    /* window.$('#modalConvenio').on('hide.bs.modal', function () {
      getConvenio();
    });*/
    /*  window.document
      .querySelector('#modalConvenio')
      .addEventListener('hide.bs.modal', function (e) {
        getConvenio();
      });*/
  }, []);

  /*  useEffect(() => {
    getConvenio();
  }, [statusConvenio]);*/
  // select multiple

  const multiOnChange = (opt, { option }) => {
    //   console.log(opt);
    //  console.log(option);
    let newOpts = opt;
    let string = searchField.toUpperCase();

    if (option?.value === 'cadastrar') {
      document.getElementById('btn_Cadastrar').click();
      document.getElementsByClassName('modal-backdrop')[1].style.zIndex = 1051;
      document.getElementsByClassName(
        'bd-example-modal-md',
      )[0].style.zIndex = 1052;

      return false;
    }
    if (option && option.value === 'all') {
      let filteredOptions = clone(statusConvenio);

      filteredOptions = filteredOptions.filter(
        (filteredOption) =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption),
      );

      string = null;
      newOpts = newOpts
        .concat(filteredOptions)
        .filter((newOpt) => newOpt.value !== 'all');
    }

    //  console.log(string);
    //  console.log(newOpts);
    setSearchField(string);
    setValues(newOpts);
  };

  const multiOnInputChange = (string, { action }) => {
    if (action === 'input-change') {
      setSearchField(string);
    }
  };
  const multiFilterOption = ({ label, value }, string) => {
    if (value === 'all') {
      return true;
    } else if (string) {
      return (
        label.includes(string.toUpperCase()) ||
        value.toString().includes(string.toUpperCase())
      );
    } else {
      return true;
    }
  };
  // fim do select multiple
  /* const getConvenio = () => {
    api
      .get('api/convenios?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayConvenios = [];
        console.log(JSON.stringify(response));
        arrayConvenios.push({
          value: 'cadastrar',
          label: 'Cadastrar novo convênio',
        });
        for (let i = 0; i < response.data.length; i++) {
          arrayConvenios.push({
            value: response.data[i].conv_id,
            label:
              response.data[i].conv_id + ' - ' + response.data[i].conv_nome,
          });
          console.log(JSON.stringify(arrayConvenios));
          console.log(
            response.data[i].conv_id + ' - ' + response.data[i].conv_nome,
          );
        }
        console.log(JSON.stringify(arrayConvenios));
        setStatusConvenio(arrayConvenios);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
*/
  const getConvenio = () => {
    let url = 'api/convenios?med_id=' + sessionStorage.getItem('med_id');
    //  let url = 'api/convenios';
    api
      .get(url, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        //   console.log(JSON.stringify(response.data));
        const arrayConvenios = [];
        for (let i = 0; i < response.data.length; i++) {
          arrayConvenios.push({
            value: String(response.data[i].conv_id),
            label:
              response.data[i].conv_id + ' - ' + response.data[i].conv_nome,
          });
        }

        arrayConvenios.push({
          value: 'cadastrar',
          label: 'Cadastrar novo convênio',
        });

        setStatusConvenio(arrayConvenios);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getMedico = () => {
    api
      .get('api/medicos?select=1&medico=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setMedicos(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        const cidadePag = [];
        if (fim > response.data.length) {
          fim = response.data.length;
        }
        for (let i = inicio; i < fim; i++) {
          cidadePag.push(response.data[i]);
        }
        setMedicosPag(cidadePag);
        let medicoExcel = [];
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].med_telefone = mask_tel_celular_area(
            response.data[i].med_telefone,
          );
          let convenio_nome = response.data[i].nome_convenio.split(',');

          let Convenio = '';

          for (let i = 0; i < convenio_nome.length; i++) {
            Convenio += RemoveAspasChaves(convenio_nome[i]) + ',';
          }

          response.data[i].nome_convenio = Convenio.substr(
            0,
            Convenio.length - 1,
          );
          medicoExcel.push(response.data[i]);

          setMedicoExcel(medicoExcel);
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const checkboxAtivo = () => {
    if (med_status === 1) {
      setMed_status(0);
    } else {
      setMed_status(1);
    }
  };
  const checkboxDomingo = () => {
    if (exp_domingo === 7) {
      setExp_domingo(0);
    } else {
      setExp_domingo(7);
    }
  };
  const checkboxSegunda = () => {
    if (exp_segunda === 1) {
      setExp_segunda(0);
    } else {
      setExp_segunda(1);
    }
  };
  const checkboxTerca = () => {
    if (exp_terca === 2) {
      setExp_terca(0);
    } else {
      setExp_terca(2);
    }
  };
  const checkboxQuarta = () => {
    if (exp_quarta === 3) {
      setExp_quarta(0);
    } else {
      setExp_quarta(3);
    }
  };
  const checkboxQuinta = () => {
    if (exp_quinta === 4) {
      setExp_quinta(0);
    } else {
      setExp_quinta(4);
    }
  };
  const checkboxSexta = () => {
    if (exp_sexta === 5) {
      setExp_sexta(0);
    } else {
      setExp_sexta(5);
    }
  };
  const checkboxSabado = () => {
    if (exp_sabado === 6) {
      setExp_sabado(0);
    } else {
      setExp_sabado(6);
    }
  };

  const handleChangeUF = (cid_uf) => {
    setCid_uf(cid_uf);
    getCidade(UFtoInt(cid_uf.value));
  };
  const handleChangeConselho = (conselho) => {
    setMed_conselho(conselho);
  };

  const handleChange = (med_cidade) => {
    setMed_cidade(med_cidade);
  };

  const RemoveAspasChaves = (value) => {
    value = value.replace(/"/gi, '');
    value = value.replace('}', '');
    value = value.replace('{', '');
    return value;
  };

  const editar = (medico) => {
    if (medico.conselho_memed === 1) {
      getMedicoMemed(medico.med_id);
    }

    setMed_conselho({
      value: medico.conselho_id,
      label: medico.conselho_sigla,
    });

    document.getElementById('med_nome').focus();

    var convenio_id = medico.id_convenio.split(',');
    var convenio_nome = medico.nome_convenio.split(',');

    var Convenio = [];
    var ValuesId = [];
    for (let i = 0; i < convenio_id.length; i++) {
      if (convenio_nome[i]) {
        Convenio.push({
          value: parseInt(mask_Numeros(convenio_id[i])),
          label: RemoveAspasChaves(convenio_nome[i]),
        });
        ValuesId.push(parseInt(parseInt(mask_Numeros(convenio_id[i]))));
      }
    }
    setConselho_memed(medico.conselho_memed);
    setMed_data_nascimento(medico.med_data_nascimento);
    setMed_sobrenome(medico.med_sobrenome);
    setMed_horaini(medico.med_horaini);
    setMed_horafim(medico.med_horafim);
    setMed_sessao(medico.med_sessao);
    setValuesId(ValuesId);
    setValues(Convenio);

    setMed_conselho_codigo(medico.med_conselho_codigo);
    setMed_id(medico.med_id);
    setMed_email(medico.med_email);
    setMed_nome(medico.med_nome);
    setMed_status(medico.med_status);
    setMed_cep(medico.med_cep);
    setMed_numero(medico.med_numero);
    setMed_telefone(medico.med_telefone);
    setMed_endereco(medico.med_endereco);
    setCid_uf({ value: medico.cid_uf, label: medico.cid_uf });

    setMed_bairro(medico.med_bairro);
    if (medico.cid_uf) {
      getCidade(UFtoInt(medico.cid_uf));
    }
    setMed_cidade({
      value: medico.med_cidade,
      label: medico.cid_municipio,
    });

    if (medico.med_status === 1) {
      document.getElementById('med_status').checked = true;
    } else {
      document.getElementById('med_status').checked = false;
    }
    if (medico.med_exp_semanal) {
      setarStatusExpediente(medico.med_exp_semanal);
    }

    var spans = '';

    if (medico.med_img) {
      var med_imgs = medico.med_img.split(',');
      for (let i = 0; i < med_imgs.length - 1; i++) {
        if (med_imgs[i] !== null) {
          spans +=
            '<input type="checkbox" name="checkbox_imagem[]" value="' +
            med_imgs[i] +
            '" id="' +
            med_imgs[i] +
            '" />';
          spans += '<label for=' + med_imgs[i] + '>';
          spans +=
            '<img className="thumb-upload" style="margin-left:5px; max-width: 100px;max-height: 100px;" id=' +
            med_imgs[i] +
            ' src=' +
            '../imagens/medico/' +
            medico.med_id +
            '/' +
            med_imgs[i] +
            ' />';
          spans += '</label>';
        }
      }

      document.getElementById('list').innerHTML = spans;
    } else {
      document.getElementById('list').innerHTML = '';
    }
  };

  const dadosModal = (nome, id, status) => {
    setNomeModal(nome);
    setIdModal(id);
    setStatusModal(status);
  };
  const setarStatusExpediente = (string) => {
    document.getElementById('exp_domingo').checked = true;
    document.getElementById('exp_segunda').checked = true;
    document.getElementById('exp_terca').checked = true;
    document.getElementById('exp_quarta').checked = true;
    document.getElementById('exp_quinta').checked = true;
    document.getElementById('exp_sexta').checked = true;
    document.getElementById('exp_sabado').checked = true;
    if (string.indexOf('7') != -1) {
      setExp_domingo(7);
      document.getElementById('exp_domingo').checked = false;
    }
    if (string.indexOf('0') != -1) {
      setExp_domingo(7);
      document.getElementById('exp_domingo').checked = false;
    }

    if (string.indexOf('1') != -1) {
      setExp_segunda(1);
      document.getElementById('exp_segunda').checked = false;
    }
    if (string.indexOf('2') != -1) {
      setExp_terca(2);
      document.getElementById('exp_terca').checked = false;
    }
    if (string.indexOf('3') != -1) {
      setExp_quarta(3);
      document.getElementById('exp_quarta').checked = false;
    }
    if (string.indexOf('4') != -1) {
      setExp_quinta(4);
      document.getElementById('exp_quinta').checked = false;
    }
    if (string.indexOf('5') != -1) {
      setExp_sexta(5);
      document.getElementById('exp_sexta').checked = false;
    }
    if (string.indexOf('6') != -1) {
      setExp_sabado(5);
      document.getElementById('exp_sabado').checked = false;
    }
  };

  const save = (e, metodo, status) => {
    e.preventDefault();
    e.stopPropagation();

    let data, method, url;
    if (metodo) {
      method = 'delete';
    } else {
      var mc_convenio = [];

      for (let i = 0; i < values.length; i++) {
        mc_convenio.push(values[i].value);
      }

      if (!med_id) {
        method = 'post';
      } else {
        method = 'put';
      }

      var data_uri = document.querySelectorAll('output span img');

      var med_imgs = [];

      for (let i = 0; i < data_uri.length; i++) {
        med_imgs.push(data_uri[i].src);
      }
    }
    /*  
                       
                             
                                "external_id"     => $medico->getId_externo_memed(),
                         
                             ,
                               */
    var exp_semanal = '8';
    if (exp_domingo) {
      exp_semanal = exp_semanal + exp_domingo;
    }
    if (exp_segunda) {
      exp_semanal = exp_semanal + exp_segunda;
    }
    if (exp_terca) {
      exp_semanal = exp_semanal + exp_terca;
    }
    if (exp_quarta) {
      exp_semanal = exp_semanal + exp_quarta;
    }
    if (exp_quinta) {
      exp_semanal = exp_semanal + exp_quinta;
    }
    if (exp_sexta) {
      exp_semanal = exp_semanal + exp_sexta;
    }
    if (exp_sabado) {
      exp_semanal = parseInt(exp_semanal + exp_sabado);
    }

    if (method === 'put') {
      sessionStorage.setItem('med_conselho', med_conselho.value);
      data = {
        med_data_nascimento: med_data_nascimento,
        med_cidade: med_cidade.value,
        cid_municipio: removeAcento(med_cidade.label),
        cid_uf: cid_uf.value,
        med_id: med_id,
        id_externo_memed: external_id,
        med_conselho_id: med_conselho.value,
        med_conselho_codigo: med_conselho_codigo,
        med_nome: med_nome,
        med_sobrenome: med_sobrenome,
        med_email: med_email,
        mc_convenio: mc_convenio,
        med_status: med_status,
        med_endereco: med_endereco,
        med_numero: med_numero,
        med_bairro: med_bairro,
        med_cep: med_cep,
        med_telefone: med_telefone,
        med_senha: med_senha,
        secretaria_email: secretaria_email,
        secretaria_senha: secretaria_senha,
        med_horaini: med_horaini,
        med_horafim: med_horafim,
        med_sessao: med_sessao,
        med_img: med_imgs,
        med_exp_semanal: exp_semanal,
      };

      url = 'api/medicos/' + med_id;
    } else if (method === 'post') {
      data = {
        med_data_nascimento: med_data_nascimento,
        med_cidade: med_cidade.value,
        cid_municipio: removeAcento(med_cidade.label),
        cid_uf: cid_uf.value,
        med_conselho_id: med_conselho.value,
        med_conselho_codigo: med_conselho_codigo,
        med_nome: med_nome,
        med_sobrenome: med_sobrenome,
        med_email: med_email,
        mc_convenio: mc_convenio,
        med_status: med_status,
        med_endereco: med_endereco,
        med_numero: med_numero,
        med_bairro: med_bairro,
        med_cep: med_cep,
        med_telefone: med_telefone,
        med_senha: med_senha,
        secretaria_email: secretaria_email,
        secretaria_senha: secretaria_senha,
        med_horaini: med_horaini,
        med_horafim: med_horafim,
        med_sessao: med_sessao,
        med_img: med_imgs,
        med_exp_semanal: exp_semanal,
      };
      url = 'api/medicos';
    } else if (method === 'delete') {
      //    url = url + "/" + idModal + "/" + status;
      url = 'api/medicos/' + idModal;
      data = {
        id: idModal,
        status: status,
      };
      setNomeModal('');
      setIdModal('');
    }

    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        getMedico();
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setConvenio([]);
    setMed_cidade([]);
    setValues([]);
    setValuesId([]);
    setCid_uf([]);
    setMed_conselho([]);
    setMed_conselho_codigo('');
    setMed_id('');
    setMed_data_nascimento('');
    setMed_email('');
    setMed_nome('');
    setMed_telefone('');
    setMed_cep('');
    setMed_numero('');
    setMed_endereco('');
    setMed_bairro('');
    setMed_status(1);
    setMed_senha('');
    setNomeModal('');
    setIdModal('');
    setSecretaria_email('');
    setSecretaria_senha('');
    document.getElementById('exp_domingo').checked = true;
    document.getElementById('exp_segunda').checked = true;
    document.getElementById('exp_terca').checked = true;
    document.getElementById('exp_quarta').checked = true;
    document.getElementById('exp_quinta').checked = true;
    document.getElementById('exp_sexta').checked = true;
    document.getElementById('exp_sabado').checked = true;
    if (document.getElementById('list')) {
      document.getElementById('list').innerHTML = '';
    }
    if (document.getElementById('prod_foto')) {
      document.getElementById('prod_foto').value = '';
    }
  };
  const getMedicoMemed = async (med_id) => {
    let url = 'api/memed?med_id=' + med_id;
    if (!med_id) {
      url = 'api/memed?med_id=' + sessionStorage.getItem('med_id');
    }
    await api
      .get(url, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        if (response?.data?.data?.attributes?.external_id) {
          setExternal_id(response?.data?.data?.attributes?.external_id);
        }
        if (response?.data?.errors[0]?.title) {
          handleInfo(response.data.errors[0].detail);
        }
        if (response?.data?.errors[0]?.title) {
          handleInfo('Contate o suporte!');
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getConselho = (conselho_id) => {
    //  setMed_cidade([]);
    api
      .get('api/conselhos', {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayConselho = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayConselho.push({
            value: response.data[i].conselho_id,
            label: response.data[i].conselho_sigla,
          });
        }

        setStatusMed_conselho(arrayConselho);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getCidade = (cid_uf) => {
    setMed_cidade([]);
    api
      .get('api/cidades?UF=' + cid_uf, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayCidade = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayCidade.push({
            value: response.data[i].cid_id,
            label: response.data[i].cid_municipio,
          });
        }

        setStatusMed_cidade(arrayCidade);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const viacep = (value) => {
    if (value) {
      if (value.length === 10) {
        axios
          .get('https://viacep.com.br/ws/' + mask_Numeros(value) + '/json/')
          .then((response) => {
            if (response.data.ibge) {
              setMed_bairro(removeAcento(response.data.bairro));
              setMed_endereco(removeAcento(response.data.logradouro));
              setCid_uf({
                value: response.data.uf,
                label: response.data.uf,
              });
              if (response.data.uf) {
                getCidade(response.data.uf);
              }
              setMed_cidade({
                value: response.data.ibge,
                label: removeAcento(response.data.localidade),
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              catchResponse(error.response.status);
            }
          });
      }
    }
    return value;
  };

  const buscar = async () => {
    let filtrado;
    let filtradoDistribuidora;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = medicos;
      } else {
        filtrado = arrayFiltrado.filter(function (obj) {
          return sqlLIKE(obj.med_nome, '%' + busca + '%');
          // return ilikeToRegExp('%' + busca + '%').test(obj.med_nome);
        });

        filtradoDistribuidora = arrayFiltrado.filter(function (obj) {
          return sqlLIKE(obj.med_conselho, '%' + busca + '%');
          //  return ilikeToRegExp('%' + busca + '%').test(obj.med_conselho);
        });
        filtrado = filtrado.concat(filtradoDistribuidora);
        filtrado = filtrado.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      if (!filtrado.length) {
        filtrado = medicos;
        handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const cidadePag = [];
      for (let i = inicio - 1; i < fim; i++) {
        cidadePag.push(filtrado[i]);
      }

      await setMedicosPag(cidadePag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo('Nenhum registro encontrado');
    }
  };
  const handleImageButton = (e) => {
    e.preventDefault();
    if (document.querySelector('#prod_foto')) {
      document.querySelector('#prod_foto').click();
    }
  };

  const _handleImageChange = (e) => {
    e.preventDefault();

    document.getElementById('list').innerHTML = '';

    var fileInput = document.getElementById('prod_foto');
    var fileList = [];

    if (fileInput.files.length > 4) {
      handleError('No máximo 4 imagem');
      document.getElementById('prod_foto').value = '';
      return false;
    }
    for (var i = 0; i < fileInput.files.length; i++) {
      fileList.push(fileInput.files[i]);
    }

    for (var i = 0, f; (f = fileList[i]); i++) {
      //    this.GerarDataURI(f, i);

      // Only process image files.
      if (!f.type.match('image.*')) {
        continue;
      }

      var reader = new FileReader();

      reader.onload = (function (theFile) {
        return function (e) {
          (function () {
            var image = new Image();
            image.addEventListener(
              'load',
              function () {
                // tamanho original

                var canvas = document.createElement('canvas'),
                  ctx = canvas.getContext('2d');
                // coordenadas origem (source)

                // tamanho destino
                var MAX_WIDTH = 400;
                var MAX_HEIGHT = 400;
                var width = this.width;
                var height = this.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(this, 0, 0, width, height);

                let ext = theFile.name.split('.');

                //   var dataurl = canvas.toDataURL("image/" + ext[ext.length - 1]);
                var dataurl = canvas.toDataURL('image/png');

                var span = document.createElement('span');
                span.innerHTML = [
                  '<img className="img_data_uri" style="margin-left:5px; max-width: 150px;max-height: 150px;" name="img_data_uris" src="',
                  dataurl,
                  '" title="',
                  escape(theFile.name),
                  '"/>',
                ].join('');
                document.getElementById('list').insertBefore(span, null);
              },
              false,
            );
            image.src = e.target.result;
          })();
        };
      })(f);

      // Read in the image file as a data URL.
      reader.readAsDataURL(f);
    }
  };
  //console.log(statusConvenio);
  return (
    <>
      <div className="container-fluid">
        <div
          className="modal fade bd-example-modal-xl"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="card ">
                <div className="card-header">
                  {' '}
                  {BriefcaseMedical} Cadastro de Médico {AngleDoubleLeft}
                </div>

                <div className="card-body">
                  <form id="form_medico" onSubmit={(e) => save(e)}>
                    <div className="row mb-3">
                      <div className="col-md-3 mb-3">
                        <label htmlFor="med_nome">
                          Nome:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="med_nome"
                          placeholder="Nome do Médico"
                          onChange={(e) =>
                            setMed_nome(removeAcento(e.target.value))
                          }
                          value={med_nome}
                          required
                          maxLength="30"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="med_sobrenome">
                          Sobrenome:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="med_sobrenome"
                          placeholder="Sobrenome do Médico"
                          onChange={(e) =>
                            setMed_sobrenome(removeAcento(e.target.value))
                          }
                          value={med_sobrenome}
                          required
                          maxLength="40"
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="med_data_nascimento">
                          Data de nascimento:
                          <strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="med_data_nascimento"
                          placeholder="Data de nascimento"
                          onChange={(e) =>
                            setMed_data_nascimento(e.target.value)
                          }
                          value={med_data_nascimento}
                          required
                        />
                      </div>
                      {/* <div className="col-md-3 mb-3">
                        <label htmlFor="med_conselho">
                          Conselho:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          maxLength="100"
                          className="form-control"
                          id="med_conselho"
                          placeholder="Digite o Conselho"
                          onChange={(e) =>
                            setMed_conselho(removeAcento(e.target.value))
                          }
                          value={med_conselho}
                          required
                        />
                      </div>*/}

                      <div className="col-md-3 mb-3">
                        <label htmlFor="med_conselho">
                          Conselho:
                          <strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="med_conselho"
                          value={med_conselho}
                          onChange={handleChangeConselho}
                          options={statusMed_conselho}
                          placeholder={<div>Selecione</div>}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                            width: '10%',
                          }}
                          type="text"
                          id="med_conselho_html5_validate"
                          value={med_conselho.value}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-3 mb-3">
                        <label htmlFor="med_conselho_codigo">
                          Códico do conselho:
                          <strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          maxLength="100"
                          className="form-control"
                          id="med_conselho_codigo"
                          placeholder="Códico do Conselho"
                          onChange={(e) =>
                            setMed_conselho_codigo(removeAcento(e.target.value))
                          }
                          value={med_conselho_codigo}
                          required
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="med_cep">
                          CEP:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="med_cep"
                          maxLength="35"
                          required
                          placeholder="Digite o CEP"
                          onChange={(e) =>
                            setMed_cep(viacep(mask_CEP(e.target.value)))
                          }
                          value={med_cep}
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="med_endereco">
                          Endereço:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="med_endereco"
                          placeholder="Digite seu Endereço"
                          maxLength="150"
                          onChange={(e) =>
                            setMed_endereco(removeAcento(e.target.value))
                          }
                          value={med_endereco}
                          required
                        />
                      </div>{' '}
                      <div className="col-md-3  mb-3">
                        <label htmlFor="med_numero">
                          Número:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="med_numero"
                          placeholder="N°"
                          maxLength="20"
                          onChange={(e) => setMed_numero(e.target.value)}
                          value={med_numero}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-3 mb-3">
                        <label htmlFor="med_bairro">
                          Bairro:<strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="med_bairro"
                          placeholder="Digite o seu Bairro"
                          maxLength="80"
                          required
                          onChange={(e) =>
                            setMed_bairro(removeAcento(e.target.value))
                          }
                          value={med_bairro}
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="cid_uf">
                          Estado / UF:
                          <strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="cid_uf"
                          value={cid_uf}
                          onChange={handleChangeUF}
                          options={statusCid_uf}
                          placeholder={<div>Selecione</div>}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                            width: '10%',
                          }}
                          type="text"
                          id="cid_uf_html5_validate"
                          value={cid_uf}
                          required
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="med_cidade">
                          Cidade:<strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="med_cidade"
                          value={med_cidade}
                          onChange={handleChange}
                          options={statusMed_cidade}
                          placeholder={<div>Selecione o estado antes</div>}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                            width: '10%',
                          }}
                          type="text"
                          id="med_cidade_html5_validate"
                          value={med_cidade}
                          required
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <label htmlFor="med_telefone">Telefone/Celular:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="med_telefone"
                          placeholder="Telefone com DDD"
                          maxLength="20"
                          onChange={(e) =>
                            setMed_telefone(
                              mask_tel_celular_area(e.target.value),
                            )
                          }
                          value={med_telefone}
                        />
                        {
                          // regra para deixar  telefone OBRIGATORIO
                          /* <input
                          style={{
                            top: "43px",
                            position: "absolute",
                            zIndex: "-1",
                          }}
                          type="password"
                          id="med_telefone_html5_validate"
                          
                          value={med_telefone}
                          required
                        />*/
                        }
                      </div>

                      {!med_id && (
                        <>
                          <div className="col-md-3 mb-3">
                            <div className="row">
                              <div
                                className="col-md-12 col-12 col-sm-12"
                                style={{ paddingRight: '0px' }}
                              >
                                <label htmlFor="med_senha">Senha:</label>
                                <div
                                  class="input-group mb-3"
                                  style={{
                                    position: 'relative',
                                    right: '50px',
                                  }}
                                >
                                  <div
                                    class="input-group-prepend"
                                    onClick={(e) =>
                                      (document.getElementById(
                                        'med_senha',
                                      ).type =
                                        document.getElementById('med_senha')
                                          .type == 'text'
                                          ? 'password'
                                          : 'text')
                                    }
                                  >
                                    {' '}
                                    <div class="wrapper-tooltip">
                                      <span
                                        style={{
                                          display: 'inline',
                                          borderRadius: '50px',
                                          background: '#a7c4d2 ',
                                          color: 'white',
                                          position: 'relative',
                                          right: '-37px',
                                          zIndex: '99',
                                        }}
                                        class="input-group-text"
                                        id="basic-addon1"
                                      >
                                        {Eye}
                                      </span>
                                      <div
                                        className="tooltip"
                                        style={{
                                          left: '45px',
                                          width: '280px',
                                        }}
                                      >
                                        Mínimo de 6 e máximo de 12 caracteres,
                                        pelo menos uma letra maiúscula, uma
                                        letra minúscula, um número e um
                                        caractere especial
                                      </div>
                                    </div>{' '}
                                  </div>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="med_senha"
                                    placeholder="Senha"
                                    onChange={(e) =>
                                      setMed_senha(e.target.value)
                                    }
                                    value={med_senha}
                                    maxLength="12"
                                    minLength="6"
                                    required
                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                                    style={{
                                      position: 'absolute',
                                      minWidth: '100%',
                                      marginLeft: '39px',
                                      padding: '7px 29px 7px 52px',
                                      borderRadius: '50px 0px 0px 50px',
                                      borderBottom: '6px solid #a7c4d2 ',
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-3 mb-3">
                        <label htmlFor="med_email">Email:</label>
                        <input
                          type="email"
                          className="form-control"
                          id="med_email"
                          maxLength="100"
                          placeholder="Digite o seu Email"
                          onChange={(e) =>
                            setMed_email(
                              removeEspacos(e.target.value.toLowerCase()),
                            )
                          }
                          value={med_email}
                        />
                      </div>
                      <div className="col-md-2 col-6 mb-3">
                        <label htmlFor="med_status">
                          {med_status === 1 ? (
                            <b className="text-secondary">Status</b>
                          ) : (
                            <b className="text-secondary">Status</b>
                          )}
                        </label>
                        <div className="material-switch   pull-right">
                          <input
                            onClick={(e) => checkboxAtivo()}
                            id="med_status"
                            name="ativo"
                            type="checkbox"
                          />
                          <label
                            htmlFor="med_status"
                            className="badge-primary"
                          ></label>
                        </div>
                      </div>
                      <div className="col-md-12  col-12 mb-3">
                        <label htmlFor="convenio">
                          Convênio:
                          <strong className="fild-required">*</strong>
                        </label>
                        <div>
                          <Select
                            filterOption={multiFilterOption}
                            hideSelectedOptions
                            onInputChange={multiOnInputChange}
                            onChange={multiOnChange}
                            options={statusConvenio}
                            isMulti
                            value={values}
                            placeholder={<div>Selecione</div>}
                          />
                        </div>
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                          }}
                          type="text"
                          id="convenio_html5_validate"
                          value={values}
                          required
                        />
                      </div>

                      <div className="col-md-12 mb-3">
                        <div className="mr-3 mb-3">
                          <label htmlFor="prod_foto">
                            <b>&nbsp;&nbsp;&nbsp;</b>{' '}
                          </label>
                          <br />{' '}
                          <button
                            type="button"
                            className="btn btn-secondary"
                            id="botaoLogotipo"
                            style={{ width: 'fit-content' }}
                            onClick={(e) => handleImageButton(e)}
                          >
                            {ImageUpload} Imagem{' '}
                            {med_img.length > 0 ? (
                              ''
                            ) : (
                              <strong className="fild-required">*</strong>
                            )}
                          </button>
                          <input
                            type="file"
                            name="prod_foto[]"
                            className="form-control-file"
                            accept=".jpg, .jpeg, .png, .webp"
                            id="prod_foto"
                            hidden
                            onChange={(e) => _handleImageChange(e)}
                          />
                        </div>
                        {/*

                        <label htmlFor="prod_foto">
                          <b>Imagens:</b>{' '}
                          {med_img.length > 0 ? (
                            ''
                          ) : (
                            <strong className="fild-required">*</strong>
                          )}
                        </label>
                        <br />{' '}
                        <input
                          type="file"
                          name="prod_foto[]"
                          style={{
                            backgroundColor: '#ecf0f4',
                            border: '0px',
                          }}
                          accept=".jpg, .jpeg, .png, .webp"
                          id="prod_foto"
                          onChange={(e) => _handleImageChange(e)}
                            />*/}{' '}
                      </div>
                      <div className="col-md-12  col-12 mb-3">
                        <output
                          id="list"
                          style={{
                            display: 'flex',

                            flexFlow: 'wrap',
                          }}
                        ></output>{' '}
                      </div>
                      <div className="col-md-12  col-12 mb-3">
                        <div
                          className={med_id ? 'card ' : ' card card-success'}
                        >
                          <div
                            style={{ color: '#063441', padding: '10px' }}
                            className="card-header"
                          >
                            {' '}
                            {Clock} Tempo de expediente e sessão{' '}
                            {AngleDoubleLeft}
                          </div>

                          <div className="card-body">
                            <div className="row mb-3">
                              <div className="col-md-4 col-6 mb-3">
                                <label htmlFor="med_horaini">
                                  Hora Inicial:
                                </label>
                                <input
                                  type="time"
                                  required
                                  className="form-control"
                                  id="med_horaini"
                                  onChange={(e) =>
                                    setMed_horaini(e.target.value)
                                  }
                                  value={med_horaini}
                                />
                              </div>

                              <div className="col-md-4 col-6 mb-3">
                                <label htmlFor="med_horafim">Hora final:</label>
                                <input
                                  type="time"
                                  className="form-control"
                                  id="med_horafim"
                                  required
                                  onChange={(e) =>
                                    setMed_horafim(e.target.value)
                                  }
                                  value={med_horafim}
                                />
                              </div>
                              <div className="col-md-4 col-6 mb-3">
                                <label htmlFor="med_sessao">
                                  Tempo de sessão:
                                </label>
                                <input
                                  type="time"
                                  className="form-control"
                                  id="med_sessao"
                                  required
                                  min="00:05"
                                  max="01:00"
                                  onChange={(e) =>
                                    setMed_sessao(e.target.value)
                                  }
                                  value={med_sessao}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12  col-12 mb-3">
                        <div
                          className={med_id ? 'card ' : ' card card-success'}
                        >
                          <div
                            style={{ color: '#063441', padding: '10px' }}
                            className="card-header"
                          >
                            {' '}
                            {Clock} Escolha os dias do seu atendimento{' '}
                            {AngleDoubleLeft}
                          </div>

                          <div className="card-body">
                            <div className="row mb-3">
                              <div className="col-md-2 col-6 mb-3">
                                <label htmlFor="exp_domingo">
                                  {exp_domingo !== 7 ? (
                                    <b style={{ color: '#74b9e3' }}>Domingo</b>
                                  ) : (
                                    <b className="text-secondary">Domingo</b>
                                  )}
                                </label>
                                <div className="material-switch   pull-right">
                                  <input
                                    onClick={(e) => checkboxDomingo()}
                                    id="exp_domingo"
                                    name="expediente"
                                    type="checkbox"
                                  />
                                  <label
                                    htmlFor="exp_domingo"
                                    className="badge-primary"
                                  ></label>
                                </div>
                              </div>
                              <div className="col-md-2 col-6 mb-3">
                                <label htmlFor="exp_segunda">
                                  {exp_segunda !== 1 ? (
                                    <b style={{ color: '#74b9e3' }}>Segunda</b>
                                  ) : (
                                    <b className="text-secondary">Segunda</b>
                                  )}
                                </label>
                                <div className="material-switch   pull-right">
                                  <input
                                    onClick={(e) => checkboxSegunda()}
                                    id="exp_segunda"
                                    name="expediente"
                                    type="checkbox"
                                  />
                                  <label
                                    htmlFor="exp_segunda"
                                    className="badge-primary"
                                  ></label>
                                </div>
                              </div>
                              <div className="col-md-2 col-6 mb-3">
                                <label htmlFor="exp_terca">
                                  {exp_terca !== 2 ? (
                                    <b style={{ color: '#74b9e3' }}>Terça</b>
                                  ) : (
                                    <b className="text-secondary">Terça</b>
                                  )}
                                </label>
                                <div className="material-switch   pull-right">
                                  <input
                                    onClick={(e) => checkboxTerca()}
                                    id="exp_terca"
                                    name="expediente"
                                    type="checkbox"
                                  />
                                  <label
                                    htmlFor="exp_terca"
                                    className="badge-primary"
                                  ></label>
                                </div>
                              </div>
                              <div className="col-md-2 col-6 mb-3">
                                <label htmlFor="exp_quarta">
                                  {exp_quarta !== 3 ? (
                                    <b style={{ color: '#74b9e3' }}>Quarta</b>
                                  ) : (
                                    <b className="text-secondary">Quarta</b>
                                  )}
                                </label>
                                <div className="material-switch   pull-right">
                                  <input
                                    onClick={(e) => checkboxQuarta()}
                                    id="exp_quarta"
                                    name="expediente"
                                    type="checkbox"
                                  />
                                  <label
                                    htmlFor="exp_quarta"
                                    className="badge-primary"
                                  ></label>
                                </div>
                              </div>
                              <div className="col-md-2 col-6 mb-3">
                                <label htmlFor="exp_quinta">
                                  {exp_quinta !== 4 ? (
                                    <b style={{ color: '#74b9e3' }}>Quinta</b>
                                  ) : (
                                    <b className="text-secondary">Quinta</b>
                                  )}
                                </label>
                                <div className="material-switch   pull-right">
                                  <input
                                    onClick={(e) => checkboxQuinta()}
                                    id="exp_quinta"
                                    name="expediente"
                                    type="checkbox"
                                  />
                                  <label
                                    htmlFor="exp_quinta"
                                    className="badge-primary"
                                  ></label>
                                </div>
                              </div>
                              <div className="col-md-2 col-6 mb-3">
                                <label htmlFor="exp_sexta">
                                  {exp_sexta !== 5 ? (
                                    <b style={{ color: '#74b9e3' }}>Sexta</b>
                                  ) : (
                                    <b className="text-secondary">Sexta</b>
                                  )}
                                </label>
                                <div className="material-switch   pull-right">
                                  <input
                                    onClick={(e) => checkboxSexta()}
                                    id="exp_sexta"
                                    name="expediente"
                                    type="checkbox"
                                  />
                                  <label
                                    htmlFor="exp_sexta"
                                    className="badge-primary"
                                  ></label>
                                </div>
                              </div>
                              <div className="col-md-2 col-6 mb-3">
                                <label htmlFor="exp_sabado">
                                  {exp_sabado !== 6 ? (
                                    <b style={{ color: '#74b9e3' }}>Sabado</b>
                                  ) : (
                                    <b className="text-secondary">Sabado</b>
                                  )}
                                </label>
                                <div className="material-switch   pull-right">
                                  <input
                                    onClick={(e) => checkboxSabado()}
                                    id="exp_sabado"
                                    name="expediente"
                                    type="checkbox"
                                  />
                                  <label
                                    htmlFor="exp_sabado"
                                    className="badge-primary"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*
                      <div className="col-md-12  col-12 mb-3">
                        <div
                          className={med_id ? 'card ' : ' card card-success'}
                        >
                          <div
                            style={{ color: '#063441', padding: '10px' }}
                            className="card-header"
                          >
                            {' '}
                            {UserClock} Cadastro de Secretária {AngleDoubleLeft}
                          </div>

                          <div className="card-body">
                            <div className="row mb-3">
                              <div className="col-md-6 col-12 mb-3">
                                <label htmlFor="secretaria_email">Email:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="secretaria_email"
                                  placeholder="Digite o email da secretaria"
                                  onChange={(e) =>
                                    setSecretaria_email(e.target.value)
                                  }
                                  value={secretaria_email}
                                />
                              </div>

                              <div className=" col-md-6 col-12 mb-3">
                                <label
                                  for="secretaria_senha"
                                  style={{ width: '100%', color: '#7c7f82' }}
                                >
                                  Senha:
                                </label>
                                <div
                                  class="input-group mb-3"
                                  style={{
                                    position: 'relative',
                                    right: '50px',
                                  }}
                                >
                                  <div
                                    class="input-group-prepend"
                                    onClick={(e) =>
                                      (document.getElementById(
                                        'secretaria_senha',
                                      ).type =
                                        document.getElementById(
                                          'secretaria_senha',
                                        ).type == 'text'
                                          ? 'password'
                                          : 'text')
                                    }
                                  >
                                    {' '}
                                    <div class="wrapper-tooltip">
                                      <span
                                        style={{
                                          display: 'inline',
                                          borderRadius: '50px',
                                          background: '#a7c4d2 ',
                                          color: 'white',
                                          position: 'relative',
                                          right: '-37px',
                                          zIndex: '99',
                                        }}
                                        class="input-group-text"
                                        id="basic-addon1"
                                      >
                                        {Eye}
                                      </span>
                                      <div
                                        className="tooltip"
                                        style={{
                                          left: '45px',
                                          width: '280px',
                                        }}
                                      >
                                        Mínimo de 6 e máximo de 12 caracteres,
                                        pelo menos uma letra maiúscula, uma
                                        letra minúscula, um número e um
                                        caractere especial
                                      </div>
                                    </div>{' '}
                                  </div>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="secretaria_senha"
                                    maxLength="12"
                                    minLength="6"
                                    placeholder="Senha"
                                    onChange={(e) =>
                                      setSecretaria_senha(e.target.value)
                                    }
                                    value={secretaria_senha}
                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                                    style={{
                                      position: 'absolute',
                                      minWidth: '100%',
                                      marginLeft: '39px',
                                      padding: '7px 29px 7px 52px',
                                      borderRadius: '50px 0px 0px 50px',
                                      borderBottom: '6px solid #a7c4d2 ',
                                    }}
                                  />
                                </div>
                                {/*   <label
                                  for="secretaria_senha"
                                  style={{ width: '100%', color: '#7c7f82' }}
                                >
                                  Senha:
                                </label>

                                <input
                                  type="password"
                                  className="form-control"
                                  id="secretaria_senha"
                                  maxLength="12"
                                  minLength="6"
                                  placeholder="Senha"
                                  onChange={(e) =>
                                    setSecretaria_senha(e.target.value)
                                  }
                                  value={secretaria_senha}
                                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                                />
                                <div
                                  className="input-group-prepend"
                                  style={{ width: '47px', height: '38px' }}
                                >
                                  <div
                                    onClick={(e) =>
                                      (document.getElementById(
                                        'secretaria_senha',
                                      ).type =
                                        document.getElementById(
                                          'secretaria_senha',
                                        ).type == 'text'
                                          ? 'password'
                                          : 'text')
                                    }
                                    className="input-group-text "
                                  >
                                    <div className="wrapper-tooltip">
                                      <span className="icons">{Eye}</span>
                                      <div
                                        className="tooltip"
                                        style={{
                                          left: '-244px',
                                          width: '280px',
                                        }}
                                      >
                                        Mínimo de 6 e máximo de 12 caracteres,
                                        pelo menos uma letra maiúscula, uma
                                        letra minúscula, um número e um
                                        caractere especial
                                      </div>
                                    </div>
                                  </div>
                                </div>
                           */
                      /*}{' '}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                   */}
                    </div>

                    <div className="text-left mb-3 mt-3 ">
                      {med_id ? (
                        <>
                          <button
                            className="btn btn-sm btn_Cadastrar "
                            type="submit"
                            id="btnAlterar"
                          >
                            {Edit} Alterar
                          </button>
                        </>
                      ) : (
                        <button
                          className="btn btn-sm btn_Cadastrar "
                          type="submit"
                          id="btnCadastrarMedico"
                        >
                          {Save} Cadastrar
                        </button>
                      )}{' '}
                      &nbsp;&nbsp;{' '}
                      <button
                        className="btn btn-sm btn-secondary "
                        onClick={(e) => limpar(e)}
                        type="button"
                        id="btn_limpar"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        {Times} Fechar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            {' '}
            {BriefcaseMedical} Lista de Médicos {AngleDoubleLeft}{' '}
          </div>

          <div className="card-body ">
            <form>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      {/*
                      <div
                        className="col-md-10 col-12 col-sm-12"
                        style={{ paddingRight: '0px' }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <div
                          className="input-group mb-3"
                          style={{ position: 'relative', right: '50px' }}
                          onClick={(e) => buscar()}
                        >
                          <div className="input-group-prepend">
                            <span
                              style={{
                                display: 'inline',
                                borderRadius: '50px',
                                background: '#a7c4d2 ',
                                color: 'white',
                                position: 'relative',
                                right: '-37px',
                                zIndex: '99',
                              }}
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              {Search}
                            </span>
                          </div>
                          <input
                            id="filtro"
                            className="form-control"
                            style={{
                              display: 'inline',
                              padding: '7px 29px 7px 52px',
                              borderRadius: '50px 0px 0px 50px',
                              borderBottom: '6px solid #a7c4d2 ',
                            }}
                            type="text"
                            onChange={(e) =>
                              setBusca(removeAcento(e.target.value))
                            }
                            value={busca}
                          />
                        </div>
                       
                      </div>
 */}
                    </div>
                  </div>

                  <div className="col-md-2">
                    {/*   <label htmlFor="btn_cadastar">&nbsp;&nbsp;&nbsp;</label>
                    <button
                      type="button"
                      className="btn btn_Cadastrar btn-block "
                      id="btn_cadastar"
                      onClick={(e) => limpar(e)}
                      data-toggle="modal"
                      data-target=".bd-example-modal-xl"
                    >
                      {Save} Cadastrar
                    </button>*/}
                  </div>
                  <div className="col-md-2">
                  
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </div>
            </form>

            <div className="table-responsive">
              <table className="table table-hover table-striped table-light">
                <thead className="text-white bg-dark">
                  <tr>
                    <th style={{ width: '95%' }} scope="col">
                      Médico
                    </th>
                    {/* <th scope="col">Proprietario</th>
                    <th scope="col">Endereço</th>
                    <th scope="col">Cidade</th>
                      <th scope="col">Status</th>*/}
                    <th style={{ width: '5%' }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {medicosPag.length > 0 &&
                    medicosPag.map((medico, i) => {
                      var convenio_nome = medico.nome_convenio.split(',');

                      var Convenio = [];
                      for (let i = 0; i < convenio_nome.length; i++) {
                        Convenio.push({
                          convenio: RemoveAspasChaves(convenio_nome[i]),
                        });
                      }
                      return (
                        <>
                          <tr key={medico.med_id}>
                            <th>
                              {medico.med_nome?.toUpperCase() +
                                ' ' +
                                medico.med_sobrenome?.toUpperCase()}
                              <br />
                              <i
                                style={{
                                  fontSize: '12px',
                                }}
                              >
                                {medico.conselho_sigla +
                                  ' ' +
                                  medico.med_conselho_codigo}

                                <br />
                                {Convenio.map(function (dist, i) {
                                  return (
                                    <>
                                      <span className="text-lowercase">
                                        {dist.convenio}
                                      </span>
                                      <br />
                                    </>
                                  );
                                })}

                                {medico.med_status === 1 ? (
                                  <>
                                    <span className="color-51comvc text-lowercase">
                                      Ativo
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    <span className="text-lowercase ">
                                      Inativo
                                    </span>{' '}
                                  </>
                                )}
                              </i>
                            </th>

                            <td>
                              <img
                                src="alterar.png"
                                onClick={(e) => editar(medico)}
                                className="btn-acao mb-2 mr-2"
                                data-toggle="modal"
                                data-target=".bd-example-modal-xl"
                              />
                              {/* <img
                                src="excluir.png"
                                onClick={(e) =>
                                  dadosModal(
                                    medico.med_nome,
                                    medico.med_id,
                                    medico.med_status,
                                  )
                                }
                                className="btn-acao mb-2 mr-2"
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                              />
                              */}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Registros por página'}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card card-red ">
              <div className="card-header ">{Trash} Excluir Médico</div>

              <div className="card-body">
                Tem certeza que deseja excluir o Médico
                <i>
                  <b className="text-danger">"</b>
                  {nomeModal}
                  <b className="text-danger">"</b>
                </i>{' '}
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-sm btn-secondary "
                  type="button"
                  data-dismiss="modal"
                >
                  {Times}
                  &nbsp;Cancelar
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-sm btn_excluir "
                  onClick={(e) => save(e, 'delete', 0)}
                  type="button"
                  data-dismiss="modal"
                >
                  {Trash} Excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade bd-example-modal-md"
        tabIndex="-1"
        id="modalConvenio"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <ConvenioCad
              arrayconvenio={statusConvenio}
              atualizarselect={setStatusConvenio}
              autoselecionarselect={setValues}
              arrayconveniovalues={values}
            />{' '}
          </div>
        </div>
      </div>{' '}
      <button
        type="button"
        className="btn  btn-success btn-block "
        id="btn_Cadastrar"
        data-toggle="modal"
        hidden
        data-target=".bd-example-modal-md"
      >
        Cadastrar
      </button>
    </>
  );
};
export default Medico;
