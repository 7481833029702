import React from "react";

const Footer = () => {



  return (
    <>
      <footer className="footer fixar-rodape bg-dark">
        <div className="container">
          <p className="text-light text-center">
            {" "}
            &copy;{new Date().getFullYear()} -{" "}
            <a href="http://viaconect.com" style={{color:'#fff'}} target="_blank">
              Viaconect{" "}
            </a>
            - All Rights Reserved
          </p>{" "}
        </div>
      </footer>
    </>
  );
};
export default Footer;
