import React, { useState, useEffect, useContext, useRef } from 'react';
import Select from 'react-select';
import FullCalendar, { formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptbrLocale from '@fullcalendar/core/locales/pt-br';
import { api } from '../utils/WebApiConfig';
import axios from 'axios';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import {
  Times,
  CalendarAlt,
  Edit,
  Save,
  Trash,
  AngleDoubleLeft,
  Search,
} from '../utils/Fontawesome';
import PacienteCad from './PacienteCad';
import { capitalize, removeAcento } from '../utils/Mascara';

export const adicionaZero = (numero) => {
  if (numero <= 9) return '0' + numero;
  else return numero;
};

export const adicionaZeroMinutes = (numero) => {
  if (numero === 0) return '0' + numero;
  else return numero;
};

const Consulta = () => {
  let history = useHistory();
  let tooltip = null;
  window.start = '';
  window.end = '';
  const calendarRef = useRef();
  const [displayPacienteCad, setDisplayPacienteCad] = useState('none');
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setcurrentEvents] = useState([]);
  const [cons_avisar_zap, setCons_avisar_zap] = useState('');
  const [buscarPaciente, setBuscarPaciente] = useState('');
  const [med_horaini, setMed_horaini] = useState('');
  const [med_horafim, setMed_horafim] = useState('');
  const [med_sessao, setMed_sessao] = useState('');
  const [cons_codigo, setCons_codigo] = useState('');
  const [cons_data, setCons_data] = useState('');
  const [med_exp_semanal, setMed_exp_semanal] = useState();

  const [cons_hora, setCons_hora] = useState('');
  const [cons_medico, setCons_medico] = useState([]);
  const [statusCons_medico, setStatusCons_medico] = useState([]);
  const [cons_paciente, setCons_paciente] = useState('');
  const [statusCons_paciente, setStatusCons_paciente] = useState([]);
  const [cons_convenio, setCons_convenio] = useState('');
  const [pac_celular, setPac_celular] = useState('');
  const [pac_fone, setPac_fone] = useState('');
  const [statusCons_hora, setStatusCons_hora] = useState([
    { value: 0, label: 'Carregando...' },
  ]);
  const [statusCons_convenio, setStatusCons_convenio] = useState('');
  const [cons_procedimento, setCons_procedimento] = useState('');
  const [cons_texto, setCons_texto] = useState('');
  const [cons_tipo, setCons_tipo] = useState('');
  const [statusCons_tipo, setStatusCons_tipo] = useState([
    { value: 'C', label: 'Consulta' },
    { value: 'P', label: '1. Consulta' },
    { value: 'R', label: 'Retorno' },
    { value: 'T', label: 'Receita' },
    { value: 'E', label: 'Exame' },
    { value: 'A', label: 'Ausente' },
  ]);

  const [cons_status, setCons_status] = useState();
  const [statusCons_status, setStatusCons_status] = useState([
    { value: 0, label: 'Agendado' },
    { value: 1, label: 'Aguardando' },
    { value: 4, label: 'Faltou ' },
  ]);

  const [consultaEvents, setConsultaEvents] = useState([]);
  const [, forceUpdate] = useState();

  const [secretaria_senha, setSecretaria_senha] = useState('');
  const [secretaria_email, setSecretaria_email] = useState('');

  const [medicos, setMedicos] = useState([]);
  const [idModal, setIdModal] = useState('');
  const [nomeModal, setNomeModal] = useState('');
  const [idStatusModal, setStatusModal] = useState('');

  const dadosModal = (nome, id, status) => {
    setNomeModal(nome);
    setIdModal(id);
    setStatusModal(status);
  };

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    } else {
      console.log('nao tem');
    }

    GetFullCalendarAtualizar();
    getMedico();
    getConvenio();
  }, []);
  const GetFullCalendarAtualizar = async () => {
    let calendarApi = calendarRef.current.getApi();
    let data = new Date(
      calendarApi.currentDataManager.data.dateProfile.activeRange.end,
    );
    let dataEnd =
      data.getFullYear() +
      '-' +
      (data.getMonth() + 1 < 10
        ? '0' + (data.getMonth() + 1)
        : data.getMonth() + 1) +
      '-' +
      (data.getDate() < 10 ? '0' + data.getDate() : data.getDate());

    let start = new Date(
      calendarApi.currentDataManager.data.dateProfile.activeRange.start,
    );
    let dataStart =
      start.getFullYear() +
      '-' +
      (start.getMonth() + 1 < 10
        ? '0' + (start.getMonth() + 1)
        : start.getMonth() + 1) +
      '-' +
      (start.getDate() < 10 ? '0' + start.getDate() : start.getDate());
    console.log(dataStart + ' a ' + dataEnd);

    getConsulta(sessionStorage.getItem('med_id'), dataStart, dataEnd);
  };
  const GetFullCalendar = async () => {
    let calendarApi = calendarRef.current.getApi();
    let data = new Date(
      calendarApi.currentDataManager.data.dateProfile.activeRange.end,
    );
    let dataEnd =
      data.getFullYear() +
      '-' +
      (data.getMonth() + 1 < 10
        ? '0' + (data.getMonth() + 1)
        : data.getMonth() + 1) +
      '-' +
      (data.getDate() < 10 ? '0' + data.getDate() : data.getDate());

    let start = new Date(
      calendarApi.currentDataManager.data.dateProfile.activeRange.start,
    );
    let dataStart =
      start.getFullYear() +
      '-' +
      (start.getMonth() + 1 < 10
        ? '0' + (start.getMonth() + 1)
        : start.getMonth() + 1) +
      '-' +
      (start.getDate() < 10 ? '0' + start.getDate() : start.getDate());
    console.log(dataStart + ' a ' + dataEnd);
    if (
      sessionStorage.getItem('dataStart') !== dataStart &&
      sessionStorage.getItem('dataEnd') !== dataEnd
    ) {
      sessionStorage.setItem('dataStart', dataStart);
      sessionStorage.setItem('dataEnd', dataEnd);
      getConsulta(sessionStorage.getItem('med_id'), dataStart, dataEnd);
    }
  };

  useEffect(() => {
    GetFullCalendar();
  }, [
    document.querySelectorAll(
      '.fc-prev-button, .fc-next-button, .fc-today-button',
    ),
  ]);
  useEffect(() => {
    let horarios = document.getElementsByClassName(
      'fc-timegrid-slot-label-cushion',
    );
    let hora;
    for (let i = 0; i < horarios.length; i++) {
      hora = horarios[i].innerText + ':00';
      horarios[i].innerText = hora.substring(0, 5);
    }
  }, [consultaEvents]);

  const getConsulta = (medico_id, dataStart, dataEnd) => {
    let parametro =
      '?start=' +
      dataStart +
      '&end=' +
      dataEnd +
      '&secretaria=' +
      sessionStorage.getItem('secretaria_id');
    if (medico_id) {
      parametro =
        '?start=' + dataStart + '&end=' + dataEnd + '&medico=' + medico_id;
    }

    if (sessionStorage.getItem('cargo') === 'M') {
      parametro =
        '?start=' +
        dataStart +
        '&end=' +
        dataEnd +
        '&medico=' +
        sessionStorage.getItem('med_id');
    }

    api
      .get('api/consultas' + parametro, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          const arrayConsultas = [];

          Date.prototype.addMinutos = function (minutos) {
            this.setMinutes(this.getMinutes() + minutos);
          };

          Date.prototype.addHoras = function (horas) {
            this.setHours(this.getHours() + horas);
          };

          const arrayData = [];
          var datas =
            response.data[0].cons_data.substring(0, 10) +
            ' ' +
            response.data[0].med_horaini;
          var datafim =
            response.data[0].cons_data.substring(0, 10) +
            ' ' +
            response.data[0].med_horafim;
          var medSessao = response.data[0].med_sessao.substring(3, 5);

          var dataAtual = '';
          arrayData.unshift({
            value: datas.substring(11, 16),
            label: datas.substring(11, 16),
          });
          for (let i = 0; i < 100; i++) {
            if (datas <= datafim) {
              dataAtual = new Date(datas); //29/01/2020
              dataAtual.addMinutos(parseInt(medSessao));
              datas =
                dataAtual.getFullYear() +
                '-' +
                adicionaZero(dataAtual.getMonth() + 1).toString() +
                '-' +
                adicionaZero(dataAtual.getDate().toString()) +
                ' ' +
                dataAtual.getHours() +
                ':' +
                adicionaZeroMinutes(dataAtual.getMinutes());
              arrayData.push({
                value: datas.substring(11, 16),
                label: datas.substring(11, 16),
              });
            } else {
              arrayData.pop();
              break;
            }
          }
          setStatusCons_hora(arrayData);

          for (let i = 0; i < response.data.length; i++) {
            let celular = '';

            if (response.data[i].pac_celular) {
              celular = ' ' + response.data[i].pac_celular;
            }

            var statusCor = '';
            var tipo_consulta = '';

            switch (response.data[i].cons_tipo) {
              case 'C':
                tipo_consulta = 'Consulta';
                break;
              case 'P':
                tipo_consulta = '1. Consulta';
                break;
              case 'R':
                tipo_consulta = 'Retorno';
                break;
              case 'T':
                tipo_consulta = 'Receita';
                break;
              case 'E':
                tipo_consulta = 'Exame';
                break;
              case 'A':
                tipo_consulta = 'Ausente';
                break;
            }
            switch (response.data[i].cons_status) {
              case 1:
                // paciente em espera no consultorio
                statusCor = '#2196f3';
                break;
              case 2:
                // atendimento em andamento
                statusCor = '#fec107';
                break;
              case 3:
                // atendimento concluido
                statusCor = '#01a777';
                break;
              case 4:
                // atendimento concluido
                statusCor = '#eb2d2d';
                break;

              default:
                // apenas agendado
                statusCor = '#aa528c';
            }
            arrayConsultas.push({
              id: response.data[i].cons_codigo,
              title: '⠀' + response.data[i].pac_nome,
              color: statusCor,
              start: response.data[i].cons_data,
              extendedProps: {
                description:
                  ' ' +
                  response.data[i].pac_nome +
                  celular +
                  ' | ' +
                  response.data[i].conv_nome +
                  ' | ' +
                  tipo_consulta,
                cons_codigo: response.data[i].cons_codigo,
                cons_status: response.data[i].cons_status,
                cons_tipo: response.data[i].cons_tipo,
                cons_status: response.data[i].cons_status,
                cons_medico: response.data[i].cons_medico,
                med_nome: response.data[i].med_nome,
                cons_paciente: response.data[i].cons_paciente,
                pac_nome: response.data[i].pac_nome,
                cons_convenio: response.data[i].cons_convenio,
                conv_nome: response.data[i].conv_nome,
                cons_data: response.data[i].cons_data,
                med_horafim: response.data[i].med_horafim,
                med_horaini: response.data[i].med_horaini,
                med_sessao: response.data[i].med_sessao,
                pac_celular: response.data[i].pac_celular,
                pac_fone: response.data[i].pac_fone,
                cons_avisar_zap: response.data[i].cons_avisar_zap,
              },
            });
          }

          setConsultaEvents(arrayConsultas);
        } else {
          setConsultaEvents([]);
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getConvenio = () => {
    api
      .get('api/convenios?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayConvenios = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayConvenios.push({
            value: response.data[i].conv_id,
            label:
              response.data[i].conv_id + ' - ' + response.data[i].conv_nome,
          });
        }

        setStatusCons_convenio(arrayConvenios);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const cancelarPaciente = () => {
    setStatusCons_paciente([]);
    setBuscarPaciente('');
    setCons_paciente('');
    document.getElementById('select_paciente').style.display = 'none';
    document.getElementById('buscar_paciente').style.display = '';
  };
  const getPaciente = (termo, medico) => {
    if (!termo || termo.length < 3) {
      return false;
    }
    //   medico = medico ? medico : sessionStorage.getItem('med_id');
    let url = 'api/pacientes?medico=' + medico + '&pac_nome=' + termo;

    api
      .get(url, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayPacientes = [];

        arrayPacientes.push({
          value: 'CADASTRAR',
          label: 'CADASTRAR NOVO PACIENTE',
        });
        for (let i = 0; i < response.data.length; i++) {
          arrayPacientes.push({
            value: response.data[i].pac_id,
            label: response.data[i].pac_id + ' - ' + response.data[i].pac_nome,
            convenio: response.data[i].pac_convenio,
          });
        }

        setStatusCons_paciente(arrayPacientes);
        if (response.data.length > 0) {
          document.getElementById('buscar_paciente').style.display = 'none';
          document.getElementById('select_paciente').style.display = '';
          if (document.getElementById('cons_paciente')) {
            document.querySelector(
              '#cons_paciente > div > div.css-1hwfws3 > div.css-1wa3eu0-placeholder > div',
            ).style.marginLeft = '35px';
            setDisplayPacienteCad('none');
          }
        } else {
          handleInfo('Nenhum paciente encontrado por esse termo');
          document.getElementById('buscar_paciente').style.display = '';
          document.getElementById('select_paciente').style.display = 'none';
          setDisplayPacienteCad('block');
        }
      })
      .catch((error) => {
        document.getElementById('buscar_paciente').style.display = '';
        document.getElementById('select_paciente').style.display = 'none';
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getMedico = async () => {
    let parametro = '?secretaria=' + sessionStorage.getItem('secretaria_id');
    if (sessionStorage.getItem('cargo') === 'M') {
      parametro = '?medico=' + sessionStorage.getItem('med_id');
    }
    await api
      .get('api/medicos' + parametro, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayMedicos = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayMedicos.push({
            value: response.data[i]?.med_id,
            label: capitalize(
              response.data[i]?.med_nome +
                ' ' +
                response.data[i]?.med_sobrenome,
            ),
            med_horaini: response.data[i]?.med_horaini,
            med_horafim: response.data[i]?.med_horafim,
            med_sessao: response.data[i]?.med_sessao,
          });
        }

        setStatusCons_medico(arrayMedicos);

        if (response.data.length === 1) {
          setCons_medico({
            value: response.data[0].med_id,
            label: capitalize(
              response.data[0].med_nome + ' ' + response.data[0].med_sobrenome,
            ),
          });

          setMed_horaini(response.data[0].med_horaini);
          setMed_horafim(response.data[0].med_horafim);
          setMed_sessao(response.data[0].med_sessao);

          let exp_semanal = String(response.data[0].med_exp_semanal);
          const chars = exp_semanal.split('');
          const array_exp_semanal = [];
          for (let i = 0; i < chars.length; i++) {
            if (chars[i] == 7) {
              array_exp_semanal.push(0);
            } else {
              array_exp_semanal.push(parseInt(chars[i]));
            }
          }
          setMed_exp_semanal(array_exp_semanal);
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const checkboxStatus = () => {
    if (cons_status === 1) {
      setCons_status(0);
    } else {
      setCons_status(1);
    }
  };

  const handleChangeHora = (hora) => {
    setCons_hora(hora);
  };
  const handleChangePaciente = (paciente) => {
    if (paciente?.value === 'CADASTRAR') {
      setDisplayPacienteCad('block');
      return false;
    }
    setDisplayPacienteCad('none');
    setCons_paciente(paciente);

    let convenio = statusCons_convenio.filter(function (obj) {
      if (paciente.convenio == obj.value) {
        return obj.label;
      }
    });

    setCons_convenio(convenio[0]);
  };

  const handleChangeConvenio = (convenio) => {
    setCons_convenio(convenio);
  };

  const handleChangeMedico = (medico) => {
    setCons_medico(medico);
    // getConsulta(medico.value);
    sessionStorage.setItem('med_id', medico.value);
    GetFullCalendarAtualizar();

    // getPaciente(medico.value);
    setMed_horaini(medico.med_horaini);
    setMed_horafim(medico.med_horafim);
    setMed_sessao(medico.med_sessao);
  };

  const handleChangeTipo = (tipo) => {
    setCons_tipo(tipo);
  };

  const RemoveAspasChaves = (value) => {
    value = value.replace(/"/gi, '');
    value = value.replace('}', '');
    value = value.replace('{', '');
    return value;
  };

  const save = (e, method, status) => {
    e.preventDefault();
    e.stopPropagation();

    let data, url;
    if (!cons_medico.value) {
      handleInfo('Selecione um médico');
      return false;
    }
    if (method === 'delete') {
      method = 'delete';
    } else {
      if (!cons_codigo) {
        method = 'post';
      } else {
        method = 'put';
      }
    }

    if (method === 'put') {
      data = {
        cons_codigo: cons_codigo,
        cons_data: cons_data + 'T' + cons_hora.value + ':00',
        cons_medico: cons_medico.value,
        cons_paciente: cons_paciente.value,
        cons_convenio: cons_convenio.value,
        cons_tipo: cons_tipo.value,
        cons_avisar_zap: cons_avisar_zap,
        cons_status: cons_status,
      };

      url = 'api/consultas/' + cons_codigo;
    } else if (method === 'post') {
      data = {
        cons_data: cons_data + 'T' + cons_hora.value + ':00',
        cons_medico: cons_medico.value,
        cons_paciente: cons_paciente.value,
        cons_convenio: cons_convenio.value,
        cons_tipo: cons_tipo.value,
        cons_status: cons_status,
      };
      url = 'api/consultas';
    } else if (method === 'delete') {
      //    url = url + "/" + idModal + "/" + status;
      url = 'api/consultas/' + idModal;
      data = {
        id: idModal,
        status: status,
      };
      setNomeModal('');
      setIdModal('');
    }

    //   return false;
    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }

        GetFullCalendarAtualizar();
        // getConsulta();
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const sendWhatsapp = async (codigo) => {
    let data = {
      cons_codigo: codigo ? codigo : cons_codigo,
      user_senha: sessionStorage.getItem('senha'),
      user_id:
        sessionStorage.getItem('cargo') === 'M'
          ? sessionStorage.getItem('med_email')
          : sessionStorage.getItem('secretaria_email'),
    };
    await axios
      .post('https://tcdoc.viaconect.com/api/twilio/send/', data)
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        // handleSuccess('Enviado com sucesso !');
      })
      .catch((error) => {
        //  catchResponse();
        catchResponse(error.response.status);
        console.log(error);
      });
  };
  const limpar = () => {
    setCons_codigo('');
    setCons_data('');
    setCons_hora('');
    //setCons_medico("");
    setCons_paciente('');
    setCons_convenio('');
    setCons_procedimento('');
    setCons_texto('');
    setCons_tipo('');
    setBuscarPaciente('');
    setCons_status(0);
    document.getElementById('buscar_paciente').style.display = '';
    document.getElementById('select_paciente').style.display = 'none';
  };
  const contatoZap = (contato_zap) => {
    if (contato_zap.length > 0) {
      contato_zap = contato_zap.replace('-', '');
      contato_zap = contato_zap.replace(' ', '');
      contato_zap = contato_zap.replace('(', '');
      contato_zap = contato_zap.replace(')', '');
      return contato_zap;
    }
  };
  // actions
  /*const handleMouseEnter = (info) => {
    
    if (info.event.extendedProps.description) {
      tooltipInstance = new window.Tooltip(info.el, {
        title: info.event.extendedProps.description,
        html: true,
        placement: 'top',
        trigger: 'hover',
        container: 'body',
      });

      tooltipInstance.show();
    }
  };*/
  /* const handleMouseEnter = (info) => {
    info.el.popup({
      observeChanges: false,
      html:
        '<div className="ui top label grey attached"><strong>Pop Up</strong></div><br>' +
        info.event.extendedProps.description,
    });
  };*/

  const handleMouseEnter = (info) => {
    tooltip = new Tooltip(info.el, {
      title: info.event.extendedProps.description,
      content: info.event.extendedProps.description,
      placement: 'top',
      trigger: 'hover',
      container: 'body',
    });
    tooltip.show();
  };

  const handleMouseLeave = (info) => {
    if (tooltip) {
      tooltip.dispose();
      tooltip = null;
    }
  };
  const renderSidebar = () => {
    return (
      <div className="demo-app-sidebar">
        {/* <div className='demo-app-sidebar-section'>
          <h2>Instructions</h2>
          <ul>
            <li>Select dates and you will be prompted to create a new event</li>
            <li>Drag, drop, and resize events</li>
            <li>Click an event to delete it</li>
          </ul>
        </div>*/}
        <div className="row demo-app-sidebar-section">
          <div className="col-md-4 mb-3">
            <form autoComplete="off">
              <label htmlFor="cons_medico_consulta">Selecione um Médico</label>
              <Select
                id="cons_medico_consulta"
                value={cons_medico}
                onChange={handleChangeMedico}
                options={statusCons_medico}
                placeholder={<div>Selecione</div>}
              />
            </form>
            <input
              style={{
                top: '43px',
                position: 'absolute',
                zIndex: '-1',
                width: '10%',
              }}
              type="password"
              id="cons_medico_html5_validate"
              value={cons_medico}
              required
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>&nbsp;&nbsp;&nbsp;&nbsp; </label>
            <button
              className="btn btn-toggleweekends  btn-block "
              style={{ width: '100%' }}
              onClick={(e) => history.push('/comunicado')}
            >
              Comunicar
            </button>
          </div>
          <div className="col-md-3 mb-3">
            {' '}
            <label>&nbsp;&nbsp;&nbsp;&nbsp; </label>
            <button
              className="btn btn-toggleweekends  btn-block "
              style={{ width: '100%' }}
              onClick={(e) => history.push('/filaEspera')}
            >
              Fila de espera
            </button>
          </div>
        </div>
        {/*<div className='demo-app-sidebar-section'>
          <h2>All Events ({currentEvents.length})</h2>
          <ul>
            {currentEvents.map(renderSidebarEvent)}
          </ul>
      </div>*/}
      </div>
    );
  };
  const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible);
  };

  const handleDateSelect = (selectInfo, el) => {
    //  let title = prompt('Please enter a new title for your event')
    if (tooltip) {
      tooltip.dispose();
      tooltip = null;
    }
    let calendarApi = selectInfo.view.calendar;
    limpar();
    //  let extendedProps = selectInfo.event.extendedProps.conv_nome
    // let extendedProps2 = selectInfo.extendedProps.conv_nome
    setDisplayPacienteCad('none');
    document.getElementById('modal-agendamento').click();

    setCons_data(selectInfo.startStr.substring(0, 10));
    setCons_hora({
      value: selectInfo.startStr.substring(11, 16),
      label: selectInfo.startStr.substring(11, 16),
    });
    horarioLivre(
      selectInfo.startStr,
      med_horaini,
      med_horafim,
      med_sessao,
      cons_medico,
    );
    // console.log(selectInfo.startStr);
    //console.log(selectInfo.endStr);

    calendarApi.unselect(); // clear date selection
    /*  let title;
    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      })
    }*/
  };

  const horarioLivre = (
    cons_data,
    med_horaini,
    med_horafim,
    med_sessao,
    cons_medico,
  ) => {
    /* console.log('cons_data ' + JSON.stringify(cons_data));
    console.log('med_horaini ' + med_horaini);
    console.log('med_horafim ' + med_horafim);
    console.log('med_sessao ' + med_sessao);
    console.log('cons_medico ' + cons_medico);*/

    Date.prototype.addMinutos = function (minutos) {
      this.setMinutes(this.getMinutes() + minutos);
    };

    Date.prototype.addHoras = function (horas) {
      this.setHours(this.getHours() + horas);
    };

    const arrayData = [];
    var datas = cons_data.substring(0, 10) + ' ' + med_horaini;
    var datafim = cons_data.substring(0, 10) + ' ' + med_horafim;

    var consultaData = cons_data?.substring(0, 10);

    var dataAtual = '';

    var medSessao_minutos = parseInt(med_sessao?.substring(3, 5));
    var medSessao_horas = parseInt(med_sessao?.substring(0, 2));
    var medSessao = medSessao_horas * 60 + medSessao_minutos;

    arrayData.unshift({
      value: datas.substring(11, 16),
      label: datas.substring(11, 16),
    });
    /*  console.log('datas ' + JSON.stringify(datas));
    console.log('datafim ' + JSON.stringify(datafim));
    console.log('dataAtual ' + JSON.stringify(dataAtual));
    console.log('consultaData ' + JSON.stringify(consultaData));
    console.log('medSessao_minutos ' + JSON.stringify(medSessao_minutos));
    console.log('medSessao_horas ' + JSON.stringify(medSessao_horas));*/
    for (let i = 0; i < 100; i++) {
      if (datas <= datafim) {
        dataAtual = new Date(datas); //29/01/2020
        dataAtual.addMinutos(parseInt(medSessao));
        //   console.log('dataAtual ' + JSON.stringify(dataAtual));
        datas =
          dataAtual.getFullYear() +
          '-' +
          adicionaZero(dataAtual.getMonth() + 1).toString() +
          '-' +
          adicionaZero(dataAtual.getDate().toString()) +
          ' ' +
          adicionaZero(dataAtual.getHours()) +
          ':' +
          adicionaZeroMinutes(dataAtual.getMinutes());

        arrayData.push({
          value: datas.substring(11, 16),
          label: datas.substring(11, 16),
        });
        //    console.log('arrayData ' + JSON.stringify(arrayData));
      } else {
        arrayData.pop();
        break;
      }
    }

    let parametro =
      '?secretaria=' +
      sessionStorage.getItem('secretaria_id') +
      '&data=' +
      consultaData;
    if (cons_medico?.value > 0) {
      parametro = '?medico=' + cons_medico.value + '&data=' + consultaData;
    }

    if (sessionStorage.getItem('cargo') === 'M') {
      parametro =
        '?medico=' + sessionStorage.getItem('med_id') + '&data=' + consultaData;
    }

    api
      .get('api/consultas' + parametro, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          const arrayHoras = [];

          for (let i = 0; i < response.data.length; i++) {
            arrayHoras.push({
              value: response.data[i].cons_data.substring(11, 16),
              label: response.data[i].cons_data.substring(11, 16),
            });
          }

          const arr3 = arrayData.filter((x) => {
            return JSON.stringify(arrayHoras).indexOf(JSON.stringify(x)) < 0;
          });

          arr3.unshift({
            value: cons_data.substring(11, 16),
            label: cons_data.substring(11, 16),
          });

          setStatusCons_hora(arr3);
        } else {
          setStatusCons_hora(arrayData);
        }
      })
      .catch((error) => {
        //  handleInfo('falhou ');
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const handleEventClick = (clickInfo) => {
    /*  if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        clickInfo.event.remove()
      }*/
    limpar();
    if (tooltip) {
      tooltip.dispose();
      tooltip = null;
    }

    let extendedProps = clickInfo.event.extendedProps;

    setCons_paciente({
      value: extendedProps.cons_paciente,
      label: extendedProps.pac_nome,
    });
    setBuscarPaciente(
      extendedProps.cons_paciente + ' - ' + extendedProps.pac_nome,
    );

    let convenio = statusCons_convenio.filter(function (obj) {
      if (extendedProps.cons_convenio == obj.value) {
        return obj;
      }
    });

    setCons_convenio(convenio[0]);

    let tipo = statusCons_tipo.filter(function (obj) {
      if (extendedProps.cons_tipo == obj.value) {
        return obj;
      }
    });
    setCons_tipo(tipo[0]);

    setCons_data(extendedProps.cons_data.substring(0, 10));
    setCons_hora(extendedProps.cons_data.substring(11, 16));

    if (extendedProps.cons_status === 1) {
      setCons_status(1);
      document.getElementById('cons_status').checked = true;
    } else {
      setCons_status(0);
      document.getElementById('cons_status').checked = false;
    }
    setCons_codigo(extendedProps.cons_codigo);
    setCons_status(extendedProps.cons_status);
    setCons_medico({
      value: extendedProps.cons_medico,
      label: extendedProps.med_nome,
    });

    horarioLivre(
      extendedProps.cons_data,
      extendedProps.med_horaini,
      extendedProps.med_horafim,
      extendedProps.med_sessao,
      extendedProps.cons_medico,
    );

    setCons_hora({
      value: extendedProps.cons_data.substring(11, 16),
      label: extendedProps.cons_data.substring(11, 16),
    });
    setPac_fone(extendedProps.pac_fone);
    setPac_celular(extendedProps.pac_celular);
    setDisplayPacienteCad('none');
    document.getElementById('modal-agendamento').click();

    //setCons_data(clickInfo.startStr.substring(0,10));
    //setCons_hora(clickInfo.startStr.substring(11,16));

    //   console.log(clickInfo.startStr);
    //  console.log(clickInfo.endStr);
  };

  const handleEvents = (events) => {
    setcurrentEvents(events);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };
  const renderSidebarEvent = (event) => {
    return (
      <li key={event.id}>
        <b>
          {formatDate(event.start, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </b>
        <i>{event.title}</i>
      </li>
    );
  };
  const formatarPaciente = (paciente) => {
    let stringArray = paciente.split('-');
    return stringArray[1];
  };

  return (
    <>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            {' '}
            {CalendarAlt} Consulta {AngleDoubleLeft}
          </div>

          <div id="triangulo-para-baixo"></div>

          <div className="card-body">
            <section>
              <p>
                <b>Status da consulta agendada</b>
              </p>
              {/* paciente em espera no consultorio
                              statusCor = '#2196f3';
                              break;
                            case 2:
                              // atendimento em andamento
                              statusCor = '#fec107';
                              break;
                            case 3:
                              // atendimento concluido
                              statusCor = '#01a777';
                              break;
                            default:
                              // apenas agendado
                              statusCor = '#aa528c';*/}
              <ul className="legenda-consultas">
                <li style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      width: '17px',
                      height: '17px',
                      background: '#aa528c',
                      borderRadius: '50%',
                    }}
                    className="color-51comvc text-lowercase"
                  ></div>
                  &nbsp;Consulta agendada
                </li>
                <li style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      width: '17px',
                      height: '17px',
                      background: '#2196f3',
                      borderRadius: '50%',
                    }}
                    className="color-51comvc text-lowercase"
                  ></div>
                  &nbsp;Paciente em espera no consultorio
                </li>
                <li style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      width: '17px',
                      height: '17px',
                      background: '#fec107',
                      borderRadius: '50%',
                    }}
                    className="color-51comvc text-lowercase"
                  ></div>
                  &nbsp;Atendimento em andamento
                </li>
                <li style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      width: '17px',
                      height: '17px',
                      background: '#01a777',
                      borderRadius: '50%',
                    }}
                    className="color-51comvc text-lowercase"
                  ></div>
                  &nbsp;Atendimento concluido
                </li>
                <li style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      width: '17px',
                      height: '17px',
                      background: '#eb2d2d',
                      borderRadius: '50%',
                    }}
                    className="color-51comvc text-lowercase"
                  ></div>
                  &nbsp;Ausente
                </li>
              </ul>
            </section>
            {renderSidebar()}
            <div className="demo-app">
              {consultaEvents ? (
                <div className="demo-app-main">
                  <FullCalendar
                    plugins={[timeGridPlugin, interactionPlugin, listPlugin]}
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'timeGridWeek,timeGridDay,listWeek',
                    }}
                    initialView="timeGridWeek"
                    //Duration="00:15:00"
                    themeSystem="Minty"
                    nowIndicator={true}
                    slotDuration={med_sessao ? med_sessao : '00:15'}
                    defaultTimedEventDuration={
                      med_sessao ? med_sessao : '00:15'
                    }
                    allDaySlot={false}
                    hiddenDays={med_exp_semanal}
                    slotMinTime={med_horaini ? med_horaini : '08:00'}
                    slotMaxTime={med_horafim ? med_horafim : '20:00'}
                    locales={[ptbrLocale]}
                    editable={false}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={weekendsVisible}
                    eventColor={'#a7c4d2'}
                    eventTextColor={'#fff'}
                    ref={calendarRef}
                    // initialEvents={consultaEvents} // alternatively, use the `events` setting to fetch from a feed
                    events={consultaEvents}
                    select={handleDateSelect}
                    eventContent={renderEventContent} // custom render function
                    eventClick={handleEventClick}
                    eventsSet={handleEvents}
                    eventMouseEnter={handleMouseEnter}
                    eventMouseLeave={handleMouseLeave}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <input
        type="hidden"
        className="btn btn-primary"
        data-toggle="modal"
        id="modal-agendamento"
        data-target=".bd-example-modal-xl"
      />

      <div
        className="modal fade bd-example-modal-xl"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="card ">
              <div className="card-header">
                {' '}
                {CalendarAlt} Agendar consulta {AngleDoubleLeft}
              </div>

              <div className="card-body">
                <form id="form_paciente" onSubmit={(e) => save(e)}>
                  <div className="row mb-3">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="cons_data">
                        Data:<strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="cons_data"
                        onChange={(e) =>
                          setCons_data(removeAcento(e.target.value))
                        }
                        value={cons_data}
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="cons_hora">Hora</label>
                      <Select
                        id="cons_hora"
                        value={cons_hora}
                        onChange={handleChangeHora}
                        options={statusCons_hora}
                        placeholder={<div>Selecione</div>}
                      />
                      <input
                        style={{
                          top: '43px',
                          position: 'absolute',
                          zIndex: '-1',
                          width: '10%',
                        }}
                        type="password"
                        id="cons_hora_html5_validate"
                        value={cons_hora}
                        required
                      />
                    </div>
                    <div
                      className="  col-md-4 mb-3"
                      style={{ paddingRight: '0px' }}
                      id="buscar_paciente"
                    >
                      <label htmlFor="buscarPaciente">Buscar Paciente:</label>
                      <div
                        className="input-group mb-3"
                        style={{ position: 'relative', right: '50px' }}
                      >
                        <div className="input-group-prepend">
                          <span
                            onClick={(e) =>
                              getPaciente(buscarPaciente, cons_medico.value)
                            }
                            style={{
                              display: 'inline',
                              borderRadius: '50px',
                              background: '#a7c4d2 ',
                              color: 'white',
                              position: 'relative',
                              right: '-37px',
                              zIndex: '99',
                            }}
                            className="input-group-text"
                            id="basic-addon1"
                          >
                            {Search}
                          </span>
                        </div>
                        <input
                          id="buscarPaciente"
                          className="form-control"
                          style={{
                            display: 'inline',
                            padding: '7px 29px 7px 52px',
                            borderRadius: '50px 0px 0px 50px',
                            borderBottom: '6px solid #a7c4d2 ',
                          }}
                          type="text"
                          placeholder="Min 3 letras"
                          onChange={(e) =>
                            setBuscarPaciente(removeAcento(e.target.value))
                          }
                          value={buscarPaciente}
                        />
                      </div>
                    </div>
                    <div
                      style={{ display: 'none' }}
                      className="  col-md-4 mb-3"
                      id="select_paciente"
                    >
                      <label htmlFor="cons_paciente">Paciente:</label>
                      <div
                        className="input-group "
                        style={{ display: 'block', position: 'relative' }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                          }}
                          className="input-group-prepend"
                        >
                          <span
                            style={{
                              display: 'inline',
                              borderRadius: '50px',
                              background: '#f38692 ',
                              color: 'white',
                              position: 'relative',
                              right: '5px',
                              zIndex: '99',
                            }}
                            onClick={(e) => cancelarPaciente()}
                            className="input-group-text"
                            id="basic-addon1"
                          >
                            {Times}
                          </span>
                        </div>
                        <Select
                          id="cons_paciente"
                          value={cons_paciente}
                          onChange={handleChangePaciente}
                          options={statusCons_paciente}
                          placeholder={<div> Selecione</div>}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                            width: '10%',
                          }}
                          type="password"
                          id="cons_paciente_html5_validate"
                          value={cons_paciente}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="cons_convenio">
                        Convênio
                        <strong className="fild-required">*</strong>
                      </label>
                      <Select
                        id="cons_convenio"
                        value={cons_convenio}
                        onChange={handleChangeConvenio}
                        options={statusCons_convenio}
                        placeholder={<div>Selecione</div>}
                      />
                      <input
                        style={{
                          top: '43px',
                          position: 'absolute',
                          zIndex: '-1',
                          width: '10%',
                        }}
                        type="password"
                        id="cons_convenio_html5_validate"
                        value={cons_convenio}
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="cons_tipo">
                        Tipo:
                        <strong className="fild-required">*</strong>
                      </label>
                      <Select
                        id="cons_tipo"
                        value={cons_tipo}
                        onChange={handleChangeTipo}
                        options={statusCons_tipo}
                        placeholder={<div>Selecione</div>}
                      />
                      <input
                        style={{
                          top: '43px',
                          position: 'absolute',
                          zIndex: '-1',
                          width: '10%',
                        }}
                        type="password"
                        id="cons_tipo_html5_validate"
                        value={cons_tipo}
                        required
                      />
                    </div>
                    <div className="col-md-3 col-6 mb-3">
                      <label htmlFor="cons_status">
                        {cons_status === 1 ? (
                          <b className="text-secondary">
                            Paciente está na sala de espera aguardando?
                          </b>
                        ) : (
                          <b className="text-secondary">
                            Paciente está na sala de espera aguardando?
                          </b>
                        )}
                      </label>
                      <div className="material-switch   pull-right">
                        <input
                          onClick={(e) => checkboxStatus()}
                          id="cons_status"
                          name="ativo"
                          type="checkbox"
                        />
                        <label
                          htmlFor="cons_status"
                          className="badge-primary"
                        ></label>
                      </div>
                    </div>
                    {/*cons_codigo && (
                      <div className="col-md-3 mb-3">
                        <legend>Contato</legend>

                        {pac_fone && (
                          <p>
                            {' '}
                            <span style={{ fontSize: '20px', color: 'black' }}>
                              {PhoneAlt}
                            </span>{' '}
                            {pac_fone}
                          </p>
                        )}
                        {pac_celular && (
                          <a
                            target="_blank"
                            style={{ color: '#848688' }}
                            href={
                              'https://web.whatsapp.com/send?phone=55' +
                              encodeURIComponent(contatoZap(pac_celular))
                            }
                          >
                            {' '}
                            <p>
                              <span
                                style={{ fontSize: '22px', color: 'green' }}
                              >
                                {Whatsapp}
                              </span>{' '}
                              {pac_celular}
                            </p>
                          </a>
                        )}
                      </div>
                          )*/}
                    <div className="col-md-3 col-6 mb-3">
                      {/*cons_codigo && pac_celular && (
                        /*   <button
                          className="btn btn-sm mb-2 mr-2 "
                          style={{
                            background: '#94d299',
                            color: 'white',
                            width: '154px',
                          }}
                          type="button"
                          id="btnzap"
                          onClick={(e) => sendWhatsapp(e)}
                        >
                          Avisar via whatsapp
                        </button>

                        <a
                          target="blank"
                          className="text-success"
                          href={
                            'https://wa.me/55' +
                            contatoZap(pac_celular) +
                            '?text=' +
                            encodeURIComponent(
                              'Olá ' +
                                capitalize(
                                  formatarPaciente(cons_paciente.label),
                                ) +
                                ' ' +
                                pac_celular +
                                ' sua consulta com o Dr(a) ' +
                                capitalize(cons_medico.label) +
                                '  está confirmada para o dia ' +
                                mask_data(cons_data) +
                                ' as ' +
                                cons_hora.label +
                                '. Estamos aguardando você!',
                            )
                          }
                        >
                          <button
                            className="btn btn-sm mb-2 mr-2 "
                            style={{
                              background: '#94d299',
                              color: 'white',
                              width: '154px',
                            }}
                            type="button"
                            id="btnzap"
                          >
                            Avisar via whatsapp
                          </button>
                        </a>
                      )*/}{' '}
                    </div>

                    <PacienteCad
                      displayPaciente={displayPacienteCad}
                      atualizarDisplay={setDisplayPacienteCad}
                      statusCons_paciente={statusCons_paciente}
                      setStatusCons_paciente={setStatusCons_paciente}
                      setCons_paciente={setCons_paciente}
                      setCons_convenio={setCons_convenio}
                    />
                  </div>

                  <div className="text-left mb-3 mt-3 ">
                    {cons_codigo ? (
                      <>
                        <button
                          className="btn btn-sm mb-2 mr-2 btn_Cadastrar"
                          type="submit"
                          id="btnAlterar"
                        >
                          {Edit} Alterar
                        </button>
                        <button
                          type="button"
                          onClick={(e) =>
                            history.push(
                              `/paciente/${cons_paciente.value}/${cons_medico.value}`,
                            )
                          }
                          className="btn btn-sm mb-2 mr-2 btn_Cadastrar"
                        >
                          {Edit} Paciente
                        </button>

                        <button
                          type="button"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          onClick={(e) =>
                            dadosModal(cons_paciente, cons_codigo)
                          }
                          className="btn btn-sm mb-2 mr-2 btn_excluir"
                        >
                          {Trash} Exluir
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn mb-2 mr-2 btn-sm btn_Cadastrar "
                        type="submit"
                        id="btnCadastrar"
                      >
                        {Save} Cadastrar
                      </button>
                    )}

                    <button
                      className="btn btn-sm mb-2 mr-2 btn-secondary "
                      onClick={(e) => limpar(e)}
                      type="button"
                      id="btn_limpar"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      {Times} Fechar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card card-red">
              <div className="card-header">{Trash} Excluir Consulta</div>

              <div className="card-body">
                Tem certeza que deseja excluir a consulta do Paciente
                <i>
                  <b className="text-danger">"</b>
                  {' ' + nomeModal?.label}
                  <b className="text-danger">"</b>
                </i>{' '}
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-sm btn-secondary "
                  type="button"
                  data-dismiss="modal"
                >
                  {Times}
                  &nbsp;Cancelar
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-sm btn_excluir "
                  onClick={(e) => save(e, 'delete', 0)}
                  type="button"
                  data-dismiss="modal"
                >
                  {Trash} Excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Consulta;
