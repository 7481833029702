import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import {
  Times,
  Search,
  Save,
  Edit,
  Trash,
  BookMedical,
  AngleDoubleLeft,
} from '../utils/Fontawesome';
import { TablePagination } from '@material-ui/core';
import { sqlLIKE, removeAcento } from '../utils/Mascara';
const ExameConvenio = () => {
  let history = useHistory();

  const [ec_exame, setEc_exame] = useState([]);
  const [statusEc_exame, setStatusEc_exame] = useState([]);
  const [ec_convenio, setEc_convenio] = useState([]);
  const [statusEc_convenio, setStatusEc_convenio] = useState([]);
  const [exa_descricao, setExa_descricao] = useState('');
  const [ec_codigo, setEc_codigo] = useState('');
  const [novoCodigo, setNovoCodigo] = useState('');

  const [exame, setExame] = useState([]);
  const [nomeModal, setNomeModal] = useState('');
  const [idModal, setIdModal] = useState('');

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [examePag, setExamePag] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = async (event, newPage) => {
    await setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
      "rowsPerPage:" +
        rowsPerPage +
        " newPage " +
        newPage +
        " " +
        inicio +
        " ---- " +
        fim
    );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setExamePag(usuariosPag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setExamePag(usuariosPag);
  };
  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    window.$('#sidebarCollapse').on('click', function () {
      window.$('#sidebar').toggleClass('active');
      window.$(this).toggleClass('active');
    });

    getExameCovenios();
    getConvenio();
    getExames();
  }, []);

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };
  const getExames = () => {
    api
      .get('api/exames?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayExames = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayExames.push({
            value: response.data[i].exa_codigo,
            label: response.data[i].exa_descricao,
          });
        }

        setStatusEc_exame(arrayExames);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getExameCovenios = () => {
    api
      .get('api/exameconvenios?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setExame(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        if (fim > response.data.length) {
          fim = response.data.length;
        }

        const usuarioPag = [];
        for (let i = inicio; i < fim; i++) {
          usuarioPag.push(response.data[i]);
        }
        setExamePag(usuarioPag);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const handleChange = (medico) => {
    setEc_exame(medico);
  };
  const handleChangeConvenio = (convenio) => {
    setEc_convenio(convenio);
  };

  const getConvenio = () => {
    api
      .get('api/convenios?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayConvenios = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayConvenios.push({
            value: response.data[i].conv_id,
            label:
              response.data[i].conv_id + ' - ' + response.data[i].conv_nome,
          });
        }

        setStatusEc_convenio(arrayConvenios);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const editar = (exame) => {
    setEc_codigo(exame.ec_codigo);
    setNovoCodigo(exame.ec_codigo);
    setEc_exame({ value: exame.ec_exame, label: exame.exa_descricao });
    setEc_convenio({ value: exame.ec_convenio, label: exame.conv_nome });
  };

  const dadosModal = (nome, id) => {
    setNomeModal(nome);
    setIdModal(id);
  };
  const save = (e, metodo, status) => {
    e.preventDefault();
    e.stopPropagation();
    let data, method, url;
    if (metodo) {
      method = 'delete';
    } else {
      if (!ec_codigo) {
        method = 'post';
      } else {
        method = 'put';
      }
    }

    if (method === 'put') {
      data = {
        ec_codigo: ec_codigo,
        novocodigo: novoCodigo,
        ec_exame: ec_exame.value,
        ec_convenio: ec_convenio.value,
      };
      url = 'api/exameconvenios/' + ec_codigo;
    } else if (method === 'post') {
      data = {
        ec_codigo: ec_codigo,
        novocodigo: novoCodigo,
        ec_exame: ec_exame.value,
        ec_convenio: ec_convenio.value,
      };
      url = 'api/exameconvenios';
    } else if (method === 'delete') {
      // url = url + "/" + idModal + "/" + status;
      url = 'api/exameconvenios/' + idModal;
      data = {
        id: idModal,
      };
      setNomeModal('');
      setIdModal('');
    }

    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        getExameCovenios();
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setEc_exame('');
    setExa_descricao('');
    setEc_codigo('');
    setNovoCodigo('');
    setNomeModal('');
    setIdModal('');
  };

  const buscar = async () => {
    let filtrado, filtradoExame, filtradoConvenio;

    if (arrayFiltrado) {
      if (!busca) {
        filtrado = exame;
      } else {
        filtrado = arrayFiltrado.filter(function (obj) {
          return sqlLIKE(obj.ec_codigo, '%' + busca + '%');
          //  return ilikeToRegExp('%' + busca + '%').test(obj.ec_codigo);
        });

        filtradoExame = arrayFiltrado.filter(function (obj) {
          return sqlLIKE(obj.exa_descricao, '%' + busca + '%');
          //  return ilikeToRegExp('%' + busca + '%').test(obj.exa_descricao);
        });
        filtrado = filtrado.concat(filtradoExame);

        filtradoConvenio = arrayFiltrado.filter(function (obj) {
          return sqlLIKE(obj.conv_nome, '%' + busca + '%');
          //return ilikeToRegExp('%' + busca + '%').test(obj.conv_nome);
        });
        filtrado = filtrado.concat(filtradoConvenio);
        filtrado = filtrado.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null));
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      if (!filtrado.length) {
        filtrado = exame;
        handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const cidadePag = [];
      for (let i = inicio - 1; i < fim; i++) {
        cidadePag.push(filtrado[i]);
      }

      await setExamePag(cidadePag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo('Nenhum registro encontrado');
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div
          className="modal fade bd-example-modal-lg"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="card ">
                <div className="card-header">
                  {' '}
                  {BookMedical} Cadastrar exames do convênio {AngleDoubleLeft}
                </div>

                <div id="triangulo-para-baixo"></div>
                <div className="card-body">
                  <form id="form_exame" onSubmit={(e) => save(e)}>
                    <div className="form-row mb-3">
                      <div className="col-md-4 col-12 mb-3">
                        <label htmlFor="novoCodigo">
                          Código:
                          <strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="novoCodigo"
                          placeholder="Digite o código do exame"
                          onChange={(e) => setNovoCodigo(e.target.value)}
                          value={novoCodigo}
                          required
                        />
                      </div>
                      <div className="col-md-4 col-12 mb-3">
                        <label htmlFor="ec_exame">
                          Exame:
                          <strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="ec_exame"
                          value={ec_exame}
                          onChange={handleChange}
                          options={statusEc_exame}
                          placeholder={<div>Selecione</div>}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                            width: '10%',
                          }}
                          type="password"
                          id="ec_exame_html5_validate"
                          value={ec_exame}
                          required
                        />
                      </div>
                      <div className="col-md-4 col-12 mb-3">
                        <label htmlFor="ec_convenio">
                          Convênio:
                          <strong className="fild-required">*</strong>
                        </label>
                        <Select
                          id="ec_convenio"
                          value={ec_convenio}
                          onChange={handleChangeConvenio}
                          options={statusEc_convenio}
                          placeholder={<div>Selecione</div>}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                            width: '10%',
                          }}
                          type="password"
                          id="ec_convenio_html5_validate"
                          value={ec_convenio}
                          required
                        />
                      </div>
                    </div>
                    <div className="text-left ">
                      {ec_codigo ? (
                        <button
                          className="btn btn-sm btn_Cadastrar "
                          type="submit"
                          id="btnAlterar"
                        >
                          {Edit} Alterar
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn_Cadastrar "
                          type="submit"
                          id="btnCadastrar"
                        >
                          {Save} Cadastrar
                        </button>
                      )}
                      &nbsp;&nbsp;{' '}
                      <button
                        className="btn btn-sm btn-secondary "
                        onClick={(e) => limpar(e)}
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="btn_limpar"
                      >
                        {Times} Fechar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            {' '}
            {BookMedical} Lista de exames do convênio {AngleDoubleLeft}
          </div>

          <div id="triangulo-para-baixo"></div>
          <div className="card-body ">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      <div
                        className="col-md-10 col-12 col-sm-12"
                        style={{ paddingRight: '0px' }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <div
                          class="input-group mb-3"
                          style={{ position: 'relative', right: '50px' }}
                          onClick={(e) => buscar()}
                        >
                          <div class="input-group-prepend">
                            <span
                              style={{
                                display: 'inline',
                                borderRadius: '50px',
                                background: '#a7c4d2 ',
                                color: 'white',
                                position: 'relative',
                                right: '-37px',
                                zIndex: '99',
                              }}
                              class="input-group-text"
                              id="basic-addon1"
                            >
                              {Search}
                            </span>
                          </div>
                          <input
                            id="filtro"
                            className="form-control"
                            style={{
                              display: 'inline',
                              padding: '7px 29px 7px 52px',
                              borderRadius: '50px 0px 0px 50px',
                              borderBottom: '6px solid #a7c4d2 ',
                            }}
                            type="text"
                            onChange={(e) =>
                              setBusca(removeAcento(e.target.value))
                            }
                            value={busca}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <label htmlFor="btn_Cadastrar">&nbsp;&nbsp;&nbsp;</label>
                    <br />
                    <button
                      type="button"
                      className="btn btn_Cadastrar btn-block "
                      id="btn_Cadastrar"
                      onClick={(e) => limpar(e)}
                      data-toggle="modal"
                      data-target=".bd-example-modal-lg"
                    >
                      {Save} Cadastrar
                    </button>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </form>

            <div className=" table-responsive">
              <table className="table  table-hover table-striped table-light ">
                <thead className="text-white  bg-dark ">
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Exame</th>
                    <th scope="col">Convênio</th>
                    <th style={{ width: '15%' }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {examePag.length > 0 ? (
                    examePag.map(function (exames, i) {
                      return (
                        <>
                          <tr key={exames.ec_codigo}>
                            <th>{exames.ec_codigo}</th>
                            <th>{exames.exa_descricao}</th>
                            <th>{exames.conv_nome}</th>
                            <td>
                              <img
                                src="alterar.png"
                                onClick={(e) => editar(exames)}
                                className="btn-acao mb-2 mr-2"
                                data-toggle="modal"
                                data-target=".bd-example-modal-lg"
                              />
                              <img
                                src="excluir.png"
                                onClick={(e) =>
                                  dadosModal(exames.ec_codigo, exames.ec_codigo)
                                }
                                className="btn-acao mb-2 mr-2"
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>Nenhum registro foi encontrado</th>
                        <th></th>
                      </tr>
                    </>
                  )}{' '}
                </tbody>{' '}
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Registros por página'}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card card-red">
              <div className="card-header">
                {' '}
                {Trash} Excluir exame do convênio{' '}
              </div>

              <div className="card-body">
                Tem certeza que deseja excluir o exame código{' '}
                <i>
                  <b className="text-danger">"</b>
                  {nomeModal}
                  <b className="text-danger">"</b>
                </i>{' '}
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-sm btn-secondary "
                  type="button"
                  data-dismiss="modal"
                >
                  {Times}
                  &nbsp;Cancelar
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-sm btn_excluir "
                  onClick={(e) => save(e, 'delete', 0)}
                  type="button"
                  data-dismiss="modal"
                >
                  {Trash} Excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ExameConvenio;
