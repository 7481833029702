import React, { useState, useEffect } from 'react';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import {
  Times,
  Save,
  Edit,
  AddressCard,
  AngleDoubleLeft,
} from '../utils/Fontawesome';

const ConvenioCad = (props) => {
  let history = useHistory();

  const [conv_status, setConv_status] = useState(1);
  const [conv_nome, setConv_nome] = useState('');
  const [conv_id, setConv_id] = useState(0);

  const [convenio, setConvenio] = useState([]);

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    window.$('#sidebarCollapse').on('click', function () {
      window.$('#sidebar').toggleClass('active');
      window.$(this).toggleClass('active');
    });
  }, []);

  const checkboxAtivo = () => {
    if (conv_status === 1) {
      setConv_status(0);
    } else {
      setConv_status(1);
    }
  };
  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limparConvenioCad')) {
          document.getElementById('btn_limparConvenioCad').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  const save = (e, metodo, status) => {
    e.preventDefault();
    e.stopPropagation();
    let data, method, url;

    if (!conv_nome) {
      handleInfo('Preencha o campo nome do convenio.');
      return false;
    }

    if (!conv_id) {
      method = 'post';
      let existeCidade = convenio.findIndex(
        (val) => val.conv_nome === conv_nome,
      );
      if (existeCidade !== -1) {
        handleInfo('convenio já existente');
        return false;
      }
    } else {
      method = 'put';
    }

    if (method === 'put') {
      data = {
        conv_id: conv_id,
        conv_status: 1,
        conv_nome: conv_nome,
        conv_med_id: parseInt(sessionStorage.getItem('med_id')),
      };
      url = 'api/convenios/' + conv_id;
    } else if (method === 'post') {
      data = {
        conv_nome: conv_nome,
        conv_status: 1,
        conv_med_id: parseInt(sessionStorage.getItem('med_id')),
      };
      url = 'api/convenios';
    }

    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        var arrayConvenios = props.arrayconvenio;
        var arrayConveniosvalues = props.arrayconveniovalues;
        arrayConvenios.push({
          value: String(response.data),
          label: response.data + ' - ' + conv_nome,
        });
        props.atualizarselect(arrayConvenios);

        arrayConveniosvalues.push({
          value: parseInt(response.data),
          label: conv_nome,
        });
        props.autoselecionarselect(arrayConveniosvalues);
        document.getElementsByClassName('css-2b097c-container')[0].click();
        if (document.getElementById('btn_limparConvenioCad')) {
          document.getElementById('btn_limparConvenioCad').click();
        }
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    if (document.getElementsByClassName('modal-backdrop')[1]) {
      var no = document.getElementsByClassName('modal-backdrop')[1];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    setConv_status(1);
    setConv_nome('');
    setConv_id('');
  };

  return (
    <>
      <div className="card ">
        <div className="card-header">
          {' '}
          {AddressCard} Cadastro de Convênio {AngleDoubleLeft}
        </div>

        <div id="triangulo-para-baixo"></div>
        <div className="card-body">
          <form id="form_convenio" onSubmit={(e) => save(e)}>
            <div className="form-row mb-3">
              <div className="col-md-12 col-9 mb-3">
                <label htmlFor="conv_nome">
                  Nome do Convênio:
                  <strong className="fild-required">*</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="conv_nome"
                  placeholder="Digite o nome do convênio"
                  onChange={(e) => setConv_nome(e.target.value.toUpperCase())}
                  value={conv_nome}
                  required
                />
              </div>
            </div>
            <div className="text-left ">
              {conv_id ? (
                <button
                  className="btn btn-sm btn_Cadastrar "
                  type="submit"
                  id="btnAlterar"
                >
                  {Edit} Alterar
                </button>
              ) : (
                <button
                  className="btn btn-sm btn_Cadastrar "
                  type="submit"
                  id="btnCadastrar"
                >
                  {Save} Cadastrar
                </button>
              )}
              &nbsp;&nbsp;{' '}
              <button
                className="btn btn-sm btn-secondary "
                onClick={(e) => limpar(e)}
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                id="btn_limparConvenioCad"
              >
                {Times} Fechar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default ConvenioCad;
