/************************************* Lista de Funções ********************************************/
//   Funções                  Descrição
//removeEspacos          : Retira todos espaços de uma string
// capitalize            : Deixa cada 1 letra de uma string em maiuscula excluindo da , de e etc
// mask_Numeros               : Mascara de numeros, Permite digitar apenas numeros
// mask_hora                  : Mascara de horario, Formata hora e minutos digitados 00:00
// mask_Moeda                 : Mascara de moeda
// mask_tel_celular_area      : Mascara de telefone e celular (19) 3567-1452 ou (19) 99674-1452
// mask_tel_celular_DDD       : Mascara de telefone e celular com DDD 55 (19) 3567-1452 ou 55 (19) 99674-1452
// mask_CEP                   : Mascara de CEP
// mask_CPF                   : Mascara de CPF esta vinculada ao CNPJ,caso o numero de caracter do CPF for maior que o necessario, a mascara passa a formatar CNPJ
// mask_CNPJ                  : Mascara de CNPJ esta vinculado ao CPF,caso o numero de caracter do CNPJ for menor que o necessario, a mascara passa a formatar CPF
// validar_CNPJ               : Valida se o CNPJ é valido e retorne true caso verdadeiro ou false caso seja falso
// validar_CPF                : Valida se o CPF é valido e retorne true caso verdadeiro ou false caso seja falso
/****************************************************************************************************/

export const removeEspacos = (string) => {
  return string.split(' ').join('');
};
export const replaceSpecialChars = (str) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove acentos
    .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
    .replace(/\-\-+/g, '-') // Substitui multiplos hífens por um único hífen
    .replace(/(^-+|-+$)/, ''); // Remove hífens extras do final ou do inicio da string
};

const escapePattern = (s) => s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

// converts ILIKE pattern to a RegExp object
// Nao funciona no safari devido aos 2 primeiros replaces devido ao regex que tem neles
export const ilikeToRegExp = (pattern) =>
  new RegExp(
    `^${escapePattern(pattern)}$`
      // convert ILIKE wildcards, don't match escaped
      .toString()
      .replace(/(?<![\\])%/g, '.*')
      .toString()
      .replace(/(?<![\\])_/g, '.')
      // replace ILIKE escapes
      .replace(/\\%/g, '%')
      .replace(/\\_/g, '_'),
    'i',
  );
// equivalente a ilikeToRegExp e funfa no safari
export const sqlLIKE = (target, likeExp) => {
  let regex = likeExp
    .replaceAll(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1')
    .replaceAll('%', '.*')
    .replaceAll('_', '.')
    .toUpperCase();

  if (likeExp.charAt(0) !== '%' || !likeExp.includes('%')) regex = `^${regex}`;

  if (likeExp.charAt(likeExp.length - 1) !== '%' || !likeExp.includes('%'))
    regex = `${regex}$`;

  return new RegExp(regex).exec(target) !== null;
};
export const removeAcentoNormalize = (text) => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toUpperCase();
};
export const removeAcento = (text) => {
  text = text.toLowerCase();
  text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
  text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
  text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
  text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
  text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
  text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
  text = text.toUpperCase();
  return text;
};

export const capitalize = (texto) => {
  if (texto) {
    texto = texto.toLowerCase().replace(/(?:^|\s)\S/g, function (capitalize) {
      return capitalize.toUpperCase();
    });
    //preposição digitada
    let PreposM = ['Da', 'Do', 'Das', 'Dos', 'A', 'E', 'De', 'DE'];
    //preposição substituta
    let prepos = ['da', 'do', 'das', 'dos', 'a', 'e', 'de', 'de'];

    for (let i = PreposM.length - 1; i >= 0; i--) {
      texto = texto.replace(
        RegExp(
          '\\b' + PreposM[i].replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '\\b',
          'g',
        ),
        prepos[i],
      );
    }

    return texto;
  } else {
    return false;
  }
};

export const mask_Qtd = (v) => {
  v = String(v);
  v = v.replace(/\D/g, ''); //permite digitar apenas números
  v = v.replace(/[0-9]{15}/, 'inválido'); //limita pra máximo 999.999.999,99
  v = v.replace(/(\d{1})(\d{12})$/, '$1.$2'); //coloca ponto antes dos últimos 8 digitos
  v = v.replace(/(\d{1})(\d{9})$/, '$1.$2'); //coloca ponto antes dos últimos 8 digitos
  v = v.replace(/(\d{1})(\d{6})$/, '$1.$2'); //coloca ponto antes dos últimos 5 digitos
  v = v.replace(/(\d{1})(\d{3})$/, '$1.$2'); //coloca virgula antes dos últimos 2 digitos
  return v;
};

export const mask_Peso = (v) => {
  if (!v) {
    return '';
  }
  v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito

  v = v.replace(/^([0-9]{3}\.?){3}-[0-9]{3}$/, '$1.$2');

  //v=v.replace(/(\d{3})(\d)/g,"$1,$2")

  v = v.replace(/(\d)(\d{3})$/, '$1.$2'); //Coloca ponto antes dos 2 últimos digitos

  return v;
};

export const limpar_Moeda = (v) => {
  if (v) {
    v = v.split('.').join('');
    v = v.replace(',', '.');
    if (v.indexOf('.') >= 0) {
      return v;
    } else {
      let tamanho = v.length;
      return v.substr(0, tamanho - 2) + '.' + v.substr(tamanho - 2, tamanho);
    }
  }
};

export const mask_Numeros = (v) => {
  v = String(v).replace(/\D/g, ''); // permite digitar apenas numero
  return v;
};
export const mask_dateTimeBr = (v) => {
  return v
    .toLocaleString('pt-br', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replace(/-/g, '/');
};

export const mask_dateTimeEUA = (v) => {
  return v
    .toLocaleString('pt-br', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replace(/\//g, '-');
};
// se a data vir 27/03/2020 ela se tornara  2020-03-27 e vice-versa
export const mask_data = (data) => {
  data = String(data);
  if (data.indexOf('-') !== -1) {
    return data.substr(0, 10).split('-').reverse().join('/');
  } else {
    return data.substr(0, 10).split('/').reverse().join('-');
  }
};
// mascara de hora formata em 00:00
export const mask_hora = (v) => {
  v = String(v).replace(/\D/g, '');
  v = v.replace(/(\d)(\d{2})$/, '$1:$2');
  if (v.length > 5) {
    return mask_hora(v.substring(0, 5));
  } else {
    return v;
  }
};

//valida CNPJ ja com mascara
export const validar_CNPJ = (cnpj) => {
  let tamanho, numeros, digitos, soma, pos, resultado;
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;

  // Valida DVs
  tamanho = cnpj.length - 2;
  numeros = cnpj.substring(0, tamanho);
  digitos = cnpj.substring(tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(1)) return false;

  return true;
};
// valida CPF ja com mascara
export const validar_CPF = (cpf) => {
  if (cpf.length === '') {
    return true;
  }
  if (cpf.length < 16) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '') return false;
    // Elimina CPFs invalidos conhecidos
    if (
      cpf.length !== 11 ||
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    )
      return false;
    // Valida 1o digito
    let add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;
    return true;
  } else {
    return true;
  }
};
// mascara para moeda
export const mask_Moeda = (z) => {
  let v = String(z);
  v = v.replace(/\D/g, ''); //permite digitar apenas números
  v = v.replace(/[0-9]{12}/, 'inválido'); //limita pra máximo 999.999.999,99
  v = v.replace(/(\d{1})(\d{8})$/, '$1.$2'); //coloca ponto antes dos últimos 8 digitos
  v = v.replace(/(\d{1})(\d{5})$/, '$1.$2'); //coloca ponto antes dos últimos 5 digitos
  v = v.replace(/(\d{1})(\d{1,2})$/, '$1,$2'); //coloca virgula antes dos últimos 2 digitos
  return v;
};

// mascara para telefone e celular que verifica até a area
//valida telefone e celular simultaneamente (19) 3567-1452 ou (19) 99674-1452
export const mask_tel_celular_area = (v) => {
  v = String(v).replace(/\D/g, '');
  v = v.replace(/^(\d{2})(\d)/g, '($1) $2');
  v = v.replace(/(\d)(\d{4})$/, '$1-$2');
  if (v.length > 15) {
    return mask_tel_celular_area(v.substring(0, 15));
  } else {
    return v;
  }
};
// mascara para telefone e celular que verifica até o DDD
//valida telefone e celular simultaneamente 55 (19) 3567-1452 ou 55 (19) 99674-1452
export const mask_tel_celular_DDD = (v) => {
  v = String(v).replace(/\D/g, '');

  if (v.length <= 12) {
    v = v.replace(/^(\d\d)(\d\d)(\d{0,4})(\d{0,4}\d)/, '+$1 ($2) $3-$4');
  } else if (v.length > 12) {
    v = v.replace(/^(\d\d)(\d\d)(\d{0,5})(\d{0,3}\d)/, '+$1 ($2) $3-$4');
  }
  if (v.length > 19) {
    return mask_tel_celular_DDD(v.substring(0, 19));
  } else {
    return v;
  }
};
// mascara para CNPJ
// funciona em conjunto com a função CPF
// o certo é usar a função CPF e controlar se quiser que valide so um ou outro pelo max length do html5
export const mask_CNPJ = (cnpj) => {
  cnpj = cnpj.replace(/\D/g, '');
  cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2');
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
  cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2');
  if (cnpj.length > 18) {
    return mask_CNPJ(cnpj.substring(0, 18));
  } else {
    if (cnpj.length < 15) {
      return mask_CPF(cnpj);
    } else {
      return cnpj;
    }
  }
};
// mascara para CPF
//funciona em conjunto com a função CNPJ, passar o numero de um cpf a função passara o controle para a função de CNPJ
// e vice-versa
export const mask_CPF = (cpf) => {
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  if (cpf.length > 14) {
    return mask_CNPJ(cpf);
  } else {
    return cpf;
  }
};
// mascara para CEP
export const mask_CEP = (cep) => {
  cep = cep.replace(/\D/g, '');
  cep = cep.replace(/^(\d{2})(\d)/, '$1.$2');
  cep = cep.replace(/\.(\d{3})(\d)/, '.$1-$2');
  if (cep.length > 10) {
    return mask_CEP(cep.substring(0, 10));
  } else {
    return cep;
  }
};

/*
array = array
filter = nome do campo q quer filtrar (string)
word = palavra ser pesquisada (string)
result = o resultado da pesquisa feita dentro do array

o result não precisa passar como parametro porque ele é só o retorno da função
*/
export const filterBy = (array, filter, word, result) => {
  result = array.filter((el) => {
    return el[filter].indexOf(word) > -1;
  });
  return result;
};

//array = array que você quer agrupar (array)
//prop = propriedade que você quer usar como base do agrupamento (string)
//result = resultado do agrupamento (array)
export const grouperArray = (array, prop, result) => {
  result = array.reduce((r, a) => {
    r[a[prop]] = [...(r[a[prop]] || []), a];
    return r;
  }, {});
  return result;
};

export const calcularIdade = (aniversario) => {
  var nascimento = aniversario.split('-');
  var dataNascimento = new Date(
    parseInt(nascimento[0], 10),
    parseInt(nascimento[1], 10) - 1,
    parseInt(nascimento[2], 10),
  );

  var diferenca = Date.now() - dataNascimento.getTime();
  var idade = new Date(diferenca); // miliseconds from epoch
  return Math.abs(idade.getUTCFullYear() - 1970);
};
