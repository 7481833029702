import React, { useState, useEffect } from 'react';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import {
  Times,
  Search,
  Save,
  Edit,
  Trash,
  AddressCard,
  AngleDoubleLeft,
} from '../utils/Fontawesome';
import { TablePagination } from '@material-ui/core';
import { sqlLIKE, removeAcento } from '../utils/Mascara';
const Convenio = () => {
  let history = useHistory();

  const [conv_status, setConv_status] = useState(1);
  const [conv_nome, setConv_nome] = useState('');
  const [conv_id, setConv_id] = useState(0);

  const [convenio, setConvenio] = useState([]);
  const [nomeModal, setNomeModal] = useState('');
  const [idModal, setIdModal] = useState('');

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [convenioPag, setConvenioPag] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = async (event, newPage) => {
    await setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
      "rowsPerPage:" +
        rowsPerPage +
        " newPage " +
        newPage +
        " " +
        inicio +
        " ---- " +
        fim
    );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setConvenioPag(usuariosPag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const usuariosPag = [];
    for (let i = inicio - 1; i < fim; i++) {
      usuariosPag.push(arrayFiltrado[i]);
    }
    setConvenioPag(usuariosPag);
  };
  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    window.$('#sidebarCollapse').on('click', function () {
      window.$('#sidebar').toggleClass('active');
      window.$(this).toggleClass('active');
    });
    document.getElementById('conv_status').checked = true;
    getConvenios();
  }, []);

  const checkboxAtivo = () => {
    if (conv_status === 1) {
      setConv_status(0);
    } else {
      setConv_status(1);
    }
  };
  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  const getConvenios = () => {
    api
      .get('api/convenios?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setConvenio(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        if (fim > response.data.length) {
          fim = response.data.length;
        }

        const usuarioPag = [];
        for (let i = inicio; i < fim; i++) {
          usuarioPag.push(response.data[i]);
        }
        setConvenioPag(usuarioPag);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const editar = (convenio) => {
    document.getElementById('conv_nome').focus();

    setConv_nome(convenio.conv_nome);
    setConv_id(convenio.conv_id);
    setConv_status(convenio.conv_status);
    if (convenio.conv_status === 1) {
      document.getElementById('conv_status').checked = true;
    } else {
      document.getElementById('conv_status').checked = false;
    }
  };

  const dadosModal = (nome, id) => {
    setNomeModal(nome);
    setIdModal(id);
  };
  const save = (e, metodo, status) => {
    e.preventDefault();
    e.stopPropagation();
    let data, method, url;
    if (metodo) {
      method = 'delete';
    } else {
      if (!conv_nome) {
        handleInfo('Preencha o campo nome do convenio.');
        return false;
      }

      if (!conv_id) {
        method = 'post';
        let existeCidade = convenio.findIndex(
          (val) => val.conv_nome === conv_nome,
        );
        if (existeCidade !== -1) {
          handleInfo('convenio já existente');
          return false;
        }
      } else {
        method = 'put';
      }
    }

    if (method === 'put') {
      data = {
        conv_id: conv_id,
        conv_status: conv_status,
        conv_nome: conv_nome,
        conv_med_id: parseInt(sessionStorage.getItem('med_id')),
      };
      url = 'api/convenios/' + conv_id;
    } else if (method === 'post') {
      data = {
        conv_nome: conv_nome,
        conv_status: conv_status,
        conv_med_id: parseInt(sessionStorage.getItem('med_id')),
      };
      url = 'api/convenios';
    } else if (method === 'delete') {
      // url = url + "/" + idModal + "/" + status;
      url = 'api/convenios/' + idModal;
      data = {
        id: idModal,
      };
      setNomeModal('');
      setIdModal('');
    }

    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        getConvenios();
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setConv_status(1);
    setConv_nome('');
    setConv_id('');

    setNomeModal('');
    setIdModal('');
  };
  const buscar = async () => {
    var filtrado;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = convenio;
      } else {
        filtrado = arrayFiltrado.filter(function (obj) {
          return sqlLIKE(obj.conv_nome, '%' + busca + '%');
          //    return ilikeToRegExp('%' + busca + '%').test(obj.conv_nome);
        });
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;
      if (!filtrado.length) {
        filtrado = convenio;
        handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const cidadePag = [];
      for (let i = inicio - 1; i < fim; i++) {
        cidadePag.push(filtrado[i]);
      }

      await setConvenioPag(cidadePag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo('Nenhum registro encontrado');
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div
          className="modal fade bd-example-modal-md"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="card ">
                <div className="card-header">
                  {' '}
                  {AddressCard} Cadastro de Convênio {AngleDoubleLeft}
                </div>

                <div id="triangulo-para-baixo"></div>
                <div className="card-body">
                  <form id="form_convenio" onSubmit={(e) => save(e)}>
                    <div className="form-row mb-3">
                      <div className="col-md-10 col-9 mb-3">
                        <label htmlFor="conv_nome">
                          Nome do Convênio:
                          <strong className="fild-required">*</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="conv_nome"
                          placeholder="Digite o nome do convênio"
                          onChange={(e) =>
                            setConv_nome(e.target.value.toUpperCase())
                          }
                          value={conv_nome}
                          required
                        />
                      </div>
                      <div className="col-md-2 col-2 mb-3">
                        <label htmlFor="conv_status">
                          {conv_status === 1 ? (
                            <b className="text-secondary">Status</b>
                          ) : (
                            <b className="text-secondary">Status</b>
                          )}
                        </label>
                        <div className="material-switch   pull-right">
                          <input
                            onClick={(e) => checkboxAtivo()}
                            id="conv_status"
                            name="conv_status"
                            type="checkbox"
                          />
                          <label
                            htmlFor="conv_status"
                            className="badge-primary"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="text-left ">
                      {conv_id ? (
                        <button
                          className="btn btn-sm btn_Cadastrar "
                          type="submit"
                          id="btnAlterar"
                        >
                          {Edit} Alterar
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm  btn_Cadastrar  "
                          type="submit"
                          id="btnCadastrar"
                        >
                          {Save} Cadastrar
                        </button>
                      )}
                      &nbsp;&nbsp;{' '}
                      <button
                        className="btn btn-sm btn-secondary "
                        onClick={(e) => limpar(e)}
                        type="button"
                        data-dismiss="modal"
                        style={{ borderRadius: '10px' }}
                        aria-label="Close"
                        id="btn_limpar"
                      >
                        {Times} Fechar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            {' '}
            {AddressCard} Lista de Convênios {AngleDoubleLeft}
          </div>

          <div className="card-body ">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      <div
                        className="col-md-10 col-12 col-sm-12"
                        style={{ paddingRight: '0px' }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <div
                          class="input-group mb-3"
                          style={{ position: 'relative', right: '50px' }}
                          onClick={(e) => buscar()}
                        >
                          <div class="input-group-prepend">
                            <span
                              style={{
                                display: 'inline',
                                borderRadius: '50px',
                                background: '#a7c4d2 ',
                                color: 'white',
                                position: 'relative',
                                right: '-37px',
                                zIndex: '99',
                              }}
                              class="input-group-text"
                              id="basic-addon1"
                            >
                              {Search}
                            </span>
                          </div>
                          <input
                            id="filtro"
                            className="form-control"
                            style={{
                              display: 'inline',
                              padding: '7px 29px 7px 52px',
                              borderRadius: '50px 0px 0px 50px',
                              borderBottom: '6px solid #a7c4d2 ',
                            }}
                            type="text"
                            onChange={(e) =>
                              setBusca(removeAcento(e.target.value))
                            }
                            value={busca}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <label htmlFor="btn_Cadastrar">&nbsp;&nbsp;&nbsp;</label>
                    <br />
                    <button
                      type="button"
                      className="btn_Cadastrar btn  btn-block "
                      id="btn_Cadastrar"
                      onClick={(e) => limpar(e)}
                      data-toggle="modal"
                      data-target=".bd-example-modal-md"
                    >
                      Cadastrar
                    </button>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </form>

            <div className=" table-responsive">
              <table className="table  table-hover table-striped table-light ">
                <thead className="text-white  bg-dark ">
                  <tr>
                    <th style={{ width: '75%' }} scope="col">
                      Nome
                    </th>
                    <th style={{ width: '10%' }} scope="col">
                      Status
                    </th>
                    <th style={{ width: '15%' }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {convenioPag.length > 0 ? (
                    convenioPag.map(function (convenios, i) {
                      return (
                        <>
                          <tr key={convenios.conv_id}>
                            <th>{convenios.conv_nome}</th>

                            <th>
                              {convenios.conv_status === 1
                                ? 'Ativo'
                                : 'Inativo'}
                            </th>
                            <td>
                              <img
                                src="alterar.png"
                                onClick={(e) => editar(convenios)}
                                className="btn-acao mb-2 mr-2"
                                data-toggle="modal"
                                data-target=".bd-example-modal-md"
                              />
                              <img
                                src="excluir.png"
                                onClick={(e) =>
                                  dadosModal(
                                    convenios.conv_nome,
                                    convenios.conv_id,
                                  )
                                }
                                className="btn-acao mb-2 mr-2"
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <th></th>

                        <th>Nenhum registro foi encontrado</th>
                        <th></th>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Registros por página'}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="card card-red">
              <div className="card-header">{Trash} Excluir Convênio</div>

              <div className="card-body">
                Tem certeza que deseja excluir o convênio{' '}
                <i>
                  <b className="text-danger">"</b>
                  {nomeModal}
                  <b className="text-danger">"</b>
                </i>{' '}
              </div>
              <div className="modal-footer">
                <button
                  className="btn  btn-sm btn-secondary "
                  type="button"
                  data-dismiss="modal"
                >
                  {Times}
                  &nbsp;Cancelar
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-sm btn_excluir "
                  onClick={(e) => save(e, 'delete', 0)}
                  type="button"
                  data-dismiss="modal"
                >
                  {Trash} Excluir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Convenio;
