import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { Edit, AngleDoubleLeft } from '../utils/Fontawesome';
import { Link, TablePagination } from '@material-ui/core';
import {
  removeAcento,
  mask_dateTimeBr,
  calcularIdade,
  mask_data,
} from '../utils/Mascara';
import ImprimirProtuario from './ImprimirProntuario';
export const adicionaZero = (numero) => {
  if (numero <= 9) return '0' + numero;
  else return numero;
};

export const adicionaZeroMinutes = (numero) => {
  if (numero === 0) return '0' + numero;
  else return numero;
};
const Atendimento = () => {
  let history = useHistory();
  const location = useLocation();

  const [cons_codigo, setCons_codigo] = useState('');
  const [cons_data, setCons_data] = useState('');
  const [cons_hora, setCons_hora] = useState('');
  const [cons_medico, setCons_medico] = useState(null);

  const [cons_paciente, setCons_paciente] = useState('');
  const [statusCons_paciente, setStatusCons_paciente] = useState([]);
  const [cons_convenio, setCons_convenio] = useState('');

  const [consultas, setConsultas] = useState([]);
  const [statusCons_convenio, setStatusCons_convenio] = useState('');
  const [cons_texto, setCons_texto] = useState('');
  const [cons_tipo, setCons_tipo] = useState('');
  const [statusCons_tipo, setStatusCons_tipo] = useState([
    { value: 'C', label: 'Consulta' },
    { value: 'P', label: '1. Consulta' },
    { value: 'R', label: 'Retorno' },
    { value: 'T', label: 'Receita' },
    { value: 'E', label: 'Exame' },
    { value: 'A', label: 'Ausente' },
  ]);

  /*const [statusCons_status, setStatusCons_status] = useState([
    { value: 0, label: "Agendado" },
    { value: 1, label: "Aguardando" },
    { value: 2, label: "Em atendimento" },
    { value: 3, label: "Atendido" },
    { value: 4, label: "Faltou " }, 
  ]);*/

  const [cons_status, setCons_status] = useState(0);
  const [pac_nascimento, setPac_nascimento] = useState('');
  const [pac_idade, setPac_idade] = useState('');

  const [navForm, setNavForm] = useState('');

  const [idModal, setIdModal] = useState('');
  const [nomeModal, setNomeModal] = useState('');
  const [idStatusModal, setStatusModal] = useState('');

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [consultaPag, setConsultaPag] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
     "rowsPerPage:" +
       rowsPerPage +
       " newPage " +
       newPage +
       " " +
       inicio +
       " ---- " +
       fim
   );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setConsultaPag(cidadePag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setConsultaPag(cidadePag);
  };

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }

    var res = location.pathname.split('/');

    getConsulta(res[3], res[2]);
    getPaciente();
    getConvenio();
  }, []);

  const getConsulta = async (paciente, codigo) => {
    let parametros =
      '?medico=' + sessionStorage.getItem('med_id') + '&paciente=' + paciente;

    await api
      .get('api/consultas' + parametros, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setConsultas(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        const cidadePag = [];
        if (fim > response.data.length) {
          fim = response.data.length;
        }
        for (let i = inicio; i < fim; i++) {
          cidadePag.push(response.data[i]);
        }
        setConsultaPag(cidadePag);

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].cons_codigo == codigo) {
            /*  { value: "C", label: "Consulta" },
                    { value: "P", label: "1. Consulta" },
                    { value: "R", label: "Retorno" },
                    { value: "T", label: "Receita" },
                    { value: "E", label: "Exame" }, */
            let tipo_nome = '';
            switch (response.data[i].cons_tipo) {
              case 'C':
                tipo_nome = 'Consulta';
                break;
              case 'P':
                tipo_nome = '1. Consulta';
                break;
              case 'R':
                tipo_nome = 'Retorno';
                break;
              case 'T':
                tipo_nome = 'Receita';
                break;
              default:
                tipo_nome = 'Exame';
            }
            setCons_codigo(response.data[i].cons_codigo);
            setData(response.data[i].pac_nascimento);
            setCons_data(response.data[i].cons_data.substring(0, 10));
            setCons_hora(response.data[i].cons_data.substring(11, 16));
            setCons_paciente({
              value: response.data[i].cons_paciente,
              label: response.data[i].pac_nome,
            });
            setCons_convenio({
              value: response.data[i].cons_convenio,
              label: response.data[i].conv_nome,
            });
            setCons_texto(response.data[i].cons_texto);
            setCons_tipo({
              value: response.data[i].cons_tipo,
              label: tipo_nome,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getConvenio = () => {
    api
      .get('api/convenios', {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayConvenios = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayConvenios.push({
            value: response.data[i].conv_id,
            label:
              response.data[i].conv_id + ' - ' + response.data[i].conv_nome,
          });
        }

        setStatusCons_convenio(arrayConvenios);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getPaciente = () => {
    api
      .get('api/pacientes', {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayPacientes = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayPacientes.push({
            value: response.data[i].pac_id,
            label: response.data[i].pac_id + ' - ' + response.data[i].pac_nome,
            convenio: response.data[i].pac_convenio,
          });
        }

        setStatusCons_paciente(arrayPacientes);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const handleChangePaciente = (paciente) => {
    setCons_paciente(paciente);

    let convenio = statusCons_convenio.filter(function (obj) {
      if (paciente.convenio == obj.value) {
        return obj.label;
      }
    });

    setCons_convenio(convenio[0]);
  };

  const handleChangeConvenio = (convenio) => {
    setCons_convenio(convenio);
  };

  const handleChangeTipo = (tipo) => {
    setCons_tipo(tipo);
  };

  const RemoveAspasChaves = (value) => {
    value = value.replace(/"/gi, '');
    value = value.replace('}', '');
    value = value.replace('{', '');
    return value;
  };

  const save = (e, method, status) => {
    e.preventDefault();
    e.stopPropagation();

    let data, url;

    if (cons_codigo) {
      method = 'put';
    } else {
      handleInfo('Falha ao carregar o codigo');
      return false;
    }

    if (method === 'put') {
      data = {
        cons_codigo: cons_codigo,
        cons_data: cons_data + 'T' + cons_hora + ':00',
        cons_medico: parseInt(sessionStorage.getItem('med_id')),
        cons_paciente: cons_paciente.value,
        cons_convenio: cons_convenio.value,
        cons_tipo: cons_tipo.value,
        cons_status: 3,
        cons_texto: cons_texto,
      };

      url = 'api/consultas/' + cons_codigo;
    } else if (method === 'delete') {
      //    url = url + "/" + idModal + "/" + status;
      url = 'api/consultas/' + idModal;
      data = {
        id: idModal,
        status: status,
      };
      setNomeModal('');
      setIdModal('');
    }

    // return false;
    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        history.push(`/atendimento`);
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setCons_codigo('');
    setCons_data('');
    setCons_hora('');
    setCons_medico('');
    setCons_paciente('');
    setCons_convenio('');

    setCons_texto('');
    setCons_tipo('');

    setCons_status(0);
  };

  const editar = (consulta) => {
    //  console.log(cliente);

    setCons_medico(consulta.cons_medico);
    setCons_data(consulta.cons_data);
    setCons_paciente(consulta.cons_paciente);
    setCons_convenio(consulta.cons_convenio);
    setCons_texto(consulta.cons_texto);
    setCons_tipo(consulta.cons_tipo);

    setCons_status(consulta.cons_status);
    setCons_codigo(consulta.cons_codigo);
    setCons_hora(consulta.cons_data.substring(11, 16));
  };

  const setData = (data) => {
    setPac_nascimento(data);
    setPac_idade(calcularIdade(data));
  };

  const VoltarHistory = () => {
    history.push('/atendimento');
  };
  /*
  const ImprimirProtuario = () => {
    window.print();
    var res = location.pathname.split('/');
    history.push(`/imprimirprontuario/${res[3]}/${res[2]}`);
  };*/
  function ImprimirProtuario(elem) {
    console.log(
      document.getElementsByClassName('container-fluid')[0].innerHTML,
    );
    var mywindow = window.open('', 'PRINT', 'height=1200,width=1200');

    mywindow.document.write('<html><head>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>teste de print</h1>');
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  var dataAtual = new Date();
  var dia = dataAtual.getDate();
  var mes = dataAtual.getMonth() + 1;
  var ano = dataAtual.getFullYear();
  var horas = dataAtual.getHours();
  var minutos = dataAtual.getMinutes();
  var segundos = dataAtual.getSeconds();

  var dataAtual =
    adicionaZero(dia) +
    '/' +
    adicionaZero(mes) +
    '/' +
    ano +
    ' ' +
    horas +
    ':' +
    adicionaZero(minutos) +
    ':' +
    adicionaZero(segundos);
  return (
    <>
      <div className="container-fluid">
        <div className="text-center page-header">
          <span>
            <b>São Francisco</b>
          </span>
          <br />
          <span>
            Avenida Dr. Jambeiro Costa n 850 - Centro - Leme - São Paulo
          </span>
          <br />
          <span>Cep 138102-36 Telefone (19) 3573-1487</span>
          <br />
        </div>
        <div className="text-left mt-5 ml-5 mr-5 mb-5 page-body">
          <span>
            Paciente: <b>Hugo Henrique Vianna Villa</b>
          </span>
          <br />
          <span>Endereço: . . . .</span>
          <br />
          <span>Data de Emissão: {dataAtual} </span>
          <br />
        </div>

        <h5 className="text-center text-dark mt-5 ml-5 mr-5 mb-5 font-weight-bold">
          Receita Médica
        </h5>
        <div className="text-left mt-5 ml-5 mr-5 mb-5 page-body">
          <span>USO INTERNO</span>
          <br />
          <span>omeprazol 20MG</span>
          <br />
          <span>TOMAR 1CP DE 12.12H</span>
          <br />
        </div>
        <div className="text-center  mt-5 ml-5 mr-5 mb-5 ">
          <span> Dr.(a) Rafael Muller de Carvalho</span>
          <span>
            <b>CRM 218482</b>
          </span>
        </div>
      </div>
    </>
  );
};
export default Atendimento;
