import React, { useState, useEffect, useContext } from 'react';
import { api } from '../utils/WebApiConfig';
import { handleError, handleInfo, handleSuccess } from '../utils/Toastify';

import { useHistory, useLocation } from 'react-router-dom';
import { User, Lock, Envelope, AddressCard } from '../utils/Fontawesome';
import { capitalize, removeEspacos } from '../utils/Mascara';
import { version } from '../../../package.json';
//import { UserContext } from "./UserContext";

const Login = () => {
  const [id, setId] = useState('');
  const [senha, setSenha] = useState('');
  const [senhaRepetir, setSenhaRepetir] = useState('');
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [navForm, setNavForm] = useState('');
  const [usu_email, setUsu_email] = useState('');

  //const {logar} = useContext(UserContext)
  let history = useHistory();
  const location = useLocation();

  const esqueciSenha = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let data = {
      med_email: usu_email,
    };

    api({
      method: 'PUT',
      url: '/api/medicos/alterarsenha/',
      data: data,
    })
      .then((response) => {
        handleSuccess('Link de alteração foi encaminhado para o seu email');
        document.getElementById('fecharmodal').click();
      })
      .catch((error) => {
        handleError(error);
      });
  };
  const onSubimit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    logar(id, senha);
  };
  const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };
  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    if (!sessionStorage.getItem('refresh')) {
      sessionStorage.setItem('refresh', 1);
      window.location.reload(false);
    }

    var uri = location.pathname.split('/');
    if (uri[1] && uri[2]) {
      ativarConta(uri[2], uri[3], uri[4]);
    }
  }, []);

  const ativarConta = async (email, codigo, tipo) => {
    let url = 'api/medicos/ativarconta/';
    if (tipo) {
      url = 'api/secretarias/ativarconta/';
    }

    await api({
      method: 'PUT',
      url: url,
      data: { email: email, codigo: codigo },
    })
      .then((response) => {
        handleInfo('Conta ativada com sucesso!');
      })
      .catch((error) => {
        //  catchResponse();
        if (error.response.data.error.description) {
          handleInfo(error.response.data.error.description);
        }
        //  catchResponse(error.response.status);
        console.log(error);
      });
  };
  const logar = (username, password) => {
    let url = 'api/tokens';
    let method = 'post';
    if (!username && !password) {
      handleError('Preencha todos os campos');
    }
    if (navForm === 'registrar') {
      url = 'api/medicos/registrar/';

      if (senhaRepetir !== password) {
        handleError('A senha a repetir não confere com a senha');
        return false;
      }
    }
    // url = 'api/twilio/send/';
    //  method = 'post';

    let data = {
      username: username,
      password: password,
      nome: nome,
      sobrenome: sobrenome,
    };

    api({
      method: method,
      url: url,
      data: data,
    })
      .then((response) => {
        if (isNumber(response.data)) {
          handleSuccess('Confirme seu registro em seu email');
        } else {
          if (response.data.cargo === 'M') {
            if (
              response.data.med_status === 1 ||
              response.data.med_status === 2
            ) {
              sessionStorage.setItem('med_id', response.data.med_id);
              sessionStorage.setItem('med_email', response.data.med_email);
              sessionStorage.setItem('senha', response.data.senha);
              sessionStorage.setItem(
                'med_nome',
                capitalize(
                  response.data.med_nome + ' ' + response.data.med_sobrenome,
                ),
              );
              sessionStorage.setItem(
                'med_conselho',
                response.data.med_conselho,
              );
              sessionStorage.setItem(
                'conselho_memed',
                response.data.conselho_memed,
              );

              sessionStorage.setItem(
                'med_sobrenome',
                response.data.med_sobrenome,
              );
              sessionStorage.setItem('med_status', response.data.med_status);
              sessionStorage.setItem(
                'secretaria_email',
                response.data.secretaria_email,
              );
              sessionStorage.setItem(
                'secretaria_id',
                response.data.secretaria_id,
              );
              sessionStorage.setItem('cargo', response.data.cargo);
              sessionStorage.setItem('token', response.data.access_token);

              if (response.data.med_status === 1) {
                history.push('/atendimento');
                //   history.push('/pendente');
              } else {
                history.push('/pendente');
              }
            } else {
              handleInfo('Login inválido');
            }
          } else {
            if (response.data.ms_status) {
              sessionStorage.setItem('med_id', response.data.med_id);
              sessionStorage.setItem('med_email', response.data.med_email);
              sessionStorage.setItem('med_nome', response.data.med_nome);
              sessionStorage.setItem(
                'med_sobrenome',
                response.data.med_sobrenome,
              );

              sessionStorage.setItem('med_status', response.data.med_status);
              sessionStorage.setItem('senha', response.data.senha);
              sessionStorage.setItem(
                'secretaria_email',
                response.data.secretaria_email,
              );
              sessionStorage.setItem(
                'secretaria_id',
                response.data.secretaria_id,
              );
              sessionStorage.setItem('cargo', response.data.cargo);
              sessionStorage.setItem('token', response.data.access_token);

              history.push('/consulta');
            } else {
              handleInfo('Login inválido');
            }
          }
        }
      })
      .catch((error) => {
        //  catchResponse();
        if (!error.response.data.error) {
          console.log(error);
          handleInfo('Login invalido.');
        } else {
          if (error.response.data.error.description) {
            handleInfo(error.response.data.error.description);
          }
        }

        //  catchResponse(error.response.status);
        console.log(error);
      });
  };
  const spanLogo = {
    textalign: 'center',
    marginBottom: '30px',
    display: 'inline-block',
  };
  const spanBtnSubimit = {
    width: '50%',
    textalign: 'right',
    display: 'inline-block',
    // marginBottom: '10px',
  };
  const imgLogo = {
    width: '150px',
    marginLeft: '-15px',
    border: '2px solid #fff',
    borderRadius: '100px',
  };

  //<div className="main" style={{ backgroundImage: "url(" + wood + ")" }}>
  return (
    <>
      <div
        style={{
          backgroundImage: "url('/wallpaper.jpg')",
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          textAlign: '-webkit-center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="main"
      >
        {' '}
        <div id="login" style={{ maxWidth: '500px' }}>
          <div
            className="card"
            style={{ border: '0px solid rgba(0,0,0,.125)' }}
          >
            <div
              className="card-header"
              style={{
                padding: '0px',
                borderTop: '0px',
                background: '#e6e8f4',
                borderRadius: '15px 15px 0px 0px',
              }}
            >
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="pills-login-tab"
                    data-toggle="pill"
                    href="#pills-login"
                    role="tab"
                    aria-controls="pills-login"
                    aria-selected="true"
                    style={{
                      borderRadius: '15px 15px 0px 0px',
                      width: 'calc(50% )',
                      position: 'absolute',
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;Login&nbsp;&nbsp;&nbsp;
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="pills-registrar-tab"
                    data-toggle="pill"
                    href="#pills-registrar"
                    role="tab"
                    aria-controls="pills-registrar"
                    aria-selected="false"
                    style={{
                      borderRadius: '15px 15px 0px 0px',
                      paddingLeft: '106px',
                    }}
                  >
                    Registrar-se
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="card-body"
              style={{
                background: '#e6e8f4',
                padding: '0px 15px',
                borderRadius: '0px 0px 25px 25px ',
              }}
            >
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-login"
                  onClick={(e) => setNavForm('login')}
                  role="tabpanel"
                  aria-labelledby="pills-login-tab"
                >
                  <form onSubmit={onSubimit}>
                    <fieldset className="clearfix_login">
                      <span style={spanLogo}>
                        {/*<img src={logo} alt="logo" style={imgLogo} />*/}
                        <h2 style={{ color: '#000', fontWeight: 'bold' }}>
                          {' '}
                          BlueDoctor
                        </h2>
                      </span>

                      <div className="row ">
                        <div
                          className="col-md-12 col-12 col-sm-12 mb-3"
                          style={{ paddingRight: '0px' }}
                        >
                          <div
                            className="input-group mb-3"
                            style={{
                              position: 'relative',
                              right: '50px',
                            }}
                          >
                            <div className="input-group-prepend">
                              {' '}
                              <div className="wrapper-tooltip">
                                <span
                                  style={{
                                    display: 'inline',
                                    borderRadius: '50px',
                                    background: '#a7c4d2 ',
                                    color: 'white',
                                    position: 'relative',
                                    right: '-37px',
                                    zIndex: '99',
                                  }}
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  {User}
                                </span>
                                <div
                                  className="tooltip"
                                  style={{
                                    left: '45px',
                                    width: '280px',
                                  }}
                                >
                                  Digite o seu Email neste campo.
                                </div>
                              </div>{' '}
                            </div>
                            <input
                              className="form-control"
                              type="text"
                              id="email"
                              placeholder="Email"
                              required
                              onChange={(e) =>
                                setId(
                                  removeEspacos(e.target.value.toLowerCase()),
                                )
                              }
                              value={id}
                              style={{
                                position: 'absolute',
                                minWidth: '100%',
                                marginLeft: '39px',
                                padding: '7px 29px 7px 52px',
                                borderRadius: '50px 0px 0px 50px',
                                borderBottom: '6px solid #a7c4d2 ',
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-12 col-12 col-sm-12 mb-3"
                          style={{ paddingRight: '0px' }}
                        >
                          <div
                            className="input-group mb-3"
                            style={{
                              position: 'relative',
                              right: '50px',
                            }}
                          >
                            <div
                              className="input-group-prepend"
                              onClick={(e) =>
                                (document.getElementById('senha').type =
                                  document.getElementById('senha').type ==
                                  'text'
                                    ? 'password'
                                    : 'text')
                              }
                            >
                              {' '}
                              <div className="wrapper-tooltip">
                                <span
                                  style={{
                                    display: 'inline',
                                    borderRadius: '50px',
                                    background: '#a7c4d2 ',
                                    color: 'white',
                                    position: 'relative',
                                    right: '-37px',
                                    zIndex: '99',
                                  }}
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  {Lock}
                                </span>
                                <div
                                  className="tooltip"
                                  style={{
                                    left: '45px',
                                    width: '280px',
                                  }}
                                >
                                  Mínimo de 6 e máximo de 12 caracteres, pelo
                                  menos uma letra maiúscula, uma letra
                                  minúscula, um número e um caractere especial
                                </div>
                              </div>{' '}
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              id="senha"
                              placeholder="Senha"
                              required
                              onChange={(e) =>
                                setSenha(removeEspacos(e.target.value))
                              }
                              value={senha}
                              maxLength="12"
                              minLength="6"
                              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                              style={{
                                position: 'absolute',
                                minWidth: '100%',
                                marginLeft: '39px',
                                padding: '7px 29px 7px 52px',
                                borderRadius: '50px 0px 0px 50px',
                                borderBottom: '6px solid #a7c4d2 ',
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {/*   <span className="icons">{User}</span>
                        <label htmlFor="email"></label>
                        <input
                          type="text"
                          id="email"
                          placeholder="Email"
                          required
                          onChange={(e) => setId(e.target.value)}
                          value={id}
                        />
                      </p>
                      <p>
                        <div className="wrapper-tooltip">
                          <span className="icons">{Lock}</span>
                          <div className="tooltip">
                            Mínimo de 6 e máximo de 12 caracteres, pelo menos
                            uma letra maiúscula, uma letra minúscula, um número
                            e um caractere especial
                          </div>
                        </div>
                        <label htmlFor="senha"></label>
                        <input
                          type="password"
                          id="senha"
                          placeholder="Senha"
                          required
                          onChange={(e) => setSenha(e.target.value)}
                          value={senha}
                          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                        />*/}

                      <div>
                        <span style={spanBtnSubimit}>
                          <input
                            type="submit"
                            value="&nbsp;&nbsp;Login&nbsp;&nbsp;"
                          />
                        </span>
                      </div>
                    </fieldset>
                    <div className="clearfix_login"></div>
                  </form>
                  <div className="row mt-3 mb-1 ">
                    <div className="col-4"></div>
                    <div className="col-4">
                      {' '}
                      <b
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                        style={{
                          color: '#000',
                          fontWeight: 'bold',
                          fontSize: '12px',
                        }}
                      >
                        Esqueceu a senha?
                      </b>
                    </div>
                    <div className="col-4" style={{ textAlign: 'right' }}>
                      {' '}
                      <span
                        style={{
                          color: '#000',
                          fontWeight: 'bold',
                          marginBottom: '3px',
                          fontSize: '12px',
                          marginRight: '3px',
                        }}
                      >
                        {'Versão: ' + version}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-registrar"
                  role="tabpanel"
                  onClick={(e) => setNavForm('registrar')}
                  aria-labelledby="pills-registrar-tab"
                >
                  <form onSubmit={onSubimit}>
                    <fieldset className="clearfix_login">
                      <span style={spanLogo}>
                        {/*<img src={logo} alt="logo" style={imgLogo} />*/}
                        <h2 style={{ color: '#000', fontWeight: 'bold' }}>
                          {' '}
                          BlueDoctor
                        </h2>
                      </span>
                      {/*  <span
                        style={{
                          color: '#000',
                          fontWeight: 'bold',
                          marginBottom: '3px',
                        }}
                      >
                        {'Versão: ' + version}
                      </span>*/}
                      <div className="row ">
                        <div
                          className="col-md-6 col-12 col-sm-6 mb-3"
                          style={{ paddingRight: '0px' }}
                        >
                          <div
                            className="input-group mb-3"
                            style={{
                              position: 'relative',
                              right: '50px',
                            }}
                          >
                            <div className="input-group-prepend">
                              {' '}
                              <div className="wrapper-tooltip">
                                <span
                                  style={{
                                    display: 'inline',
                                    borderRadius: '50px',
                                    background: '#a7c4d2 ',
                                    color: 'white',
                                    position: 'relative',
                                    right: '-37px',
                                    zIndex: '99',
                                  }}
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  {User}
                                </span>
                                <div
                                  className="tooltip"
                                  style={{
                                    left: '45px',
                                    width: '280px',
                                  }}
                                >
                                  Nome do usuario.
                                </div>
                              </div>{' '}
                            </div>
                            <input
                              className="form-control"
                              type="text"
                              id="registro_nome"
                              placeholder="Nome"
                              required
                              onChange={(e) => setNome(e.target.value)}
                              value={nome}
                              style={{
                                position: 'absolute',
                                minWidth: '100%',
                                marginLeft: '39px',
                                padding: '7px 29px 7px 52px',
                                borderRadius: '50px 0px 0px 50px',
                                borderBottom: '6px solid #a7c4d2 ',
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-12 col-sm-6 mb-3"
                          style={{ paddingRight: '0px' }}
                        >
                          <div
                            className="input-group mb-3"
                            style={{
                              position: 'relative',
                              right: '50px',
                            }}
                          >
                            <input
                              className="form-control"
                              type="text"
                              id="registro_sobrenome"
                              placeholder="Sobrenome"
                              required
                              onChange={(e) => setSobrenome(e.target.value)}
                              value={sobrenome}
                              style={{
                                position: 'absolute',
                                minWidth: '100%',
                                marginLeft: '39px',
                                padding: '7px 29px 7px 52px',
                                borderRadius: '50px 0px 0px 50px',
                                borderBottom: '6px solid #a7c4d2 ',
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-12 col-12 col-sm-12 mb-3"
                          style={{ paddingRight: '0px' }}
                        >
                          <div
                            className="input-group mb-3"
                            style={{
                              position: 'relative',
                              right: '50px',
                            }}
                          >
                            <div className="input-group-prepend">
                              {' '}
                              <div className="wrapper-tooltip">
                                <span
                                  style={{
                                    display: 'inline',
                                    borderRadius: '50px',
                                    background: '#a7c4d2 ',
                                    color: 'white',
                                    position: 'relative',
                                    right: '-37px',
                                    zIndex: '99',
                                  }}
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  {Envelope}
                                </span>
                                <div
                                  className="tooltip"
                                  style={{
                                    left: '45px',
                                    width: '280px',
                                  }}
                                >
                                  Email da conta , será utilizado para realizar
                                  o login
                                </div>
                              </div>{' '}
                            </div>
                            <input
                              className="form-control"
                              type="text"
                              id="registro_email"
                              placeholder="Email"
                              required
                              onChange={(e) =>
                                setId(
                                  removeEspacos(e.target.value.toLowerCase()),
                                )
                              }
                              value={id}
                              style={{
                                position: 'absolute',
                                minWidth: '100%',
                                marginLeft: '39px',
                                padding: '7px 29px 7px 52px',
                                borderRadius: '50px 0px 0px 50px',
                                borderBottom: '6px solid #a7c4d2 ',
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-12 col-12 col-sm-12 mb-3"
                          style={{ paddingRight: '0px' }}
                        >
                          <div
                            className="input-group mb-3"
                            style={{
                              position: 'relative',
                              right: '50px',
                            }}
                          >
                            <div
                              className="input-group-prepend"
                              onClick={(e) =>
                                (document.getElementById(
                                  'registro_senha',
                                ).type =
                                  document.getElementById('registro_senha')
                                    .type == 'text'
                                    ? 'password'
                                    : 'text')
                              }
                            >
                              {' '}
                              <div className="wrapper-tooltip">
                                <span
                                  style={{
                                    display: 'inline',
                                    borderRadius: '50px',
                                    background: '#a7c4d2 ',
                                    color: 'white',
                                    position: 'relative',
                                    right: '-37px',
                                    zIndex: '99',
                                  }}
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  {Lock}
                                </span>
                                <div
                                  className="tooltip"
                                  style={{
                                    left: '45px',
                                    width: '280px',
                                  }}
                                >
                                  Mínimo de 6 e máximo de 12 caracteres, pelo
                                  menos uma letra maiúscula, uma letra
                                  minúscula, um número e um caractere especial
                                </div>
                              </div>{' '}
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              id="registro_senha"
                              placeholder="Senha"
                              required
                              onChange={(e) =>
                                setSenha(removeEspacos(e.target.value))
                              }
                              value={senha}
                              maxLength="12"
                              minLength="6"
                              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,12}$"
                              style={{
                                position: 'absolute',
                                minWidth: '100%',
                                marginLeft: '39px',
                                padding: '7px 29px 7px 52px',
                                borderRadius: '50px 0px 0px 50px',
                                borderBottom: '6px solid #a7c4d2 ',
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-12 col-12 col-sm-12 mb-3"
                          style={{ paddingRight: '0px' }}
                        >
                          <div
                            className="input-group mb-3"
                            style={{
                              position: 'relative',
                              right: '50px',
                            }}
                          >
                            <div
                              className="input-group-prepend"
                              onClick={(e) =>
                                (document.getElementById('senhaRepetir').type =
                                  document.getElementById('senhaRepetir')
                                    .type == 'text'
                                    ? 'password'
                                    : 'text')
                              }
                            >
                              {' '}
                              <div className="wrapper-tooltip">
                                <span
                                  style={{
                                    display: 'inline',
                                    borderRadius: '50px',
                                    background: '#a7c4d2 ',
                                    color: 'white',
                                    position: 'relative',
                                    right: '-37px',
                                    zIndex: '99',
                                  }}
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  {Lock}
                                </span>
                                <div
                                  className="tooltip"
                                  style={{
                                    left: '45px',
                                    width: '280px',
                                  }}
                                >
                                  Repita a senha com o mínimo de 6 e máximo de
                                  12 caracteres, pelo menos uma letra maiúscula,
                                  uma letra minúscula, um número e um caractere
                                  especial
                                </div>
                              </div>{' '}
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              id="senhaRepetir"
                              placeholder="Repita a Senha"
                              required
                              onChange={(e) =>
                                setSenhaRepetir(removeEspacos(e.target.value))
                              }
                              value={senhaRepetir}
                              maxLength="12"
                              minLength="6"
                              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,12}$"
                              style={{
                                position: 'absolute',
                                minWidth: '100%',
                                marginLeft: '39px',
                                padding: '7px 29px 7px 52px',
                                borderRadius: '50px 0px 0px 50px',
                                borderBottom: '6px solid #a7c4d2 ',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <p>
                        <span className="icons">{User}</span>
                        <label htmlFor="registro_nome"></label>
                        <input
                          type="text"
                          id="registro_nome"
                          placeholder="Nome"
                          required
                          onChange={(e) => setNome(e.target.value)}
                          value={nome}
                        />
                      </p>
                      <p>
                        <span className="icons">{Envelope}</span>
                        <label htmlFor="registro_email"></label>
                        <input
                          type="text"
                          id="registro_email"
                          placeholder="Email"
                          required
                          onChange={(e) => setId(e.target.value)}
                          value={id}
                        />
                      </p>
                      <p>
                        <div className="wrapper-tooltip">
                          <span className="icons">{Lock}</span>
                          <div className="tooltip">
                            Mínimo de 6 e máximo de 12 caracteres, pelo menos
                            uma letra maiúscula, uma letra minúscula, um número
                            e um caractere especial
                          </div>
                        </div>
                        <label htmlFor="registro_senha"></label>
                        <input
                          type="password"
                          id="registro_senha"
                          placeholder="Senha"
                          required
                          onChange={(e) => setSenha(e.target.value)}
                          value={senha}
                          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,12}$"
                        />{' '}
                      </p>
                    */}{' '}
                      <div>
                        <span style={spanBtnSubimit}>
                          <input type="submit" value="Registrar" />
                        </span>
                      </div>
                      <div className="row mt-3 mb-1 ">
                        <div className="col-4"></div>
                        <div className="col-4">
                          {' '}
                          <b
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            style={{
                              color: '#000',
                              fontWeight: 'bold',
                              fontSize: '12px',
                            }}
                          >
                            Esqueceu a senha?
                          </b>
                        </div>
                        <div className="col-4" style={{ textAlign: 'right' }}>
                          {' '}
                          <span
                            style={{
                              color: '#000',
                              fontWeight: 'bold',
                              marginBottom: '3px',
                              fontSize: '12px',
                              marginRight: '3px',
                            }}
                          >
                            {'Versão: ' + version}
                          </span>
                        </div>
                      </div>
                    </fieldset>
                    <div className="clearfix_login"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{
                background: '#7d9aa2',
                borderRadius: '25px 25px 0px 0px ',
              }}
            >
              <h5
                className="modal-title"
                style={{ color: '#fff' }}
                id="exampleModalLongTitle"
              >
                {AddressCard} Esqueci minha senha
              </h5>
              <button
                type="button"
                className="close"
                id="fecharmodal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="text-white" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{
                background: '#e6e8f4',
                borderRadius: '0px 0px 25px 25px',
              }}
            >
              {' '}
              <legend style={{ fontSize: '14px' }}>
                Insira o email atual de sua conta para a equipe do suporte possa
                lhe enviar o link de alteração de senha.
              </legend>
              <form>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <input
                      type="email"
                      className="form-control"
                      id="usu_email"
                      value={usu_email}
                      placeholder="Email"
                      onChange={(e) => setUsu_email(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-2"> </div>
                  <div className="col-sm-8">
                    <button
                      type="button"
                      onClick={(e) => esqueciSenha(e)}
                      className="btn btn-block esqueci-senha "
                    >
                      Enviar
                    </button>
                  </div>
                  <div className="col-sm-2"> </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
