import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { api } from '../utils/WebApiConfig';
import axios from 'axios';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import {
  Times,
  Edit,
  Save,
  UserClock,
  ClipboardList,
  CalendarAlt,
  Clipboard,
  Search,
  Check,
  AngleDoubleLeft,
  Whatsapp,
} from '../utils/Fontawesome';
import { capitalize, removeAcento, mask_data } from '../utils/Mascara';
import { TablePagination } from '@material-ui/core';

const Atendimento = (props) => {
  let history = useHistory();

  const [med_id, setMed_id] = useState('');
  const [med_nome, setMed_nome] = useState('');
  const [med_email, setMed_email] = useState('');
  const [med_conselho, setMed_conselho] = useState('');
  const [med_status, setMed_status] = useState(1);

  const [med_endereco, setMed_endereco] = useState('');
  const [med_numero, setMed_numero] = useState('');
  const [med_bairro, setMed_bairro] = useState('');
  const [med_cep, setMed_cep] = useState('');
  const [med_cidade, setMed_cidade] = useState('');
  const [statusMed_cidade, setStatusMed_cidade] = useState([]);
  const [med_telefone, setMed_telefone] = useState('');
  const [med_senha, setMed_senha] = useState('');
  const [secretaria_senha, setSecretaria_senha] = useState('');
  const [secretaria_email, setSecretaria_email] = useState('');
  const [convenio, setConvenio] = useState('');
  const [statusConvenio, setStatusConvenio] = useState('');
  const [medicoExcel, setMedicoExcel] = useState([]);

  const [weekendsVisible, setWeekendsVisible] = useState(true);

  const [consultaEvents, setConsultaEvents] = useState([]);
  const [med_horaini, setMed_horaini] = useState(null);
  const [med_horafim, setMed_horafim] = useState(null);
  const [med_sessao, setMed_sessao] = useState(null);
  const [cons_codigo, setCons_codigo] = useState('');
  const [cons_data, setCons_data] = useState('');
  const [cons_hora, setCons_hora] = useState('');
  const [cons_medico, setCons_medico] = useState(null);
  const [statusCons_medico, setStatusCons_medico] = useState([]);
  const [cons_paciente, setCons_paciente] = useState('');
  const [statusCons_paciente, setStatusCons_paciente] = useState([]);
  const [pac_filter, setPac_filter] = useState([]);
  const [cons_convenio, setCons_convenio] = useState('');
  const [buscarPaciente, setBuscarPaciente] = useState(null);
  const [consultas, setConsultas] = useState([]);
  const [statusCons_convenio, setStatusCons_convenio] = useState('');
  const [cons_procedimento, setCons_procedimento] = useState('');
  const [cons_texto, setCons_texto] = useState('');
  const [cons_tipo, setCons_tipo] = useState('');
  const [statusCons_tipo, setStatusCons_tipo] = useState([
    { value: 'C', label: 'Consulta' },
    { value: 'P', label: '1. Consulta' },
    { value: 'R', label: 'Retorno' },
    { value: 'T', label: 'Receita' },
    { value: 'E', label: 'Exame' },
    { value: 'A', label: 'Ausente' },
  ]);

  /*const [statusCons_status, setStatusCons_status] = useState([
    { value: 0, label: "Agendado" },
    { value: 1, label: "Aguardando" },
    { value: 2, label: "Em atendimento" },
    { value: 3, label: "Atendido" },
    { value: 4, label: "Faltou " }, 
  ]);*/
  const [disable_campos, setDisable_campos] = useState(true);
  const [cons_esperando, setCons_esperando] = useState(0);
  const [cons_status, setCons_status] = useState(0);

  const [medicos, setMedicos] = useState([]);
  const [idModal, setIdModal] = useState('');
  const [nomeModal, setNomeModal] = useState('');
  const [idStatusModal, setStatusModal] = useState('');

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [consultaPag, setConsultaPag] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
     "rowsPerPage:" +
       rowsPerPage +
       " newPage " +
       newPage +
       " " +
       inicio +
       " ---- " +
       fim
   );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setConsultaPag(cidadePag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setConsultaPag(cidadePag);
  };

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }

    getConsulta();
    getPaciente();
    getConvenio();
  }, []);
  /* useEffect(() => {
    if (document.getElementById('buscarPaciente')) {
      document.getElementById('buscarPaciente').disabled = false;
    }
  }, [consultas]);*/

  const getConsulta = (data, paciente) => {
    if (!data) {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();

      data = yyyy + '-' + mm + '-' + dd;
      setCons_data(data);
    }
    let parametro = '';
    if (paciente) {
      parametro =
        '?medico=' +
        sessionStorage.getItem('med_id') +
        '&status=1&select=1&orderby=cons_data&data=' +
        data +
        '&paciente=' +
        paciente;
    } else {
      parametro =
        '?medico=' +
        sessionStorage.getItem('med_id') +
        '&status=1&select=1&orderby=cons_data&order=asc&data=' +
        data;
    }

    api
      .get('api/consultas' + parametro, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayConsultas = [];

        setConsultas(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        const cidadePag = [];
        if (fim > response.data.length) {
          fim = response.data.length;
        }
        for (let i = inicio; i < fim; i++) {
          cidadePag.push(response.data[i]);
        }
        setConsultaPag(cidadePag);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getConvenio = () => {
    api
      .get('api/convenios', {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayConvenios = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayConvenios.push({
            value: response.data[i].conv_id,
            label:
              response.data[i].conv_id + ' - ' + response.data[i].conv_nome,
          });
        }

        setStatusCons_convenio(arrayConvenios);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getPaciente = (paciente, medico) => {
    let medico_id = medico ? medico : sessionStorage.getItem('med_id');
    let parametro = 'api/pacientes?medico=' + medico_id;
    if (paciente && paciente.length > 2) {
      parametro = 'api/pacientes?medico=' + medico_id + '&pac_nome=' + paciente;
    }
    api
      .get(parametro, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayPacientes = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayPacientes.push({
            value: response.data[i].pac_id,
            label: response.data[i].pac_id + ' - ' + response.data[i].pac_nome,
            convenio: response.data[i].pac_convenio,
          });
        }

        setStatusCons_paciente(arrayPacientes);
        if (response.data.length > 0 && paciente) {
          document.getElementById('buscar_paciente').style.display = 'none';
          document.getElementById('select_paciente').style.display = '';
          if (document.getElementById('cons_paciente')) {
            document.querySelector(
              '#cons_paciente > div > div.css-1hwfws3 > div.css-1wa3eu0-placeholder > div',
            ).style.marginLeft = '35px';
          }
        } else {
          if (paciente) {
            handleInfo('Nenhum paciente encontrado por esse termo');
          }

          document.getElementById('buscar_paciente').style.display = '';
          document.getElementById('select_paciente').style.display = 'none';
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const handleChangePaciente = (paciente) => {
    setCons_paciente(paciente);

    let convenio = statusCons_convenio.filter(function (obj) {
      if (paciente.convenio == obj.value) {
        return obj.label;
      }
    });

    setCons_convenio(convenio[0]);
  };

  const handleChangePacFilter = (paciente) => {
    setPac_filter(paciente);
    var data = new Date();
    data.setDate(data.getDate() - 14);
    data = data.toLocaleString();
    data = mask_data(data.substring(0, 10));
    getConsulta(data, paciente.value);
  };
  const handleChangeConvenio = (convenio) => {
    setCons_convenio(convenio);
  };

  const handleChangeMedico = (medico) => {
    setCons_medico(medico);
    getConsulta(medico.value);
    setMed_horaini(medico.med_horaini);
    setMed_horafim(medico.med_horafim);
    setMed_sessao(medico.med_sessao);
  };

  const handleChangeTipo = (tipo) => {
    setCons_tipo(tipo);
  };

  const RemoveAspasChaves = (value) => {
    value = value.replace(/"/gi, '');
    value = value.replace('}', '');
    value = value.replace('{', '');
    return value;
  };

  const save = (e, method, status) => {
    e.preventDefault();
    e.stopPropagation();

    let data, url;
    if (!cons_medico.value) {
      handleInfo('Selecione um médico');
      return false;
    }
    if (cons_codigo) {
      method = 'put';
    } else {
      handleInfo('Falha ao carregar o codigo');
      return false;
    }

    if (method === 'put') {
      data = {
        cons_codigo: cons_codigo,
        cons_data: cons_data,
        cons_medico: cons_medico,
        cons_paciente: cons_paciente,
        cons_convenio: cons_convenio,
        cons_tipo: cons_tipo,
        cons_esperando: cons_esperando,
        cons_status: cons_status,
        cons_texto: cons_texto,
      };

      url = 'api/consultas/' + cons_codigo;
    } else if (method === 'delete') {
      //    url = url + "/" + idModal + "/" + status;
      url = 'api/consultas/' + idModal;
      data = {
        id: idModal,
        status: status,
      };
      setNomeModal('');
      setIdModal('');
    }

    //   return false;
    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        getConsulta();
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setCons_codigo('');
    setCons_data('');
    setCons_hora('');
    setCons_medico('');
    setCons_paciente('');
    setCons_convenio('');
    setCons_procedimento('');
    setCons_texto('');
    setCons_tipo('');
    setCons_esperando(0);
    setCons_status(0);
  };

  const sendWhatsapp = async (codigo, index) => {
    let data = {
      cons_codigo: codigo ? codigo : cons_codigo,
      user_senha: sessionStorage.getItem('senha'),
      user_id:
        sessionStorage.getItem('cargo') === 'M'
          ? sessionStorage.getItem('med_email')
          : sessionStorage.getItem('secretaria_email'),
    };
    await axios
      .post('https://tcdoc.viaconect.com/api/twilio/send/', data)
      .then((response) => {
        for (let i = 0; i < consultaPag.length; i++) {
          if (i == index) {
            if (consultaPag[i]['cons_avisar_zap'] > 0) {
              var aux = (consultaPag[i]['cons_avisar_zap'] = 0);
              consultaPag[i]['cons_avisar_zap'] = aux;
              document.querySelector('#zap' + i).style.color = 'red';
              document.querySelector(
                '#zap' + i,
              ).innerHTML = `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>`;
            } else {
              var aux = (consultaPag[i]['cons_avisar_zap'] = 1);
              consultaPag[i]['cons_avisar_zap'] = aux;
              document.querySelector('#zap' + i).style.color = 'green';
              document.querySelector(
                '#zap' + i,
              ).innerHTML = `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>`;
            }
          }
        }

        console.log(consultaPag);
        setConsultaPag(consultaPag);
        handleSuccess(response.headers.statusmessage);
        //getConsulta();
        // handleSuccess('Enviado com sucesso !');
      })
      .catch((error) => {
        //  catchResponse();
        //  catchResponse(error.response.status);
        console.log(error);
      });
  };

  const pacienteProntuario = (consulta) => {
    let data,
      url,
      status = consulta.cons_status;
    if (consulta.cons_status === 0 || consulta.cons_status === 1) {
      status = 2;
    } else {
      if (consulta.cons_status === 2) {
        handleSuccess('Consulta em andamento!');
      }
      history.push(
        `/prontuario/${consulta.cons_codigo}/${consulta.cons_paciente}`,
      );
      return false;
    }
    data = {
      cons_codigo: consulta.cons_codigo,
      cons_data: consulta.cons_data,
      cons_medico: parseInt(sessionStorage.getItem('med_id')),
      cons_paciente: consulta.cons_paciente,
      cons_convenio: consulta.cons_convenio,
      cons_tipo: consulta.cons_tipo,
      cons_status: status,
      cons_texto: consulta.cons_texto,
    };

    url = 'api/consultas/' + consulta.cons_codigo;

    //  console.log(values);

    api({
      method: 'PUT',
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess('Atendimento ao paciente foi iniciado!');
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        history.push(
          `/prontuario/${consulta.cons_codigo}/${consulta.cons_paciente}`,
        );
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const editar = (consulta) => {
    setCons_medico(consulta.cons_medico);
    setCons_data(consulta.cons_data);
    setCons_paciente(consulta.cons_paciente);
    setCons_convenio(consulta.cons_convenio);
    setCons_texto(consulta.cons_texto);
    setCons_tipo(consulta.cons_tipo);
    setCons_esperando(consulta.cons_esperando);
    setCons_status(consulta.cons_status);
    setCons_codigo(consulta.cons_codigo);
    setCons_hora(consulta.cons_data.substring(11, 16));
  };

  const dataFiltro = () => {
    if (cons_data.length === 10) {
      setPac_filter([]);
      getConsulta(cons_data);
    }
  };
  const formatarPaciente = (paciente) => {
    let stringArray = paciente.split('-');
    return stringArray[1];
  };
  const contatoZap = (contato_zap) => {
    if (contato_zap.length > 0) {
      contato_zap = contato_zap.replace('-', '');
      contato_zap = contato_zap.replace(' ', '');
      contato_zap = contato_zap.replace('(', '');
      contato_zap = contato_zap.replace(')', '');
      return contato_zap;
    }
  };
  const cancelarPaciente = () => {
    setStatusCons_paciente([]);
    setBuscarPaciente('');
    setPac_filter('');
    document.getElementById('select_paciente').style.display = 'none';
    document.getElementById('buscar_paciente').style.display = '';
  };
  return (
    <>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            {' '}
            {props.cardtitulo ? CalendarAlt : UserClock}
            {props.cardtitulo
              ? ' ' + props.cardtitulo + ' '
              : ' Atendimento do dia '}
            {AngleDoubleLeft}{' '}
          </div>

          <div className="card-body ">
            <br />

            <div className="form-group">
              <div className="form-row">
                <div className="col-md-4 col-12">
                  <div className="row">
                    <div
                      className="col-md-10 col-12 col-sm-12"
                      style={{ paddingRight: '0px' }}
                    >
                      <label htmlFor="filtro">Filtrar Tabela:</label>
                      <div
                        class="input-group mb-3"
                        style={{ position: 'relative', right: '50px' }}
                        onClick={(e) => dataFiltro()}
                      >
                        <div class="input-group-prepend">
                          <span
                            style={{
                              display: 'inline',
                              borderRadius: '50px',
                              background: '#a7c4d2 ',
                              color: 'white',
                              position: 'relative',
                              right: '-37px',
                              zIndex: '99',
                            }}
                            class="input-group-text"
                            id="basic-addon1"
                          >
                            {Search}
                          </span>
                        </div>
                        <input
                          id="filtro"
                          className="form-control"
                          style={{
                            display: 'inline',
                            padding: '7px 29px 7px 52px',
                            borderRadius: '50px 0px 0px 50px',
                            borderBottom: '6px solid #a7c4d2 ',
                          }}
                          type="date"
                          onChange={(e) => setCons_data(e.target.value)}
                          value={cons_data}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {props.cardtitulo ? (
                  <>
                    <div
                      className="  col-md-4 mb-3"
                      style={{ paddingRight: '0px' }}
                      id="buscar_paciente"
                    >
                      <label htmlFor="buscarPaciente">Buscar Paciente:</label>
                      <div
                        class="input-group mb-3"
                        style={{ position: 'relative', right: '50px' }}
                      >
                        <div class="input-group-prepend">
                          <span
                            onClick={(e) => getPaciente(buscarPaciente)}
                            style={{
                              display: 'inline',
                              borderRadius: '50px',
                              background: '#a7c4d2 ',
                              color: 'white',
                              position: 'relative',
                              right: '-37px',
                              zIndex: '99',
                            }}
                            class="input-group-text"
                            id="basic-addon1"
                          >
                            {Search}
                          </span>
                        </div>{' '}
                        <form autoComplete="off">
                          <input
                            id="buscarPaciente"
                            className="form-control"
                            style={{
                              display: 'inline',
                              padding: '7px 29px 7px 52px',
                              borderRadius: '50px 0px 0px 50px',
                              borderBottom: '6px solid #a7c4d2 ',
                            }}
                            type="text"
                            placeholder="Min 3 letras"
                            onChange={(e) =>
                              setBuscarPaciente(removeAcento(e.target.value))
                            }
                            value={buscarPaciente}
                            autoComplete="off"
                          />
                        </form>
                      </div>
                    </div>
                    <div
                      style={{ display: 'none' }}
                      className="  col-md-4 mb-3"
                      id="select_paciente"
                    >
                      <label htmlFor="cons_paciente">Paciente:</label>
                      <div
                        class="input-group "
                        style={{ display: 'block', position: 'relative' }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                          }}
                          class="input-group-prepend"
                        >
                          <span
                            style={{
                              display: 'inline',
                              borderRadius: '50px',
                              background: '#f38692 ',
                              color: 'white',
                              position: 'relative',
                              right: '5px',
                              zIndex: '99',
                            }}
                            onClick={(e) => cancelarPaciente()}
                            class="input-group-text"
                            id="basic-addon1"
                          >
                            {Times}
                          </span>
                        </div>
                        <Select
                          id="cons_paciente"
                          value={pac_filter}
                          onChange={handleChangePacFilter}
                          options={statusCons_paciente}
                          placeholder={<div>Selecione</div>}
                          disabled={disable_campos}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                            width: '10%',
                          }}
                          type="password"
                          id="cons_paciente_html5_validate"
                          value={cons_paciente}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-2 mb-3">
                      <label>&nbsp;&nbsp;&nbsp;&nbsp; </label>
                      <button
                        className="btn btn_Cadastrar  btn-block "
                        style={{ width: '200px' }}
                        onClick={(e) => history.push('/consulta')}
                      >
                        {AngleDoubleLeft} Voltar ao calendario
                      </button>
                    </div>
                    <div className="col-md-3"></div>
                  </>
                ) : (
                  <div className="col-md-9"></div>
                )}
              </div>
            </div>

            {
              // se props.cardtitulo estiver preenchido entao mostra a tabela de comunicado da tela consulta.js (calendario) , senão mostra a tela normal de atendimento

              props.cardtitulo ? (
                <div className="table-responsive">
                  <table className="table table-hover table-striped table-light">
                    <thead className="text-white bg-dark">
                      <tr>
                        <th scope="col"></th>
                        <th style={{ width: '85%' }} scope="col">
                          Paciente
                        </th>

                        {/* <th scope="col">Proprietario</th>*/}

                        <th scope="col">Hora</th>

                        <th style={{ width: '10%' }} scope="col">
                          Envio
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {consultaPag.length > 0 &&
                        consultaPag.map((consulta, i) => {
                          console.log(JSON.stringify(consulta));
                          var tipo_consulta = '';

                          switch (consulta.cons_tipo) {
                            case 'C':
                              tipo_consulta = 'Consulta';
                              break;
                            case 'P':
                              tipo_consulta = '1. Consulta';
                              break;
                            case 'R':
                              tipo_consulta = 'Retorno';
                              break;
                            case 'T':
                              tipo_consulta = 'Receita';
                              break;
                            case 'E':
                              tipo_consulta = 'Exame';
                              break;
                            case 'A':
                              tipo_consulta = 'Ausente';
                              break;
                          }
                          var statusCor = '';
                          switch (consulta.cons_status) {
                            case 1:
                              // paciente em espera no consultorio
                              statusCor = '#2196f3';
                              break;
                            case 2:
                              // atendimento em andamento
                              statusCor = '#fec107';
                              break;
                            case 3:
                              // atendimento concluido
                              statusCor = '#01a777';
                              break;
                            case 4:
                              // ausente
                              statusCor = '#eb2d2d';
                              break;
                            default:
                              // apenas agendado
                              statusCor = '#aa528c';
                          }
                          return (
                            <>
                              <tr key={consulta.cons_codigo}>
                                <td>
                                  <>
                                    <div
                                      style={{
                                        width: '17px',
                                        height: '17px',
                                        background: statusCor,
                                        borderRadius: '50%',
                                      }}
                                      className="color-51comvc text-lowercase"
                                    ></div>
                                  </>
                                </td>
                                <th>
                                  {consulta.pac_nome}
                                  <br />
                                  <i
                                    style={{
                                      fontSize: '12px',
                                    }}
                                  >
                                    {'Data : ' +
                                      mask_data(
                                        consulta.cons_data.substring(0, 10),
                                      )}
                                  </i>
                                  <br />
                                  <i
                                    style={{
                                      fontSize: '12px',
                                    }}
                                  >
                                    {'Celular : ' + consulta.pac_celular}
                                  </i>
                                  <br />
                                  <i
                                    style={{
                                      fontSize: '12px',
                                    }}
                                  >
                                    {'Convênio : ' + consulta.conv_nome}

                                    <br />
                                  </i>

                                  <i
                                    style={{
                                      fontSize: '12px',
                                    }}
                                  >
                                    {'Tipo : ' + tipo_consulta}
                                  </i>
                                </th>

                                <td>{consulta.cons_data.substring(11, 16)}</td>
                                <td>
                                  {consulta.cons_avisar_zap > 0 ? (
                                    <a
                                      target="blank"
                                      className="text-success"
                                      onClick={(e) =>
                                        sendWhatsapp(consulta.cons_codigo, i)
                                      }
                                      href={
                                        'https://wa.me/55' +
                                        contatoZap(consulta.pac_celular) +
                                        '?text=' +
                                        encodeURIComponent(
                                          'Olá ' +
                                            capitalize(consulta.pac_nome) +
                                            ' ' +
                                            consulta.pac_celular +
                                            ' sua consulta com o Dr(a) ' +
                                            capitalize(
                                              consulta.med_nome +
                                                ' ' +
                                                consulta.med_sobrenome,
                                            ) +
                                            '  está confirmada para o dia ' +
                                            mask_data(consulta.cons_data) +
                                            ' as ' +
                                            consulta.cons_data.substring(
                                              11,
                                              16,
                                            ) +
                                            '. Estamos aguardando você!',
                                        )
                                      }
                                    >
                                      <div>
                                        <span
                                          style={{
                                            fontSize: '22px',
                                            color: 'green',
                                            margin: '5px',
                                          }}
                                        >
                                          {Whatsapp}
                                        </span>
                                        <span
                                          id={'zap' + i}
                                          style={{
                                            fontSize: '22px',
                                            color: 'green',
                                          }}
                                        >
                                          {Check}
                                        </span>
                                      </div>
                                    </a>
                                  ) : (
                                    <a
                                      target="blank"
                                      className="text-success"
                                      onClick={(e) =>
                                        sendWhatsapp(consulta.cons_codigo, i)
                                      }
                                      href={
                                        'https://wa.me/55' +
                                        contatoZap(consulta.pac_celular) +
                                        '?text=' +
                                        encodeURIComponent(
                                          'Olá ' +
                                            capitalize(consulta.pac_nome) +
                                            ' ' +
                                            consulta.pac_celular +
                                            ' sua consulta com o Dr(a) ' +
                                            capitalize(
                                              consulta.med_nome +
                                                ' ' +
                                                consulta.med_sobrenome,
                                            ) +
                                            '  está confirmada para o dia ' +
                                            mask_data(consulta.cons_data) +
                                            ' as ' +
                                            consulta.cons_data.substring(
                                              11,
                                              16,
                                            ) +
                                            '. Estamos aguardando você!',
                                        )
                                      }
                                    >
                                      <div>
                                        <span
                                          style={{
                                            fontSize: '22px',
                                            color: 'green',
                                            margin: '5px',
                                          }}
                                        >
                                          {Whatsapp}
                                        </span>
                                        <span
                                          id={'zap' + i}
                                          style={{
                                            fontSize: '22px',
                                            color: 'red',
                                          }}
                                        >
                                          {Times}
                                        </span>
                                      </div>
                                    </a>
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                  <TablePagination
                    component="div"
                    count={totalPages}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage={'Registros por página'}
                  />
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table table-hover table-striped table-light">
                    <thead className="text-white bg-dark">
                      <tr>
                        <th scope="col"></th>
                        <th style={{ width: '85%' }} scope="col">
                          Paciente
                        </th>
                        {/* <th scope="col">Proprietario</th>*/}

                        <th scope="col">Hora</th>

                        <th style={{ width: '15%' }} scope="col">
                          Prontuário
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {consultaPag.length > 0 &&
                        consultaPag.map((consulta, i) => {
                          var tipo_consulta = '';

                          switch (consulta.cons_tipo) {
                            case 'C':
                              tipo_consulta = 'Consulta';
                              break;
                            case 'P':
                              tipo_consulta = '1. Consulta';
                              break;
                            case 'R':
                              tipo_consulta = 'Retorno';
                              break;
                            case 'T':
                              tipo_consulta = 'Receita';
                              break;
                            case 'E':
                              tipo_consulta = 'Exame';
                              break;
                            case 'A':
                              tipo_consulta = 'Ausente';
                              break;
                          }
                          var statusCor = '';
                          switch (consulta.cons_status) {
                            case 1:
                              // paciente em espera no consultorio
                              statusCor = '#2196f3';
                              break;
                            case 2:
                              // atendimento em andamento
                              statusCor = '#fec107';
                              break;
                            case 3:
                              // atendimento concluido
                              statusCor = '#01a777';
                              break;
                            case 4:
                              // ausente
                              statusCor = '#eb2d2d';
                              break;
                            default:
                              // apenas agendado
                              statusCor = '#aa528c';
                          }
                          return (
                            <>
                              <tr key={consulta.cons_codigo}>
                                <td>
                                  <>
                                    <div
                                      style={{
                                        width: '17px',
                                        height: '17px',
                                        background: statusCor,
                                        borderRadius: '50%',
                                      }}
                                      className="color-51comvc text-lowercase"
                                    ></div>
                                  </>
                                </td>

                                <th>
                                  {consulta.pac_nome}
                                  <br />

                                  <i
                                    style={{
                                      fontSize: '12px',
                                    }}
                                  >
                                    {'Convênio : ' + consulta.conv_nome}

                                    <br />
                                  </i>
                                  <i
                                    style={{
                                      fontSize: '12px',
                                    }}
                                  >
                                    {'Tipo : ' + tipo_consulta}

                                    <br />
                                  </i>
                                </th>

                                <td>{consulta.cons_data.substring(11, 16)}</td>
                                <td>
                                  <button
                                    style={{
                                      fontSize: '24px',
                                      color: statusCor,
                                    }}
                                    onClick={(e) =>
                                      pacienteProntuario(consulta)
                                    }
                                    type="button"
                                    className="btn btn-sm mb-2 mr-2 "
                                  >
                                    {ClipboardList}
                                    {/* <img
                                  src="prontuario.png"
                                  style={{ minWidth: '30px' }}
                               />*/}
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                  <TablePagination
                    component="div"
                    count={totalPages}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage={'Registros por página'}
                  />
                </div>
              )
            }
          </div>
        </div>
      </div>
      <input
        type="hidden"
        class="btn btn-primary"
        data-toggle="modal"
        id="modal-atendimento"
        data-target=".bd-example-modal-md"
      />

      <div
        className="modal fade bd-example-modal-md"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="card">
              <div className="card-header">{Clipboard} Prontuário</div>

              <div id="triangulo-para-baixo"></div>
              <div className="card-body">
                <form id="form_paciente" onSubmit={(e) => save(e)}>
                  <div className="row mb-3">
                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <label htmlFor="cons_texto">
                          <b>Descrição:</b>
                        </label>
                        <textarea
                          className="form-control textarea-responsive"
                          id="cons_texto"
                          required="required"
                          type="text"
                          value={cons_texto}
                          onChange={(e) => setCons_texto(e.target.value)}
                          rows="7"
                          cols="120"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="text-left mb-3 mt-3 ">
                    {cons_codigo ? (
                      <>
                        <button
                          className="btn btn-sm btn-primary "
                          type="submit"
                          id="btnAlterar"
                        >
                          {Edit} Alterar
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn btn-sm btn-success "
                        type="submit"
                        id="btnCadastrar"
                      >
                        {Save} Cadastrar
                      </button>
                    )}{' '}
                    &nbsp;&nbsp;{' '}
                    <button
                      className="btn btn-sm btn-primary "
                      onClick={(e) => limpar(e)}
                      type="button"
                      id="btn_limpar"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      {Times} Fechar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Atendimento;
