import React from 'react';
import Atendimento from './Atendimento';
import { useHistory } from 'react-router-dom';

const Comunicado = () => {
  let history = useHistory();
  //sessionStorage.clear();
  // history.push('/');
  return (
    <>
      <Atendimento cardtitulo="Comunicado" />
    </>
  );
};
export default Comunicado;
