import React, { useState, useEffect, useContext } from 'react';
import { api } from '../utils/WebApiConfig';
import { handleError, handleInfo, handleSuccess } from '../utils/Toastify';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  User,
  Lock,
  Envelope,
  AngleDoubleLeft,
  Edit,
} from '../utils/Fontawesome';
import { version } from '../../../package.json';
const Login = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [repetirSenha, setRepetirSenha] = useState('');
  const [codigo, setCodigo] = useState('');
  const [cargo, setCargo] = useState('');

  let history = useHistory();
  const location = useLocation();

  const save = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let data;
    if (senha !== repetirSenha) {
      handleInfo('Senha repetida esta diferente da nova senha');
      return false;
    }

    data = {
      codigo: codigo,
      med_senha: senha,
      med_email: email,
      cargo: cargo,
    };

    api({
      method: 'put',
      url: 'api/medicos/recuperarsenha/',
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);

        history.push('/login');
      })
      .catch((error) => {
        //  catchResponse();

        handleError('Ocorreu um erro inesperado na execução desta operação');
        console.log(error);
      });
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    if (!sessionStorage.getItem('refresh')) {
      sessionStorage.setItem('refresh', 1);
      window.location.reload(false);
    }

    var uri = location.pathname.split('/');

    if (uri[2] && uri[3]) {
      setEmail(atob(uri[3]));
      setCodigo(uri[4]);
      setCargo(uri[2]);
    }
  }, []);
  const spanLogo = {
    width: '50%',
    textalign: 'center',
    marginBottom: '30px',
    display: 'inline-block',
  };
  const spanBtnSubimit = {
    width: '50%',
    textalign: 'right',
    display: 'inline-block',
    // marginBottom: '10px',
  };
  return (
    <>
      <div
        style={{
          backgroundImage: "url('/wallpaper.jpg')",
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          textAlign: '-webkit-center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="main"
      >
        {' '}
        <div id="login" style={{ maxWidth: '500px' }}>
          <div
            className="card"
            style={{ border: '0px solid rgba(0,0,0,.125)' }}
          >
            <nav class="nav nav-pills flex-column flex-sm-row">
              <span
                style={{ borderRadius: '25px 25px 0px 0px' }}
                class="flex-sm-fill text-sm-center nav-link active"
                href="#"
              >
                <b> Redefinir Senha</b>
              </span>
            </nav>
            <div
              className="card-header"
              style={{
                padding: '0px',
                borderTop: '0px',
                background: '#e6e8f4',
                borderRadius: '15px 15px 0px 0px',
              }}
            ></div>
            <div
              className="card-body"
              style={{
                background: '#e6e8f4',
                padding: '0px 15px',
                borderRadius: '0px 0px 25px 25px',
              }}
            >
              <form onSubmit={(e) => save(e)}>
                <fieldset className="clearfix_login">
                  <span style={spanLogo}>
                    {/*<img src={logo} alt="logo" style={imgLogo} />*/}
                    {/* <h2 style={{ color: '#000', fontWeight: 'bold' }}>
                      {' '}
                      BlueDoctor
                    </h2>*/}
                  </span>
                  {/*  <span
                        style={{
                          color: '#000',
                          fontWeight: 'bold',
                          marginBottom: '3px',
                        }}
                      >
                        {'Versão: ' + version}
                      </span>*/}
                  <div className="row ">
                    <div
                      className="col-md-12 col-12 col-sm-12 mb-3"
                      style={{ paddingRight: '0px' }}
                    >
                      <div
                        className="input-group mb-3"
                        style={{
                          position: 'relative',
                          right: '50px',
                        }}
                      >
                        <div className="input-group-prepend">
                          {' '}
                          <div className="wrapper-tooltip">
                            <span
                              style={{
                                display: 'inline',
                                borderRadius: '50px',
                                background: '#a7c4d2 ',
                                color: 'white',
                                position: 'relative',
                                right: '-37px',
                                zIndex: '99',
                              }}
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              {Envelope}
                            </span>
                            <div
                              className="tooltip"
                              style={{
                                left: '45px',
                                width: '280px',
                              }}
                            >
                              Email do usuario.
                            </div>
                          </div>{' '}
                        </div>
                        <input
                          className="form-control"
                          type="text"
                          id="email"
                          placeholder="Email"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          readOnly
                          style={{
                            position: 'absolute',
                            minWidth: '100%',
                            marginLeft: '39px',
                            padding: '7px 29px 7px 52px',
                            borderRadius: '50px 0px 0px 50px',
                            borderBottom: '6px solid #a7c4d2 ',
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-12 col-12 col-sm-12 mb-3"
                      style={{ paddingRight: '0px' }}
                    >
                      <div
                        className="input-group mb-3"
                        style={{
                          position: 'relative',
                          right: '50px',
                        }}
                      >
                        <div
                          className="input-group-prepend"
                          onClick={(e) =>
                            (document.getElementById('registro_senha').type =
                              document.getElementById('registro_senha').type ==
                              'text'
                                ? 'password'
                                : 'text')
                          }
                        >
                          {' '}
                          <div className="wrapper-tooltip">
                            <span
                              style={{
                                display: 'inline',
                                borderRadius: '50px',
                                background: '#a7c4d2 ',
                                color: 'white',
                                position: 'relative',
                                right: '-37px',
                                zIndex: '99',
                              }}
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              {Lock}
                            </span>
                            <div
                              className="tooltip"
                              style={{
                                left: '45px',
                                width: '280px',
                              }}
                            >
                              Nova senha , Mínimo de 6 e máximo de 12
                              caracteres, pelo menos uma letra maiúscula, uma
                              letra minúscula, um número e um caractere especial
                            </div>
                          </div>{' '}
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          id="registro_senha"
                          placeholder="Senha"
                          required
                          onChange={(e) => setSenha(e.target.value)}
                          value={senha}
                          maxLength="12"
                          minLength="6"
                          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                          style={{
                            position: 'absolute',
                            minWidth: '100%',
                            marginLeft: '39px',
                            padding: '7px 29px 7px 52px',
                            borderRadius: '50px 0px 0px 50px',
                            borderBottom: '6px solid #a7c4d2 ',
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className="col-md-12 col-12 col-sm-12 mb-3"
                      style={{ paddingRight: '0px' }}
                    >
                      <div
                        className="input-group mb-3"
                        style={{
                          position: 'relative',
                          right: '50px',
                        }}
                      >
                        <div
                          className="input-group-prepend"
                          onClick={(e) =>
                            (document.getElementById('senha').type =
                              document.getElementById('senha').type == 'text'
                                ? 'password'
                                : 'text')
                          }
                        >
                          {' '}
                          <div className="wrapper-tooltip">
                            <span
                              style={{
                                display: 'inline',
                                borderRadius: '50px',
                                background: '#a7c4d2 ',
                                color: 'white',
                                position: 'relative',
                                right: '-37px',
                                zIndex: '99',
                              }}
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              {Lock}
                            </span>
                            <div
                              className="tooltip"
                              style={{
                                left: '45px',
                                width: '280px',
                              }}
                            >
                              Repetir Senha , Mínimo de 6 e máximo de 12
                              caracteres, pelo menos uma letra maiúscula, uma
                              letra minúscula, um número e um caractere especial
                            </div>
                          </div>{' '}
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          id="senha"
                          placeholder="Repetir Senha"
                          required
                          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                          maxLength="12"
                          minLength="6"
                          onChange={(e) => setRepetirSenha(e.target.value)}
                          value={repetirSenha}
                          style={{
                            position: 'absolute',
                            minWidth: '100%',
                            marginLeft: '39px',
                            padding: '7px 29px 7px 52px',
                            borderRadius: '50px 0px 0px 50px',
                            borderBottom: '6px solid #a7c4d2 ',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <p>
                        <span className="icons">{User}</span>
                        <label htmlFor="registro_nome"></label>
                        <input
                          type="text"
                          id="registro_nome"
                          placeholder="Nome"
                          required
                          onChange={(e) => setNome(e.target.value)}
                          value={nome}
                        />
                      </p>
                      <p>
                        <span className="icons">{Envelope}</span>
                        <label htmlFor="registro_email"></label>
                        <input
                          type="text"
                          id="registro_email"
                          placeholder="Email"
                          required
                          onChange={(e) => setId(e.target.value)}
                          value={id}
                        />
                      </p>
                      <p>
                        <div className="wrapper-tooltip">
                          <span className="icons">{Lock}</span>
                          <div className="tooltip">
                            Mínimo de 6 e máximo de 12 caracteres, pelo menos
                            uma letra maiúscula, uma letra minúscula, um número
                            e um caractere especial
                          </div>
                        </div>
                        <label htmlFor="registro_senha"></label>
                        <input
                          type="password"
                          id="registro_senha"
                          placeholder="Senha"
                          required
                          onChange={(e) => setSenha(e.target.value)}
                          value={senha}
                          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                        />{' '}
                      </p>
                    */}{' '}
                  <div>
                    <button
                      className="mb-3 mt-3 mr-3 btn btn_Cadastrar"
                      style={{
                        width: '108px',
                        height: '35px',
                        textTransform: 'none',
                      }}
                      type="submit"
                    >
                      {Edit} Alterar
                    </button>
                    <Link to="/login">
                      <button
                        className="mb-3 mt-3 mr-3 btn btn-secondary"
                        style={{ borderRadius: '10px' }}
                      >
                        {AngleDoubleLeft} Login
                      </button>
                    </Link>
                  </div>
                  <p className="row mt-3 mb-1 ">
                    <div className="col-4"></div>
                    <div className="col-4">
                      {' '}
                      <b
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                        style={{
                          color: '#000',
                          fontWeight: 'bold',
                          fontSize: '12px',
                        }}
                      ></b>
                    </div>
                    <div className="col-4" style={{ textAlign: 'right' }}>
                      {' '}
                      <span
                        style={{
                          color: '#000',
                          fontWeight: 'bold',
                          marginBottom: '3px',
                          fontSize: '12px',
                          marginRight: '3px',
                        }}
                      >
                        {'Versão: ' + version}
                      </span>
                    </div>
                  </p>
                </fieldset>
                <div className="clearfix_login"></div>
              </form>
            </div>
          </div>
        </div>
      </div>{' '}
      {/*  <div className="main">
        <div className="container_login">
          <center>
            <div className="middle">
              <div id="login">
                <div
                  class="card"
                  style={{ border: '0px solid rgba(0,0,0,.125)' }}
                >
                  <div
                    class="card-header"
                    style={{
                      padding: '0px',
                      borderTop: '0px',
                      background: '#2196f3',
                      textAlign: 'center',
                    }}
                  >
                    <h4>Alterar Senha</h4>
                  </div>
                  <div class="card-body">
                    <form onSubmit={(e) => save(e)}>
                      <fieldset className="clearfix_login">
                        <br />
                        <p>
                          <span className="icons">{User}</span>
                          <label htmlFor="email"></label>
                          <input
                            style={{ background: '#c1c1c1' }}
                            type="text"
                            id="email"
                            placeholder="Email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            readOnly
                          />
                        </p>
                        <p>
                          <div class="wrapper-tooltip">
                            <span className="icons">{Lock}</span>
                            <div class="tooltip">
                              Mínimo de 6 e máximo de 12 caracteres, pelo menos
                              uma letra maiúscula, uma letra minúscula, um
                              número e um caractere especial
                            </div>
                          </div>
                          <label htmlFor="senha"></label>
                          <input
                            type="password"
                            id="senha"
                            placeholder="Senha"
                            required
                            onChange={(e) => setSenha(e.target.value)}
                            value={senha}
                            maxLength="12"
                            minLength="6"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                          />{' '}
                        </p>
                        <p>
                          <div class="wrapper-tooltip">
                            <span className="icons">{Lock}</span>
                            <div class="tooltip">
                              Mínimo de 6 e máximo de 12 caracteres, pelo menos
                              uma letra maiúscula, uma letra minúscula, um
                              número e um caractere especial
                            </div>
                          </div>
                          <label htmlFor="senha"></label>
                          <input
                            type="password"
                            id="senha"
                            placeholder="Repetir Senha"
                            required
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                            maxLength="12"
                            minLength="6"
                            onChange={(e) => setRepetirSenha(e.target.value)}
                            value={repetirSenha}
                          />{' '}
                        </p>
                        <div>
                          <button
                            className="mb-3 mt-3 mr-3 btn btn_Cadastrar"
                            style={{
                              width: '108px',
                              height: '35px',
                              textTransform: 'none',
                            }}
                            type="submit"
                          >
                            {Edit} Alterar
                          </button>
                          <Link to="/login">
                            <button className="mb-3 mt-3 mr-3 btn btn-secondary">
                              {AngleDoubleLeft} Login
                            </button>
                          </Link>
                        </div>
                      </fieldset>
                      <div className="clearfix_login"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
 */}{' '}
    </>
  );
};
export default Login;
