import React, { useState, useEffect } from 'react';
import { api } from '../utils/WebApiConfig';
import { handleError, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import { Key, AngleDoubleLeft } from '../utils/Fontawesome';

const Premium = () => {
  let history = useHistory();

  const [senha, setSenha] = useState('');
  const [repetirSenha, setRepetirSenha] = useState('');
  const [statusLogado, setStatusLogado] = useState('');

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    window.$('#sidebarCollapse').on('click', function () {
      window.$('#sidebar').toggleClass('active');
      window.$(this).toggleClass('active');
    });
    getAssinatura(sessionStorage.getItem('med_id'));
  }, []);
  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  const cancelarAssinatura = async (med_id) => {
    return true;
  };

  const getAssinatura = async (med_id) => {
    await api
      .get('api/assinatura?med_id=' + med_id, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setStatusLogado(response.data[0].assinatura_status);
        console.log(response.data[0].assinatura_status);
        if (response.data[0].expirou === 2) {
          switch (response.data[0].assinatura_status) {
            case 1:
              document.getElementById(
                'message_pagseguro',
              ).innerHTML = `   A assinatura já esta ativada! `;
              break;
            case 2:
              document.getElementById(
                'message_pagseguro',
              ).innerHTML = `Sua assinatura está pendente! `;
              break;
            default:
              document.getElementById(
                'message_pagseguro',
              ).innerHTML = `Desculpe , mais houve um erro durante a verificação de seu premium , tente atualizar a pagina com "Ctrl + f5" ou contatar o nosso suporte  `;
          }
        } else {
          document.getElementById(
            'message_pagseguro',
          ).innerHTML = `Sua assinatura já expirou! `;
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const assinarPagSeguro = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let data = {
      med_id:
        sessionStorage.getItem('cargo') === 'M'
          ? sessionStorage.getItem('med_id')
          : sessionStorage.getItem('secretaria_id'),
      senha: senha,
    };

    api({
      method: 'POST',
      url: 'api/assinatura',
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        //   handleSuccess(response.headers.statusmessage);
        if (response?.headers?.statusmessage) {
          let text = response?.headers?.statusmessage;
          text = text.replace('{"0":"', '');
          let code = text.replace('"', '');
          code = code.replace('"', '');
          //    cobrarPagSeguro(code);

          /*  let linkAssinatura =
            'https://sandbox.pagseguro.uol.com.br/v2/pre-approvals/request.html?code=' +
            code;
          console.log(linkAssinatura);
          document.getElementById('link_pagseguro').innerHTML =
            `<a href="` +
            linkAssinatura +
            `">  <button  class="btn mt-5 btn-sm btn-primary "            style={      height: '35px';
              width: 'max-content';
            }
       
            type="button"
            id="btnAlterar"
          >
          Ir ao PagSeguro
          </button></a>`;*/
        }

        // limpar();
      })
      .catch((error) => {
        //  catchResponse();

        //        catchResponse(error?.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setRepetirSenha('');
    setSenha('');
  };

  return (
    <>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            {' '}
            {Key} Ativação BlueDoctor {AngleDoubleLeft}
          </div>

          <div id="triangulo-para-baixo"></div>
          <div className="card-body">
            <form id="form_alterar_senha">
              <div id="message_pagseguro" className="mt-3 mb-3"></div>
              <div className="text-left ">
                <>
                  <button
                    className="btn btn-sm btn-success "
                    style={{
                      width: '108px',
                      height: '35px',
                      width: 'max-content',
                    }}
                    onClick={(e) => assinarPagSeguro(e)}
                    type="button"
                    id="btnassinar"
                  >
                    Assinatura PagSeguro
                  </button>
                </>

                <button
                  className="btn btn-sm btn-danger "
                  style={{
                    width: '108px',
                    height: '35px',
                    width: 'max-content',
                  }}
                  onClick={(e) => cancelarAssinatura(e)}
                  type="button"
                  id="btncancelar"
                >
                  Cancelar assinatura
                </button>

                <div id="link_pagseguro"> </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Premium;
