import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { clone } from 'lodash';
import { api } from '../utils/WebApiConfig';
import ConvenioCad from './ConvenioCad';
//import NotificarSuporte from './NotificarSuporte';

import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import {
  Times,
  Clock,
  UserClock,
  Save,
  Print,
  BriefcaseMedical,
  Eye,
  Cogs,
  ImageUpload,
} from '../utils/Fontawesome';
import medico_sistema from '../imagens/step_medico_sistema.jpg';
import axios from 'axios';
import { Prontuario } from '../templates/Prontuario';

import {
  mask_tel_celular_area,
  mask_CEP,
  removeAcento,
  mask_Numeros,
  removeEspacos,
} from '../utils/Mascara';

import { UserContext } from './UserContext';

export const adicionaZero = (numero) => {
  if (numero <= 9) return '0' + numero;
  else return numero;
};

export const adicionaZeroMinutes = (numero) => {
  if (numero === 0) return '0' + numero;
  else return numero;
};
const isIncludingString = (string, option) => {
  let result = false;
  if (
    !string ||
    option.label.toString().includes(string) ||
    option.value.toString().includes(string)
  ) {
    result = true;
  }
  return result;
};

const UFtoInt = (uf) => {
  switch (uf) {
    case 'TO':
      uf = 1;
      break;
    case 'SP':
      uf = 2;
      break;
    case 'SE':
      uf = 3;
      break;
    case 'SC':
      uf = 4;
      break;
    case 'RS':
      uf = 5;
      break;
    case 'RR':
      uf = 6;
      break;
    case 'RO':
      uf = 7;
      break;
    case 'RN':
      uf = 8;
      break;
    case 'RJ':
      uf = 9;
      break;
    case 'PR':
      uf = 10;
      break;
    case 'PI':
      uf = 11;
      break;
    case 'PE':
      uf = 12;
      break;
    case 'PB':
      uf = 13;
      break;
    case 'PA':
      uf = 14;
      break;
    case 'MT':
      uf = 15;
      break;
    case 'MS':
      uf = 16;
      break;
    case 'MG':
      uf = 17;
      break;
    case 'MA':
      uf = 18;
      break;
    case 'GO':
      uf = 19;
      break;
    case 'ES':
      uf = 20;
      break;
    case 'DF':
      uf = 21;
      break;
    case 'CE':
      uf = 22;
      break;
    case 'BA':
      uf = 23;
      break;
    case 'AP':
      uf = 24;
      break;
    case 'AM':
      uf = 25;
      break;
    case 'AL':
      uf = 26;
      break;
    // case "AC":uf = 27;break;
    default:
      uf = 27;
  }
  return uf;
};
const Pendente = () => {
  let history = useHistory();

  const [currentTab, setCurrentTab] = useState(0);
  const { user } = useContext(UserContext);
  const [cid_uf, setCid_uf] = useState([]);
  const [statusCid_uf, setStatusCid_uf] = useState([
    { value: 'TO', label: 'TO - Tocantins' },
    { value: 'SP', label: 'SP - São Paulo' },
    { value: 'SE', label: 'SE - Sergipe' },
    { value: 'SC', label: 'SC - Santa Catarina' },
    { value: 'RS', label: 'RS - Rio Grande do Sul' },
    { value: 'RR', label: 'RR - Roraima' },
    { value: 'RO', label: 'RO - Rondônia' },
    { value: 'RN', label: 'RN - Rio Grande do Norte' },
    { value: 'RJ', label: 'RJ - Rio de Janeiro' },
    { value: 'PR', label: 'PR - Paraná' },
    { value: 'PI', label: 'PI - Piauí' },
    { value: 'PE', label: 'PE - Pernambuco' },
    { value: 'PB', label: 'PB - Paraíba' },
    { value: 'PA', label: 'PA - Pará' },
    { value: 'MT', label: 'MT - Mato Grosso' },
    { value: 'MS', label: 'MS - Mato Grosso do Sul' },
    { value: 'MG', label: 'MG - Minas Gerais' },
    { value: 'MA', label: 'MA - Maranhão' },
    { value: 'GO', label: 'GO - Goiás' },
    { value: 'ES', label: 'ES - Espírito Santo' },
    { value: 'DF', label: 'DF - Distrito Federal' },
    { value: 'CE', label: 'CE - Ceará' },
    { value: 'BA', label: 'BA - Bahia' },
    { value: 'AP', label: 'AP - Amapá' },
    { value: 'AM', label: 'AM - Amazonas' },
    { value: 'AL', label: 'AL - Alagoas' },
    { value: 'AC', label: 'AC - Acre' },
  ]);
  const [med_id, setMed_id] = useState('');
  const [med_nome, setMed_nome] = useState('');
  const [med_email, setMed_email] = useState('');
  const [med_conselho, setMed_conselho] = useState([]);
  const [statusMed_conselho, setStatusMed_conselho] = useState('');

  const [med_data_nascimento, setMed_data_nascimento] = useState('');
  const [med_conselho_codigo, setMed_conselho_codigo] = useState('');
  const [med_status, setMed_status] = useState(1);
  const [med_horaini, setMed_horaini] = useState('');
  const [med_horafim, setMed_horafim] = useState('');
  const [med_sessao, setMed_sessao] = useState('');
  const [med_img, setMed_img] = useState([]);
  const [resultViacep, setResultViacep] = useState(false);
  const [statusEspecialidade, setStatusEspecialidade] = useState([]);
  const [especialidade, setEspecialidade] = useState([]);
  const [med_endereco, setMed_endereco] = useState('');
  const [med_numero, setMed_numero] = useState('');
  const [med_bairro, setMed_bairro] = useState('');
  const [med_cep, setMed_cep] = useState('');
  const [med_cidade, setMed_cidade] = useState([]);
  const [statusMed_cidade, setStatusMed_cidade] = useState([]);
  const [med_telefone, setMed_telefone] = useState('');
  const [med_senha, setMed_senha] = useState('');
  const [secretaria_senha, setSecretaria_senha] = useState('');
  const [secretaria_email, setSecretaria_email] = useState('');
  const [convenio, setConvenio] = useState('');
  const [statusConvenio, setStatusConvenio] = useState('');
  const [medicoExcel, setMedicoExcel] = useState([]);

  const [medicos, setMedicos] = useState([]);
  const [idModal, setIdModal] = useState('');
  const [nomeModal, setNomeModal] = useState('');
  const [idStatusModal, setStatusModal] = useState('');

  //config da pagina

  const [page_top, setPage_top] = useState('');
  const [page_left, setPage_left] = useState('');
  const [page_right, setPage_right] = useState('');
  const [page_bottom, setPage_bottom] = useState('');
  const [page_med_id, setPage_med_id] = useState([]);
  const [statusPage_med_id, setStatusPage_med_id] = useState([]);
  const [page_id, setPage_id] = useState(0);

  // fim da config da pagina

  // select multiple
  const [searchField, setSearchField] = useState('');
  const [values, setValues] = useState([]);
  const [valuesId, setValuesId] = useState([]);

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [medicosPag, setMedicosPag] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    } else {
      console.log('nao tem');
    }
    window.$('#sidebarCollapse').on('click', function () {
      window.$('#sidebar').toggleClass('active');
      window.$(this).toggleClass('active');
    });

    /*
    if (!sessionStorage.getItem("refresh")) {
      sessionStorage.setItem("refresh", 1);
      window.location.reload(false);
    }*/

    showTab(currentTab);
    getMedico();
    getEspecialidades();
    getConselho();
    getConvenio();
    window.$('#modalConvenio').on('hide.bs.modal', function () {
      getConvenio();
    });
  }, []);
  useEffect(() => {
    if (!values) {
      getConvenio();
    }
  }, [statusConvenio]);
  // select multiple

  const multiOnChange = (opt, { option }) => {
    let newOpts = opt;
    let string = searchField.toUpperCase();

    if (option?.value === 'cadastrar') {
      document.getElementById('btn_Cadastrar').click();

      return false;
    }
    if (option && option.value === 'all') {
      let filteredOptions = clone(statusConvenio);

      filteredOptions = filteredOptions.filter(
        (filteredOption) =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption),
      );

      string = null;
      newOpts = newOpts
        .concat(filteredOptions)
        .filter((newOpt) => newOpt.value !== 'all');
    }

    setSearchField(string);
    setValues(newOpts);

    document.querySelector(
      '#regForm > div:nth-child(2) > form > div.row.mb-3.mt-3 > div.col-md-6.col-12.mb-3 > div > div > div > div.css-1hwfws3',
    ).style.background = 'none';
  };

  const multionInputChange = (string, { action }) => {
    if (action === 'input-change') {
      setSearchField(string);
    }
  };
  const multiFilterOption = ({ label, value }, string) => {
    if (value === 'all') {
      return true;
    } else if (string) {
      return (
        label.includes(string.toUpperCase()) ||
        value.toString().includes(string.toUpperCase())
      );
    } else {
      return true;
    }
  };
  // fim do select multiple

  const getConselho = (conselho_id) => {
    //  setMed_cidade([]);
    api
      .get('api/conselhos', {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayConselho = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayConselho.push({
            value: response.data[i].conselho_id,
            label: response.data[i].conselho_sigla,
            memed: response.data[i].conselho_memed,
          });
        }

        setStatusMed_conselho(arrayConselho);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getConvenio = () => {
    api
      .get('api/convenios?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayConvenios = [];
        arrayConvenios.push({
          value: 'cadastrar',
          label: 'Cadastrar novo convênio',
        });
        for (let i = 0; i < response.data.length; i++) {
          arrayConvenios.push({
            value: String(response.data[i].conv_id),
            label:
              response.data[i].conv_id + ' - ' + response.data[i].conv_nome,
          });
        }

        setStatusConvenio(arrayConvenios);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getMedico = () => {
    api
      .get('api/medicos?select=1&medico=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setMedicos(response.data);
        editar(response.data[0]);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getEspecialidades = () => {
    api
      .get('https://api.memed.com.br/v1/especialidades', {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        const arrayEspecialidade = [];

        for (let i = 0; i < response.data.data.length; i++) {
          arrayEspecialidade.push({
            value: response.data.data[i].id,
            label: response.data.data[i].attributes.nome,
          });
        }

        setStatusEspecialidade(arrayEspecialidade);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const checkboxAtivo = () => {
    if (med_status === 1) {
      setMed_status(0);
    } else {
      setMed_status(1);
    }
  };

  const handleChangeUF = (cid_uf) => {
    setCid_uf(cid_uf);
    getCidade(UFtoInt(cid_uf.value));
    document.querySelector('#cid_uf > div > div.css-1hwfws3').style.background =
      ' none';
  };

  const handleChange = (med_cidade) => {
    setMed_cidade(med_cidade);
    document.querySelector(
      '#med_cidade > div > div.css-1hwfws3',
    ).style.background = ' none';
  };
  const handleChangeEspecialidade = (especialidade) => {
    setEspecialidade(especialidade);
    document.querySelector(
      '#especialidade > div > div.css-1hwfws3',
    ).style.background = ' none';
  };

  const RemoveAspasChaves = (value) => {
    value = value.replace(/"/gi, '');
    value = value.replace('}', '');
    value = value.replace('{', '');
    return value;
  };

  const editar = (medico) => {
    var convenio_id = medico.id_convenio.split(',');
    var convenio_nome = medico.nome_convenio.split(',');

    var Convenio = [];
    var ValuesId = [];
    for (let i = 0; i < convenio_id.length; i++) {
      if (convenio_nome[i]) {
        Convenio.push({
          value: parseInt(mask_Numeros(convenio_id[i])),
          label: RemoveAspasChaves(convenio_nome[i]),
        });
        ValuesId.push(parseInt(parseInt(mask_Numeros(convenio_id[i]))));
      }
    }
    setMed_data_nascimento(medico.med_data_nascimento);
    /* setPage_bottom(medico.page_bottom);
    setPage_left(medico.page_left);
    setPage_top(medico.page_top);
    setPage_right(medico.page_right);
    setPage_id(medico.page_id);*/
    setMed_horaini(medico.med_horaini);
    setMed_horafim(medico.med_horafim);
    setMed_sessao(medico.med_sessao);
    setValuesId(ValuesId);
    setValues(Convenio);

    setMed_conselho({
      value: medico.conselho_id,
      label: medico.conselho_sigla,
      memed: medico.conselho_memed,
    });
    setMed_conselho_codigo(medico.med_conselho_codigo);
    setMed_id(medico.med_id);
    setMed_email(medico.med_email);
    setMed_nome(medico.med_nome);
    setMed_status(medico.med_status);
    setMed_cep(medico.med_cep);
    viacep(medico.med_cep);
    setMed_numero(medico.med_numero);
    setMed_telefone(medico.med_telefone);
    setMed_endereco(medico.med_endereco);
    setCid_uf({ value: medico.cid_uf, label: medico.cid_uf });
    setMed_bairro(medico.med_bairro);
    if (medico.cid_uf) {
      getCidade(UFtoInt(medico.cid_uf));
    }
    setMed_cidade({
      value: medico.med_cidade,
      label: medico.cid_municipio,
    });

    var spans = '';

    if (medico.med_img) {
      var med_imgs = medico.med_img.split(',');
      for (let i = 0; i < med_imgs.length - 1; i++) {
        if (med_imgs[i] !== null) {
          spans +=
            `<span><img class="img_data_uri" style="margin-left:5px;    border: solid 2px #000; max-width: 150px;max-height: 150px;" name="img_data_uris" src="../imagens/medico/` +
            medico.med_id +
            `/` +
            med_imgs[0] +
            `"/></span>`;
        }
      }

      document.getElementById('list').innerHTML = spans;
    } else {
      document.getElementById('list').innerHTML = '';
    }
  };

  const dadosModal = (nome, id, status) => {
    setNomeModal(nome);
    setIdModal(id);
    setStatusModal(status);
  };

  const save = (e, step) => {
    e.preventDefault();
    e.stopPropagation();
    nextPrev(1);
    let data,
      url = 'api/medicos/' + sessionStorage.getItem('med_id'),
      metodo = 'PUT';

    var mc_convenio = [];

    for (let i = 0; i < values.length; i++) {
      mc_convenio.push(values[i].value);
    }

    var data_uri = document.querySelectorAll('output span img');

    var med_imgs = [];

    for (let i = 0; i < data_uri.length; i++) {
      med_imgs.push(data_uri[i].src);
    }
    let med_nome = sessionStorage.getItem('med_nome');
    med_nome = med_nome.split(' ');
    if (step === 1) {
      data = {
        med_nome: med_nome[0].toUpperCase(),
        med_sobrenome: sessionStorage.getItem('med_sobrenome'),
        med_cidade: med_cidade.value,
        med_data_nascimento: med_data_nascimento,
        cid_municipio: removeAcento(med_cidade.label),
        cid_uf: cid_uf.value,
        med_conselho: med_conselho.value,
        med_conselho_codigo: med_conselho_codigo,
        mc_convenio: mc_convenio,
        med_status: 2,
        med_endereco: med_endereco,
        med_numero: med_numero,
        med_bairro: med_bairro,
        med_cep: med_cep,
        med_telefone: med_telefone,
        med_id: sessionStorage.getItem('med_id'),
      };
    } else if (step === 2) {
      data = {
        med_horaini: med_horaini,
        med_horafim: med_horafim,
        med_sessao: med_sessao,
        med_id: sessionStorage.getItem('med_id'),
      };
    } else if (step === 3) {
      data = {
        page_id: page_id ? page_id : 0,
        page_top: page_top,
        page_left: page_left,
        page_right: page_right,
        page_bottom: page_bottom,
        page_med_id: sessionStorage.getItem('med_id'),
      };
      api({
        method: 'PUT',
        url: 'api/paginas/' + (page_id ? page_id : 0),
        data: data,
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
        .then((response) => {
          //   handleSuccess(response.headers.statusmessage);
          getMedico();
        })
        .catch((error) => {
          catchResponse(error.response.status);

          console.log(error);
        });
      if (med_imgs.length > 0) {
        if (med_imgs.indexOf('imagens/medico') != -1) {
          return false;
          // encontrou link de immg ja upada
        } else {
          data = {
            med_img: med_imgs,
            med_id: sessionStorage.getItem('med_id'),
          };
        }
      } else {
        return false;
      }
      document.getElementById('nextBtn5').disabled = true;
    } else if (step === 4) {
      document.getElementById('nextBtn5').disabled = true;
      if (secretaria_email && secretaria_senha) {
        data = {
          secretaria_email: secretaria_email,
          secretaria_senha: secretaria_senha,
          secretaria_status: 0,
          ms_id: sessionStorage.getItem('med_id'),
          med_id: sessionStorage.getItem('med_id'),
          med_nome: sessionStorage.getItem('med_nome'),
        };
        metodo = 'POST';
        url = 'api/medicos';
      }
      if (med_conselho.memed === 1) {
        let crm = mask_Numeros(med_conselho_codigo);
        let dataMemed = {
          type: 'usuarios',
          external_id: `bluedoctor` + sessionStorage.getItem('med_id'),
          nome: sessionStorage.getItem('med_nome'),
          sobrenome: sessionStorage.getItem('med_sobrenome'),
          email: sessionStorage.getItem('med_email'),
          uf: cid_uf.label,
          conselho_id: med_conselho.value,
          crm: crm,
          endereco: med_endereco + ` ` + med_numero + ` ` + med_bairro,
          cidade: med_cidade.label,
          telefone: `55` + mask_Numeros(med_telefone),
          data_nascimento: med_data_nascimento,
          especialidade_label: especialidade.label,
          especialidade_id: especialidade.value,
        };
        api({
          method: 'POST',
          url: 'api/memed',
          data: dataMemed,
          headers: {
            Authorization: 'bearer ' + sessionStorage.getItem('token'),
            tcdocpw: sessionStorage.getItem('senha'),
            tcdocuserlogin:
              sessionStorage.getItem('cargo') === 'M'
                ? sessionStorage.getItem('med_email')
                : sessionStorage.getItem('secretaria_email'),
          },
        })
          .then((response) => {
            handleSuccess(response.headers.statusmessage);
            if (
              response.headers.statusmessage ===
              '"Erro ao cadastrar medico no Memed"'
            ) {
              document.getElementById('prevBtn4').click();
            } else {
              document.getElementById('nextBtn5').disabled = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (step === 5) {
      data = {
        med_status: 1,
        med_id: sessionStorage.getItem('med_id'),
      };
    }

    if (!data) {
      return false;
    }
    //  return false;
    api({
      method: metodo,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        if (step === 4) {
          handleInfo('Secretaria cadastrada');
        }
        if (step === 5) {
          //  handleSuccess(response.headers.statusmessage);
          sessionStorage.setItem('med_status', 1);
          history.push('/atendimento');
        }
        //  handleSuccess(response.headers.statusmessage);
        /*   if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }*/
        //  getMedico();
      })
      .catch((error) => {
        //  catchResponse();

        //  handleSuccess(response.headers.statusmessage);
        handleInfo(error);

        // catchResponse(error.response.status);
        console.log(error);
      });
  };

  const NomeSobrenome = (nome, nomeOrSobrenome) => {
    nome = nome.split(' ');
    console.log(nome);
    let sobrenome = '';
    if (nomeOrSobrenome === 'nome' || nomeOrSobrenome === 1) {
      return nome[0];
    } else if (nomeOrSobrenome === 'sobrenome' || nomeOrSobrenome === 2) {
      for (let i = 1; i < nome.length; i++) {
        if (i === 1) {
          sobrenome += sobrenome + nome[i];
        } else {
          sobrenome += sobrenome + ' ' + nome[i];
        }
      }
      return sobrenome;
    }
  };
  const limpar = () => {
    setConvenio([]);
    setMed_cidade([]);
    setValues([]);
    setValuesId([]);
    setCid_uf([]);
    setMed_conselho([]);
    setMed_id('');
    setMed_email('');
    setMed_nome('');
    setMed_telefone('');
    setMed_cep('');

    setMed_numero('');
    setMed_endereco('');
    setMed_bairro('');
    setMed_status(1);
    setMed_senha('');
    setNomeModal('');
    setIdModal('');
    setSecretaria_email('');
    setSecretaria_senha('');
    if (document.getElementById('list')) {
      document.getElementById('list').innerHTML = '';
    }
    if (document.getElementById('prod_foto')) {
      document.getElementById('prod_foto').value = '';
    }
  };
  const ImprimirProtuario = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var mywindow = window.open(
      'https://tcdoc.viaconect.com',
      'PRINT',
      'height=1200,width=1200',
    );
    var dataAtual = new Date();
    var dia = dataAtual.getDate();
    var mes = dataAtual.getMonth() + 1;
    var ano = dataAtual.getFullYear();
    var horas = dataAtual.getHours();
    var minutos = dataAtual.getMinutes();
    var segundos = dataAtual.getSeconds();

    var dataAtual =
      adicionaZero(dia) +
      '/' +
      adicionaZero(mes) +
      '/' +
      ano +
      ' ' +
      horas +
      ':' +
      adicionaZero(minutos) +
      ':' +
      adicionaZero(segundos);
    var img_logo = 'https://tcdoc.viaconect.com/logoteste.png';

    if (document.getElementById('prod_foto').files.length > 0) {
      img_logo = document.getElementsByClassName('img_data_uri')[0].src;
    }
    mywindow.document.write(
      // Receita(page_top, page_bottom, page_left, page_right, dataAtual)
      Prontuario(
        page_top,
        page_bottom,
        page_left,
        page_right,
        dataAtual,
        'José da Silva',
        sessionStorage.getItem('med_nome').toLowerCase(),
        med_conselho?.value
          ? med_conselho.value + ' ' + med_conselho_codigo
          : 'CRM 999999',
        med_endereco
          ? med_endereco
          : 'Rua Capitão Alberto Mendes Júnior' + ' n° ' + med_numero
          ? med_numero
          : '99' + ' - ' + med_bairro
          ? med_bairro
          : 'centro' + ' - ' + med_cidade.label
          ? med_cidade.label
          : 'leme' + ' - ' + cid_uf.label
          ? cid_uf.label
          : 'SP',
        med_telefone ? med_telefone : '(19)9999-99999',
        med_cep ? med_cep : '13610-100',
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.".replace(
          /\r?\n/g,
          '<br />',
        ),
        img_logo,
      ),
    );

    //  mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  };
  const getCidade = (cid_uf) => {
    setMed_cidade([]);
    api
      .get('api/cidades?UF=' + cid_uf, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayCidade = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayCidade.push({
            value: response.data[i].cid_id,
            label: response.data[i].cid_municipio,
          });
        }

        setStatusMed_cidade(arrayCidade);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const viacep = (value) => {
    if (value) {
      if (value.length === 10) {
        axios
          .get('https://viacep.com.br/ws/' + mask_Numeros(value) + '/json/')
          .then((response) => {
            if (response.data.ibge) {
              setResultViacep(true);
              // setMed_bairro(removeAcento(response.data.bairro));
              if (response.data.bairro) {
                setMed_bairro(removeAcento(response.data.bairro));
                document.getElementById('med_bairro').className =
                  'form-control';
                document.getElementById('med_bairro').readOnly = true;
              } else {
                document.getElementById('med_bairro').readOnly = false;
              }

              if (response.data.logradouro) {
                setMed_endereco(removeAcento(response.data.logradouro));
                document.getElementById('med_endereco').className =
                  'form-control';
                document.getElementById('med_endereco').readOnly = true;
              } else {
                document.getElementById('med_endereco').readOnly = false;
              }
              if (response.data.uf) {
                setCid_uf({
                  value: response.data.uf,
                  label: response.data.uf,
                });
                document.querySelector(
                  '#cid_uf > div > div.css-1hwfws3',
                ).style.background = 'none';
              }
              if (response.data.uf) {
                getCidade(response.data.uf);
              }

              if (response.data.ibge) {
                setMed_cidade({
                  value: response.data.ibge,
                  label: removeAcento(response.data.localidade),
                });
                document.querySelector(
                  '#med_cidade > div > div.css-1hwfws3',
                ).style.background = 'none';
              }
            } else {
              handleInfo('CEP não encontrado.');

              if (document.getElementById('med_endereco')) {
                document.getElementById('med_endereco').readOnly = false;
              }
              if (document.getElementById('med_bairro')) {
                document.getElementById('med_bairro').readOnly = false;
              }
              setResultViacep(false);
              setMed_endereco('');
              setMed_bairro('');
              setCid_uf([]);
              setMed_cidade([]);
            }
          })
          .catch((error) => {
            if (error.response) {
              catchResponse(error.response.status);
            }
          });
      }
    }
    return value;
  };

  const _handleImageChange = (e) => {
    e.preventDefault();

    document.getElementById('list').innerHTML = '';

    var fileInput = document.getElementById('prod_foto');
    var fileList = [];

    if (fileInput.files.length > 4) {
      handleError('No máximo 4 imagem');
      document.getElementById('prod_foto').value = '';
      return false;
    }
    for (var i = 0; i < fileInput.files.length; i++) {
      fileList.push(fileInput.files[i]);
    }

    for (var i = 0, f; (f = fileList[i]); i++) {
      // Only process image files.
      if (!f.type.match('image.*')) {
        continue;
      }

      var reader = new FileReader();

      reader.onload = (function (theFile) {
        return function (e) {
          (function () {
            var image = new Image();
            image.addEventListener(
              'load',
              function () {
                // tamanho original

                var canvas = document.createElement('canvas'),
                  ctx = canvas.getContext('2d');
                // coordenadas origem (source)

                // tamanho destino
                var MAX_WIDTH = 400;
                var MAX_HEIGHT = 400;
                var width = this.width;
                var height = this.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(this, 0, 0, width, height);

                let ext = theFile.name.split('.');

                //   var dataurl = canvas.toDataURL("image/" + ext[ext.length - 1]);
                var dataurl = canvas.toDataURL('image/png');

                var span = document.createElement('span');
                span.innerHTML = [
                  '<img class="img_data_uri" style="margin-left:5px;    border: solid 2px #000; max-width: 150px;max-height: 150px;" name="img_data_uris" src="',
                  dataurl,
                  '" title="',
                  escape(theFile.name),
                  '"/>',
                ].join('');
                document.getElementById('list').insertBefore(span, null);
              },
              false,
            );
            image.src = e.target.result;
          })();
        };
      })(f);

      // Read in the image file as a data URL.
      reader.readAsDataURL(f);
    }
  };

  const showTab = (n) => {
    // This function will display the specified tab of the form...
    var x = document.getElementsByClassName('tab');
    x[n].style.display = 'block';
    //... and fix the Anterior/Próximo buttons:
    /*  if (n == 0) {
      document.getElementById('prevBtn').style.display = 'none';
    } else {
      document.getElementById('prevBtn').style.display = 'inline';
    }
    if (n == x.length - 1) {
      document.getElementById('nextBtn').innerHTML = 'Submit';
    } else {
      document.getElementById('nextBtn').innerHTML = 'Próximo';
    }*/
    //... and run a function that will display the correct step indicator:
    fixStepIndicator(n);
  };

  const nextPrev = (n) => {
    // This function will figure out which tab to display

    var x = document.getElementsByClassName('tab');

    // Exit the function if any field in the current tab is invalid:
    // console.log(n + ' ' + !validateForm());
    if (n == 1 && !validateForm()) return false;
    // Hide the current tab:

    x[currentTab].style.display = 'none';

    // Increase or decrease the current tab by 1:
    var currentTabs = currentTab + n;
    setCurrentTab(currentTabs);
    // if you have reached the end of the form...

    if (currentTab + n >= x.length) {
      // ... the form gets submitted:

      //      document.getElementById('regForm').submit();
      return false;
    }
    // Otherwise, display the correct tab:

    showTab(currentTab + n);
  };
  var hasOwnProperty = Object.prototype.hasOwnProperty;

  function isEmpty(obj) {
    // null é "empty"
    if (obj == null) return true;

    // Suponhamos que se tenha uma propriedade length com um valor diferente de zero
    // Essa proriedade será verdadeira
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // Caso contrário ela tem todas as sua propriedades?
    // Isto não se manipula
    // toString e valueOf são erros de enumeração no IE < 9
    for (var key in obj) {
      if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
  }
  const validateForm = () => {
    // This function deals with validation of the form fields
    var x,
      y,
      i,
      valid = true;
    x = document.getElementsByClassName('tab');
    y = x[currentTab].getElementsByTagName('input');

    // A loop that checks every input field in the current tab:
    const ids = [
      'react-select-2-input',
      'react-select-3-input',
      'react-select-4-input',
      'react-select-5-input',
      'react-select-6-input',
      'prod_foto',
      'secretaria_email',
      'secretaria_senha',
      'cid_uf_html5_validate',
      'med_cidade_html5_validate',
      'med_conselho_html5_validate',
      'especialidade_html5_validate',
      'values_html5_validate',
    ];

    for (i = 0; i < y.length; i++) {
      // If a field is empty...
      if (ids.includes(y[i]?.id)) {
        continue;
      }
      console.log(y[i].value);
      if (y[i].value == '') {
        // add an "invalid" class to the field:
        y[i].className += ' invalid';
        // and set the current valid status to false

        valid = false;
      }
    }

    if (isEmpty(cid_uf)) {
      // add an "invalid" class to the field:

      document.querySelector(
        '#cid_uf > div > div.css-1hwfws3',
      ).style.background = '#ffdddd';
      // and set the current valid status to false
      valid = false;
    } else if (isEmpty(med_cidade)) {
      // add an "invalid" class to the field:

      document.querySelector(
        '#med_cidade > div > div.css-1hwfws3',
      ).style.background = '#ffdddd';
      // and set the current valid status to false
      valid = false;
    }

    // If a field is empty...
    if (isEmpty(values)) {
      // add an "invalid" class to the field:

      document.querySelector(
        '#regForm > div:nth-child(2) > form > div.row.mb-3.mt-3 > div.col-md-6.col-12.mb-3 > div > div > div > div.css-1hwfws3',
      ).style.background = '#ffdddd';
      // and set the current valid status to false
      valid = false;
    }

    // If the valid status is true, mark the step as finished and valid:
    if (valid) {
      document.getElementsByClassName('step')[currentTab].className +=
        ' finish';
    }
    return valid; // return the valid status
  };

  const fixStepIndicator = (n) => {
    // This function removes the "active" class of all steps...
    var i,
      x = document.getElementsByClassName('step');
    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(' active', '');
    }
    //... and adds the "active" class on the current step:
    x[n].className += ' active';
  };

  const handleImageButton = (e) => {
    e.preventDefault();
    if (document.querySelector('#prod_foto')) {
      document.querySelector('#prod_foto').click();
    }
  };

  const verificarEmail = (secretaria_email) => {
    api({
      method: 'get',
      url: 'api/secretarias?secretaria_email=' + secretaria_email,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
      },
    })
      .then((response) => {
        if (response.data.length > 0) {
          handleInfo('Email já existente na plataforma');
          //  setSecretaria_senha('Bendolix99!');
          document.getElementById('secretaria_senha').readOnly = true;
        } else {
          document.getElementById('secretaria_senha').readOnly = false;
        }

        //   handleSuccess(response.headers.statusmessage);
      })
      .catch((error) => {
        catchResponse(error.response.status);

        console.log(error);
      });
  };
  const handleChangeConselho = (conselho) => {
    setMed_conselho(conselho);
  };
  return (
    <>
      <div className="container-fluid tela-pendente">
        <div className={' card card-success'}>
          <div className="card-header">
            {' '}
            {BriefcaseMedical} Atualizar cadastro de médico
          </div>
          <div id="triangulo-para-baixo"></div>
          <div style={{ background: '#f8f9fb' }} className="card-body">
            <div
              style={{ zIndex: '0', border: 'none', position: 'relative' }}
              id="regForm"
            >
              <ul id="progressbar">
                <li className="step active"></li>
                <li className="step"></li>
                <li className="step"></li>
                <li className="step"></li>
                {/* <li className="step"></li>*/}
              </ul>

              <div className="tab">
                <form onSubmit={(e) => save(e, 1)}>
                  <b className="mb-3 text-center">
                    O seu cadastro esta incompleto, continue preenchendo o
                    mesmo. a seguir deve ser preenchido o conselho, endereço do
                    seu consultório ou área de trabalho em que você exerce suas
                    atividades profissionais o convenio que você fornece
                    serviço, caso não haja nenhum convenio cadastrado adicione
                    um convenio a partir da primeira opção da seleção de
                    convenio
                  </b>
                  <div className="row mb-3 mt-3">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="med_data_nascimento">
                        Data de nascimento:
                        <strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="med_data_nascimento"
                        placeholder="Data de nascimento"
                        onChange={(e) => setMed_data_nascimento(e.target.value)}
                        value={med_data_nascimento}
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="med_cep">
                        CEP:<strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="med_cep"
                        maxLength="10"
                        minLength="10"
                        pattern="\d{2}.?\d{3}-?\d{3}"
                        required
                        placeholder="Digite o CEP"
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) =>
                          setMed_cep(viacep(mask_CEP(e.target.value)))
                        }
                        value={med_cep}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="med_endereco">
                        Endereço:<strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="med_endereco"
                        placeholder="Digite seu Endereço"
                        maxLength="150"
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) =>
                          setMed_endereco(removeAcento(e.target.value))
                        }
                        value={med_endereco}
                        required
                      />
                    </div>{' '}
                    <div className="col-md-4  mb-3">
                      <label htmlFor="med_numero">
                        Número:<strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="med_numero"
                        placeholder="N°"
                        maxLength="20"
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) => setMed_numero(e.target.value)}
                        value={med_numero}
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="med_bairro">
                        Bairro:<strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="med_bairro"
                        placeholder="Digite o seu Bairro"
                        maxLength="80"
                        required
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) =>
                          setMed_bairro(removeAcento(e.target.value))
                        }
                        value={med_bairro}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="cid_uf">
                        Estado / UF:
                        <strong className="fild-required">*</strong>
                      </label>
                      <Select
                        id="cid_uf"
                        value={cid_uf}
                        isDisabled={resultViacep && cid_uf.value ? true : false}
                        onChange={handleChangeUF}
                        options={statusCid_uf}
                        placeholder={<div>Selecione</div>}
                      />
                      <input
                        style={{
                          top: '43px',
                          position: 'absolute',
                          zIndex: '-1',
                          width: '10%',
                          height: '1%',
                          border: '0px solid',
                        }}
                        type="text"
                        id="cid_uf_html5_validate"
                        value={cid_uf.value}
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="med_cidade">
                        Cidade:<strong className="fild-required">*</strong>
                      </label>
                      <Select
                        id="med_cidade"
                        value={med_cidade}
                        onChange={handleChange}
                        isDisabled={
                          resultViacep && med_cidade.value ? true : false
                        }
                        options={statusMed_cidade}
                        placeholder={<div>Selecione o estado antes</div>}
                      />
                      <input
                        style={{
                          top: '43px',
                          position: 'absolute',
                          zIndex: '-1',
                          width: '10%',
                          height: '1%',
                          border: '0px solid',
                        }}
                        type="text"
                        id="med_cidade_html5_validate"
                        value={med_cidade.value}
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="med_telefone">Telefone/Celular:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="med_telefone"
                        placeholder="Telefone com DDD"
                        pattern="\([0-9]{2}\)[\s][0-9]{4,5}-[0-9]{4}"
                        maxLength="20"
                        required
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) =>
                          setMed_telefone(mask_tel_celular_area(e.target.value))
                        }
                        value={med_telefone}
                      />
                    </div>
                    {/*<div className="col-md-4 mb-3">
                      <label htmlFor="med_conselho">
                        Conselho:<strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        minLength="3"
                        id="med_conselho"
                        placeholder="Digite o Conselho"
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) =>
                          setMed_conselho(removeAcento(e.target.value))
                        }
                        value={med_conselho}
                        required
                      />
                      </div>*/}
                    <div className="col-md-4 mb-3">
                      <label htmlFor="med_conselho">
                        Conselho:
                        <strong className="fild-required">*</strong>
                      </label>
                      <Select
                        id="med_conselho"
                        value={med_conselho}
                        onChange={handleChangeConselho}
                        options={statusMed_conselho}
                        placeholder={<div>Selecione</div>}
                      />
                      <input
                        style={{
                          top: '43px',
                          position: 'absolute',
                          zIndex: '-1',
                          width: '10%',
                          height: '1%',
                          border: '0px solid',
                        }}
                        type="password"
                        id="med_conselho_html5_validate"
                        value={med_conselho.value}
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="med_conselho_codigo">
                        Código do conselho:
                        <strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="text"
                        maxLength="100"
                        className="form-control"
                        minLength="3"
                        id="med_conselho_codigo"
                        placeholder="Código do conselho"
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) =>
                          setMed_conselho_codigo(removeAcento(e.target.value))
                        }
                        value={med_conselho_codigo}
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="especialidade">
                        Especialidade:
                        <strong className="fild-required">*</strong>
                      </label>
                      <Select
                        id="especialidade"
                        value={especialidade}
                        onChange={handleChangeEspecialidade}
                        options={statusEspecialidade}
                        placeholder={<div>Selecione</div>}
                      />
                      <input
                        style={{
                          top: '43px',
                          position: 'absolute',
                          zIndex: '-1',
                          width: '10%',
                          height: '1%',
                          border: '0px solid',
                        }}
                        type="password"
                        id="especialidade_html5_validate"
                        value={especialidade.value}
                        required
                      />
                    </div>
                    <div className="col-md-6  col-12 mb-3">
                      <label htmlFor="convenio">
                        Convênio:
                        <strong className="fild-required">*</strong>
                      </label>
                      <div>
                        <Select
                          filterOption={multiFilterOption}
                          hideSelectedOptions
                          onInputChange={multionInputChange}
                          onChange={multiOnChange}
                          options={statusConvenio}
                          isMulti
                          value={values}
                          placeholder={<div>Selecione</div>}
                        />
                        <input
                          style={{
                            top: '43px',
                            position: 'absolute',
                            zIndex: '-1',
                            width: '10%',
                            height: '1%',
                            border: '0px solid',
                          }}
                          type="password"
                          id="values_html5_validate"
                          value={values}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="tab-form-btn">
                    <input
                      type="submit"
                      id="nextBtn1"
                      style={{ width: '105px', height: '40px' }}
                      value="Próximo"
                      className="mb-3 mt-3 nextBtn btn_Cadastrar"
                    />
                  </div>
                </form>
              </div>
              <div className="tab">
                <form onSubmit={(e) => save(e, 2)}>
                  <b className="mb-3 text-center">
                    Determine o tempo de seu expediente e a duração de cada
                    consulta ou sessão
                  </b>
                  <div className="row mb-3 mt-3">
                    <div className="col-md-4 col-6 mb-3">
                      <label htmlFor="med_horaini">Hora Inicial:</label>
                      <input
                        type="time"
                        required
                        className="form-control"
                        id="med_horaini"
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) => setMed_horaini(e.target.value)}
                        value={med_horaini}
                      />
                    </div>

                    <div className="col-md-4 col-6 mb-3">
                      <label htmlFor="med_horafim">Hora final:</label>
                      <input
                        type="time"
                        className="form-control"
                        id="med_horafim"
                        required
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) => setMed_horafim(e.target.value)}
                        value={med_horafim}
                      />
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <label htmlFor="med_sessao">Tempo de sessão:</label>
                      <input
                        type="time"
                        className="form-control"
                        id="med_sessao"
                        required
                        min="00:05"
                        max="01:00"
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) => setMed_sessao(e.target.value)}
                        value={med_sessao}
                      />
                    </div>
                  </div>

                  <div className="tab-form-btn">
                    <button
                      type="button"
                      className="prevBtn mb-3 mt-3 ml-3 mr-3"
                      style={{ width: '105px', height: '40px' }}
                      id="prevBtn1"
                      onClick={(e) => nextPrev(-1)}
                    >
                      Anterior
                    </button>
                    <input
                      type="submit"
                      id="nextBtn2"
                      style={{ width: '105px', height: '40px' }}
                      value="Próximo"
                      className="mb-3 mt-3 nextBtn btn_Cadastrar"
                    />
                  </div>
                </form>
              </div>
              {/*   <div className="tab">
                <form onSubmit={(e) => save(e, 3)}>
                  <b className="mb-3 text-center">
                    {' '}
                    Configure as margens que serão utilizadas durante a
                    impressão de uma receita, prontuário e etc., vale prestar
                    atenção no botão de impressão de teste onde você pode
                    visualizar como será uma impressão com o logo da sua empresa
                    e as margens que são afetadas de acordo com o preenchimento
                    acima.
                  </b>
                  <br />
                  <br />
                  <b className="mb-3">
                    {' '}
                    Observação: a prévia da folha da impressão pode ser vista
                    antes mesmo de imprimir.
                  </b>
                  <div className="form-row mt-3 mb-3">
                    <div className="col-md-3 col-12 mb-3">
                      <label htmlFor="page_top">
                        Margem Superior
                        <span style={{ fontSize: '10px' }}>(cm)</span>:
                        <strong className="text-danger">*</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="page_top"
                        maxLength="1"
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) =>
                          setPage_top(mask_Numeros(e.target.value))
                        }
                        value={page_top}
                        required
                      />
                    </div>
                    <div className="col-md-3 col-12 mb-3">
                      <label htmlFor="page_bottom">
                        Margem Inferior
                        <span style={{ fontSize: '10px' }}>(cm)</span>:
                        <strong className="text-danger">*</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="page_bottom"
                        maxLength="1"
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) =>
                          setPage_bottom(mask_Numeros(e.target.value))
                        }
                        value={page_bottom}
                        required
                      />
                    </div>
                    <div className="col-md-3 col-12 mb-3">
                      <label htmlFor="page_left">
                        Margem Esquerda
                        <span style={{ fontSize: '10px' }}>(cm)</span>:
                        <strong className="text-danger">*</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="page_left"
                        maxLength="1"
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) =>
                          setPage_left(mask_Numeros(e.target.value))
                        }
                        value={page_left}
                        required
                      />
                    </div>
                    <div className="col-md-3 col-12 mb-3">
                      <label htmlFor="page_right">
                        Margem Direita
                        <span style={{ fontSize: '10px' }}>(cm)</span>:
                        <strong className="text-danger">*</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="page_right"
                        maxLength="1"
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) =>
                          setPage_right(mask_Numeros(e.target.value))
                        }
                        value={page_right}
                        required
                      />
                    </div>
                    <div className="mr-3 mb-3">
                      <label htmlFor="btnImprimir">&nbsp;&nbsp;&nbsp;</label>
                      <br />{' '}
                      <button
                        className="btn btn-secondary "
                        id="btnImprimir"
                        style={{ width: 'fit-content' }}
                        onClick={(e) => ImprimirProtuario(e)}
                        type="button"
                      >
                        {Print} Impressão de Teste
                      </button>
                    </div>
                    <div className="mr-3 mb-3">
                      <label htmlFor="prod_foto">
                        <b>&nbsp;&nbsp;&nbsp;</b>{' '}
                      </label>
                      <br />{' '}
                      <button
                        type="button"
                        className="btn btn-secondary"
                        id="botaoLogotipo"
                        style={{ width: 'fit-content' }}
                        onClick={(e) => handleImageButton(e)}
                      >
                        {ImageUpload} Selecione a Logomarca{' '}
                      </button>
                      <input
                        type="file"
                        name="prod_foto[]"
                        className="form-control-file"
                        accept=".jpg, .jpeg, .png, .webp"
                        id="prod_foto"
                        hidden
                        onChange={(e) => _handleImageChange(e)}
                      />
                    </div>

                    <div className="mb-3">
                      <output
                        id="list"
                        style={{
                          display: 'flex',

                          flexFlow: 'wrap',
                        }}
                      ></output>{' '}
                    </div>
                  </div>

                  <div className="tab-form-btn">
                    <button
                      type="button"
                      className="prevBtn mb-3 mt-3 ml-3 mr-3"
                      style={{ width: '105px', height: '40px' }}
                      id="prevBtn2"
                      onClick={(e) => nextPrev(-1)}
                    >
                      Anterior
                    </button>
                    <input
                      type="submit"
                      id="nextBtn3"
                      value="Próximo"
                      className="mb-3 mt-3 nextBtn btn_Cadastrar"
                      style={{ width: '105px', height: '40px' }}
                    />
                  </div>
                </form>
              </div>
              */}
              <div className="tab">
                <form onSubmit={(e) => save(e, 4)}>
                  <b className="mb-3 text-center">
                    Cadastre uma secretaria para administrar suas atividades,
                    Vale ressaltar que o email cadastrado precisa de confirmação
                    no email para a conta ter acesso ao sistema
                  </b>
                  <br />
                  <br />
                  <b className="mb-3 text-dark"> Este cadastro é opcional.</b>
                  <div className="row mb-3 mt-3">
                    <div className="col-md-6 col-12 mb-3">
                      <label htmlFor="secretaria_email">Email:</label>
                      <input
                        type="email"
                        className="form-control"
                        id="secretaria_email"
                        onBlur={(e) => verificarEmail(e.target.value)}
                        placeholder="Digite o email da secretaria"
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) =>
                          setSecretaria_email(
                            removeEspacos(e.target.value.toLowerCase()),
                          )
                        }
                        value={secretaria_email}
                      />
                    </div>
                    <div class="   col-md-6 col-12 mb-3">
                      <label
                        htmlFor="secretaria_senha"
                        style={{ width: '100%', color: '#7c7f82' }}
                      >
                        Senha:
                      </label>
                      <div
                        class="input-group mb-3"
                        style={{
                          position: 'relative',
                          right: '50px',
                        }}
                      >
                        <div
                          class="input-group-prepend"
                          onClick={(e) =>
                            (document.getElementById('secretaria_senha').type =
                              document.getElementById('secretaria_senha')
                                .type == 'text'
                                ? 'password'
                                : 'text')
                          }
                        >
                          {' '}
                          <div class="wrapper-tooltip">
                            <span
                              style={{
                                display: 'inline',
                                borderRadius: '50px',
                                background: '#a7c4d2 ',
                                color: 'white',
                                position: 'relative',
                                right: '-37px',
                                zIndex: '99',
                              }}
                              class="input-group-text"
                              id="basic-addon1"
                            >
                              {Eye}
                            </span>
                            <div
                              className="tooltip"
                              style={{
                                left: '45px',
                                width: '280px',
                              }}
                            >
                              Mínimo de 6 e máximo de 12 caracteres, pelo menos
                              uma letra maiúscula, uma letra minúscula, um
                              número e um caractere especial
                            </div>
                          </div>{' '}
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          id="secretaria_senha"
                          maxLength="12"
                          minLength="6"
                          placeholder="Senha"
                          readOnly
                          onInput={(e) => (e.target.className = 'form-control')}
                          onChange={(e) => setSecretaria_senha(e.target.value)}
                          value={secretaria_senha}
                          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,12}$"
                          style={{
                            position: 'absolute',
                            minWidth: '100%',
                            marginLeft: '39px',
                            padding: '7px 29px 7px 52px',
                            borderRadius: '50px 0px 0px 50px',
                            borderBottom: '6px solid #a7c4d2 ',
                          }}
                        />
                      </div>
                    </div>

                    {/*   <div className="input-group col-md-6 col-12 mb-3">
                      <label
                        htmlFor="secretaria_senha"
                        style={{ width: '100%', color: '#7c7f82' }}
                      >
                        Senha:
                      </label>

                      <input
                        type="password"
                        className="form-control"
                        id="secretaria_senha"
                        maxLength="12"
                        minLength="6"
                        
                        placeholder="Senha"
                        readOnly
                        onInput={(e) => (e.target.className = 'form-control')}
                        onChange={(e) => setSecretaria_senha(e.target.value)}
                        value={secretaria_senha}
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,12}$"
                      />
                      <div
                        className="input-group-prepend"
                        style={{ width: '47px', height: '38px' }}
                      >
                        <div
                          onClick={(e) =>
                            (document.getElementById('secretaria_senha').type =
                              document.getElementById('secretaria_senha')
                                .type == 'text'
                                ? 'password'
                                : 'text')
                          }
                          className="input-group-text "
                        >
                          <div className="wrapper-tooltip">
                            <span className="icons">{Eye}</span>
                            <div
                              className="tooltip"
                              style={{
                                left: '-244px',
                                width: '280px',
                              }}
                            >
                              Mínimo de 6 e máximo de 12 caracteres, pelo menos
                              uma letra maiúscula, uma letra minúscula, um
                              número e um caractere especial
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    */}
                  </div>

                  <div className="tab-form-btn">
                    <button
                      type="button"
                      className="prevBtn mb-3 mt-3 ml-3 mr-3"
                      id="prevBtn3"
                      style={{ width: '105px', height: '40px' }}
                      onClick={(e) => nextPrev(-1)}
                    >
                      Anterior
                    </button>
                    <input
                      type="submit"
                      id="nextBtn4"
                      style={{ width: '105px', height: '40px' }}
                      value="Próximo"
                      className="mb-3 mt-3 nextBtn btn_Cadastrar"
                    />
                  </div>
                </form>
              </div>
              <div className="tab tab-form">
                <div className="form-row mb-3">
                  <div className="col-md-6 col-12 mb-3">
                    <div>
                      <img
                        style={{ borderRadius: '3%', border: 'solid 2px #000' }}
                        src={medico_sistema}
                        alt="Sistema BlueDoctor"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12 mb-3">
                    <h3 className="mb-5 text-center text-dark font-weight-bold">
                      Cadastro Finalizado
                    </h3>
                    <p style={{ fontSize: '16px' }} className="mt-5 text-dark ">
                      Parabéns por completar o cadastro no BlueDoctor !{' '}
                    </p>
                    <br />
                    <p
                      style={{ fontSize: '16px' }}
                      className="mt-2 text-dark mb-3"
                    >
                      Qualquer dúvida não hesite em consultar o suporte do
                      BlueDoctor.
                    </p>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <div className="tab-form-btn">
                  <button
                    type="button"
                    className="prevBtn mb-3 mt-3 ml-3 mr-3"
                    style={{ width: '105px', height: '40px' }}
                    id="prevBtn4"
                    onClick={(e) => nextPrev(-1)}
                  >
                    Anterior
                  </button>
                  <input
                    type="submit"
                    id="nextBtn5"
                    value="Finalizar"
                    className="nextBtn mb-3 mt-3 btn_Cadastrar "
                    style={{ width: '105px', height: '40px' }}
                    onClick={(e) => save(e, 5)}
                  />
                </div>
              </div>
            </div>
          </div>{' '}
        </div>
      </div>
      <div
        className="modal fade bd-example-modal-md"
        tabIndex="-1"
        id="modalConvenio"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <ConvenioCad
              arrayconvenio={statusConvenio}
              atualizarselect={setStatusConvenio}
              autoselecionarselect={setValues}
              arrayconveniovalues={values}
            />{' '}
          </div>
        </div>
      </div>{' '}
      <button
        type="button"
        className="btn  btn-success btn-block "
        id="btn_Cadastrar"
        data-toggle="modal"
        hidden
        data-target=".bd-example-modal-md"
      >
        Cadastrar
      </button>
    </>
  );
};
export default Pendente;
