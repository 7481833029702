import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { api } from '../utils/WebApiConfig';

import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';

import { TablePagination } from '@material-ui/core';
import { sqlLIKE, removeAcento, mask_Numeros } from '../utils/Mascara';
import {
  Times,
  Search,
  Save,
  Edit,
  Trash,
  City,
  AngleDoubleLeft,
} from '../utils/Fontawesome';

const Cidades = () => {
  let history = useHistory();

  const [cid_id, setCid_id] = useState('');
  const [cid_id_old, setCid_id_old] = useState('');
  const [cid_status_acao, setCid_status_acao] = useState('');
  const [cid_municipio, setCid_municipio] = useState('');
  const [cid_uf, setCid_uf] = useState('');

  const [cidades, setCidades] = useState([]);
  const [cidadePag, setCidadePag] = useState([]);
  const [nomeModal, setNomeModal] = useState('');
  const [idModal, setIdModal] = useState('');

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
      "rowsPerPage:" +
        rowsPerPage +
        " newPage " +
        newPage +
        " " +
        inicio +
        " ---- " +
        fim
    );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setCidadePag(cidadePag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setCidadePag(cidadePag);
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    window.$('#sidebarCollapse').on('click', function () {
      window.$('#sidebar').toggleClass('active');
      window.$(this).toggleClass('active');
    });
    getCidade();
    document.getElementById('cid_municipio').readOnly = true;
  }, []);

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  const getCidade = () => {
    api
      .get('api/cidades', {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setCidades(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        const cidadePag = [];
        if (fim > response.data.length) {
          fim = response.data.length;
        }
        for (let i = inicio; i < fim; i++) {
          cidadePag.push(response.data[i]);
        }
        setCidadePag(cidadePag);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const editar = (cidade) => {
    document.getElementById('cid_municipio').focus();
    setCid_municipio(cidade.cid_municipio);
    setCid_status_acao('alterar');
    setCid_uf(cidade.cid_uf);
    setCid_id(cidade.cid_id);
    setCid_id_old(cidade.cid_id);
  };

  const dadosModal = (nome, id) => {
    setNomeModal(nome);
    setIdModal(id);
  };
  const save = (e, metodo) => {
    e.preventDefault();
    e.stopPropagation();
    let data, method, url;
    if (metodo) {
      method = 'delete';
    } else {
      if (!cid_id) {
        handleInfo('Preencha o campo de IBGE da cidade.');
        return false;
      }
      if (!cid_municipio) {
        handleInfo('Insira um código IBGE válido.');
        return false;
      }

      if (!cid_status_acao) {
        method = 'post';
      } else {
        method = 'put';
      }
    }

    if (method === 'put') {
      data = {
        cid_id: cid_id,
        cid_municipio: cid_municipio,
        cid_uf: cid_uf,
        cid_id_old: cid_id_old,
      };
      url = 'api/cidades/' + cid_id_old;
    } else if (method === 'post') {
      let existeCidade = cidades.findIndex((val) => val.cid_id === cid_id);
      if (existeCidade !== -1) {
        handleInfo('Cidade já existente');

        return false;
      }

      data = {
        cid_id: cid_id,
        cid_municipio: cid_municipio,
        cid_uf: cid_uf,
      };
      url = 'api/cidades';
    } else if (method === 'delete') {
      url = 'api/cidades/' + idModal;
      data = {
        id: idModal,
      };
      setNomeModal('');
      setIdModal('');
    }

    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        getCidade();
      })
      .catch((error) => {
        //  catchResponse();
        catchResponse(error.response.status);

        console.log(error);
      });
  };

  const limpar = () => {
    setCid_id('');
    setCid_municipio('');
    setCid_uf('');
    setCid_status_acao('');
    setNomeModal('');
    setIdModal('');
  };

  const ibge = (value) => {
    if (value) {
      if (value.length > 5) {
        axios
          .get('https://viacep.com.br/ws/' + value + '/json/')
          .then((response) => {
            if (response.data.id) {
              // setStatusBar_cidade(arrayCidade);
              setCid_municipio(removeAcento(response.data.nome));
              setCid_uf(response.data.microrregiao.mesorregiao.UF.sigla);
            } else {
              setCid_municipio('');
            }
          })
          .catch((error) => {
            if (error.response) {
              catchResponse(error.response.status);
            }
          });
      }
    }
    return value;
  };
  const buscar = async () => {
    var filtrado;
    if (arrayFiltrado) {
      if (!busca) {
        filtrado = cidades;
      } else {
        //   console.log(ilikeToRegExp('%' + busca + '%'));
        filtrado = arrayFiltrado.filter(function (obj) {
          return sqlLIKE(obj.cid_municipio, '%' + busca + '%');
          //  return ilikeToRegExp('%' + busca + '%').test(obj.cid_municipio);
        });
      }
      var inicio = 0 * rowsPerPage + 1;
      var fim = inicio + rowsPerPage - 1;

      if (!filtrado.length) {
        filtrado = cidades;
        handleInfo('Nenhum registro encontrado.');
      }
      if (fim > filtrado.length) {
        fim = filtrado.length;
      }
      const cidadePag = [];
      for (let i = inicio - 1; i < fim; i++) {
        cidadePag.push(filtrado[i]);
      }

      await setCidadePag(cidadePag);
      await setTotalPages(filtrado.length);
      await setArrayFiltrado(filtrado);
      await setPage(0);
    } else {
      handleInfo('Nenhum registro encontrado');
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div
          className="modal fade bd-example-modal-lg"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="card ">
                <div className="card-header">
                  {' '}
                  {City} Cadastro de Cidades {AngleDoubleLeft}
                </div>

                <div className="card-body">
                  <form id="form_cidade" onSubmit={(e) => save(e)}>
                    <div className="form-row mb-3">
                      <div className="col-md-5 mb-3">
                        <div className="row">
                          <div
                            className="col-md-12 col-12 col-sm-12"
                            style={{ paddingRight: '0px' }}
                          >
                            <label htmlFor="ibge">IBGE da cidade::</label>
                            <div
                              class="input-group mb-3"
                              style={{ position: 'relative', right: '50px' }}
                            >
                              <div
                                class="input-group-prepend"
                                onClick={(e) => ibge(cid_id)}
                              >
                                <span
                                  style={{
                                    display: 'inline',
                                    borderRadius: '50px',
                                    background: '#a7c4d2 ',
                                    color: 'white',
                                    position: 'relative',
                                    right: '-37px',
                                    zIndex: '99',
                                  }}
                                  class="input-group-text"
                                  id="basic-addon1"
                                >
                                  {Search}
                                </span>
                              </div>
                              <input
                                id="ibge"
                                className="form-control"
                                autoComplete="new-password"
                                style={{
                                  display: 'inline',
                                  padding: '7px 29px 7px 52px',
                                  borderRadius: '50px 0px 0px 50px',
                                  borderBottom: '6px solid #a7c4d2 ',
                                }}
                                type="text"
                                placeholder="Código IBGE da cidade"
                                maxLength="9"
                                onChange={(e) =>
                                  setCid_id(ibge(mask_Numeros(e.target.value)))
                                }
                                value={cid_id}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-10 mb-3">
                        <label htmlFor="cid_municipio">Nome da Cidade:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cid_municipio"
                          placeholder="Digite um código válido em IBGE"
                          onChange={(e) =>
                            setCid_municipio(
                              removeAcento(e.target.value.toUpperCase()),
                            )
                          }
                          value={cid_municipio}
                          required
                        />
                      </div>
                      <div className="paddingrl-0 col-md-1 col-2 mb-3 ">
                        <label htmlFor="cid_uf">UF:</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cid_uf"
                          readOnly
                          placeholder="UF"
                          onChange={(e) =>
                            setCid_uf(e.target.value.toUpperCase())
                          }
                          value={cid_uf}
                          required
                        />
                      </div>
                    </div>
                    <div className="text-left ">
                      {cid_status_acao ? (
                        <button
                          className="btn btn-sm btn_Cadastrar "
                          type="submit"
                          id="btnAlterar"
                        >
                          {Edit} Alterar
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn_Cadastrar "
                          type="submit"
                          id="btnCadastrar"
                        >
                          {Save} Cadastrar
                        </button>
                      )}{' '}
                      &nbsp;&nbsp;
                      <button
                        className="btn btn-sm btn-secondary "
                        onClick={(e) => limpar(e)}
                        style={{ borderRadius: '10px' }}
                        type="button"
                        id="btn_limpar"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        {Times} Fechar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            {' '}
            <b style={{ fontSize: '16px' }}>
              {City} Lista de Cidades {AngleDoubleLeft}
            </b>
          </div>

          <div className="card-body ">
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-5 col-12">
                    <div className="row">
                      <div
                        className="col-md-10 col-12 col-sm-12"
                        style={{ paddingRight: '0px' }}
                      >
                        <label htmlFor="filtro">Filtrar Tabela:</label>
                        <div
                          class="input-group mb-3"
                          style={{ position: 'relative', right: '50px' }}
                          onClick={(e) => buscar()}
                        >
                          <div class="input-group-prepend">
                            <span
                              style={{
                                display: 'inline',
                                borderRadius: '50px',
                                background: '#a7c4d2 ',
                                color: 'white',
                                position: 'relative',
                                right: '-37px',
                                zIndex: '99',
                              }}
                              class="input-group-text"
                              id="basic-addon1"
                            >
                              {Search}
                            </span>
                          </div>
                          <input
                            id="filtro"
                            className="form-control"
                            style={{
                              display: 'inline',
                              padding: '7px 29px 7px 52px',
                              borderRadius: '50px 0px 0px 50px',
                              borderBottom: '6px solid #a7c4d2 ',
                            }}
                            type="text"
                            onChange={(e) =>
                              setBusca(e.target.value.toUpperCase())
                            }
                            value={busca}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <label htmlFor="btn_Cadastrar">&nbsp;&nbsp;&nbsp;</label>
                    <br />
                    <button
                      type="button"
                      className="btn  btn_Cadastrar btn-block "
                      id="btn_Cadastrar"
                      onClick={(e) => limpar(e)}
                      data-toggle="modal"
                      data-target=".bd-example-modal-lg"
                    >
                      {Save} Cadastrar
                    </button>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </form>

            <div className=" table-responsive">
              <table className="table  table-hover table-striped table-light ">
                <thead className="text-white  bg-dark ">
                  <tr>
                    <th style={{ width: '85%' }} scope="col">
                      Nome
                    </th>
                    <th style={{ width: '15%' }} scope="col">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cidadePag.length > 0 ? (
                    cidadePag.map(function (cidade, i) {
                      return (
                        <>
                          <tr key={cidade.cid_id}>
                            <th>
                              {cidade.cid_municipio + ' / ' + cidade.cid_uf}
                            </th>
                            <td>
                              <img
                                src="alterar.png"
                                onClick={(e) => editar(cidade)}
                                className="btn-acao mb-2 mr-2"
                                data-toggle="modal"
                                data-target=".bd-example-modal-lg"
                              />
                              <img
                                src="excluir.png"
                                onClick={(e) =>
                                  dadosModal(
                                    cidade.cid_municipio,
                                    cidade.cid_id,
                                  )
                                }
                                className="btn-acao mb-2 mr-2"
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <th>Nenhum registro foi encontrado</th>
                        <th></th>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Registros por página'}
              />
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="card card-red">
                <div className="card-header">{Trash} Excluir Cidade</div>

                <div className="card-body">
                  Tem certeza que deseja excluir a cidade{' '}
                  <i>
                    <b className="text-danger">"</b>
                    {nomeModal}
                    <b className="text-danger">"</b>
                  </i>{' '}
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-sm btn-secondary "
                    type="button"
                    data-dismiss="modal"
                  >
                    {Times}
                    &nbsp;Cancelar
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-sm btn_excluir "
                    onClick={(e) => save(e, 'delete', 0)}
                    type="button"
                    data-dismiss="modal"
                  >
                    {Trash} Excluir
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Cidades;
