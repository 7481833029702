import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  Key,
  CalendarAlt,
  BriefcaseMedical,
  HeadSideMask,
  AddressCard,
  City,
  SignOutAlt,
  AlignJustify,
  UserClock,
  User,
  Plus,
  FileMedicalAlt,
  BookMedical,
  Cogs,
  Cog,
} from '../utils/Fontawesome';

export const cadastros = [
  { text: 'Convênio', link: '/convenio', icon: AddressCard, nivel: 'M' },
  { text: 'Cidade', link: '/cidade', icon: City, nivel: 'SM' },
  { text: 'Médico', link: '/medico', icon: BriefcaseMedical, nivel: 'M' },
  { text: 'Secretária', link: '/secretaria', icon: User, nivel: 'M' },
  { text: 'Paciente', link: '/paciente', icon: HeadSideMask, nivel: 'SM' },
  { text: 'Exame', link: '/exame', icon: FileMedicalAlt, nivel: 'M' },
  {
    text: 'Exame Convênio',
    link: '/exameConvenio',
    icon: BookMedical,
    nivel: 'M',
  },
];

export const permissoes = (value) => {
  if (value.nivel) {
    if (value.nivel.indexOf(String(sessionStorage.getItem('cargo'))) !== -1) {
      return value;
    }
  }
};

const Navbar = () => {
  useEffect(() => {
    if (sessionStorage.getItem('med_status') == 2) {
      window.location.assign('/pendente');
    }
  }, []);

  return (
    <>
      <div className="wrapper">
        <div id="content">
          <nav
            style={{
              padding: '0px',
              height: '55px',
              margin: '0px',
              background: '#fefefe',
              alignItems: 'flex-start',
            }}
            className="navbar  navbar-expand-lg  navbar-dark "
          >
            <Link
              className="navbar-brand "
              style={{
                color: '#2e3a48',
                fontWeight: 'bold',
                padding: '0px 50px',
                alignSelf: 'center',
                fontSize: '30px',
              }}
              to="/consulta"
            >
              <img
                src="logo192.png"
                style={{
                  maxWidth: '125px',
                }}
              ></img>
            </Link>

            <button
              className="navbar-btn text-dark btn btn-light d-inline-block d-lg-none ml-auto"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {AlignJustify}
            </button>
            <div
              style={{
                background: '#E3E3E3',
                padding: '0px',
                borderLeft: '21px solid #73969C',
                height: '60px',
                borderRadius: '0px 0px 0px 67px',
                margin: '0px',
                color: '#176D95',
              }}
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  mr-auto ml-5">
                <li className="nav-item dropdown active">
                  <Link
                    className="nav-link "
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      color: '#176D95',
                      fontSize: '30px',
                    }}
                  >
                    {Plus}
                  </Link>

                  <div
                    className="dropdown-menu dropdown-menu-right "
                    style={{ width: 'max-content' }}
                    aria-labelledby="navbarDropdown"
                  >
                    {
                      //cadastros.filter(permissoes).map((item) => {
                      cadastros.filter(permissoes).map((item) => {
                        return (
                          <Link
                            style={{
                              color: '#176D95',
                            }}
                            key={item.link}
                            className="nav-link text-dark"
                            to={item.link}
                          >
                            <span className="mx-2" style={{ fontSize: '16px' }}>
                              {item.icon}
                            </span>
                            {item.text}
                          </Link>
                        );
                      })
                    }
                  </div>
                </li>
                <li>
                  <Link
                    style={{
                      color: '#176D95',
                    }}
                    className="nav-link  active"
                    to="/consulta"
                  >
                    <span className="mx-2" style={{ fontSize: '30px' }}>
                      {CalendarAlt}
                    </span>
                  </Link>
                </li>
                {sessionStorage.getItem('cargo') === 'M' && (
                  <li>
                    <Link
                      style={{
                        color: '#176D95',
                      }}
                      className="nav-link  active"
                      to="/atendimento"
                    >
                      <span className="mx-2" style={{ fontSize: '30px' }}>
                        {User}
                      </span>
                    </Link>
                  </li>
                )}
                {/*
                 cadastros.filter(permissoes).map((item) => {
                  return ( 
                  <li>
                    <Link
                      key={item.link}
                      className="nav-link  active"
                      to={item.link}
                    >
                      <span className='mx-2' style={{fontSize:'16px'}}>{item.icon}</span>{item.text} 
                    </Link>
                     </li>
                  );
                })
              */}
              </ul>
              <ul className="nav navbar-nav ml-auto">
                <li className="nav-item dropdown active">
                  <Link
                    className="nav-link"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      color: '#176D95',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '30px',
                        width: '30px',
                        height: '30px',
                        borderRadius: '50px',
                      }}
                    >
                      {' '}
                      {Cog}
                    </div>
                    {/*sessionStorage.getItem('cargo') == 'M'
                      ? sessionStorage.getItem('med_nome')
                  : sessionStorage.getItem('secretaria_email')*/}
                  </Link>

                  <div
                    className="dropdown-menu dropdown-menu-right "
                    style={{ width: 'max-content' }}
                    aria-labelledby="navbarDropdown"
                  >
                    <a className="nav-link text-dark" href="#">
                      <span style={{ textTransform: 'capitalize' }}>
                        <i className="mx-2">{User}</i>
                        {sessionStorage.getItem('cargo') == 'M'
                          ? sessionStorage.getItem('med_nome')
                          : sessionStorage.getItem('secretaria_email')}
                      </span>
                    </a>
                    <Link className="nav-link text-dark" to="/alterarsenha">
                      <span>
                        <i className="mx-2">{Key}</i>
                        Alterar Senha
                      </span>
                    </Link>
                    {/* <Link className="nav-link text-dark" to="/premium">
                      <span>
                        <i style={{ color: 'blue' }} className="mx-2">
                          {Key}
                        </i>
                        BlueDoctor Premium
                      </span>
                </Link>*/}
                    {/* sessionStorage.getItem('cargo') == 'M' && (
                      <Link className="nav-link text-dark" to="/pageconfig">
                        <span>
                          <i className="mx-2">{Cogs}</i>
                          Config Página
                        </span>
                      </Link>
                    )*/}
                    <Link className="nav-link text-dark" to="/logout">
                      <span>
                        <i className="mx-2">{SignOutAlt}</i>
                        Sair
                      </span>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </nav>{' '}
        </div>
      </div>
      <br />
    </>
  );
};
export default Navbar;
