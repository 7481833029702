import React, { useState, useEffect } from 'react';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory } from 'react-router-dom';
import { Times, Edit, Key, Eye, AngleDoubleLeft } from '../utils/Fontawesome';
const Clientes = () => {
  let history = useHistory();

  const [senha, setSenha] = useState('');
  const [repetirSenha, setRepetirSenha] = useState('');

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    window.$('#sidebarCollapse').on('click', function () {
      window.$('#sidebar').toggleClass('active');
      window.$(this).toggleClass('active');
    });
  }, []);
  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  const save = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let data, url;
    if (senha !== repetirSenha) {
      handleInfo('Senha repetida esta diferente da nova senha');
      return false;
    }
    if (sessionStorage.getItem('cargo') === 'M') {
      url = 'api/medicos/alterarsenha/' + sessionStorage.getItem('med_id');
    } else {
      url =
        'api/secretarias/alterarsenha/' +
        sessionStorage.getItem('secretaria_id');
    }

    data = {
      id:
        sessionStorage.getItem('cargo') === 'M'
          ? sessionStorage.getItem('med_id')
          : sessionStorage.getItem('secretaria_id'),
      senha: senha,
    };

    api({
      method: 'put',
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        limpar();
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setRepetirSenha('');
    setSenha('');
  };

  return (
    <>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            {' '}
            {Key} Alterar senha {AngleDoubleLeft}
          </div>

          <div id="triangulo-para-baixo"></div>
          <div className="card-body">
            <form id="form_alterar_senha" onSubmit={(e) => save(e)}>
              <div className="form-row mb-3">
                <div class="input-group col-md-6 col-12 mb-3">
                  <label
                    for="senha"
                    style={{ width: '100%', color: '#7c7f82' }}
                  >
                    Nova senha:
                  </label>
                  <div
                    class="input-group mb-3"
                    style={{
                      position: 'relative',
                      right: '50px',
                    }}
                  >
                    <div
                      class="input-group-prepend"
                      onClick={(e) =>
                        (document.getElementById('senha').type =
                          document.getElementById('senha').type == 'text'
                            ? 'password'
                            : 'text')
                      }
                    >
                      {' '}
                      <div class="wrapper-tooltip">
                        <span
                          style={{
                            display: 'inline',
                            borderRadius: '50px',
                            background: '#a7c4d2 ',
                            color: 'white',
                            position: 'relative',
                            right: '-37px',
                            zIndex: '99',
                          }}
                          class="input-group-text"
                          id="basic-addon1"
                        >
                          {Eye}
                        </span>
                        <div
                          className="tooltip"
                          style={{
                            left: '45px',
                            width: '280px',
                          }}
                        >
                          Mínimo de 6 e máximo de 12 caracteres, pelo menos uma
                          letra maiúscula, uma letra minúscula, um número e um
                          caractere especial
                        </div>
                      </div>{' '}
                    </div>
                    <input
                      type="password"
                      class="form-control"
                      id="senha"
                      maxLength="12"
                      minLength="6"
                      placeholder="Digite a nova senha"
                      onChange={(e) => setSenha(e.target.value)}
                      value={senha}
                      pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                      style={{
                        position: 'absolute',
                        minWidth: '100%',
                        marginLeft: '39px',
                        padding: '7px 29px 7px 52px',
                        borderRadius: '50px 0px 0px 50px',
                        borderBottom: '6px solid #a7c4d2 ',
                      }}
                    />
                  </div>
                  {/*   <div class="input-group col-md-6 col-12 mb-3">
                  <label
                    for="senha"
                    style={{ width: '100%', color: '#7c7f82' }}
                  >
                    Nova senha:
                  </label>

                  <input
                    type="password"
                    class="form-control"
                    id="senha"
                    maxLength="12"
                    minLength="6"
                    placeholder="Digite a nova senha"
                    onChange={(e) => setSenha(e.target.value)}
                    value={senha}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                  />
                  <div
                    class="input-group-prepend"
                    style={{ width: '47px', height: '38px' }}
                  >
                    <div
                      onClick={(e) =>
                        (document.getElementById('senha').type =
                          document.getElementById('senha').type == 'text'
                            ? 'password'
                            : 'text')
                      }
                      class="input-group-text "
                    >
                      <div class="wrapper-tooltip">
                        <span className="icons">{Eye}</span>
                        <div
                          class="tooltip"
                          style={{
                            left: '-244px',
                            width: '280px',
                          }}
                        >
                          Mínimo de 6 e máximo de 12 caracteres, pelo menos uma
                          letra maiúscula, uma letra minúscula, um número e um
                          caractere especial
                        </div>
                      </div>
                    </div>
                  </div>  */}{' '}
                </div>
              </div>
              <div className="form-row mb-3">
                <div class="input-group col-md-6 col-12 mb-3">
                  <label
                    for="repetirSenha"
                    style={{ width: '100%', color: '#7c7f82' }}
                  >
                    Repita a senha:
                  </label>
                  <div
                    class="input-group mb-3"
                    style={{
                      position: 'relative',
                      right: '50px',
                    }}
                  >
                    <div
                      class="input-group-prepend"
                      onClick={(e) =>
                        (document.getElementById('repetirSenha').type =
                          document.getElementById('repetirSenha').type == 'text'
                            ? 'password'
                            : 'text')
                      }
                    >
                      {' '}
                      <div class="wrapper-tooltip">
                        <span
                          style={{
                            display: 'inline',
                            borderRadius: '50px',
                            background: '#a7c4d2 ',
                            color: 'white',
                            position: 'relative',
                            right: '-37px',
                            zIndex: '99',
                          }}
                          class="input-group-text"
                          id="basic-addon1"
                        >
                          {Eye}
                        </span>
                        <div
                          className="tooltip"
                          style={{
                            left: '45px',
                            width: '280px',
                          }}
                        >
                          Mínimo de 6 e máximo de 12 caracteres, pelo menos uma
                          letra maiúscula, uma letra minúscula, um número e um
                          caractere especial
                        </div>
                      </div>{' '}
                    </div>
                    <input
                      type="password"
                      class="form-control"
                      id="repetirSenha"
                      maxLength="12"
                      minLength="6"
                      placeholder="Repita a senha"
                      onChange={(e) => setRepetirSenha(e.target.value)}
                      value={repetirSenha}
                      pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                      style={{
                        position: 'absolute',
                        minWidth: '100%',
                        marginLeft: '39px',
                        padding: '7px 29px 7px 52px',
                        borderRadius: '50px 0px 0px 50px',
                        borderBottom: '6px solid #a7c4d2 ',
                      }}
                    />
                  </div>
                  {/*     <div class="input-group col-md-6 col-12 mb-3">
                  <label
                    for="repetirSenha"
                    style={{ width: '100%', color: '#7c7f82' }}
                  >
                    Repita a senha:
                  </label>

                  <input
                    type="password"
                    class="form-control"
                    id="repetirSenha"
                    maxLength="12"
                    minLength="6"
                    placeholder="Repita a senha"
                    onChange={(e) => setRepetirSenha(e.target.value)}
                    value={repetirSenha}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#_()@$!%*?&])[A-Za-z\d#_()@$!%*?&]{6,12}$"
                  />
                  <div
                    class="input-group-prepend"
                    style={{ width: '47px', height: '38px' }}
                  >
                    <div
                      onClick={(e) =>
                        (document.getElementById('repetirSenha').type =
                          document.getElementById('repetirSenha').type == 'text'
                            ? 'password'
                            : 'text')
                      }
                      class="input-group-text "
                    >
                      <div class="wrapper-tooltip">
                        <span className="icons">{Eye}</span>
                        <div
                          class="tooltip"
                          style={{
                            left: '-244px',
                            width: '280px',
                          }}
                        >
                          Mínimo de 6 e máximo de 12 caracteres, pelo menos uma
                          letra maiúscula, uma letra minúscula, um número e um
                          caractere especial
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
           */}{' '}
                </div>{' '}
              </div>
              <div className="text-left ">
                <button
                  className="btn btn-sm btn_Cadastrar "
                  style={{ width: '108px', height: '35px' }}
                  type="submit"
                  id="btnAlterar"
                >
                  {Edit} Alterar
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-sm btn_excluir "
                  onClick={(e) => limpar(e)}
                  style={{ width: '108px', height: '35px' }}
                  type="button"
                  id="btn_limpar"
                >
                  {Times} Limpar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clientes;
