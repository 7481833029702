const DataCIDAutocomplete = [
  'A00.0 COLERA DEV VIBRIO CHOLERAE 01 BIOT CHOLERAE',
  'A00.1 COLERA DEV VIBRIO CHOLERAE 01 BIOT EL TOR',
  'A00.9 COLERA NE',
  'A01.0 FEBRE TIFOIDE',
  'A01.1 FEBRE PARATIFOIDE A',
  'A01.2 FEBRE PARATIFOIDE B',
  'A01.3 FEBRE PARATIFOIDE C',
  'A01.4 FEBRE PARATIFOIDE NE',
  'A02.0 ENTERITE P/SALMONELA',
  'A02.1 SEPTICEMIA P/SALMONELA',
  'A02.2 INFECC LOCALIZADAS P/SALMONELA',
  'A02.8 OUTR INFECC ESPEC P/SALMONELA',
  'A02.9 INFECC NE P/SALMONELA',
  'A03.0 SHIGUELOSE DEV SHIGELLA DYSENTERIAE',
  'A03.1 SHIGUELOSE DEV SHIGELLA FLEXNERI',
  'A03.2 SHIGUELOSE DEV SHIGELLA BOYDII',
  'A03.3 SHIGUELOSE DEV SHIGELLA SONNEI',
  'A03.8 OUTR SHIGUELOSES',
  'A03.9 SHIGUELOSE NE',
  'A04.0 INFECC P/ESCHERICHIA COLI ENTEROPATOGENICA',
  'A04.1 INFECC P/ESCHERICHIA COLI ENTEROTOXIGENICA',
  'A04.2 INFECC P/ESCHERICHIA COLI ENTEROINVASIVA',
  'A04.3 INFECC P/ESCHERICHIA COLI ENTEROHEMORRAGICA',
  'A04.4 OUTR INFECC INTESTINAIS P/ESCHERICHIA COLI',
  'A04.5 ENTERITE P/CAMPYLOBACTER',
  'A04.6 ENTERITE DEV YERSINIA ENTEROCOLITICA',
  'A04.7 ENTEROCOLITE DEV CLOSTRIDIUM DIFFICILE',
  'A04.8 OUTR INFECC BACTER INTESTINAIS ESPEC',
  'A04.9 INFECC INTESTINAL BACTER NE',
  'A05.0 INTOX ALIMENTAR ESTAFILOCOCICA',
  'A05.1 BOTULISMO',
  'A05.2 INTOX ALIMENTAR DEV CLOSTRIDIUM PERFRINGENS',
  'A05.3 INTOX ALIMENTAR DEV VIBRIO PARAHEMOLYTICUS',
  'A05.4 INTOX ALIMENTAR DEV BACILLUS CEREUS',
  'A05.8 OUTR INTOX ALIMENTARES BACTER ESPEC',
  'A05.9 INTOX ALIMENTAR BACTER NE',
  'A06.0 DISENTERIA AMEBIANA AGUDA',
  'A06.1 AMEBIASE INTESTINAL CRONICA',
  'A06.2 COLITE AMEBIANA NAO-DISENTERICA',
  'A06.3 AMEBOMA INTESTINAL',
  'A06.4 ABSCESSO AMEBIANO DO FIGADO',
  'A06.5 ABSCESSO AMEBIANO DO PULMAO',
  'A06.6 ABSCESSO AMEBIANO DO CEREBRO',
  'A06.7 AMEBIASE CUTANEA',
  'A06.8 INFECC AMEBIANA DE OUTR LOCALIZ',
  'A06.9 AMEBIASE NE',
  'A07.0 BALANTIDIASE',
  'A07.1 GIARDIASE',
  'A07.2 CRIPTOSPORIDIOSE',
  'A07.3 ISOSPORIASE',
  'A07.8 OUTR DOENC INTESTINAIS ESPEC P/PROTOZOARIOS',
  'A07.9 DOENC INTESTINAL NE P/PROTOZOARIOS',
  'A08.0 ENTERITE P/ROTAVIRUS',
  'A08.1 GASTROENTEROPATIA AGUDA P/AGENTE DE NORWALK',
  'A08.2 ENTERITE P/ADENOVIRUS',
  'A08.3 OUTR ENTERITES VIRAIS',
  'A08.4 INFECC INTESTINAL DEV VIRUS NE',
  'A08.5 OUTR INFECC INTESTINAIS ESPEC',
  'A09   DIARREIA E GASTROENTERITE ORIG INFECC PRESUM',
  'A15.0 TUBERC PULM C/CONF P/EX MICR EXPEC C/S/CULT',
  'A15.1 TUBERC PULMONAR C/CONF SOMENTE P/CULTURA',
  'A15.2 TUBERC PULMONAR C/CONF HISTOLOG',
  'A15.3 TUBERC PULMONAR C/CONF P/MEIO NE',
  'A15.4 TUBERC GANGL INTRATOR C/CONF BACTER E HISTOL',
  'A15.5 TUBERC LARING TRAQ BRONQ C/CONF BACT E HIST',
  'A15.6 PLEURIS TUBERC C/CONF BACTERIOL E HISTOLOG',
  'A15.7 TUBERC PRIM VIAS RESPIR C/CONF BACT E HISTOL',
  'A15.8 OUTR FORM TUBERC VIA RESP C/CONF BACT HIST',
  'A15.9 TUBERC NE VIAS RESPIR C/CONF BACTER HISTOL',
  'A16.0 TUBERC PULMONAR C/EXAMES BACT E HIST NEGAT',
  'A16.1 TUBERC PULMONAR S/EXAME BACTER OU HISTOLOG',
  'A16.2 TUBERC PULMONAR S/MENC CONF BACT OU HIST',
  'A16.3 TUBERC GANGL INTRAT S/MENC CONF BACT HIST',
  'A16.4 TUBERC LARING TRAQ BRONQ S/MENC BACT HIST',
  'A16.5 PLEURISIA TUBERC S/MENC CONF BACT OU HISTOL',
  'A16.7 TUBERC RESPIR PRIM S/MENC CONF BACT OU HIST',
  'A16.8 OUTR TUBER VIA RESP S/MENC CONF BACT HIST',
  'A16.9 TUBERC RESPIRAT NE S/MENC CONF BACT OU HIST',
  'A17.0 MENINGITE TUBERC',
  'A17.1 TUBERCULOMA MENINGEO',
  'A17.8 OUTR TUBERC DO SIST NERVOSO',
  'A17.9 TUBERC NE DO SIST NERVOSO',
  'A18.0 TUBERC OSSEA E DAS ARTICULACOES',
  'A18.1 TUBERC DO APARELHO GENITURINARIO',
  'A18.2 LINFADENOPATIA TUBERC PERIFERICA',
  'A18.3 TUBERC INTESTINO PERITONIO GANGL MESENTER',
  'A18.4 TUBERC DE PELE E TEC CELULAR SUBCUTANEO',
  'A18.5 TUBERC DO OLHO',
  'A18.6 TUBERC DO OUVIDO',
  'A18.7 TUBERC DAS SUPRA-RENAIS',
  'A18.8 TUBERC DE OUTR ORGAOS ESPEC',
  'A19.0 TUBERC MILIAR AGUDA DE LOCALIZ UNICA ESPEC',
  'A19.1 TUBERC MILIAR AGUDA DE MULT LOCALIZ',
  'A19.2 TUBERC MILIAR AGUDA NE',
  'A19.8 OUTR TUBERC MILIARES',
  'A19.9 TUBERC MILIAR NE',
  'A20.0 PESTE BUBONICA',
  'A20.1 PESTE CELULOCUTANEA',
  'A20.2 PESTE PNEUMONICA',
  'A20.3 PESTE MENINGEA',
  'A20.7 PESTE SEPTICEMICA',
  'A20.8 OUTR FORM DE PESTE',
  'A20.9 PESTE FORM NE',
  'A21.0 TULAREMIA ULCEROGLANDULAR',
  'A21.1 TULAREMIA OCULOGLANDULAR',
  'A21.2 TULAREMIA PULMONAR',
  'A21.3 TULAREMIA GASTROINTESTINAL',
  'A21.7 TULAREMIA GENERALIZADA',
  'A21.8 OUTR FORM DE TULAREMIA',
  'A21.9 TULAREMIA FORM NE',
  'A22.0 CARBUNCULO CUTANEO',
  'A22.1 CARBUNCULO PULMONAR',
  'A22.2 CARBUNCULO GASTROINTESTINAL',
  'A22.7 SEPTICEMIA CARBUNCULOSA',
  'A22.8 OUTR FORM DE CARBUNCULO',
  'A22.9 CARBUNCULO FORM NE',
  'A23.0 BRUCELOSE P/BRUCELLA MELITENSIS',
  'A23.1 BRUCELOSE P/BRUCELLA ABORTUS',
  'A23.2 BRUCELOSE P/BRUCELLA SUIS',
  'A23.3 BRUCELOSE P/BRUCELLA CANIS',
  'A23.8 OUTR BRUCELOSES',
  'A23.9 BRUCELOSE NE',
  'A24.0 MORMO',
  'A24.1 MELIOIDOSE AGUDA E FULMINANTE',
  'A24.2 MELIOIDOSE SUBAGUDA E CRONICA',
  'A24.3 OUTR MELIOIDOSES',
  'A24.4 MELIOIDOSE NE',
  'A25.0 ESPIRILOSE',
  'A25.1 ESTREPTOBACILOSE',
  'A25.9 FEBRE TRANSM P/MORDEDURA DE RATO TIPO NE',
  'A26.0 ERISIPELOIDE CUTANEO',
  'A26.7 SEPTICEMIA P/ERYSIPELOTHRIX',
  'A26.8 OUTR FORM DE ERISIPELOIDE',
  'A26.9 ERISIPELOIDE NE',
  'A27.0 LEPTOPIROSE ICTEROHEMORRAGICA',
  'A27.8 OUTR FORM DE LEPTOSPIROSE',
  'A27.9 LEPTOSPIROSE NE',
  'A28.0 PASTEURELOSE',
  'A28.1 DOENC P/ARRANHADURA DO GATO',
  'A28.2 YERSINIOSE EXTRA-INTESTINAL',
  'A28.8 OUTR DOENC BACTER ZOONOTICAS ESPEC NCOP',
  'A28.9 DOENC BACTER ZOONOTICA NE',
  'A30.0 HANSENIASE INDETERMINADA',
  'A30.1 HANSENIASE TUBERCULOIDE',
  'A30.2 HANSENIASE TUBERCULOIDE BORDERLINE',
  'A30.3 HANSENIASE DIMORFA',
  'A30.4 HANSENIASE LEPROMATOSA BORDERLINE',
  'A30.5 HANSENIASE LEPROMATOSA',
  'A30.8 OUTR FORM DE HANSENIASE',
  'A30.9 HANSENIASE NE',
  'A31.0 INFECC PULMONAR MICOBACTERIANA',
  'A31.1 INFECC CUTANEA MICOBACTERIANA',
  'A31.8 OUTR INFECC MICOBACTERIANAS',
  'A31.9 INFECC MICOBACTERIANA NE',
  'A32.0 LISTERIOSE CUTANEA',
  'A32.1 MENINGITE E MENINGOENCEFALITE P/LISTERIA',
  'A32.7 SEPTICEMIA LISTERIOTICA',
  'A32.8 OUTR FORM DE LISTERIOSE',
  'A32.9 LISTERIOSE NE',
  'A33   TETANO DO RECEM-NASCIDO',
  'A34   TETANO OBSTETRICO',
  'A35   OUTR TIPOS DE TETANO',
  'A36.0 DIFTERIA FARINGEA',
  'A36.1 DIFTERIA NASOFARINGEA',
  'A36.2 DIFTERIA LARINGEA',
  'A36.3 DIFTERIA CUTANEA',
  'A36.8 OUTR FORM DE DIFTERIA',
  'A36.9 DIFTERIA NE',
  'A37.0 COQUELUCHE P/BORDETELLA PERTUSSIS',
  'A37.1 COQUELUCHE P/BORDETELLA PARAPERTUSSIS',
  'A37.8 COQUELUCHE P/OUTR ESPECIES DA BORDETELLA',
  'A37.9 COQUELUCHE NE',
  'A38   ESCARLATINA',
  'A39.0 MENINGITE MENINGOCOCICA',
  'A39.1 SINDR DE WATERHOUSE-FRIDERICHSEN',
  'A39.2 MENINGOCOCCEMIA AGUDA',
  'A39.3 MENINGOCOCCEMIA CRONICA',
  'A39.4 MENINGOCOCCEMIA NE',
  'A39.5 CARDITE P/MENINGOCOCOS',
  'A39.8 OUTR INFECC P/MENINGOCOCOS',
  'A39.9 INFECC MENINGOCOCICA NE',
  'A40.0 SEPTICEMIA P/STREPTOCOCCUS DO GRUPO A',
  'A40.1 SEPTICEMIA P/STREPTOCOCCUS DO GRUPO B',
  'A40.2 SEPTICEMIA P/STREPTOCOCCUS DO GRUPO D',
  'A40.3 SEPTICEMIA P/STREPTOCOCCUS PNEUMONIA',
  'A40.8 OUTR SEPTICEMIAS ESTREPTOCOCICAS',
  'A40.9 SEPTICEMIA ESTREPTOCOCICA NE',
  'A41.0 SEPTICEMIA P/STAPHYLOCOCCUS AUREUS',
  'A41.1 SEPTICEMIA P/OUTR ESTAFILOCOCOS ESPEC',
  'A41.2 SEPTICEMIA P/ESTAFILOCOCOS NE',
  'A41.3 SEPTICEMIA P/HAEMOPHILUS INFLUENZAE',
  'A41.4 SEPTICEMIA P/ANAEROBIOS',
  'A41.5 SEPTICEMIA P/OUTR MICROORG GRAM-NEGATIVOS',
  'A41.8 OUTR SEPTICEMIAS ESPEC',
  'A41.9 SEPTICEMIA NE',
  'A42.0 ACTINOMICOSE PULMONAR',
  'A42.1 ACTINOMICOSE ABDOMINAL',
  'A42.2 ACTINOMICOSE CERVICOFACIAL',
  'A42.7 SEPTICEMIA ACTINOMICOTICA',
  'A42.8 OUTR FORM DE ACTINOMICOSE',
  'A42.9 ACTINOMICOSE NE',
  'A43.0 NOCARDIOSE PULMONAR',
  'A43.1 NOCARDIOSE CUTANEA',
  'A43.8 OUTR FORM DE NOCARDIOSE',
  'A43.9 NOCARDIOSE NE',
  'A44.0 BARTONELOSE SISTEMICA',
  'A44.1 BARTONELOSE CUTANEA E CUTANEO-MUCOSA',
  'A44.8 OUTR FORM DE BARTONELOSE',
  'A44.9 BARTONELOSE NE',
  'A46   ERISIPELA',
  'A48.0 GANGRENA GASOSA',
  'A48.1 DOENC DOS LEGIONARIOS',
  'A48.2 DOENC DOS LEGIONARIOS NAO-PNEUMONICA',
  'A48.3 SINDR DO CHOQUE TOXICO',
  'A48.4 FEBRE PURPURICA DO BRASIL',
  'A48.8 OUTR DOENC BACTER ESPEC',
  'A49.0 INFECC ESTAFILOCOCICA NE',
  'A49.1 INFECC ESTREPTOCOCICA NE',
  'A49.2 INFECC P/HAEMOPHILUS INFLUENZAE NE',
  'A49.3 INFECC P/MYCOPLASMA NE',
  'A49.8 OUTR INFECC BACTER DE LOCALIZ NE',
  'A49.9 INFECC BACTER NE',
  'A50.0 SIFILIS CONGEN PRECOCE SINTOMATICA',
  'A50.1 SIFILIS CONGEN PRECOCE FORM LATENTE',
  'A50.2 SIFILIS CONGEN PRECOCE NE',
  'A50.3 OCULOPATIA SIFILITICA CONGEN TARD',
  'A50.4 NEUROSSIFILIS CONGEN TARD',
  'A50.5 OUTR FORM TARD E SINTOMAT SIFILIS CONGEN',
  'A50.6 SIFILIS CONGEN TARD LATENTE',
  'A50.7 SIFILIS CONGEN TARD NE',
  'A50.9 SIFILIS CONGEN NE',
  'A51.0 SIFILIS GENITAL PRIM',
  'A51.1 SIFILIS ANAL PRIM',
  'A51.2 SIFILIS PRIM DE OUTR LOCALIZ',
  'A51.3 SIFILIS SECUND DA PELE E DAS MUCOSAS',
  'A51.4 OUTR FORM DE SIFILIS SECUND',
  'A51.5 SIFILIS PRECOCE LATENTE',
  'A51.9 SIFILIS PRECOCE NE',
  'A52.0 SIFILIS CARDIOVASCULAR',
  'A52.1 NEUROSSIFILIS SINTOMATICA',
  'A52.2 NEUROSSIFILIS ASSINTOMATICA',
  'A52.3 NEUROSSIFILIS NE',
  'A52.7 OUTR FORM DE SIFILIS TARD SINTOMATICA',
  'A52.8 SIFILIS TARD LATENTE',
  'A52.9 SIFILIS TARD NE',
  'A53.0 SIFILIS LATENTE NE SE RECENTE OU TARD',
  'A53.9 SIFILIS NE',
  'A54.0 INF GON TR GENIT INF S/ABS PERIUR/GLAND ACES',
  'A54.1 INF GON TR GENIT INF C/ABS PERIUR/GLAND ACES',
  'A54.2 PELVIPERIT GONOC E OUTR INFECC GENITUR GONOC',
  'A54.3 INFECC GONOCOCICA DO OLHO',
  'A54.4 INFECC GONOCOCICA SIST MUSCULO-ESQUELETICO',
  'A54.5 FARINGITE GONOCOCICA',
  'A54.6 INFECC GONOCOCICA DO ANUS OU DO RETO',
  'A54.8 OUTR INFECC GONOCOCICAS',
  'A54.9 INFECC GONOCOCICA NE',
  'A55   LINFOGRANULOMA P/CLAMIDIA',
  'A56.0 INFECC P/CLAMIDIAS TRATO GENITURINARIO INFER',
  'A56.1 INFECC P/CLAMID PELVIPER E OUTR ORG GENITUR',
  'A56.2 INFECC P/CLAMIDIAS TRATO GENITUR LOCALIZ NE',
  'A56.3 INFECC DO ANUS E DO RETO P/CLAMIDIAS',
  'A56.4 INFECC DA FARINGE P/CLAMIDIAS',
  'A56.8 INFECC P/CLAMID TRANSM P/VIA SEX OUTR LOCAL',
  'A57   CANCRO MOLE',
  'A58   GRANULOMA INGUINAL',
  'A59.0 TRICOMONIASE UROGENITAL',
  'A59.8 OUTR LOCALIZ DE TRICOMONIASE',
  'A59.9 TRICOMONIASE NE',
  'A60   INFECC ANOGENITAIS P/VIRUS DO HERPES',
  'A60.1 INFECC MARGEM CUTAN ANUS/RETO P/VIRUS HERPES',
  'A60.9 INFECC ANOGENITAL NE P/VIRUS DO HERPES',
  'A63.0 VERRUGAS ANOGENITAIS',
  'A63.8 OUTR DOENC ESPEC DE TRANSM PREDOM SEXUAL',
  'A64   DOENC SEXUALMENTE TRANSM NE',
  'A65   SIFILIS NAO-VENEREA',
  'A66.0 LESOES INICIAIS DA BOUBA',
  'A66.1 PAPILOMAS MULT E BOUBA PLANTAR UMIDA',
  'A66.2 OUTR LESOES CUTANEAS PRECOCES DA BOUBA',
  'A66.3 HIPERCERATOSE DEV BOUBA',
  'A66.4 GOMAS E ULCERAS DEV BOUBA',
  'A66.5 GANGOSA',
  'A66.6 LESOES OSTEOARTICULARES DEV BOUBA',
  'A66.7 OUTR MANIFESTACOES DA BOUBA',
  'A66.8 BOUBA LATENTE',
  'A66.9 BOUBA NE',
  'A67.0 LESOES PRIM DA PINTA',
  'A67.1 LESOES INTERMEDIARIAS DA PINTA',
  'A67.2 LESOES TARD DA PINTA',
  'A67.3 LESOES MISTAS DA PINTA',
  'A67.9 PINTA NE',
  'A68.0 FEBRE RECORRENTE TRANSM P/PIOLHOS',
  'A68.1 FEBRE RECORRENTE TRANSM P/CARRAPATOS',
  'A68.9 FEBRE RECORRENTE NE',
  'A69.0 ESTOMATITE ULCERATIVA NECROTIZANTE',
  'A69.1 OUTR INFECC DE VINCENT',
  'A69.2 DOENC DE LYME',
  'A69.8 OUTR INFECC ESPEC P/ESPIROQUETAS',
  'A69.9 INFECC P/ESPIROQUETA NE',
  'A70   INFECC CAUSADAS P/CLAMIDIA PSITTACI',
  'A71.0 FASE INICIAL DO TRACOMA',
  'A71.1 FASE ATIVA DO TRACOMA',
  'A71.9 TRACOMA NE',
  'A74.0 CONJUNTIVITE CAUSADA P/CLAMIDIAS',
  'A74.8 OUTR DOENC CAUSADAS P/CLAMIDIAS',
  'A74.9 INFECC CAUSADA P/CLAMIDIAS NE',
  'A75.0 TIFO EPIDEM TRANSM P/PIOLHOS R. PROWAZEKII',
  'A75.1 TIFO RECRUDESCENTE',
  'A75.2 TIFO P/RICKETTSIA TYPHI',
  'A75.3 TIFO P/RICKETTSIA TSUTSUGAMUCHI',
  'A75.9 TIFO NE',
  'A77.0 FEBRE MACULOSA P/RICKETTSIA RICHETTSII',
  'A77.1 FEBRE MACULOSA P/RICKETTSIA CONORII',
  'A77.2 FEBRE MACULOSA DEV RICKETTSIA SIBERICA',
  'A77.3 FEBRE MACULOSA DEV RICKETTSIA AUSTRALIS',
  'A77.8 OUTR FEBRES MACULOSAS',
  'A77.9 FEBRE MACULOSA NE',
  'A78   FEBRE Q',
  'A79.0 FEBRE DAS TRINCHEIRAS',
  'A79.1 RICKETTSIOSE VARICELIFORME DEV R.AKARI',
  'A79.8 OUTR TIPOS DE RICKETTSIOSES ESPEC',
  'A79.9 RICKETTSIOSE NE',
  'A80.0 POLIOMIELITE PARAL AGUDA ASSOC VIRUS VACINAL',
  'A80.1 POLIOMIEL PARAL AGUDA VIRUS SELVAGEM IMPORT',
  'A80.2 POLIOMIEL PARAL AGUDA VIRUS SELVAGEM INDIG',
  'A80.3 POLIOMIELITES PARALITICAS AGUDAS OUTR E NE',
  'A80.4 POLIOMIELITE AGUDA NAO-PARALITICA',
  'A80.9 POLIOMIELITE AGUDA NE',
  'A81.0 DOENC DE CREUTZFELDT-JAKOB',
  'A81.1 PANENCEFALITE ESCLEROSANTE SUBAGUDA',
  'A81.2 LEUCOENCEFALOPATIA MULTIFOCAL PROGRESSIVA',
  'A81.8 OUTR INFECC P/VIRUS ATIPIC SIST NERV CENTRAL',
  'A81.9 INFECC NE SIST NERV CENTRAL P/VIRUS ATIPICOS',
  'A82.0 RAIVA SILVESTRE',
  'A82.1 RAIVA URBANA',
  'A82.9 RAIVA NE',
  'A83.0 ENCEFALITE JAPONESA',
  'A83.1 ENCEFALITE EQUINA OCIDENTAL',
  'A83.2 ENCEFALITE EQUINA ORIENTAL',
  'A83.3 ENCEFALITE DE ST. LOUIS',
  'A83.4 ENCEFALITE AUSTRALIANA',
  'A83.5 ENCEFALITE DA CALIFORNIA',
  'A83.6 DOENC P/VIRUS DE ROCIO',
  'A83.8 OUTR ENCEFALITES P/VIRUS TRANSM P/MOSQUITOS',
  'A83.9 ENCEFALITE NE P/VIRUS TRANSM P/MOSQUITOS',
  'A84.0 ENCEFALITE DA TAIGA',
  'A84.1 ENCEFALITE EUROPA CENTRAL TRANSM P/CARRAPATO',
  'A84.8 OUTR ENCEFALITES P/VIRUS TRANSM P/CARRAPATOS',
  'A84.9 ENCEFALITE NE P/VIRUS TRANSM P/CARRAPATOS',
  'A85.0 ENCEFALITE P/ENTEROVIRUS',
  'A85.1 ENCEFALITE P/ADENOVIRUS',
  'A85.2 ENCEFALITE P/VIRUS TRANSM P/ARTROPODES NE',
  'A85.8 OUTR ENCEFALITES VIRAIS ESPEC',
  'A86   ENCEFALITE VIRAL NE',
  'A87.0 MENINGITE P/ENTEROVIRUS',
  'A87.1 MENINGITE P/ADENOVIRUS',
  'A87.2 CORIOMENINGITE LINFOCITARIA',
  'A87.8 OUTR MENINGITES VIRAIS',
  'A87.9 MENINGITE VIRAL NE',
  'A88.0 FEBRE EXANTEMATICA P/ENTEROVIRUS',
  'A88.1 VERTIGEM EPIDEMICA',
  'A88.8 OUTR INFECC VIRAIS ESPEC SIST NERV CENTRAL',
  'A89   INFECC VIRAIS NE DO SIST NERVOSO CENTRAL',
  'A90   DENGUE',
  'A91   FEBRE HEMORRAGICA DEV VIRUS DO DENGUE',
  'A92.0 FEBRE DE CHIKUNGUNYA',
  'A92.1 FEBRE DE ONYONG-NYONG',
  'A92.2 FEBRE EQUINA VENEZUELANA',
  'A92.3 FEBRE P/VIRUS WEST NILE',
  'A92.4 FEBRE DO VALE DO RIFT',
  'A92.8 OUTR FEBRES VIRAIS ESPEC TRANSM P/MOSQUITOS',
  'A92.9 FEBRE VIRAL TRANSM P/MOSQUITOS NE',
  'A93.0 FEBRE DE OROPOUCHE',
  'A93.1 FEBRE P/FLEBOTOMOS',
  'A93.2 FEBRE DO COLORADO TRANSM P/CARRAPATOS',
  'A93.8 OUTR FEBRES VIRAIS ESPEC TRANSM P/ARTROPODES',
  'A94   FEBRE VIRAL TRANSM P/ARTROPODES NE',
  'A95.0 FEBRE AMARELA SILVESTRE',
  'A95.1 FEBRE AMARELA URBANA',
  'A95.9 FEBRE AMARELA NE',
  'A96.0 FEBRE HEMORRAGICA DE JUNIN',
  'A96.1 FEBRE HEMORRAGICA DE MACHUPO',
  'A96.2 FEBRE DE LASSA',
  'A96.8 OUTR FEBRES HEMORRAGICAS P/ARENAVIRUS',
  'A96.9 FEBRE HEMORRAGICA P/ARENAVIRUS NE',
  'A98.0 FEBRE HEMORRAGICA DA CRIMEIA',
  'A98.1 FEBRE HEMORRAGICA DE OMSK',
  'A98.2 DOENC DA FLORESTA DE KYASANUR',
  'A98.3 DOENC DE MARBURG',
  'A98.4 DOENC P/VIRUS EBOLA',
  'A98.5 FEBRE HEMORRAGICA C/SINDR RENAL',
  'A98.8 OUTR FEBRES HEMORRAGICAS ESPEC P/VIRUS',
  'A99   FEBRES HEMORRAGICAS VIRAIS NE',
  'B00.0 ECZEMA HERPETICO',
  'B00.1 DERMATITE VESICULAR DEV VIRUS DO HERPES',
  'B00.2 GENGIVOESTOM E FARINGOAMIG DEV VIRUS HERPES',
  'B00.3 MENINGITE DEV VIRUS DO HERPES',
  'B00.4 ENCEFALITE DEV VIRUS DO HERPES',
  'B00.5 AFECCOES OCULARES DEV VIRUS DO HERPES',
  'B00.7 DOENC DISSEMINADA DEV VIRUS DO HERPES',
  'B00.8 OUTR FORM DE INFECC DEV VIRUS DO HERPES',
  'B00.9 INFECC NE DEV VIRUS DO HERPES',
  'B01.0 MENINGITE P/VARICELA',
  'B01.1 ENCEFALITE P/VARICELA',
  'B01.2 PNEUMOPATIA VARICELOSA',
  'B01.8 VARICELA C/OUTR COMPLIC',
  'B01.9 VARICELA S/COMPLIC',
  'B02.0 ENCEFALITE P/VIRUS DO HERPES ZOSTER',
  'B02.1 MENINGITE P/VIRUS DO HERPES ZOSTER',
  'B02.2 HERPES ZOSTER ACOMP OUTR MANIF NEUROLOGICAS',
  'B02.3 HERPES ZOSTER OFTALMICO',
  'B02.7 HERPES ZOSTER DISSEMINADO',
  'B02.8 HERPES ZOSTER C/OUTR COMPLIC',
  'B02.9 HERPES ZOSTER S/COMPLIC',
  'B03   VARIOLA',
  'B04   VARIOLA DOS MACACOS',
  'B05.0 SARAMPO COMPLICADO P/ENCEFALITE',
  'B05.1 SARAMPO COMPLICADO P/MENINGITE',
  'B05.2 SARAMPO COMPLICADO P/PNEUMONIA',
  'B05.3 SARAMPO COMPLICADO P/OTITE MEDIA',
  'B05.4 SARAMPO C/COMPLIC INTESTINAIS',
  'B05.8 SARAMPO C/OUTR COMPLIC',
  'B05.9 SARAMPO S/COMPLIC',
  'B06.0 RUBEOLA C/COMPLIC NEUROLOGICAS',
  'B06.8 RUBEOLA C/OUTR COMPLIC',
  'B06.9 RUBEOLA S/COMPLIC',
  'B07   VERRUGAS ORIG VIRAL',
  'B08.0 OUTR INFECC P/ORTOPOXVIRUS',
  'B08.1 MOLUSCO CONTAGIOSO',
  'B08.2 EXANTEMA SUBITO',
  'B08.3 ERITEMA INFECC',
  'B08.4 ESTOMATITE VESIC DEV ENTEROVIRUS C/EXANTEMA',
  'B08.5 FARINGITE VESICULAR DEV ENTEROVIRUS',
  'B08.8 OUTR INF VIRAIS ESPEC LESOES PELE MEMBR MUCO',
  'B09   INF VIRAL NE CARACT P/LESOES PELE MEMBR MUCO',
  'B15.0 HEPATITE A C/COMA HEPATICO',
  'B15.9 HEPATITE A S/COMA HEPATICO',
  'B16.0 HEPATITE AGUDA B C/AGENTE DELTA C/COMA HEPAT',
  'B16.1 HEPATITE AGUDA B C/AGENTE DELTA S/COMA HEPAT',
  'B16.2 HEPATITE AGUDA B S/AGENTE DELTA C/COMA HEPAT',
  'B16.9 HEPATITE AGUDA B S/AGENTE DELTA S/COMA HEPAT',
  'B17.0 INFECC DELTA AGUDA DE PORTADOR DE HEPATITE B',
  'B17.1 HEPATITE AGUDA C',
  'B17.2 HEPATITE AGUDA E',
  'B17.8 OUTR HEPATITES VIRAIS AGUDAS ESPEC',
  'B18.0 HEPATITE VIRAL CRONICA B C/AGENTE DELTA',
  'B18.1 HEPATITE CRONICA VIRAL B S/AGENTE DELTA',
  'B18.2 HEPATITE VIRAL CRONICA C',
  'B18.8 OUTR HEPATITES CRONICAS VIRAIS',
  'B18.9 HEPATITE VIRAL CRONICA NE',
  'B19.0 HEPATITE VIRAL NE C/COMA',
  'B19.9 HEPATITE VIRAL NE S/COMA',
  'B20.0 DOENC P/HIV RESULT EM INFECC MICOBACTERIANAS',
  'B20.1 DOENC P/HIV RESULT OUTR INFECC BACTER',
  'B20.2 DOENC P/HIV RESULT EM DOENC CITOMEGALICA',
  'B20.3 DOENC P/HIV RESULT EM OUTR INFECC VIRAIS',
  'B20.4 DOENC P/HIV RESULT EM CANDIDIASE',
  'B20.5 DOENC P/HIV RESULT EM OUTR MICOSES',
  'B20.6 DOENC P/HIV RESULT PNEUMONIA P/P.CARINII',
  'B20.7 DOENC P/HIV RESULT EM INFECC MULT',
  'B20.8 DOENC P/HIV RESULT OUTR DOENC INFECC PARASIT',
  'B20.9 DOENC P/HIV RESULT DOENC INFECC PARASIT NE',
  'B21.0 DOENC P/HIV RESULT EM SARCOMA DE KAPOSI',
  'B21.1 DOENC P/HIV RESULT EM LINFOMA DE BURKITT',
  'B21.2 DOENC P/HIV RES OUTR TIP LINFOMA NAO-HODGKIN',
  'B21.3 DOENC P/HIV OUT NEOP MAL TEC LINF HEMAT CORR',
  'B21.7 DOENC P/HIV RESULT EM MULT NEOPL MALIG',
  'B21.8 DOENC P/HIV RESULT EM OUTR NEOPL MALIG',
  'B21.9 DOENC P/HIV RESULT EM NEOPL MALIG NE',
  'B22.0 DOENC P/HIV RESULT EM ENCEFALOPATIA',
  'B22.1 DOENC P/HIV RESULT PNEUMONITE INTERST LINFAT',
  'B22.2 DOENC P/HIV RESULT EM SINDR DE EMACIACAO',
  'B22.7 DOENC P/HIV RESULT EM DOENC MULT COP',
  'B23.0 SINDR DE INFECC AGUDA P/HIV',
  'B23.1 DOENC P/HIV RESULT LINFADENOPATIAS GENERALIZ',
  'B23.2 DOENC P/HIV RESULT ANOM HEMAT IMUNOLOG NCOP',
  'B23.8 DOENC P/HIV RESULT EM OUTR AFECCOES ESPEC',
  'B24   DOENC P/HIV NE',
  'B25.0 PNEUMONITE P/CITOMEGALOVIRUS',
  'B25.1 HEPATITE P/CITOMEGALOVIRUS',
  'B25.2 PANCREATITE P/CITOMEGALOVIRUS',
  'B25.8 OUTR DOENC P/CITOMEGALOVIRUS',
  'B25.9 DOENC NE P/CITOMEGALOVIRUS',
  'B26.0 ORQUITE P/CAXUMBA',
  'B26.1 MENINGITE P/CAXUMBA',
  'B26.2 ENCEFALITE P/CAXUMBA',
  'B26.3 PANCREATITE P/CAXUMBA',
  'B26.8 CAXUMBA C/OUTR COMPLIC',
  'B26.9 CAXUMBA S/COMPLIC',
  'B27.0 MONONUCLEOSE P/VIRUS HERPES GAMA',
  'B27.1 MONONUCLEOSE P/CITOMEGALOVIRUS',
  'B27.8 OUTR MONONUCLEOSES INFECC',
  'B27.9 MONONUCLEOSE INFECC NE',
  'B30.0 CERATOCONJUNTIVITE DEV ADENOVIRUS',
  'B30.1 CONJUNTIVITE DEV ADENOVIRUS',
  'B30.2 FARINGOCONJUNTIVITE VIRAL',
  'B30.3 CONJUNTIVITE HEMORRAGICA AGUDA ENDEMICA',
  'B30.8 OUTR CONJUNTIVITES VIRAIS',
  'B30.9 CONJUNTIVITE VIRAL NE',
  'B33.0 MIALGIA EPIDEMICA',
  'B33.1 DOENC DE ROSS RIVER',
  'B33.2 CARDITE VIRAL',
  'B33.3 INFECC P/RETROVIRUS NCOP',
  'B33.8 OUTR DOENC ESPEC P/VIRUS',
  'B34.0 INFECC P/ADENOVIRUS NE',
  'B34.1 INFECC P/ENTEROVIRUS NE',
  'B34.2 INFECC P/CORONAVIRUS NE',
  'B34.3 INFECC P/PARVOVIRUS NE',
  'B34.4 INFECC P/PAPOVAVIRUS NE',
  'B34.8 OUTR INFECC P/VIRUS DE LOCALIZ NE',
  'B34.9 INFECC VIRAL NE',
  'B35.0 TINHA DA BARBA E DO COURO CABELUDO',
  'B35.1 TINHA DAS UNHAS',
  'B35.2 TINHA DA MAO',
  'B35.3 TINHA DOS PES',
  'B35.4 TINHA DO CORPO',
  'B35.5 TINHA IMBRICADA',
  'B35.6 TINEA CRURIS',
  'B35.8 OUTR DERMATOFITOSES',
  'B35.9 DERMATOFITOSE NE',
  'B36.0 PITIRIASE VERSICOLOR',
  'B36.1 TINHA NEGRA',
  'B36.2 PIEDRA BRANCA',
  'B36.3 PIEDRA NEGRA',
  'B36.8 OUTR MICOSES SUPERF ESPEC',
  'B36.9 MICOSE SUPERF NE',
  'B37.0 ESTOMATITE P/CANDIDA',
  'B37.1 CANDIDIASE PULMONAR',
  'B37.2 CANDIDIASE DA PELE E DAS UNHAS',
  'B37.3 CANDIDIASE DA VULVA E DA VAGINA',
  'B37.4 CANDIDIASE DE OUTR LOCALIZ UROGENITAIS',
  'B37.5 MENINGITE P/CANDIDA',
  'B37.6 ENDOCARDITE P/CANDIDA',
  'B37.7 SEPTICEMIA P/CANDIDA',
  'B37.8 CANDIDIASE DE OUTR LOCALIZ',
  'B37.9 CANDIDIASE NE',
  'B38.0 COCCIDIOIDOMICOSE PULMONAR AGUDA',
  'B38.1 COCCIDIOIDOMICOSE PULMONAR CRONICA',
  'B38.2 COCCIDIOIDOMICOSE PULMONAR NE',
  'B38.3 COCCIDIOIDOMICOSE CUTANEA',
  'B38.4 MENINGITE P/COCCIDIOIDOMICOSE',
  'B38.7 COCCIDIOIDOMICOSE DISSEMINADA',
  'B38.8 OUTR FORM DE COCCIDIOIDOMICOSE',
  'B38.9 COCCIDIOIDOMICOSE NE',
  'B39.0 HISTOPLASMOSE PULMONAR AGUDA P/H.CAPSULATUM',
  'B39.1 HISTOPLASMOSE PULMONAR CRONIC P/H.CAPSULATUM',
  'B39.2 HISTOPLASMOSE PULMONAR NE P/H.CAPSULATUM',
  'B39.3 HISTOPLASMOSE DISSEMINADA P/H.CAPSULATUM',
  'B39.4 HISTOPLASMOSE NE P/HISTOPLASMA CAPSULATUM',
  'B39.5 HISTOPLASMOSE P/HISTOPLASMA DUBOISII',
  'B39.9 HISTOPLASMOSE NE',
  'B40.0 BLASTOMICOSE PULMONAR AGUDA',
  'B40.1 BLASTOMICOSE PULMONAR CRONICA',
  'B40.2 BLASTOMICOSE PULMONAR NE',
  'B40.3 BLASTOMICOSE CUTANEA',
  'B40.7 BLASTOMICOSE DISSEMINADA',
  'B40.8 OUTR FORM DE BLASTOMICOSE',
  'B40.9 BLASTOMICOSE NE',
  'B41.0 PARACOCCIDIOIDOMICOSE PULMONAR',
  'B41.7 PARACOCCIDIOIDOMICOSE DISSEMINADA',
  'B41.8 OUTR FORM DE PARACOCCIDIOIDOMICOSE',
  'B41.9 PARACOCCIDIOIDOMICOSE NE',
  'B42.0 ESPOROTRICOSE PULMONAR',
  'B42.1 ESPOROTRICOSE LINFOCUTANEA',
  'B42.7 ESPOROTRICOSE DISSEMINADA',
  'B42.8 OUTR FORM DE ESPOROTRICOSE',
  'B42.9 ESPOROTRICOSE NE',
  'B43.0 CROMOMICOSE CUTANEA',
  'B43.1 ABSCESSO CEREBRAL FEOMICOTICO',
  'B43.2 ABSCESSO E CISTO FEOMICOTICO SUBCUTANEOS',
  'B43.8 OUTR FORM DE CROMOMICOSE',
  'B43.9 CROMOMICOSE NE',
  'B44.0 ASPERGILOSE PULMONAR INVASIVA',
  'B44.1 OUTR ASPERGILOSES PULMONARES',
  'B44.2 ASPERGILOSE AMIGDALIANA',
  'B44.7 ASPERGILOSE DISSEMINADA',
  'B44.8 OUTR FORM DE ASPERGILOSE',
  'B44.9 ASPERGILOSE NE',
  'B45.0 CRIPTOCOCOSE PULMONAR',
  'B45.1 CRIPTOCOCOSE CEREBRAL',
  'B45.2 CRIPTOCOCOSE CUTANEA',
  'B45.3 CRIPTOCOCOSE OSSEA',
  'B45.7 CRIPTOCOCOSE DISSEMINADA',
  'B45.8 OUTR FORM DE CRIPTOCOCOSE',
  'B45.9 CRIPTOCOCOSE NE',
  'B46.0 MUCORMICOSE PULMONAR',
  'B46.1 MUCORMICOSE RINOCEREBRAL',
  'B46.2 MUCORMICOSE GASTROINTESTINAL',
  'B46.3 MUCORMICOSE CUTANEA',
  'B46.4 MUCORMICOSE DISSEMINADA',
  'B46.5 MUCORMICOSE NE',
  'B46.8 OUTR ZIGOMICOSES',
  'B46.9 ZIGOMICOSE NE',
  'B47.0 EUMICETOMA',
  'B47.1 ACTINOMICETOMA',
  'B47.9 MICETOMA NE',
  'B48.0 LOBOMICOSE',
  'B48.1 RINOSPORIDIOSE',
  'B48.2 ALESQUERIOSE',
  'B48.3 GEOTRICOSE',
  'B48.4 PENICILOSE',
  'B48.7 MICOSES OPORTUNISTAS',
  'B48.8 OUTR MICOSES ESPEC',
  'B49   MICOSE NE',
  'B50.0 MALARIA P/P.FALCIPARUM C/COMPLIC CEREBRAIS',
  'B50.8 OUTR FORM GRAV COMPL MALARIA P/P.FALCIPARUM',
  'B50.9 MALARIA NE P/PLASMODIUM FALCIPARUM',
  'B51.0 MALARIA P/PLASMODIUM VIVAX C/ROTURA DO BACO',
  'B51.8 MALARIA P/PLASMODIUM VIVAX C/OUTR COMPLIC',
  'B51.9 MALARIA P/PLASMODIUM VIVAX S/COMPLIC',
  'B52.0 MALARIA P/PLASMODIUM MALARIAE C/NEFROPATIA',
  'B52.8 MALARIA P/PLASMODIUM MALARIAE C/OUTR COMPLIC',
  'B52.9 MALARIA P/PLASMODIUM MALARIAE S/COMPLIC',
  'B53.0 MALARIA P/PLASMODIUM OVALE',
  'B53.1 MALARIA P/PLASMODIOS DE MACACOS',
  'B53.8 OUTR FORM MALARIAS C/CONF PARASITOLOG NCOP',
  'B54   MALARIA NE',
  'B55.0 LEISHMANIOSE VISCERAL',
  'B55.1 LEISHMANIOSE CUTANEA',
  'B55.2 LEISHMANIOSE CUTANEO-MUCOSA',
  'B55.9 LEISHMANIOSE NE',
  'B56.0 TRIPANOSSOMIASE P/TRYPANOSOMA GAMBIENSE',
  'B56.1 TRIPANOSSOMIASE P/TRYPANOSOMA RHODESIENSE',
  'B56.9 TRIPANOSSOMIASE AFRICANA NE',
  'B57.0 FORM AGUDA DOENC DE CHAGAS C/COMPR CARDIACO',
  'B57.1 FORM AGUDA DOENC DE CHAGAS S/COMPR CARDIACO',
  'B57.2 DOENC DE CHAGAS C/COMPR CARDIACO',
  'B57.3 DOENC DE CHAGAS C/COMPR APARELHO DIGESTIVO',
  'B57.4 DOENC DE CHAGAS C/COMPR DO SIST NERVOSO',
  'B57.5 DOENC DE CHAGAS C/COMPR DE OUTR ORGAOS',
  'B58.0 OCULOPATIA P/TOXOPLASMA',
  'B58.1 HEPATITE P/TOXOPLASMA',
  'B58.2 MENINGOENCEFALITE P/TOXOPLASMA',
  'B58.3 TOXOPLASMOSE PULMONAR',
  'B58.8 TOXOPLASMOSE C/COMPR DE OUTR ORGAOS',
  'B58.9 TOXOPLASMOSE NE',
  'B59   PNEUMOCISTOSE',
  'B60.0 BABESIOSE',
  'B60.1 ACANTAMEBIASE',
  'B60.2 NAEGLERIASE',
  'B60.8 OUTR DOENC ESPEC DEV PROTOZOARIOS',
  'B64   DOENC NE DEV PROTOZOARIOS',
  'B65.0 ESQUISTOSSOMOSE DEV SCHISTOSOMA HAEMATOBIUM',
  'B65.1 ESQUISTOSSOMOSE DEV SCHISTOSOMA MANSONI',
  'B65.2 ESQUISTOSSOMOSE DEV SCHISTOSOMA JAPONICUM',
  'B65.3 DERMATITE P/CERCARIAS',
  'B65.8 OUTR ESQUISTOSSOMOSES',
  'B65.9 ESQUISTOSSOMOSE NE',
  'B66.0 OPISTORQUIASE',
  'B66.1 CLONORQUIASE',
  'B66.2 DICROCELIASE',
  'B66.3 FASCIOLIASE',
  'B66.4 PARAGONIMIASE',
  'B66.5 FASCIOLOPSIASE',
  'B66.8 OUTR INFESTACOES P/TREMATODEOS ESPEC',
  'B66.9 INFECC NE P/TREMATODEO',
  'B67.0 INFESTACAO HEPATICA P/E.GRANULOSUS',
  'B67.1 INFESTACAO PULMONAR P/E.GRANULOSUS',
  'B67.2 INFESTACAO OSSEA P/ECHINOCOCCUS GRANULOSUS',
  'B67.3 INFESTACOES P/E.GRANULOSUS OUTR E LOCAL MULT',
  'B67.4 INFESTACAO NE P/ECHINOCOCCUS GRANULOSUS',
  'B67.5 INFESTACAO HEPATICA P/E.MULTILOCULARIS',
  'B67.6 INFECC P/E.MULTILOCULARIS OUTR E LOCAL MULT',
  'B67.7 INFESTACAO NE P/ECHINOCOCCUS MULTILOCULARIS',
  'B67.8 INFESTACAO HEPATICA NE P/ECHINOCOCCUS',
  'B67.9 INFESTACOES P/ECHINOCOCCUS OUTR E AS NE',
  'B68.0 INFESTACAO P/TAENIA SOLIUM',
  'B68.1 INFESTACAO P/TAENIA SAGINATA',
  'B68.9 INFESTACAO NE P/TAENIA',
  'B69.0 CISTICERCOSE DO SIST NERVOSO CENTRAL',
  'B69.1 CISTICERCOSE DO OLHO',
  'B69.8 CISTICERCOSE DE OUTR LOCALIZ',
  'B69.9 CISTICERCOSE NE',
  'B70.0 DIFILOBOTRIASE',
  'B70.1 ESPARGANOSE',
  'B71.0 INFESTACAO P/HYMENOLEPIS',
  'B71.1 INFESTACAO P/DIPYLIDIUM',
  'B71.8 OUTR INFESTACOES ESPEC P/CESTOIDES',
  'B71.9 INFESTACAO NE P/CESTOIDES',
  'B72   DRACONTIASE',
  'B73   ONCOCERCOSE',
  'B74.0 FILARIOSE P/WUCHERERIA BANCROFTI',
  'B74.1 FILARIOSE P/BRUGIA MALAYI',
  'B74.2 FILARIOSE P/BRUGIA TIMORI',
  'B74.3 LOAIASE',
  'B74.4 MANSONELOSE',
  'B74.8 OUTR FILARIOSES',
  'B74.9 FILARIOSE NE',
  'B75   TRIQUINOSE',
  'B76.0 ANCILOSTOMOSE',
  'B76.1 NECATORIASE',
  'B76.8 OUTR ANCILOSTOMIASES',
  'B76.9 ANCILOSTOMIASE NE',
  'B77.0 ASCARIDIASE C/COMPLIC INTESTINAIS',
  'B77.8 ASCARIDIASE C/OUTR COMPLIC',
  'B77.9 ASCARIDIASE NE',
  'B78.0 ESTRONGILOIDIASE INTESTINAL',
  'B78.1 ESTRONGILOIDIASE CUTANEA',
  'B78.7 ESTRONGILOIDIASE DISSEMINADA',
  'B78.9 ESTRONGILOIDIASE NE',
  'B79   TRICURIASE',
  'B80   OXIURIASE',
  'B81.0 ANISAQUIASE',
  'B81.1 CAPILARIASE INTESTINAL',
  'B81.2 TRICOSTRONGILOSE',
  'B81.3 ANGIOSTRONGILOIDOSE INTESTINAL',
  'B81.4 HELMINTIASES INTESTINAIS MISTAS',
  'B81.8 OUTR HELMINTIASES INTESTINAIS ESPEC',
  'B82.0 HELMINTIASE INTESTINAL NE',
  'B82.9 PARASITOSE INTESTINAL NE',
  'B83.0 LARVA MIGRANS VISCERAL',
  'B83.1 GNATOSTOMIASE',
  'B83.2 ANGROSTRONGILOIDOSE DEV P.CANTONENSIS',
  'B83.3 SINGAMOSE',
  'B83.4 HIRUDINIASE INTERNA',
  'B83.8 OUTR HELMINTIASES ESPEC',
  'B83.9 HELMINTIASE NE',
  'B85.0 PEDICULOSE DEV PEDICULUS HUMANUS CAPITIS',
  'B85.1 PEDICULOSE DEV PEDICULUS HUMANUS CORPORIS',
  'B85.2 PEDICULOSE NE',
  'B85.3 FTIRIASE',
  'B85.4 PEDICULOSE E FTIRIASE MISTA',
  'B86   ESCABIOSE',
  'B87.0 MIIASE CUTANEA',
  'B87.1 MIIASE DAS FERIDAS',
  'B87.2 MIIASE OCULAR',
  'B87.3 MIIASE NASOFARINGEA',
  'B87.4 MIIASE AURICULAR',
  'B87.8 MIIASE DE OUTR LOCALIZ',
  'B87.9 MIIASE NE',
  'B88.0 OUTR ACARIASES',
  'B88.1 TUNGIASE',
  'B88.2 OUTR INFESTACOES P/ARTROPODOS',
  'B88.3 HIRUDINIASE EXTERNA',
  'B88.8 OUTR INFESTACOES ESPEC',
  'B88.9 INFESTACAO NE',
  'B89   DOENC PARASIT NE',
  'B90.0 SEQUELAS DE TUBERC DO SIST NERVOSO CENTRAL',
  'B90.1 SEQUELAS DE TUBERC GENITURINARIA',
  'B90.2 SEQUELAS DE TUBERC OSSEA E DAS ARTICULACOES',
  'B90.8 SEQUELAS DE TUBERC DE OUTR ORGAOS',
  'B90.9 SEQUELAS DE TUBERC VIAS RESPIRAT E ORGAOS NE',
  'B91   SEQUELAS DE POLIOMIELITE',
  'B92   SEQUELAS DE HANSENIASE',
  'B94.0 SEQUELAS DE TRACOMA',
  'B94.1 SEQUELAS DE ENCEFALITE VIRAL',
  'B94.2 SEQUELAS DE HEPATITE VIRAL',
  'B94.8 SEQUELAS OUTR DOENC INFECC E PARASIT ESPEC',
  'B94.9 SEQUELAS DE DOENC INFECC OU PARASIT NE',
  'B95.0 ESTREPTOC GRUPO A CAUSA DOENC CLASS OUTR CAP',
  'B95.1 ESTREPTOC GRUPO B CAUSA DOENC CLASS OUTR CAP',
  'B95.2 ESTREPTOC GRUPO D CAUSA DOENC CLASS OUTR CAP',
  'B95.3 S. PNEUMONIAE CAUSA DOENC CLASS OUTR CAP',
  'B95.4 OUTR ESTREPTOCOCO CAUSA DOENC CLASS OUTR CAP',
  'B95.5 ESTREPTOCOCO NE CAUSA DOENC CLASS OUTR CAP',
  'B95.6 S.AUREUS CAUSA DOENC CLASS OUTR CAP',
  'B95.7 OUTR ESTAFILOCOCO CAUSA DOENC CLASS OUTR CAP',
  'B95.8 ESTAFILOCOCO NE CAUSA DOENC CLASS OUTR CAP',
  'B96.0 M.PNEUMONIAE CAUSA DOENC CLASS OUTR CAP',
  'B96.1 K.PNEUMONIAE COMO DOENC CLASS OUTR CAP',
  'B96.2 ESCHERICHIA COLI COMO DOENC CLASS OUTR CAP',
  'B96.3 H.INFLUENZAE COMO DOENC CLASS OUTR CAP',
  'B96.4 PROTEUS COMO DOENC CLASS OUTR CAP',
  'B96.5 PSEUDOMONAS COMO DOENC CLASS OUTR CAP',
  'B96.6 BACILLUS FRAGILIS COMO DOENC CLASS OUTR CAP',
  'B96.7 C.PERFRINGENS COMO DOENC CLASS OUTR CAP',
  'B96.8 OUTR AG BACT ESPEC COMO DOENC CLASS OUTR CAP',
  'B97.0 ADENOVIRUS COMO DOENC CLASS OUTR CAP',
  'B97.1 ENTEROVIRUS COMO DOENC CLASS OUTR CAP',
  'B97.2 CORONAVIRUS COMO DOENC CLASS OUTR CAP',
  'B97.3 RETROVIRUS COMO DOENC CLASS OUTR CAP',
  'B97.4 VIRUS SINCIC RESP COMO DOENC CLASS OUTR CAP',
  'B97.5 REOVIRUS COMO DOENC CLASS OUTR CAP',
  'B97.6 PARVOVIRUS COMO DOENC CLASS OUTR CAP',
  'B97.7 PAPILOMAVIRUS COMO DOENC CLASS OUTR CAP',
  'B97.8 OUTR AGENT VIRAIS COMO DOENC CLASS OUTR CAP',
  'B99   DOENC INFECC OUTR E AS NE',
  'C00.0 LABIO SUPER EXTERNO',
  'C00.1 LABIO INFER EXTERNO',
  'C00.2 LABIO EXTERNO NE',
  'C00.3 LABIO SUPER FACE INTERNA',
  'C00.4 LABIO INFER FACE INTERNA',
  'C00.5 LABIO S/ESPECIFICACAO FACE INTERNA',
  'C00.6 COMISSURA LABIAL',
  'C00.8 LESAO INVASIVA DO LABIO',
  'C00.9 LABIO NE',
  'C01   NEOPL MALIG DA BASE DA LINGUA',
  'C02.0 FACE DORSAL DA LINGUA',
  'C02.1 BORDA DA LINGUA',
  'C02.2 FACE VENTRAL DA LINGUA',
  'C02.3 DOIS TERCOS ANTERIORES DA LINGUA PARTE NE',
  'C02.4 AMIGDALA LINGUAL',
  'C02.8 LESAO INVASIVA DA LINGUA',
  'C02.9 LINGUA NE',
  'C03.0 GENGIVA SUPER',
  'C03.1 GENGIVA INFER',
  'C03.9 GENGIVA NE',
  'C04.0 ASSOALHO ANTERIOR DA BOCA',
  'C04.1 ASSOALHO LATERAL DA BOCA',
  'C04.8 LESAO INVASIVA DO ASSOALHO DA BOCA',
  'C04.9 ASSOALHO DA BOCA NE',
  'C05.0 PALATO DURO',
  'C05.1 PALATO MOLE',
  'C05.2 UVULA',
  'C05.8 LESAO INVASIVA DO PALATO',
  'C05.9 PALATO NE',
  'C06.0 MUCOSA ORAL',
  'C06.1 VESTIBULO DA BOCA',
  'C06.2 AREA RETROMOLAR',
  'C06.8 LESAO INVASIVA OUTR PARTES E PARTES NE BOCA',
  'C06.9 BOCA NE',
  'C07   NEOPL MALIG DA GLAND PAROTIDA',
  'C08.0 GLAND SUBMANDIBULAR',
  'C08.1 GLAND SUBLINGUAL',
  'C08.8 LESAO INVASIVA DE GLAND SALIVARES MAIORES',
  'C08.9 GLAND SALIVAR MAIOR NE',
  'C09.0 FOSSA AMIGDALIANA',
  'C09.1 PILAR AMIGDALIANO',
  'C09.8 LESAO INVASIVA DA AMIGDALA',
  'C09.9 AMIGDALA NE',
  'C10.0 VALECULA',
  'C10.1 FACE ANTERIOR DA EPIGLOTE',
  'C10.2 PAREDE LATERAL DA OROFARINGE',
  'C10.3 PAREDE POSTERIOR DA OROFARINGE',
  'C10.4 FENDA BRANQUIAL',
  'C10.8 LESAO INVASIVA DA OROFARINGE',
  'C10.9 OROFARINGE NE',
  'C11.0 PAREDE SUPER DA NASOFARINGE',
  'C11.1 PAREDE POSTERIOR DA NASOFARINGE',
  'C11.2 PAREDE LATERAL DA NASOFARINGE',
  'C11.3 PAREDE ANTERIOR DA NASOFARINGE',
  'C11.8 LESAO INVASIVA DA NASOFARINGE',
  'C11.9 NASOFARINGE NE',
  'C12   NEOPL MALIG DO SEIO PIRIFORME',
  'C13.0 REGIAO POS-CRICOIDEA',
  'C13.1 PREGA ARIEPIGLOTICA FACE HIPOFARINGEA',
  'C13.2 PAREDE POSTERIOR DA HIPOFARINGE',
  'C13.8 LESAO INVASIVA DA HIPOFARINGE',
  'C13.9 HIPOFARINGE NE',
  'C14.0 FARINGE NE',
  'C14.2 ANEL DE WALDEYER',
  'C14.8 LESAO INVASIVA LABIO CAVIDADE ORAL E FARINGE',
  'C15.0 PORCAO CERVICAL DO ESOFAGO',
  'C15.1 PORCAO TORACICA DO ESOFAGO',
  'C15.2 PORCAO ABDOMINAL DO ESOFAGO',
  'C15.3 TERCO SUPER DO ESOFAGO',
  'C15.4 TERCO MEDIO DO ESOFAGO',
  'C15.5 TERCO INFER DO ESOFAGO',
  'C15.8 LESAO INVASIVA DO ESOFAGO',
  'C15.9 ESOFAGO NE',
  'C16.0 CARDIA',
  'C16.1 FUNDO DO ESTOMAGO',
  'C16.2 CORPO DO ESTOMAGO',
  'C16.3 ANTRO PILORICO',
  'C16.4 PILORO',
  'C16.5 PEQUENA CURVATURA DO ESTOMAGO NE',
  'C16.6 GRANDE CURVATURA DO ESTOMAGO NE',
  'C16.8 LESAO INVASIVA DO ESTOMAGO',
  'C16.9 ESTOMAGO NE',
  'C17.0 DUODENO',
  'C17.1 JEJUNO',
  'C17.2 ILEO',
  'C17.3 DIVERTICULO DE MECKEL',
  'C17.8 LESAO INVASIVA DO INTESTINO DELGADO',
  'C17.9 INTESTINO DELGADO NE',
  'C18.0 CECO',
  'C18.1 APENDICE',
  'C18.2 COLON ASCENDENTE',
  'C18.3 FLEXURA HEPATICA',
  'C18.4 COLON TRANSVERSO',
  'C18.5 FLEXURA ESPLENICA',
  'C18.6 COLON DESCENDENTE',
  'C18.7 COLON SIGMOIDE',
  'C18.8 LESAO INVASIVA DO COLON',
  'C18.9 COLON NE',
  'C19   NEOPL MALIG DA JUNCAO RETOSSIGMOIDE',
  'C20   NEOPL MALIG DO RETO',
  'C21.0 ANUS NE',
  'C21.1 CANAL ANAL',
  'C21.2 ZONA CLOACOGENICA',
  'C21.8 LESAO INVASIVA DO RETO ANUS E DO CANAL ANAL',
  'C22.0 CARCINOMA DE CELULAS HEPATICAS',
  'C22.1 CARCINOMA DE VIAS BILIARES INTRA-HEPATICAS',
  'C22.2 HEPATOBLASTOMA',
  'C22.3 ANGIOSSARCOMA DO FIGADO',
  'C22.4 OUTR SARCOMAS DO FIGADO',
  'C22.7 OUTR CARCINOMAS ESPEC DO FIGADO',
  'C22.9 NEOPL MALIG DO FIGADO NE',
  'C23   NEOPL MALIG DA VESICULA BILIAR',
  'C24.0 VIAS BILIARES EXTRA-HEPATICAS',
  'C24.1 AMPOLA DE VATER',
  'C24.8 LESAO INVASIVA DAS VIAS BILIARES',
  'C24.9 VIA BILIAR NE',
  'C25.0 CABECA DO PANCREAS',
  'C25.1 CORPO DO PANCREAS',
  'C25.2 CAUDA DO PANCREAS',
  'C25.3 CANAL PANCREATICO',
  'C25.4 PANCREAS ENDOCRINO',
  'C25.7 NEOPL MALIG DE OUTR PARTES DO PANCREAS',
  'C25.8 LESAO INVASIVA DO PANCREAS',
  'C25.9 PANCREAS NE',
  'C26.0 TRATO INTESTINAL PARTE NE',
  'C26.1 BACO',
  'C26.8 LESAO INVASIVA DO APARELHO DIGESTIVO',
  'C26.9 LOCALIZ MAL DEF DENTRO APARELHO DIGESTIVO',
  'C30.0 CAVIDADE NASAL',
  'C30.1 OUVIDO MEDIO',
  'C31.0 SEIO MAXILAR',
  'C31.1 SEIO ETMOIDAL',
  'C31.2 SEIO FRONTAL',
  'C31.3 SEIO ESFENOIDAL',
  'C31.8 LESAO INVASIVA DOS SEIOS DA FACE',
  'C31.9 SEIO DA FACE NE',
  'C32.0 GLOTE',
  'C32.1 REGIAO SUPRAGLOTICA',
  'C32.2 REGIAO SUBGLOTICA',
  'C32.3 CARTILAGENS DA LARINGE',
  'C32.8 LESAO INVASIVA DA LARINGE',
  'C32.9 LARINGE NE',
  'C33   NEOPL MALIG DA TRAQUEIA',
  'C34.0 BRONQUIO PRINCIPAL',
  'C34.1 LOBO SUPER BRONQUIO OU PULMAO',
  'C34.2 LOBO MEDIO BRONQUIO OU PULMAO',
  'C34.3 LOBO INFER BRONQUIO OU PULMAO',
  'C34.8 LESAO INVASIVA DOS BRONQUIOS E DOS PULMOES',
  'C34.9 BRONQUIOS OU PULMOES NE',
  'C37   NEOPL MALIG DO TIMO',
  'C38.0 CORACAO',
  'C38.1 MEDIASTINO ANTERIOR',
  'C38.2 MEDIASTINO POSTERIOR',
  'C38.3 MEDIASTINO PORCAO NE',
  'C38.4 PLEURA',
  'C38.8 LESAO INVASIVA CORACAO MEDIASTINO E PLEURA',
  'C39.0 TRATO RESPIRAT SUPER PORCAO NE',
  'C39.8 LESAO INVASIVA AP RESPIRAT ORG INTRATORACIC',
  'C39.9 LOCALIZ MAL DEFINIDAS DO APARELHO RESPIRAT',
  'C40.0 OMOPLATA E OSSOS LONGOS DOS MEMBROS SUPER',
  'C40.1 OSSOS CURTOS DOS MEMBROS SUPER',
  'C40.2 OSSOS LONGOS DOS MEMBROS INFER',
  'C40.3 OSSOS CURTOS DOS MEMBROS INFER',
  'C40.8 LESAO INVASIVA OSSOS/CARTILAG ARTIC MEMBROS',
  'C40.9 OSSOS E CARTILAGENS ARTICULARES DE MEMBRO NE',
  'C41.0 OSSOS DO CRANIO E DA FACE',
  'C41.1 MANDIBULA',
  'C41.2 COLUNA VERTEBRAL',
  'C41.3 COSTELAS ESTERNO E CLAVICULA',
  'C41.4 OSSOS DA PELVE SACRO E COCCIX',
  'C41.8 LESAO INVASIVA OSSOS E CARTILAG ARTICULARES',
  'C41.9 OSSOS E CARTILAGENS ARTICULARES NE',
  'C43.0 MELANOMA MALIG DO LABIO',
  'C43.1 MELANOMA MALIG PALPEBRA INCL COMISS PALPEBR',
  'C43.2 MELANOMA MALIG ORELHA CONDUTO AUDITIV EXTERN',
  'C43.3 MELANOMA MALIG OUTR PARTES E NE DA FACE',
  'C43.4 MELANOMA MALIG COURO CABELUDO E DO PESCOCO',
  'C43.5 MELANOMA MALIGNO DO TRONCO',
  'C43.6 MELANOMA MALIGNO DO MEMBRO SUPER INCL OMBRO',
  'C43.7 MELANOMA MALIGNO MEMBRO INFER INCL QUADRIL',
  'C43.8 MELANOMA MALIGNO INVASIVO DA PELE',
  'C43.9 MELANOMA MALIGNO DE PELE NE',
  'C44.0 PELE DO LABIO',
  'C44.1 PELE DA PALPEBRA INCL O CANTO',
  'C44.2 PELE DA ORELHA E DO CONDUTO AUDITIVO EXTERNO',
  'C44.3 PELE DE OUTR PARTES E DE PARTES NE DA FACE',
  'C44.4 PELE DO COURO CABELUDO E DO PESCOCO',
  'C44.5 PELE DO TRONCO',
  'C44.6 PELE DO MEMBRO SUPER INCL OMBRO',
  'C44.7 PELE DO MEMBRO INFER INCL QUADRIL',
  'C44.8 LESAO INVASIVA DA PELE',
  'C44.9 NEOPL MALIG DA PELE NE',
  'C45.0 MESOTELIOMA DA PLEURA',
  'C45.1 MESOTELIOMA DO PERITONIO',
  'C45.2 MESOTELIOMA DO PERICARDIO',
  'C45.7 MESOTELIOMA DE OUTR LOCALIZ',
  'C45.9 MESOTELIOMA NE',
  'C46.0 SARCOMA DE KAPOSI DA PELE',
  'C46.1 SARCOMA DE KAPOSI DE TEC MOLES',
  'C46.2 SARCOMA DE KAPOSI DO PALATO',
  'C46.3 SARCOMA DE KAPOSI DOS GANGLIOS LINFATICOS',
  'C46.7 SARCOMA DE KAPOSI DE OUTR LOCALIZ',
  'C46.8 SARCOMA DE KAPOSI DE MULT ORGAOS',
  'C46.9 SARCOMA DE KAPOSI NE',
  'C47.0 NERVOS PERIFERICOS DA CABECA FACE E PESCOCO',
  'C47.1 NERVOS PERIFERICOS MEMBROS SUPER INCL OMBRO',
  'C47.2 NERVOS PERIFERIC MEMBROS INFER INCL QUADRIL',
  'C47.3 NERVOS PERIFERICOS DO TORAX',
  'C47.4 NERVOS PERIFERICOS DO ABDOME',
  'C47.5 NERVOS PERIFERICOS DA PELVE',
  'C47.6 NERVOS PERIFERICOS DO TRONCO',
  'C47.8 LESAO INVASIVA NERVOS PERIF E SIST NERV AUTO',
  'C47.9 NERVOS PERIFERICOS E SIST NERV AUTONOMO NE',
  'C48.0 RETROPERITONIO',
  'C48.1 PARTES ESPEC DO PERITONIO',
  'C48.2 PERITONIO',
  'C48.8 LESAO INVASIVA TEC MOLES RETRO- E PERITONIO',
  'C49.0 TEC CONJUNTIVO E MOLES CABECA FACE E PESCOCO',
  'C49.1 TEC CONJUNTIVO E MOLES MEMBR SUP INCL OMBRO',
  'C49.2 TEC CONJUNTIVO E MOLES MEMBR INF INCL QUADR',
  'C49.3 TEC CONJUNTIVO E TEC MOLES DO TORAX',
  'C49.4 TEC CONJUNTIVO E TEC MOLES DO ABDOME',
  'C49.5 TEC CONJUNTIVO E TEC MOLES DA PELVE',
  'C49.6 TEC CONJUNTIVO E TEC MOLES DO TRONCO',
  'C49.8 LESAO INVASIVA TEC CONJUNTIVO E TEC MOLES',
  'C49.9 NEOPL MALIG TEC CONJUNTIVO E TEC MOLES NE',
  'C50.0 MAMILO E AREOLA',
  'C50.1 PORCAO CENTRAL DA MAMA',
  'C50.2 QUADRANTE SUPER INTERNO DA MAMA',
  'C50.3 QUADRANTE INFER INTERNO DA MAMA',
  'C50.4 QUADRANTE SUPER EXTERNO DA MAMA',
  'C50.5 QUADRANTE INFER EXTERNO DA MAMA',
  'C50.6 PORCAO AXILAR DA MAMA',
  'C50.8 LESAO INVASIVA DA MAMA',
  'C50.9 MAMA NE',
  'C51.0 GRANDES LABIOS',
  'C51.1 PEQUENOS LABIOS',
  'C51.2 CLITORIS',
  'C51.8 LESAO INVASIVA DA VULVA',
  'C51.9 VULVA NE',
  'C52   NEOPL MALIG DA VAGINA',
  'C53.0 ENDOCERVIX',
  'C53.1 EXOCERVIX',
  'C53.8 LESAO INVASIVA DO COLO DO UTERO',
  'C53.9 COLO DO UTERO NE',
  'C54.0 ISTMO DO UTERO',
  'C54.1 ENDOMETRIO',
  'C54.2 MIOMETRIO',
  'C54.3 FUNDO DO UTERO',
  'C54.8 LESAO INVASIVA DO CORPO DO UTERO',
  'C54.9 CORPO DO UTERO NE',
  'C55   NEOPL MALIG DO UTERO PORCAO NE',
  'C56   NEOPL MALIG DO OVARIO',
  'C57.0 TROMPA DE FALOPIO',
  'C57.1 LIGAMENTO LARGO',
  'C57.2 LIGAMENTO REDONDO',
  'C57.3 PARAMETRIO',
  'C57.4 ANEXOS UTERINOS',
  'C57.7 OUTR PARTES ESPEC ORGAOS GENITAIS FEMIN',
  'C57.8 LESAO INVASIVA DOS ORGAOS GENITAIS FEMIN',
  'C57.9 ORGAO GENITAL FEMIN NE',
  'C58   NEOPL MALIG DA PLACENTA',
  'C60.0 PREPUCIO',
  'C60.1 GLANDE',
  'C60.2 CORPO DO PENIS',
  'C60.8 LESAO INVASIVA DO PENIS',
  'C60.9 PENIS NE',
  'C61   NEOPL MALIG DA PROSTATA',
  'C62.0 TESTICULO CRIPTORQUIDICO',
  'C62.1 TESTICULO TOPICO',
  'C62.9 TESTICULO SOE',
  'C63.0 EPIDIDIMO',
  'C63.1 CORDAO ESPERMATICO',
  'C63.2 ESCROTO',
  'C63.7 OUTR ORGAOS GENITAIS MASC ESPEC',
  'C63.8 LESAO INVASIVA ORGAOS GENITAIS MASC',
  'C63.9 ORGAO GENITAL MASC NE',
  'C64   NEOPL MALIG DO RIM EXCETO PELVE RENAL',
  'C65   NEOPL MALIG DA PELVE RENAL',
  'C66   NEOPL MALIG DOS URETERES',
  'C67.0 TRIGONO DA BEXIGA',
  'C67.1 CUPULA DA BEXIGA',
  'C67.2 PAREDE LATERAL DA BEXIGA',
  'C67.3 PAREDE ANTERIOR DA BEXIGA',
  'C67.4 PAREDE POSTERIOR DA BEXIGA',
  'C67.5 COLO DA BEXIGA',
  'C67.6 ORIFICIO URETERICO',
  'C67.7 URACO',
  'C67.8 LESAO INVASIVA DA BEXIGA',
  'C67.9 BEXIGA SOE',
  'C68.0 URETRA',
  'C68.1 GLAND PARAURETRAL',
  'C68.8 LESAO INVASIVA DOS ORGAOS URINARIOS',
  'C68.9 ORGAO URINARIO NE',
  'C69.0 CONJUNTIVA',
  'C69.1 CORNEA',
  'C69.2 RETINA',
  'C69.3 COROIDE',
  'C69.4 CORPO CILIAR',
  'C69.5 GLAND E CANAL LACRIMAL',
  'C69.6 ORBITA',
  'C69.8 LESAO INVASIVA DO OLHO E ANEXOS',
  'C69.9 OLHO NE',
  'C70.0 MENINGES CEREBRAIS',
  'C70.1 MENINGES ESPINHAIS',
  'C70.9 MENINGE NE',
  'C71.0 CEREBRO EXCETO LOBOS E VENTRICULOS',
  'C71.1 LOBO FRONTAL',
  'C71.2 LOBO TEMPORAL',
  'C71.3 LOBO PARIETAL',
  'C71.4 LOBO OCCIPITAL',
  'C71.5 VENTRICULO CEREBRAL',
  'C71.6 CEREBELO',
  'C71.7 TRONCO CEREBRAL',
  'C71.8 LESAO INVASIVA DO ENCEFALO',
  'C71.9 ENCEFALO NE',
  'C72.0 MEDULA ESPINHAL',
  'C72.1 CAUDA EQUINA',
  'C72.2 NERVO OLFATIVO',
  'C72.3 NERVO OPTICO',
  'C72.4 NERVO ACUSTICO',
  'C72.5 OUTR NERVOS CRANIANOS E OS NE',
  'C72.8 LESAO INVASIVA ENCEF OUT PART SIST NERV CENT',
  'C72.9 SIST NERVOSO CENTRAL NE',
  'C73   NEOPL MALIG DA GLAND TIREOIDE',
  'C74.0 CORTEX DA SUPRA-RENAL',
  'C74.1 MEDULA DA SUPRA-RENAL',
  'C74.9 GLAND SUPRA-RENAL NE',
  'C75.0 GLAND PARATIREOIDE',
  'C75.1 GLAND HIPOFISE',
  'C75.2 CONDUTO CRANIOFARINGEO',
  'C75.3 GLAND PINEAL',
  'C75.4 CORPO CAROTIDEO',
  'C75.5 CORPO AORTICO E OUTR PARAGANGLIOS',
  'C75.8 COMPR PLURIGLANDULAR SOE',
  'C75.9 GLAND ENDOCRINA NE',
  'C76.0 CABECA FACE E PESCOCO',
  'C76.1 TORAX',
  'C76.2 ABDOME',
  'C76.3 PELVE',
  'C76.4 MEMBRO SUPER',
  'C76.5 MEMBRO INFER',
  'C76.7 OUTR LOCALIZ MAL DEFINIDAS',
  'C76.8 LESAO INVASIVA OUTR LOCALIZ E MAL DEFINIDAS',
  'C77.0 GANGLIOS LINFATICOS DA CABECA FACE E PESCOCO',
  'C77.1 GANGLIOS LINFATICOS INTRATORACICOS',
  'C77.2 GANGLIOS LINFATICOS INTRA-ABDOMINAIS',
  'C77.3 GANGLIOS LINFATICOS AXILARES E MEMBROS SUPER',
  'C77.4 GANGLIOS LINFATICOS INGUINAIS E MEMBR INFER',
  'C77.5 GANGLIOS LINFATICOS INTRAPELVICOS',
  'C77.8 GANGLIOS LINFATICOS DE MULT REGIOES',
  'C77.9 GANGLIO LINFATICO NE',
  'C78.0 NEOPL MALIG SECUND DOS PULMOES',
  'C78.1 NEOPL MALIG SECUND DO MEDIASTINO',
  'C78.2 NEOPL MALIG SECUND DA PLEURA',
  'C78.3 NEOPL MALIG SECUND OUTR ORGAOS RESPIRAT E NE',
  'C78.4 NEOPL MALIG SECUND DO INTESTINO DELGADO',
  'C78.5 NEOPL MALIG SECUND INTESTINO GROSSO E RETO',
  'C78.6 NEOPL MALIG SECUND RETRO- E PERITONIO',
  'C78.7 NEOPL MALIG SECUND DO FIGADO',
  'C78.8 NEOPL MALIG SECUND OUTR ORG DIGESTIVOS E NE',
  'C79.0 NEOPL MALIG SECUND DO RIM E DA PELVE RENAL',
  'C79.1 NEOPL MALIG SECUND BEXIGA OUTR ORG URIN E NE',
  'C79.2 NEOPL MALIG SECUND DA PELE',
  'C79.3 NEOPL MALIG SECUND ENCEFALO E MENING CEREBR',
  'C79.4 NEOPL MALIG SECUND OUTR PART SIST NERV E NE',
  'C79.5 NEOPL MALIG SECUND OSSOS E MEDULA OSSEA',
  'C79.6 NEOPL MALIG SECUND DO OVARIO',
  'C79.7 NEOPL MALIG SECUND DAS GLAND SUPRA-RENAIS',
  'C79.8 NEOPL MALIG SECUND DE OUTR LOCALIZ ESPEC',
  'C80   NEOPL MALIG S/ESPECIFICACAO DE LOCALIZ',
  'C81.0 PREDOM LINFOCITICA',
  'C81.1 ESCLEROSE NODULAR',
  'C81.2 CELULARIDADE MISTA',
  'C81.3 DEPLECAO LINFOCITICA',
  'C81.7 OUTR FORM DA DOENC DE HODGKIN',
  'C81.9 DOENC DE HODGKIN NE',
  'C82.0 PEQUENAS CELULAS CLIVADAS FOLICULAR',
  'C82.1 MISTO PEQ/GRANDES CELULAS CLIVADAS FOLICULAR',
  'C82.2 GRANDES CELULAS FOLICULAR',
  'C82.7 OUTR TIPOS DE LINFOMA NAO-HODGKIN FOLICULAR',
  'C82.9 LINFOMA NAO-HODGKIN FOLICULAR NE',
  'C83.0 PEQUENAS CELULAS',
  'C83.1 PEQUENAS CELULAS CLIVADAS',
  'C83.2 MISTO DE PEQUENAS E GRANDES CELULAS',
  'C83.3 GRANDES CELULAS',
  'C83.4 IMUNOBLASTICO',
  'C83.5 LINFOBLASTICO',
  'C83.6 INDIFERENCIADO',
  'C83.7 TUMOR DE BURKITT',
  'C83.8 OUTR TIPOS DE LINFOMA NAO-HODGKIN DIFUSO',
  'C83.9 LINFOMA NAO-HODGKIN DIFUSO NE',
  'C84.0 MICOSE FUNGOIDE',
  'C84.1 DOENC DE SEZARY',
  'C84.2 LINFOMA DA ZONA T',
  'C84.3 LINFOMA LINFOEPITELIOIDE',
  'C84.4 LINFOMA DE CELULAS T PERIFERICO',
  'C84.5 OUTR LINFOMAS DE CELULAS T E OS NE',
  'C85.0 LINFOSSARCOMA',
  'C85.1 LINFOMA DE CELULAS B NE',
  'C85.7 OUTR TIPOS ESPEC DE LINFOMA NAO-HODGKIN',
  'C85.9 LINFOMA NAO-HODGKIN DE TIPO NE',
  'C88.0 MACROGLOBULINEMIA DE WALDENSTROM',
  'C88.1 DOENC DE CADEIA PESADA ALFA',
  'C88.2 DOENC DE CADEIA PESADA GAMA',
  'C88.3 DOENC IMUNOPROLIFERATIVA INTESTINO DELGADO',
  'C88.7 OUTR DOENC IMUNOPROLIFERATIVAS MALIGNAS',
  'C88.9 DOENC IMUNOPROLIFERATIVA MALIGNA NE',
  'C90.0 MIELOMA MULT',
  'C90.1 LEUCEMIA PLASMOCITARIA',
  'C90.2 PLASMOCITOMA EXTRAMEDULAR',
  'C91.0 LEUCEMIA LINFOBLASTICA AGUDA',
  'C91.1 LEUCEMIA LINFOCITICA CRONICA',
  'C91.2 LEUCEMIA LINFOCITICA SUBAGUDA',
  'C91.3 LEUCEMIA PRO-LINFOCITICA',
  'C91.4 LEUCEMIA DE CELULAS PILOSAS',
  'C91.5 LEUCEMIA DE CELULAS T DO ADULTO',
  'C91.7 OUTR LEUCEMIAS LINFOIDES',
  'C91.9 LEUCEMIA LINFOIDE NE',
  'C92.0 LEUCEMIA MIELOIDE AGUDA',
  'C92.1 LEUCEMIA MIELOIDE CRONICA',
  'C92.2 LEUCEMIA MIELOIDE SUBAGUDA',
  'C92.3 SARCOMA MIELOIDE',
  'C92.4 LEUCEMIA PRO-MIELOCITICA AGUDA',
  'C92.5 LEUCEMIA MIELOMONOCITICA AGUDA',
  'C92.7 OUTR LEUCEMIAS MIELOIDES',
  'C92.9 LEUCEMIA MIELOIDE NE',
  'C93.0 LEUCEMIA MONOCITICA AGUDA',
  'C93.1 LEUCEMIA MONOCITICA CRONICA',
  'C93.2 LEUCEMIA MONOCITICA SUBAGUDA',
  'C93.7 OUTR LEUCEMIAS MONOCITICAS',
  'C93.9 LEUCEMIA MONOCITICA NE',
  'C94.0 ERITREMIA E ERITROLEUCEMIA AGUDAS',
  'C94.1 ERITREMIA CRONICA',
  'C94.2 LEUCEMIA MEGACARIOBLASTICA AGUDA',
  'C94.3 LEUCEMIA DE MASTOCITOS',
  'C94.4 PANMIELOSE AGUDA',
  'C94.5 MIELOFIBROSE AGUDA',
  'C94.7 OUTR LEUCEMIAS ESPEC',
  'C95.0 LEUCEMIA AGUDA DE TIPO CELULAR NE',
  'C95.1 LEUCEMIA CRONICA DE TIPO CELULAR NE',
  'C95.2 LEUCEMIA SUBAGUDA DE TIPO CELULAR NE',
  'C95.7 OUTR LEUCEMIAS DE TIPO CELULAR NE',
  'C95.9 LEUCEMIA NE',
  'C96.0 DOENC DE LETTERER-SIWE',
  'C96.1 HISTIOCITOSE MALIGNA',
  'C96.2 TUMOR MALIGNO DE MASTOCITOS',
  'C96.3 LINFOMA HISTIOCITICO VERDADEIRO',
  'C96.7 OUTR NEOP MAL ESPEC TEC LINF HEMATOP E CORR',
  'C96.9 NEOP MAL TEC LINF HEMATOP E CORR NE',
  'C97   NEOPL MALIG DE LOCALIZ MULT INDEPENDENTES',
  'D00.0 LABIOS CAVIDADE ORAL E FARINGE',
  'D00.1 ESOFAGO',
  'D00.2 ESTOMAGO',
  'D01.0 COLON',
  'D01.1 JUNCAO RETOSSIGMOIDE',
  'D01.2 RETO',
  'D01.3 ANUS E CANAL ANAL',
  'D01.4 OUTR PARTES DO INTESTINO E AS NE',
  'D01.5 FIGADO VESICULA BILIAR E VIAS BILIARES',
  'D01.7 OUTR ORGAOS ESPEC DO APARELHO DIGESTIVO',
  'D01.9 ORGAOS DIGESTIVOS NE',
  'D02.0 LARINGE',
  'D02.1 TRAQUEIA',
  'D02.2 BRONQUIOS E PULMOES',
  'D02.3 OUTR PARTES DO APARELHO RESPIRAT',
  'D02.9 APARELHO RESPIRAT NE',
  'D03.0 MELANOMA IN SITU DO LABIO',
  'D03.1 MELANOMA IN SITU DA PALPEBRA INCL O CANTO',
  'D03.2 MELANOMA IN SITU ORELHA E CONDUTO AUDIT EXT',
  'D03.3 MELANOMA IN SITU OUTR PARTES E NE DA FACE',
  'D03.4 MELANOMA IN SITU COURO CABELUDO E PESCOCO',
  'D03.5 MELANOMA IN SITU DO TRONCO',
  'D03.6 MELANOMA IN SITU MEMBROS SUPER INCL OMBRO',
  'D03.7 MELANOMA IN SITU MEMBROS INFER INCL QUADRIL',
  'D03.8 MELANOMA IN SITU DE OUTR LOCALIZ',
  'D03.9 MELANOMA IN SITU NE',
  'D04.0 PELE DO LABIO',
  'D04.1 PELE DA PALPEBRA INCL O CANTO',
  'D04.2 PELE DA ORELHA E DO CONDUTO AUDITIVO EXTERNO',
  'D04.3 PELE DE OUTR PARTES E DE PARTES NE DA FACE',
  'D04.4 PELE DO COURO CABELUDO E DO PESCOCO',
  'D04.5 PELE DO TRONCO',
  'D04.6 PELE DOS MEMBROS SUPER INCL OMBRO',
  'D04.7 PELE DOS MEMBROS INFER INCL QUADRIL',
  'D04.8 PELE DE OUTR LOCALIZ',
  'D04.9 PELE NE',
  'D05.0 CARCINOMA LOBULAR IN SITU',
  'D05.1 CARCINOMA INTRADUCTAL IN SITU',
  'D05.7 OUTR CARCINOMAS IN SITU',
  'D05.9 CARCINOMA IN SITU DA MAMA NE',
  'D06.0 ENDOCERVIX',
  'D06.1 EXOCERVIX',
  'D06.7 OUTR PARTES DO COLO DO UTERO',
  'D06.9 COLO DO UTERO NE',
  'D07.0 ENDOMETRIO',
  'D07.1 VULVA',
  'D07.2 VAGINA',
  'D07.3 OUTR ORGAOS GENITAIS FEMIN E OS NE',
  'D07.4 PENIS',
  'D07.5 PROSTATA',
  'D07.6 OUTR ORGAOS GENITAIS MASC E OS NE',
  'D09.0 BEXIGA',
  'D09.1 OUTR ORGAOS URINARIOS E OS NE',
  'D09.2 OLHO',
  'D09.3 TIREOIDE E DE OUTR GLAND ENDOCRINAS',
  'D09.7 CARCINOMA IN SITU DE OUTR LOCALIZ ESPEC',
  'D09.9 CARCINOMA IN SITU NE',
  'D10.0 LABIOS',
  'D10.1 LINGUA',
  'D10.2 ASSOALHO DA BOCA',
  'D10.3 OUTR PARTES DA BOCA E AS NE',
  'D10.4 AMIGDALA',
  'D10.5 OUTR PARTES DA OROFARINGE',
  'D10.6 NASOFARINGE',
  'D10.7 HIPOFARINGE',
  'D10.9 FARINGE NE',
  'D11.0 GLAND PAROTIDA',
  'D11.7 OUTR GLAND SALIVARES MAIORES',
  'D11.9 GLAND SALIVAR MAIOR NE',
  'D12.0 CECO',
  'D12.1 APENDICE',
  'D12.2 COLON ASCENDENTE',
  'D12.3 COLON TRANSVERSO',
  'D12.4 COLON DESCENDENTE',
  'D12.5 COLON SIGMOIDE',
  'D12.6 COLON NE',
  'D12.7 JUNCAO RETOSSIGMOIDE',
  'D12.8 RETO',
  'D12.9 CANAL ANAL E ANUS',
  'D13.0 ESOFAGO',
  'D13.1 ESTOMAGO',
  'D13.2 DUODENO',
  'D13.3 OUTR PARTES E PARTES NE DO INTESTINO DELGADO',
  'D13.4 FIGADO',
  'D13.5 VIAS BILIARES EXTRA-HEPATICAS',
  'D13.6 PANCREAS',
  'D13.7 PANCREAS ENDOCRINO',
  'D13.9 LOCALIZ MAL DEFINIDAS DO APARELHO DIGESTIVO',
  'D14.0 OUVIDO MEDIO CAV NASAL E SEIOS PARANASAIS',
  'D14.1 LARINGE',
  'D14.2 TRAQUEIA',
  'D14.3 BRONQUIOS E PULMAO',
  'D14.4 APARELHO RESPIRAT NE',
  'D15.0 TIMO',
  'D15.1 CORACAO',
  'D15.2 MEDIASTINO',
  'D15.7 OUTR ORGAOS INTRATORACICOS ESPEC',
  'D15.9 ORGAO INTRATORACICO NE',
  'D16.0 OMOPLATA E OSSOS LONGOS DOS MEMBROS SUPER',
  'D16.1 OSSOS CURTOS DOS MEMBROS SUPER',
  'D16.2 OSSOS LONGOS DOS MEMBROS INFER',
  'D16.3 OSSOS CURTOS DOS MEMBROS INFER',
  'D16.4 OSSOS DO CRANIO E DA FACE',
  'D16.5 OSSO DA MANDIBULA',
  'D16.6 COLUNA VERTEBRAL',
  'D16.7 COSTELAS DO ESTERNO E DA CLAVICULA',
  'D16.8 OSSOS PELVICOS SACRO E COCCIX',
  'D16.9 OSSO E CARTILAGEM ARTICULAR NE',
  'D17.0 NEOP LIPOM BEN PELE TEC SUBCUT CAB FACE PESC',
  'D17.1 NEOPL LIPOMATOSA BENIG PELE TEC SUBCUT TRONC',
  'D17.2 NEOPL LIPOMATOSA BENIG PELE TEC SUBCUT MEMBR',
  'D17.3 NEOPL LIP BENIG PELE TEC SUBC OUTR LOC E NE',
  'D17.4 NEOPL LIPOMATOSA BENIG ORGAOS INTRATORACICOS',
  'D17.5 NEOPL LIPOMATOSA BENIG ORGAOS INTRA-ABDOMIN',
  'D17.6 NEOPL LIPOMATOSA BENIG DO CORDAO ESPERMATICO',
  'D17.7 NEOPLASIA LIPOMATOSA BENIGNA DE OUTR LOCALIZ',
  'D17.9 NEOPLASIA LIPOMATOSA BENIGNA DE LOCALIZ NE',
  'D18.0 HEMANGIOMA DE QUALQUER LOCALIZ',
  'D18.1 LINFANGIOMA DE QUALQUER LOCALIZ',
  'D19.0 TEC MESOTELIAL DA PLEURA',
  'D19.1 TEC MESOTELIAL DO PERITONIO',
  'D19.7 TEC MESOTELIAL DE OUTR LOCALIZ',
  'D19.9 TEC MESOTELIAL NE',
  'D20.0 RETROPERITONIO',
  'D20.1 PERITONIO',
  'D21.0 TEC CONJ E OUTR TEC MOLES CABECA FACE E PESC',
  'D21.1 TEC CONJ E OUT TEC MOLES MEMBR SUP INCL OMBR',
  'D21.2 TEC CONJ E OUT TEC MOLES MEMBR INF INCL QUAD',
  'D21.3 TEC CONJUNTIVO E OUTR TEC MOLES DO TORAX',
  'D21.4 TEC CONJUNTIVO E OUTR TEC MOLES DO ABDOME',
  'D21.5 TEC CONJUNTIVO E OUTR TEC MOLES DA PELVE',
  'D21.6 TEC CONJUNTIVO E OUTR TEC MOLES DO TRONCO NE',
  'D21.9 TEC CONJUNTIVO E OUTR TEC MOLES S/OUTR ESPEC',
  'D22.0 NEVO MELANOCITICO DO LABIO',
  'D22.1 NEVO MELANOCITICO DA PALPEBRA INCL O CANTO',
  'D22.2 NEVO MELANOCITICO ORELHA E COND AUDITIVO EXT',
  'D22.3 NEVO MELANOCITICO OUTR PARTES E NE DA FACE',
  'D22.4 NEVO MELANOCITICO COURO CABELUDO E PESCOCO',
  'D22.5 NEVO MELANOCITICO DO TRONCO',
  'D22.6 NEVO MELANOCITICO MEMBROS SUPER INCL OMBRO',
  'D22.7 NEVO MELANOCITICO MEMBROS INFER INCL QUADRIL',
  'D22.9 NEVO MELANOCITICO NE',
  'D23.0 PELE DOS LABIOS',
  'D23.1 PELE DA PALPEBRA INCL O CANTO',
  'D23.2 PELE DA ORELHA E DO CONDUTO AUDITIVO EXTERNO',
  'D23.3 PELE DE OUTR PARTES E DE PARTES NE DA FACE',
  'D23.4 PELE DO COURO CABELUDO E DO PESCOCO',
  'D23.5 PELE DO TRONCO',
  'D23.6 PELE DOS MEMBROS SUPER INCL O OMBRO',
  'D23.7 PELE DOS MEMBROS INFER INCL O QUADRIL',
  'D23.9 PELE NE',
  'D24   NEOPL BENIG DA MAMA',
  'D25.0 LEIOMIOMA SUBMUCOSO DO UTERO',
  'D25.1 LEIOMIOMA INTRAMURAL DO UTERO',
  'D25.2 LEIOMIOMA SUBSEROSO DO UTERO',
  'D25.9 LEIOMIOMA DO UTERO NE',
  'D26.0 COLO DO UTERO',
  'D26.1 CORPO DO UTERO',
  'D26.7 OUTR PARTES DO UTERO',
  'D26.9 UTERO NE',
  'D27   NEOPL BENIG DO OVARIO',
  'D28.0 VULVA',
  'D28.1 VAGINA',
  'D28.2 TROMPAS E LIGAMENTOS UTERINOS',
  'D28.7 OUTR ORGAOS GENITAIS FEMIN ESPEC',
  'D28.9 ORGAO GENITAL FEMIN NE',
  'D29.0 PENIS',
  'D29.1 PROSTATA',
  'D29.2 TESTICULOS',
  'D29.3 EPIDIDIMO',
  'D29.4 ESCROTO',
  'D29.7 OUTR ORGAOS GENITAIS MASC',
  'D29.9 ORGAO GENITAL MASC NE',
  'D30.0 RIM',
  'D30.1 PELVE RENAL',
  'D30.2 URETER',
  'D30.3 BEXIGA',
  'D30.4 URETRA',
  'D30.7 OUTR ORGAOS URINARIOS',
  'D30.9 ORGAO URINARIO NE',
  'D31.0 CONJUNTIVA',
  'D31.1 CORNEA',
  'D31.2 RETINA',
  'D31.3 COROIDE',
  'D31.4 CORPO CILIAR',
  'D31.5 GLAND E DOS CANAIS LACRIMAIS',
  'D31.6 ORBITA NE',
  'D31.9 OLHO NE',
  'D32.0 MENINGES CEREBRAIS',
  'D32.1 MENINGES ESPINHAIS',
  'D32.9 MENINGES NE',
  'D33.0 ENCEFALO SUPRATENTORIAL',
  'D33.1 ENCEFALO INFRATENTORIAL',
  'D33.2 ENCEFALO NE',
  'D33.3 NERVOS CRANIANOS',
  'D33.4 MEDULA ESPINHAL',
  'D33.7 OUTR PARTES ESPEC DO SIST NERVOSO CENTRAL',
  'D33.9 SIST NERVOSO CENTRAL NE',
  'D34   NEOPL BENIG DA GLAND TIREOIDE',
  'D35.0 GLAND SUPRA-RENAL',
  'D35.1 GLAND PARATIREOIDE',
  'D35.2 GLAND HIPOFISE',
  'D35.3 CONDUTO CRANIOFARINGEO',
  'D35.4 GLAND PINEAL',
  'D35.5 CORPO CAROTIDEO',
  'D35.6 CORPOS AORTICOS E OUTR PARAGANGLIOS',
  'D35.7 OUTR GLAND ENDOCRINAS ESPEC',
  'D35.8 COMPR PLURIGLANDULAR',
  'D35.9 GLAND ENDOCRINA NE',
  'D36.0 GANGLIOS LINFATICOS',
  'D36.1 NERVOS PERIFERICOS E SIST NERVOSO AUTONOMO',
  'D36.7 OUTR LOCALIZ ESPEC',
  'D36.9 NEOPL BENIG DE LOCALIZ NE',
  'D37.0 LABIO CAVIDADE ORAL E FARINGE',
  'D37.1 ESTOMAGO',
  'D37.2 INTESTINO DELGADO',
  'D37.3 APENDICE',
  'D37.4 COLONS',
  'D37.5 RETO',
  'D37.6 FIGADO VESICULA BILIAR E VIAS BILIARES',
  'D37.7 OUTR ORGAOS DIGESTIVOS',
  'D37.9 ORGAO DIGESTIVO NE',
  'D38.0 LARINGE',
  'D38.1 TRAQUEIA BRONQUIOS E PULMAO',
  'D38.2 PLEURA',
  'D38.3 MEDIASTINO',
  'D38.4 TIMO',
  'D38.5 OUTR ORGAOS RESPIRAT',
  'D38.6 ORGAO RESPIRAT NE',
  'D39.0 UTERO',
  'D39.1 OVARIO',
  'D39.2 PLACENTA',
  'D39.7 OUTR ORGAOS GENITAIS FEMIN',
  'D39.9 ORGAO GENITAL FEMIN NE',
  'D40.0 PROSTATA',
  'D40.1 TESTICULO',
  'D40.7 OUTR ORGAOS GENITAIS MASC',
  'D40.9 ORGAO GENITAL MASC NE',
  'D41.0 RIM',
  'D41.1 PELVE RENAL',
  'D41.2 URETER',
  'D41.3 URETRA',
  'D41.4 BEXIGA',
  'D41.7 OUTR ORGAOS URINARIOS',
  'D41.9 ORGAO URINARIO NE',
  'D42.0 MENINGES CEREBRAIS',
  'D42.1 MENINGES ESPINHAIS',
  'D42.9 MENINGES NE',
  'D43.0 ENCEFALO SUPRATENTORIAL',
  'D43.1 ENCEFALO INFRATENTORIAL',
  'D43.2 ENCEFALO NE',
  'D43.3 NERVOS CRANIANOS',
  'D43.4 MEDULA ESPINHAL',
  'D43.7 OUTR PARTES DO SIST NERVOSO CENTRAL',
  'D43.9 SIST NERVOSO CENTRAL NE',
  'D44.0 GLAND TIREOIDE',
  'D44.1 GLAND SUPRA-RENAL',
  'D44.2 GLAND PARATIREOIDE',
  'D44.3 GLAND HIPOFISE',
  'D44.4 CONDUTO CRANIOFARINGEO',
  'D44.5 GLAND PINEAL',
  'D44.6 CORPO CAROTIDEO',
  'D44.7 CORPOS AORTICOS E OUTR PARAGANGLIOS',
  'D44.8 COMPR PLURIGLANDULAR',
  'D44.9 GLAND ENDOCRINA NE',
  'D45   POLICITEMIA VERA',
  'D46.0 ANEMIA REFRATARIA S/SIDEROBLASTOS',
  'D46.1 ANEMIA REFRATARIA C/SIDEROBLASTOS',
  'D46.2 ANEMIA REFRATARIA C/EXCESSO DE BLASTOS',
  'D46.3 ANEMIA REFRATARIA C/EXCESSO BLASTOS C/TRANSF',
  'D46.4 ANEMIA REFRATARIA NE',
  'D46.7 OUTR SINDR MIELODISPLASICAS',
  'D46.9 SINDR MIELODISPLASICA NE',
  'D47.0 TUM COMP INC/DESC MASTOCIT CELULAS HISTIOCIT',
  'D47.1 DOENC MIELOPROLIFERATIVA CRONICA',
  'D47.2 GAMOPATIA MONOCLONAL',
  'D47.3 TROMBOCITEMIA ESSENCIAL',
  'D47.7 OUT NEOP ESP COMP INC/DES LINF HEMAT TEC REL',
  'D47.9 NEOP COMP INC/DES TEC LINF HEMAT TEC CORR NE',
  'D48.0 OSSOS E CARTILAGENS ARTICULARES',
  'D48.1 TEC CONJUNTIVO E OUTR TEC MOLES',
  'D48.2 NERVOS PERIFERICOS E SIST NERVOSO PERIFERICO',
  'D48.3 RETROPERITONIO',
  'D48.4 PERITONIO',
  'D48.5 PELE',
  'D48.6 MAMA',
  'D48.7 OUTR LOCALIZ ESPEC',
  'D48.9 NEOPL COMP INCERTO/DESCONHECIDO S/OUTR ESPEC',
  'D50.0 ANEMIA P/DEFIC FERRO SECUND PERDA DE SANGUE',
  'D50.1 DISFAGIA SIDEROPENICA',
  'D50.8 OUTR ANEMIAS P/DEFIC DE FERRO',
  'D50.9 ANEMIA P/DEFIC DE FERRO NE',
  'D51.0 ANEMIA P/DEFIC VIT B12 DEV DEFIC FAT INTRINS',
  'D51.1 ANEMIA DEF VIT B12 MA-ABS SEL VIT B12 C/PROT',
  'D51.2 DEFIC DE TRANSCOBALAMINA II',
  'D51.3 OUTR ANEMIAS P/DEFIC VITAMINA B12 NA DIETA',
  'D51.8 OUTR ANEMIAS P/DEFIC DE VITAMINA B12',
  'D51.9 ANEMIA P/DEFIC DE VITAMINA B12 NE',
  'D52.0 ANEMIA P/DEFIC DE FOLATO NA DIETA',
  'D52.1 ANEMIA P/DEFIC DE FOLATO INDUZ P/DROGAS',
  'D52.8 OUTR ANEMIAS P/DEFIC DE FOLATO',
  'D52.9 ANEMIA P/DEFIC DE FOLATO NE',
  'D53.0 ANEMIA P/DEFIC DE PROTEINAS',
  'D53.1 OUTR ANEMIAS MEGALOBLASTICAS NCOP',
  'D53.2 ANEMIA ESCORBUTICA',
  'D53.8 OUTR ANEMIAS NUTRICIONAIS ESPEC',
  'D53.9 ANEMIA NUTRICIONAL NE',
  'D55.0 ANEMIA DEV DEFIC GLICOSE-6-FOSFATO-DESIDROG',
  'D55.1 ANEMIA DEV OUTR TRANST METABOLISMO GLUTATION',
  'D55.2 ANEMIA DEV TRANST DAS ENZIMAS GLICOLITICAS',
  'D55.3 ANEMIA DEV TRANST METABOLISMO NUCLEOTIDIOS',
  'D55.8 OUTR ANEMIAS DEV TRANST ENZIMATICOS',
  'D55.9 ANEMIA DEV TRANST ENZIMATICO NE',
  'D56.0 TALASSEMIA ALFA',
  'D56.1 TALASSEMIA BETA',
  'D56.2 TALASSEMIA DELTA-BETA',
  'D56.3 ESTIGMA TALASSEMICO',
  'D56.4 PERSISTENCIA HEREDITARIA HEMOGLOBINA FETAL',
  'D56.8 OUTR TALASSEMIAS',
  'D56.9 TALASSEMIA NE',
  'D57.0 ANEMIA FALCIFORME C/CRISE',
  'D57.1 ANEMIA FALCIFORME S/CRISE',
  'D57.2 TRANST FALCIFORMES HETEROZIGOTICOS DUPLOS',
  'D57.3 ESTIGMA FALCIFORME',
  'D57.8 OUTR TRANST FALCIFORMES',
  'D58.0 ESFEROCITOSE HEREDITARIA',
  'D58.1 ELIPTOCITOSE HEREDITARIA',
  'D58.2 OUTR HEMOGLOBINOPATIAS',
  'D58.8 OUTR ANEMIAS HEMOLITICAS HEREDITARIAS ESPEC',
  'D58.9 ANEMIA HEMOLITICA HEREDITARIA NE',
  'D59.0 ANEMIA HEMOLITICA AUTO-IMUNE INDUZ P/DROGA',
  'D59.1 OUTR ANEMIAS HEMOLITICAS AUTO-IMUNES',
  'D59.2 ANEMIA HEMOLITIC N-AUTO-IMUNE INDUZ P/DROGAS',
  'D59.3 SINDR HEMOLITICO-UREMICA',
  'D59.4 OUTR ANEMIAS HEMOLITICAS NAO-AUTOIMUNES',
  'D59.5 HEMOGLOBINURIA PAROXISTICA NOTURNA',
  'D59.6 HEMOGLOBINURIA DEV HEMOLISE P/OUTR CAUS EXT',
  'D59.8 OUTR ANEMIAS HEMOLITICAS ADQUIR',
  'D59.9 ANEMIA HEMOLITICA ADQUIR NE',
  'D60.0 APLASIA PURA ADQ CRONICA SERIE VERMELHA',
  'D60.1 APLASIA PURA ADQ TRANSITORIA SERIE VERMELHA',
  'D60.8 OUTR APLASIAS PURAS ADQ SERIE VERMELHA',
  'D60.9 APLASIA PURA ADQUIR NE DA SERIE VERMELHA',
  'D61.0 ANEMIA APLASTICA CONSTITUCIONAL',
  'D61.1 ANEMIA APLASTICA INDUZ P/DROGAS',
  'D61.2 ANEMIA APLASTICA DEV OUTR AGENTES EXTERNOS',
  'D61.3 ANEMIA APLASTICA IDIOPATICA',
  'D61.8 OUTR ANEMIAS APLASTICAS ESPEC',
  'D61.9 ANEMIA APLASTICA NE',
  'D62   ANEMIA AGUDA POS-HEMORRAGICA',
  'D63.0 ANEMIA EM NEOPLASIAS',
  'D63.8 ANEMIA EM OUTR DOENC COP',
  'D64.0 ANEMIA SIDEROBLASTICA HEREDITARIA',
  'D64.1 ANEMIA SIDEROBLASTICA SECUND A DOENC',
  'D64.2 ANEMIA SIDEROBLASTIC SECUND USO DROGAS TOXIN',
  'D64.3 OUTR ANEMIAS SIDEROBLASTICAS',
  'D64.4 ANEMIA DISERITROPOETICA CONGEN',
  'D64.8 OUTR ANEMIAS ESPEC',
  'D64.9 ANEMIA NE',
  'D65   COAGULACAO INTRAVASCULAR DISSEMINADA',
  'D66   DEFIC HEREDITARIA DO FATOR VIII',
  'D67   DEFIC HEREDITARIA DO FATOR IX',
  'D68.0 DOENC DE VON WILLEBRAND',
  'D68.1 DEFIC HEREDITARIA DE FATOR XI',
  'D68.2 DEFIC HEREDITARIA OUTR FATORES DE COAGULACAO',
  'D68.3 TRANST HEMORRAGICO DEV ANTICOAG CIRCULANTES',
  'D68.4 DEFIC ADQUIR DE FATOR DE COAGULACAO',
  'D68.8 OUTR DEFEITOS ESPEC DA COAGULACAO',
  'D68.9 DEFEITO DE COAGULACAO NE',
  'D69.0 PURPURA ALERGICA',
  'D69.1 DEFEITOS QUALITATIVOS DAS PLAQUETAS',
  'D69.2 OUTR PURPURAS NAO-TROMBOCITOPENICAS',
  'D69.3 PURPURA TROMBOCITOPENICA IDIOPATICA',
  'D69.4 OUTR TROMBOCITOPENIA PRIM',
  'D69.5 TROMBOCITOPENIA SECUND',
  'D69.6 TROMBOCITOPENIA NE',
  'D69.8 OUTR AFECCOES HEMORRAGICAS ESPEC',
  'D69.9 AFECCAO HEMORRAGICA NE',
  'D70   AGRANULOCITOSE',
  'D71   TRANST FUNC NEUTROFILOS POLIMORFONUCLEARES',
  'D72.0 ANOMALIAS GENETICAS DOS LEUCOCITOS',
  'D72.1 EOSINOFILIA',
  'D72.8 OUTR TRANST ESPEC DOS GLOBULOS BRANCOS',
  'D72.9 TRANST NE DOS GLOBULOS BRANCOS',
  'D73.0 HIPOESPLENISMO',
  'D73.1 HIPERESPLENISMO',
  'D73.2 ESPLENOMEGALIA CONGESTIVA CRONICA',
  'D73.3 ABSCESSO DO BACO',
  'D73.4 CISTO DO BACO',
  'D73.5 INFARTO DO BACO',
  'D73.8 OUTR DOENC DO BACO',
  'D73.9 DOENC NE DO BACO',
  'D74.0 METEMOGLOBINEMIA CONGEN',
  'D74.8 OUTR METEMOGLOBINEMIAS',
  'D74.9 METEMOGLOBINEMIA NE',
  'D75.0 ERITROCITOSE FAMILIAR',
  'D75.1 POLICITEMIA SECUND',
  'D75.2 TROMBOCITOSE ESSENCIAL',
  'D75.8 OUTR DOENC ESPEC SANGUE E ORG HEMATOPOETICOS',
  'D75.9 DOENC NE DO SANGUE E ORGAOS HEMATOPOETICOS',
  'D76.0 HISTIOCITOSE DAS CELULAS DE LANGERHANS NCOP',
  'D76.1 LINFOHISTIOCITOSE HEMOFAGOCITICA',
  'D76.2 SINDR HEMATOFAGOCITICA ASSOC A INFECC',
  'D76.3 OUTR SINDR HISTIOCITICAS',
  'D77   OUTR TRANST SANGUE E ORG HEMATOP DOENC COP',
  'D80.0 HIPOGAMAGLOBULINEMIA HEREDITARIA',
  'D80.1 HIPOGAMAGLOBULINEMIA NAO FAMILIAR',
  'D80.2 DEFIC SELETIVA DE IMUNOGLOBULINA A',
  'D80.3 DEFIC SELETIVA SUBCLASSES IMUNOGLOBULINA G',
  'D80.4 DEFIC SELETIVA DE IMUNOGLOBULINA M',
  'D80.5 IMUNODEFICIENCIA C/AUMENTO IMUNOGLOBULINA M',
  'D80.6 DEF ANTICORP C/IMUNOG PROX NORM OU C/HIPERIM',
  'D80.7 HIPOGAMAGLOBULINEMIA TRANSITORIA DA INFANCIA',
  'D80.8 OUTR IMUNODEF C/PREDOM DEFEITOS ANTICORPOS',
  'D80.9 IMUNODEF NE C/PREDOM DEFEITOS ANTICORPOS',
  'D81.0 IMUNODEF COMB GRAVE C/DISGENESIA RETICULAR',
  'D81.1 IMUNODEF COMB GRAVE C/NUM BAIXOS CELULAS T B',
  'D81.2 IMUNODEF COMB GRAVE C/NUM BAIX/NORM CELUL B',
  'D81.3 DEFIC DE ADENOSINA-DEAMINASE',
  'D81.4 SINDR DE NEZELOF',
  'D81.5 DEFIC DE PURINA-NUCLEOSIDEO FOSFORILASE',
  'D81.6 DEFIC MAJOR CLASSE I COMPLEXO HISTOCOMPATIB',
  'D81.7 DEFIC MAJOR CLASSE II COMPLEXO HISTOCOMPATIB',
  'D81.8 OUTR DEFIC IMUNITARIAS COMBINADAS',
  'D81.9 DEFIC IMUNITARIAS COMBINADAS NE',
  'D82.0 SINDR DE WISKOTT-ALDRICH',
  'D82.1 SINDR DE DI GEORGE',
  'D82.2 IMUNODEFICIENCIA C/ENCURTAMENTO DE MEMBROS',
  'D82.3 IMUNODEF Q SEG RESP HERED DEF VIRUS E.-BARR',
  'D82.4 SINDR DA HIPERIMUNOGLOBULINA E',
  'D82.8 IMUNODEF ASSOC C/OUTR DEFEITOS MAJOR ESPEC',
  'D82.9 IMUNODEFICIENCIA ASSOC C/DEFEITOS MAJOR NE',
  'D83.0 IMUNODEF COM VAR PREDOM ANORM NUM FUNC CEL B',
  'D83.1 IMUNODEF COM VAR PRED TRANST IMUNORREG CEL T',
  'D83.2 IMUNODEF COMUM VAR C/AUTO-ANTICORPOS CEL B/T',
  'D83.8 OUTR IMUNODEFICIENCIAS COMUNS VARIAVEIS',
  'D83.9 IMUNODEFICIENCIA COMUM VARIAVEL NE',
  'D84.0 DEFEITO DO ANTIGENO-1 DA FUNCAO DE LINFOCITO',
  'D84.1 DEFEITOS NO SIST COMPLEMENTO',
  'D84.8 OUTR IMUNODEFICIENCIAS ESPEC',
  'D84.9 IMUNODEFICIENCIA NE',
  'D86.0 SARCOIDOSE DO PULMAO',
  'D86.1 SARCOIDOSE DOS GANGLIOS LINFATICOS',
  'D86.2 SARCOIDOSE PULMAO C/SARCOIDOSE GANGL LINFAT',
  'D86.3 SARCOIDOSE DA PELE',
  'D86.8 SARCOIDOSE OUTR LOCAIS ESPEC E COMBINADOS',
  'D86.9 SARCOIDOSE NE',
  'D89.0 HIPERGAMAGLOBULINEMIA POLICLONAL',
  'D89.1 CRIOGLOBULINEMIA',
  'D89.2 HIPERGAMAGLOBULINEMIA NE',
  'D89.8 OUTR TRANST ESPEC Q COMPROM MEC IMUNIT NCOP',
  'D89.9 TRANST NE Q COMPROMETEM MECANISMO IMUNITARIO',
  'E00.0 SINDR DEFIC CONGEN IODO TIPO NEUROLOGICO',
  'E00.1 SINDR DEFIC CONGEN IODO TIPO MIXEDEMATOSO',
  'E00.2 SINDR DEFIC CONGEN IODO TIPO MISTO',
  'E00.9 SINDR DA DEFIC CONGEN DE IODO NE',
  'E01.0 BOCIO DIFUSO P/DEFIC DE IODO',
  'E01.1 BOCIO MULTINODULAR P/DEFIC DE IODO',
  'E01.2 BOCIO NE P/DEFIC DE IODO',
  'E01.8 OUTR TRANST TIREOID AFECC ASSOC REL DEF IODO',
  'E02   HIPOTIREOIDISMO SUBCLINICO P/DEFIC DE IODO',
  'E03.0 HIPOTIREOIDISMO CONGEN C/BOCIO DIFUSO',
  'E03.1 HIPOTIREOIDISMO CONGEN S/BOCIO',
  'E03.2 HIPOTIREOIDISMO DEV MEDIC OUTR SUBST EXOGEN',
  'E03.3 HIPOTIREOIDISMO POS-INFECC',
  'E03.4 ATROFIA DA TIREOIDE',
  'E03.5 COMA MIXEDEMATOSO',
  'E03.8 OUTR HIPOTIREOIDISMOS ESPEC',
  'E03.9 HIPOTIREOIDISMO NE',
  'E04.0 BOCIO NAO-TOXICO DIFUSO',
  'E04.1 BOCIO NAO-TOXICO UNINODULAR',
  'E04.2 BOCIO NAO-TOXICO MULTINODULAR',
  'E04.8 OUTR BOCIO NAO-TOXICO ESPEC',
  'E04.9 BOCIO NAO-TOXICO NE',
  'E05.0 TIREOTOXICOSE C/BOCIO DIFUSO',
  'E05.1 TIREOTOXICOSE C/BOCIO TOXICO UNINODULAR',
  'E05.2 TIREOTOXICOSE C/BOCIO TOXICO MULTINODULAR',
  'E05.3 TIREOTOXICOSE CAUS P/TEC TIREOID ECTOPICO',
  'E05.4 TIREOTOXICOSE FACTICIA',
  'E05.5 CRISE OU TEMPESTADE TIREOTOXICA',
  'E05.8 OUTR TIREOTOXICOSES',
  'E05.9 TIREOTOXICOSE NE',
  'E06.0 TIREOIDITE AGUDA',
  'E06.1 TIREOIDITE SUBAGUDA',
  'E06.2 TIREOIDITE CRONICA C/TIREOTOXICOSE TRANSIT',
  'E06.3 TIREOIDITE AUTO-IMUNE',
  'E06.4 TIREOIDITE INDUZ P/DROGA',
  'E06.5 OUTR TIREOIDITES CRONICAS',
  'E06.9 TIREOIDITE NE',
  'E07.0 HIPERSECRECAO DE CALCITONINA',
  'E07.1 BOCIO DISORMONOGENICO',
  'E07.8 OUTR TRANST ESPEC DA TIREOIDE',
  'E07.9 TRANST NE DA TIREOIDE',
  'E10.0 C/COMA',
  'E10.1 C/CETOACIDOSE',
  'E10.2 C/COMPL RENAIS',
  'E10.3 C/COMPL OFTALMICAS',
  'E10.4 C/COMPL NEUROLOGICAS',
  'E10.5 C/COMPL CIRCULATORIAS PERIFERICAS',
  'E10.6 C/OUTR COMPL ESPEC',
  'E10.7 C/COMPLIC MULT',
  'E10.8 C/COMPL NE',
  'E10.9 S/COMPLIC',
  'E11.0 C/COMA',
  'E11.1 C/CETOACIDOSE',
  'E11.2 C/COMPL RENAIS',
  'E11.3 C/COMPL OFTALMICAS',
  'E11.4 C/COMPL NEUROLOGICAS',
  'E11.5 C/COMPL CIRCULATORIAS PERIFERICAS',
  'E11.6 C/OUTR COMPL ESPEC',
  'E11.7 C/COMPLIC MULT',
  'E11.8 C/COMPL NE',
  'E11.9 S/COMPLIC',
  'E12.0 C/COMA',
  'E12.1 C/CETOACIDOSE',
  'E12.2 C/COMPL RENAIS',
  'E12.3 C/COMPL OFTALMICAS',
  'E12.4 C/COMPL NEUROLOGICAS',
  'E12.5 C/COMPL CIRCULATORIAS PERIFERICAS',
  'E12.6 C/OUTR COMPL ESPEC',
  'E12.7 C/COMPLIC MULT',
  'E12.8 C/COMPL NE',
  'E12.9 S/COMPLIC',
  'E13.0 C/COMA',
  'E13.1 C/CETOACIDOSE',
  'E13.2 C/COMPL RENAIS',
  'E13.3 C/COMPL OFTALMICAS',
  'E13.4 C/COMPL NEUROLOGICAS',
  'E13.5 C/COMPL CIRCULATORIAS PERIFERICAS',
  'E13.6 C/OUTR COMPL ESPEC',
  'E13.7 C/COMPLIC MULT',
  'E13.8 C/COMPL NE',
  'E13.9 S/COMPLIC',
  'E14.0 C/COMA',
  'E14.1 C/CETOACIDOSE',
  'E14.2 C/COMPL RENAIS',
  'E14.3 C/COMPL OFTALMICAS',
  'E14.4 C/COMPL NEUROLOGICAS',
  'E14.5 C/COMPL CIRCULATORIAS PERIFERICAS',
  'E14.6 C/OUTR COMPL ESPEC',
  'E14.7 C/COMPLIC MULT',
  'E14.8 C/COMPL NE',
  'E14.9 S/COMPLIC',
  'E15   COMA HIPOGLICEMICO NAO-DIABETICO',
  'E16.0 HIPOGLICEMIA INDUZ P/DROGA S/COMA',
  'E16.1 OUTR HIPOGLICEMIA',
  'E16.2 HIPOGLICEMIA NE',
  'E16.3 AUMENTO DA SECRECAO DE GLUCAGON',
  'E16.4 SECRECAO ANORMAL DE GASTRINA',
  'E16.8 OUTR TRANST ESPEC SECRECAO PANCREATICA INT',
  'E16.9 TRANST NE DA SECRECAO PANCREATICA INTERNA',
  'E20.0 HIPOPARATIREOIDISMO IDIOPATICO',
  'E20.1 PSEUDOHIPOPARATIREOIDISMO',
  'E20.8 OUTR HIPOPARATIREOIDISMO',
  'E20.9 HIPOPARATIREOIDISMO NE',
  'E21.0 HIPERPARATIREOIDISMO PRIM',
  'E21.1 HIPERPARATIREOIDISMO SECUND NCOP',
  'E21.2 OUTR HIPERPARATIREOIDISMO',
  'E21.3 HIPERPARATIREOIDISMO NE',
  'E21.4 OUTR TRANST ESPEC DA GLAND PARATIREOIDE',
  'E21.5 TRANST NE DA GLAND PARATIREOIDE',
  'E22.0 ACROMEGALIA E GIGANTISMO HIPOFISARIO',
  'E22.1 HIPERPROLACTINEMIA',
  'E22.2 SINDR SECRECAO INADEQ HORMONIO ANTIDIURETICO',
  'E22.8 OUTR HIPERFUNCOES DA HIPOFISE',
  'E22.9 HIPERFUNCAO NE DA HIPOFISE',
  'E23.0 HIPOPITUITARISMO',
  'E23.1 HIPOPITUITARISMO INDUZ P/DROGA',
  'E23.2 DIABETES INSIPIDO',
  'E23.3 DISFUNCAO HIPOTALAMICA NCOP',
  'E23.6 OUTR TRANST DA HIPOFISE',
  'E23.7 TRANST NE DA HIPOFISE',
  'E24.0 SINDR DE CUSHING DEPENDENTE DA HIPOFISE',
  'E24.1 SINDR DE NELSON',
  'E24.2 SINDR DE CUSHING INDUZ P/DROGA',
  'E24.3 SINDR DO ACTH ECTOPICO',
  'E24.4 SINDR DE PSEUDO-CUSHING INDUZ P/ALCOOL',
  'E24.8 OUTR SINDR DE CUSHING',
  'E24.9 SINDR DE CUSHING NE',
  'E25.0 TRANST ADRENOGEN CONGEN ASSOC DEFIC ENZIMAT',
  'E25.8 OUTR TRANST ADRENOGENITAIS',
  'E25.9 TRANST ADRENOGENITAL NE',
  'E26.0 HIPERALDOSTERONISMO PRIM',
  'E26.1 HIPERALDOSTERONISMO SECUND',
  'E26.8 OUTR HIPERALDOSTERONISMO',
  'E26.9 HIPERALDOSTERONISMO NE',
  'E27.0 OUTR EXCESSOS DE ATIVIDADE ADRENOCORTICAL',
  'E27.1 INSUF ADRENOCORTICAL PRIM',
  'E27.2 CRISE ADDISONIANA',
  'E27.3 INSUF ADRENOCORTICAL INDUZ P/DROGAS',
  'E27.4 OUTR INSUF ADRENOCORTICAIS E AS NE',
  'E27.5 HIPERFUNCAO ADRENOMEDULAR',
  'E27.8 OUTR TRANST ESPEC DA SUPRA-RENAL',
  'E27.9 TRANST NE DA SUPRA-RENAL',
  'E28.0 EXCESSO DE ESTROGENO',
  'E28.1 EXCESSO DE ANDROGENOS',
  'E28.2 SINDR DO OVARIO POLICISTICO',
  'E28.3 INSUF OVARIANA PRIM',
  'E28.8 OUTR DISFUNCAO OVARIANA',
  'E28.9 DISFUNCAO OVARIANA NE',
  'E29.0 HIPERFUNCAO TESTICULAR',
  'E29.1 HIPOFUNCAO TESTICULAR',
  'E29.8 OUTR DISFUNCAO TESTICULAR',
  'E29.9 DISFUNCAO TESTICULAR NE',
  'E30.0 PUBERDADE RETARDADA',
  'E30.1 PUBERDADE PRECOCE',
  'E30.8 OUTR TRANST DA PUBERDADE',
  'E30.9 TRANST NE DA PUBERDADE',
  'E31.0 INSUF POLIGLANDULAR AUTO-IMUNE',
  'E31.1 HIPERFUNCAO POLIGLANDULAR',
  'E31.8 OUTR DISFUNCAO POLIGLANDULAR',
  'E31.9 DISFUNCAO POLIGLANDULAR NE',
  'E32.0 HIPERPLASIA PERSISTENTE DO TIMO',
  'E32.1 ABSCESSO DO TIMO',
  'E32.8 OUTR DOENC DO TIMO',
  'E32.9 DOENC DO TIMO NE',
  'E34.0 SINDR CARCINOIDE',
  'E34.1 OUTR HIPERSECRECAO DE HORMONIOS INTESTINAIS',
  'E34.2 SECRECAO HORMONAL ECTOPICA NCOP',
  'E34.3 NANISMO NCOP',
  'E34.4 ALTA ESTATURA CONSTITUCIONAL',
  'E34.5 SINDR DE RESISTENCIA A ANDROGENOS',
  'E34.8 OUTR TRANST ENDOCRINOS ESPEC',
  'E34.9 TRANST ENDOCRINO NE',
  'E35.0 TRANST DA GLAND TIREOIDE EM DOENC COP',
  'E35.1 TRANST DAS SUPRA-RENAIS EM DOENC COP',
  'E35.8 TRANST DE OUTR GLAND ENDOCRINAS EM DOENC COP',
  'E40   KWASHIORKOR',
  'E41   MARASMO NUTRICIONAL',
  'E42   KWASHIORKOR MARASMATICO',
  'E43   DESNUTRIC PROTEICO-CALORICA GRAVE NE',
  'E44.0 DESNUTRIC PROTEICO-CALORICA MODERADA',
  'E44.1 DESNUTRIC PROTEICO-CALORICA LEVE',
  'E45   ATRASO DESENV DEV DESNUTRIC PROTEICO-CALORIC',
  'E46   DESNUTRIC PROTEICO-CALORICA NE',
  'E50.0 DEFIC DE VITAMINA A C/XEROSE CONJUNTIVAL',
  'E50.1 DEFIC VITAMINA C/MANCHA DE BITOT XEROSE CONJ',
  'E50.2 DEFIC DE VITAMINA A C/XEROSE DA CORNEA',
  'E50.3 DEFIC VITAMINA A C/ULCERACAO E XEROSE CORNEA',
  'E50.4 DEFIC DE VITAMINA A C/CERATOMALACIA',
  'E50.5 DEFIC DE VITAMINA A C/CEGUEIRA NOTURNA',
  'E50.6 DEFIC VITAMINA A CICATRIZES XEROFT CORNEA',
  'E50.7 OUTR MANIF OCULARES DEV DEFIC VITAMINA A',
  'E50.8 OUTR MANIFESTACOES DEV DEFIC DE VITAMINA A',
  'E50.9 DEFIC NE DE VITAMINA A',
  'E51.1 BERIBERI',
  'E51.2 ENCEFALOPATIA DE WERNICKE',
  'E51.8 OUTR MANIFESTACOES DA DEFIC DE TIAMINA',
  'E51.9 DEFIC NE DE TIAMINA',
  'E52   DEFIC DE NIACINA',
  'E53.0 DEFIC DE RIBOFLAVINA',
  'E53.1 DEFIC DE PIRIDOXINA',
  'E53.8 DEFIC DE OUTR VITAMINAS ESPEC DO GRUPO B',
  'E53.9 DEFIC NE DE VITAMINA B',
  'E54   DEFIC DE ACIDO ASCORBICO',
  'E55.0 RAQUITISMO ATIVO',
  'E55.9 DEFIC NE DE VITAMINA D',
  'E56.0 DEFIC DE VITAMINA E',
  'E56.1 DEFIC DE VITAMINA K',
  'E56.8 DEFIC DE OUTR VITAMINAS',
  'E56.9 DEFIC VITAMINICA NE',
  'E58   DEFIC DE CALCIO DA DIETA',
  'E59   DEFIC DE SELENIO DA DIETA',
  'E60   DEFIC DE ZINCO DA DIETA',
  'E61.0 DEFIC DE COBRE',
  'E61.1 DEFIC DE FERRO',
  'E61.2 DEFIC DE MAGNESIO',
  'E61.3 DEFIC DE MANGANES',
  'E61.4 DEFIC DE CROMO',
  'E61.5 DEFIC DE MOLIBDENIO',
  'E61.6 DEFIC DE VANADIO',
  'E61.7 DEFIC DE MULT ELEMENTOS NUTRIENTES',
  'E61.8 DEFIC DE OUTR ELEMENTOS NUTRIENTES ESPEC',
  'E61.9 DEFIC DE ELEMENTOS NUTRIENTES NE',
  'E63.0 DEFIC DE ACIDO GRAXO ESSENCIAL',
  'E63.1 DESEQUILIBRIO CONSTITUINTES INGEST ALIMENTOS',
  'E63.8 OUTR DEFIC NUTRICIONAIS ESPEC',
  'E63.9 DEFIC NUTRICIONAL NE',
  'E64.0 SEQUELAS DE DESNUTRIC PROTEICO-CALORICA',
  'E64.1 SEQUELAS DA DEFIC DE VITAMINA A',
  'E64.2 SEQUELAS DA DEFIC DE VITAMINA C',
  'E64.3 SEQUELAS DO RAQUITISMO',
  'E64.8 SEQUELAS DE OUTR DEFIC NUTRICIONAIS',
  'E64.9 SEQUELAS DE DEFIC NUTRICIONAL NE',
  'E65   ADIPOSIDADE LOCALIZADA',
  'E66.0 OBESIDADE DEV EXCESSO DE CALORIAS',
  'E66.1 OBESIDADE INDUZ P/DROGAS',
  'E66.2 OBESIDADE EXTREMA C/HIPOVENTILACAO ALVEOLAR',
  'E66.8 OUTR OBESIDADE',
  'E66.9 OBESIDADE NE',
  'E67.0 HIPERVITAMINOSE A',
  'E67.1 HIPERCAROTENEMIA',
  'E67.2 SINDR DE MEGAVITAMINA B6',
  'E67.3 HIPERVITAMINOSE D',
  'E67.8 OUTR FORM ESPEC DE HIPERALIMENTACAO',
  'E68   SEQUELAS DE HIPERALIMENTACAO',
  'E70.0 FENILCETONURIA CLASSICA',
  'E70.1 OUTR HIPERFENILALANINEMIAS',
  'E70.2 DISTURBIOS DO METABOLISMO DA TIROSINA',
  'E70.3 ALBINISMO',
  'E70.8 OUTR DISTURBIOS METAB AMINOACIDOS AROMATICOS',
  'E70.9 DISTURBIO NE METAB AMINOACIDOS AROMATICOS',
  'E71.0 DOENC DA URINA EM XAROPE DE ACER',
  'E71.1 OUTR DISTURB METAB AMINOACIDOS CADEIA RAMIF',
  'E71.2 DISTURBIO NE METAB AMINOACIDOS CADEIA RAMIF',
  'E71.3 DISTURBIOS DO METABOLISMO DE ACIDOS GRAXOS',
  'E72.0 DISTURBIOS DO TRANSPORTE DE AMINOACIDOS',
  'E72.1 DISTURB METAB AMINOACIDOS QUE CONTEM ENXOFRE',
  'E72.2 DISTURBIOS DO METABOLISMO DO CICLO DA UREIA',
  'E72.3 DISTURBIOS METABOLISMO LISINA HIDROXILISINA',
  'E72.4 DISTURBIOS DO METABOLISMO DA ORNITINA',
  'E72.5 DISTURBIOS DO METABOLISMO DA GLICINA',
  'E72.8 OUTR DISTURBIOS ESPEC METAB AMINOACIDOS',
  'E72.9 DISTURBIO NE DO METABOLISMO DOS AMINOACIDOS',
  'E73.0 DEFIC CONGEN DE LACTASE',
  'E73.1 DEFIC SECUND DE LACTASE',
  'E73.8 OUTR INTOLERANCIAS A LACTOSE',
  'E73.9 INTOLERANCIA A LACTOSE NE',
  'E74.0 DOENC DE DEPOSITO DE GLICOGENIO',
  'E74.1 DISTURBIOS DO METABOLISMO DA FRUTOSE',
  'E74.2 DISTURBIOS DO METABOLISMO DA GALACTOSE',
  'E74.3 OUTR DISTURBIOS ABSORCAO INTEST CARBOIDRATOS',
  'E74.4 DISTURBIOS METAB PIRUVATO GLICONEOGENESE',
  'E74.8 OUTR DISTURBIOS ESPEC METAB CARBOIDRATOS',
  'E74.9 DISTURBIO NE DO METABOLISMO DE CARBOIDRATOS',
  'E75.0 GANGLIOSIDOSE GM2',
  'E75.1 OUTR GANGLIOSIDOSES',
  'E75.2 OUTR ESFINGOLIPIDOSES',
  'E75.3 ESFINGOLIPIDOSE NE',
  'E75.4 LIPOFUSCINOSE NEURONAL CEROIDE',
  'E75.5 OUTR DISTURBIOS DO DEPOSITO DE LIPIDES',
  'E75.6 DISTURBIO NE DO DEPOSITO DE LIPIDES',
  'E76.0 MUCOPOLISSACARIDOSE DO TIPO I',
  'E76.1 MUCOPOLISSACARIDOSE DO TIPO II',
  'E76.2 OUTR MUCOPOLISSACARIDOSES',
  'E76.3 MUCOPOLISSACARIDOSE NE',
  'E76.8 OUTR DISTURBIOS METAB GLICOSAMINOGLICANO',
  'E76.9 DISTURBIO NE METABOLISMO GLICOSAMINOGLICANO',
  'E77.0 DEFEITOS MODIF POS-TRANSLAC ENZIMAS LISOSSOM',
  'E77.1 DEFEITOS NA DEGRADACAO DAS GLICOPROTEINAS',
  'E77.8 OUTR DISTURBIOS METABOLISMO GLICOPROTEINAS',
  'E77.9 DISTURBIO NE DO METABOLISMO GLICOPROTEINAS',
  'E78.0 HIPERCOLESTEROLEMIA PURA',
  'E78.1 HIPERGLICERIDEMIA PURA',
  'E78.2 HIPERLIPIDEMIA MISTA',
  'E78.3 HIPERQUILOMICRONEMIA',
  'E78.4 OUTR HIPERLIPIDEMIAS',
  'E78.5 HIPERLIPIDEMIA NE',
  'E78.6 DEFIC DE LIPOPROTEINAS',
  'E78.8 OUTR DISTURBIOS METABOLISMO DE LIPOPROTEINAS',
  'E78.9 DISTURBIO NE DO METABOLISMO DE LIPOPROTEINAS',
  'E79.0 HIPERURIC S/SINAIS ARTRITE INFL DOENC TOFOS',
  'E79.1 SINDR DE LESCH-NYHAN',
  'E79.8 OUTR DISTURBIOS METAB PURINA E PIRIMIDINA',
  'E79.9 DISTURBIO NE METABOLISMO PURINA E PIRIMIDINA',
  'E80.0 PORFIRIA HEREDITARIA ERITROPOETICA',
  'E80.1 PORFIRIA CUTANEA TARD',
  'E80.2 OUTR PORFIRIAS',
  'E80.3 DEFEITOS DA CATALASE E DA PEROXIDASE',
  'E80.4 SINDR DE GILBERT',
  'E80.5 SINDR DA CRIGLER-NAJJAR',
  'E80.6 OUTR DISTURBIOS METABOLISMO DA BILIRRUBINA',
  'E80.7 DISTURBIO NE DO METABOLISMO DA BILIRRUBINA',
  'E83.0 DISTURBIOS DO METABOLISMO DO COBRE',
  'E83.1 DOENC DO METABOLISMO DO FERRO',
  'E83.2 DISTURBIOS DO METABOLISMO DO ZINCO',
  'E83.3 DISTURBIOS DO METABOLISMO DO FOSFORO',
  'E83.4 DISTURBIOS DO METABOLISMO DO MAGNESIO',
  'E83.5 DISTURBIOS DO METABOLISMO DO CALCIO',
  'E83.8 OUTR DISTURBIOS DO METABOLISMO MINERAL',
  'E83.9 DISTURBIO NE DO METABOLISMO MINERAL',
  'E84.0 FIBROSE CISTICA C/MANIFESTACOES PULMONARES',
  'E84.1 FIBROSE CISTICA C/MANIFESTACOES INTESTINAIS',
  'E84.8 FIBROSE CISTICA C/OUTR MANIFESTACOES',
  'E84.9 FIBROSE CISTICA NE',
  'E85.0 AMILOIDOSE HEREDOFAMILIAR NAO-NEUROPATICA',
  'E85.1 AMILOIDOSE HEREDOFAMILIAR NEUROPATICA',
  'E85.2 AMILOIDOSE HEREDOFAMILIAR NE',
  'E85.3 AMILOIDOSE SISTEMICA SECUND',
  'E85.4 AMILOIDOSE LIMITADA A ORGAOS',
  'E85.8 OUTR AMILOIDOSES',
  'E85.9 AMILOIDOSE NE',
  'E86   DEPLECAO DE VOLUME',
  'E87.0 HIPEROSMOLARIDADE E HIPERNATREMIA',
  'E87.1 HIPOSMOLARIDADE E HIPONATREMIA',
  'E87.2 ACIDOSE',
  'E87.3 ALCALOSE',
  'E87.4 DISTURBIO MISTO DO EQUILIBRIO ACIDO-BASICO',
  'E87.5 HIPERPOTASSEMIA',
  'E87.6 HIPOPOTASSEMIA',
  'E87.7 SOBRECARGA DE LIQUIDOS',
  'E87.8 OUTR TRANST EQUILIBRIO HIDROELETROLIT NCOP',
  'E88.0 DISTURBIOS METAB PROTEINAS PLASMATICAS NCOP',
  'E88.1 LIPODISTROFIA NCOP',
  'E88.2 LIPOMATOSE NCOP',
  'E88.8 OUTR DISTURBIOS ESPEC DO METABOLISMO',
  'E88.9 DISTURBIO METABOLICO NE',
  'E89.0 HIPOTIREOIDISMO POS-PROCED',
  'E89.1 HIPOINSULINEMIA POS-PROCED',
  'E89.2 HIPOPARATIREOIDISMO POS-PROCED',
  'E89.3 HIPOPITUITARISMO POS-PROCED',
  'E89.4 INSUF OVARIANA POS-PROCED',
  'E89.5 HIPOFUNCAO TESTICULAR POS-PROCED',
  'E89.6 HIPOFUNCAO ADRENOCORTICAL POS-PROCED',
  'E89.8 OUTR TRANST ENDOCR E METAB POS-PROCED',
  'E89.9 TRANST ENDOCRINOS E METAB NE POS-PROCED',
  'E90   TRANST NUTRICIONAIS E METAB EM DOENC COP',
  'F00.0 DEMENCIA DOENC ALZHEIMER INICIO PRECOCE',
  'F00.1 DEMENCIA DOENC ALZHEIMER INICIO TARD',
  'F00.2 DEMENCIA DOENC ALZHEIMER FORM ATIPICA MISTA',
  'F00.9 DEMENCIA NE NA DOENC DE ALZHEIMER',
  'F01.0 DEMENCIA VASCULAR DE INICIO AGUDO',
  'F01.1 DEMENCIA P/INFARTOS MULT',
  'F01.2 DEMENCIA VASCULAR SUBCORTICAL',
  'F01.3 DEMENCIA VASCULAR MISTA CORTICAL SUBCORTICAL',
  'F01.8 OUTR DEMENCIA VASCULAR',
  'F01.9 DEMENCIA VASCULAR NE',
  'F02.0 DEMENCIA DA DOENC DE PICK',
  'F02.1 DEMENCIA NA DOENC DE CREUTZFELDT-JAKOB',
  'F02.2 DEMENCIA NA DOENC DE HUNTINGTON',
  'F02.3 DEMENCIA NA DOENC DE PARKINSON',
  'F02.4 DEMENCIA NA DOENC PELO HIV',
  'F02.8 DEMENCIA EM OUTR DOENC ESPEC COP',
  'F03   DEMENCIA NE',
  'F04   SINDR AMNES ORG N IND ALCOOL OUT SUBST PSIC',
  'F05.0 DELIRIUM NAO SUPERPOSTO DEMENCIA ASSIM DESCR',
  'F05.1 DELIRIUM SUPERPOSTO DEMENCIA',
  'F05.8 OUTR DELIRIUM',
  'F05.9 DELIRIUM NE',
  'F06.0 ALUCINOSE ORGANICA',
  'F06.1 ESTADO CATATONICO ORGANICO',
  'F06.2 TRANST DELIRANTE ORGANICO',
  'F06.3 TRANST DO HUMOR ORGANICOS',
  'F06.4 TRANST DA ANSIEDADE ORGANICOS',
  'F06.5 TRANST DISSOCIATIVO ORGANICO',
  'F06.6 TRANST DE LABILIDADE EMOCIONAL ORGANICO',
  'F06.7 TRANST COGNITIVO LEVE',
  'F06.8 OUT TRANS MENT ESP DEV LES DISF CER DOEN FIS',
  'F06.9 TRANST MENT NE DEV LESAO DISF CEREB DOEN FIS',
  'F07.0 TRANST ORGANICO DA PERSONALIDADE',
  'F07.1 SINDR POS-ENCEFALITICA',
  'F07.2 SINDR POS-TRAUM',
  'F07.8 OUT TRANS ORG PERS COMP DOEN CER LESAO DISF',
  'F07.9 TRANST ORG NE PERS COMP DOEN CER LESAO DISF',
  'F09   TRANST MENTAL ORGANICO OU SINTOMATICO NE',
  'F10.0 INTOX AGUDA',
  'F10.1 USO NOCIVO P/A SAUDE',
  'F10.2 SINDR DE DEPENDENCIA',
  'F10.3 SINDR DE ABSTINENCIA',
  'F10.4 SINDR DE ABSTINENCIA C/DELIRIUM',
  'F10.5 TRANST PSICOTICO',
  'F10.6 SINDR AMNESICA',
  'F10.7 TRANS PSICOTICO RESIDUAL INSTALACAO TARD',
  'F10.8 OUTR TRANST MENTAIS OU COMPORT',
  'F10.9 TRANST MENTAL OU COMPORT NE',
  'F11.0 INTOX AGUDA',
  'F11.1 USO NOCIVO P/A SAUDE',
  'F11.2 SINDR DE DEPENDENCIA',
  'F11.3 SINDR DE ABSTINENCIA',
  'F11.4 SINDR DE ABSTINENCIA C/DELIRIUM',
  'F11.5 TRANST PSICOTICO',
  'F11.6 SINDR AMNESICA',
  'F11.7 TRANS PSICOTICO RESIDUAL INSTALACAO TARD',
  'F11.8 OUTR TRANST MENTAIS OU COMPORT',
  'F11.9 TRANST MENTAL OU COMPORT NE',
  'F12.0 INTOX AGUDA',
  'F12.1 USO NOCIVO P/A SAUDE',
  'F12.2 SINDR DE DEPENDENCIA',
  'F12.3 SINDR DE ABSTINENCIA',
  'F12.4 SINDR DE ABSTINENCIA C/DELIRIUM',
  'F12.5 TRANST PSICOTICO',
  'F12.6 SINDR AMNESICA',
  'F12.7 TRANS PSICOTICO RESIDUAL INSTALACAO TARD',
  'F12.8 OUTR TRANST MENTAIS OU COMPORT',
  'F12.9 TRANST MENTAL OU COMPORT NE',
  'F13.0 INTOX AGUDA',
  'F13.1 USO NOCIVO P/A SAUDE',
  'F13.2 SINDR DE DEPENDENCIA',
  'F13.3 SINDR DE ABSTINENCIA',
  'F13.4 SINDR DE ABSTINENCIA C/DELIRIUM',
  'F13.5 TRANST PSICOTICO',
  'F13.6 SINDR AMNESICA',
  'F13.7 TRANS PSICOTICO RESIDUAL INSTALACAO TARD',
  'F13.8 OUTR TRANST MENTAIS OU COMPORT',
  'F13.9 TRANST MENTAL OU COMPORT NE',
  'F14.0 INTOX AGUDA',
  'F14.1 USO NOCIVO P/A SAUDE',
  'F14.2 SINDR DE DEPENDENCIA',
  'F14.3 SINDR DE ABSTINENCIA',
  'F14.4 SINDR DE ABSTINENCIA C/DELIRIUM',
  'F14.5 TRANST PSICOTICO',
  'F14.6 SINDR AMNESICA',
  'F14.7 TRANS PSICOTICO RESIDUAL INSTALACAO TARD',
  'F14.8 OUTR TRANST MENTAIS OU COMPORT',
  'F14.9 TRANST MENTAL OU COMPORT NE',
  'F15.0 INTOX AGUDA',
  'F15.1 USO NOCIVO P/A SAUDE',
  'F15.2 SINDR DE DEPENDENCIA',
  'F15.3 SINDR DE ABSTINENCIA',
  'F15.4 SINDR DE ABSTINENCIA C/DELIRIUM',
  'F15.5 TRANST PSICOTICO',
  'F15.6 SINDR AMNESICA',
  'F15.7 TRANS PSICOTICO RESIDUAL INSTALACAO TARD',
  'F15.8 OUTR TRANST MENTAIS OU COMPORT',
  'F15.9 TRANST MENTAL OU COMPORT NE',
  'F16.0 INTOX AGUDA',
  'F16.1 USO NOCIVO P/A SAUDE',
  'F16.2 SINDR DE DEPENDENCIA',
  'F16.3 SINDR DE ABSTINENCIA',
  'F16.4 SINDR DE ABSTINENCIA C/DELIRIUM',
  'F16.5 TRANST PSICOTICO',
  'F16.6 SINDR AMNESICA',
  'F16.7 TRANS PSICOTICO RESIDUAL INSTALACAO TARD',
  'F16.8 OUTR TRANST MENTAIS OU COMPORT',
  'F16.9 TRANST MENTAL OU COMPORT NE',
  'F17.0 INTOX AGUDA',
  'F17.1 USO NOCIVO P/A SAUDE',
  'F17.2 SINDR DE DEPENDENCIA',
  'F17.3 SINDR DE ABSTINENCIA',
  'F17.4 SINDR DE ABSTINENCIA C/DELIRIUM',
  'F17.5 TRANST PSICOTICO',
  'F17.6 SINDR AMNESICA',
  'F17.7 TRANS PSICOTICO RESIDUAL INSTALACAO TARD',
  'F17.8 OUTR TRANST MENTAIS OU COMPORT',
  'F17.9 TRANST MENTAL OU COMPORT NE',
  'F18.0 INTOX AGUDA',
  'F18.1 USO NOCIVO P/A SAUDE',
  'F18.2 SINDR DE DEPENDENCIA',
  'F18.3 SINDR DE ABSTINENCIA',
  'F18.4 SINDR DE ABSTINENCIA C/DELIRIUM',
  'F18.5 TRANST PSICOTICO',
  'F18.6 SINDR AMNESICA',
  'F18.7 TRANS PSICOTICO RESIDUAL INSTALACAO TARD',
  'F18.8 OUTR TRANST MENTAIS OU COMPORT',
  'F18.9 TRANST MENTAL OU COMPORT NE',
  'F19.0 INTOX AGUDA',
  'F19.1 USO NOCIVO P/A SAUDE',
  'F19.2 SINDR DE DEPENDENCIA',
  'F19.3 SINDR DE ABSTINENCIA',
  'F19.4 SINDR DE ABSTINENCIA C/DELIRIUM',
  'F19.5 TRANST PSICOTICO',
  'F19.6 SINDR AMNESICA',
  'F19.7 TRANS PSICOTICO RESIDUAL INSTALACAO TARD',
  'F19.8 OUTR TRANST MENTAIS OU COMPORT',
  'F19.9 TRANST MENTAL OU COMPORT NE',
  'F20.0 ESQUIZOFRENIA PARANOIDE',
  'F20.1 ESQUIZOFRENIA HEBEFRENICA',
  'F20.2 ESQUIZOFRENIA CATATONICA',
  'F20.3 ESQUIZOFRENIA INDIFERENCIADA',
  'F20.4 DEPRESSAO POS-ESQUIZOFRENICA',
  'F20.5 ESQUIZOFRENIA RESIDUAL',
  'F20.6 ESQUIZOFRENIA SIMPLES',
  'F20.8 OUTR ESQUIZOFRENIAS',
  'F20.9 ESQUIZOFRENIA NE',
  'F21   TRANST ESQUIZOTIPICO',
  'F22.0 TRANST DELIRANTE',
  'F22.8 OUTR TRANST DELIRANTES PERSISTENTES',
  'F22.9 TRANST DELIRANTE PERSISTENTE NE',
  'F23.0 TRANST PSICOT AGUDO POLIMORF S/SINT ESQUIZOF',
  'F23.1 TRANST PSICOT AGUDO POLIMORF C/SINT ESQUIZOF',
  'F23.2 TRANST PSICOTICO AGUDO TIPO ESQUIZOFRENICO',
  'F23.3 OUTR TRANST PSICOT AGUDOS ESSENC DELIRANTES',
  'F23.8 OUTR TRANST PSICOTICOS AGUDOS E TRANSITORIOS',
  'F23.9 TRANST PSICOTICO AGUDO E TRANSITORIO NE',
  'F24   TRANST DELIRANTE INDUZ',
  'F25.0 TRANST ESQUIZOAFETIVO DO TIPO MANIACO',
  'F25.1 TRANST ESQUIZOAFETIVO DO TIPO DEPRESSIVO',
  'F25.2 TRANST ESQUIZOAFETIVO DO TIPO MISTO',
  'F25.8 OUTR TRANST ESQUIZOAFETIVOS',
  'F25.9 TRANST ESQUIZOAFETIVO NE',
  'F28   OUTR TRANST PSICOTICOS NAO-ORGANICOS',
  'F29   PSICOSE NAO-ORGANICA NE',
  'F30.0 HIPOMANIA',
  'F30.1 MANIA S/SINT PSICOTICOS',
  'F30.2 MANIA C/SINT PSICOTICOS',
  'F30.8 OUTR EPISODIOS MANIACOS',
  'F30.9 EPISODIO MANIACO NE',
  'F31.0 TRANST AFET BIPOLAR EPISODIO ATUAL HIPOMAN',
  'F31.1 TRANS AFET BIP EPIS ATUAL MAN S/SINT PSICOT',
  'F31.2 TRANS AFET BIP EPIS ATUAL MAN C/SINT PSICOT',
  'F31.3 TRANS AFET BIP EPIS ATUAL DEPR LEVE/ MODERAD',
  'F31.4 TRANS AFET BIP ATUAL DEPR GRAV S/SINT PSICOT',
  'F31.5 TRANS AFET BIP ATUAL DEPR GRAV C/SINT PSICOT',
  'F31.6 TRANST AFETIVO BIPOLAR EPISODIO ATUAL MISTO',
  'F31.7 TRANST AFET BIPOLAR ATUALMENTE EM REMISSAO',
  'F31.8 OUTR TRANST AFETIVOS BIPOLARES',
  'F31.9 TRANST AFETIVO BIPOLAR NE',
  'F32.0 EPISODIO DEPRESSIVO LEVE',
  'F32.1 EPISODIO DEPRESSIVO MODERADO',
  'F32.2 EPISODIO DEPRESSIVO GRAVE S/SINT PSICOTICOS',
  'F32.3 EPISODIO DEPRESSIVO GRAVE C/SINT PSICOTICOS',
  'F32.8 OUTR EPISODIOS DEPRESSIVOS',
  'F32.9 EPISODIO DEPRESSIVO NE',
  'F33.0 TRANST DEPRESSIVO RECORR EPISODIO ATUAL LEVE',
  'F33.1 TRANST DEPRESS RECORR EPISODIO ATUAL MODERAD',
  'F33.2 TRANS DEPR RECOR EPIS ATUAL GRAV S/SINT PSIC',
  'F33.3 TRANS DEPR RECOR EPIS ATUAL GRAV C/SINT PSIC',
  'F33.4 TRANST DEPRESS RECORR ATUALMENTE EM REMISSAO',
  'F33.8 OUTR TRANST DEPRESSIVOS RECORRENTES',
  'F33.9 TRANST DEPRESSIVO RECORRENTE S/ESPECIFICACAO',
  'F34.0 CICLOTIMIA',
  'F34.1 DISTIMIA',
  'F34.8 OUTR TRANST DO HUMOR PERSISTENTES',
  'F34.9 TRANST DO HUMOR PERSISTENTE NE',
  'F38.0 OUTR TRANST DO HUMOR ISOLADOS',
  'F38.1 OUTR TRANST DO HUMOR RECORRENTES',
  'F38.8 OUTR TRANST ESPEC DO HUMOR',
  'F39   TRANST DO HUMOR NE',
  'F40.0 AGORAFOBIA',
  'F40.1 FOBIAS SOCIAIS',
  'F40.2 FOBIAS ESPECIFICAS',
  'F40.8 OUTR TRANST FOBICO-ANSIOSOS',
  'F40.9 TRANST FOBICO-ANSIOSO NE',
  'F41.0 TRANST DE PANICO',
  'F41.1 ANSIEDADE GENERALIZADA',
  'F41.2 TRANST MISTO ANSIOSO E DEPRESSIVO',
  'F41.3 OUTR TRANST ANSIOSOS MISTOS',
  'F41.8 OUTR TRANST ANSIOSOS ESPEC',
  'F41.9 TRANST ANSIOSO NE',
  'F42.0 COM PREDOM IDEIAS OU RUMINACOES OBSESSIVAS',
  'F42.1 COM PREDOM DE COMPORT COMPULSIVOS',
  'F42.2 FORM MISTA C/IDEIAS OBSESS E COMPORT COMPULS',
  'F42.8 OUTR TRANST OBSESSIVO-COMPULSIVOS',
  'F42.9 TRANST OBSESSIVO-COMPULSIVO NE',
  'F43.0 REACAO AGUDA AO STRESS',
  'F43.1 ESTADO DE STRESS POS-TRAUM',
  'F43.2 TRANST DE ADAPTACAO',
  'F43.8 OUTR REACOES AO STRESS GRAVE',
  'F43.9 REACAO NE A UM STRESS GRAVE',
  'F44.0 AMNESIA DISSOCIATIVA',
  'F44.1 FUGA DISSOCIATIVA',
  'F44.2 ESTUPOR DISSOCIATIVO',
  'F44.3 ESTADOS DE TRANSE E DE POSSESSAO',
  'F44.4 TRANST DISSOCIATIVOS DO MOVIMENTO',
  'F44.5 CONVULSOES DISSOCIATIVAS',
  'F44.6 ANESTESIA E PERDA SENSORIAL DISSOCIATIVAS',
  'F44.7 TRANST DISSOCIATIVO MISTO',
  'F44.8 OUTR TRANST DISSOCIATIVOS',
  'F44.9 TRANST DISSOCIATIVO NE',
  'F45.0 TRANST DE SOMATIZACAO',
  'F45.1 TRANST SOMATOFORME INDIFERENCIADO',
  'F45.2 TRANST HIPOCONDRIACO',
  'F45.3 TRANST NEUROVEGETATIVO SOMATOFORME',
  'F45.4 TRANST DOLOROSO SOMATOFORME PERSISTENTE',
  'F45.8 OUTR TRANST SOMATOFORMES',
  'F45.9 TRANST SOMATOFORME NE',
  'F48.0 NEURASTENIA',
  'F48.1 SINDR DE DESPERSONALIZACAO-DESREALIZACAO',
  'F48.8 OUTR TRANST NEUROTICOS ESPEC',
  'F48.9 TRANST NEUROTICO NE',
  'F50.0 ANOREXIA NERVOSA',
  'F50.1 ANOREXIA NERVOSA ATIPICA',
  'F50.2 BULIMIA NERVOSA',
  'F50.3 BULIMIA NERVOSA ATIPICA',
  'F50.4 HIPERFAGIA ASSOC OUTR DISTURB PSICOLOGICOS',
  'F50.5 VOMITOS ASSOC A OUTR DISTURBIOS PSICOLOGICOS',
  'F50.8 OUTR TRANST DA ALIMENTACAO',
  'F50.9 TRANST DE ALIMENTACAO NE',
  'F51.0 INSONIA NAO-ORGANICA',
  'F51.1 HIPERSONIA NAO-ORGANICA',
  'F51.2 TRANST CICLO VIGILIA-SONO DEV FAT NAO-ORGAN',
  'F51.3 SONAMBULISMO',
  'F51.4 TERRORES NOTURNOS',
  'F51.5 PESADELOS',
  'F51.8 OUTR TRANST DO SONO DEV FAT NAO-ORGANICOS',
  'F51.9 TRANST DO SONO DEV FATORES NAO-ORGANICOS NE',
  'F52.0 AUSENCIA OU PERDA DO DESEJO SEXUAL',
  'F52.1 AVERSAO SEXUAL E AUSENCIA DE PRAZER SEXUAL',
  'F52.2 FALHA DE RESPOSTA GENITAL',
  'F52.3 DISFUNCAO ORGASMICA',
  'F52.4 EJACULACAO PRECOCE',
  'F52.5 VAGINISMO NAO-ORGANICO',
  'F52.6 DISPAREUNIA NAO-ORGANICA',
  'F52.7 APETITE SEXUAL EXCESSIVO',
  'F52.8 OUTR DISFUNC SEX N DEV TRANST DOENC ORGAN',
  'F52.9 DISFUNCAO SEXUAL N DEV TRANST DOENC ORGAN NE',
  'F53.0 TRANST MENT COMPORT LEVES ASSOC PUERP NCOP',
  'F53.1 TRANST MENT COMPORT GRAVES ASSOC PUERP NCOP',
  'F53.8 OUTR TRANST MENTAIS COMPORT ASSOC PUERP NCOP',
  'F53.9 TRANST MENTAL E COMPORT ASSOC PUERPERIO NE',
  'F54   FAT PSICOLOG COMPORT ASSOC DOENC TRANST COP',
  'F55   ABUSO DE SUBST QUE NAO PRODUZEM DEPENDENCIA',
  'F59   SINDR COMP ASS TRANS FUNC FISIO FAT FISIC NE',
  'F60.0 PERSONALIDADE PARANOICA',
  'F60.1 PERSONALIDADE ESQUIZOIDE',
  'F60.2 PERSONALIDADE DISSOCIAL',
  'F60.3 TRANST DE PERSONALIDADE C/INSTAB EMOCIONAL',
  'F60.4 PERSONALIDADE HISTRIONICA',
  'F60.5 PERSONALIDADE ANANCASTICA',
  'F60.6 PERSONALIDADE ANSIOSA',
  'F60.7 PERSONALIDADE DEPENDENTE',
  'F60.8 OUTR TRANST ESPECIFICOS DA PERSONALIDADE',
  'F60.9 TRANST NE DA PERSONALIDADE',
  'F61   TRANST MISTOS PERSONAL OUTR TRANST PERSONAL',
  'F62.0 MODIF DURAD PERSONALID APOS EXPER CATASTR',
  'F62.1 MODIF DURAD PERSONALID APOS DOENC PSIQUIATR',
  'F62.8 OUTR MODIFICACOES DURADOURAS PERSONALIDADE',
  'F62.9 MODIFICACAO DURADOURA DA PERSONALIDADE NE',
  'F63.0 JOGO PATOLOGICO',
  'F63.1 PIROMANIA',
  'F63.2 ROUBO PATOLOGICO',
  'F63.3 TRICOTILOMANIA',
  'F63.8 OUTR TRANST DOS HABITOS E DOS IMPULSOS',
  'F63.9 TRANST DOS HABITOS E IMPULSOS NE',
  'F64.0 TRANSEXUALISMO',
  'F64.1 TRAVESTISMO BIVALENTE',
  'F64.2 TRANST DE IDENTIDADE SEXUAL NA INFANCIA',
  'F64.8 OUTR TRANST DA IDENTIDADE SEXUAL',
  'F64.9 TRANST NE DA IDENTIDADE SEXUAL',
  'F65.0 FETICHISMO',
  'F65.1 TRAVESTISMO FETICHISTA',
  'F65.2 EXIBICIONISMO',
  'F65.3 VOYEURISMO',
  'F65.4 PEDOFILIA',
  'F65.5 SADOMASOQUISMO',
  'F65.6 TRANST MULT DA PreferENCIA SEXUAL',
  'F65.8 OUTR TRANST DA PreferENCIA SEXUAL',
  'F65.9 TRANST DA PreferENCIA SEXUAL NE',
  'F66.0 TRANST DA MATURACAO SEXUAL',
  'F66.1 ORIENTACAO SEXUAL EGODISTONICA',
  'F66.2 TRANST DO RELACIONAMENTO SEXUAL',
  'F66.8 OUTR TRANST DO DESENVOLV PSICOSSEXUAL',
  'F66.9 TRANST DO DESENVOLV SEXUAL NE',
  'F68.0 SINT FISICOS AUMENT P/FATORES PSICOLOGICOS',
  'F68.1 PROD DELIB OU SIMUL SINT INCAPAC FISIC PSIC',
  'F68.8 OUTR TRANST ESPEC PERSONAL E COMPORT ADULTO',
  'F69   TRANST PERSONALIDADE E COMPORT ADULTO NE',
  'F70.0 MENCAO AUSENCIA DE OU COMPR MINIMO COMPORT',
  'F70.1 COMPR SIGNIF COMPORT REQ VIGILANCIA OU TRAT',
  'F70.8 OUTR COMPROMETIMENTOS DO COMPORT',
  'F70.9 S/MENCAO DE COMPR DO COMPORT',
  'F71.0 MENCAO AUSENCIA DE OU COMPR MINIMO COMPORT',
  'F71.1 COMPR SIGNIF COMPORT REQ VIGILANCIA OU TRAT',
  'F71.8 OUTR COMPROMETIMENTOS DO COMPORT',
  'F71.9 S/MENCAO DE COMPR DO COMPORT',
  'F72.0 MENCAO AUSENCIA DE OU COMPR MINIMO COMPORT',
  'F72.1 COMPR SIGNIF COMPORT REQ VIGILANCIA OU TRAT',
  'F72.8 OUTR COMPROMETIMENTOS DO COMPORT',
  'F72.9 S/MENCAO DE COMPR DO COMPORT',
  'F73.0 MENCAO AUSENCIA DE OU COMPR MINIMO COMPORT',
  'F73.1 COMPR SIGNIF COMPORT REQ VIGILANCIA OU TRAT',
  'F73.8 OUTR COMPROMETIMENTOS DO COMPORT',
  'F73.9 S/MENCAO DE COMPR DO COMPORT',
  'F78.0 MENCAO AUSENCIA DE OU COMPR MINIMO COMPORT',
  'F78.1 COMPR SIGNIF COMPORT REQ VIGILANCIA OU TRAT',
  'F78.8 OUTR COMPROMETIMENTOS DO COMPORT',
  'F78.9 S/MENCAO DE COMPR DO COMPORT',
  'F79.0 MENCAO AUSENCIA DE OU COMPR MINIMO COMPORT',
  'F79.1 COMPR SIGNIF COMPORT REQ VIGILANCIA OU TRAT',
  'F79.8 OUTR COMPROMETIMENTOS DO COMPORT',
  'F79.9 S/MENCAO DE COMPR DO COMPORT',
  'F80.0 TRANST ESPECIFICO DA ARTICULACAO DA FALA',
  'F80.1 TRANST EXPRESSIVO DE LINGUAGEM',
  'F80.2 TRANST RECEPTIVO DA LINGUAGEM',
  'F80.3 AFASIA ADQUIR C/EPILEPSIA',
  'F80.8 OUTR TRANST DESENVOLV FALA OU LINGUAGEM',
  'F80.9 TRANST NE DO DESENVOLV DA FALA OU LINGUAGEM',
  'F81.0 TRANST ESPECIFICO DE LEITURA',
  'F81.1 TRANST ESPECIFICO DA SOLETRACAO',
  'F81.2 TRANST ESPECIFICO HABILIDADE EM ARITMETICA',
  'F81.3 TRANST MISTO DE HABILIDADES ESCOLARES',
  'F81.8 OUTR TRANST DO DESENVOLV HABILIDADE ESCOLAR',
  'F81.9 TRANST NE DO DESENVOLV HABILIDADES ESCOLARES',
  'F82   TRANST ESPECIFICO DO DESENVOLV MOTOR',
  'F83   TRANST ESPECIFICOS MISTO DO DESENVOLV',
  'F84.0 AUTISMO INFANTIL',
  'F84.1 AUTISMO ATIPICO',
  'F84.2 SINDR DE RETT',
  'F84.3 OUTR TRANST DESINTEGRATIVO DA INFANCIA',
  'F84.4 TRANST C/HIPERCIN ASS RET MENT MOV ESTEREOT',
  'F84.5 SINDR DE ASPERGER',
  'F84.8 OUTR TRANST GLOBAIS DO DESENVOLV',
  'F84.9 TRANST GLOBAIS NE DO DESENVOLV',
  'F88   OUTR TRANST DO DESENVOLV PSICOLOGICO',
  'F89   TRANST DO DESENVOLV PSICOLOGICO NE',
  'F90.0 DISTURBIOS DA ATIVIDADE E DA ATENCAO',
  'F90.1 TRANST HIPERCINETICO DE CONDUTA',
  'F90.8 OUTR TRANST HIPERCINETICOS',
  'F90.9 TRANST HIPERCINETICO NE',
  'F91.0 DISTURBIO CONDUTA RESTRITO CONTEXTO FAMILIAR',
  'F91.1 DISTURBIO DE CONDUTA NAO-SOCIALIZADO',
  'F91.2 DISTURBIO DE CONDUTA DO TIPO SOCIALIZADO',
  'F91.3 DISTURBIO DESAFIADOR E DE OPOSICAO',
  'F91.8 OUTR TRANST DE CONDUTA',
  'F91.9 TRANST DE CONDUTA NE',
  'F92.0 DISTURBIO DEPRESSIVO DE CONDUTA',
  'F92.8 OUTR TRANST MISTOS DA CONDUTA E DAS EMOCOES',
  'F92.9 TRANST MISTO DA CONDUTA E DAS EMOCOES NE',
  'F93.0 TRANST LIGADO A ANGUSTIA DE SEPARACAO',
  'F93.1 TRANST FOBICO ANSIOSO DA INFANCIA',
  'F93.2 DISTURBIO DE ANSIEDADE SOCIAL DA INFANCIA',
  'F93.3 TRANST DE RIVALIDADE ENTRE IRMAOS',
  'F93.8 OUTR TRANST EMOCIONAIS DA INFANCIA',
  'F93.9 TRANST EMOCIONAL DA INFANCIA NE',
  'F94.0 MUTISMO ELETIVO',
  'F94.1 DISTURBIO REATIVO DE VINCULACAO DA INFANCIA',
  'F94.2 TRANST DE FIXACAO DA INFANCIA C/DESINIBICAO',
  'F94.8 OUTR TRANST DO FUNCIONAMENTO SOCIAL INFANCIA',
  'F94.9 TRANST DO FUNCIONAMENTO SOCIAL INFANCIA NE',
  'F95.0 TIQUE TRANSITORIO',
  'F95.1 TIQUE MOTOR OU VOCAL CRONICO',
  'F95.2 TIQUES VOCAIS E MOTORES MULT COMBINADOS',
  'F95.8 OUTR TIQUES',
  'F95.9 TIQUE NE',
  'F98.0 ENURESE ORIG NAO-ORGANICA',
  'F98.1 ENCOPRESE ORIG NAO-ORGANICA',
  'F98.2 TRANST DE ALIMENTACAO NA INFANCIA',
  'F98.3 PICA DO LACTENTE OU DA CRIANCA',
  'F98.4 ESTEREOTIPIAS MOTORAS',
  'F98.5 GAGUEIRA',
  'F98.6 LINGUAGEM PRECIPITADA',
  'F98.8 OUTR TRANS COMP EMOC ESPEC C/INIC INF ADOLES',
  'F98.9 TRANST COMP EMOC NE C/INICIO INFANC ADOLESC',
  'F99   TRANST MENTAL NE EM OUTR PARTE',
  'G00.0 MENINGITE P/HAEMOPHILUS',
  'G00.1 MENINGITE PNEUMOCOCICA',
  'G00.2 MENINGITE ESTREPTOCOCICA',
  'G00.3 MENINGITE ESTAFILOCOCICA',
  'G00.8 OUTR MENINGITES BACTER',
  'G00.9 MENINGITE BACTER NE',
  'G01   MENINGITE EM DOENC BACTER COP',
  'G02.0 MENINGITE EM DOENC VIRAIS COP',
  'G02.1 MENINGITE EM MICOSES',
  'G02.8 MENINGITE EM OUTR DOENC INFECC E PARASIT COP',
  'G03.0 MENINGITE NAO-PIOGENICA',
  'G03.1 MENINGITE CRONICA',
  'G03.2 MENINGITE RECORRENTE BENIGNA',
  'G03.8 MENINGITE DEV OUTR CAUSAS ESPEC',
  'G03.9 MENINGITE NE',
  'G04.0 ENCEFALITE AGUDA DISSEMINADA',
  'G04.1 PARAPLEGIA ESPASTICA TROPICAL',
  'G04.2 MENINGOENCEFALITE MENINGOMIELITE BACTER NCOP',
  'G04.8 OUTR ENCEFALITES MIELITES E ENCEFALOMIELITES',
  'G04.9 ENCEFALITE MIELITE E ENCEFALOMIELITE NE',
  'G05.0 ENCEFAL MIELIT ENCEFALOMIEL DOENC BACTER COP',
  'G05.1 ENCEFAL MIELIT ENCEFALOMIEL DOENC VIRAIS COP',
  'G05.2 ENCEF MIEL ENCEFAL OUT DOENC INFEC PARAS COP',
  'G05.8 ENCEFAL MIELIT ENCEFALOMIEL OUTR DOENC COP',
  'G06.0 ABSCESSO E GRANULOMA INTRACRANIANOS',
  'G06.1 ABSCESSO E GRANULOMA INTRA-RAQUIDIANOS',
  'G06.2 ABSCESSO EXTRADURAL E SUBDURAL NE',
  'G07   ABSCESS GRANUL INTRACRAN INTRASPIN DOENC COP',
  'G08   FLEBITE TROMBOFLEBITE INTRACRAN INTRA-RAQUID',
  'G09   SEQUELAS DOENC INFLAM SIST NERV CENTR',
  'G10   DOENC DE HUNTINGTON',
  'G11.0 ATAXIA CONGEN NAO-PROGRESSIVA',
  'G11.1 ATAXIA CEREBELAR DE INICIO PRECOCE',
  'G11.2 ATAXIA CEREBELAR DE INICIO TARD',
  'G11.3 ATAXIA CEREBELAR C/DEFICIT NA REPARACAO DNA',
  'G11.4 PARAPLEGIA ESPASTICA HEREDITARIA',
  'G11.8 OUTR ATAXIAS HEREDITARIAS',
  'G11.9 ATAXIA HEREDITARIA NE',
  'G12.0 ATROFIA MUSCULAR ESPINAL INFANTIL TIPO I',
  'G12.1 OUTR ATROFIAS MUSCULARES ESPINAIS HEREDITAR',
  'G12.2 DOENC DO NEURONIO MOTOR',
  'G12.8 OUTR ATROF MUSCUL ESPINAIS SINDR MUSCUL CORR',
  'G12.9 ATROFIA MUSCULAR ESPINAL NE',
  'G13.0 NEUROMIOPATIA E NEUROPATIA PARANEOPLASICAS',
  'G13.1 OUT ATROF SIST AFET SIST NERV CENT DOEN NEOP',
  'G13.2 ATROF SIST Q AFET PRIM SIST NERV CENTR MIXED',
  'G13.8 ATR SIST Q AFET SIST NERV CENTR OUT DOEN COP',
  'G20   DOENC DE PARKINSON',
  'G21.0 SINDR MALIGNA DOS NEUROLEPTICOS',
  'G21.1 OUTR FORM PARKINSONISMO SECUND INDUZ DROGAS',
  'G21.2 PARKINSONISMO SECUND DEV OUTR AGENTES EXTERN',
  'G21.3 PARKINSONISMO POS-ENCEFALITICO',
  'G21.8 OUTR FORM DE PARKINSONISMO SECUND',
  'G21.9 PARKINSONISMO SECUND NE',
  'G22   PARKINSONISMO EM DOENC COP',
  'G23.0 DOENC DE HALLERVORDEN-SPATZ',
  'G23.1 OFTALMOPLEGIA SUPRANUCLEAR PROGRESSIVA',
  'G23.2 DEGENERACAO ESTRIONIGRICA',
  'G23.8 OUTR DOENC DEGENERATIVAS ESPEC GANGLIOS BASE',
  'G23.9 DOENC DEGENERATIVA DOS GANGLIOS DA BASE NE',
  'G24.0 DISTONIA INDUZ P/DROGAS',
  'G24.1 DISTONIA FAMILIAR IDIOPATICA',
  'G24.2 DISTONIA NAO-FAMILIAR IDIOPATICA',
  'G24.3 TORCICOLO ESPASMODICO',
  'G24.4 DISTONIA OROFACIAL IDIOPATICA',
  'G24.5 BLEFAROESPASMO',
  'G24.8 OUTR DISTONIAS',
  'G24.9 DISTONIA NE',
  'G25.0 TREMOR ESSENCIAL',
  'G25.1 TREMOR INDUZ P/DROGAS',
  'G25.2 OUTR FORM ESPEC DE TREMOR',
  'G25.3 MIOCLONIA',
  'G25.4 COREIA INDUZ P/DROGA',
  'G25.5 OUTR FORM DE COREIA',
  'G25.6 TIQUES INDUZ P/DROGA E OUTR TIPOS ORIG ORGAN',
  'G25.8 OUTR DOENC EXTRAPIRAMID TRANST MOVIM ESPEC',
  'G25.9 DOENC EXTRAPIRAMIDAIS TRANST MOVIMENTOS NE',
  'G26   DOENC EXTRAPIRAMIDAIS TRANST MOVIM DOENC COP',
  'G30.0 DOENC DE ALZHEIMER DE INICIO PRECOCE',
  'G30.1 DOENC DE ALZHEIMER DE INICIO TARD',
  'G30.8 OUTR FORM DE DOENC DE ALZHEIMER',
  'G30.9 DOENC DE ALZHEIMER NE',
  'G31.0 ATROFIA CEREBRAL CIRCUNSCRITA',
  'G31.1 DEGENERACAO CEREBRAL SENIL NCOP',
  'G31.2 DEGENERACAO DO SIST NERVOSO DEV ALCOOL',
  'G31.8 OUTR DOENC DEGENERATIVAS ESPEC SIST NERVOSO',
  'G31.9 DOENC DEGENERATIVA DO SIST NERVOSO NE',
  'G32.0 DEGENER COMB SUBAGUD MEDUL ESPINAL DOENC COP',
  'G32.8 OUTR TRANST DEGENER ESPEC SIST NERV DOEN COP',
  'G35   ESCLEROSE MULT',
  'G36.0 NEUROMIELITE OPTICA',
  'G36.1 LEUCOENCEFALITE HEMORRAGICA AGUDA E SUBAGUDA',
  'G36.8 OUTR DESMIELINIZACOES DISSEM AGUDAS ESPEC',
  'G36.9 DESMIELINIZACAO DISSEMINADA AGUDA NE',
  'G37.0 ESCLEROSE DIFUSA',
  'G37.1 DESMIELINIZACAO CENTRAL DO CORPO CALOSO',
  'G37.2 MIELINOLISE CENTRAL DA PONTE',
  'G37.3 MIELIT TRANS AGUD DOEN DESMIEL SIS NERV CENT',
  'G37.4 MIELITE SUBAGUDA NECROTICA',
  'G37.5 ESCLEROSE CONCENTRICA',
  'G37.8 OUTR DOENC DESMIEL ESPEC SIST NERV CENTR',
  'G37.9 DOENC DESMIELINIZANTE SIST NERV CENTRAL NE',
  'G40.0 EPILEP SIND EPIL IDIOP DEF LOC CRIS INIC FOC',
  'G40.1 EPILEP SIND EPIL SINT DEF LOC CRIS PARC SIMP',
  'G40.2 EPILEP SIND EPIL SINT DEF LOC CRIS PARC COMP',
  'G40.3 EPILEPSIA E SINDR EPILEPTICAS GENER IDIOPAT',
  'G40.4 OUTR EPILEPSIAS E SINDR EPILEPTICAS GENER',
  'G40.5 SINDR EPILEPTICAS ESPECIAIS',
  'G40.6 CRISE DE GRANDE MAL NE',
  'G40.7 PEQUENO MAL NE S/CRISES DE GRANDE MAL',
  'G40.8 OUTR EPILEPSIAS',
  'G40.9 EPILEPSIA NE',
  'G41.0 ESTADO DE GRANDE MAL EPILEPTICO',
  'G41.1 ESTADO DE PEQUENO MAL EPILEPTICO',
  'G41.2 ESTADO DE MAL EPILEPTICO PARCIAL COMPLEXO',
  'G41.8 OUTR ESTADOS DE MAL EPILEPTICO',
  'G41.9 ESTADO DE MAL EPILEPTICO NE',
  'G43.0 ENXAQUECA S/AURA',
  'G43.1 ENXAQUECA C/AURA',
  'G43.2 ESTADO DE MAL ENXAQUECOSO',
  'G43.3 ENXAQUECA COMPLICADA',
  'G43.8 OUTR FORM DE ENXAQUECA',
  'G43.9 ENXAQUECA S/ESPECIFICACAO',
  'G44.0 SINDR DE CLUSTER-HEADACHE',
  'G44.1 CEFALEIA VASCULAR NCOP',
  'G44.2 CEFALEIA TENSIONAL',
  'G44.3 CEFALEIA CRONICA POS-TRAUM',
  'G44.4 CEFALEIA INDUZ P/DROGAS NCOP',
  'G44.8 OUTR SINDR DE CEFALEIA ESPEC',
  'G45.0 SINDR DA ARTERIA VERTEBRO-BASILAR',
  'G45.1 SINDR DA ARTERIA CAROTIDEA',
  'G45.2 SINDR ARTERIAS PRE-CEREBRAIS MULT BILATERAIS',
  'G45.3 AMAUROSE FUGAZ',
  'G45.4 AMNESIA GLOBAL TRANSITORIA',
  'G45.8 OUTR ACID ISQUEMICOS CEREBR TRANS SINDR CORR',
  'G45.9 ISQUEMIA CEREBRAL TRANSITORIA NE',
  'G46.0 SINDR DA ARTERIA CEREBRAL MEDIA',
  'G46.1 SINDR DA ARTERIA CEREBRAL ANTERIOR',
  'G46.2 SINDR DA ARTERIA CEREBRAL POSTERIOR',
  'G46.3 SINDR VASCULARES DO TRONCO CEREBRAL',
  'G46.4 SINDR VASCULAR CEREBELAR',
  'G46.5 SINDR LACUNAR MOTORA PURA',
  'G46.6 SINDR LACUNAR SENSORIAL PURA',
  'G46.7 OUTR SINDR LACUNARES',
  'G46.8 OUTR SINDR VASC CEREBR DOENC CEREBROVASCULAR',
  'G47.0 DISTURBIOS DO INICIO E DA MANUTENCAO DO SONO',
  'G47.1 DISTURBIOS DO SONO P/SONOLENCIA EXCESSIVA',
  'G47.2 DISTURBIOS DO CICLO VIGILIA-SONO',
  'G47.3 APNEIA DE SONO',
  'G47.4 NARCOLEPSIA E CATAPLEXIA',
  'G47.8 OUTR DISTURBIOS DO SONO',
  'G47.9 DISTURBIO DO SONO NE',
  'G50.0 NEVRALGIA DO TRIGEMEO',
  'G50.1 DOR FACIAL ATIPICA',
  'G50.8 OUTR TRANST DO NERVO TRIGEMEO',
  'G50.9 TRANST NE DO NERVO TRIGEMEO',
  'G51.0 PARALISIA DE BELL',
  'G51.1 GANGLIONITE GENICULADA',
  'G51.2 SINDR DE MELKERSSON',
  'G51.3 ESPASMO HEMIFACIAL CLONICO',
  'G51.4 MIOQUIMIA FACIAL',
  'G51.8 OUTR TRANST DO NERVO FACIAL',
  'G51.9 TRANST NE DO NERVO FACIAL',
  'G52.0 TRANST DO NERVO OLFATORIO',
  'G52.1 TRANST DO NERVO GLOSSOFARINGEO',
  'G52.2 TRANST DO NERVO VAGO',
  'G52.3 TRANST DO NERVO HIPOGLOSSO',
  'G52.7 TRANST DE MULT NERVOS CRANIANOS',
  'G52.8 TRANST DE OUTR NERVOS CRANIANOS ESPEC',
  'G52.9 TRANST DE NERVO CRANIANO NE',
  'G53.0 NEVRALGIA POS-ZOSTER',
  'G53.1 PARAL MULT NERV CRAN DOEN INFECC PARASIT COP',
  'G53.2 PARALISIAS MULT NERVOS CRANIANOS SARCOIDOSE',
  'G53.3 PARALISIAS MULT NERV CRAN DOENC NEOPLASICAS',
  'G53.8 OUTR TRANST NERVOS CRANIANOS OUTR DOENC COP',
  'G54.0 TRANST DO PLEXO BRAQUIAL',
  'G54.1 TRANST DO PLEXO LOMBOSSACRAL',
  'G54.2 TRANST DAS RAIZES CERVICAIS NCOP',
  'G54.3 TRANST DAS RAIZES TORACICAS NCOP',
  'G54.4 TRANST DAS RAIZES LOMBOSSACRAS NCOP',
  'G54.5 AMIOTROFIA NEVRALGICA',
  'G54.6 SINDR DOLOROSA DO MEMBRO FANTASMA',
  'G54.7 SINDR MEMBRO FANTASMA S/MANIFEST DOLOROSA',
  'G54.8 OUTR TRANST DAS RAIZES E DOS PLEXOS NERVOSOS',
  'G54.9 TRANST NE DAS RAIZES E DOS PLEXOS NERVOSOS',
  'G55.0 COMPRESSOES RAIZES PLEXOS NERV DOENC NEOPLAS',
  'G55.1 COMPR RAIZ PLEXO NERV TRANST DISCO INTERVERT',
  'G55.2 COMPRESSOES RAIZES PLEXOS NERV ESPONDILOSE',
  'G55.3 COMPRESSOES RAIZES PLEXOS NERV OUTR DORSOPAT',
  'G55.8 COMPRESS RAIZES PLEXOS NERV OUTR DOENC COP',
  'G56.0 SINDR DO TUNEL DO CARPO',
  'G56.1 OUTR LESOES DO NERVO MEDIANO',
  'G56.2 LESOES DO NERVO CUBITAL',
  'G56.3 LESAO DO NERVO RADIAL',
  'G56.4 CAUSALGIA',
  'G56.8 OUTR MONONEUROPATIAS DOS MEMBROS SUPER',
  'G56.9 MONONEUROPATIA DOS MEMBROS SUPER NE',
  'G57.0 LESAO DO NERVO CIATICO',
  'G57.1 MERALGIA PARESTESICA',
  'G57.2 LESAO DO NERVO FEMORAL',
  'G57.3 LESAO DO NERVO POPLITEO LATERAL',
  'G57.4 LESAO DO NERVO POPLITEO MEDIAL',
  'G57.5 SINDR DO TUNEL DO TARSO',
  'G57.6 LESAO DO NERVO PLANTAR',
  'G57.8 OUTR MONONEUROPATIAS DOS MEMBROS INFER',
  'G57.9 MONONEUROPATIA DOS MEMBROS INFER NE',
  'G58.0 NEUROPATIA INTERCOSTAL',
  'G58.7 MONONEURITE MULT',
  'G58.8 OUTR MONONEUROPATIAS ESPEC',
  'G58.9 MONONEUROPATIA NE',
  'G59.0 MONONEUROPATIA DIABETICA',
  'G59.8 OUTR MONONEUROPATIAS EM DOENC COP',
  'G60.0 NEUROPATIA HEREDITARIA MOTORA E SENSORIAL',
  'G60.1 DOENC DE REFSUM',
  'G60.2 NEUROPATIA ASSOC A ATAXIA HEREDITARIA',
  'G60.3 NEUROPATIA PROGRESSIVA IDIOPATICA',
  'G60.8 OUTR NEUROPATIAS HEREDITARIAS E IDIOPATICAS',
  'G60.9 NEUROPATIA HEREDITARIA E IDIOPATICA NE',
  'G61.0 SINDR DE GUILLAIN-BARRE',
  'G61.1 NEUROPATIA SERICA',
  'G61.8 OUTR POLINEUROPATIAS INFLAM',
  'G61.9 POLINEUROPATIA INFLAM NE',
  'G62.0 POLINEUROPATIA INDUZ P/DROGAS',
  'G62.1 POLINEUROPATIA ALCOOLICA',
  'G62.2 POLINEUROPATIA DEV OUTR AGENTES TOXICOS',
  'G62.8 OUTR POLINEUROPATIAS ESPEC',
  'G62.9 POLINEUROPATIA NE',
  'G63.0 POLINEUROPATIA EM DOENC INFECC E PARASIT COP',
  'G63.1 POLINEUROPATIA EM DOENC NEOPLASICAS',
  'G63.2 POLINEUROPATIA DIABETICA',
  'G63.3 POLINEUROPATIA OUTR DOENC ENDOCR METABOLICAS',
  'G63.4 POLINEUROPATIA EM DEFIC NUTRICIONAIS',
  'G63.5 POLINEUROPATIA DOENC SISTEMICAS TEC CONJUNT',
  'G63.6 POLINEUROPATIA OUTR TRANST OSTEOMUSCULARES',
  'G63.8 POLINEUROPATIA EM OUTR DOENC COP',
  'G64   OUTR TRANST DO SIST NERVOSO PERIFERICO',
  'G70.0 MIASTENIA GRAVIS',
  'G70.1 TRANST MIONEURAIS TOXICOS',
  'G70.2 MIASTENIA CONGEN E DO DESENVOLV',
  'G70.8 OUTR TRANST MIONEURAIS ESPEC',
  'G70.9 TRANST MIONEURAL NE',
  'G71.0 DISTROFIA MUSCULAR',
  'G71.1 TRANST MIOTONICOS',
  'G71.2 MIOPATIAS CONGEN',
  'G71.3 MIOPATIA MITOCONDRIAL NCOP',
  'G71.8 OUTR TRANST PRIM DOS MUSCULOS',
  'G71.9 TRANST MUSCULAR PRIM NE',
  'G72.0 MIOPATIA INDUZ P/DROGAS',
  'G72.1 MIOPATIA ALCOOLICA',
  'G72.2 MIOPATIA DEV OUTR AGENTES TOXICOS',
  'G72.3 PARALISIA PERIODICA',
  'G72.4 MIOPATIA INFLAM NCOP',
  'G72.8 OUTR MIOPATIAS ESPEC',
  'G72.9 MIOPATIA NE',
  'G73.0 SINDR MIASTENICAS EM DOENC ENDOCRINAS',
  'G73.1 SINDR DE EATON-LAMBERT',
  'G73.2 OUTR SINDR MIASTENICAS EM DOENC NEOPLASICAS',
  'G73.3 SINDR MIASTENICAS EM OUTR DOENC COP',
  'G73.4 MIOPATIA EM DOENC INFECC E PARASIT COP',
  'G73.5 MIOPATIA EM DOENC ENDOCRINAS',
  'G73.6 MIOPATIA EM DOENC METABOLICAS',
  'G73.7 MIOPATIA EM OUTR DOENC COP',
  'G80.0 PARALISIA CEREBRAL ESPASTICA',
  'G80.1 DIPLEGIA ESPASTICA',
  'G80.2 HEMIPLEGIA INFANTIL',
  'G80.3 PARALISIA CEREBRAL DISCINETICA',
  'G80.4 PARALISIA CEREBRAL ATAXICA',
  'G80.8 OUTR FORM DE PARALISIA CEREBRAL INFANTIL',
  'G80.9 PARALISIA CEREBRAL INFANTIL NE',
  'G81.0 HEMIPLEGIA FLACIDA',
  'G81.1 HEMIPLEGIA ESPASTICA',
  'G81.9 HEMIPLEGIA NE',
  'G82.0 PARAPLEGIA FLACIDA',
  'G82.1 PARAPLEGIA ESPASTICA',
  'G82.2 PARAPLEGIA NE',
  'G82.3 TETRAPLEGIA FLACIDA',
  'G82.4 TETRAPLEGIA ESPASTICA',
  'G82.5 TETRAPLEGIA NE',
  'G83.0 DIPLEGIA DOS MEMBROS SUPER',
  'G83.1 MONOPLEGIA DO MEMBRO INFER',
  'G83.2 MONOPLEGIA DO MEMBRO SUPER',
  'G83.3 MONOPLEGIA NE',
  'G83.4 SINDR DA CAUDA EQUINA',
  'G83.8 OUTR SINDR PARALITICAS ESPEC',
  'G83.9 SINDR PARALITICA NE',
  'G90.0 NEUROPATIA AUTONOMICA PERIFERICA IDIOPATICA',
  'G90.1 DISAUTONOMIA FAMILIAR',
  'G90.2 SINDR DE HORNER',
  'G90.3 DEGENERACAO MULTISSISTEMICA',
  'G90.8 OUTR TRANST DO SIST NERVOSO AUTONOMO',
  'G90.9 TRANST NE DO SIST NERVOSO AUTONOMO',
  'G91.0 HIDROCEFALIA COMUNICANTE',
  'G91.1 HIDROCEFALIA OBSTRUTIVA',
  'G91.2 HIDROCEFALIA DE PRESSAO NORMAL',
  'G91.3 HIDROCEFALIA POS-TRAUM NE',
  'G91.8 OUTR FORM DE HIDROCEFALIA',
  'G91.9 HIDROCEFALIA NE',
  'G92   ENCEFALOPATIA TOXICA',
  'G93.0 CISTOS CEREBRAIS',
  'G93.1 LESAO ENCEFALICA ANOXICA NCOP',
  'G93.2 HIPERTENSAO INTRACRANIANA BENIGNA',
  'G93.3 SINDR DA FADIGA POS-VIRAL',
  'G93.4 ENCEFALOPATIA NE',
  'G93.5 COMPRESSAO DO ENCEFALO',
  'G93.6 EDEMA CEREBRAL',
  'G93.7 SINDR DE REYE',
  'G93.8 OUTR TRANST ESPEC DO ENCEFALO',
  'G93.9 TRANST NE DO ENCEFALO',
  'G94.0 HIDROCEFALIA EM DOENC INFECC E PARASIT COP',
  'G94.1 HIDROCEFALIA EM DOENC NEOPLASICAS',
  'G94.2 HIDROCEFALIA EM OUTR DOENC COP',
  'G94.8 OUTR TRANST ENCEFALICOS ESPEC EM DOENC COP',
  'G95.0 SIRINGOMIELIA E SIRINGOBULBIA',
  'G95.1 MIELOPATIAS VASCULARES',
  'G95.2 COMPRESSAO NE DE MEDULA ESPINAL',
  'G95.8 OUTR DOENC ESPEC DA MEDULA ESPINAL',
  'G95.9 DOENC NE DA MEDULA ESPINAL',
  'G96.0 FISTULA LIQUORICA',
  'G96.1 TRANST DAS MENINGES NCOP',
  'G96.8 OUTR TRANST ESPEC DO SIST NERVOSO CENTRAL',
  'G96.9 TRANST NE DO SIST NERVOSO CENTRAL',
  'G97.0 PERDA DE LIQUOR RESULTANTE DE PUNCAO ESPINAL',
  'G97.1 OUTR REACAO A PUNCAO ESPINAL E LOMBAR',
  'G97.2 HIPOTENSAO INTRACRAN CONSEQ DERIV VENTRIC',
  'G97.8 OUTR TRANST POS-PROCED DO SIST NERVOSO',
  'G97.9 TRANST POS-PROCED DO SIST NERVOSO NE',
  'G98   OUTR TRANST DO SIST NERVOSO NCOP',
  'G99.0 NEUROPATIA AUTONOMICA DOENC ENDOCR METABOL',
  'G99.1 OUTR TRANST SIST NERVOSO AUTONOMO DOENC COP',
  'G99.2 MIELOPATIA EM DOENC COP',
  'G99.8 OUTR TRANST ESPEC DO SIST NERVOSO DOENC COP',
  'H00.0 HORDEOLO E OUTR INFLAM PROFUNDAS PALPEBRAS',
  'H00.1 CALAZIO',
  'H01.0 BLEFARITE',
  'H01.1 DERMATOSES NAO INFECC DA PALPEBRA',
  'H01.8 OUTR INFLAM ESPEC DA PALPEBRA',
  'H01.9 INFLAM NE DA PALPEBRA',
  'H02.0 ENTROPIO E TRIQUIASE DA PALPEBRA',
  'H02.1 ECTROPIO DA PALPEBRA',
  'H02.2 LAGOFTALMO',
  'H02.3 BLEFAROCALASIA',
  'H02.4 PTOSE DA PALPEBRA',
  'H02.5 OUTR TRANST QUE AFETAM A FUNCAO DA PALPEBRA',
  'H02.6 XANTELASMA DA PALPEBRA',
  'H02.7 OUTR TRANST DEGENER PALPEBRA AREA PERIOCULAR',
  'H02.8 OUTR TRANST ESPEC DAS PALPEBRAS',
  'H02.9 TRANST NE DA PALPEBRA',
  'H03.0 INFESTACAO PARASIT DA PALPEBRA DOENC COP',
  'H03.1 COMPR DA PALPEBRA EM OUTR DOENC INFECC COP',
  'H03.8 COMPR DA PALPEBRA EM OUTR DOENC COP',
  'H04.0 DACRIOADENITE',
  'H04.1 OUTR TRANST DA GLAND LACRIMAL',
  'H04.2 EPIFORA',
  'H04.3 INFLAM AGUDA E NE DOS CANAIS LACRIMAIS',
  'H04.4 INFLAM CRONICA DOS CANAIS LACRIMAIS',
  'H04.5 ESTENOSE E INSUF DOS CANAIS LACRIMAIS',
  'H04.6 OUTR ALTERACOES NOS CANAIS LACRIMAIS',
  'H04.8 OUTR TRANST DO APARELHO LACRIMAL',
  'H04.9 TRANST NE DO APARELHO LACRIMAL',
  'H05.0 INFLAM AGUDA DA ORBITA',
  'H05.1 TRANST INFLAM CRONICOS DA ORBITA',
  'H05.2 AFECCOES EXOFTALMICAS',
  'H05.3 DEFORM DA ORBITA',
  'H05.4 ENOFTALMIA',
  'H05.5 CORPO ESTRANHO RET CONS FERIM PERFUR ORBITA',
  'H05.8 OUTR TRANST DA ORBITA',
  'H05.9 TRANST NE DA ORBITA',
  'H06.0 TRANST DO APARELHO LACRIMAL EM DOENC COP',
  'H06.1 INFESTACAO PARASIT DA ORBITA DOENC COP',
  'H06.2 EXOFTALMO DISTIREOIDEO',
  'H06.3 OUTR TRANST DA ORBITA EM DOENC COP',
  'H10.0 CONJUNTIVITE MUCOPURULENTA',
  'H10.1 CONJUNTIVITE AGUDA ATOPICA',
  'H10.2 OUTR CONJUNTIVITES AGUDAS',
  'H10.3 CONJUNTIVITE AGUDA NE',
  'H10.4 CONJUNTIVITE CRONICA',
  'H10.5 BLEFAROCONJUNTIVITE',
  'H10.8 OUTR CONJUNTIVITES',
  'H10.9 CONJUNTIVITE NE',
  'H11.0 PTERIGIO',
  'H11.1 DEGENERACOES E DEPOSITOS DA CONJUNTIVA',
  'H11.2 CICATRIZES DA CONJUNTIVA',
  'H11.3 HEMORRAGIA CONJUNTIVAL',
  'H11.4 OUTR TRANST VASCULARES E CISTOS CONJUNTIVAIS',
  'H11.8 OUTR TRANST ESPEC DA CONJUNTIVA',
  'H11.9 TRANST NE DA CONJUNTIVA',
  'H13.0 INFESTACAO P/FILARIAS NA CONJUNTIVA',
  'H13.1 CONJUNTIVITE EM DOENC INFECC E PARASIT COP',
  'H13.2 CONJUNTIVITE EM OUTR DOENC COP',
  'H13.3 PENFIGOIDE OCULAR',
  'H13.8 OUTR TRANST DA CONJUNTIVA EM DOENC COP',
  'H15.0 ESCLERITE',
  'H15.1 EPISCLERITE',
  'H15.8 OUTR TRANST DA ESCLEROTICA',
  'H15.9 TRANST NE DA ESCLEROTICA',
  'H16.0 ULCERA DE CORNEA',
  'H16.1 OUTR CERATITES SUPERF S/CONJUNTIVITE',
  'H16.2 CERATOCONJUNTIVITE',
  'H16.3 CERATITES INTERSTICIAL E PROFUNDA',
  'H16.4 NEOVASCULARIZACAO DA CORNEA',
  'H16.8 OUTR CERATITES',
  'H16.9 CERATITE NE',
  'H17.0 LEUCOMA ADERENTE',
  'H17.1 OUTR OPACIDADE CENTRAL DA CORNEA',
  'H17.8 OUTR CICATRIZES E OPACIDADES DA CORNEA',
  'H17.9 CICATRIZ E OPACIDADE NE DA CORNEA',
  'H18.0 PIGMENTACOES E DEPOSITOS DA CORNEA',
  'H18.1 CERATOPATIA BOLHOSA',
  'H18.2 OUTR EDEMAS DA CORNEA',
  'H18.3 ALTERACOES NAS MEMBRANAS DA CORNEA',
  'H18.4 DEGENERACOES DA CORNEA',
  'H18.5 DISTROFIAS HEREDITARIAS DA CORNEA',
  'H18.6 CERATOCONE',
  'H18.7 OUTR DEFORM DA CORNEA',
  'H18.8 OUTR TRANST ESPEC DA CORNEA',
  'H18.9 TRANST NE DA CORNEA',
  'H19.0 ESCLERITE E EPISCLERITE EM DOENC COP',
  'H19.1 CERATITE CERATOCONJUNT VIRUS HERPES SIMPLES',
  'H19.2 CERAT CERATOCONJ OUTR DOENC INFEC PARAS COP',
  'H19.3 CERATITE CERATOCONJUNTIVITE OUTR DOENC COP',
  'H19.8 OUTR TRANST DE ESCLEROTICA CORNEA DOENC COP',
  'H20.0 IRIDOCICLITE AGUDA E SUBAGUDA',
  'H20.1 IRIDOCICLITE CRONICA',
  'H20.2 IRIDOCICLITE INDUZ P/CRISTALINO',
  'H20.8 OUTR IRIDOCICLITES',
  'H20.9 IRIDOCICLITE NE',
  'H21.0 HIFEMA',
  'H21.1 OUTR TRANST VASCULARES IRIS E CORPO CILIAR',
  'H21.2 DEGENERACOES DA IRIS E DO CORPO CILIAR',
  'H21.3 CISTOS IRIS CORPO CILIAR E CAMARA ANTERIOR',
  'H21.4 MEMBRANAS PUPILARES',
  'H21.5 OUTR ADERENCIAS E ROTURAS IRIS CORPO CILIAR',
  'H21.8 OUTR TRANST ESPEC DA IRIS E DO CORPO CILIAR',
  'H21.9 TRANST NE DA IRIS E DO CORPO CILIAR',
  'H22.0 IRIDOCICLITE EM DOENC INFECC E PARASIT COP',
  'H22.1 IRIDOCICLITE EM OUTR DOENC COP',
  'H22.8 OUTR TRANST DA IRIS E CORPO CILIAR DOENC COP',
  'H25.0 CATARATA SENIL INCIPIENTE',
  'H25.1 CATARATA SENIL NUCLEAR',
  'H25.2 CATARATA SENIL TIPO MORGAGNI',
  'H25.8 OUTR CATARATAS SENIS',
  'H25.9 CATARATA SENIL NE',
  'H26.0 CATARATA INFANTIL JUVENIL E PRE-SENIL',
  'H26.1 CATARATA TRAUM',
  'H26.2 CATARATA COMPLICADA',
  'H26.3 CATARATA INDUZ P/DROGAS',
  'H26.4 POS-CATARATA',
  'H26.8 OUTR CATARATAS ESPEC',
  'H26.9 CATARATA NE',
  'H27.0 AFACIA',
  'H27.1 DESLOCAMENTO DO CRISTALINO',
  'H27.8 OUTR TRANST ESPEC DO CRISTALINO',
  'H27.9 TRANST NE DO CRISTALINO',
  'H28.0 CATARATA DIABETICA',
  'H28.1 CATARATA OUTR DOENC ENDOCR NUTRIC E METABOL',
  'H28.2 CATARATA EM OUTR DOENC COP',
  'H28.8 OUTR TRANST DO CRISTALINO EM DOENC COP',
  'H30.0 INFLAM CORIORRETINIANA FOCAL',
  'H30.1 INFLAM CORRORRETINIANA DISSEMINADA',
  'H30.2 CICLITE POSTERIOR',
  'H30.8 OUTR INFLAM CORIORRETINIANAS',
  'H30.9 INFLAM NE DA COROIDE E DA RETINA',
  'H31.0 CICATRIZES CORIORRETINIANAS',
  'H31.1 DEGENERACAO DA COROIDE',
  'H31.2 DISTROFIA HEREDITARIA DA COROIDE',
  'H31.3 HEMORRAGIA E ROTURA DA COROIDE',
  'H31.4 DESCOLAMENTO DA COROIDE',
  'H31.8 OUTR TRANST ESPEC DA COROIDE',
  'H31.9 TRANST NE DA COROIDE',
  'H32.0 INFLAM CORIORRETIN DOENC INFECC PARASIT COP',
  'H32.8 OUTR TRANST CORIORRETINIANOS EM DOENC COP',
  'H33.0 DESCOLAMENTO DA RETINA C/DEFEITO RETINIANO',
  'H33.1 RETINOSQUISE E CISTOS DA RETINA',
  'H33.2 DESCOLAMENTO SEROSO DA RETINA',
  'H33.3 DEFEITOS DA RETINA S/DESCOLAMENTO',
  'H33.4 DESCOLAMENTO DA RETINA P/TRACAO',
  'H33.5 OUTR DESCOLAMENTOS DA RETINA',
  'H34.0 OCLUSAO ARTERIAL RETINIANA TRANSITORIA',
  'H34.1 OCLUSAO DA ARTERIA RETINIANA CENTRAL',
  'H34.2 OUTR OCLUSOES DA ARTERIA RETINIANA',
  'H34.8 OUTR OCLUSOES VASCULARES RETINIANAS',
  'H34.9 OCLUSAO VASCULAR RETINIANA NE',
  'H35.0 RETINOPATIAS DE FUNDO ALTERACOES VASC RETINA',
  'H35.1 RETINOPATIA DA PREMATURIDADE',
  'H35.2 OUTR RETINOPATIAS PROLIFERATIVAS',
  'H35.3 DEGENERACAO DA MACULA E DO POLO POSTERIOR',
  'H35.4 DEGENERACOES PERIFERICAS DA RETINA',
  'H35.5 DISTROFIAS HEREDITARIAS DA RETINA',
  'H35.6 HEMORRAGIA RETINIANA',
  'H35.7 SEPARACAO DAS CAMADAS DA RETINA',
  'H35.8 OUTR TRANST ESPEC DA RETINA',
  'H35.9 TRANST DA RETINA NE',
  'H36.0 RETINOPATIA DIABETICA',
  'H36.8 OUTR TRANST RETINIANOS EM DOENC COP',
  'H40.0 SUSPEITA DE GLAUCOMA',
  'H40.1 GLAUCOMA PRIM DE ANGULO ABERTO',
  'H40.2 GLAUCOMA PRIM DE ANGULO FECHADO',
  'H40.3 GLAUCOMA SECUND A TRAUM OCULAR',
  'H40.4 GLAUCOMA SECUND A INFLAM OCULAR',
  'H40.5 GLAUCOMA SECUND A OUTR TRANST DO OLHO',
  'H40.6 GLAUCOMA SECUND A DROGAS',
  'H40.8 OUTR GLAUCOMA',
  'H40.9 GLAUCOMA NE',
  'H42.0 GLAUCOMA EM DOENC ENDOCR NUTRIC E METAB COP',
  'H42.8 GLAUCOMA EM OUTR DOENC COP',
  'H43.0 PROLAPSO DO HUMOR VITREO',
  'H43.1 HEMORRAGIA DO HUMOR VITREO',
  'H43.2 DEPOSITOS CRISTALINOS NO HUMOR VITREO',
  'H43.3 OUTR OPACIDADES DO VITREO',
  'H43.8 OUTR TRANST DO HUMOR VITREO',
  'H43.9 TRANST NE DO HUMOR VITREO',
  'H44.0 ENDOFTALMITE PURULENTA',
  'H44.1 OUTR ENDOFTALMITES',
  'H44.2 MIOPIA DEGENERATIVA',
  'H44.3 OUTR TRANST DEGENERATIVOS DO GLOBO OCULAR',
  'H44.4 HIPOTONIA DO OLHO',
  'H44.5 AFECCOES DEGENERATIVAS DO GLOBO OCULAR',
  'H44.6 CORPO ESTRANHO RETIDO INTRA-OCULAR NAT MAGN',
  'H44.7 CORPO ESTRANHO RET INTRA-OCULAR NAT NAO-MAGN',
  'H44.8 OUTR TRANST DO GLOBO OCULAR',
  'H44.9 TRANST NE DO GLOBO OCULAR',
  'H45.0 HEMORRAGIA VITREA EM DOENC COP',
  'H45.1 ENDOFTALMITE EM DOENC COP',
  'H45.8 OUTR TRANST HUMOR VITR GLOB OCULAR DOENC COP',
  'H46   NEURITE OPTICA',
  'H47.0 TRANST DO NERVO OPTICO NCOP',
  'H47.1 PAPILEDEMA NE',
  'H47.2 ATROFIA OPTICA',
  'H47.3 OUTR TRANST DO DISCO OPTICO',
  'H47.4 TRANST DO QUIASMA OPTICO',
  'H47.5 TRANST DE OUTR VIAS OPTICAS',
  'H47.6 TRANST DO CORTEX VISUAL',
  'H47.7 TRANST NE DAS VIAS OPTICAS',
  'H48.0 ATROFIA OPTICA EM DOENC COP',
  'H48.1 NEURITE RETROBULBAR EM DOENC COP',
  'H48.8 OUTR TRANST NERVO OPTIC VIAS OPTIC DOENC COP',
  'H49.0 PARALISIA DO TERCEIRO PAR',
  'H49.1 PARALISIA DO QUARTO PAR',
  'H49.2 PARALISIA DO SEXTO PAR',
  'H49.3 OFTALMOPLEGIA TOTAL',
  'H49.4 OFTALMOPLEGIA EXTERNA PROGRESSIVA',
  'H49.8 OUTR ESTRABISMOS PARALITICOS',
  'H49.9 ESTRABISMO PARALITICO NE',
  'H50.0 ESTRABISMO CONVERGENTE CONCOMITANTE',
  'H50.1 ESTRABISMO DIVERGENTE CONCOMITANTE',
  'H50.2 ESTRABISMO VERTICAL',
  'H50.3 HETEROTROPIA INTERMITENTE',
  'H50.4 OUTR HETEROTROPIAS E AS NE',
  'H50.5 HETEROFORIA',
  'H50.6 ESTRABISMO MECANICO',
  'H50.8 OUTR ESTRABISMOS ESPEC',
  'H50.9 ESTRABISMO NE',
  'H51.0 PARALISIA DO OLHAR CONJUGADO',
  'H51.1 INSUF OU EXCESSO DE CONVERGENCIA',
  'H51.2 OFTALMOPLEGIA INTERNUCLEAR',
  'H51.8 OUTR TRANST ESPEC DO MOVIMENTO BINOCULAR',
  'H51.9 TRANST NE DO MOVIMENTO BINOCULAR',
  'H52.0 HIPERMETROPIA',
  'H52.1 MIOPIA',
  'H52.2 ASTIGMATISMO',
  'H52.3 ANISOMETROPIA E ANISEICONIA',
  'H52.4 PRESBIOPIA',
  'H52.5 TRANST DA ACOMODACAO',
  'H52.6 OUTR TRANST DA REFRACAO',
  'H52.7 TRANST NE DA REFRACAO',
  'H53.0 AMBLIOPIA P/ANOPSIA',
  'H53.1 DISTURBIOS VISUAIS SUBJETIVOS',
  'H53.2 DIPLOPIA',
  'H53.3 OUTR TRANST DA VISAO BINOCULAR',
  'H53.4 DEFEITOS DO CAMPO VISUAL',
  'H53.5 DEFIC DA VISAO CROMATICA',
  'H53.6 CEGUEIRA NOTURNA',
  'H53.8 OUTR DISTURBIOS VISUAIS',
  'H53.9 DISTURBIO VISUAL NE',
  'H54.0 CEGUEIRA AMBOS OS OLHOS',
  'H54.1 CEGUEIRA UM OLHO E VISAO SUBNORMAL EM OUTR',
  'H54.2 VISAO SUBNORMAL DE AMBOS OS OLHOS',
  'H54.3 PERDA NAO QUALIFICADA DA VISAO AMBOS OLHOS',
  'H54.4 CEGUEIRA EM UM OLHO',
  'H54.5 VISAO SUBNORMAL EM UM OLHO',
  'H54.6 PERDA NAO QUALIFICADA DA VISAO EM UM OLHO',
  'H54.7 PERDA NE DA VISAO',
  'H55   NISTAGMO E OUTR MOVIMENTOS IRREGULARES OLHO',
  'H57.0 ANOMALIAS DA FUNCAO PUPILAR',
  'H57.1 DOR OCULAR',
  'H57.8 OUTR TRANST ESPEC DO OLHO E ANEXOS',
  'H57.9 TRANST NE DO OLHO E ANEXOS',
  'H58.0 ANOMALIAS DA FUNCAO PUPILAR EM DOENC COP',
  'H58.1 DISTURBIOS VISUAIS EM DOENC COP',
  'H58.8 OUTR TRANST ESPEC DO OLHO E ANEXOS DOENC COP',
  'H59.0 SINDR VITREA SUBSEQ A CIRURGIA DE CATARATA',
  'H59.8 OUTR TRANST DO OLHO E ANEXOS POS-PROCED',
  'H59.9 TRANST NE DO OLHO E ANEXOS POS-PROCED',
  'H60.0 ABSCESSO DO OUVIDO EXTERNO',
  'H60.1 CELULITE DO OUVIDO EXTERNO',
  'H60.2 OTITE EXTERNA MALIGNA',
  'H60.3 OUTR OTITES EXTERNAS INFECC',
  'H60.4 COLESTEATOMA DO OUVIDO EXTERNO',
  'H60.5 OTITE EXTERNA AGUDA NAO-INFECC',
  'H60.8 OUTR OTITES EXTERNAS',
  'H60.9 OTITE EXTERNA NE',
  'H61.0 PERICONDRITE DO PAVILHAO DA ORELHA',
  'H61.1 TRANST NAO-INFECC DO PAVILHAO DA ORELHA',
  'H61.2 CERUME IMPACTADO',
  'H61.3 ESTENOSE ADQUIR DO CONDUTO AUDITIVO EXT',
  'H61.8 OUTR TRANST ESPEC DO OUVIDO EXTERNO',
  'H61.9 TRANST NE DO OUVIDO EXTERNO',
  'H62.0 OTITE EXTERNA EM DOENC BACTER COP',
  'H62.1 OTITE EXTERNA EM DOENC VIRAIS COP',
  'H62.2 OTITE EXTERNA EM MICOSES',
  'H62.3 OTITE EXTERNA OUTR DOENC INFECC PARASIT COP',
  'H62.4 OTITE EXTERNA EM OUTR DOENC COP',
  'H62.8 OUTR TRANST DO OUVIDO EXTERNO EM DOENC COP',
  'H65.0 OTITE MEDIA AGUDA SEROSA',
  'H65.1 OUTR OTITES MEDIAS AGUDAS NAO-SUPURATIVAS',
  'H65.2 OTITE MEDIA SEROSA CRONICA',
  'H65.3 OTITE MEDIA MUCOIDE CRONICA',
  'H65.4 OUTR OTITES MEDIAS CRONICAS NAO-SUPURATIVAS',
  'H65.9 OTITE MEDIA NAO-SUPURATIVA NE',
  'H66.0 OTITE MEDIA AGUDA SUPURATIVA',
  'H66.1 OTITE MEDIA TUBOTIMPANICA SUPURATIVA CRONICA',
  'H66.2 OTITE MEDIA ATICO-ANTRAL SUPURATIVA CRONICA',
  'H66.3 OUTR OTITES MEDIAS SUPURATIVAS CRONICAS',
  'H66.4 OTITE MEDIA SUPURATIVA NE',
  'H66.9 OTITE MEDIA NE',
  'H67.0 OTITE MEDIA EM DOENC BACTER COP',
  'H67.1 OTITE MEDIA EM DOENC VIRAIS COP',
  'H67.8 OTITE MEDIA EM OUTR DOENC COP',
  'H68.0 SALPINGITE DA TROMPA DE EUSTAQUIO',
  'H68.1 OBSTRUCAO DA TROMPA DE EUSTAQUIO',
  'H69.0 TROMPA DE EUSTAQUIO ABERTA',
  'H69.8 OUTR TRANST ESPEC DA TROMPA DE EUSTAQUIO',
  'H69.9 TRANST NE DA TROMPA DE EUSTAQUIO',
  'H70.0 MASTOIDITE AGUDA',
  'H70.1 MASTOIDITE CRONICA',
  'H70.2 PETROSITE',
  'H70.8 OUTR MASTOIDITES E AFECCOES RELAC MASTOIDITE',
  'H70.9 MASTOIDITE NE',
  'H71   COLESTEATOMA DO OUVIDO MEDIO',
  'H72.0 PERFURACAO CENTRAL DA MEMBRANA DO TIMPANO',
  'H72.1 PERFURACAO ATICA DA MEMBRANA DO TIMPANO',
  'H72.2 OUTR PERFURACOES MARGINAIS MEMBRANA TIMPANO',
  'H72.8 OUTR PERFURACOES DA MEMBRANA DO TIMPANO',
  'H72.9 PERFURACAO NE DA MEMBRANA DO TIMPANO',
  'H73.0 MIRINGITE AGUDA',
  'H73.1 MIRINGITE CRONICA',
  'H73.8 OUTR TRANST ESPEC DA MEMBRANA DO TIMPANO',
  'H73.9 TRANST NE DA MEMBRANA DO TIMPANO',
  'H74.0 TIMPANOSCLEROSE',
  'H74.1 DOENC ADESIVA DO OUVIDO MEDIO',
  'H74.2 DESCONTINUIDADE E DESLOC OSSICULOS OUVIDO',
  'H74.3 OUTR ANORM ADQUIR OSSICULOS OUVIDO',
  'H74.4 POLIPO DO OUVIDO MEDIO',
  'H74.8 OUTR TRANST ESPEC DO OUVIDO MEDIO E MASTOIDE',
  'H74.9 TRANST NE DO OUVIDO MEDIO E DA MASTOIDE',
  'H75.0 MASTOIDITE EM DOENC INFECC E PARASIT COP',
  'H75.8 OUTR TRANST ESPEC OUV MED MASTOIDE DOENC COP',
  'H80.0 OTOSCLEROSE Q COMPROM JANELA OVAL NAO-OBLIT',
  'H80.1 OTOSCLEROSE Q COMPROM JANELA OVAL OBLIT',
  'H80.2 OTOSCLEROSE DA COCLEA',
  'H80.8 OUTR OTOSCLEROSES',
  'H80.9 OTOSCLEROSE NE',
  'H81.0 DOENC DE MENIERE',
  'H81.1 VERTIGEM PAROXISTICA BENIGNA',
  'H81.2 NEURONITE VESTIBULAR',
  'H81.3 OUTR VERTIGENS PERIFERICAS',
  'H81.4 VERTIGEM ORIG CENTRAL',
  'H81.8 OUTR TRANST DA FUNCAO VESTIBULAR',
  'H81.9 TRANST NE DA FUNCAO VESTIBULAR',
  'H82   SINDR VERTIGINOSAS EM DOENC COP',
  'H83.0 LABIRINTITE',
  'H83.1 FISTULA DO LABIRINTO',
  'H83.2 DISFUNCAO DO LABIRINTO',
  'H83.3 EFEITOS DO RUIDO SOBRE O OUVIDO INTERNO',
  'H83.8 OUTR TRANST ESPEC DO OUVIDO INTERNO',
  'H83.9 TRANST NE DO OUVIDO INTERNO',
  'H90.0 PERDA AUDICAO BILATERAL DEV TRANST CONDUCAO',
  'H90.1 PERDA AUD UNILAT TRANS COND S/REST AUD CONTR',
  'H90.2 PERDA NE DE AUDICAO DEV TRANST DE CONDUCAO',
  'H90.3 PERDA DE AUDICAO BILATERAL NEURO-SENSORIAL',
  'H90.4 PERDA AUD UNILAT NEURO-SENS S/REST AUD CONTR',
  'H90.5 PERDA DE AUDICAO NEURO-SENSORIAL NE',
  'H90.6 PERDA AUDIC BILAT MISTA CONDUCAO NEURO-SENS',
  'H90.7 PERD AUD UNI MIST COND NEUR S/REST AUD CONTR',
  'H90.8 PERDA AUDICAO MISTA CONDUCAO E NEURO-SENS NE',
  'H91.0 PERDA DE AUDICAO OTOTOXICA',
  'H91.1 PRESBIACUSIA',
  'H91.2 PERDA DE AUDICAO SUBITA IDIOPATICA',
  'H91.3 SURDO-MUDEZ NCOP',
  'H91.8 OUTR PERDAS DE AUDICAO ESPEC',
  'H91.9 PERDA NE DE AUDICAO',
  'H92.0 OTALGIA',
  'H92.1 OTORREIA',
  'H92.2 OTORRAGIA',
  'H93.0 TRANST DEGENERATIVOS E VASCULARES DO OUVIDO',
  'H93.1 TINNITUS',
  'H93.2 OUTR PERCEPCOES AUDITIVAS ANORMAIS',
  'H93.3 TRANST DO NERVO ACUSTICO',
  'H93.8 OUTR TRANST ESPEC DO OUVIDO',
  'H93.9 TRANST NE DO OUVIDO',
  'H94.0 NEURITE ACUSTICA EM DOENC INFECC PARASIT COP',
  'H94.8 OUTR TRANST ESPEC DO OUVIDO EM DOENC COP',
  'H95.0 COLESTEATOMA RECORR CAVIDADE POS-MASTOIDECT',
  'H95.1 OUTR TRANST CONSEQUENTES A MASTOIDECTOMIA',
  'H95.8 OUTR TRANST OUVIDO APOF MASTOIDE POS-PROCED',
  'H95.9 TRANST NE OUVIDO APOFISE MASTOIDE POS-PROCED',
  'I00   FEBRE REUMATICA S/MENCAO DE COMPR DO CORACAO',
  'I01.0 PERICARDITE REUMATICA AGUDA',
  'I01.1 ENDOCARDITE REUMATICA AGUDA',
  'I01.2 MIOCARDITE REUMATICA AGUDA',
  'I01.8 OUTR FORM DE DOENC CARDIACA REUMATICA AGUDA',
  'I01.9 DOENC CARDIACA REUMATICA AGUDA NE',
  'I02.0 COREIA REUMATICA C/COMPR CARDIACO',
  'I02.9 COREIA REUMATICA S/COMPR CARDIACO',
  'I05.0 ESTENOSE MITRAL',
  'I05.1 INSUF MITRAL REUMATICA',
  'I05.2 ESTENOSE MITRAL C/INSUF',
  'I05.8 OUTR DOENC DA VALVA MITRAL',
  'I05.9 DOENC NE DA VALVA MITRAL',
  'I06.0 ESTENOSE AORTICA REUMATICA',
  'I06.1 INSUF AORTICA REUMATICA',
  'I06.2 ESTENOSE AORTICA REUMATICA C/INSUF',
  'I06.8 OUTR DOENC REUMATICAS DA VALVA AORTICA',
  'I06.9 DOENC REUMATICA DA VALVA AORTICA NE',
  'I07.0 ESTENOSE TRICUSPIDE',
  'I07.1 INSUF TRICUSPIDE',
  'I07.2 ESTENOSE TRICUSPIDE C/INSUF',
  'I07.8 OUTR DOENC DA VALVA TRICUSPIDE',
  'I07.9 DOENC NE DA VALVA TRICUSPIDE',
  'I08.0 TRANST DE VALVAS MITRAL E AORTICA',
  'I08.1 TRANST DE AMBAS AS VALVAS MITRAL TRICUSPIDE',
  'I08.2 TRANST DE AMBAS AS VALVAS AORTICA TRICUSPIDE',
  'I08.3 TRANST DAS VALVAS MITRAL AORTICA TRICUSPIDE',
  'I08.8 OUTR DOENC DE MULT VALVAS',
  'I08.9 DOENC NE DE MULT VALVAS',
  'I09.0 MIOCARDITE REUMATICA',
  'I09.1 DOENC REUMATICAS DO ENDOCARDIO VALVA NE',
  'I09.2 PERICARDITE REUMATICA CRONICA',
  'I09.8 OUTR DOENC REUMATICAS ESPEC DO CORACAO',
  'I09.9 DOENC CARDIACA REUMATICA NE',
  'I10   HIPERTENSAO ESSENCIAL',
  'I11.0 DOENC CARDIACA HIPERTENSIVA C/INSUF CARDIACA',
  'I11.9 DOENC CARDIACA HIPERTENSIVA S/INSUF CARDIACA',
  'I12.0 DOENC RENAL HIPERTENSIVA C/INSUF RENAL',
  'I12.9 DOENC RENAL HIPERTENSIVA S/INSUF RENAL',
  'I13.0 DOENC CARDIACA E RENAL HIPERT C/INSUFIC CARD',
  'I13.1 DOENC CARDIACA E RENAL HIPERT C/INSUF RENAL',
  'I13.2 DOENC CARD RENAL HIPERT C/INSUF CARD E RENAL',
  'I13.9 DOENC CARDIACA E RENAL HIPERTENSIVA NE',
  'I15.0 HIPERTENSAO RENOVASCULAR',
  'I15.1 HIPERTENSAO SECUND A OUTR AFECCOES RENAIS',
  'I15.2 HIPERTENSAO SECUND A AFECCOES ENDOCRINAS',
  'I15.8 OUTR FORM DE HIPERTENSAO SECUND',
  'I15.9 HIPERTENSAO SECUND NE',
  'I20.0 ANGINA INSTAVEL',
  'I20.1 ANGINA PECTORIS C/ESPASMO DOCUMENTADO',
  'I20.8 OUTR FORM DE ANGINA PECTORIS',
  'I20.9 ANGINA PECTORIS NE',
  'I21.0 INFARTO AGUDO TRANSMURAL PAREDE ANT MIOCARD',
  'I21.1 INFARTO AGUDO TRANSMURAL PAREDE INF MIOCARD',
  'I21.2 INFARTO AGUDO TRANSMURAL MIOCARDIO OUTR LOC',
  'I21.3 INFARTO AGUDO TRANSMURAL MIOCARDIO LOC NE',
  'I21.4 INFARTO AGUDO SUBENDOCARDICO DO MIOCARDIO',
  'I21.9 INFARTO AGUDO DO MIOCARDIO NE',
  'I22.0 INFARTO DO MIOCARDIO RECORRENTE PAREDE ANT',
  'I22.1 INFARTO DO MIOCARDIO RECORRENTE PAREDE INFER',
  'I22.8 INFARTO DO MIOCARDIO RECORRENTE OUTR LOCALIZ',
  'I22.9 INFARTO DO MIOCARDIO RECORRENTE LOCALIZ NE',
  'I23.0 HEMOPERIC C/COMPL SUBS INFART AGUD MIOCARD',
  'I23.1 COM INTERATR C/COMP ATUAL SUBS INF AGUD MIOC',
  'I23.2 COM INTERVEN C/COMP ATUAL SUBS INF AGUD MIOC',
  'I23.3 RUPT PAR COR S/HEMOP C/COMP SUBS INF AG MIOC',
  'I23.4 RUPT CORD TEND C/COMP ATUAL SUBS INF AG MIOC',
  'I23.5 RUPT MUSC PAP C/COMP ATUAL SUBS INF AG MIOC',
  'I23.6 TROMB ATR AUR/VENT C/COMP AT SUB INF AG MIOC',
  'I23.8 OUTR COMPLIC ATUAIS SUBS INFART AGUD MIOCARD',
  'I24.0 TROMB CORONARIA Q N RESULT INFARTO MIOCARDIO',
  'I24.1 SINDR DE DRESSLER',
  'I24.8 OUTR FORM DOENC ISQUEMICA AGUDA DO CORACAO',
  'I24.9 DOENC ISQUEMICA AGUDA DO CORACAO NE',
  'I25.0 DOENC CARDIOVASC ATEROSCLER DESCR DESTE MODO',
  'I25.1 DOENC ATEROSCLEROTICA DO CORACAO',
  'I25.2 INFARTO ANTIGO DO MIOCARDIO',
  'I25.3 ANEURISMA CARDIACO',
  'I25.4 ANEURISMA DE ARTERIA CORONARIA',
  'I25.5 MIOCARDIOPATIA ISQUEMICA',
  'I25.6 ISQUEMIA MIOCARDICA SILENCIOSA',
  'I25.8 OUTR FORM DE DOENC ISQUEMICA CRONICA CORACAO',
  'I25.9 DOENC ISQUEMICA CRONICA DO CORACAO NE',
  'I26.0 EMBOLIA PULMONAR C/MENC COR PULMONALE AGUDO',
  'I26.9 EMBOLIA PULMONAR S/MENC COR PULMONALE AGUDO',
  'I27.0 HIPERTENSAO PULMONAR PRIM',
  'I27.1 CARDIOPATIA CIFOESCOLIOTICA',
  'I27.2 OUTRA HIPERTENSAO PULMONAR SECUNDARIA',
  'I27.8 OUTR DOENC PULMONARES DO CORACAO ESPEC',
  'I27.9 CARDIOPATIA PULMONAR NE',
  'I28.0 FISTULA ARTERIOVENOSA DE VASOS PULMONARES',
  'I28.1 ANEURISMA DA ARTERIA PULMONAR',
  'I28.8 OUTR DOENC ESPEC DOS VASOS PULMONARES',
  'I28.9 DOENC NE DOS VASOS PULMONARES',
  'I30.0 PERICARDITE AGUDA IDIOPATICA NAO ESPECIFICA',
  'I30.1 PERICARDITE INFECC',
  'I30.8 OUTR FORM DE PERICARDITE AGUDA',
  'I30.9 PERICARDITE AGUDA NE',
  'I31.0 PERICARDITE ADESIVA CRONICA',
  'I31.1 PERICARDITE CONSTRITIVA CRONICA',
  'I31.2 HEMOPERICARDIO NCOP',
  'I31.3 DERRAME PERICARDICO',
  'I31.8 OUTR DOENC ESPEC DO PERICARDIO',
  'I31.9 DOENC NE DO PERICARDIO',
  'I32.0 PERICARDITE EM DOENC BACTER COP',
  'I32.1 PERICARDITE OUTR DOENC INFECC E PARASIT COP',
  'I32.8 PERICARDITE EM OUTR DOENC COP',
  'I33.0 ENDOCARDITE INFECC AGUDA E SUBAGUDA',
  'I33.9 ENDOCARDITE AGUDA NE',
  'I34.0 INSUF MITRAL',
  'I34.1 PROLAPSO MITRAL',
  'I34.2 ESTENOSE MITRAL NAO-REUMATICA',
  'I34.8 OUTR TRANST NAO-REUMATICOS DA VALVA MITRAL',
  'I34.9 TRANST NAO-REUMATICOS DA VALVA MITRAL NE',
  'I35.0 ESTENOSE AORTICA',
  'I35.1 INSUF AORTICA',
  'I35.2 ESTENOSE AORTICA C/INSUF',
  'I35.8 OUTR TRANST DA VALVA AORTICA',
  'I35.9 TRANST NE DA VALVA AORTICA',
  'I36.0 ESTENOSE TRICUSPIDE NAO-REUMATICA',
  'I36.1 INSUF TRICUSPIDE NAO-REUMATICA',
  'I36.2 ESTENOSE TRICUSPIDE C/INSUF NAO-REUMATICA',
  'I36.8 OUTR TRANST NAO-REUMATICOS VALVA TRICUSPIDE',
  'I36.9 TRANST NAO-REUMATICOS DA VALVA TRICUSPIDE NE',
  'I37.0 ESTENOSE DA VALVA PULMONAR',
  'I37.1 INSUF DA VALVA PULMONAR',
  'I37.2 ESTENOSE DA VALVA PULMONAR C/INSUF',
  'I37.8 OUTR TRANST DA VALVA PULMONAR',
  'I37.9 TRANST NE DA VALVA PULMONAR',
  'I38   ENDOCARDITE DE VALVA NE',
  'I39.0 TRANST DA VALVA MITRAL EM DOENC COP',
  'I39.1 TRANST DA VALVA AORTICA EM DOENC COP',
  'I39.2 TRANST DA VALVA TRICUSPIDE EM DOENC COP',
  'I39.3 TRANST DA VALVA PULMONAR EM DOENC COP',
  'I39.4 TRANST DE MULT VALVAS EM DOENC COP',
  'I39.8 ENDOCARDITE DE VALVA NE EM DOENC COP',
  'I40.0 MIOCARDITE INFECC',
  'I40.1 MIOCARDITE ISOLADA',
  'I40.8 OUTR MIOCARDITES AGUDAS',
  'I40.9 MIOCARDITE AGUDA NE',
  'I41.0 MIOCARDITE EM DOENC BACTER COP',
  'I41.1 MIOCARDITE EM DOENC VIRAIS COP',
  'I41.2 MIOCARDITE OUTR DOENC INFECC E PARASIT COP',
  'I41.8 MIOCARDITE EM OUTR DOENC COP',
  'I42.0 CARDIOMIOPATIA DILATADA',
  'I42.1 CARDIOMIOPATIA OBSTRUTIVA HIPERTROFICA',
  'I42.2 OUTR CARDIOMIOPATIAS HIPERTROFICAS',
  'I42.3 DOENC ENDOMIOCARDICA',
  'I42.4 FIBROELASTOSE ENDOCARDICA',
  'I42.5 OUTR CARDIOMIOPATIAS RESTRITIVAS',
  'I42.6 CARDIOMIOPATIA ALCOOLICA',
  'I42.7 CARDIOMIOPATIA DEV DROGAS E OUTR AGENTES EXT',
  'I42.8 OUTR CARDIOMIOPATIAS',
  'I42.9 CARDIOMIOPATIA NE',
  'I43.0 CARDIOMIOPATIA EM DOENC INFECC E PARASIT COP',
  'I43.1 CARDIOMIOPATIA EM DOENC METABOLICAS',
  'I43.2 CARDIOMIOPATIA EM DOENC NUTRICIONAIS',
  'I43.8 CARDIOMIOPATIA EM OUTR DOENC COP',
  'I44.0 BLOQUEIO ATRIOVENTRICULAR DE 1.GRAU',
  'I44.1 BLOQUEIO ATRIOVENTRICULAR DE 2.GRAU',
  'I44.2 BLOQUEIO ATRIOVENTRICULAR TOTAL',
  'I44.3 OUTR FORM DE BLOQUEIO ATRIOVENTRICULAR E NE',
  'I44.4 BLOQUEIO DO FASCICULO ANTERIOR ESQUERDO',
  'I44.5 BLOQUEIO DO FASCICULO POSTERIOR ESQUERDO',
  'I44.6 OUTR FORM DE BLOQUEIO FASCICULAR E AS NE',
  'I44.7 BLOQUEIO DE RAMO ESQUERDO NE',
  'I45.0 BLOQUEIO FASCICULAR DIREITO',
  'I45.1 OUTR FORM DE BLOQUEIO DE RAMO DIREITO E NE',
  'I45.2 BLOQUEIO BIFASCICULAR',
  'I45.3 BLOQUEIO TRIFASCICULAR',
  'I45.4 BLOQUEIO INTRAVENTRICULAR NAO ESPECIFICO',
  'I45.5 OUTR FORM ESPEC DE BLOQUEIO CARDIACO',
  'I45.6 SINDR DE PRE-EXCITACAO',
  'I45.8 OUTR TRANST ESPEC DA CONDUCAO',
  'I45.9 TRANST DE CONDUCAO NE',
  'I46.0 PARADA CARDIACA C/RESSUSCITACAO BEM SUCEDIDA',
  'I46.1 MORTE SUBITA CARDIACA DESCRITA DESTA FORM',
  'I46.9 PARADA CARDIACA NE',
  'I47.0 ARRITMIA VENTRICULAR P/REENTRADA',
  'I47.1 TAQUICARDIA SUPRAVENTRICULAR',
  'I47.2 TAQUICARDIA VENTRICULAR',
  'I47.9 TAQUICARDIA PAROXISTICA NE',
  'I48   FLUTTER E FIBRILACAO ATRIAL',
  'I49.0 FLUTTER E FIBRILACAO VENTRICULAR',
  'I49.1 DESPOLARIZACAO ATRIAL PREMATURA',
  'I49.2 DESPOLARIZACAO JUNCIONAL PREMATURA',
  'I49.3 DESPOLARIZACAO VENTRICULAR PREMATURA',
  'I49.4 OUTR FORM DE DESPOLARIZACAO PREMATURA E NE',
  'I49.5 SINDR DO NO SINUSAL',
  'I49.8 OUTR ARRITMIAS CARDIACAS ESPEC',
  'I49.9 ARRITMIA CARDIACA NE',
  'I50.0 INSUF CARDIACA CONGESTIVA',
  'I50.1 INSUF VENTRICULAR ESQUERDA',
  'I50.9 INSUF CARDIACA NE',
  'I51.0 DEFEITO ADQUIR DE SEPTO CARDIACO',
  'I51.1 RUPTURA DE CORDOALHA TENDINEA NCOP',
  'I51.2 RUPTURA DE MUSCULO PAPILAR NCOP',
  'I51.3 TROMBOSE INTRACARDIACA NCOP',
  'I51.4 MIOCARDITE NE',
  'I51.5 DEGENERACAO MIOCARDICA',
  'I51.6 DOENC CARDIOVASCULAR NE',
  'I51.7 CARDIOMEGALIA',
  'I51.8 OUTR DOENC MAL DEFINIDAS DO CORACAO',
  'I51.9 DOENC NE DO CORACAO',
  'I52.0 OUTR AFECCOES CARDIACAS EM DOENC BACTER COP',
  'I52.1 OUTR AFECC CARD OUTR DOENC INFEC PARASIT COP',
  'I52.8 OUTR AFECCOES CARDIACAS EM OUTR DOENC COP',
  'I60.0 HEMORRAGIA SUBARAC PROV SIFAO BIFURC CAROTID',
  'I60.1 HEMORRAGIA SUBARAC PROV ARTERIA CEREBR MEDIA',
  'I60.2 HEMORRAGIA SUBARAC PROV ARTERIA COMUNIC ANT',
  'I60.3 HEMORRAGIA SUBARAC PROV ARTERIA COMUNIC POST',
  'I60.4 HEMORRAGIA SUBARAC PROV ARTERIA BASILAR',
  'I60.5 HEMORRAGIA SUBARAC PROV ARTERIA VERTEBRAL',
  'I60.6 HEMORRAGIA SUBARAC PROV OUTR ARTER INTRACRAN',
  'I60.7 HEMORRAGIA SUBARAC PROV ARTERIA INTRACRAN NE',
  'I60.8 OUTR HEMORRAGIAS SUBARACNOIDES',
  'I60.9 HEMORRAGIA SUBARACNOIDE NE',
  'I61.0 HEMORRAGIA INTRACEREBR HEMISF SUBCORTICAL',
  'I61.1 HEMORRAGIA INTRACEREBR HEMISFERICA CORTICAL',
  'I61.2 HEMORRAGIA INTRACEREBRAL HEMISFERICA NE',
  'I61.3 HEMORRAGIA INTRACEREBRAL DO TRONCO CEREBRAL',
  'I61.4 HEMORRAGIA INTRACEREBRAL CEREBELAR',
  'I61.5 HEMORRAGIA INTRACEREBRAL INTRAVENTRICULAR',
  'I61.6 HEMORRAGIA INTRACEREBRAL DE MULT LOCALIZ',
  'I61.8 OUTR HEMORRAGIAS INTRACEREBRAIS',
  'I61.9 HEMORRAGIA INTRACEREBRAL NE',
  'I62.0 HEMORRAGIA SUBDURAL',
  'I62.1 HEMORRAGIA EXTRADURAL NAO-TRAUM',
  'I62.9 HEMORRAGIA INTRACRANIANA NE',
  'I63.0 INFARTO CEREBR DEV TROMB ARTERIAS PRE-CEREBR',
  'I63.1 INFARTO CEREBR DEV EMBOL ARTERIAS PRE-CEREBR',
  'I63.2 INF CER DEV OCLUSAO ESTEN NE ART PRE-CEREBR',
  'I63.3 INFARTO CEREBR DEV TROMBOSE ARTERIAS CEREBR',
  'I63.4 INFARTO CEREBRAL DEV EMBOLIA ARTERIAS CEREBR',
  'I63.5 INF CER DEV OCLUSAO ESTENOSE NE ART CEREBR',
  'I63.6 INF CEREBR DEV TROMB VEN CEREBR NAO-PIOGENIC',
  'I63.8 OUTR INFARTOS CEREBRAIS',
  'I63.9 INFARTO CEREBRAL NE',
  'I64   ACID VASC CEREBR NE COMO HEMORRAG ISQUEMICO',
  'I65.0 OCLUSAO E ESTENOSE DA ARTERIA VERTEBRAL',
  'I65.1 OCLUSAO E ESTENOSE DA ARTERIA BASILAR',
  'I65.2 OCLUSAO E ESTENOSE DA ARTERIA CAROTIDA',
  'I65.3 OCLUSAO/ESTENOSE ART PRE-CEREBR MULT BILAT',
  'I65.8 OCLUSAO E ESTENOSE OUTR ARTERIA PRE-CEREBRAL',
  'I65.9 OCLUSAO E ESTENOSE ARTERIAS PRE-CEREBRAIS NE',
  'I66.0 OCLUSAO E ESTENOSE DA ARTERIA CEREBRAL MEDIA',
  'I66.1 OCLUSAO E ESTENOSE ARTERIA CEREBRAL ANTERIOR',
  'I66.2 OCLUSAO E ESTENOSE ARTERIA CEREBRAL POST',
  'I66.3 OCLUSAO E ESTENOSE DE ARTERIAS CEREBELARES',
  'I66.4 OCLUSAO/ESTENOSE ARTERIAS CEREBR MULT BILAT',
  'I66.8 OCLUSAO E ESTENOSE DE OUTR ARTERIA CEREBRAL',
  'I66.9 OCLUSAO E ESTENOSE DE ARTERIA CEREBRAL NE',
  'I67.0 DISSECCAO DE ARTERIAS CEREBRAIS S/RUPTURA',
  'I67.1 ANEURISMA CEREBRAL NAO-ROTO',
  'I67.2 ATEROSCLEROSE CEREBRAL',
  'I67.3 LEUCOENCEFALOPATIA VASCULAR PROGRESSIVA',
  'I67.4 ENCEFALOPATIA HIPERTENSIVA',
  'I67.5 DOENC DE MOYAMOYA',
  'I67.6 TROMBOSE NAO-PIOGENICA SIST VENOSO INTRACRAN',
  'I67.7 ARTERITE CEREBRAL NCOP',
  'I67.8 OUTR DOENC CEREBROVASCULARES ESPEC',
  'I67.9 DOENC CEREBROVASCULAR NE',
  'I68.0 ANGIOPATIA CEREBRAL AMILOIDOTICA',
  'I68.1 ARTERITE CEREBRAL EM DOENC INFECC E PARASIT',
  'I68.2 ARTERITE CEREBRAL EM OUTR DOENC COP',
  'I68.8 OUTR TRANST CEREBROVASCULARES EM DOENC COP',
  'I69.0 SEQUELAS DE HEMORRAGIA SUBARANOIDEA',
  'I69.1 SEQUELAS DE HEMORRAGIA INTRACEREBRAL',
  'I69.2 SEQUELAS OUTR HEMORRAG INTRACRAN NAO TRAUM',
  'I69.3 SEQUELAS DE INFARTO CEREBRAL',
  'I69.4 SEQUELAS ACID VASC CEREBR NE C/HEMORR ISQUEM',
  'I69.8 SEQUELAS OUTR DOENC CEREBROVASCULARES E NE',
  'I70.0 ATEROSCLEROSE DA AORTA',
  'I70.1 ATEROSCLEROSE DA ARTERIA RENAL',
  'I70.2 ATEROSCLEROSE DAS ARTERIAS DAS EXTREMIDADES',
  'I70.8 ATEROSCLEROSE DE OUTR ARTERIAS',
  'I70.9 ATEROSCLEROSE GENERALIZADA E A NE',
  'I71.0 ANEURISMA DISSECANTE DA AORTA',
  'I71.1 ANEURISMA DA AORTA TORACICA ROTO',
  'I71.2 ANEURISMA DA AORTA TORACICA S/MENCAO RUPTURA',
  'I71.3 ANEURISMA DA AORTA ABDOMINAL ROTO',
  'I71.4 ANEURISMA AORTA ABDOMINAL S/MENCAO RUPTURA',
  'I71.5 ANEURISMA DA AORTA TORACO-ABDOMINAL ROTO',
  'I71.6 ANEURISMA AORTA TORACO-ABDOM S/MENC RUPTURA',
  'I71.8 ANEURISMA DA AORTA DE LOCALIZ NE ROTO',
  'I71.9 ANEURISMA AORTICO LOCAL NE S/MENCAO RUPTURA',
  'I72.0 ANEURISMA DA ARTERIA CAROTIDA',
  'I72.1 ANEURISMA DE ARTERIA DOS MEMBROS SUPER',
  'I72.2 ANEURISMA DA ARTERIA RENAL',
  'I72.3 ANEURISMA DE ARTERIA ILIACA',
  'I72.4 ANEURISMA DE ARTERIA DOS MEMBROS INFER',
  'I72.8 ANEURISMA DE OUTR ARTERIAS ESPEC',
  'I72.9 ANEURISMA DE LOCALIZ NE',
  'I73.0 SINDR DE RAYNAUD',
  'I73.1 TROMBOANGEITE OBLITERANTE',
  'I73.8 OUTR DOENC VASCULARES PERIFERICAS ESPEC',
  'I73.9 DOENC VASCULARES PERIFERICAS NE',
  'I74.0 EMBOLIA E TROMBOSE DA AORTA ABDOMINAL',
  'I74.1 EMBOLIA TROMBOSE OUTR PORCOES DA AORTA E NE',
  'I74.2 EMBOLIA E TROMBOSE DE ARTERIAS MEMBROS SUPER',
  'I74.3 EMBOLIA E TROMBOSE DE ARTERIAS MEMBROS INFER',
  'I74.4 EMBOLIA E TROMBOSE DE ARTERIAS MEMBROS NE',
  'I74.5 EMBOLIA E TROMBOSE DA ARTERIA ILIACA',
  'I74.8 EMBOLIA E TROMBOSE DE OUTR ARTERIAS',
  'I74.9 EMBOLIA E TROMBOSE DE ARTERIA NE',
  'I77.0 FISTULA ARTERIOVENOSA ADQUIR',
  'I77.1 ESTENOSE DE ARTERIA',
  'I77.2 RUPTURA DE ARTERIA',
  'I77.3 DISPLASIA FIBROMUSCULAR ARTERIAL',
  'I77.4 SINDR DE COMPRESSAO DA ARTERIA CELIACA',
  'I77.5 NECROSE DE ARTERIA',
  'I77.6 ARTERITE NE',
  'I77.8 OUTR AFECCOES ESPEC ARTERIAS E ARTERIOLAS',
  'I77.9 AFECCOES DE ARTERIAS E ARTERIOLAS NE',
  'I78.0 TELANGIECTASIA HEMORRAGICA HEREDITARIA',
  'I78.1 NEVO NAO-NEOPLASICO',
  'I78.8 OUTR DOENC DOS CAPILARES',
  'I78.9 DOENC NE DOS CAPILARES',
  'I79.0 ANEURISMA DA AORTA EM DOENC COP',
  'I79.1 AORTITE EM DOENC COP',
  'I79.2 ANGIOPATIA PERIFERICA EM DOENC COP',
  'I79.8 OUTR TRANST ARTER ARTERIOLAS CAPIL DOENC COP',
  'I80.0 FLEBITE TROMBOFLEBITE VASOS SUPERF MEMBR INF',
  'I80.1 FLEBITE E TROMBOFLEBITE DA VEIA FEMURAL',
  'I80.2 FLEBITE TROMBOFLEB OUTR VASOS PROF MEMBR INF',
  'I80.3 FLEBITE E TROMBOFLEBITE DOS MEMBROS INFER NE',
  'I80.8 FLEBITE E TROMBOFLEBITE DE OUTR LOCALIZ',
  'I80.9 FLEBITE E TROMBOFLEBITE DE LOCALIZ NE',
  'I81   TROMBOSE DA VEIA PORTA',
  'I82.0 SINDR DE BUDD-CHIARI',
  'I82.1 TROMBOFLEBITE MIGRATORIA',
  'I82.2 EMBOLIA E TROMBOSE DE VEIA CAVA',
  'I82.3 EMBOLIA E TROMBOSE DE VEIA RENAL',
  'I82.8 EMBOLIA E TROMBOSE DE OUTR VEIAS ESPEC',
  'I82.9 EMBOLIA E TROMBOSE VENOSAS DE VEIA NE',
  'I83.0 VARIZES DOS MEMBROS INFER C/ULCERA',
  'I83.1 VARIZES DOS MEMBROS INFER C/INFLAM',
  'I83.2 VARIZES MEMBROS INFER C/ULCERA E INFLAM',
  'I83.9 VARIZES MEMBROS INFER S/ULCERA OU INFLAM',
  'I84.0 HEMORROIDAS INTERNAS TROMBOSADAS',
  'I84.1 HEMORROIDAS INTERNAS C/OUTR COMPLIC',
  'I84.2 HEMORROIDAS INTERNAS S/COMPLIC',
  'I84.3 HEMORROIDAS EXTERNAS TROMBOSADAS',
  'I84.4 HEMORROIDAS EXTERNAS C/OUTR COMPLIC',
  'I84.5 HEMORROIDAS EXTERNAS S/COMPLIC',
  'I84.6 PLICOMAS HEMORROIDARIOS RESIDUAIS',
  'I84.7 HEMORROIDAS TROMBOSADAS NE',
  'I84.8 HEMORROIDAS NE C/OUTR COMPLIC',
  'I84.9 HEMORROIDAS S/COMPLIC NE',
  'I85.0 VARIZES ESOFAGIANAS SANGRANTES',
  'I85.9 VARIZES ESOFAGIANAS S/SANGRAMENTO',
  'I86.0 VARIZES SUBLINGUAIS',
  'I86.1 VARIZES ESCROTAIS',
  'I86.2 VARIZES PELVICAS',
  'I86.3 VARIZES DA VULVA',
  'I86.4 VARIZES GASTRICAS',
  'I86.8 VARIZES DE OUTR LOCALIZ ESPEC',
  'I87.0 SINDR POS-FLEBITE',
  'I87.1 COMPRESSAO VENOSA',
  'I87.2 INSUF VENOSA',
  'I87.8 OUTR TRANST VENOSOS ESPEC',
  'I87.9 TRANST VENOSO NE',
  'I88.0 LINFADENITE MESENTERICA NAO ESPECIFICA',
  'I88.1 LINFADENITE CRONICA EXCETO A MESENTERICA',
  'I88.8 OUTR LINFADENITES INESPECIFICAS',
  'I88.9 LINFADENITE NE',
  'I89.0 LINFEDEMA NCOP',
  'I89.1 LINFANGITE',
  'I89.8 OUT TRANST N-INF ESPEC VASOS LINF GANGL LINF',
  'I89.9 TRANST NAO-INFECC VASOS LINF GANGL LINF NE',
  'I95.0 HIPOTENSAO IDIOPATICA',
  'I95.1 HIPOTENSAO ORTOSTATICA',
  'I95.2 HIPOTENSAO DEV DROGAS',
  'I95.8 OUTR TIPOS DE HIPOTENSAO',
  'I95.9 HIPOTENSAO NE',
  'I97.0 SINDR POS-CARDIOTOMIA',
  'I97.1 OUTR DISTURB FUNC SUBSEQ CIRURGIA CARDIACA',
  'I97.2 SINDR DO LINFEDEMA POS-MASTECTOMIA',
  'I97.8 OUTR TRANST AP CIRCULAT SUBSEQ PROCED NCOP',
  'I97.9 TRANST APARELHO CIRCULAT SUBSEQ PROCED NE',
  'I98.0 SIFILIS CARDIOVASCULAR',
  'I98.1 TRANST CARDIOVASC OUTR DOENC INF PARASIT COP',
  'I98.2 VARIZES ESOFAGIANAS EM DOENC COP',
  'I98.8 OUTR TRANST ESPEC AP CIRCULAT DOENC COP',
  'I99   OUTR TRANST DO APARELHO CIRCULATORIO E OS NE',
  'J00   NASOFARINGITE AGUDA',
  'J01.0 SINUSITE MAXILAR AGUDA',
  'J01.1 SINUSITE FRONTAL AGUDA',
  'J01.2 SINUSITE ETMOIDAL AGUDA',
  'J01.3 SINUSITE ESFENOIDAL AGUDA',
  'J01.4 PANSINUSITE AGUDA',
  'J01.8 OUTR SINUSITES AGUDAS',
  'J01.9 SINUSITE AGUDA NE',
  'J02.0 FARINGITE ESTREPTOCOCICA',
  'J02.8 FARINGITE AGUDA DEV OUTR MICROORG ESPEC',
  'J02.9 FARINGITE AGUDA NE',
  'J03.0 AMIGDALITE ESTREPTOCOCICA',
  'J03.8 AMIGDALITE AGUDA DEV OUTR MICROORG ESPEC',
  'J03.9 AMIGDALITE AGUDA NE',
  'J04.0 LARINGITE AGUDA',
  'J04.1 TRAQUEITE AGUDA',
  'J04.2 LARINGOTRAQUEITE AGUDA',
  'J05.0 LARINGITE OBSTRUTIVA AGUDA',
  'J05.1 EPIGLOTITE AGUDA',
  'J06.0 LARINGOFARINGITE AGUDA',
  'J06.8 OUTR INFECC AGUDAS VIAS AEREAS SUP LOC MULT',
  'J06.9 INFECC AGUDA DAS VIAS AEREAS SUPER NE',
  'J10.0 INFLUENZA C/PNEUM DEV VIRUS INFLUENZA IDENT',
  'J10.1 INFLUENZ C/OUT MANIF RESP DEV VIR INFL IDENT',
  'J10.8 INFLUENZA C/OUTR MANIF DEV VIRUS INFL IDENT',
  'J11.0 INFLUENZA C/PNEUMONIA DEV VIRUS NAO IDENT',
  'J11.1 INFLUENZA C/OUT MANIF RESP DEV VIRUS N IDENT',
  'J11.8 INFLUENZA C/OUTR MANIF DEV VIRUS NAO IDENT',
  'J12.0 PNEUMONIA DEV ADENOVIRUS',
  'J12.1 PNEUMONIA DEV VIRUS RESPIRAT SINCICIAL',
  'J12.2 PNEUMONIA DEV PARAINFLUENZA',
  'J12.8 OUTR PNEUMONIAS VIRAIS',
  'J12.9 PNEUMONIA VIRAL NE',
  'J13   PNEUMONIA DEV STREPTOCOCCUS PNEUMONIAE',
  'J14   PNEUMONIA DEV HAEMOPHILUS INFUENZAE',
  'J15.0 PNEUMONIA DEV KLEBSIELLA PNEUMONIAE',
  'J15.1 PNEUMONIA DEV PSEUDOMONAS',
  'J15.2 PNEUMONIA DEV STAPHYLOCOCCUS',
  'J15.3 PNEUMONIA DEV STREPTOCOCCUS DO GRUPO B',
  'J15.4 PNEUMONIA DEV OUTR ESTREPTOCOCOS',
  'J15.5 PNEUMONIA DEV ESCHERICHIA COLI',
  'J15.6 PNEUMONIA DEV OUTR BACT AEROBICAS GRAM-NEG',
  'J15.7 PNEUMONIA DEV MYCOPLASMA PNEUMONIAE',
  'J15.8 OUTR PNEUMONIAS BACTER',
  'J15.9 PNEUMONIA BACTER NE',
  'J16.0 PNEUMONIA DEV CLAMIDIAS',
  'J16.8 PNEUMONIA DEV OUTR MICROORG INFECC ESPEC',
  'J17.0 PNEUMONIA EM DOENC BACTER COP',
  'J17.1 PNEUMONIA EM DOENC VIRAIS COP',
  'J17.2 PNEUMONIA EM MICOSES COP',
  'J17.3 PNEUMONIA EM DOENC PARASIT COP',
  'J17.8 PNEUMONIA EM OUTR DOENC COP',
  'J18.0 BRONCOPNEUMONIA NE',
  'J18.1 PNEUMONIA LOBAR NE',
  'J18.2 PNEUMONIA HIPOSTATICA NE',
  'J18.8 OUTR PNEUMONIAS DEV MICROORG NE',
  'J18.9 PNEUMONIA NE',
  'J20.0 BRONQUITE AGUDA DEV MYCOPLASMA PNEUMONIAE',
  'J20.1 BRONQUITE AGUDA DEV HAEMOPHILUS INFLUENZAE',
  'J20.2 BRONQUITE AGUDA DEV ESTREPTOCOCOS',
  'J20.3 BRONQUITE AGUDA DEV VIRUS COXSACKIE',
  'J20.4 BRONQUITE AGUDA DEV VIRUS PARAINFLUENZA',
  'J20.5 BRONQUITE AGUDA DEV VIRUS SINCICIAL RESPIRAT',
  'J20.6 BRONQUITE AGUDA DEV RINOVIRUS',
  'J20.7 BRONQUITE AGUDA DEV ECHOVIRUS',
  'J20.8 BRONQUITE AGUDA DEV OUTR MICROORG ESPEC',
  'J20.9 BRONQUITE AGUDA NE',
  'J21.0 BRONQUIOLITE AGUDA DEV VIRUS SINCICIAL RESP',
  'J21.8 BRONQUIOLITE AGUDA DEV OUTR MICROORG ESPEC',
  'J21.9 BRONQUITE AGUDA NE',
  'J22   INFECC AGUDAS NE DAS VIAS AEREAS INFER',
  'J30.0 RINITE VASOMOTORA',
  'J30.1 RINITE ALERGICA DEV POLEN',
  'J30.2 OUTR RINITES ALERGICAS SAZONAIS',
  'J30.3 OUTR RINITES ALERGICAS',
  'J30.4 RINITE ALERGICA NE',
  'J31.0 RINITE CRONICA',
  'J31.1 NASOFARINGITE CRONICA',
  'J31.2 FARINGITE CRONICA',
  'J32.0 SINUSITE MAXILAR CRONICA',
  'J32.1 SINUSITE FRONTAL CRONICA',
  'J32.2 SINUSITE ETMOIDAL CRONICA',
  'J32.3 SINUSITE ESFENOIDAL CRONICA',
  'J32.4 PANSINUSITE CRONICA',
  'J32.8 OUTR SINUSITES CRONICAS',
  'J32.9 SINUSITE CRONICA NE',
  'J33.0 POLIPO DA CAVIDADE NASAL',
  'J33.1 DEGENERACAO POLIPOIDE DO SEIO PARANASAL',
  'J33.8 OUTR POLIPOS DO SEIO PARANASAL',
  'J33.9 POLIPO NASAL NE',
  'J34.0 ABSCESSO FURUNCULO E ANTRAZ DO NARIZ',
  'J34.1 CISTO E MUCOCELE DO NARIZ E SEIO PARANASAL',
  'J34.2 DESVIO DO SEPTO NASAL',
  'J34.3 HIPERTROFIA DOS CORNETOS NASAIS',
  'J34.8 OUTR TRANST ESPEC NARIZ E SEIOS PARANASAIS',
  'J35.0 AMIGDALITE CRONICA',
  'J35.1 HIPERTROFIA DAS AMIGDALAS',
  'J35.2 HIPERTROFIA DAS ADENOIDES',
  'J35.3 HIPERTROFIA AMIGDALAS C/HIPERTROF ADENOIDES',
  'J35.8 OUTR DOENC CRONICAS AMIGDALAS E ADENOIDES',
  'J35.9 DOENC DAS AMIGDALAS E DAS ADENOIDES NE',
  'J36   ABSCESSO PERIAMIGDALIANO',
  'J37.0 LARINGITE CRONICA',
  'J37.1 LARINGOTRAQUEITE CRONICA',
  'J38.0 PARALISIA DAS CORDAS VOCAIS E DA LARINGE',
  'J38.1 POLIPO DAS CORDAS VOCAIS E DA LARINGE',
  'J38.2 NODULOS DAS CORDAS VOCAIS',
  'J38.3 OUTR DOENC DAS CORDAS VOCAIS',
  'J38.4 EDEMA DA LARINGE',
  'J38.5 ESPASMO DA LARINGE',
  'J38.6 ESTENOSE DA LARINGE',
  'J38.7 OUTR DOENC DA LARINGE',
  'J39.0 ABSCESSO RETROFARINGEO E PARAFARINGEO',
  'J39.1 OUTR ABSCESSOS DA FARINGE',
  'J39.2 OUTR DOENC DA FARINGE',
  'J39.3 REACAO HIPERSENSIB VIAS AEREAS SUPER LOC NE',
  'J39.8 OUTR DOENC ESPEC DAS VIAS AEREAS SUPER',
  'J39.9 DOENC NE DAS VIAS AEREAS SUPER',
  'J40   BRONQUITE NE COMO AGUDA OU CRONICA',
  'J41.0 BRONQUITE CRONICA SIMPLES',
  'J41.1 BRONQUITE CRONICA MUCOPURULENTA',
  'J41.8 BRONQUITE CRON MISTA SIMPLES E MUCOPURULENTA',
  'J42   BRONQUITE CRONICA NE',
  'J43.0 SINDR DE MACLEOD',
  'J43.1 ENFISEMA PANLOBULAR',
  'J43.2 ENFISEMA CENTROLOBULAR',
  'J43.8 OUTR FORM DE ENFISEMA',
  'J43.9 ENFISEMA NE',
  'J44.0 DOEN PULM OBS CRON C/INF RESP AG TR RESP INF',
  'J44.1 DOENC PULMONAR OBSTR CRON C/EXACERB AGUDA NE',
  'J44.8 OUTR FORM ESPEC DOENC PULMONAR OBSTRUT CRON',
  'J44.9 DOENC PULMONAR OBSTRUTIVA CRONICA NE',
  'J45.0 ASMA PREDOM ALERGICA',
  'J45.1 ASMA NAO-ALERGICA',
  'J45.8 ASMA MISTA',
  'J45.9 ASMA NE',
  'J46   ESTADO DE MAL ASMATICO',
  'J47   BRONQUECTASIA',
  'J60   PNEUMOCONIOSE DOS MINEIROS DE CARVAO',
  'J61   PNEUMOCONIOSE DEV AMIANTO OUTR FIBR MINERAIS',
  'J62.0 PNEUMOCONIOSE DEV PO DE TALCO',
  'J62.8 PNEUMOCONIOSE DEV OUTR POEIRAS Q CONT SILICA',
  'J63.0 ALUMINOSE',
  'J63.1 FIBROSE CAUSADA P/BAUXITA',
  'J63.2 BERILIOSE',
  'J63.3 FIBROSE CAUSADA P/GRAFITE',
  'J63.4 SIDEROSE',
  'J63.5 ESTANOSE',
  'J63.8 PNEUMOCONIOSE DEV OUTR POEIRA INORG ESPEC',
  'J64   PNEUMOCONIOSE NE',
  'J65   PNEUMOCONIOSE ASSOC C/TUBERC',
  'J66.0 BISSINOSE',
  'J66.1 DOENC DOS CARDADORES DE LINHO',
  'J66.2 CANABINOSE',
  'J66.8 DOENC VIAS AEREAS DEV OUTR POEIRAS ORG ESPEC',
  'J67.0 PULMAO DE FAZENDEIRO',
  'J67.1 BAGACOSE',
  'J67.2 PULMAO DOS CRIADORES DE PASSAROS',
  'J67.3 SUBEROSE',
  'J67.4 PULMAO DOS TRABALHADORES DO MALTE',
  'J67.5 PULMAO DOS QUE TRABALHAM C/COGUMELOS',
  'J67.6 PULMAO DOS CORTADORES DE CASCA DO BORDO',
  'J67.7 DOENC PULMONAR DEV SIST AR COND UMIDIF AR',
  'J67.8 PNEUMONITES HIPERSENS DEV OUTR POEIRAS ORG',
  'J67.9 PNEUMONITE HIPERSENSIBILID DEV POEIRA ORG NE',
  'J68.0 BRONQUIT PNEUMON DEV PROD QUIM GAS FUMAC VAP',
  'J68.1 EDEMA PULM AGUDO DEV PROD QUIM GAS FUMAC VAP',
  'J68.2 INFL VIA AER SUP DEV PR QUI GAS FUM VAP NCOP',
  'J68.3 OUT AF RESP AG SUBAG DEV PRO QUI GAS FUM VAP',
  'J68.4 AFECC RESP CRON DEV PROD QUIM GAS FUM VAP',
  'J68.8 OUTR AFECC RESP DEV PROD QUIM GAS FUM VAP',
  'J68.9 AFECC RESP NE DEV PROD QUIM GASES FUMAC VAP',
  'J69.0 PNEUMONITE DEV ALIMENTO OU VOMITO',
  'J69.1 PNEUMONITE DEV OLEOS E ESSENCIAS',
  'J69.8 PNEUMONITE DEV OUTR SOLIDOS E LIQUIDOS',
  'J70.0 MANIFESTACOES PULMONARES AGUDAS DEV RADIACAO',
  'J70.1 MANIF PULMONARES CRON E OUTR DEV RADIACAO',
  'J70.2 TRANST PULM INTERSTIC AGUDOS INDUZ P/DROGA',
  'J70.3 TRANST PULM INTERSTIC CRONICOS INDUZ P/DROGA',
  'J70.4 TRANST PULM INTERSTICIAL NE INDUZ P/DROGA',
  'J70.8 AFECC RESPIRAT DEV OUTR AGENT EXTERNOS ESPEC',
  'J70.9 AFECCOES RESPIRAT DEV AGENTES EXTERNOS NE',
  'J80   SINDR DO DESCONFORTO RESPIRAT DO ADULTO',
  'J81   EDEMA PULMONAR NE DE OUTR FORM',
  'J82   EOSINOFILIA PULMONAR NCOP',
  'J84.0 AFECCOES ALVEOLARES E PARIETO-ALVEOLARES',
  'J84.1 OUTR DOENC PULM INTERSTICIAIS C/FIBROSE',
  'J84.8 OUTR DOENC PULMONARES INTERSTICIAIS ESPEC',
  'J84.9 DOENC PULMONAR INTERSTICIAL NE',
  'J85.0 GANGRENA E NECROSE DO PULMAO',
  'J85.1 ABSCESSO DO PULMAO C/PNEUMONIA',
  'J85.2 ABSCESSO DO PULMAO S/PNEUMONIA',
  'J85.3 ABSCESSO DO MEDIASTINO',
  'J86.0 PIOTORAX C/FISTULA',
  'J86.9 PIOTORAX S/FISTULA',
  'J90   DERRAME PLEURAL NCOP',
  'J91   DERRAME PLEURAL EM AFECCOES COP',
  'J92.0 PLACAS PLEURAIS C/PRESENCA DE AMIANTO',
  'J92.9 PLACAS PLEURAIS CEM PRESENCA DE AMIANTO',
  'J93.0 PNEUMOTORAX DE TENSAO ESPONTANEO',
  'J93.1 OUTR FORM DE PNEUMOTORAX ESPONTANEO',
  'J93.8 OUTR TIPOS DE PNEUMOTORAX ESPEC',
  'J93.9 PNEUMOTORAX NE',
  'J94.0 DERRAME QUILOSO OU QUILIFORME',
  'J94.1 FIBROTORAX',
  'J94.2 HEMOTORAX',
  'J94.8 OUTR AFECCOES PLEURAIS ESPEC',
  'J94.9 AFECCAO PLEURAL NE',
  'J95.0 MAU FUNCIONAMENTO DE TRAQUEOSTOMIA',
  'J95.1 INSUF PULMONAR AGUDA SUBSEQ CIRURG TORACICA',
  'J95.2 INSUF PULM AGUDA SUBSEQ CIRURG NAO TORACICA',
  'J95.3 INSUF PULMONAR CRONICA POS-CIRURGICA',
  'J95.4 SINDR DE MENDELSON',
  'J95.5 ESTENOSE SUBGLOTICA POS-PROCED',
  'J95.8 OUTR TRANST RESPIRAT POS-PROCED',
  'J95.9 TRANST RESPIRAT POS-PROCED NE',
  'J96.0 INSUF RESPIRAT AGUDA',
  'J96.1 INSUF RESPIRAT CRONICA',
  'J96.9 INSUF RESPIRAT NE',
  'J98.0 OUTR DOENC DOS BRONQUIOS NCOP',
  'J98.1 COLAPSO PULMONAR',
  'J98.2 ENFISEMA INTERSTICIAL',
  'J98.3 ENFISEMA COMPENSATORIO',
  'J98.4 OUTR TRANST PULMONARES',
  'J98.5 DOENC DO MEDIASTINO NCOP',
  'J98.6 TRANST DO DIAFRAGMA',
  'J98.8 OUTR TRANST RESPIRAT ESPEC',
  'J98.9 TRANST RESPIRAT NE',
  'J99.0 DOENC PULMONAR REUMATOIDE',
  'J99.1 TRANST RESPIRAT OUTR DOENC SIST TEC CONJ COP',
  'J99.8 TRANST RESPIRAT EM OUTR DOENC COP',
  'K00.0 ANODONTIA',
  'K00.1 DENTES SUPRANUMERARIOS',
  'K00.2 ANOMALIAS DO TAMANHO E DA FORM DOS DENTES',
  'K00.3 DENTES MANCHADOS',
  'K00.4 DISTURBIOS NA FORMACAO DOS DENTES',
  'K00.5 ANOMALIAS HEREDITARIAS ESTRUT DENTARIA NCOP',
  'K00.6 DISTURBIOS DA ERUPCAO DENTARIA',
  'K00.7 SINDR DA ERUPCAO DENTARIA',
  'K00.8 OUTR DISTURBIOS DO DESENVOLV DOS DENTES',
  'K00.9 DISTURBIO NE DO DESENVOLV DENTARIO',
  'K01.0 DENTES INCLUSOS',
  'K01.1 DENTES IMPACTADOS',
  'K02.0 CARIES LIMITADAS AO ESMALTE',
  'K02.1 CARIES DA DENTINA',
  'K02.2 CARIE DO CEMENTO',
  'K02.3 CARIES DENTARIAS ESTAVEIS',
  'K02.4 ODONTOCLASIA',
  'K02.8 OUTR CARIES DENTARIAS',
  'K02.9 CARIE DENTARIA SOE',
  'K03.0 ATRITO DENTARIO EXCESSIVO',
  'K03.1 ABRASAO DENTARIA',
  'K03.2 EROSAO DENTARIA',
  'K03.3 REABSORCAO PATOLOGICA DOS DENTES',
  'K03.4 HIPERCEMENTOSE',
  'K03.5 ANCILOSE DENTARIA',
  'K03.6 DEPOSITOS NOS DENTES',
  'K03.7 ALTERAC POS-ERUPTIVAS COR TEC DUROS DENTES',
  'K03.8 OUTR DOENC ESPEC DOS TEC DUROS DOS DENTES',
  'K03.9 DOENC DOS TEC DUROS DOS DENTES NE',
  'K04.0 PULPITE',
  'K04.1 NECROSE DA POLPA',
  'K04.2 DEGENERACAO DA POLPA',
  'K04.3 FORMACAO ANORMAL DE TEC DUROS NA POLPA',
  'K04.4 PERIODONTITE APICAL AGUDA ORIG PULPAR',
  'K04.5 PERIODONTITE APICAL CRONICA',
  'K04.6 ABSCESSO PERIAPICAL C/FISTULA',
  'K04.7 ABSCESSO PERIAPICAL S/FISTULA',
  'K04.8 CISTO RADICULAR',
  'K04.9 OUTR DOENC DA POLPA E TEC PERIAPICAIS E NE',
  'K05.0 GENGIVITE AGUDA',
  'K05.1 GENGIVITE CRONICA',
  'K05.2 PERIODONTITE AGUDA',
  'K05.3 PERIODONTITE CRONICA',
  'K05.4 PERIODONTOSE',
  'K05.5 OUTR DOENC PERIODONTAIS',
  'K05.6 DOENC PERIODONTAL SOE',
  'K06.0 RETRACAO GENGIVAL',
  'K06.1 HIPERPLASIA GENGIVAL',
  'K06.2 LESOES GENG REBORD ALVEOL S/DENT ASSOC TRAUM',
  'K06.8 OUTR TRANST ESPEC GENG REBORD ALVEOL S/DENT',
  'K06.9 TRANST GENGIVA REBORDO ALVEOLAR S/DENTES SOE',
  'K07.0 ANOMALIAS IMPORTANTES TAMANHO DA MANDIBULA',
  'K07.1 ANOMALIAS RELACAO ENTRE MANDIBULA BASE CRAN',
  'K07.2 ANOMALIAS RELACAO ENTRE AS ARCADAS DENTARIAS',
  'K07.3 ANOMALIAS DA POSICAO DOS DENTES',
  'K07.4 MALOCLUSAO NE',
  'K07.5 ANORMALIDADES DENTOFACIAIS FUNCIONAIS',
  'K07.6 TRANST DA ARTICULACAO TEMPOROMANDIBULAR',
  'K07.8 OUTR ANOMALIAS DENTOFACIAIS',
  'K07.9 ANOMALIA DENTOFACIAL SOE',
  'K08.0 EXFOLIACAO DOS DENTES DEV CAUSAS SISTEMICAS',
  'K08.1 PERDA DENT DEV ACID EXTR DOENC PERIODONT LOC',
  'K08.2 ATROFIA DO REBORDO ALVEOLAR S/DENTES',
  'K08.3 RAIZ DENTARIA RETIDA',
  'K08.8 OUTR TRANST ESPEC DENTES ESTRUT SUSTENTACAO',
  'K08.9 TRANST DENTES ESTRUTURAS SUSTENTACAO SOE',
  'K09.0 CISTOS ODONTOGENICOS DE DESENVOLV',
  'K09.1 CISTOS DE DESENVOLV DA REGIAO BUCAL',
  'K09.2 OUTR CISTOS DAS MANDIBULAS',
  'K09.8 OUTR CISTOS DA REGIAO ORAL NCOP',
  'K09.9 CISTOS DA REGIAO ORAL SOE',
  'K10.0 TRANST DO DESENVOLV DOS MAXILARES',
  'K10.1 GRANULOMA CENTRAL DE CELULAS GIGANTES',
  'K10.2 AFECCOES INFLAM DOS MAXILARES',
  'K10.3 ALVEOLITE MAXILAR',
  'K10.8 OUTR DOENC ESPEC DOS MAXILARES',
  'K10.9 DOENC DOS MAXILARES SOE',
  'K11.0 ATROFIA DE GLAND SALIVAR',
  'K11.1 HIPERTROFIA DE GLAND SALIVAR',
  'K11.2 SIALADENITE',
  'K11.3 ABSCESSO DE GLAND SALIVAR',
  'K11.4 FISTULA DE GLAND SALIVAR',
  'K11.5 SIALOLITIASE',
  'K11.6 MUCOCELE DE GLAND SALIVAR',
  'K11.7 ALTERACOES DA SECRECAO SALIVAR',
  'K11.8 OUTR DOENC DAS GLAND SALIVARES',
  'K11.9 DOENC DE GLAND SALIVAR SOE',
  'K12.0 AFTAS BUCAIS RECIDIVANTES',
  'K12.1 OUTR FORM DE ESTOMATITE',
  'K12.2 CELULITE E ABSCESSO DA BOCA',
  'K13.0 DOENC DOS LABIOS',
  'K13.1 MORDEDURA DA MUCOSA DAS BOCHECHAS E LABIOS',
  'K13.2 LEUCOPLAS OUTR AFECC EPITELIO ORAL INCL LING',
  'K13.3 LEUCOPLASIA PILOSA',
  'K13.4 LESOES GRANULOMAT GRANULOMATOID MUCOSA ORAL',
  'K13.5 FIBROSE ORAL SUBMUCOSA',
  'K13.6 HIPERPLASIA IRRITATIVA DA MUCOSA ORAL',
  'K13.7 OUTR LESOES E AS NE DA MUCOSA ORAL',
  'K14.0 GLOSSITE',
  'K14.1 LINGUA GEOGRAFICA',
  'K14.2 GLOSSITE ROMBOIDE MEDIANA',
  'K14.3 HIPERTROFIA DAS PAPILAS LINGUAIS',
  'K14.4 ATROFIA DAS PAPILAS LINGUAIS',
  'K14.5 LINGUA ESCROTAL',
  'K14.6 GLOSSODINIA',
  'K14.8 OUTR DOENC DA LINGUA',
  'K14.9 DOENC DA LINGUA SOE',
  'K20   ESOFAGITE',
  'K21.0 DOENC DE REFLUXO GASTROESOFAGICO C/ESOFAGITE',
  'K21.9 DOENC DE REFLUXO GASTROESOFAGICO S/ESOFAGITE',
  'K22.0 ACALASIA DO CARDIA',
  'K22.1 ULCERA DO ESOFAGO',
  'K22.2 OBSTRUCAO DO ESOFAGO',
  'K22.3 PERFURACAO DO ESOFAGO',
  'K22.4 DISCINESIA DO ESOFAGO',
  'K22.5 DIVERTICULO DO ESOFAGO ADQUIR',
  'K22.6 SINDR LACERACAO HEMORRAGICA GASTROESOFAGICA',
  'K22.8 OUTR DOENC ESPEC DO ESOFAGO',
  'K22.9 DOENC DO ESOFAGO SOE',
  'K23.0 ESOFAGITE TUBERC',
  'K23.1 MEGAESOFAGO NA DOENC DE CHAGAS',
  'K23.8 TRANST DO ESOFAGO EM OUTR DOENC COP',
  'K25.0 AGUDA C/HEMORRAGIA',
  'K25.1 AGUDA C/PERFURACAO',
  'K25.2 AGUDA C/HEMORRAGIA E PERFURACAO',
  'K25.3 AGUDA S/HEMORRAGIA OU PERFURACAO',
  'K25.4 CRONICA OU NE C/HEMORRAGIA',
  'K25.5 CRONICA OU NE C/PERFURACAO',
  'K25.6 CRONICA OU NE C/HEMORRAGIA E PERFURACAO',
  'K25.7 CRONICA S/HEMORRAGIA OU PERFURACAO',
  'K25.9 NE COMO AGUDA CRONICA S/HEMORRAGIA PERFUR',
  'K26.0 AGUDA C/HEMORRAGIA',
  'K26.1 AGUDA C/PERFURACAO',
  'K26.2 AGUDA C/HEMORRAGIA E PERFURACAO',
  'K26.3 AGUDA S/HEMORRAGIA OU PERFURACAO',
  'K26.4 CRONICA OU NE C/HEMORRAGIA',
  'K26.5 CRONICA OU NE C/PERFURACAO',
  'K26.6 CRONICA OU NE C/HEMORRAGIA E PERFURACAO',
  'K26.7 CRONICA S/HEMORRAGIA OU PERFURACAO',
  'K26.9 NE COMO AGUDA CRONICA S/HEMORRAGIA PERFUR',
  'K27.0 AGUDA C/HEMORRAGIA',
  'K27.1 AGUDA C/PERFURACAO',
  'K27.2 AGUDA C/HEMORRAGIA E PERFURACAO',
  'K27.3 AGUDA S/HEMORRAGIA OU PERFURACAO',
  'K27.4 CRONICA OU NE C/HEMORRAGIA',
  'K27.5 CRONICA OU NE C/PERFURACAO',
  'K27.6 CRONICA OU NE C/HEMORRAGIA E PERFURACAO',
  'K27.7 CRONICA S/HEMORRAGIA OU PERFURACAO',
  'K27.9 NE COMO AGUDA CRONICA S/HEMORRAGIA PERFUR',
  'K28.0 AGUDA C/HEMORRAGIA',
  'K28.1 AGUDA C/PERFURACAO',
  'K28.2 AGUDA C/HEMORRAGIA E PERFURACAO',
  'K28.3 AGUDA S/HEMORRAGIA OU PERFURACAO',
  'K28.4 CRONICA OU NE C/HEMORRAGIA',
  'K28.5 CRONICA OU NE C/PERFURACAO',
  'K28.6 CRONICA OU NE C/HEMORRAGIA E PERFURACAO',
  'K28.7 CRONICA S/HEMORRAGIA OU PERFURACAO',
  'K28.9 NE COMO AGUDA CRONICA S/HEMORRAGIA PERFUR',
  'K29.0 GASTRITE HEMORRAGICA AGUDA',
  'K29.1 OUTR GASTRITES AGUDAS',
  'K29.2 GASTRITE ALCOOLICA',
  'K29.3 GASTRITE SUPERF CRONICA',
  'K29.4 GASTRITE ATROFICA CRONICA',
  'K29.5 GASTRITE CRONICA SOE',
  'K29.6 OUTR GASTRITES',
  'K29.7 GASTRITE NE',
  'K29.8 DUODENITE',
  'K29.9 GASTRODUODENITE SOE',
  'K30   DISPEPSIA',
  'K31.0 DILATACAO AGUDA DO ESTOMAGO',
  'K31.1 ESTENOSE PILORICA HIPERTROFICA DO ADULTO',
  'K31.2 ESTENOSE E ESTREITAMENTO AMPULHETA ESTOMAGO',
  'K31.3 ESPASMO DO PILORO NCOP',
  'K31.4 DIVERTICULO GASTRICO',
  'K31.5 OBSTRUCAO DO DUODENO',
  'K31.6 FISTULA DO ESTOMAGO E DO DUODENO',
  'K31.7 POLIPO DO ESTOMAGO E DO DUODENO',
  'K31.8 OUTR DOENC ESPEC DO ESTOMAGO E DO DUODENO',
  'K31.9 DOENC DO ESTOMAGO E DO DUODENO SOE',
  'K35.0 APENDICITE AGUDA C/PERITONITE GENERALIZADA',
  'K35.1 APENDICITE AGUDA C/ABSCESSO PERITONIAL',
  'K35.9 APENDICITE AGUDA SOE',
  'K36   OUTR FORM DE APENDICITE',
  'K37   APENDICITE SOE',
  'K38.0 HIPERPLASIA DO APENDICE',
  'K38.1 CONCRECOES APENDICULARES',
  'K38.2 DIVERTICULO DO APENDICE',
  'K38.3 FISTULA DO APENDICE',
  'K38.8 OUTR DOENC ESPEC DO APENDICE',
  'K38.9 DOENC DO APENDICE SOE',
  'K40.0 HERNIA INGUINAL BILATERAL C/OBSTR S/GANGRENA',
  'K40.1 HERNIA INGUINAL BILATERAL C/GANGRENA',
  'K40.2 HERNIA INGUINAL BILAT S/OBSTR OU GANGRENA',
  'K40.3 HERNIA INGUINAL UNILAT NE C/OBSTR S/GANGRENA',
  'K40.4 HERNIA INGUINAL UNILATERAL OU NE C/GANGRENA',
  'K40.9 HERNIA INGUINAL UNILAT NE S/OBSTR GANGRENA',
  'K41.0 HERNIA FEMORAL BILATERAL C/OBSTR S/GANGRENA',
  'K41.1 HERNIA FEMORAL BILATERAL C/GANGRENA',
  'K41.2 HERNIA FEMORAL BILATERAL S/OBSTR OU GANGRENA',
  'K41.3 HERNIA FEMORAL UNILAT NE C/OBSTR S/GANGRENA',
  'K41.4 HERNIA FEMORAL UNILATERAL OU NE C/GANGRENA',
  'K41.9 HERNIA FEMORAL UNILAT NE S/OBSTR OU GANGRENA',
  'K42.0 HERNIA UMBILICAL C/OBSTRUCAO S/GANGRENA',
  'K42.1 HERNIA UMBILICAL C/GANGRENA',
  'K42.9 HERNIA UMBILICAL S/OBSTRUCAO OU GANGRENA',
  'K43.0 HERNIA VENTRAL C/OBSTRUCAO S/GANGRENA',
  'K43.1 HERNIA VENTRAL C/GANGRENA',
  'K43.9 HERNIA VENTRAL S/OBSTRUCAO OU GANGRENA',
  'K44.0 HERNIA DIAFRAGMATICA C/OBSTRUCAO S/GANGRENA',
  'K44.1 HERNIA DIAFRAGMATICA C/GANGRENA',
  'K44.9 HERNIA DIAFRAGMATICA S/OBSTRUCAO OU GANGRENA',
  'K45.0 OUTR HERNIAS ABDOM ESPEC C/OBSTR S/GANGRENA',
  'K45.1 OUTR HERNIAS ABDOMINAIS ESPEC COM GANGRENA',
  'K45.8 OUTR HERNIAS ABDOM ESPEC S/OBSTR OU GANGRENA',
  'K46.0 HERNIA ABDOMINAL NE C/OBSTRUCAO S/GANGRENA',
  'K46.1 HERNIA ABDOMINAL NE C/GANGRENA',
  'K46.9 HERNIA ABDOMINAL NE S/OBSTRUCAO OU GANGRENA',
  'K50.0 DOENC DE CROHN DO INTESTINO DELGADO',
  'K50.1 DOENC DE CROHN DO INTESTINO GROSSO',
  'K50.8 OUTR FORM DE DOENC DE CROHN',
  'K50.9 DOENC DE CROHN DE LOCALIZ NE',
  'K51.0 ENTEROCOLITE ULCERATIVA',
  'K51.1 ILEOCOLITE ULCERATIVA',
  'K51.2 PROCTITE ULCERATIVA',
  'K51.3 RETOSSIGMOIDITE ULCERATIVA',
  'K51.4 PSEUDOPOLIPOSE DO COLON',
  'K51.5 PROCTOCOLITE MUCOSA',
  'K51.8 OUTR COLITES ULCERATIVAS',
  'K51.9 COLITE ULCERATIVA SOE',
  'K52.0 GASTROENTERITE E COLITE DEV RADIACAO',
  'K52.1 GASTROENTERITE E COLITE TOXICAS',
  'K52.2 GASTROENTERITE COLITE ALERG LIGADAS A DIETA',
  'K52.8 OUTR GASTROENTERITES E COLITES ESPEC NAO-INF',
  'K52.9 GASTROENTERITE E COLITE NAO-INFECC NE',
  'K55.0 TRANST VASCULARES AGUDOS DO INTESTINO',
  'K55.1 TRANST VASCULARES CRONICOS DO INTESTINO',
  'K55.2 ANGIODISPLASIA DO COLON',
  'K55.8 OUTR TRANST VASCULARES DO INTESTINO',
  'K55.9 TRANST VASCULAR DO INTESTINO SOE',
  'K56.0 ILEO PARALITICO',
  'K56.1 INTUSSUSCEPCAO',
  'K56.2 VOLVO',
  'K56.3 ILEO BILIAR',
  'K56.4 OUTR OBSTRUCOES DO INTESTINO',
  'K56.5 ADERENCIAS INTESTINAIS C/OBSTRUCAO',
  'K56.6 OUTR FORM DE OBSTRUCAO INTESTINAL E AS NE',
  'K56.7 ILEO NE',
  'K57.0 DOENC DIVERT INTEST DELGADO C/PERFUR ABSCES',
  'K57.1 DOENC DIVERT INTEST DELGADO S/PERFUR ABSCES',
  'K57.2 DOENC DIVERT INTEST GROSSO C/PERFUR ABSCESSO',
  'K57.3 DOENC DIVERT INTEST GROSSO S/PERFUR ABSCESSO',
  'K57.4 DOENC DIVERT INTEST DELG GROSSO C/PERF ABSC',
  'K57.5 DOENC DIVERT INTEST DELG GROSSO S/PERF ABSC',
  'K57.8 DOENC DIVERT INTEST LOCALIZ NE C/PERF ABSC',
  'K57.9 DOENC DIVERT INTEST LOCALIZ NE S/PERF ABSC',
  'K58.0 SINDR DO COLON IRRITAVEL C/DIARREIA',
  'K58.9 SINDR DO COLON IRRITAVEL S/DIARREIA',
  'K59.0 CONSTIPACAO',
  'K59.1 DIARREIA FUNCIONAL',
  'K59.2 COLON NEUROGENICO NCOP',
  'K59.3 MEGACOLON NCOP',
  'K59.4 ESPASMO ANAL',
  'K59.8 OUTR TRANST FUNCIONAIS ESPEC DO INTESTINO',
  'K59.9 TRANST INTESTINAL FUNCIONAL NE',
  'K60.0 FISSURA ANAL AGUDA',
  'K60.1 FISSURA ANAL CRONICA',
  'K60.2 FISSURA ANAL NE',
  'K60.3 FISTULA ANAL',
  'K60.4 FISTULA RETAL',
  'K60.5 FISTULA ANORRETAL',
  'K61.0 ABSCESSO ANAL',
  'K61.1 ABSCESSO RETAL',
  'K61.2 ABSCESSO ANORRETAL',
  'K61.3 ABSCESSO ISQUIO-RETAL',
  'K61.4 ABSCESSO INTRA-ESFINCTERIANO',
  'K62.0 POLIPO ANAL',
  'K62.1 POLIPO RETAL',
  'K62.2 PROLAPSO ANAL',
  'K62.3 PROLAPSO RETAL',
  'K62.4 ESTENOSE DO ANUS E DO RETO',
  'K62.5 HEMORRAGIA DO ANUS E DO RETO',
  'K62.6 ULCERA DO ANUS E DO RETO',
  'K62.7 PROCTITE P/RADIACAO',
  'K62.8 OUTR DOENC ESPEC DO ANUS E DO RETO',
  'K62.9 DOENC DO ANUS E DO RETO SOE',
  'K63.0 ABSCESSO DO INTESTINO',
  'K63.1 PERFURACAO DO INTESTINO',
  'K63.2 FISTULA DO INTESTINO',
  'K63.3 ULCERA DO INTESTINO',
  'K63.4 ENTEROPTOSE',
  'K63.5 POLIPO DO COLON',
  'K63.8 OUTR DOENC ESPEC DO INTESTINO',
  'K63.9 DOENC DO INTESTINO SOE',
  'K65.0 PERITONITE AGUDA',
  'K65.8 OUTR PERITONITES',
  'K65.9 PERITONITE SOE',
  'K66.0 ADERENCIAS PERITONIAIS',
  'K66.1 HEMOPERITONIO',
  'K66.8 OUTR AFECCOES ESPEC DO PERITONIO',
  'K66.9 AFECCOES DO PERITONIO SOE',
  'K67.0 PERITONITE P/CLAMIDIAS',
  'K67.1 PERITONITE GONOCOCICA',
  'K67.2 PERITONITE SIFILITICA',
  'K67.3 PERITONITE TUBERC',
  'K67.8 OUTR FORM COMPR PERITONIO DOENC INFECC COP',
  'K70.0 FIGADO GORDUROSO ALCOOLICO',
  'K70.1 HEPATITE ALCOOLICA',
  'K70.2 FIBROSE E ESCLEROSE ALCOOLICAS DO FIGADO',
  'K70.3 CIRROSE HEPATICA ALCOOLICA',
  'K70.4 INSUF HEPATICA ALCOOLICA',
  'K70.9 DOENC ALCOOLICA DO FIGADO SOE',
  'K71.0 DOENC HEPATICA TOXICA C/COLESTASE',
  'K71.1 DOENC HEPATICA TOXICA C/NECROSE HEPATICA',
  'K71.2 DOENC HEPATICA TOXICA C/HEPATITE AGUDA',
  'K71.3 DOENC HEPATICA TOX C/HEPATITE CRON PERSIST',
  'K71.4 DOENC HEPATICA TOX C/HEPATITE CRON LOBULAR',
  'K71.5 DOENC HEPATICA TOXICA C/HEPATITE CRON ATIVA',
  'K71.6 DOENC HEPATICA TOXICA C/HEPATITE NCOP',
  'K71.7 DOENC HEPAT TOXICA C/FIBROSE E CIRROSE HEPAT',
  'K71.8 DOENC HEPATICA TOXICA C/OUTR TRANST FIGADO',
  'K71.9 DOENC HEPATICA TOXICA SOE',
  'K72.0 INSUF HEPATICA AGUDA E SUBAGUDA',
  'K72.1 INSUF HEPATICA CRONICA',
  'K72.9 INSUF HEPATICA SOE',
  'K73.0 HEPATITE CRONICA PERSISTENTE NCOP',
  'K73.1 HEPATITE CRONICA LOBULAR NCOP',
  'K73.2 HEPATITE CRONICA ATIVA NCOP',
  'K73.8 OUTR HEPATITES CRONICAS NCOP',
  'K73.9 HEPATITE CRONICA SOE',
  'K74.0 FIBROSE HEPATICA',
  'K74.1 ESCLEROSE HEPATICA',
  'K74.2 FIBROSE HEPATICA C/ESCLEROSE HEPATICA',
  'K74.3 CIRROSE BILIAR PRIM',
  'K74.4 CIRROSE BILIAR SECUND',
  'K74.5 CIRROSE BILIAR SOE',
  'K74.6 OUTR FORM DE CIRROSE HEPATICA E AS NE',
  'K75.0 ABSCESSO HEPATICO',
  'K75.1 FLEBITE DA VEIA PORTA',
  'K75.2 HEPATITE REATIVA NAO-ESPECIFICA',
  'K75.3 HEPATITE GRANULOMATOSA NCOP',
  'K75.4 HEPATITE AUTOIMUNE',
  'K75.8 OUTR DOENC HEPATICAS INFLAM ESPEC',
  'K75.9 DOENC HEPATICA INFLAM SOE',
  'K76.0 DEGENERACAO GORDUROSA DO FIGADO NCOP',
  'K76.1 CONGESTAO PASSIVA CRONICA DO FIGADO',
  'K76.2 NECROSE HEMORRAGICA CENTRAL DO FIGADO',
  'K76.3 INFARTO DO FIGADO',
  'K76.4 PELIOSE HEPATICA',
  'K76.5 DOENC HEPATICA VENO-OCLUSIVA',
  'K76.6 HIPERTENSAO PORTAL',
  'K76.7 SINDR HEPATORRENAL',
  'K76.8 OUTR DOENC ESPEC DO FIGADO',
  'K76.9 DOENC HEPATICA SOE',
  'K77.0 TRANST HEPATICOS DOENC INFECC E PARASIT COP',
  'K77.8 TRANST HEPATICOS EM OUTR DOENC COP',
  'K80.0 CALCULOSE VESICULA BILIAR C/COLICISTITE AGUD',
  'K80.1 CALCULOSE VESIC BILIAR C/OUTR FORM COLECIST',
  'K80.2 CALCULOSE DA VESICULA BILIAR S/COLECISTITE',
  'K80.3 CALCULOSE DE VIA BILIAR C/COLANGITE',
  'K80.4 CALCULOSE DE VIA BILIAR C/COLECISTITE',
  'K80.5 CALCULOSE VIA BILIAR S/COLANGITE COLECISTITE',
  'K80.8 OUTR COLELITIASES',
  'K81.0 COLECISTITE AGUDA',
  'K81.1 COLECISTITE CRONICA',
  'K81.8 OUTR COLECISTITES',
  'K81.9 COLECISTITE SOE',
  'K82.0 OBSTRUCAO DA VESICULA BILIAR',
  'K82.1 HIDROPSIA DA VESICULA BILIAR',
  'K82.2 PERFURACAO DA VESICULA BILIAR',
  'K82.3 FISTULA DA VESICULA BILIAR',
  'K82.4 COLESTEROLOSE DA VESICULA BILIAR',
  'K82.8 OUTR DOENC ESPEC DA VESICULA BILIAR',
  'K82.9 DOENC DA VESICULA BILIAR SOE',
  'K83.0 COLANGITE',
  'K83.1 OBSTRUCAO DE VIA BILIAR',
  'K83.2 PERFURACAO DE VIA BILIAR',
  'K83.3 FISTULA DE VIA BILIAR',
  'K83.4 ESPASMO DO ESFINCTER DE ODDI',
  'K83.5 CISTO BILIAR',
  'K83.8 OUTR DOENC ESPEC DAS VIAS BILIARES',
  'K83.9 DOENC DAS VIAS BILIARES SOE',
  'K85   PANCREATITE AGUDA',
  'K86.0 PANCREATITE CRONICA INDUZ P/ALCOOL',
  'K86.1 OUTR PANCREATITES CRONICAS',
  'K86.2 CISTO DO PANCREAS',
  'K86.3 PSEUDOCISTO DO PANCREAS',
  'K86.8 OUTR DOENC ESPEC DO PANCREAS',
  'K86.9 DOENC DO PANCREAS SOE',
  'K87.0 TRANST VESIC BILIAR VIAS BILIARES DOENC COP',
  'K87.1 TRANST DO PANCREAS EM DOENC COP',
  'K90.0 DOENC CELIACA',
  'K90.1 ESPRU TROPICAL',
  'K90.2 SINDR DA ALCA CEGA NCOP',
  'K90.3 ESTEATORREIA PANCREATICA',
  'K90.4 MA-ABSORCAO DEV INTOLERANCIA NCOP',
  'K90.8 OUTR FORM DE MA-ABSORCAO INTESTINAL',
  'K90.9 MA-ABSORCAO INTESTINAL SOE',
  'K91.0 VOMITOS POS-CIRURGIA GASTROINTESTINAL',
  'K91.1 SINDR POS-CIRURGIA GASTRICA',
  'K91.2 MA-ABSORCAO POS-CIRURGICA NCOP',
  'K91.3 OBSTRUCAO INTESTINAL POS-OPERATORIA',
  'K91.4 MAU FUNCIONAMENTO COLOSTOMIA E ENTEROSTOMIA',
  'K91.5 SINDR POS-COLECISTECTOMIA',
  'K91.8 OUTR TRANST APARELHO DIGEST POS-CIRURG NCOP',
  'K91.9 TRANST DO APARELHO DIGESTIVO POS PROCED',
  'K92.0 HEMATEMESE',
  'K92.1 MELENA',
  'K92.2 HEMORRAGIA GASTROINTESTINAL SOE',
  'K92.8 OUTR DOENC ESPEC DO APARELHO DIGESTIVO',
  'K92.9 DOENC DO APARELHO DIGESTIVO SOE',
  'K93.0 TRANST DEV TUBERC INTEST PERIT GANGL MESENT',
  'K93.1 MEGACOLON NA DOENC DE CHAGAS',
  'K93.8 TRANST OUTR ORG DIGESTIVOS ESPEC DOENC COP',
  'L00   SINDR PELE ESCALDADA ESTAFILOCOC RECEM-NASC',
  'L01.0 IMPETIGO',
  'L01.1 IMPETIGINIZACAO DE OUTR DERMATOSES',
  'L02.0 ABSCESSO CUTANEO FURUNCULO E ANTRAZ FACE',
  'L02.1 ABSCESSO CUTANEO FURUNCULO E ANTRAZ PESCOCO',
  'L02.2 ABSCESSO CUTANEO FURUNCULO E ANTRAZ TRONCO',
  'L02.3 ABSCESSO CUTANEO FURUNCULO E ANTRAZ NADEGA',
  'L02.4 ABSCESSO CUTANEO FURUNCULO E ANTRAZ MEMBROS',
  'L02.8 ABSCESSO CUTANEO FURUNCULO E ANTRAZ OUTR LOC',
  'L02.9 ABSCESSO CUTANEO FURUNCULO E ANTRAZ LOC NE',
  'L03.0 CELULITE DE DEDOS DAS MAOS E DOS PES',
  'L03.1 CELULITE DE OUTR PARTES DE MEMBROS',
  'L03.2 CELULITE DA FACE',
  'L03.3 CELULITE DO TRONCO',
  'L03.8 CELULITE DE OUTR LOCAIS',
  'L03.9 CELULITE NE',
  'L04.0 LINFADENITE AGUDA DE FACE CABECA E PESCOCO',
  'L04.1 LINFADENITE AGUDA DO TRONCO',
  'L04.2 LINFADENITE AGUDA DE MEMBRO SUPER',
  'L04.3 LINFADENITE AGUDA DE MEMBRO INFER',
  'L04.8 LINFADENITE AGUDA DE OUTR LOCALIZ',
  'L04.9 LINFADENITE AGUDA DE LOCALIZ NE',
  'L05.0 CISTO PILONIDAL C/ABSCESSO',
  'L05.9 CISTO PILONIDAL S/ABSCESSO',
  'L08.0 PIODERMITE',
  'L08.1 ERITRASMA',
  'L08.8 OUTR INFECC LOCAL ESPEC PELE TEC SUBCUTANEO',
  'L08.9 INFECC LOCALIZ PELE E DO TEC SUBCUTANEO NE',
  'L10.0 PENFIGO VULGAR',
  'L10.1 PENFIGO VEGETANTE',
  'L10.2 PENFIGO FOLIACEO',
  'L10.3 PENFIGO BRASILEIRO',
  'L10.4 PENFIGO ERITEMATOSO',
  'L10.5 PENFIGO INDUZ P/DROGAS',
  'L10.8 OUTR FORM DE PENFIGO',
  'L10.9 PENFIGO NE',
  'L11.0 CERATOSE FOLICULAR ADQUIR',
  'L11.1 DERMATOSE ACANTOLITICA TRANSITORIA',
  'L11.8 OUTR AFECCOES ACANTOLITICAS ESPEC',
  'L11.9 AFECCOES ACANTOLITICAS NE',
  'L12.0 PENFIGOIDE BOLHOSO',
  'L12.1 PENFIGOIDE CICATRICIAL',
  'L12.2 DOENC BOLHOSA CRONICA DA INFANCIA',
  'L12.3 EPIDERMOLISE BOLHOSA ADQUIR',
  'L12.8 OUTR FORM DE PENFIGOIDE',
  'L12.9 PENFIGOIDE NE',
  'L13.0 DERMATITE HERPETIFORME',
  'L13.1 DERMATITE PUSTULAR SUBCORNEANA',
  'L13.8 OUTR AFECCOES BOLHOSAS ESPEC',
  'L13.9 DOENC BOLHOSA NE',
  'L14   AFECCOES BOLHOSAS EM DOENC COP',
  'L20.0 PRURIGO DE BESNIER',
  'L20.8 OUTR DERMATITES ATOPICAS',
  'L20.9 DERMATITE ATOPICA NE',
  'L21.0 SEBORREIA DO COURO CABELUDO',
  'L21.1 DERMATITE SEBORREICA INFANTIL',
  'L21.8 OUTR DERMATITES SEBORREICAS',
  'L21.9 DERMATITE SEBORREICA NE',
  'L22   DERMATITE DAS FRALDAS',
  'L23.0 DERMATITE ALERGICA DE CONTATO DEV METAIS',
  'L23.1 DERMATITE ALERGICA DE CONTATO DEV ADESIVOS',
  'L23.2 DERMATITE ALERGICA DE CONTATO DEV COSMETICOS',
  'L23.3 DERMATITE ALERG CONTATO DEV DROGAS CONT PELE',
  'L23.4 DERMATITE ALERGICA DE CONTATO DEV CORANTES',
  'L23.5 DERMATITE ALERGICA CONTATO DEV OUT PROD QUIM',
  'L23.6 DERMATITE ALERGICA CONTAT DEV ALIM CONT PELE',
  'L23.7 DERMATITE ALERG CONTATO DEV PLANTAS EXC ALIM',
  'L23.8 DERMATITE ALERGICA CONTATO DEV OUTR AGENTES',
  'L23.9 DERMATITE ALERGICA DE CONTATO DE CAUSA NE',
  'L24.0 DERMATITE CONTATO P/IRRIT DEV DETERGENTES',
  'L24.1 DERMATITE CONTATO P/IRRIT DEV OLEOS GORDURAS',
  'L24.2 DERMATITE CONTATO P/IRRITANTES DEV SOLVENTES',
  'L24.3 DERMATITE CONTATO P/IRRIT DEV COSMETICOS',
  'L24.4 DERMATITE CONT P/IRRIT DEV DROGAS CONT PELE',
  'L24.5 DERMATITE CONTATO P/IRRIT DEV OUTR PROD QUIM',
  'L24.6 DERMATITE CONTATO P/IRRIT DEV ALIM CONT PELE',
  'L24.7 DERMATITE CONT P/IRRIT DEV PLANTAS EXC ALIM',
  'L24.8 DERMATITE CONTATO P/IRRIT DEV OUTR AGENTES',
  'L24.9 DERMATITE CONTATO P/IRRITANTES DE CAUSA NE',
  'L25.0 DERMATITE DE CONTATO NE DEV COSMETICOS',
  'L25.1 DERMATITE DE CONTATO NE DEV DROGAS CONT PELE',
  'L25.2 DERMATITE DE CONTATO NE DEV CORANTES',
  'L25.3 DERMATITE DE CONTATO NE DEV OUTR PROD QUIM',
  'L25.4 DERMATITE DE CONTATO NE DEV ALIM CONT PELE',
  'L25.5 DERMATITE DE CONTATO NE DEV PLANTAS EXC ALIM',
  'L25.8 DERMATITE DE CONTATO NE DEV OUTR AGENTES',
  'L25.9 DERMATITE DE CONTATO NE DE CAUSA NE',
  'L26   DERMATITE ESFOLIATIVA',
  'L27.0 ERUPCAO CUTANEA GENERALIZ DEV DROGAS MEDIC',
  'L27.1 ERUPCAO CUTANEA LOCALIZADA DEV DROGAS MEDIC',
  'L27.2 DERMATITE DEV INGESTAO DE ALIMENTOS',
  'L27.8 DERMATITE DEV OUTR SUBST DE USO INTERNO',
  'L27.9 DERMATITE DEV SUBST NE DE USO INTERNO',
  'L28.0 LIQUEN SIMPLES CRONICO',
  'L28.1 PRURIGO NODULAR',
  'L28.2 OUTR FORM DE PRURIGO',
  'L29.0 PRURIDO ANAL',
  'L29.1 PRURIDO ESCROTAL',
  'L29.2 PRURIDO VULVAR',
  'L29.3 PRURIDO ANOGENITAL NE',
  'L29.8 OUTR FORM DE PRURIDO',
  'L29.9 PRURIDO NE',
  'L30.0 DERMATITE NUMULAR',
  'L30.1 DISIDROSE',
  'L30.2 AUTOSSENSIBILIZACAO CUTANEA',
  'L30.3 DERMATITE INFECTADA',
  'L30.4 INTERTRIGO ERITEMATOSO',
  'L30.5 PITIRIASE ALBA',
  'L30.8 OUTR DERMATITES ESPEC',
  'L30.9 DERMATITE NE',
  'L40.0 PSORIASE VULGAR',
  'L40.1 PSORIASE PUSTULOSA GENERALIZADA',
  'L40.2 ACRODERMATITE CONTINUA',
  'L40.3 PUSTULOSE PALMAR E PLANTAR',
  'L40.4 PSORIASE GUTATA',
  'L40.5 ARTROPATIA PSORIASICA',
  'L40.8 OUTR FORM DE PSORIASE',
  'L40.9 PSORIASE NE',
  'L41.0 PITIRIASE LIQUENOIDE E VARIOLIFORME AGUDA',
  'L41.1 PITIRIASE LIQUENOIDE CRONICA',
  'L41.2 PAPULOSE LINFOMATOIDE',
  'L41.3 PARAPSORIASE DE PEQUENAS PLACAS',
  'L41.4 PARAPSORIASE DE GRANDES PLACAS',
  'L41.5 PARAPSORIASE RETIFORME',
  'L41.8 OUTR PARAPSORIASES',
  'L41.9 PARAPSORIASE NE',
  'L42   PITIRIASE ROSEA',
  'L43.0 LIQUEN PLANO HIPERTROFICO',
  'L43.1 LIQUEN PLANO BOLHOSO',
  'L43.2 REACAO LIQUENOIDE A DROGA',
  'L43.3 LIQUEN PLANO SUBAGUDO',
  'L43.8 OUTR FORM DE LIQUEN PLANO',
  'L43.9 LIQUEN PLANO NE',
  'L44.0 PITIRIASE RUBRA PILAR',
  'L44.1 LIQUEN NITIDO',
  'L44.2 LIQUEN ESTRIADO',
  'L44.3 LIQUEN RUBRO MONILIFORME',
  'L44.4 ACRODERMATITE PAPULAR INFANTIL',
  'L44.8 OUTR AFECCOES PAPULO-DESCAMATIVAS ESPEC',
  'L44.9 AFECCOES PAPULO-DESCAMATIVAS NE',
  'L45   AFECCOES PAPULO-DESCAMATIVAS EM DOENC COP',
  'L50.0 URTICARIA ALERGICA',
  'L50.1 URTICARIA IDIOPATICA',
  'L50.2 URTICARIA DEV FRIO E A CALOR',
  'L50.3 URTICARIA DERMATOGRAFICA',
  'L50.4 URTICARIA VIBRATORIA',
  'L50.5 URTICARIA COLINERGICA',
  'L50.6 URTICARIA DE CONTATO',
  'L50.8 OUTR URTICARIAS',
  'L50.9 URTICARIA NE',
  'L51.0 ERITEMA MULTIFORME NAO BOLHOSO',
  'L51.1 ERITEMA MULTIFORME BOLHOSO',
  'L51.2 NECROLISE EPIDERMICA TOXICA',
  'L51.8 OUTR FORM DE ERITEMA MULTIFORME',
  'L51.9 ERITEMA MULTIFORME NE',
  'L52   ERITEMA NODOSO',
  'L53.0 ERITEMA TOXICO',
  'L53.1 ERITEMA ANULAR CENTRIFUGO',
  'L53.2 ERITEMA MARGINADO',
  'L53.3 OUTR FORM DE ERITEMA FIGURADO CRONICO',
  'L53.8 OUTR AFECCOES ERITEMATOSAS ESPEC',
  'L53.9 AFECCOES ERITEMATOSAS NE',
  'L54.0 ERITEMA MARGINADO NA FEBRE REUMATICA AGUDA',
  'L54.8 ERITEMA EM OUTR DOENC COP',
  'L55.0 QUEIM SOLAR DE 1.GRAU',
  'L55.1 QUEIM SOLAR DE 2.GRAU',
  'L55.2 QUEIM SOLAR DE 3.GRAU',
  'L55.8 OUTR QUEIM SOLARES',
  'L55.9 QUEIM SOLAR NE',
  'L56.0 RESPOSTA FOTOTOXICA A DROGAS',
  'L56.1 RESPOSTA FOTOALERGICA A DROGAS',
  'L56.2 DERMATITE P/FOTOCONTATO',
  'L56.3 URTICARIA SOLAR',
  'L56.4 ERUPCAO POLIMORFA A LUZ',
  'L56.8 OUTR ALT AGUD ESP PELE DEV RAD ULTRAVIOLETA',
  'L56.9 ALTERACOES AGUD PELE DEV RAD ULTRAVIOLETA NE',
  'L57.0 CERATOSE ACTINICA',
  'L57.1 RETICULOIDE ACTINICA',
  'L57.2 CUTIS ROMBOIDAL DA NUCA',
  'L57.3 POIQUILODERMIA DE CIVATTE',
  'L57.4 CUTIS LAXA SENIL',
  'L57.5 GRANULOMA ACTINICO',
  'L57.8 OUTR ALT PELE DEV EXPOS CRON RAD NAO-IONIZ',
  'L57.9 ALTER PELE DEV EXPOS CRON RAD NAO-IONIZ NE',
  'L58.0 RADIODERMATITE AGUDA',
  'L58.1 RADIODERMATITE CRONICA',
  'L58.9 RADIODERMATITE NE',
  'L59.0 ERITEMA DEV CALOR OU AO FOGO',
  'L59.8 OUTR AFECC ESPEC PELE TEC SUBCUTANEO REL RAD',
  'L59.9 AFECCOES PELE TEC SUBCUTANEO RELAC RAD NE',
  'L60.0 UNHA ENCRAVADA',
  'L60.1 ONICOLISE',
  'L60.2 ONICOGRIFOSE',
  'L60.3 DISTROFIA UNGUEAL',
  'L60.4 LINHAS DE BEAU',
  'L60.5 SINDR DAS UNHAS AMARELADAS',
  'L60.8 OUTR AFECCOES DAS UNHAS',
  'L60.9 AFECCOES DAS UNHAS NE',
  'L62.0 PAQUIDERMOPERIOSTOSE C/UNHAS HIPOCRATICAS',
  'L62.8 AFECCOES DAS UNHAS EM OUTR DOENC COP',
  'L63.0 ALOPECIA TOTAL',
  'L63.1 ALOPECIA UNIVERSAL',
  'L63.2 OFIASE',
  'L63.8 OUTR FORM DE ALOPECIA AREATA',
  'L63.9 ALOPECIA AREATA NE',
  'L64.0 ALOPECIA ANDROGENICA INDUZ P/DROGA',
  'L64.8 OUTR ALOPECIAS ANDROGENICAS',
  'L64.9 ALOPECIA ANDROGENICA NE',
  'L65.0 EFLUVIO TELOGENO',
  'L65.1 EFLUVIO ANAGENO',
  'L65.2 ALOPECIA MUCINOSA',
  'L65.8 OUTR FORM ESPEC N CICATRIC PERDA CABELO PELO',
  'L65.9 PERDA DE CABELOS OU P/NAO CICATRICIAIS NE',
  'L66.0 PSEUDOPELADA',
  'L66.1 LIQUEN PLANOPILAR',
  'L66.2 FOLICULITE DESCALVANTE',
  'L66.3 PERIFOLICULITE ABSCEDANTE DA CABECA',
  'L66.4 FOLICULITE ULERITEMATOSA RETICULADA',
  'L66.8 OUTR FORM DE ALOPECIA CICATRICIAL',
  'L66.9 ALOPECIA CICATRICIAL NE',
  'L67.0 TRICORREXE NODOSA',
  'L67.1 VARIACOES DA COR DOS CABELOS OU PELOS',
  'L67.8 OUTR ANORM COR PEDICULO DOS CABELOS OU PELOS',
  'L67.9 ANORMA COR PEDICULO DOS CABELOS E PELOS NE',
  'L68.0 HIRSUTISMO',
  'L68.1 HIPERTRICOSE LANUGINOSA ADQUIR',
  'L68.2 HIPERTRICOSE LOCALIZADA',
  'L68.3 POLITRIQUIA',
  'L68.8 OUTR FORM DE HIPERTRICOSE',
  'L68.9 HIPERTRICOSE NE',
  'L70.0 ACNE VULGAR',
  'L70.1 ACNE CONGLOBATA',
  'L70.2 ACNE VARIOLIFORME',
  'L70.3 ACNE TROPICAL',
  'L70.4 ACNE INFANTIL',
  'L70.5 ACNE ESCORIADA DAS MULHERES JOVENS',
  'L70.8 OUTR FORM DE ACNE',
  'L70.9 ACNE NE',
  'L71.0 DERMATITE PERIORAL',
  'L71.1 RINOFIMA',
  'L71.8 OUTR FORM DE ROSACEA',
  'L71.9 ROSACEA NE',
  'L72.0 CISTO EPIDERMICO',
  'L72.1 CISTO TRIQUILEMAL',
  'L72.2 ESTEATOCISTOMA MULT',
  'L72.8 OUTR FORM CISTOS FOLICULARES PELE TEC SUBCUT',
  'L72.9 CISTO FOLICULAR NE DA PELE E TEC SUBCUTANEO',
  'L73.0 ACNE QUELOIDE',
  'L73.1 PSEUDOFOLICULITE DA BARBA',
  'L73.2 HIDRADENITE SUPURATIVA',
  'L73.8 OUTR AFECCOES FOLICULARES ESPEC',
  'L73.9 AFECCOES FOLICULARES NE',
  'L74.0 MILIARIA RUBRA',
  'L74.1 MILIARIA CRISTALINA',
  'L74.2 MILIARIA PROFUNDA',
  'L74.3 MILIARIA NE',
  'L74.4 ANIDROSE',
  'L74.8 OUTR AFECCOES DAS GLAND SUDORIPARAS ECRINAS',
  'L74.9 AFECCOES DAS GLAND SUDORIPARAS ECRINAS NE',
  'L75.0 BROMIDROSE',
  'L75.1 CROMIDROSE',
  'L75.2 MILIARIA APOCRINA',
  'L75.8 OUTR AFECCOES GLAND SUDORIPARAS APOCRINAS',
  'L75.9 AFECCOES DE GLAND SUDORIPARAS APOCRINAS NE',
  'L80   VITILIGO',
  'L81.0 HIPERPIGMENTACAO POS-INFLAM',
  'L81.1 CLOASMA',
  'L81.2 SARDAS',
  'L81.3 MANCHAS CAFE-COM-LEITE',
  'L81.4 OUTR FORM DE HIPERPIGMENTACAO P/MELANINA',
  'L81.5 LEUCODERMIA NCOP',
  'L81.6 OUTR TRANST LIG DIMINUICAO FORMACAO MELANINA',
  'L81.7 DERMATOSE PURPURICA PIGMENTADA',
  'L81.8 OUTR TRANST ESPEC DA PIGMENTACAO',
  'L81.9 TRANST DA PIGMENTACAO NE',
  'L82   CERATOSE SEBORREICA',
  'L83   ACANTOSE NIGRICANS',
  'L84   CALOS E CALOSIDADES',
  'L85.0 ICTIOSE ADQUIR',
  'L85.1 CERATOSE ADQUIR PALMAR E PLANTAR',
  'L85.2 CERATOSE PUNCTATA',
  'L85.3 XEROSE CUTANEA',
  'L85.8 OUTR FORM DE ESPESSAMENTO EPIDERMICO',
  'L85.9 ESPESSAMENTO EPIDERMICO NE',
  'L86   CERATODERMIA EM DOENC COP',
  'L87.0 CERATOSE FOLICULAR PARAFOLICULAR PENETR PELE',
  'L87.1 COLAGENOSE PERFURANTE REATIVA',
  'L87.2 ELASTOSE PERFURANTE SERPIGINOSA',
  'L87.8 OUTR TRANST DA ELIMINACAO TRANSEPIDERMICA',
  'L87.9 TRANST DA ELIMINACAO TRANSEPIDERMICA NE',
  'L88   PIODERMITE GANGRENOSA',
  'L89   ULCERA DE DECUBITO',
  'L90.0 LIQUEN ESCLEROSO E ATROFICO',
  'L90.1 ANETODERMIA DE SCHWENINGER-BUZZI',
  'L90.2 ANETODERMIA DE JADASSOHN-PELLIZZARI',
  'L90.3 ATROFODERMIA DE PASINI E PIERINI',
  'L90.4 ACRODERMATITE CRONICA ATROFICA',
  'L90.5 CICATRIZES E FIBROSE CUTANEA',
  'L90.6 ESTRIAS ATROFICAS',
  'L90.8 OUTR AFECCOES ATROFICAS DA PELE',
  'L90.9 AFECCOES ATROFICAS DA PELE NE',
  'L91.0 CICATRIZ QUELOIDE',
  'L91.8 OUTR AFECCOES HIPERTROFICAS DA PELE',
  'L91.9 AFECCOES HIPERTROFICAS DA PELE NE',
  'L92.0 GRANULOMA ANULAR',
  'L92.1 NECROBIOSE LIPOIDICA NCOP',
  'L92.2 GRANULOMA FACIAL',
  'L92.3 GRANULOMA CORPO ESTRANHO PELE TEC SUBCUTANEO',
  'L92.8 OUTR AFEC GRANULOMATOSAS PELE TEC SUBCUTANEO',
  'L92.9 AFECC GRANULOMATOSAS PELE TEC SUBCUTANEO NE',
  'L93.0 LUPUS ERITEMATOSO DISCOIDE',
  'L93.1 LUPUS ERITEMATOSO CUTANEO SUBAGUDO',
  'L93.2 OUTR FORM DE LUPUS ERITEMATOSO LOCALIZADO',
  'L94.0 ESCLERODERMIA LOCALIZADA',
  'L94.1 ESCLERODERMIA LINEAR',
  'L94.2 CALCINOSE CUTANEA',
  'L94.3 ESCLERODACTILIA',
  'L94.4 PAPULAS DE GOTTRON',
  'L94.5 POIQUILODERMIA VASCULAR ATROFICA',
  'L94.6 AINHUM',
  'L94.8 OUTR AFECCOES LOCALIZ TEC CONJUNTIVO ESPEC',
  'L94.9 AFECCOES LOCALIZADAS DO TEC CONJUNTIVO NE',
  'L95.0 VASCULITE LIVEDOIDE',
  'L95.1 ERITEMA ELEVADO DIUTINO',
  'L95.8 OUTR VASCULITES LIMITADAS A PELE',
  'L95.9 VASCULITES LIMITADAS A PELE NE',
  'L97   ULCERA DOS MEMBROS INFER NCOP',
  'L98.0 GRANULOMA PIOGENICO',
  'L98.1 DERMATITE FACTICIA',
  'L98.2 DERMATOSE NEUTROFILICA FEBRIL',
  'L98.3 CELULITE EOSINOFILICA',
  'L98.4 ULCERA CRONICA DA PELE NCOP',
  'L98.5 MUCINOSE DA PELE',
  'L98.6 OUTR AFECC INFILTRATIVAS PELE TEC SUBCUTANEO',
  'L98.8 OUTR AFECCOES ESPEC PELE E TEC SUBCUTANEO',
  'L98.9 AFECCOES DA PELE E DO TEC SUBCUTANEO NE',
  'L99.0 AMILOIDOSE CUTANEA',
  'L99.8 OUTR AFECC ESPEC PELE TEC SUBCUT DOENC COP',
  'M00.0 ARTRITE E PIOLIARTRITE ESTAFILOCOCICAS',
  'M00.1 ARTRITE E POLIARTRITE PNEUMOCOCICAS',
  'M00.2 OUTR ARTRITES E POLIARTRITES ESTREPTOCOCICAS',
  'M00.8 ARTRITE POLIARTR DEV OUTR AGENT BACTER ESPEC',
  'M00.9 ARTRITE PIOGENICA NE',
  'M01.0 ARTRITE MENINGOCOCICA',
  'M01.1 ARTRITE TUBERC',
  'M01.2 ARTRITE NA DOENC DE LYME',
  'M01.3 ARTRITE EM OUTR DOENC BACTER COP',
  'M01.4 ARTRITE NA RUBEOLA',
  'M01.5 ARTRITE EM OUTR DOENC VIRAIS COP',
  'M01.6 ARTRITE EM MICOSES',
  'M01.8 ARTRITE EM OUTR DOENC INFECC E PARASIT COP',
  'M02.0 ARTROPATIA POS-DERIVACAO INTESTINAL',
  'M02.1 ARTROPATIA POS-DESINTERICA',
  'M02.2 ARTROPATIA POS-IMUNIZACAO',
  'M02.3 DOENC DE REITER',
  'M02.8 OUTR ARTROPATIAS REACIONAIS',
  'M02.9 ARTROPATIA REACIONAL NE',
  'M03.0 ARTRITE POS-MENINGOCOCICA',
  'M03.1 ARTROPATIA POS-INFECC NA SIFILIS',
  'M03.2 OUTR ARTROPATIAS POS-INFECC EM DOENC COP',
  'M03.6 ARTROPATIA REACIONAL EM OUTR DOENC COP',
  'M05.0 SINDR DE FELTY',
  'M05.1 DOENC REUMATOIDE DO PULMAO',
  'M05.2 VASCULITE REUMATOIDE',
  'M05.3 ARTRITE REUMATOIDE C/COMPR OUTR ORG E SIST',
  'M05.8 OUTR ARTRITES REUMATOIDES SORO-POSITIVAS',
  'M05.9 ARTRITE REUMATOIDE SORO-POSITIVA NE',
  'M06.0 ARTRITE REUMATOIDE SORO-NEGATIVA',
  'M06.1 DOENC DE STILL DO ADULTO',
  'M06.2 BURSITE REUMATOIDE',
  'M06.3 NODULO REUMATOIDE',
  'M06.4 POLIARTROPATIA INFLAM',
  'M06.8 OUTR ARTRITES REUMATOIDES ESPEC',
  'M06.9 ARTRITE REUMATOIDE NE',
  'M07.0 ARTROPATIA PSORIASICA INTERFALANGIANA DISTAL',
  'M07.1 ARTRITE MUTILANTE',
  'M07.2 ESPONDILITE PSORIASICA',
  'M07.3 OUTR ARTROPATIAS PSORIASICAS',
  'M07.4 ARTROPATIA NA DOENC DE CROHN',
  'M07.5 ARTROPATIA NA COLITE ULCERATIVA',
  'M07.6 OUTR ARTROPATIAS ENTEROPATICAS',
  'M08.0 ARTRITE REUMATOIDE JUVENIL',
  'M08.1 ESPONDILITE ANCILOSANTE JUVENIL',
  'M08.2 ARTRITE JUVENIL C/INICIO SISTEMICO',
  'M08.3 POLIARTRITE JUVENIL',
  'M08.4 ARTRITE JUVENIL PAUCIARTICULAR',
  'M08.8 OUTR ARTRITES JUVENIS',
  'M08.9 ARTRITE JUVENIL NE',
  'M09.0 ARTRITE JUVENIL NA PSORIASE',
  'M09.1 ARTRITE JUVENIL NA DOENC DE CHRON',
  'M09.2 ARTRITE JUVENIL NA COLITE ULCERATIVA',
  'M09.8 ARTRITE JUVENIL EM OUTR DOENC COP',
  'M10.0 GOTA IDIOPATICA',
  'M10.1 GOTA INDUZ P/CHUMBO',
  'M10.2 GOTA INDUZ P/DROGAS',
  'M10.3 GOTA DEV DISFUNCAO RENAL',
  'M10.4 OUTR GOTA SECUND',
  'M10.9 GOTA NE',
  'M11.0 DOENC P/DEPOSICAO DE HIDROXIAPATITA',
  'M11.1 CONDROCALCINOSE FAMILIAR',
  'M11.2 OUTR CONDROCALCINOSES',
  'M11.8 OUTR ARTROPATIAS ESPEC P/DEPOSICAO CRISTAIS',
  'M11.9 ARTROPATIA P/DEPOSICAO DE CRISTAIS NE',
  'M12.0 ARTROPATIA POS-REUMATICA CRONICA',
  'M12.1 DOENC DE KASHIN-BECK',
  'M12.2 SINOVITE VILONODULAR',
  'M12.3 REUMATISMO PALINDROMICO',
  'M12.4 HIDRARTROSE INTERMITENTE',
  'M12.5 ARTROPATIA TRAUM',
  'M12.8 OUTR ARTROPATIAS ESPECIFICAS NCOP',
  'M13.0 POLIARTRITE NE',
  'M13.1 MONOARTRITES NCOP',
  'M13.8 OUTR ARTRITES ESPEC',
  'M13.9 ARTRITE NE',
  'M14.0 ARTROPAT GOTOSA DEV DEF ENZIM OUT DOEN HERED',
  'M14.1 ARTROPATIA DEP CRISTAIS OUTR DOENC METAB COP',
  'M14.2 ARTROPATIA DIABETICA',
  'M14.3 DERMATOARTRITE LIPOIDE',
  'M14.4 ARTROPATIA NA AMILOIDOSE',
  'M14.5 ARTROPATIAS OUTR DOENC ENDOCR NUTRIC METAB',
  'M14.6 ARTROPATIA NEUROPATICA',
  'M14.8 ARTROPATIAS EM OUTR DOENC ESPEC COP',
  'M15.0 ARTROSE PRIM GENERALIZADA',
  'M15.1 NODULOS DE HEBERDEN',
  'M15.2 NODULOS DE BOUCHARD',
  'M15.3 ARTROSE MULT SECUND',
  'M15.4 ARTROSE EROSIVA',
  'M15.8 OUTR POLIARTROSES',
  'M15.9 POLIARTROSE NE',
  'M16.0 COXARTROSE PRIM BILATERAL',
  'M16.1 OUTR COXARTROSES PRIM',
  'M16.2 COXARTROSE BILATERAL RESULTANTE DE DISPLASIA',
  'M16.3 OUTR COXARTROSES DISPLASICAS',
  'M16.4 COXARTROSE BILATERAL POS-TRAUM',
  'M16.5 OUTR COXARTROSES POS-TRAUM',
  'M16.6 OUTR COXARTROSES SECUND BILATERAIS',
  'M16.7 OUTR COXARTROSES SECUND',
  'M16.9 COXARTROSE NE',
  'M17.0 GONARTROSE PRIM BILATERAL',
  'M17.1 OUTR GONARTROSES PRIM',
  'M17.2 GONARTROSE POS-TRAUM BILATERAL',
  'M17.3 OUTR GONARTROSES POS-TRAUM',
  'M17.4 OUTR GONARTROSES SECUND BILATERAIS',
  'M17.5 OUTR GONARTROSES SECUND',
  'M17.9 GONARTROSE NE',
  'M18.0 ARTROSE PRIM BILAT PRIM ARTIC CARPOMETACARP',
  'M18.1 OUTR ARTROSES PRIM PRIM ARTIC CARPOMETACARP',
  'M18.2 ARTROSE POS-TRAUM BILAT PRIM ART CARPOMETAC',
  'M18.3 OUTR ARTROSES POS-TRAUM PRIM ART CARPOMETAC',
  'M18.4 OUTR CARTROSES SEC BILAT PRIM ART CARPOMETAC',
  'M18.5 OUTR ARTROSES SEC PRIM ARTIC CARPOMETACARP',
  'M18.9 ARTROSE NE PRIM ARTIC CARPOMETACARPIANA',
  'M19.0 ARTROSE PRIM DE OUTR ARTICULACOES',
  'M19.1 ARTROSE POS-TRAUM DE OUTR ARTICULACOES',
  'M19.2 OUTR ARTROSES SECUND',
  'M19.8 OUTR ARTROSES ESPEC',
  'M19.9 ARTROSE NE',
  'M20.0 DEFORM DE DEDOS DAS MAOS',
  'M20.1 HALLUX VALGO',
  'M20.2 HALLUX RIGIDUS',
  'M20.3 OUTR DEFORM DO HALLUX',
  'M20.4 DEDOS DO PE EM MALHO',
  'M20.5 OUTR DEFORM DE DEDOS DOS PES',
  'M20.6 DEFORM ADQUIR NE DE DEDOS DO PE',
  'M21.0 DEFORM EM VALGO NCOP',
  'M21.1 DEFORM EM VARO NCOP',
  'M21.2 DEFORM EM FLEXAO',
  'M21.3 MAO OU PE PENDENTE',
  'M21.4 PE CHATO',
  'M21.5 MAO E PE EM GARRA E MAO E PE TORTOS ADQUIR',
  'M21.6 OUTR DEFORM ADQUIR DO TORNOZELO E DO PE',
  'M21.7 DESIGUALDADE DO COMPRIMENTO DOS MEMBROS',
  'M21.8 OUTR DEFORM ADQUIR ESPEC DOS MEMBROS',
  'M21.9 DEFORM ADQUIR NE DE MEMBRO',
  'M22.0 DESLOCAMENTO RECIDIVANTE DA ROTULA',
  'M22.1 SUBLUXACAO RECIDIVANTE DA ROTULA',
  'M22.2 TRANST FEMUROPATELARES',
  'M22.3 OUTR DESARRANJOS DA ROTULA',
  'M22.4 CONDROMALACIA DA ROTULA',
  'M22.8 OUTR TRANST DA ROTULA',
  'M22.9 TRANST DA ROTULA NE',
  'M23.0 MENISCO CISTICO',
  'M23.1 MENISCO DISCOIDE',
  'M23.2 TRANST MENISCO DEV RUPTURA OU LESAO ANTIGA',
  'M23.3 OUTR TRANST DO MENISCO',
  'M23.4 CORPO FLUTUANTE NO JOELHO',
  'M23.5 INSTABILIDADE CRONICA DO JOELHO',
  'M23.6 OUTR RUPTURAS ESPONTANEAS LIGAMENTOS JOELHO',
  'M23.8 OUTR TRANST INTERNOS DO JOELHO',
  'M23.9 TRANST INTERNO NE DO JOELHO',
  'M24.0 CORPO FLUTUANTE EM ARTICULACAO',
  'M24.1 OUTR TRANST DAS CARTILAGENS ARTICULARES',
  'M24.2 TRANST DE LIGAMENTOS',
  'M24.3 DESLOCAMENTO E SUBLUXACAO PATOLOG ARTIC NCOP',
  'M24.4 DESLOCAMENTO E SUBLUXACAO ARTIC RECIDIVANTES',
  'M24.5 CONTRATURA ARTICULAR',
  'M24.6 ANCILOSE ARTICULAR',
  'M24.7 PROTUSAO DO ACETABULO',
  'M24.8 OUTR TRANST ARTICULARES ESPECIFICOS NCOP',
  'M24.9 DESARRANJO ARTICULAR NE',
  'M25.0 HEMARTROSE',
  'M25.1 FISTULA ARTICULAR',
  'M25.2 FLAIL JOINT',
  'M25.3 OUTR INSTABILIDADES ARTICULARES',
  'M25.4 DERRAME ARTICULAR',
  'M25.5 DOR ARTICULAR',
  'M25.6 RIGIDEZ ARTICULAR NCOP',
  'M25.7 OSTEOFITO',
  'M25.8 OUTR TRANST ARTICULARES ESPEC',
  'M25.9 TRANST ARTICULAR NE',
  'M30.0 POLIARTERITE NODOSA',
  'M30.1 POLIARTERITE C/COMPR PULMONAR',
  'M30.2 POLIARTERITE JUVENIL',
  'M30.3 SINDR DE LINFONODOS MUCOCUTANEOS',
  'M30.8 OUTR AFECCOES COMUNS NA POLIARTERITE NODOSA',
  'M31.0 ANGEITE DE HIPERSENSIBILIDADE',
  'M31.1 MICROANGIOPATIA TROMBOTICA',
  'M31.2 GRANULOMA DA LINHA MEDIA LETAL',
  'M31.3 GRANULOMATOSE DE WEGENER',
  'M31.4 SINDR DO ARCO AORTICO',
  'M31.5 ARTERITE CELULAS GIGANTES POLIMIALGIA REUMAT',
  'M31.6 OUTR ARTERITES DE CELULAS GIGANTES',
  'M31.8 OUTR VASCULOPATIAS NECROTIZANTES ESPEC',
  'M31.9 VASCULOPATIA NECROTIZANTE NE',
  'M32.0 LUPUS ERITEMATOSO DISSEMINADO INDUZ P/DROGAS',
  'M32.1 LUPUS ERITEMATOSO DISSEM COMPR OUTR ORG/SIST',
  'M32.8 OUTR FORM DE LUPUS ERITEMATOSO DISSEMINADO',
  'M32.9 LUPUS ERITEMATOSO DISSEMINADO NE',
  'M33.0 DERMATOMIOSITE JUVENIL',
  'M33.1 OUTR DERMATOMIOSITES',
  'M33.2 POLIMIOSITE',
  'M33.9 DERMATOPOLIMIOSITE NE',
  'M34.0 ESCLEROSE SISTEMICA PROGRESSIVA',
  'M34.1 SINDR CREST',
  'M34.2 ESCLEROSE SISTEMICA INDUZ P/DROGA SUBST QUIM',
  'M34.8 OUTR FORM DE ESCLEROSE SISTEMICA',
  'M34.9 ESCLEROSE SISTEMICA NE',
  'M35.0 SINDR SECA',
  'M35.1 OUTR SINDR SUPERPOSTAS',
  'M35.2 DOENC DE BEHCET',
  'M35.3 POLIMIALGIA REUMATICA',
  'M35.4 FASCIITE DIFUSA',
  'M35.5 FIBROESCLEROSE MULTIFOCAL',
  'M35.6 PANICULITE RECIDIVANTE',
  'M35.7 SINDR DE HIPERMOBILIDADE',
  'M35.8 OUTR COMPR SISTEMICO ESPEC DO TEC CONJUNTIVO',
  'M35.9 COMPR SISTEMICO NE DO TEC CONJUNTIVO',
  'M36.0 DERMATOMIOSITE EM DOENC NEOPLASICAS',
  'M36.1 ARTROPATIA EM DOENC NEOPLASICAS classif',
  'M36.2 ARTROPATIA HEMOFILICA',
  'M36.3 ARTROPATIAS EM OUTR DOENC HEMATOLOGICAS',
  'M36.4 ARTROPATIA ASSOC REACOES HIPERSENSIB COP',
  'M36.8 DOENC SISTEMICAS TEC CONJUNT OUTR DOENC COP',
  'M40.0 CIFOSE POSTURAL',
  'M40.1 OUTR CIFOSES SECUND',
  'M40.2 OUTR CIFOSES E AS NE',
  'M40.3 SINDR DA RETIFICACAO DA COLUNA VERTEBRAL',
  'M40.4 OUTR LORDOSES',
  'M40.5 LORDOSE NE',
  'M41.0 ESCOLIOSE IDIOPATICA INFANTIL',
  'M41.1 ESCOLIOSE IDIOPATICA JUVENIL',
  'M41.2 OUTR ESCOLIOSES IDIOPATICAS',
  'M41.3 ESCOLIOSE TORACOGENICA',
  'M41.4 ESCOLIOSE NEUROMUSCULAR',
  'M41.5 OUTR ESCOLIOSES SECUND',
  'M41.8 OUTR FORM DE ESCOLIOSE',
  'M41.9 ESCOLIOSE NE',
  'M42.0 OSTEOCONDROSE VERTEBRAL JUVENIL',
  'M42.1 OSTEOCONDROSE VERTEBRAL DO ADULTO',
  'M42.9 OSTEOCONDROSE VERTEBRAL NE',
  'M43.0 ESPONDILOLISE',
  'M43.1 ESPONDILOLISTESE',
  'M43.2 OUTR FUSOES DA COLUNA VERTEBRAL',
  'M43.3 SUBLUXACAO ATLANTO-AXIAL RECIDIV MIELOPATIA',
  'M43.4 OUTR SUBLUXACOES ATLANTO-AXIAIS RECIDIVANTES',
  'M43.5 OUTR SUBLUXACOES VERTEBRAIS RECIDIVANTES',
  'M43.6 TORCICOLO',
  'M43.8 OUTR DORSOPATIAS DEFORMANTES ESPEC',
  'M43.9 DORSOPATIA DEFORMANTE NE',
  'M45   ESPONDILITE ANCILOSANTE',
  'M46.0 ENTESOPATIA VERTEBRAL',
  'M46.1 SACROILEITE NCOP',
  'M46.2 OSTEOMIELITE DAS VERTEBRAS',
  'M46.3 INFECC DO DISCO INTERVERTEBRAL',
  'M46.4 DISCITE NE',
  'M46.5 OUTR ESPONDILOPATIAS INFECC',
  'M46.8 OUTR ESPONDILOPATIAS INFLAM ESPEC',
  'M46.9 ESPONDILOPATIA INFLAM NE',
  'M47.0 SINDR COMPRESS ART ESPINHAL ANT OU VERT ANT',
  'M47.1 OUTR ESPONDILOSES C/MIELOPATIA',
  'M47.2 OUTR ESPONDILOSES C/RADICULOPATIAS',
  'M47.8 OUTR ESPONDILOSES',
  'M47.9 ESPONDILOSE NE',
  'M48.0 ESTENOSE DA COLUNA VERTEBRAL',
  'M48.1 HIPEROSTOSE ANCILOSANTE',
  'M48.2 KISSING SPINE',
  'M48.3 ESPONDILOPATIA TRAUM',
  'M48.4 FRAT DE FADIGA DE VERTEBRA',
  'M48.5 VERTEBRA COLAPSADA NCOP',
  'M48.8 OUTR ESPONDILOPATIAS ESPEC',
  'M48.9 ESPONDILOPATIA NE',
  'M49.0 TUBERC DA COLUNA VERTEBRAL',
  'M49.1 ESPONDILITE P/BRUCELLA',
  'M49.2 ESPONDILITE P/ENTEROBACTERIAS',
  'M49.3 ESPONDILOPATIA OUTR DOENC INFECC PARASIT COP',
  'M49.4 ESPONDILOPATIA NEUROPATICA',
  'M49.5 VERTEBRA COLAPSADA EM DOENC COP',
  'M49.8 ESPONDILOPATIA OUTR DOENC COP',
  'M50.0 TRANST DO DISCO CERVICAL C/MIELOPATIA',
  'M50.1 TRANST DO DISCO CERVICAL C/RADICULOPATIA',
  'M50.2 OUTR DESLOCAMENTO DE DISCO CERVICAL',
  'M50.3 OUTR DEGENERACAO DE DISCO CERVICAL',
  'M50.8 OUTR TRANST DE DISCOS CERVICAIS',
  'M50.9 TRANST NE DE DISCO CERVICAL',
  'M51.0 TRANST DISCO LOMBAR OUTR INTERVERT MIELOPAT',
  'M51.1 TRANST DISCO LOMBAR OUTR INTERVERT RADICULOP',
  'M51.2 OUTR DESLOC DISCAIS INTERVERTEBRAIS ESPEC',
  'M51.3 OUTR DEGENERACAO ESPEC DISCO INTERVERTEBRAL',
  'M51.4 NODULOS DE SCHMORL',
  'M51.8 OUTR TRANST ESPEC DE DISCOS INTERVERTEBRAIS',
  'M51.9 TRANST NE DE DISCO INTERVERTEBRAL',
  'M53.0 SINDR CERVICOCRANIANA',
  'M53.1 SINDR CERVICOBRAQUIAL',
  'M53.2 INSTABILIDADES DA COLUNA VERTEBRAL',
  'M53.3 TRANST SACROCCIGEOS NCOP',
  'M53.8 OUTR DORSOPATIAS ESPEC',
  'M53.9 DORSOPATIA NE',
  'M54.0 PANICULITE ATINGINDO REGIOES PESCOCO E DORSO',
  'M54.1 RADICULOPATIA',
  'M54.2 CERVICALGIA',
  'M54.3 CIATICA',
  'M54.4 LUMBAGO C/CIATICA',
  'M54.5 DOR LOMBAR BAIXA',
  'M54.6 DOR NA COLUNA TORACICA',
  'M54.8 OUTR DORSALGIA',
  'M54.9 DORSALGIA NE',
  'M60.0 MIOSITE INFECC',
  'M60.1 MIOSITE INTERSTICIAL',
  'M60.2 GRANULOMA DE CORPO ESTRANHO NO TEC MOLE NCOP',
  'M60.8 OUTR MIOSITES',
  'M60.9 MIOSITE NE',
  'M61.0 MIOSITE OSSIFICANTE TRAUM',
  'M61.1 MIOSITE OSSIFICANTE PROGRESSIVA',
  'M61.2 CALCIFICACAO E OSSIFICACAO PARALIT MUSCULO',
  'M61.3 CALCIFICACAO E OSSIFICACAO MUSC ASSOC QUEIM',
  'M61.4 OUTR CALCIFICACAO DE MUSCULO',
  'M61.5 OUTR OSSIFICACAO DE MUSCULO',
  'M61.9 CALCIFICACAO E OSSIFICACAO DE MUSCULO NE',
  'M62.0 DIASTASE DE MUSCULO',
  'M62.1 OUTR RUPTURAS MUSCULARES',
  'M62.2 INFARTO ISQUEMICO DO MUSCULO',
  'M62.3 SINDR DE IMOBILIDADE',
  'M62.4 CONTRATURA DE MUSCULO',
  'M62.5 PERDA E ATROFIA MUSCULAR NCOP',
  'M62.6 DISTENSAO MUSCULAR',
  'M62.8 OUTR TRANST MUSCULARES ESPEC',
  'M62.9 TRANST MUSCULAR NE',
  'M63.0 MIOSITE EM DOENC BACTER COP',
  'M63.1 MIOSITE DOENC INFECC CAUS PROTOZ PARASIT COP',
  'M63.2 MIOSITE EM OUTR DOENC INFECC COP',
  'M63.3 MIOSITE NA SARCOIDOSE',
  'M63.8 OUTR TRANST MUSCULARES EM DOENC COP',
  'M65.0 ABSCESSO DA BAINHA TENDINEA',
  'M65.1 OUTR SINOVITES INFECC',
  'M65.2 TENDINITE CALCIFICADA',
  'M65.3 DEDO EM GATILHO',
  'M65.4 TENOSSINOVITE ESTILOIDE RADIAL',
  'M65.8 OUTR SINOVITES E TENOSSINOVITES',
  'M65.9 SINOVITE E TENOSSINOVITE NE',
  'M66.0 RUPTURA DE CISTO POPLITEO',
  'M66.1 RUPTURA DE SINOVIA',
  'M66.2 RUPTURA ESPONTANEA DE TENDOES EXTENSORES',
  'M66.3 RUPTURA ESPONTANEA DE TENDOES FLEXORES',
  'M66.4 RUPTURA ESPONTANEA DE OUTR TENDOES',
  'M66.5 RUPTURA ESPONTANEA DE TENDOES NE',
  'M67.0 TENDAO DE AQUILES CURTO',
  'M67.1 OUTR CONTRATURA DE TENDAO',
  'M67.2 HIPERTROFIA SINOVIAL NCOP',
  'M67.3 SINOVITE TRANSITORIA',
  'M67.4 GANGLIOS',
  'M67.8 OUTR TRANST ESPEC DA SINOVIA E DO TENDAO',
  'M67.9 TRANST NE DA SINOVIA E DO TENDAO',
  'M68.0 SINOVITE E TENOSSINOVITE DOENC BACTER COP',
  'M68.8 OUTR TRANST SINOVIAS E TENDOES EM DOENC COP',
  'M70.0 SINOVITE CREPITANTE CRONICA DA MAO E PUNHO',
  'M70.1 BURSITE DA MAO',
  'M70.2 BURSITE DO OLECRANO',
  'M70.3 OUTR BURSITES DO COTOVELO',
  'M70.4 BURSITE PRE-PATELAR',
  'M70.5 OUTR BURSITES DO JOELHO',
  'M70.6 BURSITE TROCANTERICA',
  'M70.7 OUTR BURSITES DO QUADRIL',
  'M70.8 OUTR TRANST TEC MOLES RELAC USO EXCESS PRESS',
  'M70.9 TRANST NE TEC MOLES RELAC USO EXCESS PRESSAO',
  'M71.0 ABSCESSO DE BOLSA SINOVIAL',
  'M71.1 OUTR BURSITES INFECC',
  'M71.2 CISTO SINOVIAL DO ESPACO POPLITEO',
  'M71.3 OUTR CISTOS DE BOLSA SINOVIAL',
  'M71.4 DEPOSITO DE CALCIO EM BOLSA SINOVIAL',
  'M71.5 OUTR BURSITES NCOP',
  'M71.8 OUTR BURSOPATIAS ESPEC',
  'M71.9 BURSOPATIA NE',
  'M72.0 FIBROMATOSE DE FASCIA PALMAR',
  'M72.1 COXINS INTERFALANGICOS',
  'M72.2 FIBROMATOSE DA FASCIA PLANTAR',
  'M72.4 FIBROMATOSE PSEUDOSSARCOMATOSA',
  'M72.6 FASCIITE NECROSANTE',
  'M72.8 OUTR TRANST FIBROBLASTICOS',
  'M72.9 TRANST FIBROBLASTICO NE',
  'M73.0 BURSITE GONOCOCICA',
  'M73.1 BURSITE SIFILITICA',
  'M73.8 OUTR TRANST DOS TEC MOLES EM OUTR DOENC COP',
  'M75.0 CAPSULITE ADESIVA DO OMBRO',
  'M75.1 SINDR DO MANGUITO ROTADOR',
  'M75.2 TENDINITE BICEPITAL',
  'M75.3 TENDINITE CALCIFICANTE DO OMBRO',
  'M75.4 SINDR DE COLIS DO OMBRO',
  'M75.5 BURSITE DO OMBRO',
  'M75.8 OUTR LESOES DO OMBRO',
  'M75.9 LESAO NE DO OMBRO',
  'M76.0 TENDINITE GLUTEA',
  'M76.1 TENDINITE DO PSOAS',
  'M76.2 ESPORAO DA CRISTA ILIACA',
  'M76.3 SINDR DA FAIXA ILIOTIBIAL',
  'M76.4 BURSITE TIBIAL COLATERAL',
  'M76.5 TENDINITE PATELAR',
  'M76.6 TENDINITE AQUILEANA',
  'M76.7 TENDINITE DO PERONEO',
  'M76.8 OUTR ENTESOPATIAS DO MEMBRO INFER EXCL O PE',
  'M76.9 ENTESOPATIA DO MEMBRO INFER NE',
  'M77.0 EPICONDILITE MEDIAL',
  'M77.1 EPICONDILITE LATERAL',
  'M77.2 PERIARTRITE DO PUNHO',
  'M77.3 ESPORAO DO CALCANEO',
  'M77.4 METATARSALGIA',
  'M77.5 OUTR ENTESOPATIA DO PE',
  'M77.8 OUTR ENTESOPATIAS NCOP',
  'M77.9 ENTESOPATIA NE',
  'M79.0 REUMATISMO NE',
  'M79.1 MIALGIA',
  'M79.2 NEVRALGIA E NEURITE NE',
  'M79.3 PANICULITE NE',
  'M79.4 HIPERTROFIA DO COXIM GORDUROSO',
  'M79.5 CORPO ESTRANHO RESIDUAL NO TEC MOLE',
  'M79.6 DOR EM MEMBRO',
  'M79.8 OUTR TRANST ESPEC DOS TEC MOLES',
  'M79.9 TRANST DOS TEC MOLES NE',
  'M80.0 OSTEOPOROSE POS-MENOPAUSICA C/FRAT PATOLOG',
  'M80.1 OSTEOPOROSE POS-OOFORECTOMIA C/FRAT PATOLOG',
  'M80.2 OSTEOPOROSE DE DESUSO C/FRAT PATOLOGICA',
  'M80.3 OSTEOPOROSE MA-ABSORC POS-CIRURG FRAT PATOL',
  'M80.4 OSTEOPOROSE INDUZ P/DROGAS C/FRAT PATOLOGICA',
  'M80.5 OSTEOPOROSE IDIOPATICA C/FRAT PATOLOGICA',
  'M80.8 OUTR OSTEOPOROSES C/FRAT PATOLOGICA',
  'M80.9 OSTEOPOROSE NE C/FRAT PATOLOGICA',
  'M81.0 OSTEOPOROSE POS-MENOPAUSICA',
  'M81.1 OSTEOPOROSE POS-OOFORECTOMIA',
  'M81.2 OSTEOPOROSE DE DESUSO',
  'M81.3 OSTEOPOROSE DEV MA-ABSORCAO POS-CIRURGICA',
  'M81.4 OSTEOPOROSE INDUZ P/DROGAS',
  'M81.5 OSTEOPOROSE IDIOPATICA',
  'M81.6 OSTEOPOROSE LOCALIZADA',
  'M81.8 OUTR OSTEOPOROSES',
  'M81.9 OSTEOPOROSE NE',
  'M82.0 OSTEOPOROSE NA MIELOMATOSE MULT',
  'M82.1 OSTEOPOROSE EM DISTURBIOS ENDOCRINOS',
  'M82.8 OSTEOPOROSE EM OUTR DOENC COP',
  'M83.0 OSTEOMALACIA PUERPERAL',
  'M83.1 OSTEOMALACIA SENIL',
  'M83.2 OSTEOMALACIA DO ADULTO DEV MA-ABSORCAO',
  'M83.3 OSTEOMALACIA DO ADULTO DEV DESNUTRIC',
  'M83.4 DOENC OSSEA P/ALUMINIO',
  'M83.5 OUTR OSTEOMALACIAS DO ADULTO INDUZ P/DROGAS',
  'M83.8 OUTR OSTEOMALACIA DO ADULTO',
  'M83.9 OSTEOMALACIA NE DO ADULTO',
  'M84.0 DEFEITO DE CONSOLIDACAO DA FRAT',
  'M84.1 AUSENCIA DE CONSOLIDACAO DA FRAT',
  'M84.2 ATRASO DE CONSOLIDACAO DE FRAT',
  'M84.3 FRAT DE FADIGA NCOP',
  'M84.4 FRAT PATOLOGICA NCOP',
  'M84.8 OUTR TRANST DA CONTINUIDADE DO OSSO',
  'M84.9 TRANST NE DA CONTINUIDADE DO OSSO',
  'M85.0 DISPLASIA FIBROSA',
  'M85.1 FLUOROSE ESQUELETICA',
  'M85.2 HIPEROSTOSE DO CRANIO',
  'M85.3 OSTEITE CONDENSANTE',
  'M85.4 CISTO OSSEO SOLITARIO',
  'M85.5 CISTO OSSEO ANEURISMATICO',
  'M85.6 OUTR CISTO OSSEO',
  'M85.8 OUTR TRANST ESPEC DENSIDADE ESTRUTURA OSSEAS',
  'M85.9 TRANST NE DENSIDADE ESTRUTURA OSSEAS',
  'M86.0 OSTEOMIELITE AGUDA HEMATOGENICA',
  'M86.1 OUTR OSTEOMIELITE AGUDA',
  'M86.2 OSTEOMIELITE SUBAGUDA',
  'M86.3 OSTEOMIELITE CRONICA MULTIFOCAL',
  'M86.4 OSTEOMIELITE CRONICA C/SEIO DRENANTE',
  'M86.5 OUTR OSTEOMIELITE CRONICA HEMATOGENICA',
  'M86.6 OUTR OSTEOMIELITE CRONICA',
  'M86.8 OUTR OSTEOMIELITE',
  'M86.9 OSTEOMIELITE NE',
  'M87.0 NECROSE ASSEPTICA IDIOPATICA DO OSSO',
  'M87.1 OSTEONECROSE DEV DROGAS',
  'M87.2 OSTEONECROSE DEV TRAUM ANTERIOR',
  'M87.3 OUTR OSTEONECROSES SECUND',
  'M87.8 OUTR OSTEONECROSES',
  'M87.9 OSTEONECROSE NE',
  'M88.0 DOENC DE PAGET DO CRANIO',
  'M88.8 DOENC DE PAGET DE OUTR OSSOS',
  'M88.9 DOENC DE PAGET DE OSSO NE',
  'M89.0 ALGONEURODISTROFIA',
  'M89.1 PARADA DE CRESCIMENTO EPIFISARIO',
  'M89.2 OUTR TRANST DESENVOLV E CRESCIMENTO OSSEO',
  'M89.3 HIPERTROFIA OSSEA',
  'M89.4 OUTR OSTEOARTROPATIAS HIPERTROFICAS',
  'M89.5 OSTEOLISE',
  'M89.6 OSTEOPATIA POS-POLIOMIELITE',
  'M89.8 OUTR TRANST ESPEC DO OSSO',
  'M89.9 TRANST NE DO OSSO',
  'M90.0 TUBERC OSSEA',
  'M90.1 PERIOSTITE EM OUTR DOENC INFECC COP',
  'M90.2 OSTEOPATIA EM OUTR DOENC INFECC COP',
  'M90.3 OSTEONECROSE EM MAL DOS CAIXOES',
  'M90.4 OSTEONECROSE DEV HEMOGLOBINOPATIA',
  'M90.5 OSTEONECROSE EM OUTR DOENC COP',
  'M90.6 OSTEITE DEFORMANTE EM DOENC NEOPLASICAS',
  'M90.7 FRAT OSSEA EM DOENC NEOPLASICAS',
  'M90.8 OSTEOPATIA EM OUTR DOENC COP',
  'M91.0 OSTEOCONDROSE JUVENIL DA PELVE',
  'M91.1 OSTEOCONDROSE JUVENIL DA CABECA DO FEMUR',
  'M91.2 COXA PLANA',
  'M91.3 PSEUDOCOXALGIA',
  'M91.8 OUTR OSTEOCONDROSES JUVENIS QUADRIL E PELVE',
  'M91.9 OSTEOCONDROSE JUVENIL NE QUADRIL E PELVE',
  'M92.0 OSTEOCONDROSE JUVENIL DO UMERO',
  'M92.1 OSTEOCONDROSE JUVENIL DO RADIO E DO CUBITO',
  'M92.2 OSTEOCONDROSE JUVENIL DA MAO',
  'M92.3 OUTR OSTEOCONDROSES JUVENIS DO MEMBRO SUPER',
  'M92.4 OSTEOCONDROSE JUVENIL DA ROTULA',
  'M92.5 OSTEOCONDROSE JUVENIL DA TIBIA E PERONIO',
  'M92.6 OSTEOCONDROSE JUVENIL DO TARSO',
  'M92.7 OSTEOCONDROSE JUVENIL DO METATARSO',
  'M92.8 OUTR OSTEOCONDROSES JUVENIS ESPEC',
  'M92.9 OSTEOCONDROSE JUVENIL NE',
  'M93.0 LUXACAO DA EPIFISE SUPER DO FEMUR',
  'M93.1 DOENC DE KIENBOCK DO ADULTO',
  'M93.2 OSTEOCONDRITE DISSECANTE',
  'M93.8 OUTR OSTEOCONDROPATIAS ESPEC',
  'M93.9 OSTEOCONDROPATIAS NE',
  'M94.0 SINDR DA JUNCAO CONDROCOSTAL',
  'M94.1 POLICONDRITE RECIDIVANTE',
  'M94.2 CONDROMALACIA',
  'M94.3 CONDROLISE',
  'M94.8 OUTR TRANST ESPEC DA CARTILAGEM',
  'M94.9 TRANST NE DA CARTILAGEM',
  'M95.0 DEFORM ADQUIR DO NARIZ',
  'M95.1 ORELHA EM COUVE-FLOR',
  'M95.2 OUTR DEFORM ADQUIR DA CABECA',
  'M95.3 DEFORM ADQUIR DO PESCOCO',
  'M95.4 DEFORM ADQUIR DO TORAX E DAS COSTELAS',
  'M95.5 DEFORM ADQUIR DA PELVE',
  'M95.8 OUTR DEFORM ADQUIR ESPEC SIST OSTEOMUSCULAR',
  'M95.9 DEFORM ADQUIR DO SIST OSTEOMUSCULAR NE',
  'M96.0 PSEUDO-ARTROSE APOS FUSAO OU ARTRODESE',
  'M96.1 SINDR POS-LAMINECTOMIA NCOP',
  'M96.2 CIFOSE POS-RADIACAO',
  'M96.3 CIFOSE POS-LAMINECTOMIA',
  'M96.4 LORDOSE POS-CIRURGICA',
  'M96.5 ESCOLIOSE POS-RADIACAO',
  'M96.6 FRAT OSSO SUBS IMPL ORTOP PROT ART PLACA OSS',
  'M96.8 OUTR TRANST OSTEOMUSCULARES POS-PROCED',
  'M96.9 TRANST OSTEOMUSCULAR NE POS-PROCED',
  'M99.0 DISFUNCAO SEGMENTAR E SOMATICA',
  'M99.1 COMPLEXO DE SUBLUXACAO',
  'M99.2 ESTENOSE DE SUBLUXACAO DO CANAL MEDULAR',
  'M99.3 ESTENOSE OSSEA DO CANAL MEDULAR',
  'M99.4 ESTENOSE DE TEC CONJUNTIVO DO CANAL MEDULAR',
  'M99.5 ESTENOSE DISCO INTERVERTEBRAL CANAL MEDULAR',
  'M99.6 ESTENOSE OSSEA SUBLUXACAO FORAMES INTERVERT',
  'M99.7 ESTENOSE TEC CONJ E DISCO FORAMES INTERVERT',
  'M99.8 OUTR LESOES BIOMECANICAS',
  'M99.9 LESAO BIOMECANICA NE',
  'N00.0 ANORMALIDADE GLOMERULAR MINOR',
  'N00.1 LESOES GLOMERULARES FOCAIS E SEGMENTARES',
  'N00.2 GLOMERULONEFRITE MEMBRANOSA DIFUSA',
  'N00.3 GLOMERULONEFRITE PROLIFER MESANGIAL DIFUSA',
  'N00.4 GLOMERULONEFRITE PROLIFER ENDOCAPILAR DIFUSA',
  'N00.5 GLOMERULONEFRITE MESANGIOCAPILAR DIFUSA',
  'N00.6 DOENC DE DEPOSITO DENSO',
  'N00.7 GLOMERULONEFRITE DIFUSA EM CRESCENTE',
  'N00.8 OUTR',
  'N00.9 NE',
  'N01.0 ANORMALIDADE GLOMERULAR MINOR',
  'N01.1 LESOES GLOMERULARES FOCAIS E SEGMENTARES',
  'N01.2 GLOMERULONEFRITE MEMBRANOSA DIFUSA',
  'N01.3 GLOMERULONEFRITE PROLIFER MESANGIAL DIFUSA',
  'N01.4 GLOMERULONEFRITE PROLIFER ENDOCAPILAR DIFUSA',
  'N01.5 GLOMERULONEFRITE MESANGIOCAPILAR DIFUSA',
  'N01.6 DOENC DE DEPOSITO DENSO',
  'N01.7 GLOMERULONEFRITE DIFUSA EM CRESCENTE',
  'N01.8 OUTR',
  'N01.9 NE',
  'N02.0 ANORMALIDADE GLOMERULAR MINOR',
  'N02.1 LESOES GLOMERULARES FOCAIS E SEGMENTARES',
  'N02.2 GLOMERULONEFRITE MEMBRANOSA DIFUSA',
  'N02.3 GLOMERULONEFRITE PROLIFER MESANGIAL DIFUSA',
  'N02.4 GLOMERULONEFRITE PROLIFER ENDOCAPILAR DIFUSA',
  'N02.5 GLOMERULONEFRITE MESANGIOCAPILAR DIFUSA',
  'N02.6 DOENC DE DEPOSITO DENSO',
  'N02.7 GLOMERULONEFRITE DIFUSA EM CRESCENTE',
  'N02.8 OUTR',
  'N02.9 NE',
  'N03.0 ANORMALIDADE GLOMERULAR MINOR',
  'N03.1 LESOES GLOMERULARES FOCAIS E SEGMENTARES',
  'N03.2 GLOMERULONEFRITE MEMBRANOSA DIFUSA',
  'N03.3 GLOMERULONEFRITE PROLIFER MESANGIAL DIFUSA',
  'N03.4 GLOMERULONEFRITE PROLIFER ENDOCAPILAR DIFUSA',
  'N03.5 GLOMERULONEFRITE MESANGIOCAPILAR DIFUSA',
  'N03.6 DOENC DE DEPOSITO DENSO',
  'N03.7 GLOMERULONEFRITE DIFUSA EM CRESCENTE',
  'N03.8 OUTR',
  'N03.9 NE',
  'N04.0 ANORMALIDADE GLOMERULAR MINOR',
  'N04.1 LESOES GLOMERULARES FOCAIS E SEGMENTARES',
  'N04.2 GLOMERULONEFRITE MEMBRANOSA DIFUSA',
  'N04.3 GLOMERULONEFRITE PROLIFER MESANGIAL DIFUSA',
  'N04.4 GLOMERULONEFRITE PROLIFER ENDOCAPILAR DIFUSA',
  'N04.5 GLOMERULONEFRITE MESANGIOCAPILAR DIFUSA',
  'N04.6 DOENC DE DEPOSITO DENSO',
  'N04.7 GLOMERULONEFRITE DIFUSA EM CRESCENTE',
  'N04.8 OUTR',
  'N04.9 NE',
  'N05.0 ANORMALIDADE GLOMERULAR MINOR',
  'N05.1 LESOES GLOMERULARES FOCAIS E SEGMENTARES',
  'N05.2 GLOMERULONEFRITE MEMBRANOSA DIFUSA',
  'N05.3 GLOMERULONEFRITE PROLIFER MESANGIAL DIFUSA',
  'N05.4 GLOMERULONEFRITE PROLIFER ENDOCAPILAR DIFUSA',
  'N05.5 GLOMERULONEFRITE MESANGIOCAPILAR DIFUSA',
  'N05.6 DOENC DE DEPOSITO DENSO',
  'N05.7 GLOMERULONEFRITE DIFUSA EM CRESCENTE',
  'N05.8 OUTR',
  'N05.9 NE',
  'N06.0 ANORMALIDADE GLOMERULAR MINOR',
  'N06.1 LESOES GLOMERULARES FOCAIS E SEGMENTARES',
  'N06.2 GLOMERULONEFRITE MEMBRANOSA DIFUSA',
  'N06.3 GLOMERULONEFRITE PROLIFER MESANGIAL DIFUSA',
  'N06.4 GLOMERULONEFRITE PROLIFER ENDOCAPILAR DIFUSA',
  'N06.5 GLOMERULONEFRITE MESANGIOCAPILAR DIFUSA',
  'N06.6 DOENC DE DEPOSITO DENSO',
  'N06.7 GLOMERULONEFRITE DIFUSA EM CRESCENTE',
  'N06.8 OUTR',
  'N06.9 NE',
  'N07.0 ANORMALIDADE GLOMERULAR MINOR',
  'N07.1 LESOES GLOMERULARES FOCAIS E SEGMENTARES',
  'N07.2 GLOMERULONEFRITE MEMBRANOSA DIFUSA',
  'N07.3 GLOMERULONEFRITE PROLIFER MESANGIAL DIFUSA',
  'N07.4 GLOMERULONEFRITE PROLIFER ENDOCAPILAR DIFUSA',
  'N07.5 GLOMERULONEFRITE MESANGIOCAPILAR DIFUSA',
  'N07.6 DOENC DE DEPOSITO DENSO',
  'N07.7 GLOMERULONEFRITE DIFUSA EM CRESCENTE',
  'N07.8 OUTR',
  'N07.9 NE',
  'N08.0 TRANST GLOMERULARES DOENC INFECC PARASIT COP',
  'N08.1 TRANST GLOMERULARES EM DOENC NEOPLASICAS',
  'N08.2 TRANST GLOMERULARES DOENC SANGUE TRANST IMUN',
  'N08.3 TRANST GLOMERULARES NO DIABETES MELLITUS',
  'N08.4 TRANST GLOMERULARES DOENC ENDOCR NUTR METAB',
  'N08.5 TRANST GLOMERULARES DOENC SIST TEC CONJUNT',
  'N08.8 TRANST GLOMERULARES EM OUTR DOENC COP',
  'N10   NEFRITE TUBULO-INTERSTICIAL AGUDA',
  'N11.0 PIELONEFRITE NAO-OBSTRUT CRON ASSOC REFLUXO',
  'N11.1 PIELONEFRITE OBSTRUTIVA CRONICA',
  'N11.8 OUTR NEFRITES TUBULO-INTERSTICIAIS CRONICAS',
  'N11.9 NEFRITE TUBULO-INTERSTICIAL CRONICA NE',
  'N12   NEFRITE TUBULO-INTERSTICIAL NE AGUDA CRONICA',
  'N13.0 HIDRONEFROSE C/OBSTR JUNCAO URETERO-PELVICA',
  'N13.1 HIDRONEFROSE C/ESTREITAMENTO DE URETER NCOP',
  'N13.2 HIDRONEFR C/OBSTR P/CALCULOSE RENAL URETERAL',
  'N13.3 OUTR HIDRONEFROSES E AS NE',
  'N13.4 HIDROURETER',
  'N13.5 TORCAO E ESTREITAMENTO URETER S/HIDRONEFROSE',
  'N13.6 PIONEFROSE',
  'N13.7 UROPATIA ASSOC A REFLUXO VESICO-URETERAL',
  'N13.8 OUTR UROPATIAS OBSTRUTIVAS E P/REFLUXO',
  'N13.9 UROPATIA OBSTRUTIVA E P/REFLUXO NE',
  'N14.0 NEFROPATIA INDUZ P/ANALGESICO',
  'N14.1 NEFROPATIA INDUZ OUT DROG MEDIC SUBST BIOLOG',
  'N14.2 NEFROPATIA INDUZ DROG MEDIC SUBST BIOLOG NE',
  'N14.3 NEFROPATIA INDUZ P/METAIS PESADOS',
  'N14.4 NEFROPATIA TOXICA NCOP',
  'N15.0 NEFROPATIA DOS BALCAS',
  'N15.1 ABSCESSO RENAL E PERINEFRETICO',
  'N15.8 OUTR DOENC RENAIS TUBULO-INTERSTICIAIS ESPEC',
  'N15.9 DOENC RENAL TUBULO-INTERSTICIAL NE',
  'N16.0 TRANST RENAIS TUB-INTERST DOEN INF PARAS COP',
  'N16.1 TRANST RENAIS TUBULO-INTERST DOENC NEOPLAS',
  'N16.2 TRANST RENAIS TUB-INTER DOEN SANG TRANS IMUN',
  'N16.3 TRANST RENAIS TUBULO-INTERST DOENC METAB',
  'N16.4 TRANST RENAIS TUBULO-INTERST DOENC TEC CONJ',
  'N16.5 TRANST RENAIS TUBULO-INTERST REJ TRANSPL',
  'N16.8 TRANST RENAIS TUBULO-INTERST OUTR DOENC COP',
  'N17.0 INSUF RENAL AGUDA C/NECROSE TUBULAR',
  'N17.1 INSUF RENAL AGUDA C/NECROSE CORTICAL AGUDA',
  'N17.2 INSUF RENAL AGUDA C/NECROSE MEDULAR',
  'N17.8 OUTR TIPO DE INSUF RENAL AGUDA',
  'N17.9 INSUF RENAL AGUDA NE',
  'N18.0 DOENC RENAL EM ESTADIO FINAL',
  'N18.8 OUTR INSUF RENAL CRONICA',
  'N18.9 INSUF RENAL CRONICA NE',
  'N19   INSUF RENAL NE',
  'N20.0 CALCULOSE DO RIM',
  'N20.1 CALCULOSE DO URETER',
  'N20.2 CALCULOSE DO RIM C/CALCULO DO URETER',
  'N20.9 CALCULOSE URINARIA NE',
  'N21.0 CALCULOSE NA BEXIGA',
  'N21.1 CALCULO URETRAL',
  'N21.8 OUTR CALCULOS DO TRATO URINARIO INFER',
  'N21.9 CALCULO DO TRATO URINARIO INFER PORCAO NE',
  'N22.0 CALCULO URINARIO NA ESQUISTOSSOMOSE',
  'N22.8 CALCULOSE DO TRATO URINARIO OUTR DOENC COP',
  'N23   COLICA NEFRETICA NE',
  'N25.0 OSTEODISTROFIA RENAL',
  'N25.1 DIABETES INSIPIDO NEFROGENICO',
  'N25.8 OUTR TRANST RESULT FUNC RENAL TUBULAR ALTER',
  'N25.9 TRANST RESULTANTE DE FUNCAO TUBULAR ALTERADA',
  'N26   RIM CONTRAIDO NE',
  'N27.0 HIPOPLASIA RENAL UNILATERAL',
  'N27.1 HIPOPLASIA RENAL BILATERAL',
  'N27.9 HIPOPLASIA RENAL NE',
  'N28.0 ISQUEMIA E INFARTO RENAL',
  'N28.1 CISTO DO RIM ADQUIR',
  'N28.8 OUTR TRANST ESPEC DO RIM E DO URETER',
  'N28.9 TRANST NE DO RIM E DO URETER',
  'N29.0 SIFILIS TARD RENAL',
  'N29.1 OUTR TRANST RIM URETER DOENC INFEC PARAS COP',
  'N29.8 OUTR TRANST RIM E URETER EM OUTR DOENC COP',
  'N30.0 CISTITE AGUDA',
  'N30.1 CISTITE INTERSTICIAL',
  'N30.2 OUTR CISTITES CRONICAS',
  'N30.3 TRIGONITE',
  'N30.4 CISTITE P/RADIACAO',
  'N30.8 OUTR CISTITES',
  'N30.9 CISTITE NE',
  'N31.0 BEXIGA NEUROPATICA NAO-INIBIDA NCOP',
  'N31.1 BEXIGA NEUROPATICA REFLEXA NCOP',
  'N31.2 BEXIGA NEUROPATICA FLACIDA NCOP',
  'N31.8 OUTR DISFUNCAO NEUROMUSCULAR DA BEXIGA',
  'N31.9 DISFUNCAO NEUROMUSCULAR NE DA BEXIGA',
  'N32.0 OBSTRUCAO DO COLO DA BEXIGA',
  'N32.1 FISTULA ENTERO-VESICAL',
  'N32.2 FISTULA VESICAL NCOP',
  'N32.3 DIVERTICULO DA BEXIGA',
  'N32.4 RUPTURA DA BEXIGA NAO-TRAUM',
  'N32.8 OUTR TRANST ESPEC DA BEXIGA',
  'N32.9 TRANST NE DA BEXIGA',
  'N33.0 CISTITE TUBERC',
  'N33.8 TRANST DA BEXIGA EM OUTR DOENC COP',
  'N34.0 ABSCESSO URETRAL',
  'N34.1 URETRITES NAO ESPECIFICAS',
  'N34.2 OUTR URETRITES',
  'N34.3 SINDR URETRAL NE',
  'N35.0 ESTENOSE URETRAL POS-TRAUM',
  'N35.1 ESTENOSE URETRAL POS-INFECC NCOP',
  'N35.8 OUTR ESTENOSE URETRAL',
  'N35.9 ESTENOSE URETRAL NE',
  'N36.0 FISTULA URETRAL',
  'N36.1 DIVERTICULO URETRAL',
  'N36.2 CARUNCULA URETRAL',
  'N36.3 PROLAPSO DA MUCOSA URETRAL',
  'N36.8 OUTR TRANST ESPEC DA URETRA',
  'N36.9 TRANST NE DA URETRA',
  'N37.0 URETRITE EM DOENC COP',
  'N37.8 OUTR TRANST DA URETRA EM DOENC COP',
  'N39.0 INFECC DO TRATO URINARIO DE LOCALIZ NE',
  'N39.1 PROTEINURIA PERSISTENTE NE',
  'N39.2 PROTEINURIA ORTOSTATICA NE',
  'N39.3 INCONTINENCIA DE TENSAO',
  'N39.4 OUTR INCONTINENCIAS URINARIAS ESPEC',
  'N39.8 OUTR TRANST ESPEC DO APARELHO URINARIO',
  'N39.9 TRANST NE DO APARELHO URINARIO',
  'N40   HIPERPLASIA DA PROSTATA',
  'N41.0 PROSTATITE AGUDA',
  'N41.1 PROSTATITE CRONICA',
  'N41.2 ABSCESSO DA PROSTATA',
  'N41.3 PROSTATOCISTITE',
  'N41.8 OUTR DOENC INFLAM DA PROSTATA',
  'N41.9 DOENC INFLAM NE DA PROSTATA',
  'N42.0 CALCULO DA PROSTATA',
  'N42.1 CONGESTAO E HEMORRAGIA DA PROSTATA',
  'N42.2 ATROFIA DA PROSTATA',
  'N42.8 OUTR AFECCOES ESPEC DA PROSTATA',
  'N42.9 AFECCAO NE DA PROSTATA',
  'N43.0 HIDROCELE ENCISTADA',
  'N43.1 HIDROCELE INFECTADA',
  'N43.2 OUTR HIDROCELE',
  'N43.3 HIDROCELE NE',
  'N43.4 ESPERMATOCELE',
  'N44   TORCAO DO TESTICULO',
  'N45.0 ORQUITE EPIDID EPIDIDIMO-ORQUITE C/MENC ABSC',
  'N45.9 ORQUITE EPIDID EPIDIDIMO-ORQUITE S/MENC ABSC',
  'N46   INFERTILIDADE MASC',
  'N47   HIPERTROFIA DO PREPUCIO FIMOSE E PARAFIMOSE',
  'N48.0 LEUCOPLASIA DO PENIS',
  'N48.1 BALANOPOSTITE',
  'N48.2 OUTR TRANST INFLAM DO PENIS',
  'N48.3 PRIAPISMO',
  'N48.4 IMPOTENCIA ORIG ORGANICA',
  'N48.5 ULCERA DO PENIS',
  'N48.6 INDURATIO PENIS PLASTICA',
  'N48.8 OUTR TRANST ESPEC DO PENIS',
  'N48.9 TRANST NE DO PENIS',
  'N49.0 TRANST INFLAM DA VESICULA SEMINAL',
  'N49.1 TRANST INFL CORD ESPERM TUNIC VAGIN VAS DEF',
  'N49.2 TRANST INFLAM DO ESCROTO',
  'N49.8 TRANST INFLAM OUTR ORG GENITAIS MASC ESPEC',
  'N49.9 TRANST INFLAM DE ORGAO GENITAL MASC NE',
  'N50.0 ATROFIA DO TESTICULO',
  'N50.1 TRANST VASCULARES ORGAOS GENITAIS MASC',
  'N50.8 OUTR TRANST ESPEC ORGAOS GENITAIS MASC',
  'N50.9 TRANST NE DOS ORGAOS GENITAIS MASC',
  'N51.0 TRANST DA PROSTATA EM DOENC COP',
  'N51.1 TRANST DO TESTICULO E EPIDIDIMO EM DOENC COP',
  'N51.2 BALANITE EM DOENC COP',
  'N51.8 OUTR TRANST ORGAOS GENITAIS MASC DOENC COP',
  'N60.0 CISTO SOLITARIO DA MAMA',
  'N60.1 MASTOPATIA CISTICA DIFUSA',
  'N60.2 FIBROADENOSE DA MAMA',
  'N60.3 FIBROSCLEROSE DA MAMA',
  'N60.4 ECTASIA DE DUTOS MAMARIOS',
  'N60.8 OUTR DISPLASIAS MAMARIAS BENIGNAS',
  'N60.9 DISPLASIA MAMARIA BENIGNA NE',
  'N61   TRANST INFLAM DA MAMA',
  'N62   HIPERTROFIA DA MAMA',
  'N63   NODULO MAMARIO NE',
  'N64.0 FISSURA E FISTULA DO MAMILO',
  'N64.1 NECROSE GORDUROSA DA MAMA',
  'N64.2 ATROFIA DA MAMA',
  'N64.3 GALACTORREIA NAO-ASSOC AO PARTO',
  'N64.4 MASTODINIA',
  'N64.5 OUTR SINT E SINAIS DA MAMA',
  'N64.8 OUTR TRANST ESPEC DA MAMA',
  'N64.9 TRANST DA MAMA NE',
  'N70.0 SALPINGITE E OOFORITE AGUDAS',
  'N70.1 SALPINGITE E OOFORITE CRONICAS',
  'N70.9 SALPINGITE E OOFORITE NE',
  'N71.0 DOENC INFLAM AGUDA DO UTERO',
  'N71.1 DOENC INFLAM CRONICA DO UTERO',
  'N71.9 DOENC INFLAM NE DO UTERO',
  'N72   DOENC INFLAM DO COLO DO UTERO',
  'N73.0 PARAMETRITE E CELULITE PELVICAS AGUDAS',
  'N73.1 PARAMETRITE E CELULITE PELVICAS CRONICAS',
  'N73.2 PARAMETRITE E CELULITE PELVICAS NE',
  'N73.3 PELVIPERITONITE AGUDA FEMIN',
  'N73.4 PELVIPERITONITE CRONICA FEMIN',
  'N73.5 PELVIPERITONITE NE FEMIN',
  'N73.6 ADERENCIAS PELVIPERITONAIS FEMIN',
  'N73.8 OUTR DOENC INFLAM ESPEC PELVE FEM',
  'N73.9 DOENC INFLAM NE DA PELVE FEMIN',
  'N74.0 TUBERC DO COLO DO UTERO',
  'N74.1 TUBERC DA PELVE FEMIN',
  'N74.2 SIFILIS PELVICA FEMIN',
  'N74.3 INFECC GONOCOCICA PELVICA FEMIN',
  'N74.4 INFECC PELVICA FEMIN P/CLAMIDIA',
  'N74.8 INFLAM PELVICA FEMIN EM OUTR DOENC COP',
  'N75.0 CISTO DA GLAND DE BARTHOLIN',
  'N75.1 ABSCESSO DA GLAND DE BARTHOLIN',
  'N75.8 OUTR DOENC DA GLAND DE BARTHOLIN',
  'N75.9 DOENC NE DA GLAND DE BARTHOLIN',
  'N76.0 VAGINITE AGUDA',
  'N76.1 VAGINITE SUBAGUDA E CRONICA',
  'N76.2 VULVITE AGUDA',
  'N76.3 VULVITE SUBAGUDA E CRONICA',
  'N76.4 ABSCESSO VULVAR',
  'N76.5 ULCERACAO VAGINAL',
  'N76.6 ULCERACAO VULVAR',
  'N76.8 OUTR INFLAM ESPEC DA VAGINA E DA VULVA',
  'N77.0 ULCERACAO DA VULVA DOENC INFECC PARASIT COP',
  'N77.1 VAGIN VULV VULVOVAGINITE DOENC INF PARAS COP',
  'N77.8 ULCERACAO INFLAM VULVOVAG OUTR DOENC COP',
  'N80.0 ENDOMETRIOSE DO UTERO',
  'N80.1 ENDOMETRIOSE DO OVARIO',
  'N80.2 ENDOMETRIOSE DA TROMPA DE FALOPIO',
  'N80.3 ENDOMETRIOSE DO PERITONIO PELVICO',
  'N80.4 ENDOMETRIOSE DO SEPTO RETOVAGINAL E VAGINA',
  'N80.5 ENDOMETRIOSE DO INTESTINO',
  'N80.6 ENDOMETRIOSE EM CICATRIZ CUTANEA',
  'N80.8 OUTR ENDOMETRIOSE',
  'N80.9 ENDOMETRIOSE NE',
  'N81.0 URETROCELE FEMIN',
  'N81.1 CISTOCELE',
  'N81.2 PROLAPSO UTEROVAGINAL INCOMPLETO',
  'N81.3 PROLAPSO UTEROVAGINAL COMPLETO',
  'N81.4 PROLAPSO UTEROVAGINAL NE',
  'N81.5 ENTEROCELE VAGINAL',
  'N81.6 RETOCELE',
  'N81.8 OUTR PROLAPSO GENITAL FEMIN',
  'N81.9 PROLAPSO GENITAL FEMIN NE',
  'N82.0 FISTULA VESICOVAGINAL',
  'N82.1 OUTR FISTULAS DO TRATO GENITURINARIO FEMIN',
  'N82.2 FISTULA VAGINA-INTESTINO DELGADO',
  'N82.3 FISTULA VAGINA-COLON',
  'N82.4 OUTR FISTULAS GENITO-INTESTINAIS FEMIN',
  'N82.5 FISTULA GENITOCUTANEA FEMIN',
  'N82.8 OUTR FISTULAS DO TRATO GENITAL FEMIN',
  'N82.9 FISTULAS NE DO TRATO GENITAL FEMIN',
  'N83.0 CISTO FOLICULAR DO OVARIO',
  'N83.1 CISTO DO CORPO LUTEO',
  'N83.2 OUTR CISTOS OVARIANOS E OS NE',
  'N83.3 ATROFIA ADQUIR DO OVARIO E TROMPA DE FALOPIO',
  'N83.4 PROLAPSO E HERNIA OVARIO E TROMPA DE FALOPIO',
  'N83.5 TORCAO OVARIO PEDICULO OVARIAN TROMP FALOPIO',
  'N83.6 HEMATOSSALPINGE',
  'N83.7 HEMATOMA DO LIGAMENTO LARGO',
  'N83.8 OUTR TRANST N INFL OVAR TROMP FALOP LIG LARG',
  'N83.9 TRANST N INFL OVAR TROMPA FALOP LIG LARGO NE',
  'N84.0 POLIPO DO CORPO DO UTERO',
  'N84.1 POLIPO DO COLO DO UTERO',
  'N84.2 POLIPO DA VAGINA',
  'N84.3 POLIPO DA VULVA',
  'N84.8 POLIPO DE OUTR PARTES DO TRATO GENITAL FEMIN',
  'N84.9 POLIPO DO TRATO GENITAL FEMIN NE',
  'N85.0 HIPERPLASIA GLANDULAR ENDOMETRIAL',
  'N85.1 HIPERPLASIA ADENOMATOSA ENDOMETRIAL',
  'N85.2 HIPERTROFIA DO UTERO',
  'N85.3 SUBINVOLUCAO DO UTERO',
  'N85.4 POSICAO ANORMAL DO UTERO',
  'N85.5 INVERSAO DO UTERO',
  'N85.6 SINEQUIAS INTRA-UTERINAS',
  'N85.7 HEMATOMETRA',
  'N85.8 OUTR TRANST NAO-INFLAM ESPEC DO UTERO',
  'N85.9 TRANST NAO-INFLAM DO UTERO NE',
  'N86   EROSAO E ECTROPIO DO COLO DO UTERO',
  'N87.0 DISPLASIA CERVICAL LEVE',
  'N87.1 DISPLASIA CERVICAL MODERADA',
  'N87.2 DISPLASIA CERVICAL GRAVE NCOP',
  'N87.9 DISPLASIA DO COLO DO UTERO NE',
  'N88.0 LEUCOPLASIA DO COLO DO UTERO',
  'N88.1 LACERACAO ANTIGA DO COLO DO UTERO',
  'N88.2 ESTREITAMENTO E ESTENOSE DO COLO DO UTERO',
  'N88.3 INCOMPETENCIA DO COLO DO UTERO',
  'N88.4 ALONGAMENTO HIPERTROFICO DO COLO DO UTERO',
  'N88.8 OUTR TRANST NAO-INFLAM ESPEC COLO DO UTERO',
  'N88.9 TRANST NAO-INFLAM E NE DO COLO DO UTERO',
  'N89.0 DISPLASIA VAGINAL LEVE',
  'N89.1 DISPLASIA VAGINAL MODERADA',
  'N89.2 DISPLASIA VAGINAL GRAVE NCOP',
  'N89.3 DISPLASIA DA VAGINA NE',
  'N89.4 LEUCOPLASIA DA VAGINA',
  'N89.5 ESTREITAMENTO E ATRESIA DA VAGINA',
  'N89.6 ANEL HIMENAL APERTADO',
  'N89.7 HEMATOCOLPOS',
  'N89.8 OUTR TRANST NAO-INFLAM ESPEC DA VAGINA',
  'N89.9 TRANST NAO-INFLAM DA VAGINA NE',
  'N90.0 DISPLASIA VULVAR LEVE',
  'N90.1 DISPLASIA VULVAR MODERADA',
  'N90.2 DISPLASIA VULVAR GRAVE NCOP',
  'N90.3 DISPLASIA DE VULVA NE',
  'N90.4 LEUCOPLASIA DE VULVA',
  'N90.5 ATROFIA DA VULVA',
  'N90.6 HIPERTROFIA DA VULVA',
  'N90.7 CISTO VULVAR',
  'N90.8 OUTR TRANST NAO-INFLAM ESPEC VULVA E PERINEO',
  'N90.9 TRANST NAO-INFLAM E NE DA VULVA E DO PERINEO',
  'N91.0 AMENORREIA PRIM',
  'N91.1 AMENORREIA SECUND',
  'N91.2 AMENORREIA NE',
  'N91.3 OLIGOMENORREIA PRIM',
  'N91.4 OLIGOMENORREIA SECUND',
  'N91.5 OLIGOMENORREIA NE',
  'N92.0 MENSTRUACAO EXCESSIVA FREQ C/CICLO REGULAR',
  'N92.1 MENSTRUACAO EXCESSIVA FREQ C/CICLO IRREGULAR',
  'N92.2 MENSTRUACAO EXCESSIVA NA PUBERDADE',
  'N92.3 SANGRAMENTO DA OVULACAO',
  'N92.4 SANGRAMENTO ABUNDANTE NA PRE-MENOPAUSA',
  'N92.5 OUTR TIPOS ESPEC IRREGULARIDADE MENSTRUACAO',
  'N92.6 MENSTRUACAO IRREGULAR NE',
  'N93.0 SANGRAMENTOS POS-COITO OU DE CONTATO',
  'N93.8 OUTR SANGRAMENTOS ANORM ESPEC UTERO VAGINA',
  'N93.9 SANGRAMENTO ANORMAL DO UTERO OU DA VAGINA NE',
  'N94.0 OVULACAO DOLOROSA',
  'N94.1 DISPAREUNIA',
  'N94.2 VAGINISMO',
  'N94.3 SINDR DE TENSAO PRE-MENSTRUAL',
  'N94.4 DISMENORREIA PRIM',
  'N94.5 DISMENORREIA SECUND',
  'N94.6 DISMENORREIA NE',
  'N94.8 OUTR AFEC ESP ASS ORG GENIT FEM CICLO MENSTR',
  'N94.9 AFECC NE ASSOC ORG GENIT FEMIN CICLO MENSTR',
  'N95.0 SANGRAMENTO POS-MENOPAUSA',
  'N95.1 ESTADO DA MENOPAUSA E DO CLIMATERIO FEMIN',
  'N95.2 VAGINITE ATROFICA POS-MENOPAUSA',
  'N95.3 CONDICOES ASSOC C/A MENOPAUSA ARTIFICIAL',
  'N95.8 OUTR TRANST ESPEC MENOPAUSA E PERIMENOPAUSA',
  'N95.9 TRANST NE DA MENOPAUSA E DA PERIMENOPAUSA',
  'N96   ABORTAMENTO HABITUAL',
  'N97.0 INFERTILIDADE FEMIN ASSOC A ANOVULACAO',
  'N97.1 INFERTILIDADE FEMIN ORIG TUBARIA',
  'N97.2 INFERTILIDADE FEMIN ORIG UTERINA',
  'N97.3 INFERTILIDADE FEMIN ORIG CERVICAL',
  'N97.4 INFERTILIDADE FEMIN ASSOC FATORES PARCEIRO',
  'N97.8 INFERTILIDADE FEMIN DE OUTR ORIGEM',
  'N97.9 INFERTILIDADE FEMIN NE',
  'N98.0 INFECC ASSOC A INSEMINACAO ARTIFICIAL',
  'N98.1 HIPERESTIMULACAO DOS OVARIOS',
  'N98.2 COMPLIC RELAC TENT INTROD OVULO FECUND ARTIF',
  'N98.3 COMPLIC RELAC TENT TRANSFERENCIA DO EMBRIAO',
  'N98.8 OUTR COMPLIC ASSOC A FECUNDACAO ARTIFICIAL',
  'N98.9 COMPLIC NE ASSOC A FECUNDACAO ARTIFICIAL',
  'N99.0 INSUF RENAL POS-PROCED',
  'N99.1 ESTREITAMENTO DE URETRA POS-PROCED',
  'N99.2 ADERENCIAS POS-OPERATORIAS DA VAGINA',
  'N99.3 PROLAPSO CUPULA DE VAGINA POS-HISTERECTOMIA',
  'N99.4 ADERENCIAS DO PERITONIO PELVICO POS-PROCED',
  'N99.5 MAU FUNCIONAMENTO ABERTURA EXT TRATO URIN',
  'N99.8 OUTR TRANST POS-PROCED AP GENITURINARIO',
  'N99.9 TRANST POS-PROCED NE APARELHO GENITURINARIO',
  'O00.0 GRAVIDEZ ABDOMINAL',
  'O00.1 GRAVIDEZ TUBARIA',
  'O00.2 GRAVIDEZ OVARIANA',
  'O00.8 OUTR FORM DE GRAVIDEZ ECTOPICA',
  'O00.9 GRAVIDEZ ECTOPICA NE',
  'O01.0 MOLA HIDATIFORME CLASSICA',
  'O01.1 MOLA HIDATIFORME INCOMPLETA OU PARCIAL',
  'O01.9 MOLA HIDATIFORME NE',
  'O02.0 OVO CLARO E MOLA NAO-HIDATIFORME',
  'O02.1 ABORTO RETIDO',
  'O02.8 OUTR PRODUTOS ANORMAIS DA CONCEPCAO ESPEC',
  'O02.9 PRODUTO ANORMAL DA CONCEPCAO NE',
  'O03.0 INCOMPL COMPLIC INF TRATO GENIT ORG PELVICOS',
  'O03.1 INCOMPL COMPLIC HEMORRAGIA EXCESSIVA OU TARD',
  'O03.2 INCOMPLETO COMPLICADO P/EMBOLIA',
  'O03.3 INCOMPLETO C/OUTR COMPLIC OU C/COMPLIC NE',
  'O03.4 INCOMPLETO S/COMPLIC',
  'O03.5 COMPL OU NE COMPLIC INF TRATO GEN ORG PELVIC',
  'O03.6 COMPL OU NE COMPLIC HEMORRAG EXCESS OU TARD',
  'O03.7 COMPLETO OU NE COMPLICADO P/EMBOLIA',
  'O03.8 COMPL OU NE C/OUTR COMPLIC OU C/COMPLIC NE',
  'O03.9 COMPLETO OU NE S/COMPLIC',
  'O04.0 INCOMPL COMPLIC INF TRATO GENIT ORG PELVICOS',
  'O04.1 INCOMPL COMPLIC HEMORRAGIA EXCESSIVA OU TARD',
  'O04.2 INCOMPLETO COMPLICADO P/EMBOLIA',
  'O04.3 INCOMPLETO C/OUTR COMPLIC OU C/COMPLIC NE',
  'O04.4 INCOMPLETO S/COMPLIC',
  'O04.5 COMPL OU NE COMPLIC INF TRATO GEN ORG PELVIC',
  'O04.6 COMPL OU NE COMPLIC HEMORRAG EXCESS OU TARD',
  'O04.7 COMPLETO OU NE COMPLICADO P/EMBOLIA',
  'O04.8 COMPL OU NE C/OUTR COMPLIC OU C/COMPLIC NE',
  'O04.9 COMPLETO OU NE S/COMPLIC',
  'O05.0 INCOMPL COMPLIC INF TRATO GENIT ORG PELVICOS',
  'O05.1 INCOMPL COMPLIC HEMORRAGIA EXCESSIVA OU TARD',
  'O05.2 INCOMPLETO COMPLICADO P/EMBOLIA',
  'O05.3 INCOMPLETO C/OUTR COMPLIC OU C/COMPLIC NE',
  'O05.4 INCOMPLETO S/COMPLIC',
  'O05.5 COMPL OU NE COMPLIC INF TRATO GEN ORG PELVIC',
  'O05.6 COMPL OU NE COMPLIC HEMORRAG EXCESS OU TARD',
  'O05.7 COMPLETO OU NE COMPLICADO P/EMBOLIA',
  'O05.8 COMPL OU NE C/OUTR COMPLIC OU C/COMPLIC NE',
  'O05.9 COMPLETO OU NE S/COMPLIC',
  'O06.0 INCOMPL COMPLIC INF TRATO GENIT ORG PELVICOS',
  'O06.1 INCOMPL COMPLIC HEMORRAGIA EXCESSIVA OU TARD',
  'O06.2 INCOMPLETO COMPLICADO P/EMBOLIA',
  'O06.3 INCOMPLETO C/OUTR COMPLIC OU C/COMPLIC NE',
  'O06.4 INCOMPLETO S/COMPLIC',
  'O06.5 COMPL OU NE COMPLIC INF TRATO GEN ORG PELVIC',
  'O06.6 COMPL OU NE COMPLIC HEMORRAG EXCESS OU TARD',
  'O06.7 COMPLETO OU NE COMPLICADO P/EMBOLIA',
  'O06.8 COMPL OU NE C/OUTR COMPLIC OU C/COMPLIC NE',
  'O06.9 COMPLETO OU NE S/COMPLIC',
  'O07.0 FALH ABORT RAZ MED COMPL INF TR GEN ORG PELV',
  'O07.1 FALH ABORT PROV RAZ MED COMPL HEMOR TARD EXC',
  'O07.2 FALH ABORT PROV RAZ MED COMPLICADO P/EMBOLIA',
  'O07.3 FALHA ABORTO PROV RAZ MEDIC OUTR COMPLIC NE',
  'O07.4 FALHA ABORTO PROV RAZ MEDIC S/COMPLIC',
  'O07.5 OUT FOR NE FAL ABOR COMP INF TR GEN ORG PELV',
  'O07.6 OUT FORM NE FALH ABORT COMPL HEMOR TARD EXC',
  'O07.7 OUTR FORM E NE FALH PROV ABORT COMPL EMBOLIA',
  'O07.8 OUTR FORM E NE FALH PROV ABORT OUTR COMPL NE',
  'O07.9 OUTR FORM E NE FALHA PROV ABORTO S/COMPLIC',
  'O08.0 INF TR GEN ORG PEL CONS ABORT GRAV ECT MOLAR',
  'O08.1 HEMORR TARD EXCESS CONS ABORT GRAV ECT MOLAR',
  'O08.2 EMBOLIA CONSEQ ABORTO GRAV ECTOPICA MOLAR',
  'O08.3 CHOQUE CONSEQ ABORTO GRAVIDEZ ECTOPICA MOLAR',
  'O08.4 INSUF RENAL CONSEQ ABORTO GRAV ECTOPIC MOLAR',
  'O08.5 DIST METAB CONSEQ ABORTO GRAV ECTOPIC MOLAR',
  'O08.6 LESAO ORG TEC PELV CONS ABORT GRAV ECT MOLAR',
  'O08.7 OUTR COMPL VENOSAS CONS ABORT GRAV ECT MOLAR',
  'O08.8 OUTR COMPLIC CONSEQ ABORTO GRAV ECTOP MOLAR',
  'O08.9 COMPLIC CONSEQ ABORTO GRAV ECTOPICA MOLAR NE',
  'O10.0 HIPERTENS ESS PRE-EXIS COMP GRAV PARTO PUERP',
  'O10.1 DOEN CARD HIPER PRE-EX COMP GRAV PARTO PUERP',
  'O10.2 DOEN RENAL HIPER PRE-EX COMP GRAV PART PUERP',
  'O10.3 DOEN CARD REN HIP PRE-EX COMP GRAV PART PUER',
  'O10.4 HIPERTENS SEC PRE-EXIST COMP GRAV PARTO PUER',
  'O10.9 HIPERTENS PRE-EXIST NE COMPL GRAV PARTO PUER',
  'O11   DIST HIPERTENS PRE-EXIST PROTEINURIA SUPERP',
  'O12.0 EDEMA GESTACIONAL',
  'O12.1 PROTEINURIA GESTACIONAL',
  'O12.2 EDEMA GESTACIONAL C/PROTEINURIA',
  'O13   HIPERTENSAO GESTACIONAL S/PROTEINURIA SIGNIF',
  'O14.0 PRE-ECLAMPSIA MODERADA',
  'O14.1 PRE-ECLAMPSIA GRAVE',
  'O14.9 PRE-ECLAMPSIA NE',
  'O15.0 ECLAMPSIA NA GRAVIDEZ',
  'O15.1 ECLAMPSIA NO TRABALHO DE PARTO',
  'O15.2 ECLAMPSIA NO PUERPERIO',
  'O15.9 ECLAMPSIA NE QUANTO AO PERIODO',
  'O16   HIPERTENSAO MATERNA NE',
  'O20.0 AMEACA DE ABORTO',
  'O20.8 OUTR HEMORRAGIAS DO INICIO DA GRAVIDEZ',
  'O20.9 HEMORRAGIA DO INICIO DA GRAVIDEZ NE',
  'O21.0 HIPEREMESE GRAVIDICA LEVE',
  'O21.1 HIPEREMESE GRAVIDICA C/DISTURBIO METABOLICO',
  'O21.2 VOMITOS TARD DA GRAVIDEZ',
  'O21.8 OUTR FORM DE VOMITOS COMPLICANDO A GRAVIDEZ',
  'O21.9 VOMITOS DA GRAVIDEZ NE',
  'O22.0 VARIZES DOS MEMBROS INFER NA GRAVIDEZ',
  'O22.1 VARIZES GENITAIS NA GRAVIDEZ',
  'O22.2 TROMBOFLEBITE SUPERF NA GRAVIDEZ',
  'O22.3 FLEBOTROMBOSE PROFUNDA NA GRAVIDEZ',
  'O22.4 HEMORROIDAS NA GRAVIDEZ',
  'O22.5 TROMBOSE VENOSA CEREBRAL NA GRAVIDEZ',
  'O22.8 OUTR COMPLIC VENOSAS NA GRAVIDEZ',
  'O22.9 COMPLIC VENOSAS NA GRAVIDEZ NE',
  'O23.0 INFECC DO RIM NA GRAVIDEZ',
  'O23.1 INFECC DA BEXIGA NA GRAVIDEZ',
  'O23.2 INFECC DA URETRA NA GRAVIDEZ',
  'O23.3 INFECC OUTR PARTES TRATO URINARIO GRAVIDEZ',
  'O23.4 INFECC NE DO TRATO URINARIO NA GRAVIDEZ',
  'O23.5 INFECC DO TRATO GENITAL NA GRAVIDEZ',
  'O23.9 OUTR INFECC E NE TRATO URINARIO NA GRAVIDEZ',
  'O24.0 DIABETES MELLITUS PRE-EXIST INSULINO-DEPEND',
  'O24.1 DIABETES MELLITUS PRE-EXIST NAO-INSULINO-DEP',
  'O24.2 DIABETES MELLITUS PRE-EXISTENTE REL DESNUTR',
  'O24.3 DIABETES MELLITUS PRE-EXISTENTE NE',
  'O24.4 DIABETES MELLITUS QUE SURGE DURANTE GRAVIDEZ',
  'O24.9 DIABETES MELLITUS NA GRAVIDEZ NE',
  'O25   DESNUTRIC NA GRAVIDEZ',
  'O26.0 GANHO EXCESSIVO DE PESO NA GRAVIDEZ',
  'O26.1 GANHO DE PESO INSUFICIENTE NA GRAVIDEZ',
  'O26.2 ASSIST GRAVIDEZ P/ ABORTAMENTO HABITUAL',
  'O26.3 RETENCAO DISP INTRA-UTERINO CONTRACEPT GRAV',
  'O26.4 HERPES GESTACIONAL',
  'O26.5 SINDR DA HIPOTENSAO MATERNA',
  'O26.6 TRANST DO FIGADO NA GRAVIDEZ PARTO PUERPERIO',
  'O26.7 SUBLUXACAO SINFISE GRAVIDEZ PARTO PUERPERIO',
  'O26.8 OUTR AFECCOES ESPEC LIGADAS A GRAVIDEZ',
  'O26.9 AFECCOES LIGADAS A GRAVIDEZ NE',
  'O28.0 ACHADOS HEMATOLOG ANORM RASTR ANTENATAL MAE',
  'O28.1 ACHADOS BIOQUIM ANORM RASTR ANTENATAL MAE',
  'O28.2 ACHADOS CITOLOG ANORM RASTR ANTENATAL MAE',
  'O28.3 ACHADOS ULTRASSON ANORM RASTR ANTENATAL MAE',
  'O28.4 ACHADOS RADIOLOG ANORM RASTR ANTENATAL MAE',
  'O28.5 ACHADOS CROMOS GENET ANORM RASTR ANTENAT MAE',
  'O28.8 OUTR ACHADOS ANORM RASTR ANTENATAL DA MAE',
  'O28.9 ACHADOS ANORM RASTR ANTENATAL DA MAE NE',
  'O29.0 COMPLIC PULM ANESTESIA ADMIN DURANTE GRAV',
  'O29.1 COMPLIC CARD ANESTESIA ADMIN DURANTE GRAV',
  'O29.2 COMPLIC SIST NERV CENTR DEV ANEST ADMIN GRAV',
  'O29.3 REACAO TOXICA ANEST LOCAL ADMIN DURANTE GRAV',
  'O29.4 CEFALEIA ANEST RAQUID PERIDUR ADMIN DUR GRAV',
  'O29.5 OUTR COMPL ANEST RAQ PERIDUR ADMIN DUR GRAV',
  'O29.6 FALHA DIFICULD NA ENTUBACAO DURANTE GRAVIDEZ',
  'O29.8 OUTR COMPLIC DE ANESTESIA DURANTE A GRAVIDEZ',
  'O29.9 COMPLIC DE ANESTESIA DURANTE A GRAVIDEZ NE',
  'O30.0 GRAVIDEZ DUPLA',
  'O30.1 GRAVIDEZ TRIGEMELAR',
  'O30.2 GRAVIDEZ QUADRIGEMELAR',
  'O30.8 OUTR GESTACOES MULT',
  'O30.9 GESTACAO MULT NE',
  'O31.0 FETO PAPIRACEO',
  'O31.1 CONTINUACAO GRAVIDEZ APOS ABORTO DE FETOS',
  'O31.2 CONTIN GRAV APOS MORTE INTRA-UTER DE FETOS',
  'O31.8 OUTR COMPLIC ESPECIFICAS DE GESTACAO MULT',
  'O32.0 ASSIST PREST MAE P/POSICAO FETAL INSTAVEL',
  'O32.1 ASSIST PRESTADA A MAE P/APRESENTACAO PELVICA',
  'O32.2 ASSIST PREST MAE POS FETAL TRANSV E OBLIQUA',
  'O32.3 ASSIST PREST MAE APRES FACE MENTO E FRONTE',
  'O32.4 ASSIST PREST MAE POLO CEFAL ALTO GEST TERMO',
  'O32.5 ASSIST PREST MAE GEST MULT APRES ANORM FETOS',
  'O32.6 ASSIST PREST MAE P/APRESENTACAO COMPOSTA',
  'O32.8 ASSIST PREST MAE OUTR FORM APRES ANORM FETO',
  'O32.9 ASSIST PREST MAE APRES ANORMAL DO FETO NE',
  'O33.0 ASSIST PREST MAE DESPR DEFORM PELV OSSEA MAT',
  'O33.1 ASSIST PREST MAE DESPROP CONTRAC GERAL PELVE',
  'O33.2 ASSIST PREST MAE DESPR CONTR ESTR SUP PELVE',
  'O33.3 ASSIST PREST MAE DESPR CONTR ESTR INF PELVE',
  'O33.4 ASSIST PREST MAE DESPROP ORIG MISTA MAT FET',
  'O33.5 ASSIST PREST MAE DESPROP FETO EXCEPC GRANDE',
  'O33.6 ASSIST PREST MAE DESPROP FETO HIDROCEFALICO',
  'O33.7 ASSIST PREST MAE DESPROP DEV OUTR DEFORM FET',
  'O33.8 ASSIST PREST MAE DESPROPORCAO DE OUTR ORIGEM',
  'O33.9 ASSIST PRESTADA A MAE P/UMA DESPROPORCAO NE',
  'O34.0 ASSIST A MAE P/MALFORMACAO CONGEN DO UTERO',
  'O34.1 ASSIST PRESTADA A MAE P/TUMOR DO CORPO UTERO',
  'O34.2 ASSIST PREST MAE CICATRIZ UTERINA CIRURG ANT',
  'O34.3 ASSIST PREST MAE P/INCOMPETENCIA CERVICAL',
  'O34.4 ASSIST PREST MAE OUTR ANORM COLO DO UTERO',
  'O34.5 ASSIST PREST MAE OUTR ANORM UTERO GRAVIDICO',
  'O34.6 ASSIST PREST MAE P/ANORMALIDADE DA VAGINA',
  'O34.7 ASSIST PREST MAE P/ANORM DA VULVA E PERINEO',
  'O34.8 ASSIST PREST MAE P/OUTR ANORM ORG PELVICOS',
  'O34.9 ASSIST PREST MAE P/ANORM ORGAOS PELVICOS NE',
  'O35.0 ASSIST PREST MAE MALFORM SIST NERV CENT FETO',
  'O35.1 ASSIST PREST MAE ANORM CROMOSSOMICA DO FETO',
  'O35.2 ASSIST PREST MAE P/DOENC HEREDITARIA DO FETO',
  'O35.3 ASSIST PREST MAE LESAO FET CAUS DOEN VIR MAT',
  'O35.4 ASSIST PREST MAE LESAO CAUS FETO ALCOOL MAT',
  'O35.5 ASSIST PREST MAE LESAO FETAL CAUSADA DROGAS',
  'O35.6 ASSIST PREST MAE LESAO FETAL CAUSADA RADIAC',
  'O35.7 ASSIST PREST MAE LESAO FET CAUS OUT PROC MED',
  'O35.8 ASSIST PREST MAE OUTR ANORM OU LESOES FETAIS',
  'O35.9 ASSIST PREST MAE LESAO OU ANORM FETAIS NE',
  'O36.0 ASSIST PRESTADA A MAE P/ISOIMUNIZACAO RH',
  'O36.1 ASSIST PREST MAE P/OUTR TIPOS ISOIMUNIZACAO',
  'O36.2 ASSIST PRESTADA A MAE P/HIDROPSIA FETAL',
  'O36.3 ASSIST PRESTADA A MAE P/SINAIS HIPOXIA FETAL',
  'O36.4 ASSIST PRESTADA A MAE P/MORTE INTRA-UTERINA',
  'O36.5 ASSIST PRESTADA A MAE P/INSUF CRESC FETAL',
  'O36.6 ASSIST PRESTADA A MAE P/CRESC FETAL EXCESS',
  'O36.7 ASSIST PREST MAE FETO VIAVEL GRAV ABDOMINAL',
  'O36.8 ASSIST PREST MAE OUTR PROBLEMAS FETAIS ESPEC',
  'O36.9 ASSIST PRESTADA A MAE P/PROBLEMA FETAL NE',
  'O40   POLIHIDRAMNIO',
  'O41.0 OLIGOHIDRAMNIO',
  'O41.1 INFECC DO SACO AMNIOTICO E DAS MEMBRANAS',
  'O41.8 OUTR TRANST ESPEC LIQ AMNIOTICO E MEMBRANAS',
  'O41.9 TRANST DO LIQUIDO AMNIOTICO E MEMBRANAS NE',
  'O42.0 RUPTURA PREMAT MEMBR C/INIC TRAB PARTO 24H',
  'O42.1 RUPTURA PREMAT MEMBR INIC TRAB PARTO DEP 24H',
  'O42.2 RUPTURA PREMAT MEMBR TRAB PARTO RETARD TERAP',
  'O42.9 RUPTURA PREMATURA DE MEMBRANAS NE',
  'O43.0 SINDR DE TRANSFUSAO PLACENTARIA',
  'O43.1 MALFORMACAO DA PLACENTA',
  'O43.8 OUTR TRANST DA PLACENTA',
  'O43.9 TRANST DA PLACENTA NE',
  'O44.0 PLACENTA PREVIA ESPEC COMO S/HEMORRAGIA',
  'O44.1 PLACENTA PREVIA C/HEMORRAGIA',
  'O45.0 DESCOLAMENTO PREMAT PLACENTA C/DEFIC COAGUL',
  'O45.8 OUTR DESCOLAMENTO PREMATURO DA PLACENTA',
  'O45.9 DESCOLAMENTO PREMATURO DA PLACENTA NE',
  'O46.0 HEMORRAGIA ANTEPARTO C/DEFIC DE COAGULACAO',
  'O46.8 OUTR FORM DE HEMORRAGIA ANTEPARTO',
  'O46.9 HEMORRAGIA ANTEPARTO NE',
  'O47.0 FALSO TRAB PARTO ANTES DE 37 SEM GESTACAO',
  'O47.1 FALSO TRAB PARTO 37Í SEM COMPLETA OU DEPOIS',
  'O47.9 FALSO TRABALHO DE PARTO NE',
  'O48   GRAVIDEZ PROLONGADA',
  'O60   PARTO PRE-TERMO',
  'O61.0 FALHA NA INDUCAO MEDICA DO TRABALHO DE PARTO',
  'O61.1 FALHA INDUCAO INSTRUMENTAL TRABALHO DE PARTO',
  'O61.8 OUTR FORM FALHA NA INDUCAO TRABALHO DE PARTO',
  'O61.9 FALHA NA INDUCAO DO TRABALHO DE PARTO NE',
  'O62.0 CONTRACOES INICIAIS INADEQUADAS',
  'O62.1 INERCIA UTERINA SECUND',
  'O62.2 OUTR FORM DE INERCIA UTERINA',
  'O62.3 TRABALHO DE PARTO PRECIPITADO',
  'O62.4 CONTRAC UTERINAS HIPERTON INCOORD OU PROLONG',
  'O62.8 OUTR ANORMALIDADES DA CONTRACAO UTERINA',
  'O62.9 ANORMALIDADES DA CONTRACAO UTERINA NE',
  'O63.0 PROLONGAMENTO DO PRIMEIRO PERIODO',
  'O63.1 PROLONGAMENTO DO SEGUNDO PERIODO',
  'O63.2 PARTO RETARDADO SEGUNDO TERCEIRO GEMEO ETC',
  'O63.9 TRABALHO DE PARTO PROLONGADO NE',
  'O64.0 OBSTR TRAB PART DEV ROT INCOMP POLO CEF FET',
  'O64.1 OBSTR TRAB PARTO DEV APRESENTACAO PELVICA',
  'O64.2 OBSTR TRAB PARTO DEV APRESENTACAO DE FACE',
  'O64.3 OBSTR TRAB PARTO DEV APRESENTACAO DE FRONTE',
  'O64.4 OBSTR TRAB PARTO DEV APRESENTACAO DE OMBRO',
  'O64.5 OBSTR TRAB PARTO DEV APRESENTACAO COMPLEXA',
  'O64.8 OBSTR TRAB PART DEV OUT FORM MA-APRES MA-POS',
  'O64.9 OBSTR TRAB PARTO DEV MA-POSIC OU MA-APRES NE',
  'O65.0 OBSTR TRABALHO DE PARTO DEV DEFORM PELVICA',
  'O65.1 OBSTR TRAB PARTO DEV CONTRACAO GERAL PELVE',
  'O65.2 OBSTR TRAB PARTO DEV CONTRACAO ESTREITO SUP',
  'O65.3 OBSTR TRAB PARTO DEV CONTR ESTR INF CAV MED',
  'O65.4 OBSTR TRAB PARTO DEV DESPROP FETO-PELVICA NE',
  'O65.5 OBSTR TRAB PARTO DEV ANORM ORG PELVICOS MAT',
  'O65.8 OBSTR TRAB PARTO DEV OUTR ANORM PELVICAS MAT',
  'O65.9 OBSTR TRAB PARTO DEV ANORM PELVICAS MAT NE',
  'O66.0 OBSTR TRAB PARTO DEV DISTOCIA DE OMBRO',
  'O66.1 OBSTR TRABALHO DE PARTO P/COLIS DE GEMEOS',
  'O66.2 OBSTR TRAB PARTO P/FETO EXCEPC GRANDE',
  'O66.3 OBSTR TRAB PARTO DEV OUTR ANORM DO FETO',
  'O66.4 FALHA DE TENTATIVA DE TRABALHO DE PARTO NE',
  'O66.5 FALHA APLICACAO FORCEPS E VACUO-EXTRATOR NE',
  'O66.8 OUTR FORM DE OBSTRUCAO DO TRAB PARTO ESPEC',
  'O66.9 OBSTRUCAO DO TRABALHO DE PARTO NE',
  'O67.0 HEMORRAGIA INTRAPARTO C/DEFIC DE COAGULACAO',
  'O67.8 OUTR HEMORRAGIAS INTRAPARTO',
  'O67.9 HEMORRAGIA INTRAPARTO NE',
  'O68.0 TRAB PARTO E PARTO COMPL ANORM FREQ CARD FET',
  'O68.1 TRAB PARTO E PARTO COMPL MECONIO LIQ AMNIOT',
  'O68.2 TRAB PART PART ANOR FRQ CARD FET MEC LIQ AMN',
  'O68.3 TRAB PARTO E PARTO COMPL EVID BIOQ SOFR FET',
  'O68.8 TRAB PARTO E PARTO COMPL OUTR EVID SOFR FET',
  'O68.9 TRAB PARTO E PARTO COMPLIC SOFR FETAL NE',
  'O69.0 TRAB PARTO E PARTO COMPLIC PROLAPSO CORDAO',
  'O69.1 TRAB PART PART COMPL CIRCUL CORDAO C/COMPRES',
  'O69.2 TRAB PARTO E PARTO COMPL OUTR ENOVEL CORDAO',
  'O69.3 TRAB PARTO E PARTO COMPLIC P/CORDAO CURTO',
  'O69.4 TRAB PARTO E PARTO COMPLIC P/VASA PRAEVIA',
  'O69.5 TRAB PARTO E PARTO COMPLIC LESAO VASC CORDAO',
  'O69.8 TRAB PARTO E PARTO COMPLIC OUTR COMPL CORDAO',
  'O69.9 TRAB PARTO E PARTO COMPLIC CORDAO NE',
  'O70.0 LACERACAO PERINEO 1. GRAU DURANTE O PARTO',
  'O70.1 LACERACAO PERINEO 2. GRAU DURANTE O PARTO',
  'O70.2 LACERACAO PERINEO 3. GRAU DURANTE O PARTO',
  'O70.3 LACERACAO PERINEO 4. GRAU DURANTE O PARTO',
  'O70.9 LACERACAO DE PERINEO DURANTE O PARTO NE',
  'O71.0 RUPTURA DO UTERO ANTES INICIO TRAB PARTO',
  'O71.1 RUPTURA DO UTERO DURANTE O TRABALHO DE PARTO',
  'O71.2 INVERSAO DO UTERO POS-PARTO',
  'O71.3 LACERACAO OBSTETRICA DO COLO DO UTERO',
  'O71.4 LACERACAO VAGINAL ALTA ISOLADA ORIG OBSTETR',
  'O71.5 OUTR LESOES OBSTETRICAS AOS ORGAOS PELVICOS',
  'O71.6 LESAO OBSTETR ARTIC E LIGAMENTOS PELVICOS',
  'O71.7 HEMATOMA OBSTETRICO DA PELVE',
  'O71.8 OUTR TRAUM OBSTETRICOS ESPEC',
  'O71.9 TRAUM OBSTETRICO NE',
  'O72.0 HEMORRAGIA DO TERCEIRO ESTAGIO',
  'O72.1 OUTR HEMORRAGIAS DO POS-PARTO IMEDIATO',
  'O72.2 HEMORRAGIAS POS-PARTO TARD E SECUND',
  'O72.3 DEFIC DE COAGULACAO POS-PARTO',
  'O73.0 RETENCAO DA PLACENTA S/HEMORRAGIA',
  'O73.1 RETENCAO PARTES PLACENTA OU MEMBR S/HEMORRAG',
  'O74.0 PNEUMONITE ASPIRAT DEV ANEST TRAB PARTO PART',
  'O74.1 OUTR COMPLIC PULM DEV ANEST TRAB PARTO PARTO',
  'O74.2 COMPLIC CARD DEV ANEST TRAB PARTO E PARTO',
  'O74.3 COMP SIST NERV CENT DEV ANEST TRAB PART PART',
  'O74.4 REACAO TOXICA ANEST LOCAL TRAB PARTO E PARTO',
  'O74.5 CEFAL POS-ANEST RAQUID PERID TRAB PARTO PART',
  'O74.6 OUTR COMPL ANEST RAQUID PERID TRAB PART PART',
  'O74.7 FALHA OU DIFIC ENTUBACAO TRAB PARTO E PARTO',
  'O74.8 OUTR COMPLIC ANEST DURANTE TRAB PARTO PARTO',
  'O74.9 COMPLIC ANEST DURANTE TRAB PARTO E PARTO NE',
  'O75.0 SOFRIM MATERNO DURANTE TRAB PARTO E PARTO',
  'O75.1 CHOQUE DURANTE OU SUBSEQ TRAB PARTO E PARTO',
  'O75.2 FEBRE DURANTE O TRABALHO DE PARTO NCOP',
  'O75.3 OUTR INFECC DURANTE O TRABALHO DE PARTO',
  'O75.4 OUTR COMPLIC PROCED OU CIRURGIA OBSTETRICA',
  'O75.5 DEMORA DO PARTO APOS RUPTURA ARTIF MEMBRANAS',
  'O75.6 DEMORA PARTO APOS RUPTURA ESPONT OU NE MEMBR',
  'O75.7 PARTO VAGINAL SUBSEQ CESARIANA ANTERIOR',
  'O75.8 OUTR COMPLIC ESPEC TRAB PARTO E DO PARTO',
  'O75.9 COMPLIC DO TRABALHO DE PARTO E DO PARTO NE',
  'O80.0 PARTO ESPONTANEO CEFALICO',
  'O80.1 PARTO ESPONTANEO PELVICO',
  'O80.8 OUTR TIPOS DE PARTO UNICO ESPONTANEO',
  'O80.9 PARTO UNICO ESPONTANEO NE',
  'O81.0 PARTO P/FORCEPS BAIXO',
  'O81.1 PARTO P/FORCEPS DA CAVIDADE MEDIANA',
  'O81.2 FORCEPS DA CAVIDADE MEDIANA C/ROTACAO',
  'O81.3 OUTR TIPOS DE PARTO P/FORCEPS E OS NE',
  'O81.4 PARTO P/VACUO-EXTRATOR',
  'O81.5 PARTO P/COMBINACAO FORCEPS E VACUO-EXTRATOR',
  'O82.0 PARTO P/CESARIANA ELETIVA',
  'O82.1 PARTO P/CESARIANA DE EMERGENCIA',
  'O82.2 PARTO P/CESARIANA P/HISTERECTOMIA',
  'O82.8 OUTR TIPOS DE PARTO UNICO P/CESARIANA',
  'O82.9 PARTO P/CESARIANA NE',
  'O83.0 EXTRACAO PELVICA',
  'O83.1 OUTR TIPOS DE PARTO PELVICO ASSISTIDO',
  'O83.2 OUTR FORM DE PARTO C/O AUXILIO MANIPULACAO',
  'O83.3 PARTO DE GRAV INTRA-ABDOMINAL FETO VIAVEL',
  'O83.4 CIRURGIA DESTRUTIVA P/FACILITAR O PARTO',
  'O83.8 OUTR TIPOS ESPEC DE PARTO UNICO ASSISTIDO',
  'O83.9 PARTO UNICO ASSISTIDO NE',
  'O84.0 PARTO MULT TODOS ESPONTANEOS',
  'O84.1 PARTO MULT TODOS P/FORCEPS OU VACUO-EXTRATOR',
  'O84.2 PARTO MULT TODOS P/CESARIANA',
  'O84.8 OUTR TIPO DE PARTO MULT',
  'O84.9 PARTO MULT NE',
  'O85   INFECC PUERPERAL',
  'O86.0 INFECC DA INCISAO CIRURGICA ORIG OBSTETRICA',
  'O86.1 OUTR INFECC DOS ORGAOS GENITAIS SUBSEQ PARTO',
  'O86.2 INFECC DAS VIAS URINARIAS SUBSEQUENTES PARTO',
  'O86.3 OUTR INFECC VIAS GENITOURIN SUBSEQ PARTO',
  'O86.4 FEBRE ORIG DESCONHECIDA SUBSEQUENTE AO PARTO',
  'O86.8 OUTR INFECC PUERPERAIS ESPEC',
  'O87.0 TROMBOFLEBITE SUPERF NO PUERPERIO',
  'O87.1 TROMBOFLEBITE PROFUNDA NO PUERPERIO',
  'O87.2 HEMORROIDAS NO PUERPERIO',
  'O87.3 TROMBOSE VENOSA CEREBRAL NO PUERPERIO',
  'O87.8 OUTR COMPLIC VENOSAS NO PUERPERIO',
  'O87.9 COMPLIC VENOSAS NO PUERPERIO NE',
  'O88.0 EMBOLIA GASOSA ORIG OBSTETRICA',
  'O88.1 EMBOLIA AMNIOTICA',
  'O88.2 EMBOLIA OBSTETRICA P/COAGULO DE SANGUE',
  'O88.3 EMBOLIA SEPTICA OU PIEMICA ORIG OBSTETRICA',
  'O88.8 OUTR EMBOLIAS ORIG OBSTETRICA',
  'O89.0 COMPLIC PULM ANESTESIA DURANTE PUERPERIO',
  'O89.1 COMPLIC CARD DEV ANESTESIA DURANTE PUERPERIO',
  'O89.2 COMPL REL SIST NERV CENT DEV ANEST PUERPERIO',
  'O89.3 REACAO TOXICA ANEST LOCAL DURANTE PUERPERIO',
  'O89.4 CEFALEIA PROV ANEST RAQUID PERID PUERPERIO',
  'O89.5 OUTR COMPL ANEST RAQUID PERID PUERPERIO',
  'O89.6 FALHA DIFIC DE ENTUBACAO DURANTE PUERPERIO',
  'O89.8 OUTR COMPLIC ANESTESIA DURANTE O PUERPERIO',
  'O89.9 COMPLIC DEV ANESTESIA DURANTE O PUERPERIO NE',
  'O90.0 RUPTURA DA INCISAO DE CESARIANA',
  'O90.1 RUPTURA DA INCISAO OBSTETRICA NO PERINEO',
  'O90.2 HEMATOMA DA INCISAO OBSTETRICA',
  'O90.3 CARDIOMIOPATIA NO PUERPERIO',
  'O90.4 INSUF RENAL AGUDA DO POS-PARTO',
  'O90.5 TIREOIDITE DO POS-PARTO',
  'O90.8 OUTR COMPLIC DO PUERPERIO NCOP',
  'O90.9 COMPLIC DO PUERPERIO NE',
  'O91.0 INFECC DO MAMILO ASSOC AO PARTO',
  'O91.1 ABSCESSO DA MAMA ASSOC AO PARTO',
  'O91.2 MASTITE NAO PURULENTA ASSOC AO PARTO',
  'O92.0 MAMILO RETRAIDO ASSOC AO PARTO',
  'O92.1 FISSURAS DO MAMILO ASSOC AO PARTO',
  'O92.2 OUTR AFECCOES DA MAMA E AS NE ASSOC AO PARTO',
  'O92.3 AGALACTIA',
  'O92.4 HIPOGALACTIA',
  'O92.5 SUSPENSAO DA LACTACAO',
  'O92.6 GALACTORREIA',
  'O92.7 OUTR DISTURBIOS DA LACTACAO E OS NE',
  'O95   MORTE OBSTETRICA DE CAUSA NE',
  'O96   MORTE QQ CAUS OBST MAIS 42D MENOS 1A PARTO',
  'O97   MORTE P/SEQUELAS CAUSAS OBSTETRICAS DIRETAS',
  'O98.0 TUBERC COMPLIC GRAVIDEZ PARTO E PUERPERIO',
  'O98.1 SIFILIS COMPLIC GRAVIDEZ PARTO E PUERPERIO',
  'O98.2 GONORREIA COMPLIC GRAVIDEZ PARTO E PUERPERIO',
  'O98.3 OUT INF VIA TRANS SEX COMPL GRAV PARTO PUERP',
  'O98.4 HEPATITE VIRAL COMPLIC GRAVIDEZ PARTO PUERP',
  'O98.5 OUTR DOENC VIRAIS COMPL GRAVIDEZ PARTO PUERP',
  'O98.6 DOENC CAUS PROTOZOAR COMPL GRAV PARTO PUERP',
  'O98.8 OUT DOEN INF PARAS MAT COMP GRAV PARTO PUERP',
  'O98.9 DOEN INF PARAS MAT NE COMPL GRAV PARTO PUERP',
  'O99.0 ANEMIA COMPLIC GRAVIDEZ PARTO PUERPERIO',
  'O99.1 OUT DOEN SANG HEMAT IMUN COMP GRAV PART PUER',
  'O99.2 DOEN ENDOC NUTR METAB COMPL GRAV PARTO PUERP',
  'O99.3 TRANS MENT DOEN SIS NERV COMP GRAV PART PUER',
  'O99.4 DOENC AP CIRCULAT COMPL GRAVIDEZ PARTO PUERP',
  'O99.5 DOENC AP RESPIRAT COMPL GRAVIDEZ PARTO PUERP',
  'O99.6 DOENC AP DIGESTIV COMPL GRAVIDEZ PARTO PUERP',
  'O99.7 DOENC PELE TEC SUBCUT COMPL GRAV PARTO PUERP',
  'O99.8 OUTR DOENC AFEC ESPEC COMPL GRAV PARTO PUERP',
  'P00.0 FET REC-NASC AFET TRANST MAT HIPERTENSIVOS',
  'P00.1 FET REC-NASC AFET DOENC MAT RENAIS VIAS URIN',
  'P00.2 FET REC-NASC AFET DOENC INFECC PARASIT MAE',
  'P00.3 FET REC-NASC AFET OUTR DOENC CIRC RESP MAT',
  'P00.4 FET REC-NASC AFET TRANST NUTRICIONAIS MATERN',
  'P00.5 FET REC-NASC AFETADOS P/TRAUM MATERNO',
  'P00.6 FET REC-NASC AFET INTERVENCAO CIRURGICA MAE',
  'P00.7 FET REC-NASC AFET OUTR PROCED MED MAE NCOP',
  'P00.8 FET REC-NASC AFET OUTR AFECCOES MATERNAS',
  'P00.9 FET REC-NASC AFETADOS P/AFECCAO MATERNA NE',
  'P01.0 FET REC-NASC AFET INCOMPETENCIA COLO UTERINO',
  'P01.1 FET REC-NASC AFET RUPTURA PREMATURA MEMBR',
  'P01.2 FET REC-NASC AFETADOS P/OLIGOHIDRAMNIO',
  'P01.3 FET REC-NASC AFETADOS P/POLIHIDRAMNIO',
  'P01.4 FET REC-NASC AFETADOS P/GRAVIDEZ ECTOPICA',
  'P01.5 FET REC-NASC AFETADOS P/GRAVIDEZ MULT',
  'P01.6 FET REC-NASC AFETADOS P/MORTE MATERNA',
  'P01.7 FET REC-NASC AFET APRES ANOR ANTES TRAB PART',
  'P01.8 FET REC-NASC AFET OUTR COMPLIC MAT GRAVIDEZ',
  'P01.9 FET REC-NASC AFET AFECC MATERNAS GRAVIDEZ NE',
  'P02.0 FET REC-NASC AFETADOS P/PLACENTA PREVIA',
  'P02.1 FET REC-NASC AFET OUTR FORM DESC PLAC HEMORR',
  'P02.2 FET REC-NASC AFET OUT ANOR MORF FUNC PLAC NE',
  'P02.3 FET REC-NASC AFET SINDR TRANSF PLACENTARIA',
  'P02.4 FET REC-NASC AFET PROLAPSO CORDAO UMBILICAL',
  'P02.5 FET REC-NASC AFET OUTR COMPR CORD UMBILICAL',
  'P02.6 FET REC-NASC AFET OUTR AFECC CORDAO UMB NE',
  'P02.7 FET REC-NASC AFETADOS P/CORIOAMNIONITE',
  'P02.8 FET REC-NASC AFET OUTR ANORM MEMBRANAS',
  'P02.9 FET REC-NASC AFET ANORMALIDADE NE MEMBRANAS',
  'P03.0 FET REC-NASC AFET PARTO E EXTRACAO PELVICAS',
  'P03.1 FET REC-NASC AFET OUT APRES ANOR MA-POS DESP',
  'P03.2 FET REC-NASC AFETADOS P/PARTO P/FORCEPS',
  'P03.3 FET REC-NASC AFET PARTO P/VACUO-EXTRATOR',
  'P03.4 FET REC-NASC AFETADOS P/PARTO P/CESARIANA',
  'P03.5 FET REC-NASC AFETADOS P/PARTO PRECIPITADO',
  'P03.6 FET REC-NASC AFET CONTRACOES UTERINAS ANORM',
  'P03.8 FET REC-NASC AFET OUT COMP ESP TRB PART PART',
  'P03.9 FET REC-NASC AFET COMPL NE TRAB PARTO PARTO',
  'P04.0 FET REC-NASC AFET ANEST ANALG MAT GRAV PARTO',
  'P04.1 FET REC-NASC AFET OUTR MEDIC UTILIZ P/MAE',
  'P04.2 FET REC-NASC AFETADOS P/USO DE FUMO P/MAE',
  'P04.3 FET REC-NASC AFETADOS P/USO DE ALCOOL P/MAE',
  'P04.4 FET REC-NASC AFET USO DROGAQ CAUSAM DEP MAE',
  'P04.5 FET REC-NASC AFET USO MAT SUBST QUIM ALIMENT',
  'P04.6 FET REC-NASC AFET EXP MAE SUBS QUIM MEIO AMB',
  'P04.8 FET REC-NASC AFET OUTR INFL NOCIVAS MATERNAS',
  'P04.9 FET REC-NASC AFET INFL NOCIVAS MATERNAS NE',
  'P05.0 RECEM-NASCIDO DE BAIXO PESO P/A IDADE GESTAC',
  'P05.1 PEQUENO P/A IDADE GESTACIONAL',
  'P05.2 DESNUTR FET S/MENC PESO COMPR BAIX IDAD GEST',
  'P05.9 RETARDO NE DO CRESCIMENTO FETAL',
  'P07.0 RECEM-NASCIDO C/PESO MUITO BAIXO',
  'P07.1 OUTR RECEM-NASCIDOS DE PESO BAIXO',
  'P07.2 IMATURIDADE EXTREMA',
  'P07.3 OUTR RECEM-NASCIDOS DE PRE-TERMO',
  'P08.0 RECEM-NASCIDO TAMANHO EXCESSIVAMENTE GRANDE',
  'P08.1 OUTR RECEM-NASCIDOS GRANDES P/A IDADE GESTAC',
  'P08.2 RECEM-NASC POS-TERMO N-GRANDE IDADE GEST',
  'P10.0 HEMORRAGIA SUBDURAL DEV TRAUM DE PARTO',
  'P10.1 HEMORRAGIA CEREBRAL DEV TRAUM DE PARTO',
  'P10.2 HEMORRAGIA INTRAVENTRICULAR DEV TRAUM PARTO',
  'P10.3 HEMORRAGIA SUBARACNOIDEA DEV TRAUM DE PARTO',
  'P10.4 RUPTURA TENTORIAL DEV TRAUM DE PARTO',
  'P10.8 OUTR LACERAC HEMORR INTRACR DEV TRAUM PARTO',
  'P10.9 LACERAC HEMORR INTRACRAN NE DEV TRAUM PARTO',
  'P11.0 EDEMA CEREBRAL DEV TRAUM DE PARTO',
  'P11.1 OUTR LESOES CEREBRAIS ESPEC DEV TRAUM PARTO',
  'P11.2 LESAO CEREBRAL NE DEV TRAUM DE PARTO',
  'P11.3 TRAUM DE PARTO DO NERVO FACIAL',
  'P11.4 TRAUM DE PARTO DE OUTR NERVOS CRANIANOS',
  'P11.5 TRAUM DE PARTO DA COLUNA E MEDULA ESPINHAL',
  'P11.9 TRAUM DE PARTO NE DO SIST NERVOSO CENTRAL',
  'P12.0 CEFALO-HEMATOMA DEV TRAUM DE PARTO',
  'P12.1 CHIGNON DEV TRAUM DE PARTO',
  'P12.2 HEMORRAG SUBAPONEUR EPICRAN DEV TRAUM PARTO',
  'P12.3 ESMAG COURO CABELUDO DEV TRAUM PARTO',
  'P12.4 LESAO P/MONITORIZ COURO CABELUDO REC-NASC',
  'P12.8 OUTR LESOES COURO CABELUDO DEV TRAUM PARTO',
  'P12.9 LESAO NE DO COURO CABELUDO DEV TRAUM PARTO',
  'P13.0 FRAT DE CRANIO DEV TRAUM DE PARTO',
  'P13.1 OUTR LESOES CRANIANAS DEV TRAUM DE PARTO',
  'P13.2 LESAO DO FEMUR DEV TRAUM DE PARTO',
  'P13.3 LESAO DE OUTR OSSOS LONGOS DEV TRAUM PARTO',
  'P13.4 FRAT DA CLAVICULA DEV TRAUM DE PARTO',
  'P13.8 LESOES OUTR PARTES ESQUELETO DEV TRAUM PARTO',
  'P13.9 LESOES NE DO ESQUELETO DEV TRAUM DE PARTO',
  'P14.0 PARALISIA DE ERB DEV TRAUM DE PARTO',
  'P14.1 PARALISIA DE KLUMPKE DEV TRAUM DE PARTO',
  'P14.2 PARALISIA DO NERVO FRENICO DEV TRAUM PARTO',
  'P14.3 OUTR LESOES PLEXO BRAQUIAL DEV TRAUM PARTO',
  'P14.8 OUT LES OUT PART SIST NERV PERIF TRAUM PARTO',
  'P14.9 LESAO NE SIST NERV PERIF DEV TRAUM DE PARTO',
  'P15.0 LESAO DO FIGADO DEV TRAUM DE PARTO',
  'P15.1 LESAO DO BACO DEV TRAUM DE PARTO',
  'P15.2 LESAO DO ESTERNOMASTOIDE DEV TRAUM DE PARTO',
  'P15.3 LESAO DOS OLHOS DEV TRAUM DE PARTO',
  'P15.4 LESAO DA FACE AO NASCER',
  'P15.5 LESAO ORG GENITAIS EXTERNOS DEV TRAUM PARTO',
  'P15.6 NECROSE GORDURA SUBCUTANEA DEV TRAUM PARTO',
  'P15.8 OUTR TRAUM DE PARTO ESPEC',
  'P15.9 TRAUM DE PARTO NE',
  'P20.0 HIPOXIA INTRA-UTER DIAGN ANT INIC TRAB PARTO',
  'P20.1 HIPOXIA INTRA-UTER DIAGN DUR TRAB PARTO PART',
  'P20.9 HIPOXIA INTRA-UTERINA NE',
  'P21.0 ASFIXIA GRAVE AO NASCER',
  'P21.1 ASFIXIA LEVE OU MODERADA AO NASCER',
  'P21.9 ASFIXIA AO NASCER NE',
  'P22.0 SINDR DA ANGUSTIA RESPIRAT DO RECEM-NASCIDO',
  'P22.1 TAQUIPNEIA TRANSITORIA DO RECEM-NASCIDO',
  'P22.8 OUTR DESCONFORTOS RESPIRAT DO RECEM-NASCIDO',
  'P22.9 DESCONFORTO RESPIRAT NE DO RECEM-NASCIDO',
  'P23.0 PNEUMONIA CONGEN DEV AGENTE VIRAL',
  'P23.1 PNEUMONIA CONGEN DEV CLAMIDIA',
  'P23.2 PNEUMONIA CONGEN DEV ESTAFILOCOCO',
  'P23.3 PNEUMONIA CONGEN DEV ESTREPTOCOCO DO GRUPO B',
  'P23.4 PNEUMONIA CONGEN DEV ESCHERICHIA COLI',
  'P23.5 PNEUMONIA CONGEN DEV A PSEUDOMONAS',
  'P23.6 PNEUMONIA CONGEN DEV OUTR AGENTES BACTER',
  'P23.8 PNEUMONIA CONGEN DEV OUTR ORGANISMOS',
  'P23.9 PNEUMONIA CONGEN NE',
  'P24.0 ASPIRACAO NEONATAL DE MECONIO',
  'P24.1 ASPIRACAO NEONATAL LIQUIDO AMNIOTICO E MUCO',
  'P24.2 ASPIRACAO NEONATAL DE SANGUE',
  'P24.3 ASPIRACAO NEONATAL LEITE ALIMENTO REGURGIT',
  'P24.8 OUTR SINDR DE ASPIRACAO NEONATAIS',
  'P24.9 SINDR DE ASPIRACAO NEONATAL NE',
  'P25.0 ENFISEMA INTERST ORIGINADO PERIODO PERINATAL',
  'P25.1 PNEUMOTORAX ORIGINADO NO PERIODO PERINATAL',
  'P25.2 PNEUMOMEDIASTINO ORIGINADO PERIODO PERINATAL',
  'P25.3 PNEUMOPERICARDIO ORIGINADO PERIODO PERINATAL',
  'P25.8 OUT AFEC REL ENFIS INTERST ORIG PER PERINAT',
  'P26.0 HEMORRAGIA TRAQUEOBRONQ ORIG PERIODO PERINAT',
  'P26.1 HEMORRAGIA PULM MACICA ORIG PERIODO PERINAT',
  'P26.8 OUTR HEMORRAGIAS PULM ORIG PERIODO PERINAT',
  'P26.9 HEMORRAGIA PULMONAR NE ORIG PERIODO PERINAT',
  'P27.0 SINDR DE WILSON-MIKITY',
  'P27.1 DISPLASIA BRONCOPULM ORIG PERIODO PERINATAL',
  'P27.8 OUTR DOENC RESPIRAT CRON ORIG PER PERINATAL',
  'P27.9 DOENC RESPIRAT CRONICA NE ORIG PER PERINATAL',
  'P28.0 ATELECTASIA PRIM DO RECEM-NASCIDO',
  'P28.1 OUTR ATELECTASIAS DO RECEM-NASCIDO E AS NE',
  'P28.2 CRISES CIANOTICAS DO RECEM-NASCIDO',
  'P28.3 APNEIA PRIM DO SONO DO RECEM-NASCIDO',
  'P28.4 OUTR APNEIAS DO RECEM-NASCIDO',
  'P28.5 INSUF RESPIRAT DO RECEM-NASCIDO',
  'P28.8 OUTR AFECCOES RESPIRAT ESPEC DO RECEM-NASC',
  'P28.9 AFECCAO RESPIRAT DO RECEM-NASCIDO NE',
  'P29.0 INSUF CARDIACA NEONATAL',
  'P29.1 DISRITMIA CARDIACA NEONATAL',
  'P29.2 HIPERTENSAO NEONATAL',
  'P29.3 PERSISTENCIA DA CIRCULACAO FETAL',
  'P29.4 ISQUEMIA MIOCARDICA TRANSITORIA RECEM-NASC',
  'P29.8 OUTR TRANST CARDIOVASC ORIG PERIODO PERINAT',
  'P29.9 TRANST CARDIOVASC NE ORIG PERIODO PERINATAL',
  'P35.0 SINDR DA RUBEOLA CONGEN',
  'P35.1 INFECC CONGEN P/CITOMEGALOVIRUS',
  'P35.2 INFECC CONGEN P/VIRUS DO HERPES',
  'P35.3 HEPATITE VIRAL CONGEN',
  'P35.8 OUTR DOENC VIRAIS CONGEN',
  'P35.9 DOENC VIRAL CONGEN NE',
  'P36.0 SEPTICEMIA REC-NASC DEV ESTREPTOCOCO GRUPO B',
  'P36.1 SEPTICEMIA REC-NASC DEV OUTR ESTREPTOC E NE',
  'P36.2 SEPTICEMIA REC-NASC DEV STAFILOCOCCUS AUREUS',
  'P36.3 SEPTICEMIA REC-NASC DEV OUTR ESTAFILOC E NE',
  'P36.4 SEPTICEMIA RECEM-NASC DEV ESCHERICHIA COLI',
  'P36.5 SEPTICEMIA DO RECEM-NASCIDO DEV ANAEROBIOS',
  'P36.8 OUTR SEPTICEMIAS BACTER DO RECEM-NASCIDO',
  'P36.9 SEPTICEMIA BACTER NE DO RECEM-NASCIDO',
  'P37.0 TUBERC CONGEN',
  'P37.1 TOXOPLASMOSE CONGEN',
  'P37.2 LISTERIOSE NEONATAL',
  'P37.3 MALARIA FALCIPARUM CONGEN',
  'P37.4 OUTR MALARIAS CONGEN',
  'P37.5 CANDIDIASE NEONATAL',
  'P37.8 OUTR DOENC INFECC E PARASIT CONGEN ESPEC',
  'P37.9 DOENC INFECC OU PARASIT CONGEN NE',
  'P38   ONFALITE RECEM-NASC C/OU S/HEMORRAGIA LEVE',
  'P39.0 MASTITE INFECC NEONATAL',
  'P39.1 CONJUNTIVITE E DACRIOCISTITE NEONATAIS',
  'P39.2 INFECC INTRA-AMNIOTICA DO FETO NCOP',
  'P39.3 INFECC NEONATAL DO TRATO URINARIO',
  'P39.4 INFECC NEONATAL DA PELE',
  'P39.8 OUTR INFECC ESPEC PROPRIAS PERIODO PERINATAL',
  'P39.9 INFECC PROPRIA DO PERIODO PERINATAL NE',
  'P50.0 PERDA SANGUINEA FETAL DEV VASA PREVIA',
  'P50.1 PERDA SANGUINEA FETAL P/RUPTURA DO CORDAO',
  'P50.2 PERDA SANGUINEA FETAL ORIGINADA DA PLACENTA',
  'P50.3 HEMORRAGIA P/A CIRCULACAO DO OUTR GEMEO',
  'P50.4 HEMORRAGIA P/A CIRCULACAO MATERNA',
  'P50.5 PERDA SANG DEV SECCAO CORD UMB OUTR GEMELAR',
  'P50.8 OUTR PERDAS SANGUINEAS FETAIS',
  'P50.9 PERDA SANGUINEA FETAL NE',
  'P51.0 HEMORRAGIA UMBILICAL MACICA DO RECEM-NASCIDO',
  'P51.8 OUTR HEMORRAGIAS UMBILICAIS DO RECEM-NASCIDO',
  'P51.9 HEMORRAGIA UMBILICAL DO RECEM-NASCIDO NE',
  'P52.0 HEMORRAGIA INTRAVENTR GRAU 1 FETO REC-NASC',
  'P52.1 HEMORRAGIA INTRAVENTR GRAU 2 FETO REC-NASC',
  'P52.2 HEMORRAGIA INTRAVENTR GRAU 3 FETO REC-NASC',
  'P52.3 HEMORRAGIA INTRAVENTR NE FETO RECEM-NASCIDO',
  'P52.4 HEMORRAGIA INTRACEREBR FETO E RECEM-NASCIDO',
  'P52.5 HEMORRAGIA SUBARACNOIDEA FETO RECEM-NASCIDO',
  'P52.6 HEMORR CEREBEL FOSSA POSTERIOR FETO REC-NASC',
  'P52.8 OUTR HEMORRAG INTRACRANIANAS FETO REC-NASC',
  'P52.9 HEMORRAGIA INTRACRANIANA FETO RECEM-NASC NE',
  'P53   DOENC HEMORRAGICA DO FETO E DO RECEM-NASCIDO',
  'P54.0 HEMATEMESE NEONATAL',
  'P54.1 MELENA NEONATAL',
  'P54.2 HEMORRAGIA RETAL NEONATAL',
  'P54.3 OUTR HEMORRAG GASTROINTEST PERIODO NEONATAL',
  'P54.4 HEMORRAGIA SUPRA-RENAL NEONATAL',
  'P54.5 HEMORRAGIA CUTANEA NEONATAL',
  'P54.6 HEMORRAGIA VAGINAL NEONATAL',
  'P54.8 OUTR HEMORRAGIAS FETAIS E NEONATAIS ESPEC',
  'P54.9 HEMORRAGIA FETAL E NEONATAL NE',
  'P55.0 ISOIMUNIZACAO RH DO FETO E DO RECEM-NASCIDO',
  'P55.1 ISOIMUNIZACAO ABO DO FETO E DO RECEM-NASCIDO',
  'P55.8 OUTR DOENC HEMOLITICAS FETO E RECEM-NASCIDO',
  'P55.9 DOENC HEMOLITICA NE DO FETO E RECEM-NASCIDO',
  'P56.0 HIDROPSIA FETAL DEV ISOIMUNIZACAO',
  'P56.9 HIDROPSIA FETAL DEV OUTR DOENC HEMOLIT E NE',
  'P57.0 KERNICTERUS DEV ISOIMUNIZACAO',
  'P57.8 OUTR KERNICTERUS ESPEC',
  'P57.9 KERNICTERUS NE',
  'P58.0 ICTERICIA NEONATAL DEV CONTUSOES',
  'P58.1 ICTERICIA NEONATAL DEV SANGRAMENTO',
  'P58.2 ICTERICIA NEONATAL DEV INFECC',
  'P58.3 ICTERICIA NEONATAL DEV POLICITEMIA',
  'P58.4 ICTER NEONAT DROG TOX TRANS MAE ADM REC-NASC',
  'P58.5 ICTERICIA NEONATAL DEV DEGLUTACAO SANG MAT',
  'P58.8 ICTER NEONAT DEV OUTR HEMOLISES EXCESS ESPEC',
  'P58.9 ICTERICIA NEONATAL DEV HEMOLISE EXCESSIVA NE',
  'P59.0 ICTERICIA NEONATAL ASSOC AO PARTO PREMATURO',
  'P59.1 SINDR DA BILE ESPESSA',
  'P59.2 ICTERICIA NEONAT OUTR LESOES HEPATOCEL E NE',
  'P59.3 ICTERICIA NEONATAL DEV INIBIDORES LEITE MAT',
  'P59.8 ICTERICIA NEONATAL DEV OUTR CAUSAS ESPEC',
  'P59.9 ICTERICIA NEONATAL NE',
  'P60   COAGULACAO INTRAVASC DISSEM FETO REC-NASC',
  'P61.0 TROMBOCITOPENIA TRANSITORIA NEONATAL',
  'P61.1 POLICITEMIA NEONATAL',
  'P61.2 ANEMIA DA PREMATURIDADE',
  'P61.3 ANEMIA CONGEN P/PERDA SANGUINEA FETAL',
  'P61.4 OUTR ANEMIAS CONGEN NCOP',
  'P61.5 NEUTROPENIA TRANSITORIA NEONATAL',
  'P61.6 OUTR TRANST TRANSIT COAG PERIODO NEONATAL',
  'P61.8 OUTR TRANST HEMATOLOGICOS PERINATAIS ESPEC',
  'P61.9 TRANST HEMATOLOGICO PERINATAL NE',
  'P70.0 SINDR DO FILHO DE MAE C/DIABETES GESTACIONAL',
  'P70.1 SINDR DO FILHO DE MAE DIABETICA',
  'P70.2 DIABETES MELLITUS NEONATAL',
  'P70.3 HIPOGLICEMIA NEONATAL IATROGENICA',
  'P70.4 OUTR HIPOGLICEMIAS NEONATAIS',
  'P70.8 OUT TRANS TRANSIT METAB CARBOID FET REC-NASC',
  'P70.9 TRANS TRANSIT NE METAB CARBOIDR FET REC-NASC',
  'P71.0 HIPOCALCEMIA RECEM-NASCIDO P/USO LEITE VACA',
  'P71.1 OUTR HIPOCALCEMIAS NEONATAIS',
  'P71.2 HIPOMAGNESEMIA NEONATAL',
  'P71.3 TETANIA NEONATAL S/DEFIC DE CALCIO MAGNESIO',
  'P71.4 HIPOPARATIROIDISMO NEONATAL TRANSITORIO',
  'P71.8 OUT TRANS TRANSIT METAB CALC MAGN PER NEONAT',
  'P71.9 TRANST TRANSIT NE METAB CALC MAGN PER NEONAT',
  'P72.0 BOCIO NEONATAL NCOP',
  'P72.1 HIPOTIREOIDISMO NEONATAL TRANSITORIO',
  'P72.2 OUTR TRANST TRANSIT FUNCAO DE TIREOIDE NCOP',
  'P72.8 OUTR TRANST ENDOCR TRANSIT ESPEC PER NEONAT',
  'P72.9 TRANST ENDOCRINO NEONATAL TRANSITORIO NE',
  'P74.0 ACIDOSE METABOLICA TARD DO RECEM-NASCIDO',
  'P74.1 DESIDRATACAO DO RECEM-NASCIDO',
  'P74.2 DISTURBIOS EQUILIBRIO SODIO RECEM-NASCIDO',
  'P74.3 DISTURBIOS EQUILIBRIO POTASSIO RECEM-NASCIDO',
  'P74.4 OUTR DIST ELETROLITICOS TRANSIT RECEM-NASC',
  'P74.5 TIROSINEMIA TRANSITORIA DO RECEM-NASCIDO',
  'P74.8 OUTR DISTURBIOS METABOLICOS TRANSIT REC-NASC',
  'P74.9 DISTURBIO METABOLICO TRANSITORIO NE REC-NASC',
  'P75   ILEO MECONIAL',
  'P76.0 SINDR DA ROLHA DE MECONIO',
  'P76.1 ILEO TRANSITORIO DO RECEM-NASCIDO',
  'P76.2 OBSTRUCAO INTESTINAL DEV LEITE ESPESSO',
  'P76.8 OUTR OBSTRUCOES INTESTINAIS ESPEC REC-NASC',
  'P76.9 OBSTRUCAO INTESTINAL NE DO RECEM-NASCIDO',
  'P77   ENTEROCOLITE NECROTIZANTE DO FETO E REC-NASC',
  'P78.0 PERFURACAO INTESTINAL NO PERIODO PERINATAL',
  'P78.1 OUTR PERITONITES NEONATAIS',
  'P78.2 HEMATEMESE MELENA DEV DEGLUTICAO SANGUE MAT',
  'P78.3 DIARREIA NEONATAL NAO-INFECC',
  'P78.8 OUTR TRANST PERINATAIS ESPEC AP DIGESTIVO',
  'P78.9 TRANST PERINATAL NE DO APARELHO DIGESTIVO',
  'P80.0 SINDR DE LESAO P/FRIO',
  'P80.8 OUTR HIPOTERMIAS DO RECEM-NASCIDO',
  'P80.9 HIPOTERMIA NE DO RECEM-NASCIDO',
  'P81.0 HIPERTERMIA AMBIENTAL DO RECEM-NASCIDO',
  'P81.8 OUTR DISTURB ESPEC REGULACAO TERM REC-NASC',
  'P81.9 DISTURBIO NE REGULACAO TERMICA RECEM-NASCIDO',
  'P83.0 ESCLEREMA NEONATAL',
  'P83.1 ERITEMA TOXICO NEONATAL',
  'P83.2 HIDROPSIA FETAL NAO-DEV DOENC HEMOLITICA',
  'P83.3 OUTR EDEMAS E NE PROPRIOS FETO RECEM-NASCIDO',
  'P83.4 INTUMESCIMENTO MAMARIO DO RECEM-NASCIDO',
  'P83.5 HIDROCELE CONGEN',
  'P83.6 POLIPO UMBILICAL DO RECEM-NASCIDO',
  'P83.8 OUTR AFECC ESPEC TEGUM PROPR FETO REC-NASC',
  'P83.9 AFECCAO NE TEGUMENTO PROPRIA FETO REC-NASC',
  'P90   CONVULSOES DO RECEM-NASCIDO',
  'P91.0 ISQUEMIA CEREBRAL NEONATAL',
  'P91.1 CISTOS PERIVENTRICULARES ADQUIR REC-NASC',
  'P91.2 LEUCOMALACIA CEREBRAL NEONATAL',
  'P91.3 IRRITABILIDADE CEREBRAL NEONATAL',
  'P91.4 DEPRESSAO CEREBRAL NEONATAL',
  'P91.5 COMA NEONATAL',
  'P91.8 OUTR DISTURBIOS ESPEC FUNCAO CEREBR REC-NASC',
  'P91.9 DISTURBIO NE DA FUNCAO CEREBRAL REC-NASC',
  'P92.0 VOMITOS NO RECEM-NASCIDO',
  'P92.1 REGURGITACAO E RUMINACAO NO RECEM-NASCIDO',
  'P92.2 ALIMENTACAO VAGAROSA DO RECEM-NASCIDO',
  'P92.3 SUBALIMENTACAO DO RECEM-NASCIDO',
  'P92.4 HIPERALIMENTACAO DO RECEM-NASCIDO',
  'P92.5 DIFICULDADE NEONATAL NA AMAMENTACAO NO PEITO',
  'P92.8 OUTR PROBLEMAS DE ALIMENTACAO RECEM-NASCIDO',
  'P92.9 PROBLEMA NE DE ALIMENTACAO DO RECEM-NASCIDO',
  'P93   REACOES E INTOX DEV DROG ADMIN FETO REC-NASC',
  'P94.0 MIASTENIA GRAVE NEONATAL TRANSITORIA',
  'P94.1 HIPERTONIA CONGEN',
  'P94.2 HIPOTONIA CONGEN',
  'P94.8 OUTR TRANST DO TONUS MUSCULAR RECEM-NASCIDO',
  'P94.9 TRANST NE DO TONUS MUSCULAR DO RECEM-NASCIDO',
  'P95   MORTE FETAL DE CAUSA NE',
  'P96.0 INSUF RENAL CONGEN',
  'P96.1 SINT ABSTINENCIA NEONATAL DROG UTILIZ P/MAE',
  'P96.2 SINT ABSTINENCIA USO DROGAS TERAP REC-NASC',
  'P96.3 SUTURAS CRANIANAS AMPLAS NO RECEM-NASCIDO',
  'P96.4 INTERRUPCAO DE GRAVIDEZ AFET FETO REC-NASC',
  'P96.5 COMPLIC DE PROCED INTRA-UTERINOS NCOP',
  'P96.8 OUTR AFECCOES ESPEC ORIG PERIODO PERINATAL',
  'P96.9 AFECCOES ORIGINADAS NO PERIODO PERINATAL NE',
  'Q00.0 ANENCEFALIA',
  'Q00.1 CRANIORRAQUISQUISE',
  'Q00.2 INIENCEFALIA',
  'Q01.0 ENCEFALOCELE FRONTAL',
  'Q01.1 ENCEFALOCELE NASOFRONTAL',
  'Q01.2 ENCEFALOCELE OCCIPITAL',
  'Q01.8 ENCEFALOCELE DE OUTR LOCALIZ',
  'Q01.9 ENCEFALOCELE NE',
  'Q02   MICROCEFALIA',
  'Q03.0 MALFORMACOES DO AQUEDUTO DE SYLVIUS',
  'Q03.1 ATRESIA FENDAS LUSCHKA E FORAMEN MAGENDIE',
  'Q03.8 OUTR HIDROCEFALIA CONGEN',
  'Q03.9 HIDROCEFALIA CONGEN NE',
  'Q04.0 MALFORMACOES CONGEN DO CORPO CALOSO',
  'Q04.1 ARRINENCEFALIA',
  'Q04.2 HOLOPROSENCEFALIA',
  'Q04.3 OUTR DEFORM P/REDUCAO DO ENCEFALO',
  'Q04.4 DISPLASIA DO SEPTO E DAS VIAS OPTICAS',
  'Q04.5 MEGALENCEFALIA',
  'Q04.6 CISTOS CEREBRAIS CONGEN',
  'Q04.8 OUTR MALFORMACOES CONGEN ESPEC DO ENCEFALO',
  'Q04.9 MALFORMACAO CONGEN NE DO ENCEFALO',
  'Q05.0 ESPINHA BIFIDA CERVICAL C/HIDROCEFALIA',
  'Q05.1 ESPINHA BIFIDA TORACICA C/HIDROCEFALIA',
  'Q05.2 ESPINHA BIFIDA LOMBAR C/HIDROCEFALIA',
  'Q05.3 ESPINHA BIFIDA SACRA C/HIDROCEFALIA',
  'Q05.4 ESPINHA BIFIDA NE C/HIDROCEFALIA',
  'Q05.5 ESPINHA BIFIDA CERVICAL S/HIDROCEFALIA',
  'Q05.6 ESPINHA BIFIDA TORACICA S/HIDROCEFALIA',
  'Q05.7 ESPINHA BIFIDA LOMBAR S/HIDROCEFALIA',
  'Q05.8 ESPINHA BIFIDA SACRA S/HIDROCEFALIA',
  'Q05.9 ESPINHA BIFIDA NE',
  'Q06.0 AMIELIA',
  'Q06.1 HIPOPLASIA E DISPLASIA DA MEDULA ESPINAL',
  'Q06.2 DIASTEMATOMIELIA',
  'Q06.3 OUTR MALFORMACOES CONGEN DA CAUDA EQUINA',
  'Q06.4 HIDROMIELIA',
  'Q06.8 OUTR MALFORM CONGEN ESPEC MEDULA ESPINAL',
  'Q06.9 MALFORMACAO CONGEN NE DA MEDULA ESPINAL',
  'Q07.0 SINDR DE ARNOLD-CHIARI',
  'Q07.8 OUTR MALFORMACOES CONGEN ESPEC SIST NERVOSO',
  'Q07.9 MALFORMACAO CONGEN NE DO SIST NERVOSO',
  'Q10.0 PTOSE CONGEN',
  'Q10.1 ECTROPIO CONGEN',
  'Q10.2 ENTROPIO CONGEN',
  'Q10.3 OUTR MALFORMACOES CONGEN DAS PALPEBRAS',
  'Q10.4 AUSENCIA OU AGENESIA DO APARELHO LACRIMAL',
  'Q10.5 ESTENOSE OU ESTREIT CONGEN CANAL LACRIMAL',
  'Q10.6 OUTR MALFORMACOES CONGEN APARELHO LACRIMAL',
  'Q10.7 MALFORMACAO CONGEN DA ORBITA',
  'Q11.0 OLHO CISTICO',
  'Q11.1 OUTR FORM DE ANOFTALMIA',
  'Q11.2 MICROFTALMIA',
  'Q11.3 MACROFTALMIA',
  'Q12.0 CATARATA CONGEN',
  'Q12.1 LUXACAO CONGEN DO CRISTALINO',
  'Q12.2 COLOBOMA DO CRISTALINO',
  'Q12.3 AFAQUIA CONGEN',
  'Q12.4 ESFEROFAQUIA',
  'Q12.8 OUTR MALFORMACOES CONGEN DO CRISTALINO',
  'Q12.9 MALFORMACAO CONGEN NE DO CRISTALINO',
  'Q13.0 COLOBOMA DA IRIS',
  'Q13.1 AUSENCIA DE IRIS',
  'Q13.2 OUTR MALFORMACOES CONGEN DA IRIS',
  'Q13.3 OPACIDADE CONGEN DA CORNEA',
  'Q13.4 OUTR MALFORMACOES CONGEN DA CORNEA',
  'Q13.5 ESCLEROTICA AZUL',
  'Q13.8 OUTR MALFORM CONGEN CAMARA ANTERIOR OLHO',
  'Q13.9 MALFORMACAO CONGEN NE CAMARA ANTERIOR OLHO',
  'Q14.0 MALFORMACAO CONGEN DO HUMOR VITREO',
  'Q14.1 MALFORMACAO CONGEN DA RETINA',
  'Q14.2 MALFORMACAO CONGEN DO DISCO OPTICO',
  'Q14.3 MALFORMACAO CONGEN DA COROIDE',
  'Q14.8 OUTR MALFORM CONGEN CAMARA POSTERIOR OLHO',
  'Q14.9 MALFORMACAO CONGEN NE CAMARA POSTERIOR OLHO',
  'Q15.0 GLAUCOMA CONGEN',
  'Q15.8 OUTR MALFORMACOES CONGEN ESPEC DO OLHO',
  'Q15.9 MALFORMACAO CONGEN NE DO OLHO',
  'Q16.0 AUSENCIA CONGEN DO PAVILHAO AURICULAR',
  'Q16.1 AUSENCIA ATRESIA ESTREIT CONGEN COND AUDIT',
  'Q16.2 AUSENCIA DA TROMPA DE EUSTAQUIO',
  'Q16.3 MALFORMACAO CONGEN DOS OSSICULOS DO OUVIDO',
  'Q16.4 OUTR MALFORMACOES CONGEN DO OUVIDO MEDIO',
  'Q16.5 MALFORMACAO CONGEN DO OUVIDO INTERNO',
  'Q16.9 MALFORM CONGEN OUVIDO NE CAUS COMPR AUDICAO',
  'Q17.0 PAVILHAO SUPRANUMERARIO',
  'Q17.1 MACROTIA',
  'Q17.2 MICROTIA',
  'Q17.3 OUTR DEFORM DA ORELHA',
  'Q17.4 ANOMALIA DE POSICAO DA ORELHA',
  'Q17.5 ORELHAS PROEMINENTES',
  'Q17.8 OUTR MALFORMACOES CONGEN ESPEC DA ORELHA',
  'Q17.9 MALFORMACAO CONGEN NE DA ORELHA',
  'Q18.0 SEIO FISTULA E CISTO ORIG BRANQUIAL',
  'Q18.1 SEIO FISTULA E CISTO PRE-AURICULAR',
  'Q18.2 OUTR MALFORMACOES DA FENDA BRANQUIAL',
  'Q18.3 PESCOCO ALADO',
  'Q18.4 MACROSTOMIA',
  'Q18.5 MICROSTOMIA',
  'Q18.6 MACROQUEILIA',
  'Q18.7 MICROQUEILIA',
  'Q18.8 OUTR MALFORMACOES CONGEN ESPEC FACE PESCOCO',
  'Q18.9 MALFORMACAO CONGEN NE DA FACE E DO PESCOCO',
  'Q20.0 TRONCO ARTERIAL COMUM',
  'Q20.1 VENTRICULO DIREITO C/DUPLA VIA DE SAIDA',
  'Q20.2 VENTRICULO ESQUERDO C/DUPLA VIA DE SAIDA',
  'Q20.3 COMUNICACAO VENTRICULO-ATRIAL DISCORDANTE',
  'Q20.4 VENTRICULO C/DUPLA VIA DE ENTRADA',
  'Q20.5 COMUNICACAO ATRIO-VENTRICULAR DISCORDANTE',
  'Q20.6 ISOMERISMO DOS APENDICES ATRIAIS',
  'Q20.8 OUTR MALFORM CONGEN CAMARAS E COMUNIC CARD',
  'Q20.9 MALFORMACAO CONGEN NE CAMARAS E COMUMIC CARD',
  'Q21.0 COMUNICACAO INTERVENTRICULAR',
  'Q21.1 COMUNICACAO INTERATRIAL',
  'Q21.2 COMUNICACAO ATRIOVENTRICULAR',
  'Q21.3 TETRALOGIA DE FALLOT',
  'Q21.4 COMUNICACAO AORTOPULMONAR',
  'Q21.8 OUTR MALFORMACOES CONGEN SEPTOS CARDIACOS',
  'Q21.9 MALFORMACAO CONGEN NE DE SEPTO CARDIACO',
  'Q22.0 ATRESIA DA VALVA PULMONAR',
  'Q22.1 ESTENOSE CONGEN DA VALVA PULMONAR',
  'Q22.2 INSUF CONGEN DA VALVA PULMONAR',
  'Q22.3 OUTR MALFORMACOES CONGEN DA VALVA PULMONAR',
  'Q22.4 ESTENOSE CONGEN DA VALVA TRICUSPIDE',
  'Q22.5 ANOMALIA DE EBSTEIN',
  'Q22.6 SINDR DO CORACAO DIREITO HIPOPLASICO',
  'Q22.8 OUTR MALFORMACOES CONGEN DA VALVA TRICUSPIDE',
  'Q22.9 MALFORMACAO CONGEN NE DA VALVA TRICUSPIDE',
  'Q23.0 ESTENOSE CONGEN DA VALVA AORTICA',
  'Q23.1 INSUF CONGEN DA VALVA AORTICA',
  'Q23.2 ESTENOSE MITRAL CONGEN',
  'Q23.3 INSUF MITRAL CONGEN',
  'Q23.4 SINDR DO CORACAO ESQUERDO HIPOPLASICO',
  'Q23.8 OUTR MALFORM CONGEN VALVAS AORTICA MITRAL',
  'Q23.9 MALFORMACAO CONGEN NE VALVAS AORTICA MITRAL',
  'Q24.0 DEXTROCARDIA',
  'Q24.1 LEVOCARDIA',
  'Q24.2 COR TRIATRIATUM',
  'Q24.3 ESTENOSE DO INFUNDIBULO PULMONAR',
  'Q24.4 ESTENOSE SUBAORTICA CONGEN',
  'Q24.5 MALFORMACOES DOS VASOS CORONARIOS',
  'Q24.6 BLOQUEIO CONGEN DO CORACAO',
  'Q24.8 OUTR MALFORMACOES CONGEN ESPEC DO CORACAO',
  'Q24.9 MALFORMACAO NE DO CORACAO',
  'Q25.0 PERMEABILIDADE DO CANAL ARTERIAL',
  'Q25.1 COARTACAO DA AORTA',
  'Q25.2 ATRESIA DA AORTA',
  'Q25.3 ESTENOSE DA AORTA',
  'Q25.4 OUTR MALFORMACOES CONGEN DA AORTA',
  'Q25.5 ATRESIA DA ARTERIA PULMONAR',
  'Q25.6 ESTENOSE DA ARTERIA PULMONAR',
  'Q25.7 OUTR MALFORMACOES CONGEN DA ARTERIA PULMONAR',
  'Q25.8 OUTR MALFORMACOES CONGEN GRANDES ARTERIAS',
  'Q25.9 MALFORMACAO CONGEN NE DAS GRANDES ARTERIAS',
  'Q26.0 ESTENOSE CONGEN DA VEIA CAVA',
  'Q26.1 PERSISTENCIA DA VEIA CAVA SUPER ESQUERDA',
  'Q26.2 COMUNICACAO VENOSA PULMONAR ANORMAL TOTAL',
  'Q26.3 COMUNICACAO VENOSA PULMONAR ANORMAL PARCIAL',
  'Q26.4 COMUNICACAO VENOSA PULMONAR ANORMAL NE',
  'Q26.5 COMUNICACAO VENOSA PORTAL ANORMAL',
  'Q26.6 FISTULA ENTRE VEIA PORTA E ARTERIA HEPATICA',
  'Q26.8 OUTR MALFORMACOES CONGEN DAS GRANDES VEIAS',
  'Q26.9 MALFORMACAO CONGEN NE DE GRANDE VEIA',
  'Q27.0 AUSENCIA CONGEN E HIPOPLASIA ARTERIA UMBILIC',
  'Q27.1 ESTENOSE CONGEN DA ARTERIA RENAL',
  'Q27.2 OUTR MALFORMACOES CONGEN DA ARTERIA RENAL',
  'Q27.3 MALFORMACAO ARTERIO-VENOSA PERIFERICA',
  'Q27.4 ECTASIA VENOSA CONGEN',
  'Q27.8 OUTR MALFORM CONGEN ESPEC SIST VASC PERIF',
  'Q27.9 MALFORMACAO CONGEN NE DO SIST VASC PERIF',
  'Q28.0 MALFORM ARTERIOVENOSA DE VASOS PRE-CEREBRAIS',
  'Q28.1 OUTR MALFORMACOES DOS VASOS PRE-CEREBRAIS',
  'Q28.2 MALFORM ARTERIOVENOSA DOS VASOS CEREBRAIS',
  'Q28.3 OUTR MALFORMACOES DOS VASOS CEREBRAIS',
  'Q28.8 OUTR MALFORM CONGEN ESPEC APARELHO CIRCULAT',
  'Q28.9 MALFORM CONGEN NE DO APARELHO CIRCULATORIO',
  'Q30.0 ATRESIA DAS COANAS',
  'Q30.1 AGENESIA OU HIPOPLASIA DO NARIZ',
  'Q30.2 FISSURA ENTALHE OU FENDA NASAL',
  'Q30.3 PERFURACAO CONGEN DO SEPTO NASAL',
  'Q30.8 OUTR MALFORMACOES CONGEN DO NARIZ',
  'Q30.9 MALFORMACAO CONGEN NE DO NARIZ',
  'Q31.0 PTERIGIO DA LARINGE',
  'Q31.1 ESTENOSE SUBGLOTICA CONGEN',
  'Q31.2 HIPOPLASIA DA LARINGE',
  'Q31.3 LARINGOCELE',
  'Q31.5 LARINGOMALACIA CONGENITA',
  'Q31.8 OUTR MALFORMACOES CONGEN DA LARINGE',
  'Q31.9 MALFORMACAO CONGEN NE DA LARINGE',
  'Q32.0 TRAQUEOMALACIA CONGEN',
  'Q32.1 OUTR MALFORMACOES CONGEN DA TRAQUEIA',
  'Q32.2 BRONCOMALACIA CONGEN',
  'Q32.3 ESTENOSE CONGEN DOS BRONQUIOS',
  'Q32.4 OUTR MALFORMACOES CONGEN DOS BRONQUIOS',
  'Q33.0 PULMAO CISTICO CONGEN',
  'Q33.1 LOBO PULMONAR SUPRANUMERARIO',
  'Q33.2 SEQUESTRO PULMONAR',
  'Q33.3 AGENESIA DO PULMAO',
  'Q33.4 BRONQUECTASIA CONGEN',
  'Q33.5 TEC ECTOPICO INTRAPULMONAR',
  'Q33.6 HIPOPLASIA E DISPLASIA DO PULMAO',
  'Q33.8 OUTR MALFORMACOES CONGEN DO PULMAO',
  'Q33.9 MALFORMACAO CONGEN NE DO PULMAO',
  'Q34.0 ANOMALIA DA PLEURA',
  'Q34.1 CISTO CONGEN DO MEDIASTINO',
  'Q34.8 OUTR MALFORM CONGEN ESPEC APARELHO RESPIRAT',
  'Q34.9 MALFORMACAO CONGEN NE DO APARELHO RESPIRAT',
  'Q35.1 FENDA DO PALATO DURO',
  'Q35.3 FENDA DO PALATO MOLE',
  'Q35.5 FENDA DO PALATO DURO C/FENDA DO PALATO MOLE',
  'Q35.7 FENDA DA UVULA',
  'Q35.9 FENDA PALATINA NE',
  'Q36.0 FENDA LABIAL BILATERAL',
  'Q36.1 FENDA LABIAL MEDIANA',
  'Q36.9 FENDA LABIAL UNILATERAL',
  'Q37.0 FENDA PALATO DURO C/FENDA LABIAL BILATERAL',
  'Q37.1 FENDA PALATO DURO C/FENDA LABIAL UNILATERAL',
  'Q37.2 FENDA PALATO MOLE C/FENDA LABIAL BILATERAL',
  'Q37.3 FENDA PALATO MOLE C/FENDA LABIAL UNILATERAL',
  'Q37.4 FENDA PALATOS DURO MOLE C/FENDA LABIAL BILAT',
  'Q37.5 FENDA PALATOS DURO MOLE C/FEND LABIAL UNILAT',
  'Q37.8 FENDA DO PALATO C/FENDA LABIAL BILATERAL NE',
  'Q37.9 FENDA DO PALATO C/FENDA LABIAL UNILATERAL NE',
  'Q38.0 MALFORMACOES CONGEN DOS LABIOS NCOP',
  'Q38.1 ANQUILOGLOSSIA',
  'Q38.2 MACROGLOSSIA',
  'Q38.3 OUTR MALFORMACOES CONGEN DA LINGUA',
  'Q38.4 MALFORMACOES CONGEN GLAND E DUTOS SALIVARES',
  'Q38.5 MALFORMACOES CONGEN DO PALATO NCOP',
  'Q38.6 OUTR MALFORMACOES CONGEN DA BOCA',
  'Q38.7 BOLSA FARINGEA',
  'Q38.8 OUTR MALFORMACOES CONGEN DA FARINGE',
  'Q39.0 ATRESIA DE ESOFAGO S/FISTULA',
  'Q39.1 ATRESIA ESOFAGO C/FISTULA TRAQUEOESOFAGICA',
  'Q39.2 FISTULA TRAQUEOESOFAGICA CONGEN S/ATRESIA',
  'Q39.3 ESTENOSE CONGEN E ESTREIT CONGEN ESOFAGO',
  'Q39.4 PTERIGIO DO ESOFAGO',
  'Q39.5 DILATACAO CONGEN DO ESOFAGO',
  'Q39.6 DIVERTICULO DO ESOFAGO',
  'Q39.8 OUTR MALFORMACOES CONGEN DO ESOFAGO',
  'Q39.9 MALFORMACAO CONGEN NE DO ESOFAGO',
  'Q40.0 ESTENOSE HIPERTROFICA CONGEN DO PILORO',
  'Q40.1 HERNIA CONGEN DE HIATO',
  'Q40.2 OUTR MALFORMACOES CONGEN ESPEC DO ESTOMAGO',
  'Q40.3 MALFORMACAO CONGEN NE DO ESTOMAGO',
  'Q40.8 OUTR MALFORM CONGEN ESPEC TRATO DIGEST SUPER',
  'Q40.9 MALFORMACAO CONGEN NE TRATO DIGESTIVO SUPER',
  'Q41.0 AUSENCIA ATRESIA E ESTENOSE CONGEN DUODENO',
  'Q41.1 AUSENCIA ATRESIA E ESTENOSE CONGEN DO JEJUNO',
  'Q41.2 AUSENCIA ATRESIA E ESTENOSE CONGEN DO ILEO',
  'Q41.8 AUSEN ATRES ESTEN CONG OUT PART ESP INT DELG',
  'Q41.9 AUSEN ATRES ESTEN CONG INT DELG S/ESPEC LOC',
  'Q42.0 AUSENCIA ATRESIA ESTENOSE CONGEN RETO C/FIST',
  'Q42.1 AUSENCIA ATRESIA ESTENOSE CONGEN RETO S/FIST',
  'Q42.2 AUSENCIA ATRESIA ESTENOSE CONGEN ANUS C/FIST',
  'Q42.3 AUSENCIA ATRESIA ESTENOSE CONGEN ANUS S/FIST',
  'Q42.8 AUSENC ATRES ESTENOSE CONGEN OUTR PART COLON',
  'Q42.9 AUSENC ATRESIA ESTENOSE CONGEN PART NE COLON',
  'Q43.0 DIVERTICULO DE MECKEL',
  'Q43.1 DOENC DE HIRSCHSPRUNG',
  'Q43.2 OUTR TRANST FUNCIONAIS CONGEN DO COLON',
  'Q43.3 MALFORMACOES CONGEN DA FIXACAO DO INTESTINO',
  'Q43.4 DUPLICACAO DO INTESTINO',
  'Q43.5 ANUS ECTOPICO',
  'Q43.6 FISTULA CONGEN DO RETO E DO ANUS',
  'Q43.7 PERSISTENCIA DE CLOACA',
  'Q43.8 OUTR MALFORMACOES CONGEN ESPEC DO INTESTINO',
  'Q43.9 MALFORMACAO CONGEN NE DO INTESTINO',
  'Q44.0 AGENESIA APLASIA HIPOPLASIA VESICULA BILIAR',
  'Q44.1 OUTR MALFORMACOES CONGEN DA VESICULA BILIAR',
  'Q44.2 ATRESIA DAS VIAS BILIARES',
  'Q44.3 ESTENOSE ESTREITAMENTO CONGEN VIAS BILIARES',
  'Q44.4 CISTO DO COLEDOCO',
  'Q44.5 OUTR MALFORMACOES CONGEN DAS VIAS BILIARES',
  'Q44.6 DOENC CISTICA DO FIGADO',
  'Q44.7 OUTR MALFORMACOES CONGEN DO FIGADO',
  'Q45.0 AGENESIA APLASIA E HIPOPLASIA DO PANCREAS',
  'Q45.1 PANCREAS ANULAR',
  'Q45.2 CISTO PANCREATICO CONGEN',
  'Q45.3 OUTR MALFORM CONGEN PANCREAS DUTO PANCREATIC',
  'Q45.8 OUTR MALFORM CONGEN ESPEC APARELHO DIGESTIVO',
  'Q45.9 MALFORMACAO CONGEN NE DO APARELHO DIGESTIVO',
  'Q50.0 AUSENCIA CONGEN DOS OVARIOS',
  'Q50.1 CISTO OVARIANO DE DESENVOLV',
  'Q50.2 TORSAO CONGEN DO OVARIO',
  'Q50.3 OUTR MALFORMACOES CONGEN DO OVARIO',
  'Q50.4 CISTO EMBRIONARIO DA TROMPA DE FALOPIO',
  'Q50.5 CISTO EMBRIONARIO DO LIGAMENTO LARGO',
  'Q50.6 OUTR MALFORM CONGEN TROMP FALOPIO LIG LARGOS',
  'Q51.0 AGENESIA E APLASIA DO UTERO',
  'Q51.1 UTERO DUPLO C/DUPLICACAO COLO UTERINO VAGINA',
  'Q51.2 OUTR DUPLICACAO DO UTERO',
  'Q51.3 UTERO BICORNEO',
  'Q51.4 UTERO UNICORNEO',
  'Q51.5 AGENESIA E APLASIA DO COLO DO UTERO',
  'Q51.6 CISTO EMBRIONARIO DO COLO DO UTERO',
  'Q51.7 FISTULA CONGEN UTERO-DIGEST OU UTERO-URINAR',
  'Q51.8 OUTR MALFORMACOES CONGEN UTERO COLO DO UTERO',
  'Q51.9 MALFORMACAO CONGEN NE UTERO COLO UTERO SOE',
  'Q52.0 AUSENCIA CONGEN DA VAGINA',
  'Q52.1 DUPLICACAO DA VAGINA',
  'Q52.2 FISTULA RETO-VAGINAL CONGEN',
  'Q52.3 IMPERFURACAO DO HIMEN',
  'Q52.4 OUTR MALFORMACOES CONGEN DA VAGINA',
  'Q52.5 FUSAO DOS LABIOS VULVARES',
  'Q52.6 MALFORMACAO CONGEN DO CLITORIS',
  'Q52.7 OUTR MALFORMACOES CONGEN DA VULVA',
  'Q52.8 OUTR MALFORM CONGEN ESPEC ORG GENITAIS FEMIN',
  'Q52.9 MALFORM CONGEN NE DOS ORGAOS GENITAIS FEMIN',
  'Q53.0 TESTICULO ECTOPICO',
  'Q53.1 TESTICULO NAO-DESCIDO UNILATERAL',
  'Q53.2 TESTICULO NAO-DESCIDO BILATERAL',
  'Q53.9 TESTICULO NAO-DESCIDO NE',
  'Q54.0 HIPOSPADIA BALANICA',
  'Q54.1 HIPOSPADIA PENIANA',
  'Q54.2 HIPOSPADIA PENOSCROTAL',
  'Q54.3 HIPOSPADIA PERINEAL',
  'Q54.4 CORDA VENEREA CONGEN',
  'Q54.8 OUTR HIPOSPADIAS',
  'Q54.9 HIPOSPADIA NE',
  'Q55.0 AUSENCIA E APLASIA DO TESTICULO',
  'Q55.1 HIPOPLASIA DO TESTICULO E DO ESCROTO',
  'Q55.2 OUTR MALFORMACOES CONGEN TESTICULO E ESCROTO',
  'Q55.3 ATRESIA DO CANAL DEFERENTE',
  'Q55.4 OUT MALF CONG CAN DEFER EPID VES SEM PROSTAT',
  'Q55.5 AUSENCIA E APLASIA CONGEN DO PENIS',
  'Q55.6 OUTR MALFORMACOES CONGEN DO PENIS',
  'Q55.8 OUTR MALFORM CONGEN ESPEC ORG GENITAIS MASC',
  'Q55.9 MALFORM CONGEN NE DOS ORGAOS GENITAIS MASC',
  'Q56.0 HERMAFRODITISMO NCOP',
  'Q56.1 PSEUDO-HERMAFRODITISMO MASC NCOP',
  'Q56.2 PSEUDO-HERMAFRODITISMO FEMIN NCOP',
  'Q56.3 PSEUDO-HERMAFRODITISMO NE',
  'Q56.4 SEXO INDETERMINADO NE',
  'Q60.0 AGENESIA UNILATERAL DO RIM',
  'Q60.1 AGENESIA BILATERAL DO RIM',
  'Q60.2 AGENESIA RENAL NE',
  'Q60.3 HIPOPLASIA RENAL UNILATERAL',
  'Q60.4 HIPOPLASIA RENAL BILATERAL',
  'Q60.5 HIPOPLASIA RENAL NE',
  'Q60.6 SINDR DE POTTER',
  'Q61.0 CISTO CONGEN UNICO DO RIM',
  'Q61.1 RIM POLICISTICO AUTOSSOMICO RECESSIVO',
  'Q61.2 RIM POLICISTICO AUTOSSOMICO DOMINANTE',
  'Q61.3 RIM POLICISTICO NE',
  'Q61.4 DISPLASIA RENAL',
  'Q61.5 CISTO MEDULAR DO RIM',
  'Q61.8 OUTR DOENC CISTICAS DO RIM',
  'Q61.9 DOENC CISTICA NE DO RIM',
  'Q62.0 HIDRONEFROSE CONGEN',
  'Q62.1 ATRESIA E ESTENOSE DO URETER',
  'Q62.2 MEGAURETER CONGEN',
  'Q62.3 OUTR ANOM OBSTR DA PELVE RENAL E DO URETER',
  'Q62.4 AGENESIA DO URETER',
  'Q62.5 DUPLICACAO DO URETER',
  'Q62.6 MA-POSICAO DO URETER',
  'Q62.7 REFLUXO VESICO-URETERO-RENAL CONGEN',
  'Q62.8 OUTR MALFORMACOES CONGEN DO URETER',
  'Q63.0 RIM SUPRANUMERARIO',
  'Q63.1 RIM LOBULADO FUNDIDO OU EM FERRADURA',
  'Q63.2 RIM ECTOPICO',
  'Q63.3 RIM HIPERPLASICO E GIGANTE',
  'Q63.8 OUTR MALFORMACOES CONGEN ESPEC DO RIM',
  'Q63.9 MALFORMACAO CONGEN NE DO RIM',
  'Q64.0 EPISPADIAS',
  'Q64.1 EXTROFIA VESICAL',
  'Q64.2 VALVULAS URETRAIS POSTERIORES CONGEN',
  'Q64.3 OUTR FORM ATRESIA ESTENOSE URETRA COLO BEXIG',
  'Q64.4 MALFORMACAO DO URACO',
  'Q64.5 AUSENCIA CONGEN DA BEXIGA E DA URETRA',
  'Q64.6 DIVERTICULO CONGEN DA BEXIGA',
  'Q64.7 OUTR MALFORMACOES CONGEN DA BEXIGA E URETRA',
  'Q64.8 OUTR MALFORM CONGEN ESPEC APARELHO URINARIO',
  'Q64.9 MALFORMACAO CONGEN NE DO APARELHO URINARIO',
  'Q65.0 LUXACAO CONGEN UNILATERAL DO QUADRIL',
  'Q65.1 LUXACAO CONGEN BILATERAL DO QUADRIL',
  'Q65.2 LUXACAO CONGEN NE DO QUADRIL',
  'Q65.3 SUBLUXACAO CONGEN UNILATERAL DO QUADRIL',
  'Q65.4 SUBLUXACAO CONGEN BILATERAL DO QUADRIL',
  'Q65.5 SUBLUXACAO CONGEN NE DO QUADRIL',
  'Q65.6 QUADRIL INSTAVEL',
  'Q65.8 OUTR DEFORM CONGEN DO QUADRIL',
  'Q65.9 DEFORM CONGEN NE DO QUADRIL',
  'Q66.0 PE TORTO EQUINOVARO',
  'Q66.1 PE TORTO CALCANEOVARO',
  'Q66.2 METATARSO VARO',
  'Q66.3 OUTR DEFORM CONGEN DOS PES EM VARO',
  'Q66.4 PE TORTO CALCANEOVALGO',
  'Q66.5 PE CHATO CONGEN',
  'Q66.6 OUTR DEFORM CONGEN DOS PES EM VALGO',
  'Q66.7 PE CAVO',
  'Q66.8 OUTR DEFORM CONGEN DO PE',
  'Q66.9 DEFORM CONGEN NE DO PE',
  'Q67.0 ASSIMETRIA FACIAL',
  'Q67.1 DEFORM FACIAL P/COMPRESSAO',
  'Q67.2 DOLICOCEFALIA',
  'Q67.3 PLAGIOCEFALIA',
  'Q67.4 OUTR DEFORM CONGEN CRANIO FACE E MANDIBULA',
  'Q67.5 DEFORM CONGEN DA COLUNA VERTEBRAL',
  'Q67.6 TORAX ESCAVADO',
  'Q67.7 TORAX CARINADO',
  'Q67.8 OUTR DEFORM CONGEN DO TORAX',
  'Q68.0 DEFORM CONGEN MUSCULO ESTERNOCLEIDOMASTOIDEU',
  'Q68.1 DEFORM CONGEN DA MAO',
  'Q68.2 DEFORM CONGEN DO JOELHO',
  'Q68.3 ENCURVAMENTO CONGEN DO FEMUR',
  'Q68.4 ENCURVAMENTO CONGEN DA TIBIA E DA PERONIO',
  'Q68.5 ENCURV CONGEN OSSOS LONGOS NE MEMBRO INFER',
  'Q68.8 OUTR DEFORM OSTEOMUSCULARES CONGEN',
  'Q69.0 DEDOS DA MAO SUPRANUMERARIOS',
  'Q69.1 POLEGARES SUPRANUMERARIOS',
  'Q69.2 ARTELHOS SUPRANUMERARIOS',
  'Q69.9 POLIDACTILIA NE',
  'Q70.0 COALESCENCIA DOS DEDOS',
  'Q70.1 DEDOS PALMADOS',
  'Q70.2 COALESCENCIA DOS ARTELHOS',
  'Q70.3 ARTELHOS PALMADOS',
  'Q70.4 POLISSINDACTILIA',
  'Q70.9 SINDACTILIA NE',
  'Q71.0 AUSENCIA CONGEN COMPLETA DE MEMBROS SUPER',
  'Q71.1 AUSENCIA CONGEN BRACO ANTEBRACO C/MAO PRES',
  'Q71.2 AUSENCIA CONGEN DO ANTEBRACO E DA MAO',
  'Q71.3 AUSENCIA CONGEN DA MAO E DE DEDOS',
  'Q71.4 DEFEITO DE REDUCAO LONGITUDINAL DO RADIO',
  'Q71.5 DEFEITO DE REDUCAO LONGITUDINAL DO CUBITO',
  'Q71.6 MAO EM GARRA DE LAGOSTA',
  'Q71.8 OUTR DEFEITOS DE REDUCAO DO MEMBRO SUPER',
  'Q71.9 DEFEITO P/REDUCAO DO MEMBRO SUPER NE',
  'Q72.0 AUSENCIA CONGEN COMPLETA DE MEMBROS INFER',
  'Q72.1 AUSENCIA CONGEN COXA PERNA C/PE PRESENTE',
  'Q72.2 AUSENCIA CONGEN DA PERNA E DO PE',
  'Q72.3 AUSENCIA CONGEN DO PE E DE ARTELHOS',
  'Q72.4 DEFEITO P/REDUCAO LONGITUDINAL DA TIBIA',
  'Q72.5 DEFEITO P/REDUCAO LONGITUDINAL DA TIBIA',
  'Q72.6 DEFEITO P/REDUCAO LONGITUDINAL DO PERONIO',
  'Q72.7 PE BIFIDO',
  'Q72.8 OUTR DEFEITOS P/REDUCAO DE MEMBROS INFER',
  'Q72.9 DEFEITO NE P/REDUCAO DO MEMBRO INFER',
  'Q73.0 AUSENCIA CONGEN DE MEMBROS NE',
  'Q73.1 FOCOMELIA MEMBROS NE',
  'Q73.8 OUTR DEFEITOS P/REDUCAO DE MEMBROS NE',
  'Q74.0 OUT MALFORM CONG MEMBR SUPER INCL CINT ESCAP',
  'Q74.1 MALFORMACAO CONGEN DO JOELHO',
  'Q74.2 OUTR MALFORM CONG MEMBROS INF INCL CINT PELV',
  'Q74.3 ARTROGRIPOSE CONGEN MULT',
  'Q74.8 OUTR MALFORMACOES CONGEN ESPEC DE MEMBROS',
  'Q74.9 MALFORMACOES CONGEN NE DE MEMBROS',
  'Q75.0 CRANIOSSINOSTOSE',
  'Q75.1 DISOSTOSE CRANIOFACIAL',
  'Q75.2 HIPERTELORISMO',
  'Q75.3 MACROCEFALIA',
  'Q75.4 DISOSTOSE MANDIBULO-FACIAL',
  'Q75.5 DISOSTOSE OCULO-MANDIBULAR',
  'Q75.8 OUTR MALFORM CONGEN ESPEC OSSOS CRANIO FACE',
  'Q75.9 MALFORM CONGEN NE DOS OSSOS CRANIO E FACE',
  'Q76.0 ESPINHA BIFIDA OCULTA',
  'Q76.1 SINDR DE KLIPPEL-FEIL',
  'Q76.2 ESPONDILOLISTESE CONGEN',
  'Q76.3 ESCOLIOSE CONGEN DEV MALFORM OSSEA CONGEN',
  'Q76.4 OUT MALF CONG COLUNA VERT N-ASSOC ESCOLIOSE',
  'Q76.5 COSTELA CERVICAL',
  'Q76.6 OUTR MALFORMACOES CONGEN DAS COSTELAS',
  'Q76.7 MALFORMACAO CONGEN DO ESTERNO',
  'Q76.8 OUTR MALFORMACOES CONGEN DOS OSSOS DO TORAX',
  'Q76.9 MALFORMACAO CONGEN NE DOS OSSOS DO TORAX',
  'Q77.0 ACONDROGENESIA',
  'Q77.1 NANISMO TANATOFORICO',
  'Q77.2 SINDR DAS COSTELAS CURTAS',
  'Q77.3 CONDRODISPLASIA PUNTACTA',
  'Q77.4 ACONDROPLASIA',
  'Q77.5 DISPLASIA DIASTROFICA',
  'Q77.6 DISPLASIA CONDROECTODERMICA',
  'Q77.7 DISPLASIA ESPONDILOEPIFISARIA',
  'Q77.8 OUT OSTEOCON C/ANOM CRESC OSSO LONG COL VERT',
  'Q77.9 OSTEOCOND NE C/ANOM CRESC OSSO LONG COL VERT',
  'Q78.0 OSTEOGENESE IMPERFEITA',
  'Q78.1 DISPLASIA POLIOSTOTICA FIBROSA',
  'Q78.2 OSTEOPETROSE',
  'Q78.3 DISPLASIA DIAFISARIA PROGRESSIVA',
  'Q78.4 ENCONDROMATOSE',
  'Q78.5 DISPLASIA METAFISARIA',
  'Q78.6 EXOSTOSES CONGEN MULT',
  'Q78.8 OUTR OSTEOCONDRODISPLASIAS ESPEC',
  'Q78.9 OSTEOCONDRODISPLASIA NE',
  'Q79.0 HERNIA DIAFRAGMATICA CONGEN',
  'Q79.1 OUTR MALFORMACOES CONGEN DO DIAFRAGMA',
  'Q79.2 EXONFALIA',
  'Q79.3 GASTROSQUISE',
  'Q79.4 SINDR DO ABDOME EM AMEIXA SECA',
  'Q79.5 OUTR MALFORMACOES CONGEN DA PAREDE ABDOMINAL',
  'Q79.6 SINDR DE EHLERS-DANLOS',
  'Q79.8 OUTR MALFORMACOES CONGEN SIST OSTEOMUSCULAR',
  'Q79.9 MALFORMACAO CONGEN NE DO SIST OSTEOMUSCULAR',
  'Q80.0 ICTIOSE VULGAR',
  'Q80.1 ICTIOSE LIGADA AO CROMOSSOMO X',
  'Q80.2 ICTIOSE LAMELAR',
  'Q80.3 ERITRODERMIA ICTIOSIFORME BULHOSA CONGEN',
  'Q80.4 FETO ARLEQUIM',
  'Q80.8 OUTR ICTIOSES CONGEN',
  'Q80.9 ICTIOSE CONGEN NE',
  'Q81.0 EPIDERMOLISE BOLHOSA SIMPLES',
  'Q81.1 EPIDERMOLISE BOLHOSA LETAL',
  'Q81.2 EPIDERMOLISE BOLHOSA DISTROFICA',
  'Q81.8 OUTR EPIDERMOLISES BOLHOSAS',
  'Q81.9 EPIDERMOLISE BOLHOSA NE',
  'Q82.0 LINFEDEMA HEREDITARIO',
  'Q82.1 XERODERMA PIGMENTOSO',
  'Q82.2 MASTOCITOSE',
  'Q82.3 INCONTINENTIA PIGMENTI',
  'Q82.4 DISPLASIA ECTODERMICA',
  'Q82.5 NEVO NAO-NEOPLASICO CONGEN',
  'Q82.8 OUTR MALFORMACOES CONGEN ESPEC DA PELE',
  'Q82.9 MALFORMACAO CONGEN NE DA PELE',
  'Q83.0 AUSENCIA CONGEN DA MAMA C/AUSENCIA DO MAMILO',
  'Q83.1 MAMA SUPRANUMERARIA',
  'Q83.2 AUSENCIA DE MAMILO',
  'Q83.3 MAMILO ACESSORIO',
  'Q83.8 OUTR MALFORMACOES CONGEN DA MAMA',
  'Q83.9 MALFORMACAO CONGEN NE DA MAMA',
  'Q84.0 ALOPECIA CONGEN',
  'Q84.1 ALTERACOES MORFOLOGICAS CONGEN CABELOS NCOP',
  'Q84.2 OUTR MALFORMACOES CONGEN DOS CABELOS',
  'Q84.3 ANONIQUIA',
  'Q84.4 LEUCONIQUIA CONGEN',
  'Q84.5 HIPERTROFIA E ALARGAMENTO DAS UNHAS',
  'Q84.6 OUTR MALFORMACOES CONGEN DAS UNHAS',
  'Q84.8 OUTR MALFORMACOES CONGEN ESPEC DO TEGUMENTO',
  'Q84.9 MALFORMACAO CONGEN NE DO TEGUMENTO',
  'Q85.0 NEUROFIBROMATOSE',
  'Q85.1 ESCLEROSE TUBEROSA',
  'Q85.8 OUTR FACOMATOSES NCOP',
  'Q85.9 FACOMATOSE NE',
  'Q86.0 SINDR FETAL ALCOOLICO',
  'Q86.1 SINDR FETAL DEV HIDANTOINA',
  'Q86.2 DISMORFISMO DEV WARFARIN',
  'Q86.8 OUTR SINDR C/MALF CONG DEV CAUSAS EXOG CONH',
  'Q87.0 SINDR C/MALF CONG AFET PREDOM ASPECTO FACE',
  'Q87.1 SINDR C/MALFORM CONGEN ASSOC PREDOM NANISMO',
  'Q87.2 SINDR C/MALFORM CONGEN AFETANDO PREDOM MEMBR',
  'Q87.3 SINDR C/MALFORM CONGEN C/HIPERCRESC PRECOCE',
  'Q87.4 SINDR DE MARFAN',
  'Q87.5 OUTR SINDR C/MALF CONG C/OUTR ALT ESQUELETO',
  'Q87.8 OUTR SINDR C/MALFORMACOES CONGEN ESPEC NCOP',
  'Q89.0 MALFORMACOES CONGEN DO BACO',
  'Q89.1 MALFORMACOES CONGEN DAS SUPRA-RENAIS',
  'Q89.2 MALFORMACOES CONGEN DE OUTR GLAND ENDOCRINAS',
  'Q89.3 SITUS INVERSUS',
  'Q89.4 REUNIAO DE GEMEOS',
  'Q89.7 MALFORMACOES CONGEN MULT NCOP',
  'Q89.8 OUTR MALFORMACOES CONGEN ESPEC',
  'Q89.9 MALFORMACOES CONGEN NE',
  'Q90.0 TRISSOMIA 21 NAO-DISJUNCAO MEIOTICA',
  'Q90.1 TRISSOMIA 21 MOSAICISMO',
  'Q90.2 TRISSOMIA 21 TRANSLOCACAO',
  'Q90.9 SINDR DE DOWN NE',
  'Q91.0 TRISSOMIA 18 NAO-DISJUNCAO MEIOTICA',
  'Q91.1 TRISSOMIA 18 MOSAICISMO CROMOSSOMICO',
  'Q91.2 TRISSOMIA 18 TRANSLOCACAO',
  'Q91.3 SINDR DE EDWARDS NE',
  'Q91.4 TRISSOMIA 13 NAO-DISJUNCAO MEIOTICA',
  'Q91.5 TRISSOMIA 13 MOSAICISMO CROMOSSOMICO',
  'Q91.6 TRISSOMIA 13 TRANSLOCACAO',
  'Q91.7 SINDR DE PATAU NE',
  'Q92.0 TRISSOMIA CROMOSS INTEIRO N-DISJUNCAO MEIOT',
  'Q92.1 TRISSOMIA CROMOSS INTEIRO MOSAICISMO CROMOSS',
  'Q92.2 TRISSOMIA PARCIAL MAJOR',
  'Q92.3 TRISSOMIA PARCIAL MINOR',
  'Q92.4 DUPLICACOES VISTAS SOMENTE NA PROMETAFASE',
  'Q92.5 DUPLICACAO C/OUTR REARRANJOS COMPLEXOS',
  'Q92.6 CROMOSSOMOS MARCADORES SUPLEMENTARES',
  'Q92.7 TRIPLOIDIA E POLIPLOIDIA',
  'Q92.8 OUTR TRISSOMIAS ESPEC E TRISSOM PARC AUTOSS',
  'Q92.9 TRISSOMIA E TRISSOMIA PARCIAL NE AUTOSSOMOS',
  'Q93.0 MONOSSOMIA CROMOSS INTEIRO N-DISJUNCAO MEIOT',
  'Q93.1 MONOSSOMIA CROMOSS INTEIRO MOSAICISM CROMOSS',
  'Q93.2 CROMOSSOMO SUBSTITUIDO P/ANEL OU DICENTRICO',
  'Q93.3 DELECAO DO BRACO CURTO DO CROMOSSOMO 4',
  'Q93.4 DELECAO DO BRACO CURTO DO CROMOSSOMO 5',
  'Q93.5 OUTR DELECOES PARCIAIS DE CROMOSSOMO',
  'Q93.6 DELECOES VISTAS SOMENTE NA PROMETAFASE',
  'Q93.7 DELECOES C/OUTR REARRANJOS COMPLEXOS',
  'Q93.8 OUTR DELECOES DOS AUTOSSOMOS',
  'Q93.9 DELECOES NE DOS AUTOSSOMOS',
  'Q95.0 TRANSLOC OU INSERCAO EQUILIBR SUJEITO NORM',
  'Q95.1 INVERSAO CROMOSSOMICA EM SUJEITO NORMAL',
  'Q95.2 REARRANJO AUTOSSOMICO EQUILIBR SUJEITO ANORM',
  'Q95.3 REARRANJO SEXUAL/AUTOSS EQUILIBR SUJ ANORM',
  'Q95.4 SUJEITO C/MARCADOR DE HETEROCROMATINA',
  'Q95.5 SUJEITO C/SITIO AUTOSSOMICO FRAGIL',
  'Q95.8 OUTR REARRANJOS E MARCADORES EQUILIBRADOS',
  'Q95.9 REARRANJOS E MARCADORES EQUILIBRADOS NE',
  'Q96.0 CARIOTIPO 45 X',
  'Q96.1 CARIOTIPO 46 X ISSO',
  'Q96.2 CARIOTIPO 46 X CROMOSS SEX ANORM SALVO ISSO',
  'Q96.3 MOSAICISMO CROMOSSOMICO 45 X/46 XX OU XY',
  'Q96.4 MOSAIC CROM 45 X/OUT LINH CEL CROM SEX ANORM',
  'Q96.8 OUTR VARIANTES DA SINDR DE TURNER',
  'Q96.9 SINDR DE TURNER NE',
  'Q97.0 CARIOTIPO 47 XXX',
  'Q97.1 MULHER C/MAIS DE TRES CROMOSSOMOS X',
  'Q97.2 MOSAICISMO CROMOSS LINH C/DIV NUM CROMOSS X',
  'Q97.3 MULHER C/CARIOTIPO 46 XY',
  'Q97.8 OUTR ANOM ESPEC CROMOSS SEX FENOTIPO FEM',
  'Q97.9 ANOMALIAS NE CROMOSS SEXUAIS FENOTIPO FEMIN',
  'Q98.0 SINDR DE KLINEFELTER CARIOTIPO 47 XXY',
  'Q98.1 SINDR KLINEFELTER HOM C/MAIS DOIS CROMOSS X',
  'Q98.2 SINDR DE KLINEFELTER HOMEM C/CARIOTIPO 46 XX',
  'Q98.3 OUTR HOMEM C/CARIOTIPO 46 XX',
  'Q98.4 SINDR DE KLINEFELTER NE',
  'Q98.5 CARIOTIPO 47 XYY',
  'Q98.6 HOMEM C/CROMOSSOMOS SEXUAIS ESTRUT ANORMAL',
  'Q98.7 HOMEM C/MOSAICISMO DOS CROMOSSOMOS SEXUAIS',
  'Q98.8 OUTR ANOM ESPEC CROMOSS SEX FENOTIPO MASC',
  'Q98.9 ANOMALIAS NE CROMOSS SEXUAIS FENOTIPO MASC',
  'Q99.0 QUIMERA 46 XX/46 XY',
  'Q99.1 HERMAFRODITE VERDADEIRO 46 XX',
  'Q99.2 CROMOSSOMO X FRAGIL',
  'Q99.8 OUTR ANOMALIAS CROMOSSOMICAS ESPEC',
  'Q99.9 ANOMALIA CROMOSSOMICA NE',
  'R00.0 TAQUICARDIA NE',
  'R00.1 BRADICARDIA NE',
  'R00.2 PALPITACOES',
  'R00.8 OUTR ANORMALIDADES E NE BATIMENTO CARDIACO',
  'R01.0 SOPROS CARDIACOS BENIGNOS OU INOCENTES',
  'R01.1 SOPRO CARDIACO NE',
  'R01.2 OUTR RUIDOS CARDIACOS',
  'R02   GANGRENA NCOP',
  'R03.0 VALOR ELEVADO PRESS ARTER S/DIAGN HIPERTENS',
  'R03.1 VALOR BAIXO PRESSAO ARTERIAL NAO ESPECIFICO',
  'R04.0 EPISTAXIS',
  'R04.1 HEMORRAGIA DA GARGANTA',
  'R04.2 HEMOPTISE',
  'R04.8 HEMORRAGIA DE OUTR LOCALIZ DAS VIAS RESPIRAT',
  'R04.9 HEMORRAGIA NE DAS VIAS RESPIRAT',
  'R05   TOSSE',
  'R06.0 DISPNEIA',
  'R06.1 ESTRIDOR',
  'R06.2 RESPIRACAO OFEGANTE',
  'R06.3 RESPIRACAO PERIODICA',
  'R06.4 HIPERVENTILACAO',
  'R06.5 RESPIRACAO P/BOCA',
  'R06.6 SOLUCO',
  'R06.7 ESPIRRO',
  'R06.8 OUTR ANORMALIDADES E AS NE DA RESPIRACAO',
  'R07.0 DOR DE GARGANTA',
  'R07.1 DOR TORACICA AO RESPIRAR',
  'R07.2 DOR PRECORDIAL',
  'R07.3 OUTR DOR TORACICA',
  'R07.4 DOR TORACICA NE',
  'R09.0 ASFIXIA',
  'R09.1 PLEURISIA',
  'R09.2 PARADA RESPIRAT',
  'R09.3 ESCARRO ANORMAL',
  'R09.8 OUTR SINT SINAIS ESPEC REL AP CIRCUL RESPIR',
  'R10.0 ABDOME AGUDO',
  'R10.1 DOR LOCALIZADA NO ABDOME SUPER',
  'R10.2 DOR PELVICA E PERINEAL',
  'R10.3 DOR LOCALIZADA EM OUTR PARTES ABDOME INFER',
  'R10.4 OUTR DORES ABDOMINAIS E AS NE',
  'R11   NAUSEA E VOMITOS',
  'R12   PIROSE',
  'R13   DISFAGIA',
  'R14   FLATULENCIA E AFECCOES CORRELATAS',
  'R15   INCONTINENCIA FECAL',
  'R16.0 HEPATOMEGALIA NCOP',
  'R16.1 ESPLENOMEGALIA NCOP',
  'R16.2 HEPATOMEGALIA C/ESPLENOMEGALIA NCOP',
  'R17   ICTERICIA NE',
  'R18   ASCITE',
  'R19.0 MASSA TUMORACAO TUMEFACAO INTRA-ABDOM PELV',
  'R19.1 RUIDOS HIDROAEREOS ANORMAIS',
  'R19.2 PERISTALTISMO VISIVEL',
  'R19.3 RIGIDEZ ABDOMINAL',
  'R19.4 ALTERACAO DO HABITO INTESTINAL',
  'R19.5 OUTR ANORMALIDADES FECAIS',
  'R19.6 HALITOSE',
  'R19.8 OUTR SINT SINAIS ESP RELAT AP DIGEST ABDOME',
  'R20.0 ANESTESIA CUTANEA',
  'R20.1 HIPOESTESIA CUTANEA',
  'R20.2 PARESTESIAS CUTANEAS',
  'R20.3 HIPERESTESIA',
  'R20.8 OUTR DISTURBIOS E NE SENSIBILIDADE CUTANEA',
  'R21   ERITEMA E OUTR ERUPCOES CUTANEAS NE',
  'R22.0 TUMEFACAO MASSA TUMORACAO LOCALIZADAS CABECA',
  'R22.1 TUMEFACAO MASSA TUMORACAO LOCALIZ PESCOCO',
  'R22.2 TUMEFACAO MASSA TUMORACAO LOCALIZADAS TRONCO',
  'R22.3 TUMEFACAO MASSA TUMORACAO LOCALIZ MEMBRO SUP',
  'R22.4 TUMEFACAO MASSA TUMORACAO LOCALIZ MEMBRO INF',
  'R22.7 TUMEFACAO MASSA TUMORACAO LOCALIZ MULT LOCAL',
  'R22.9 TUMEFACAO MASSA OU TUMORACAO NE LOCALIZADAS',
  'R23.0 CIANOSE',
  'R23.1 PALIDEZ',
  'R23.2 RUBOR',
  'R23.3 EQUIMOSES ESPONTANEAS',
  'R23.4 ALTERACOES NA TEXTURA DA PELE',
  'R23.8 OUTR ALTERACOES DA PELE E AS NE',
  'R25.0 MOVIMENTOS ANORMAIS DA CABECA',
  'R25.1 TREMOR NE',
  'R25.2 CAIBRAS E ESPASMOS',
  'R25.3 FASCICULACAO',
  'R25.8 OUTR MOVIMENTOS INVOLUNTARIOS ANORMAIS E NE',
  'R26.0 MARCHA ATAXICA',
  'R26.1 MARCHA PARALITICA',
  'R26.2 DIFICULDADE P/ANDAR NCOP',
  'R26.8 OUTR ANORMALIDADES MARCHA E MOBILIDADE E NE',
  'R27.0 ATAXIA NE',
  'R27.8 OUTR DISTURBIOS DA COORDENACAO E OS NE',
  'R29.0 TETANIA',
  'R29.1 MENINGISMO',
  'R29.2 REFLEXOS ANORMAIS',
  'R29.3 POSTURA ANORMAL',
  'R29.4 QUADRIL ESTALANTE',
  'R29.8 OUT SINT SINAIS REL SIST NERV OSTEOMUSC E NE',
  'R30.0 DISURIA',
  'R30.1 TENESMO VESICAL',
  'R30.9 MICCAO DOLOROSA NE',
  'R31   HEMATURIA NE',
  'R32   INCONTINENCIA URINARIA NE',
  'R33   RETENCAO URINARIA',
  'R34   ANURIA E OLIGURIA',
  'R35   POLIURIA',
  'R36   SECRECAO URETRAL',
  'R39.0 EXTRAVASAMENTO DE URINA',
  'R39.1 OUTR DIFICULDADES A MICCAO',
  'R39.2 UREMIA EXTRA-RENAL',
  'R39.8 OUTR SINT SINAIS RELATIVOS AP URINARIO E NE',
  'R40.0 SONOLENCIA',
  'R40.1 ESTUPOR',
  'R40.2 COMA NE',
  'R41.0 DESORIENTACAO NE',
  'R41.1 AMNESIA ANTEROGRADA',
  'R41.2 AMNESIA RETROGRADA',
  'R41.3 OUTR AMNESIA',
  'R41.8 OUTR SINT SINAIS ESP REL FUNC COGNIT E CONSC',
  'R42   TONTURA E INSTABILIDADE',
  'R43.0 ANOSMIA',
  'R43.1 PAROSMIA',
  'R43.2 PARAGEUSIA',
  'R43.8 OUTR DISTURBIOS DO OLFATO E DO PALADAR E NE',
  'R44.0 ALUCINACOES AUDITIVAS',
  'R44.1 ALUCINACOES VISUAIS',
  'R44.2 OUTR ALUCINACOES',
  'R44.3 ALUCINACOES NE',
  'R44.8 OUTR SINT SINAIS ESPEC REL SENSAC PERCEP GER',
  'R45.0 NERVOSISMO',
  'R45.1 AGITACAO E INQUIETACAO',
  'R45.2 TRISTEZA',
  'R45.3 APATIA E DESINTERESSE',
  'R45.4 IRRITABILIDADE E MAU HUMOR',
  'R45.5 HOSTILIDADE',
  'R45.6 VIOLENCIA FISICA',
  'R45.7 ESTADO DE CHOQUE EMOCIONAL E TENSAO NE',
  'R45.8 OUTR SINT SINAIS RELATIVOS ESTADO EMOCIONAL',
  'R46.0 BAIXO NIVEL DE HIGIENE PESSOAL',
  'R46.1 APARENCIA PESSOAL BIZARRA',
  'R46.2 COMPORT ESTRANHO E INEXPLICAVEL',
  'R46.3 HIPERATIVIDADE',
  'R46.4 LENTIDAO E BAIXA REATIVIDADE',
  'R46.5 PERSONALIDADE SUSPEITA E EVASIVA',
  'R46.6 INQUIET E PREOCUP EXAGERADAS C/ACONT ESTRESS',
  'R46.7 VERBORR PORMENORES CIRCUNST MASC MOT CONSULT',
  'R46.8 OUTR SINT SINAIS RELATIVOS APARENCIA COMPORT',
  'R47.0 DISFASIA E AFASIA',
  'R47.1 DISARTRIA E ANARTRIA',
  'R47.8 OUTR DISTURBIOS DA FALA E OS NE',
  'R48.0 DISLEXIA E ALEXIA',
  'R48.1 AGNOSIA',
  'R48.2 APRAXIA',
  'R48.8 OUTR DISFUNCOES SIMBOLICAS E AS NE',
  'R49.0 DISFONIA',
  'R49.1 AFONIA',
  'R49.2 HIPERNASALIDADE E HIPONASALIDADE',
  'R49.8 OUTR DISTURBIOS DA VOZ E OS NE',
  'R50.0 FEBRE C/CALAFRIOS',
  'R50.1 FEBRE PERSISTENTE',
  'R50.9 FEBRE NE',
  'R51   CEFALEIA',
  'R52.0 DOR AGUDA',
  'R52.1 DOR CRONICA INTRATAVEL',
  'R52.2 OUTR DOR CRONICA',
  'R52.9 DOR NE',
  'R53   MAL ESTAR FADIGA',
  'R54   SENILIDADE',
  'R55   SINCOPE E COLAPSO',
  'R56.0 CONVULSOES FEBRIS',
  'R56.8 OUTR CONVULSOES E AS NE',
  'R57.0 CHOQUE CARDIOGENICO',
  'R57.1 CHOQUE HIPOVOLEMICO',
  'R57.8 OUTR FORM DE CHOQUE',
  'R57.9 CHOQUE NE',
  'R58   HEMORRAGIA NCOP',
  'R59.0 AUMENTO VOLUME LOCALIZ GANGLIOS LINFATICOS',
  'R59.1 AUMENTO VOLUME GENERALIZ GANGLIOS LINFATICOS',
  'R59.9 ADENOMEGALIA AUMENTO VOL GANGL LINFATICOS NE',
  'R60.0 EDEMA LOCALIZADO',
  'R60.1 EDEMA GENERALIZADO',
  'R60.9 EDEMA NE',
  'R61.0 HIPERIDROSE LOCALIZADA',
  'R61.1 HIPERIDROSE GENERALIZADA',
  'R61.9 HIPERIDROSE NE',
  'R62.0 RETARDO DE MATURACAO',
  'R62.8 OUTR FORM RETARDO DESENVOLV FISIOLOGICO NORM',
  'R62.9 RETARDO DO DESENVOLV FISIOLOGICO NORMAL NE',
  'R63.0 ANOREXIA',
  'R63.1 POLIDIPSIA',
  'R63.2 POLIFAGIA',
  'R63.3 DIFICULD ALIMENTACAO ERROS ADMIN ALIMENTOS',
  'R63.4 PERDA DE PESO ANORMAL',
  'R63.5 GANHO DE PESO ANORMAL',
  'R63.8 OUTR SINT SINAIS REL INGESTAO ALIMENTOS LIQ',
  'R64   CAQUEXIA',
  'R68.0 HIPOTERMIA NAO ASSOC BAIXA TEMPERAT AMBIENT',
  'R68.1 SINT NAO ESPECIFICOS PECULIARES A INFANCIA',
  'R68.2 BOCA SECA NE',
  'R68.3 BAQUETEAMENTO DOS DEDOS',
  'R68.8 OUTR SINT E SINAIS GERAIS ESPEC',
  'R69   CAUSAS DESCONHECIDAS E NE DE MORBIDADE',
  'R70.0 VELOCIDADE DE HEMOSSEDIMENTACAO ELEVADA',
  'R70.1 VISCOSIDADE PLASMATICA ANORMAL',
  'R71   ANORMALIDADE DAS HEMACIAS',
  'R72   ANORMALIDADE DOS LEUCOCITOS NCOP',
  'R73.0 TESTE DE TOLERANCIA A GLICOSE ANORMAL',
  'R73.9 HIPERGLICEMIA NE',
  'R74.0 AUMENTO NIV TRANSAMINASES DESIDROGEN LATICA',
  'R74.8 NIVEIS ANORMAIS DE OUTR ENZIMAS SERICAS',
  'R74.9 ANORMALIDADE DOS NIVEIS ENZIMAS SERICAS NE',
  'R75   EVIDENCIA LABORATORIAL DO HIV',
  'R76.0 TITULO AUMENTADO DE ANTICORPOS',
  'R76.1 REACAO ANORMAL AO TESTE DA TUBERCULINA',
  'R76.2 EXAME SOROLOGICO FALSO POSITIVO P/SIFILIS',
  'R76.8 OUTR ACHADOS IMUNOLOGICOS ESPEC ANORM SORO',
  'R76.9 ACHADO ANORMAL EXAME IMUNOLOGICO SERICO NE',
  'R77.0 ANORMALIDADE DA ALBUMINA',
  'R77.1 ANORMALIDADE DAS GLOBULINAS',
  'R77.2 ANORMALIDADE DA ALFAFETOPROTEINA',
  'R77.8 OUTR ANORMALIDADES ESPEC PROTEINAS PLASMATIC',
  'R77.9 ANORMALIDADES DE PROTEINA PLASMATICA NE',
  'R78.0 PRESENCA DE ALCOOL NO SANGUE',
  'R78.1 PRESENCA DE OPIACEOS NO SANGUE',
  'R78.2 PRESENCA DE COCAINA NO SANGUE',
  'R78.3 PRESENCA DE ALUCINOGENO NO SANGUE',
  'R78.4 PRESENCA OUTR DROG POTENC CAUS DEPEND SANGUE',
  'R78.5 PRESENCA DE DROGA PSICOTROPICA NO SANGUE',
  'R78.6 PRESENCA DE AGENTE ESTEROIDE NO SANGUE',
  'R78.7 PRESENCA NIVEL ANORMAL METAIS PESADOS SANGUE',
  'R78.8 PRESENC OUTR SUBST ESPEC N NORM ENCONTR SANG',
  'R78.9 PRESENCA SUBST NE NORM NAO ENCONTRADA SANGUE',
  'R79.0 ANORMALIDADE NIVEL ELEMENTOS MINERAIS SANGUE',
  'R79.8 OUTR ACHADOS ANORMAIS ESPEC EXAMES QUIM SANG',
  'R79.9 ACHADO ANORMAL DE EXAME QUIMICO DO SANGUE NE',
  'R80   PROTEINURIA ISOLADA',
  'R81   GLICOSURIA',
  'R82.0 QUILURIA',
  'R82.1 MIOGLOBINURIA',
  'R82.2 BILIURIA',
  'R82.3 HEMOGLOBINURIA',
  'R82.4 ACETONURIA',
  'R82.5 NIVEIS URINAR ELEV DROGAS MEDIC SUBST BIOLOG',
  'R82.6 NIVEIS URINAR ANORM SUBST ORIG ESSEN N-MEDIC',
  'R82.7 ACHADOS ANORMAIS EXAME MICROBIOLOGICO URINA',
  'R82.8 ACHADOS ANORM EXAME CITOLOG HISTOLOG URINA',
  'R82.9 OUTR ACHADOS ANORMAIS NA URINA E OS NE',
  'R83.0 NIVEL ENZIMATICO ANORMAL',
  'R83.1 NIVEL HORMONAL ANORMAL',
  'R83.2 NIVEL ANORMAL OUTR DROG MEDIC SUBST BIOLOGIC',
  'R83.3 NIVEL ANORMAL SUBST ORIGEM ESSENC NAO MEDIC',
  'R83.4 ACHADOS IMUNOLOGICOS ANORMAIS',
  'R83.5 ACHADOS MICROBIOLOGICOS ANORMAIS',
  'R83.6 ACHADOS CITOLOGICOS ANORMAIS',
  'R83.7 ACHADOS HISTOLOG ANORMAIS',
  'R83.8 OUTR ACHADOS ANORMAIS',
  'R83.9 ACHADO ANORMAL NE',
  'R84.0 NIVEL ENZIMATICO ANORMAL',
  'R84.1 NIVEL HORMONAL ANORMAL',
  'R84.2 NIVEL ANORMAL OUTR DROG MEDIC SUBST BIOLOG',
  'R84.3 NIVEL ANORMAL SUBST ORIG ESSENC NAO MEDIC',
  'R84.4 ACHADOS IMUNOLOGICOS ANORMAIS',
  'R84.5 ACHADOS MICROBIOLOGICOS ANORMAIS',
  'R84.6 ACHADOS CITOLOGICOS ANORMAIS',
  'R84.7 ACHADOS HISTOLOG ANORMAIS',
  'R84.8 OUTR ACHADOS ANORMAIS',
  'R84.9 ACHADO ANORMAL NE',
  'R85.0 NIVEL ENZIMATICO ANORMAL',
  'R85.1 NIVEL HORMONAL ANORMAL',
  'R85.2 NIVEL ANORMAL OUTR DROG MEDIC SUBST BIOLOG',
  'R85.3 NIVEL ANORMAL SUBST ORIGEM ESSENC NAO MEDIC',
  'R85.4 ACHADOS IMUNOLOGICOS ANORMAIS',
  'R85.5 ACHADOS MICROBIOLOGICOS ANORMAIS',
  'R85.6 ACHADOS CITOLOGICOS ANORMAIS',
  'R85.7 ACHADOS HISTOLOG ANORMAIS',
  'R85.8 OUTR ACHADOS ANORMAIS',
  'R85.9 ACHADO ANORMAL NE',
  'R86.0 NIVEL ENZIMATICO ANORMAL',
  'R86.1 NIVEL HORMONAL ANORMAL',
  'R86.2 NIVEL ANORMAL OUTR DROG MEDIC SUBST BIOLOG',
  'R86.3 NIVEL ANORMAL SUBST ORIGEM ESSENC NAO MEDIC',
  'R86.4 ACHADOS IMUNOLOGICOS ANORMAIS',
  'R86.5 ACHADOS MICROBIOLOGICOS ANORMAIS',
  'R86.6 ACHADOS CITOLOGICOS ANORMAIS',
  'R86.7 ACHADOS HISTOLOG ANORMAIS',
  'R86.8 OUTR ACHADOS ANORMAIS',
  'R86.9 ACHADO ANORMAL NE',
  'R87.0 NIVEL ENZIMATICO ANORMAL',
  'R87.1 NIVEL HORMONAL ANORMAL',
  'R87.2 NIVEL ANORMAL OUTR DROG MEDIC SUBST BIOLOG',
  'R87.3 NIVEL ANORMAL SUBST ORIGEM ESSENC NAO MEDIC',
  'R87.4 ACHADOS IMUNOLOGICOS ANORMAIS',
  'R87.5 ACHADOS MICROBIOLOGICOS ANORMAIS',
  'R87.6 ACHADOS CITOLOGICOS ANORMAIS',
  'R87.7 ACHADOS HISTOLOG ANORMAIS',
  'R87.8 OUTR ACHADOS ANORMAIS',
  'R87.9 ACHADO ANORMAL NE',
  'R89.0 NIVEL ENZIMATICO ANORMAL',
  'R89.1 NIVEL HORMONAL ANORMAL',
  'R89.2 NIVEL ANORMAL OUTR DROG MEDIC SUBST BIOLOG',
  'R89.3 NIVEL ANORMAL SUBST ORIGEM ESSENC NAO MEDIC',
  'R89.4 ACHADOS IMUNOLOGICOS ANORMAIS',
  'R89.5 ACHADOS MICROBIOLOGICOS ANORMAIS',
  'R89.6 ACHADOS CITOLOGICOS ANORMAIS',
  'R89.7 ACHADOS HISTOLOG ANORMAIS',
  'R89.8 OUTR ACHADOS ANORMAIS',
  'R89.9 ACHADO ANORMAL NE',
  'R90.0 LESAO INTRACRANIANA OCUPANDO ESPACO',
  'R90.8 OUT RESUL ANORM EXAM DIAG IMAG SIS NERV CENT',
  'R91   ACHADOS ANORM EXAMES DIAGN IMAGEM DO PULMAO',
  'R92   ACHADOS ANORM EXAMES DIAGN IMAGEM DA MAMA',
  'R93.0 ACHADOS ANORM EX DIAG IMAG CRAN CABECA NCOP',
  'R93.1 ACHADOS ANORM EX DIAG IMAG CORAC CIRC CORON',
  'R93.2 ACHADOS ANORM EX DIAG IMAG FIGADO VIA BILIAR',
  'R93.3 ACHADOS ANORM EX DIAG IMAG OUTR PART TUB DIG',
  'R93.4 ACHADOS ANORM EXAM DIAGN IMAGEM ORG URINAR',
  'R93.5 ACHADOS ANORM EX DIAG IMAG OUT REG ABD RETR',
  'R93.6 ACHADOS ANORM EXAM DIAGN IMAGEM DOS MEMBROS',
  'R93.7 ACHADOS ANORM EX DIAG IMAG OUTR SIST OSTEOM',
  'R93.8 ACHADOS ANORM EX DIAG IMAG OUT ESTR SOMA ESP',
  'R94.0 RESULT ANORM ESTUDOS FUNCAO SIST NERV CENTR',
  'R94.1 RESULT ANORM EST FUNC SIS NERV PERI ORG SENT',
  'R94.2 RESULT ANORM DE ESTUDOS DA FUNCAO PULMONAR',
  'R94.3 RESULT ANORM ESTUDOS FUNCAO CARDIOVASCULAR',
  'R94.4 RESULTADOS ANORMAIS DE ESTUDOS FUNCAO RENAL',
  'R94.5 RESULTADOS ANORMAIS ESTUDOS FUNCAO HEPATICA',
  'R94.6 RESULTADOS ANORM ESTUDOS FUNCAO TIREOIDIANA',
  'R94.7 RESULT ANORM ESTUDOS FUNCAO OUT GLAND ENDOCR',
  'R94.8 RESULT ANORM ESTUDOS FUNCAO OUTR ORG AP SIST',
  'R95   SINDR DA MORTE SUBITA NA INFANCIA',
  'R96.0 MORTE INSTANTANEA',
  'R96.1 MORTE OCORR MENOS 24H APOS INIC SINT N EXPL',
  'R98   MORTE S/ASSIST',
  'R99   OUTR CAUSAS MAL DEFINIDAS E NE MORTALIDADE',
  'S00.0 TRAUM SUPERF DO COURO CABELUDO',
  'S00.1 CONTUSAO DA PALPEBRA E DA REGIAO PERIOCULAR',
  'S00.2 OUTR TRAUM SUPERF PALPEBRA REGIAO PERIOCULAR',
  'S00.3 TRAUM SUPERF DO NARIZ',
  'S00.4 TRAUM SUPERF DO OUVIDO',
  'S00.5 TRAUM SUPERF DOS LABIOS E DA CAVIDADE ORAL',
  'S00.7 TRAUM SUPERF MULT DA CABECA',
  'S00.8 TRAUM SUPERF DE OUTR PARTES DA CABECA',
  'S00.9 TRAUM SUPERF DA CABECA PARTE NE',
  'S01.0 FERIM DO COURO CABELUDO',
  'S01.1 FERIM DA PALPEBRA E DA REGIAO PERIOCULAR',
  'S01.2 FERIM DO NARIZ',
  'S01.3 FERIM DO OUVIDO',
  'S01.4 FERIM BOCHECHA E REGIAO TEMPORO-MANDIBULAR',
  'S01.5 FERIM DO LABIO E DA CAVIDADE ORAL',
  'S01.7 FERIM MULT DA CABECA',
  'S01.8 FERIM NA CABECA DE OUTR LOCALIZ',
  'S01.9 FERIM NA CABECA PARTE NE',
  'S02.0 FRAT DA ABOBADA DO CRANIO',
  'S02.1 FRAT DA BASE DO CRANIO',
  'S02.2 FRAT DOS OSSOS NASAIS',
  'S02.3 FRAT DO ASSOALHO ORBITAL',
  'S02.4 FRAT DOS OSSOS MALARES E MAXILARES',
  'S02.5 FRAT DE DENTES',
  'S02.6 FRAT DE MANDIBULA',
  'S02.7 FRAT MULT ENVOLV OSSOS DO CRANIO E FACE',
  'S02.8 OUTR FRAT DO CRANIO E DOS OSSOS DA FACE',
  'S02.9 FRAT DO CRANIO OU DOS OSSOS DA FACE PARTE NE',
  'S03.0 LUXACAO DO MAXILAR',
  'S03.1 LUXACAO DA CARTILAGEM DO SEPTO NASAL',
  'S03.2 LUXACAO DENTARIA',
  'S03.3 LUXACAO DE OUTR PARTES DA CABECA E DAS NE',
  'S03.4 ENTORSE E DISTENSAO DO MAXILAR',
  'S03.5 ENTORSE DISTENS ARTIC LIG OUTR LOC NE CABECA',
  'S04.0 TRAUM DO NERVO E DAS VIAS OPTICAS',
  'S04.1 TRAUM DO NERVO OCULOMOTOR',
  'S04.2 TRAUM DO NERVO TROCLEAR',
  'S04.3 TRAUM DO NERVO TRIGEMEO',
  'S04.4 TRAUM DO NERVO ABDUCENTE',
  'S04.5 TRAUM DO NERVO FACIAL',
  'S04.6 TRAUM DO NERVO ACUSTICO',
  'S04.7 TRAUM DO NERVO ACESSORIO',
  'S04.8 TRAUM DE OUTR NERVOS CRANIANOS',
  'S04.9 TRAUM DE NERVO CRANIANO NE',
  'S05.0 TRAUM CONJ ABRASAO CORNEA S/MENC CORP ESTRAN',
  'S05.1 CONTUSAO DO GLOBO OCULAR E DOS TEC DA ORBITA',
  'S05.2 LACER RUPT OCULAR C/PROL PERD TEC INTRA-OCUL',
  'S05.3 LACER OCULAR S/PROLAP PERDA TEC INTRA-OCULAR',
  'S05.4 FERIM PENETRANTE ORBITA C/ S/CORPO ESTRANHO',
  'S05.5 FERIM PENETR GLOBO OCULAR C/CORPO ESTRANHO',
  'S05.6 FERIM PENETR GLOBO OCULAR S/CORPO ESTRANHO',
  'S05.7 AVULSAO DO OLHO',
  'S05.8 OUTR TRAUM DO OLHO E DA ORBITA',
  'S05.9 TRAUM DO OLHO E DA ORBITA DE PARTE NE',
  'S06.0 CONCUSSAO CEREBRAL',
  'S06.1 EDEMA CEREBRAL TRAUM',
  'S06.2 TRAUM CEREBRAL DIFUSO',
  'S06.3 TRAUM CEREBRAL FOCAL',
  'S06.4 HEMORRAGIA EPIDURAL',
  'S06.5 HEMORRAGIA SUBDURAL DEV TRAUM',
  'S06.6 HEMORRAGIA SUBARACNOIDE DEV TRAUM',
  'S06.7 TRAUM INTRACRANIANO C/COMA PROLONGADO',
  'S06.8 OUTR TRAUM INTRACRANIANOS',
  'S06.9 TRAUM INTRACRANIANO NE',
  'S07.0 LESAO P/ESMAG DA FACE',
  'S07.1 LESAO P/ESMAG DO CRANIO',
  'S07.8 LESAO P/ESMAG DE OUTR PARTES DA CABECA',
  'S07.9 LESAO P/ESMAG DA CABECA PARTE NE',
  'S08.0 AVULSAO DO COURO CABELUDO',
  'S08.1 AMPUT TRAUM DA ORELHA',
  'S08.8 AMPUT TRAUM DE OUTR PARTES DA CABECA',
  'S08.9 AMPUT TRAUM DE PARTE NE DA CABECA',
  'S09.0 TRAUM DOS VASOS SANGUINEOS DA CABECA NCOP',
  'S09.1 TRAUM DOS MUSCULOS E DOS TENDOES DA CABECA',
  'S09.2 RUPTURA TRAUM DO TIMPANO',
  'S09.7 TRAUM MULT DA CABECA',
  'S09.8 OUTR TRAUM ESPEC DA CABECA',
  'S09.9 TRAUM NE DA CABECA',
  'S10.0 CONTUSAO DA GARGANTA',
  'S10.1 OUTR TRAUM SUPERF DA GARGANTA E OS NE',
  'S10.7 TRAUM SUPERF MULT DO PESCOCO',
  'S10.8 TRAUM SUPERF DE OUTR LOCALIZ DO PESCOCO',
  'S10.9 TRAUM SUPERF DO PESCOCO PARTE NE',
  'S11.0 FERIM ENVOLV A LARINGE E A TRAQUEIA',
  'S11.1 FERIM DA GLAND TIREOIDE',
  'S11.2 FERIM ENVOLV FARINGE ESOFAGO CERVICAL',
  'S11.7 FERIM MULT DO PESCOCO',
  'S11.8 FERIM DE OUTR PARTES DO PESCOCO',
  'S11.9 FERIM DO PESCOCO PARTE NE',
  'S12.0 FRAT DA PRIMEIRA VERTEBRA CERVICAL',
  'S12.1 FRAT DA SEGUNDA VERTEBRA CERVICAL',
  'S12.2 FRAT DE OUTR VERTEBRAS CERVICAIS ESPEC',
  'S12.7 FRAT MULT DA COLUNA CERVICAL',
  'S12.8 FRAT DE OUTR PARTES DO PESCOCO',
  'S12.9 FRAT DO PESCOCO PARTE NE',
  'S13.0 RUPTURA TRAUM DISCO INTERVERTEBRAL CERVICAL',
  'S13.1 LUXACAO DE VERTEBRA CERVICAL',
  'S13.2 LUXACAO DE OUTR PARTES DO PESCOCO E DAS NE',
  'S13.3 LUXACOES MULT DO PESCOCO',
  'S13.4 DISTENSAO E ENTORSE DA COLUNA CERVICAL',
  'S13.5 DISTENSAO E ENTORSE DA REGIAO TIREOIDIANA',
  'S13.6 DISTENS ENTORSE ARTIC LIG OUT LOC NE PESCOCO',
  'S14.0 CONCUSSAO E EDEMA DA MEDULA CERVICAL',
  'S14.1 OUTR TRAUM E OS NE DA MEDULA CERVICAL',
  'S14.2 TRAUM DA RAIZ NERVOSA DA COLUNA CERVICAL',
  'S14.3 TRAUM DO PLEXO BRAQUIAL',
  'S14.4 TRAUM DOS NERVOS PERIFERICOS DO PESCOCO',
  'S14.5 TRAUM DOS NERVOS SIMPATICOS DO PESCOCO',
  'S14.6 TRAUM DE OUTR NERVOS E DOS NE DO PESCOCO',
  'S15.0 TRAUM DA ARTERIA CAROTIDA',
  'S15.1 TRAUM DA ARTERIA VERTEBRAL',
  'S15.2 TRAUM DA VEIA JUGULAR EXTERNA',
  'S15.3 TRAUM DA VEIA JUGULAR INTERNA',
  'S15.7 TRAUM DE MULT VASOS SANGUINEOS NIVEL PESCOCO',
  'S15.8 TRAUM DE OUTR VASOS SANGUINEOS NIVEL PESCOCO',
  'S15.9 TRAUM DE VASOS SANGUINEOS NE NIVEL PESCOCO',
  'S16   TRAUM DE TENDOES E DE MUSCULOS DO PESCOCO',
  'S17.0 LESAO P/ESMAG DA LARINGE E DA TRAQUEIA',
  'S17.8 LESAO P/ESMAG DE OUTR PARTES PESCOCO',
  'S17.9 LESAO P/ESMAG DO PESCOCO PARTE NE',
  'S18   AMPUT TRAUM AO NIVEL DO PESCOCO',
  'S19.7 TRAUM MULT DO PESCOCO',
  'S19.8 OUTR TRAUM ESPEC DO PESCOCO',
  'S19.9 TRAUM NE DO PESCOCO',
  'S20.0 CONTUSAO DA MAMA',
  'S20.1 OUTR TRAUM SUPERF DA MAMA E OS NE',
  'S20.2 CONTUSAO DO TORAX',
  'S20.3 OUTR TRAUM SUPERF DA PAREDE ANTERIOR TORAX',
  'S20.4 OUTR TRAUM SUPERF DA PAREDE POSTERIOR TORAX',
  'S20.7 TRAUM SUPERF MULT DO TORAX',
  'S20.8 TRAUM SUPERF DE OUTR PARTES ESPEC TORAX E NE',
  'S21.0 FERIM DA MAMA',
  'S21.1 FERIM DA PAREDE ANTERIOR DO TORAX',
  'S21.2 FERIM DA PAREDE POSTERIOR DO TORAX',
  'S21.7 FERIM MULT DA PAREDE TORACICA',
  'S21.8 FERIM DE OUTR PARTES DO TORAX',
  'S21.9 FERIM DO TORAX PARTE NE',
  'S22.0 FRAT DE VERTEBRA TORACICA',
  'S22.1 FRAT MULT DA COLUNA TORACICA',
  'S22.2 FRAT DO ESTERNO',
  'S22.3 FRAT DE COSTELA',
  'S22.4 FRAT MULT DE COSTELAS',
  'S22.5 TORAX FUNDIDO',
  'S22.8 FRAT DE OUTR PARTES DOS OSSOS DO TORAX',
  'S22.9 FRAT DOS OSSOS DO TORAX PARTE NE',
  'S23.0 RUPTURA TRAUM DISCO INTERVERTEBRAL TORACICO',
  'S23.1 LUXACAO DE VERTEBRA TORACICA',
  'S23.2 LUXACAO DE OUTR PARTES DO TORAX E DAS NE',
  'S23.3 ENTORSE E DISTENSAO DA COLUNA TORACICA',
  'S23.4 ENTORSE E DISTENSAO DAS COSTELAS E ESTERNO',
  'S23.5 ENTORSE DISTENSAO OUTR PARTES TORAX E NE',
  'S24.0 CONCUSSAO E EDEMA MEDULA ESPINHAL TORACICA',
  'S24.1 OUTR TRAUM MEDULA ESPINHAL TORACICA E OS NE',
  'S24.2 TRAUM DA RAIZ DE NERVO DA COLUNA TORACICA',
  'S24.3 TRAUM DE NERVOS PERIFERICOS DO TORAX',
  'S24.4 TRAUM DE NERVOS SIMPATICOS DO TORAX',
  'S24.5 TRAUM DE OUTR NERVOS DO TORAX',
  'S24.6 TRAUM DE NERVO DO TORAX NE',
  'S25.0 TRAUM DA AORTA TORACICA',
  'S25.1 TRAUM DA ARTERIA SUBCLAVIA OU INOMINADA',
  'S25.2 TRAUM DA VEIA CAVA SUPER',
  'S25.3 TRAUM DA VEIA SUBCLAVIA OU INOMINADA',
  'S25.4 TRAUM DE VASOS SANGUINEOS PULMONARES',
  'S25.5 TRAUM DE VASOS SANGUINEOS INTERCOSTAIS',
  'S25.7 TRAUM DE MULT VASOS SANGUINEOS DO TORAX',
  'S25.8 TRAUM DE OUTR VASOS SANGUINEOS DO TORAX',
  'S25.9 TRAUM DE VASO SANGUINEO NE DO TORAX',
  'S26.0 TRAUM DO CORACAO C/HEMOPERICARDIO',
  'S26.8 OUTR TRAUM DO CORACAO',
  'S26.9 TRAUM DO CORACAO NE',
  'S27.0 PNEUMOTORAX TRAUM',
  'S27.1 HEMOTORAX TRAUM',
  'S27.2 HEMOPNEUMOTORAX TRAUM',
  'S27.3 OUTR TRAUM DO PULMAO',
  'S27.4 TRAUM DOS BRONQUIOS',
  'S27.5 TRAUM DA TRAQUEIA TORACICA',
  'S27.6 TRAUM DA PLEURA',
  'S27.7 TRAUM MULT DE ORGAOS INTRATORACICOS',
  'S27.8 TRAUM DE OUTR ORGAOS INTRATORACICOS ESPEC',
  'S27.9 TRAUM DE ORGAO INTRATORACICO NE',
  'S28.0 ESMAG DO TORAX',
  'S28.1 AMPUT TRAUM DE PARTE DO TORAX',
  'S29.0 TRAUM DE MUSCULO E DE TENDAO NIVEL TORACICO',
  'S29.7 TRAUM MULT DO TORAX',
  'S29.8 OUTR TRAUM ESPEC DO TORAX',
  'S29.9 TRAUM NE DO TORAX',
  'S30.0 CONTUSAO DO DORSO E DA PELVE',
  'S30.1 CONTUSAO DA PAREDE ABDOMINAL',
  'S30.2 CONTUSAO DOS ORGAOS GENITAIS EXTERNOS',
  'S30.7 TRAUM SUPERF MULT DO ABDOME DO DORSO E PELVE',
  'S30.8 OUTR TRAUM SUPERF DO ABDOME DO DORSO E PELVE',
  'S30.9 TRAUM SUPERF PARTE NE ABDOME DORSO E PELVE',
  'S31.0 FERIM DO DORSO E DA PELVE',
  'S31.1 FERIM DA PAREDE ABDOMINAL',
  'S31.2 FERIM DO PENIS',
  'S31.3 FERIM DO ESCROTO E DO TESTICULO',
  'S31.4 FERIM DA VAGINA E DA VULVA',
  'S31.5 FERIM DE OUTR ORGAOS GENITAIS EXTERNOS E NE',
  'S31.7 FERIM MULT DO ABDOME DO DORSO E DA PELVE',
  'S31.8 FERIM DE OUTR PARTES E DE PARTES NE ABDOME',
  'S32.0 FRAT DE VERTEBRA LOMBAR',
  'S32.1 FRAT DO SACRO',
  'S32.2 FRAT DO COCCIX',
  'S32.3 FRAT DO ILIO',
  'S32.4 FRAT DO ACETABULO',
  'S32.5 FRAT DO PUBIS',
  'S32.7 FRAT MULT DE COLUNA LOMBAR E DA PELVE',
  'S32.8 FRAT OUTR PART COLUNA LOMBOSSACRA PELVE E NE',
  'S33.0 RUPTURA TRAUM DO DISCO INTERVERTEBRAL LOMBAR',
  'S33.1 LUXACAO DA VERTEBRA LOMBAR',
  'S33.2 LUXACAO ARTIC SACROILIACA SACROCOCCIGEA',
  'S33.3 LUXACAO OUTR PARTES E NE COLUNA LOMBAR PELVE',
  'S33.4 RUPTURA TRAUM DA SINFISE PUBICA',
  'S33.5 ENTORSE E DISTENSAO DA COLUNA LOMBAR',
  'S33.6 ENTORSE E DISTENSAO ARTICULACAO SACROILIACA',
  'S33.7 ENTORSE DISTENS OUT PART NE COL LOMBAR PELVE',
  'S34.0 CONCUSSAO E EDEMA DA MEDULA LOMBAR',
  'S34.1 OUTR TRAUM DA MEDULA LOMBAR',
  'S34.2 TRAUM DE RAIZ NERVOSA DA MEDULA LOMBAR SACRA',
  'S34.3 TRAUM DE CAUDA EQUINA',
  'S34.4 TRAUM DO PLEXO LOMBOSSACRO',
  'S34.5 TRAUM NERVOS SIMPAT LOMBARES SACROS PELVICOS',
  'S34.6 TRAUM NERVOS PERIF ABDOME DORSO E PELVE',
  'S34.8 TRAUM OUTR NERV E NE NIV ABDOME DORSO PELVE',
  'S35.0 TRAUM DA AORTA ABDOMINAL',
  'S35.1 TRAUM DA VEIA CAVA INFER',
  'S35.2 TRAUM DA ARTERIA CELIACA OU MESENTERICA',
  'S35.3 TRAUM DA VEIA PORTA OU ESPLENICA',
  'S35.4 TRAUM DE VASO SANGUINEO RENAL',
  'S35.5 TRAUM DE VASO SANGUINEO ILIACO',
  'S35.7 TRAUM DE VASOS MULT NIVEL ABDOME DORSO PELVE',
  'S35.8 TRAUM OUTR VASOS SANG NIV ABDOME DORSO PELVE',
  'S35.9 TRAUM VASO SANG NE NIVEL ABDOME DORSO PELVE',
  'S36.0 TRAUM DO BACO',
  'S36.1 TRAUM DO FIGADO OU DA VESICULA BILIAR',
  'S36.2 TRAUM DO PANCREAS',
  'S36.3 TRAUM DO ESTOMAGO',
  'S36.4 TRAUM DO INTESTINO DELGADO',
  'S36.5 TRAUM DO COLON',
  'S36.6 TRAUM DO RETO',
  'S36.7 TRAUM DE MULT ORGAOS INTRA-ABDOMINAIS',
  'S36.8 TRAUM DE OUTR ORGAOS INTRA-ABDOMINAIS',
  'S36.9 TRAUM DE ORGAO INTRA-ABDOMINAL NE',
  'S37.0 TRAUM DO RIM',
  'S37.1 TRAUM DO URETER',
  'S37.2 TRAUM DA BEXIGA',
  'S37.3 TRAUM DA URETRA',
  'S37.4 TRAUM DO OVARIO',
  'S37.5 TRAUM DA TROMPA DE FALOPIO',
  'S37.6 TRAUM DO UTERO',
  'S37.7 TRAUM DE MULT ORGAOS PELVICOS',
  'S37.8 TRAUM DE OUTR ORGAOS PELVICOS',
  'S37.9 TRAUM DE ORGAO PELVICO NE',
  'S38.0 LESAO P/ESMAG DOS ORGAOS GENITAIS EXTERNOS',
  'S38.1 LESAO P/ESMAG OUTR PART NE ABDOME DORSO PELV',
  'S38.2 AMPUT TRAUM DE ORGAOS GENITAIS EXTERNOS',
  'S38.3 AMPUT TRAUM OUTR PART NE ABDOME DORSO PELVE',
  'S39.0 TRAUM MUSCULO E TENDAO ABDOME DORSO E PELVE',
  'S39.6 TRAUM ORG INTRA-ABDOMINAIS C/ORGAOS PELVICOS',
  'S39.7 OUTR TRAUM MULT DO ABDOME DO DORSO E PELVE',
  'S39.8 OUTR TRAUM ESPEC DO ABDOME DO DORSO E PELVE',
  'S39.9 TRAUM NE DO ABDOME DO DORSO E DA PELVE',
  'S40.0 CONTUSAO DO OMBRO E DO BRACO',
  'S40.7 TRAUM SUPERF MULT DO OMBRO E DO BRACO',
  'S40.8 OUTR TRAUM SUPERF DO OMBRO E DO BRACO',
  'S40.9 TRAUM SUPERF NE DO OMBRO E DO BRACO',
  'S41.0 FERIM DO OMBRO',
  'S41.1 FERIM DO BRACO',
  'S41.7 FERIM MULT DO OMBRO E DO BRACO',
  'S41.8 FERIM OUTR PARTES E NE CINTURA ESCAPULAR',
  'S42.0 FRAT DA CLAVICULA',
  'S42.1 FRAT DA OMOPLATA',
  'S42.2 FRAT DA EXTREMIDADE SUPER DO UMERO',
  'S42.3 FRAT DA DIAFISE DO UMERO',
  'S42.4 FRAT DA EXTREMIDADE INFER DO UMERO',
  'S42.7 FRAT MULT DA CLAVICULA DA OMOPLATA E UMERO',
  'S42.8 FRAT DE OUTR PARTES DO OMBRO E DO BRACO',
  'S42.9 FRAT DA CINTURA ESCAPULAR PARTE NE',
  'S43.0 LUXACAO DA ARTICULACAO DO OMBRO',
  'S43.1 LUXACAO DA ARTICULACAO ACROMIOCLAVICULAR',
  'S43.2 LUXACAO DA ARTICULACAO ESTERNOCLAVICULAR',
  'S43.3 LUXACAO DE OUTR PARTES E NE CINT ESCAPULAR',
  'S43.4 ENTORSE E DISTENSAO DE ARTICULACAO DO OMBRO',
  'S43.5 ENTORSE E DISTENSAO ARTIC ACROMIOCLAVICULAR',
  'S43.6 ENTORSE E DISTENSAO ARTIC ESTERNOCLAVICULAR',
  'S43.7 ENTORSE DISTENSAO OUTR PARTES NE CINT ESCAP',
  'S44.0 TRAUM DO NERVO CUBITAL AO NIVEL DO BRACO',
  'S44.1 TRAUM DO NERVO MEDIANO AO NIVEL DO BRACO',
  'S44.2 TRAUM DO NERVO RADIAL AO NIVEL DO BRACO',
  'S44.3 TRAUM DO NERVO AXILAR',
  'S44.4 TRAUM DO NERVO MUSCULOCUTANEO',
  'S44.5 TRAUM NERVO SENSIT CUTANEO NIVEL OMBRO BRACO',
  'S44.7 TRAUM DE MULT NERVOS AO NIVEL OMBRO E BRACO',
  'S44.8 TRAUM DE OUTR NERVOS AO NIVEL OMBRO E BRACO',
  'S44.9 TRAUM DE NERVO NE AO NIVEL DO OMBRO E BRACO',
  'S45.0 TRAUM DA ARTERIA AXILAR',
  'S45.1 TRAUM DA ARTERIA BRAQUIAL',
  'S45.2 TRAUM DA VEIA AXILAR OU BRAQUIAL',
  'S45.3 TRAUM DE VEIA SUPERF AO NIVEL OMBRO E BRACO',
  'S45.7 TRAUM MULT VASOS SANG NIVEL OMBRO BRACO',
  'S45.8 TRAUM DE OUTR VASOS SANG NIVEL OMBRO BRACO',
  'S45.9 TRAUM VASO SANGUINEO NE NIVEL OMBRO BRACO',
  'S46.0 TRAUM DO TENDAO DO MANGUITO ROTADOR DO OMBRO',
  'S46.1 TRAUM DO MUSCULO TENDAO CABECA LONGA BICEPS',
  'S46.2 TRAUM DO MUSCULO E TENDAO OUTR PARTES BICEPS',
  'S46.3 TRAUM DO MUSCULO E TENDAO DO TRICEPS',
  'S46.7 TRAUM MULT MUSC TENDOES NIVEL OMBRO BRACO',
  'S46.8 TRAUM OUTR MUSC TENDOES NIVEL OMBRO BRACO',
  'S46.9 TRAUM MUSCULO TENDAO NE NIVEL OMBRO BRACO',
  'S47   LESAO P/ESMAG DO OMBRO E DO BRACO',
  'S48.0 AMPUT TRAUM DA ARTICULACAO DO OMBRO',
  'S48.1 AMPUT TRAUM DE LOCALIZ ENTRE OMBRO COTOVELO',
  'S48.9 AMPUT TRAUM DO OMBRO E DO BRACO LOCALIZ NE',
  'S49.7 TRAUM MULT DO OMBRO E DO BRACO',
  'S49.8 OUTR TRAUM ESPEC DO OMBRO E DO BRACO',
  'S49.9 TRAUM NE DO OMBRO E DO BRACO',
  'S50.0 CONTUSAO DO COTOVELO',
  'S50.1 CONTUSAO OUTR PARTES E NE DO ANTEBRACO',
  'S50.7 TRAUM SUPERF MULT DO ANTEBRACO',
  'S50.8 OUTR TRAUM SUPERF DO ANTEBRACO',
  'S50.9 TRAUM SUPERF DO ANTEBRACO NE',
  'S51.0 FERIM DO COTOVELO',
  'S51.7 FERIM MULT DO ANTEBRACO',
  'S51.8 FERIM DE OUTR PARTES DO ANTEBRACO',
  'S51.9 FERIM DO ANTEBRACO PARTE NE',
  'S52.0 FRAT DA EXTREMIDADE SUPER DO CUBITO',
  'S52.1 FRAT DA EXTREMIDADE SUPER DO RADIO',
  'S52.2 FRAT DA DIAFISE DO CUBITO',
  'S52.3 FRAT DA DIAFISE DO RADIO',
  'S52.4 FRAT DAS DIAFISES DO RADIO E DO CUBITO',
  'S52.5 FRAT DA EXTREMIDADE DISTAL DO RADIO',
  'S52.6 FRAT DA EXTREMIDADE DISTAL DO RADIO E CUBITO',
  'S52.7 FRAT MULT DO ANTEBRACO',
  'S52.8 FRAT DE OUTR PARTES DO ANTEBRACO',
  'S52.9 FRAT DO ANTEBRACO PARTE NE',
  'S53.0 LUXACAO DA CABECA DO RADIO',
  'S53.1 LUXACAO DO COTOVELO NE',
  'S53.2 RUPTURA TRAUM DO LIGAMENTO COLATERAL RADIO',
  'S53.3 RUPTURA TRAUM DO LIGAMENTO COLATERAL CUBITO',
  'S53.4 ENTORSE E DISTENSAO DO COTOVELO',
  'S54.0 TRAUM DO NERVO CUBITAL AO NIVEL DO ANTEBRACO',
  'S54.1 TRAUM DO NERVO MEDIANO AO NIVEL DO ANTEBRACO',
  'S54.2 TRAUM DO NERVO RADIAL AO NIVEL DO ANTEBRACO',
  'S54.3 TRAUM NERVO CUTANEO SENSITIVO NIV ANTEBRACO',
  'S54.7 TRAUM DE MULT NERVOS AO NIVEL DO ANTEBRACO',
  'S54.8 TRAUM DE OUTR NERVOS AO NIVEL DO ANTEBRACO',
  'S54.9 TRAUM DE NERVO NE AO NIVEL DO ANTEBRACO',
  'S55.0 TRAUM DA ARTERIA CUBITAL AO NIVEL ANTEBRACO',
  'S55.1 TRAUM DA ARTERIA RADIAL AO NIVEL ANTEBRACO',
  'S55.2 TRAUM DE VEIA AO NIVEL DO ANTEBRACO',
  'S55.7 TRAUM MULT VASOS SANGUINEOS NIVEL ANTEBRACO',
  'S55.8 TRAUM OUTR VASOS SANGUINEOS NIVEL ANTEBRACO',
  'S55.9 TRAUM DE VASOS SANGUINEOS NE NIVEL ANTEBRACO',
  'S56.0 TRAUM MUSC FLEXOR TEND POLEGAR NIV ANTEBRACO',
  'S56.1 TRAUM MUSC FLEXOR TEND OUT DEDOS NIV ANTEBR',
  'S56.2 TRAUM OUTR MUSC FLEXOR TENDAO NIV ANTEBRACO',
  'S56.3 TRAUM MUSC EXTENS ABDUT TEND POLEG NIV ANTEB',
  'S56.4 TRAUM MUSC EXTENS TEND OUT DEDOS NIV ANTEBR',
  'S56.5 TRAUM OUTR MUSC EXTENS TENDOES NIV ANTEBRACO',
  'S56.7 TRAUM MUSCULOS TENDOES MULT NIVEL ANTEBRACO',
  'S56.8 TRAUM OUTR MUSC TENDOES E NE NIVEL ANTEBRACO',
  'S57.0 LESAO P/ESMAG DO COTOVELO',
  'S57.8 LESAO P/ESMAG DE OUTR PARTES DO ANTEBRACO',
  'S57.9 LESAO P/ESMAG DE PARTE NE DO ANTEBRACO',
  'S58.0 AMPUT TRAUM AO NIVEL DO COTOVELO',
  'S58.1 AMPUT TRAUM ANTEBRACO ENTRE COTOVELO PUNHO',
  'S58.9 AMPUT TRAUM DO ANTEBRACO NIVEL NE',
  'S59.7 TRAUM MULT DO COTOVELO',
  'S59.8 OUTR TRAUM ESPEC DO ANTEBRACO',
  'S59.9 TRAUM NE DO ANTEBRACO',
  'S60.0 CONTUSAO DE DEDOS S/LESAO DA UNHA',
  'S60.1 CONTUSAO DE DEDOS C/LESAO DA UNHA',
  'S60.2 CONTUSAO DE OUTR PARTES DO PUNHO E DA MAO',
  'S60.7 TRAUM SUPERF MULT DO PUNHO E DA MAO',
  'S60.8 OUTR TRAUM SUPERF DO PUNHO E DA MAO',
  'S60.9 TRAUM SUPERF NE DO PUNHO E DA MAO',
  'S61.0 FERIM DE DEDOS S/LESAO DA UNHA',
  'S61.1 FERIM DE DEDOS C/LESAO DA UNHA',
  'S61.7 FERIM MULT DO PUNHO E DA MAO',
  'S61.8 FERIM DE OUTR PARTES DO PUNHO E DA MAO',
  'S61.9 FERIM DO PUNHO E DA MAO PARTE NE',
  'S62.0 FRAT DO OSSO NAVICULAR DA MAO',
  'S62.1 FRAT DE OUTR OSSOS DO CARPO',
  'S62.2 FRAT DO PRIMEIRO METACARPIANO',
  'S62.3 FRAT DE OUTR OSSOS DO METACARPO',
  'S62.4 FRAT MULT DE OSSOS METACARPIANOS',
  'S62.5 FRAT DO POLEGAR',
  'S62.6 FRAT DE OUTR DEDOS',
  'S62.7 FRAT MULT DE DEDOS',
  'S62.8 FRAT DE OUTR PARTES E DE PARTES NE PUNHO MAO',
  'S63.0 LUXACAO DO PUNHO',
  'S63.1 LUXACAO DO DEDO',
  'S63.2 LUXACOES MULT DOS DEDOS',
  'S63.3 RUPTURA TRAUM DE LIGAMENTOS DO PUNHO E CARPO',
  'S63.4 RUPT TRAUM LIG DEDO ARTIC METACARP INTERFAL',
  'S63.5 ENTORSE E DISTENSAO DO PUNHO',
  'S63.6 ENTORSE E DISTENSAO DE DEDOS',
  'S63.7 ENTORSE E DISTENSAO DE OUTR PARTES E NE MAO',
  'S64.0 TRAUM DO NERVO CUBITAL AO NIVEL PUNHO E MAO',
  'S64.1 TRAUM DO NERVO MEDIANO AO NIVEL PUNHO E MAO',
  'S64.2 TRAUM DO NERVO RADIAL AO NIVEL PUNHO E MAO',
  'S64.3 TRAUM DO NERVO DIGITAL DO POLEGAR',
  'S64.4 TRAUM DO NERVO DIGITAL DE OUTR DEDO',
  'S64.7 TRAUM DE MULT NERVOS AO NIVEL DE PUNHO E MAO',
  'S64.8 TRAUM DE OUTR NERVOS AO NIVEL DO PUNHO E MAO',
  'S64.9 TRAUM DE NERVO NE AO NIVEL DO PUNHO E DA MAO',
  'S65.0 TRAUM DA ARTERIA CUBITAL NIVEL PUNHO E MAO',
  'S65.1 TRAUM DA ARTERIA RADIAL AO NIVEL PUNHO E MAO',
  'S65.2 TRAUM DO ARCO PALMAR SUPERF',
  'S65.3 TRAUM DO ARCO PALMAR PROFUNDO',
  'S65.4 TRAUM DE VASOS SANGUINEOS DO POLEGAR',
  'S65.5 TRAUM DE VASOS SANGUINEOS DE OUTR DEDO',
  'S65.7 TRAUM VASOS SANG MULT NIVEL PUNHO E MAO',
  'S65.8 TRAUM DE OUTR VASOS SANG NIVEL PUNHO E MAO',
  'S65.9 TRAUM DE VASO SANGUINEO NE NIVEL PUNHO E MAO',
  'S66.0 TRAUM MUSC FLEX LONG TEND POLEG NIV PUNH MAO',
  'S66.1 TRAUM MUSC FLEX TEND OUTR DEDO NIV PUNHO MAO',
  'S66.2 TRAUM MUSC EXTENS TEND POLEGAR NIV PUNHO MAO',
  'S66.3 TRAUM MUSC EXTENS TEND OUT DED NIV PUNHO MAO',
  'S66.4 TRAUM MUSC INTRINS TEND POLEG NIV PUNHO MAO',
  'S66.5 TRAUM MUSC INTRINS TEND OUT DED NIV PUNH MAO',
  'S66.6 TRAUM MUSC FLEXORES TEND MULT NIV PUNHO MAO',
  'S66.7 TRAUM MUSC EXTENSOR TEND MULT NIV PUNHO MAO',
  'S66.8 TRAUM OUTR MUSC TENDOES NIVEL PUNHO E MAO',
  'S66.9 TRAUM MUSCULO E TENDAO NE NIVEL PUNHO E MAO',
  'S67.0 LESAO P/ESMAG DO POLEGAR E DE OUTR DEDOS',
  'S67.8 LESAO P/ESMAG OUTR PARTES E NE PUNHO E MAO',
  'S68.0 AMPUT TRAUM DO POLEGAR',
  'S68.1 AMPUT TRAUM DE UM OUTR DEDO APENAS',
  'S68.2 AMPUT TRAUM DE DOIS OU MAIS DEDOS SOMENTE',
  'S68.3 AMPUT TRAUM COMB DEDO ASS OUT PART PUNHO MAO',
  'S68.4 AMPUT TRAUM DA MAO AO NIVEL DO PUNHO',
  'S68.8 AMPUT TRAUM DE OUTR PARTES DO PUNHO E DA MAO',
  'S68.9 AMPUT TRAUM DO PUNHO E DA MAO NIVEL NE',
  'S69.7 TRAUM MULT DO PUNHO E DA MAO',
  'S69.8 OUTR TRAUM ESPEC DO PUNHO E DA MAO',
  'S69.9 TRAUM NE DO PUNHO E DA MAO',
  'S70.0 CONTUSAO DO QUADRIL',
  'S70.1 CONTUSAO DA COXA',
  'S70.7 TRAUM SUPERF MULT DO QUADRIL E DA COXA',
  'S70.8 OUTR TRAUM SUPERF DO QUADRIL E DA COXA',
  'S70.9 TRAUM SUPERF NE DO QUADRIL E DA COXA',
  'S71.0 FERIM DO QUADRIL',
  'S71.1 FERIM DA COXA',
  'S71.7 FERIM MULT DO QUADRIL E DA COXA',
  'S71.8 FERIM OUTR PARTES E DAS NE CINTURA PELVICA',
  'S72.0 FRAT DO COLO DO FEMUR',
  'S72.1 FRAT PERTROCANTERICA',
  'S72.2 FRAT SUBTROCANTERICA',
  'S72.3 FRAT DA DIAFISE DO FEMUR',
  'S72.4 FRAT DA EXTREMIDADE DISTAL DO FEMUR',
  'S72.7 FRAT MULT DO FEMUR',
  'S72.8 FRAT DE OUTR PARTES DO FEMUR',
  'S72.9 FRAT DO FEMUR PARTE NE',
  'S73.0 LUXACAO DA ARTICULACAO DO QUADRIL',
  'S73.1 ENTORSE E DISTENSAO DO QUADRIL',
  'S74.0 TRAUM DO NERVO CIATICO NIVEL QUADRIL E COXA',
  'S74.1 TRAUM DO NERVO FEMURAL NIVEL QUADRIL E COXA',
  'S74.2 TRAUM NERVO SENSIT CUTANEO NIV QUADRIL COXA',
  'S74.7 TRAUM DE NERVOS MULT AO NIVEL QUADRIL E COXA',
  'S74.8 TRAUM DE OUTR NERVOS AO NIVEL QUADRIL E COXA',
  'S74.9 TRAUM DE NERVO NE AO NIVEL DO QUADRIL E COXA',
  'S75.0 TRAUM DA ARTERIA FEMURAL',
  'S75.1 TRAUM DA VEIA FEMURAL NIVEL QUADRIL E COXA',
  'S75.2 TRAUM VEIA GRANDE SAFENA NIV QUADRIL E COXA',
  'S75.7 TRAUM MULT VASOS SANG NIVEL QUADRIL E COXA',
  'S75.8 TRAUM OUTR VASO SANG NIVEL QUADRIL E COXA',
  'S75.9 TRAUM VASO SANGUINEO NE NIVEL QUADRIL E COXA',
  'S76.0 TRAUM DO MUSCULO E DO TENDAO DO QUADRIL',
  'S76.1 TRAUM DO MUSCULO E DO TENDAO DO QUADRICEPS',
  'S76.2 TRAUM DO MUSCULO E DO TENDAO DO ADUTOR COXA',
  'S76.3 TRAUM MUSCULO E TENDAO MUSC POST NIVEL COXA',
  'S76.4 TRAUM OUTR MUSCULOS TENDOES E NE NIVEL COXA',
  'S76.7 TRAUM MULT MUSC TENDOES NIVEL QUADRIL E COXA',
  'S77.0 LESAO P/ESMAG DO QUADRIL',
  'S77.1 LESAO P/ESMAG DA COXA',
  'S77.2 LESAO P/ESMAG DO QUADRIL E DA COXA',
  'S78.0 AMPUT TRAUM NA ARTICULACAO DO QUADRIL',
  'S78.1 AMPUT TRAUM LOCALIZADA ENTRE JOELHO QUADRIL',
  'S78.9 AMPUT TRAUM DO QUADRIL E COXA NIVEL NE',
  'S79.7 TRAUM MULT DO QUADRIL E DA COXA',
  'S79.8 OUTR TRAUM ESPEC DO QUADRIL E DA COXA',
  'S79.9 TRAUM NE DO QUADRIL E DA COXA',
  'S80.0 CONTUSAO DO JOELHO',
  'S80.1 CONTUSAO DE OUTR PARTES E DE PARTES NE PERNA',
  'S80.7 TRAUM SUPERF MULT DA PERNA',
  'S80.8 OUTR TRAUM SUPERF DA PERNA',
  'S80.9 TRAUM SUPERF NE DA PERNA',
  'S81.0 FERIM DO JOELHO',
  'S81.7 FERIM MULT DA PERNA',
  'S81.8 FERIM DE OUTR PARTES DA PERNA',
  'S81.9 FERIM DA PERNA PARTE NE',
  'S82.0 FRAT DA ROTULA',
  'S82.1 FRAT DA EXTREMIDADE PROXIMAL DA TIBIA',
  'S82.2 FRAT DA DIAFISE DA TIBIA',
  'S82.3 FRAT DA EXTREMIDADE DISTAL DA TIBIA',
  'S82.4 FRAT DO PERONIO',
  'S82.5 FRAT DO MALEOLO MEDIAL',
  'S82.6 FRAT DO MALEOLO LATERAL',
  'S82.7 FRAT MULT DA PERNA',
  'S82.8 FRAT DE OUTR PARTES DA PERNA',
  'S82.9 FRAT DA PERNA PARTE NE',
  'S83.0 LUXACAO DA ROTULA',
  'S83.1 LUXACAO DO JOELHO',
  'S83.2 RUPTURA DO MENISCO ATUAL',
  'S83.3 RUPTURA ATUAL CARTILAGEM ARTICULACAO JOELHO',
  'S83.4 ENTORSE DISTENSAO ENVOLV LIG COLAT JOELHO',
  'S83.5 ENTORSE DISTENSAO ENVOLV LIG CRUZADO JOELHO',
  'S83.6 ENTORSE DISTENSAO OUTR PARTES E NE JOELHO',
  'S83.7 TRAUM DE ESTRUTURAS MULT DO JOELHO',
  'S84.0 TRAUM DO NERVO TIBIAL AO NIVEL DA PERNA',
  'S84.1 TRAUM DO NERVO PERONIAL AO NIVEL DA PERNA',
  'S84.2 TRAUM DO NERVO CUTANEO SENSITIVO NIVEL PERNA',
  'S84.7 TRAUM DE MULT NERVOS AO NIVEL DA PERNA',
  'S84.8 TRAUM DE OUTR NERVOS AO NIVEL DA PERNA',
  'S84.9 TRAUM DE NERVO NE AO NIVEL DA PERNA',
  'S85.0 TRAUM DA ARTERIA POPLITEA',
  'S85.1 TRAUM DA ARTERIA TIBIAL',
  'S85.2 TRAUM DA ARTERIA PERONIAL',
  'S85.3 TRAUM DA VEIA GRANDE SAFENA AO NIVEL PERNA',
  'S85.4 TRAUM DA VEIA SAFENA MENOR AO NIVEL DA PERNA',
  'S85.5 TRAUM DA VEIA POPLITEA',
  'S85.7 TRAUM DE MULT VASOS SANGUINEOS NIVEL PERNA',
  'S85.8 TRAUM DE OUTR VASOS SANGUINEOS NIVEL PERNA',
  'S85.9 TRAUM DE VASOS SANGUINEOS NE AO NIVEL PERNA',
  'S86.0 TRAUM DO TENDAO DE AQUILES',
  'S86.1 TRAUM OUTR MUSC TEND GRUP MUSC POST NIV PERN',
  'S86.2 TRAUM MUSC TEND GRUPO MUSC ANT NIVEL PERNA',
  'S86.3 TRAUM MUSC TEND GRUPO MUSC PERON NIVEL PERNA',
  'S86.7 TRAUM DE MULT MUSCULOS E TENDOES NIVEL PERNA',
  'S86.8 TRAUM DE OUTR MUSCULOS E TENDOES NIVEL PERNA',
  'S86.9 TRAUM DE MUSCULO E DE TENDAO NE NIVEL PERNA',
  'S87.0 TRAUM P/ESMAG DO JOELHO',
  'S87.8 TRAUM P/ESMAG OUTR PARTE E PARTES NE PERNA',
  'S88.0 AMPUT TRAUM AO NIVEL DO JOELHO',
  'S88.1 AMPUT TRAUM ENTRE O JOELHO E O TORNOZELO',
  'S88.9 AMPUT TRAUM DA PERNA AO NIVEL NE',
  'S89.0 TRAUM MULT DA PERNA',
  'S89.8 OUTR TRAUM ESPEC DA PERNA',
  'S89.9 TRAUM NE DA PERNA',
  'S90.0 CONTUSAO DO TORNOZELO',
  'S90.1 CONTUSAO DE ARTELHO S/LESAO DA UNHA',
  'S90.2 CONTUSAO DE ARTELHO C/LESAO DA UNHA',
  'S90.3 CONTUSAO DE OUTR PARTES E PARTES NE DO PE',
  'S90.7 TRAUM SUPERF MULT DO TORNOZELO E DO PE',
  'S90.8 OUTR TRAUM SUPERF DO TORNOZELO E DO PE',
  'S90.9 TRAUM SUPERF DO TORNOZELO E DO PE NE',
  'S91.0 FERIM DO TORNOZELO',
  'S91.1 FERIM DE ARTELHOS S/LESAO DA UNHA',
  'S91.2 FERIM DE ARTELHOS C/LESAO DA UNHA',
  'S91.3 FERIM DE OUTR PARTES DO PE',
  'S91.7 FERIM MULT DO TORNOZELO E DO PE',
  'S92.0 FRAT DO CALCANEO',
  'S92.1 FRAT DO ASTRAGALO',
  'S92.2 FRAT DE OUTR OSSOS DO TARSO',
  'S92.3 FRAT DE OSSOS DO METATARSO',
  'S92.4 FRAT DO HALUX',
  'S92.5 FRAT DE OUTR ARTELHO',
  'S92.7 FRAT MULT DO PE',
  'S92.9 FRAT DO PE NE',
  'S93.0 LUXACAO DA ARTICULACAO DO TORNOZELO',
  'S93.1 LUXACAO DE ARTELHOS',
  'S93.2 RUPTURA DE LIGAMENTOS NIVEL TORNOZELO E PE',
  'S93.3 LUXACAO DE OUTR PARTES E DAS NE DO PE',
  'S93.4 ENTORSE E DISTENSAO DO TORNOZELO',
  'S93.5 ENTORSE E DISTENSAO DE ARTELHOS',
  'S93.6 ENTORSE DISTENSAO OUTR PARTES E PARTES NE PE',
  'S94.0 TRAUM DO NERVO PLANTAR EXTERNO',
  'S94.1 TRAUM DO NERVO PLANTAR INTERNO',
  'S94.2 TRAUM NERVO PERONIAL PROF NIV TORNOZELO E PE',
  'S94.3 TRAUM NERVO SENSIT CUTANEO NIV TORNOZELO PE',
  'S94.7 TRAUM DE MULT NERVOS AO NIVEL TORNOZELO E PE',
  'S94.8 TRAUM DE OUTR NERVOS AO NIVEL TORNOZELO E PE',
  'S94.9 TRAUM DE NERVO NE AO NIVEL DO TORNOZELO E PE',
  'S95.0 TRAUM DA ARTERIA DORSAL DO PE',
  'S95.1 TRAUM DA ARTERIA PLANTAR DO PE',
  'S95.2 TRAUM DA VEIA DORSAL DO PE',
  'S95.7 TRAUM MULT VASOS SANG NIVEL TORNOZELO E PE',
  'S95.8 TRAUM OUTR VASOS SANG NIVEL TORNOZELO E PE',
  'S95.9 TRAUM VASO SANGUINEO NE NIVEL TORNOZELO E PE',
  'S96.0 TRAUM MUSC TEND MUSC FLEX LONG ARTEL TORN PE',
  'S96.1 TRAUM MUSC TEND EXTEN LONG ARTEL NIV TORN PE',
  'S96.2 TRAUM MUSC INTRINSECO TENDAO NIVEL TORNOZ PE',
  'S96.7 TRAUM MULT MUSC TENDOES NIVEL TORNOZELO E PE',
  'S96.8 TRAUM OUTR TENDOES E MUSC NIV TORNOZELO E PE',
  'S96.9 TRAUM DE MUSCULO E TENDAO NE TORNOZELO E PE',
  'S97.0 LESAO P/ESMAG DO TORNOZELO',
  'S97.1 LESAO P/ESMAG DE ARTELHOS',
  'S97.8 LESAO P/ESMAG OUTR PARTES DO TORNOZELO E PE',
  'S98.0 AMPUT TRAUM DO PE AO NIVEL DO TORNOZELO',
  'S98.1 AMPUT TRAUM DE APENAS UM ARTELHO',
  'S98.2 AMPUT TRAUM DE DOIS OU MAIS ARTELHOS',
  'S98.3 AMPUT TRAUM DE OUTR PARTES DO PE',
  'S98.4 AMPUT TRAUM DO PE AO NIVEL NE',
  'S99.7 TRAUM MULT DO TORNOZELO E DO PE',
  'S99.8 OUTR TRAUM ESPEC DO TORNOZELO E DO PE',
  'S99.9 TRAUM NE DO TORNOZELO E DO PE',
  'T00.0 TRAUM SUPERF ENVOLV A CABECA C/O PESCOCO',
  'T00.1 TRAUM SUPER ENV TORAX ABD PART INF DORS PELV',
  'T00.2 TRAUM SUPERF ENVOLV REG MULT MEMBROS SUPER',
  'T00.3 TRAUM SUPERF ENVOLV REG MULT MEMBROS INFER',
  'T00.6 TRAUM SUPERF ENVOLV REG MULT MEMBR SUP INFER',
  'T00.8 TRAUM SUPERF ENVOLV OUTR COMB REGIOES CORPO',
  'T00.9 TRAUM SUPERF MULT NE',
  'T01.0 FERIM ENVOLV A CABECA C/O PESCOCO',
  'T01.1 FERIM ENVOLV TORAX ABD PART INF DORSO PELVE',
  'T01.2 FERIM ENVOLV REGIOES MULT DE MEMBROS SUPER',
  'T01.3 FERIM ENVOLV MULT REGIOES DE MEMBROS INFER',
  'T01.6 FERIM ENVOLV REGI MULT MEMBR SUPER MEMBR INF',
  'T01.8 FERIM ENVOLV OUTR COMBINACOES REGIOES CORPO',
  'T01.9 FERIM MULT NE',
  'T02.0 FRAT ENVOLV CABECA C/PESCOCO',
  'T02.1 FRAT ENVOLV TORAX C/PARTE INF DORSO E PELVE',
  'T02.2 FRAT ENVOLV REGIOES MULT DE UM MEMBRO SUPER',
  'T02.3 FRAT ENVOLV REGIOES MULT DE UM MEMBRO INFER',
  'T02.4 FRAT ENVOLV REGIOES MULT AMBOS MEMBROS SUPER',
  'T02.5 FRAT ENVOLV REGIOES MULT AMBOS MEMBROS INFER',
  'T02.6 FRAT ENVOLV REGIOES MULT MEMBROS SUP C/INFER',
  'T02.7 FRAT ENVOLV TORAX PART INF DORSO PELVE MEMBR',
  'T02.8 FRAT ENVOLV OUTR COMBINACOES REGIOES CORPO',
  'T02.9 FRAT MULT NE',
  'T03.0 LUXAC ENTORS DISTENS ENVOLV CABECA PESCOCO',
  'T03.1 LUX ENTOR DIST ENV TORAX PART INF DORS PELV',
  'T03.2 LUXAC ENTORS DIST ENVOLV REG MULT MEMBR SUP',
  'T03.3 LUXAC ENTORS DIST ENVOLV REG MULT MEMBR INF',
  'T03.4 LUXAC ENTORS DIST ENV REG MULT MEMBR SUP INF',
  'T03.8 LUXAC ENTORS DISTENS ENV OUTR COMB REG CORPO',
  'T03.9 LUXACOES ENTORSES E DISTENSOES MULT NE',
  'T04.0 TRAUM P/ESMAG ENVOLV A CABECA C/O PESCOCO',
  'T04.1 TRAUM P/ESM ENV TORAX ABD PART INF DORS PELV',
  'T04.2 TRAUM P/ESMAG ENVOLV REGIOES MULT MEMBR SUP',
  'T04.3 TRAUM P/ESMAG ENVOLV REGIOES MULT MEMBR INF',
  'T04.4 TRAUM P/ESMAG ENV REGIOES MULT MEMBR SUP INF',
  'T04.7 TRAUM ESM TORAX ABD PART INF DORS PELV MEMBR',
  'T04.8 TRAUM P/ESMAG ENVOLV OUTR COMB REGIOES CORPO',
  'T04.9 TRAUM MULT P/ESMAG NE',
  'T05.0 AMPUT TRAUM DE AMBAS AS MAOS',
  'T05.1 AMPUT TRAUM DE UMA MAO E DE UM OUTR BRACO',
  'T05.2 AMPUT TRAUM DE AMBOS OS BRACOS',
  'T05.3 AMPUT TRAUM DE AMBOS OS PES',
  'T05.4 AMPUT TRAUM DE UM PE E OUTR PERNA',
  'T05.5 AMPUT TRAUM DE AMBAS AS PERNAS',
  'T05.6 AMPUT TRAUM DE MEMBROS SUPER INFER QQ COMB',
  'T05.8 AMPUT TRAUM ENVOLV OUTR COMB REGIOES CORPO',
  'T05.9 AMPUT TRAUM MULT NE',
  'T06.0 TRAUM CER NERV CRAN NERV MED ESPINH NIV PESC',
  'T06.1 TRAUM NERV MED ESPINH ENV OUTR REG MULT CORP',
  'T06.2 TRAUM DE NERVOS ENVOLV REGIOES MULT DO CORPO',
  'T06.3 TRAUM VASOS SANG ENVOLV REGIOES MULT CORPO',
  'T06.4 TRAUM MUSCULOS TENDOES ENVOLV REG MULT CORPO',
  'T06.5 TRAUM ORG INTRATORAC C/INTRA-ABDOM PELVICOS',
  'T06.8 OUTR TRAUM ESPEC ENVOLV REGIOES MULT CORPO',
  'T07   TRAUM MULT NE',
  'T08   FRAT DA COLUNA NIVEL NE',
  'T09.0 TRAUM SUPERF DO TRONCO NIVEL NE',
  'T09.1 FERIM DO TRONCO NIVEL NE',
  'T09.2 LUXACAO ENTORSE DISTENS ARTIC LIG NE TRONCO',
  'T09.3 TRAUM DE MEDULA ESPINHAL NIVEL NE',
  'T09.4 TRAUM NERV RAIZ NERV ESPINH PLEXOS NE TRONCO',
  'T09.5 TRAUM DE MUSCULO E TENDAO NE DO TRONCO',
  'T09.6 AMPUT TRAUM DO TRONCO NIVEL NE',
  'T09.8 OUTR TRAUM ESPEC DO TRONCO NIVEL NE',
  'T09.9 TRAUM NE DO TRONCO NIVEL NE',
  'T10   FRAT DO MEMBRO SUPER NIVEL NE',
  'T11.0 TRAUM SUPERF DO MEMBRO SUPER NIVEL NE',
  'T11.1 FERIM DO MEMBRO SUPER NIVEL NE',
  'T11.2 LUX ENTOR DIST ARTIC LIG NE MEMBR SUP NIV NE',
  'T11.3 TRAUM DE NERVO NE DO MEMBRO SUPER NIVEL NE',
  'T11.4 TRAUM VASO SANGUINEO NE MEMBRO SUP NIVEL NE',
  'T11.5 TRAUM MUSCULO E TENDAO NE MEMBR SUP NIVEL NE',
  'T11.6 AMPUT TRAUM DO MEMBRO SUPER NIVEL NE',
  'T11.8 OUTR TRAUM ESPEC DO MEMBRO SUPER NIVEL NE',
  'T11.9 TRAUM NE DO MEMBRO SUPER NIVEL NE',
  'T12   FRAT DO MEMBRO INFER NIVEL NE',
  'T13.0 TRAUM SUPERF DE MEMBRO INFER NIVEL NE',
  'T13.1 FERIM DE MEMBRO INFER NIVEL NE',
  'T13.2 LUX ENTOR DIST ARTIC LIG NE MEMBR INF NIV NE',
  'T13.3 TRAUM DE NERVO NE DE MEMBRO INFER NIVEL NE',
  'T13.4 TRAUM VASO SANG NE MEMBRO INFER NIVEL NE',
  'T13.5 TRAUM MUSCULO TENDAO NE MEMBRO INF NIVEL NE',
  'T13.6 AMPUT TRAUM DE MEMBRO INFER NIVEL NE',
  'T13.8 OUTR TRAUM ESPEC DE MEMBRO INFER NIVEL NE',
  'T13.9 TRAUM NE DO MEMBRO INFER NIVEL NE',
  'T14.0 TRAUM SUPERF DE REGIAO NE DO CORPO',
  'T14.1 FERIM DE REGIAO NE DO CORPO',
  'T14.2 FRAT DE REGIAO NE DO CORPO',
  'T14.3 LUXACAO ENTORSE E DISTENSAO REGIAO NE CORPO',
  'T14.4 TRAUM DE NERVOS DE REGIAO NE DO CORPO',
  'T14.5 TRAUM DE VASOS SANGUINEOS DE REGIAO NE CORPO',
  'T14.6 TRAUM DE MUSCULOS E TENDOES REGIAO NE CORPO',
  'T14.7 TRAUM P/ESMAG E AMPUT TRAUM REGIOES NE CORPO',
  'T14.8 OUTR TRAUM DE REGIAO NE DO CORPO',
  'T14.9 TRAUM NE',
  'T15.0 CORPO ESTRANHO NA CORNEA',
  'T15.1 CORPO ESTRANHO NO SACO CONJUNTIVAL',
  'T15.8 CORPO ESTRANH OUTR LOC MULT PART EXT OLHO',
  'T15.9 CORPO ESTRANHO PARTE NE REGIAO EXTERNA OLHO',
  'T16   CORPO ESTRANHO NO OUVIDO',
  'T17.0 CORPO ESTRANHO NO SEIO NASAL',
  'T17.1 CORPO ESTRANHO NA NARINA',
  'T17.2 CORPO ESTRANHO NA FARINGE',
  'T17.3 CORPO ESTRANHO NA LARINGE',
  'T17.4 CORPO ESTRANHO NA TRAQUEIA',
  'T17.5 CORPO ESTRANHO NO BRONQUIO',
  'T17.8 CORPO ESTRANHO OUTR PARTES MULT TRATO RESP',
  'T17.9 CORPO ESTRANHO NO TRATO RESPIRAT PARTE NE',
  'T18.0 CORPO ESTRANHO NA BOCA',
  'T18.1 CORPO ESTRANHO NO ESOFAGO',
  'T18.2 CORPO ESTRANHO NO ESTOMAGO',
  'T18.3 CORPO ESTRANHO NO INTESTINO DELGADO',
  'T18.4 CORPO ESTRANHO NO COLON',
  'T18.5 CORPO ESTRANHO NO ANUS E RETO',
  'T18.8 CORPO ESTRANHO OUTR PARTES MULT AP DIGESTIVO',
  'T18.9 CORPO ESTRANHO EM PARTE NE AP DIGESTIVO',
  'T19.0 CORPO ESTRANHO NA URETRA',
  'T19.1 CORPO ESTRANHO NA BEXIGA',
  'T19.2 CORPO ESTRANHO NA VULVA E VAGINA',
  'T19.3 CORPO ESTRANHO NO UTERO',
  'T19.8 CORPO ESTRANHO OUT PART MULT TRAT GENITURIN',
  'T19.9 CORPO ESTRANHO TRATO GENITURINARIO PARTE NE',
  'T20.0 QUEIM DA CABECA E DO PESCOCO GRAU NE',
  'T20.1 QUEIM DE 1.GRAU DA CABECA E PESCOCO',
  'T20.2 QUEIM DE 2.GRAU DA CABECA E DO PESCOCO',
  'T20.3 QUEIM DE 3.GRAU DA CABECA E PESCOCO',
  'T20.4 CORROSAO DA CABECA E DO PESCOCO GRAU NE',
  'T20.5 CORROSAO DE 1.GRAU CABECA E PESCOCO',
  'T20.6 CORROSAO DE 2.GRAU CABECA E PESCOCO',
  'T20.7 CORROSAO DE 3.GRAU CABECA E PESCOCO',
  'T21.0 QUEIM DO TRONCO GRAU NE',
  'T21.1 QUEIM DE 1.GRAU DO TRONCO',
  'T21.2 QUEIM DE 2.GRAU DO TRONCO',
  'T21.3 QUEIM DE 3.GRAU DO TRONCO',
  'T21.4 CORROSAO DO TRONCO GRAU NE',
  'T21.5 CORROSAO DE 1.GRAU DO TRONCO',
  'T21.6 CORROSAO DE 2.GRAU DO TRONCO',
  'T21.7 CORROSAO DE 3.GRAU DO TRONCO',
  'T22.0 QUEIM OMBRO MEMBR SUP EXC PUNHO MAO GRAU NE',
  'T22.1 QUEIM 1.GRAU OMBRO MEMBR SUP EXC PUNHO E MAO',
  'T22.2 QUEIM 2.GRAU OMBRO MEMBR SUP EXC PUNHO E MAO',
  'T22.3 QUEIM 3.GRAU OMBRO MEMBR SUP EXC PUNHO E MAO',
  'T22.4 CORROS OMBRO MEMBR SUP EXC PUNHO MAO GRAU NE',
  'T22.5 CORROSAO 1.GRAU OMBR MEMBR SUP EXC PUNHO MAO',
  'T22.6 CORROSAO 2.GRAU OMBR MEMBR SUP EXC PUNHO MAO',
  'T22.7 CORROSAO 3.GRAU OMBR MEMBR SUP EXC PUNHO MAO',
  'T23.0 QUEIM DO PUNHO E DA MAO GRAU NE',
  'T23.1 QUEIM DE 1.GRAU DO PUNHO E DA MAO',
  'T23.2 QUEIM DE 2.GRAU DO PUNHO E DA MAO',
  'T23.3 QUEIM DE 3.GRAU DO PUNHO E DA MAO',
  'T23.4 CORROSAO DO PUNHO E DA MAO GRAU NE',
  'T23.5 CORROSAO DE 1.GRAU DO PUNHO E DA MAO',
  'T23.6 CORROSAO DE 2.GRAU DO PUNHO E DA MAO',
  'T23.7 CORROSAO DE 3.GRAU DO PUNHO E DA MAO',
  'T24.0 QUEIM QUADR MEMBR INF EXC TORNOZ PE GRAU NE',
  'T24.1 QUEIM 1.GRAU QUADR MEMBR INF EXC TORNOZ PE',
  'T24.2 QUEIM 2.GRAU QUADR MEMBR INF EXC TORNOZ PE',
  'T24.3 QUEIM 3.GRAU QUADR MEMBR INF EXC TORNOZ PE',
  'T24.4 CORROS QUADR MEMBR INF EXC TORNOZ PE GRAU NE',
  'T24.5 CORROS 1.GRAU QUADR MEMBR INF EXC TORNOZ PE',
  'T24.6 CORROS 2.GRAU QUADR MEMBR INF EXC TORNOZ PE',
  'T24.7 CORROS 3.GRAU QUADR MEMBR INF EXC TORNOZ PE',
  'T25.0 QUEIM DO TORNOZELO E DO PE GRAU NE',
  'T25.1 QUEIM DE 1.GRAU DO TORNOZELO E DO PE',
  'T25.2 QUEIM DE 2.GRAU DO TORNOZELO E DO PE',
  'T25.3 QUEIM DE 3.GRAU DO TORNOZELO E DO PE',
  'T25.4 CORROSAO DO TORNOZELO E DO PE GRAU NE',
  'T25.5 CORROSAO DE 1.GRAU DO TORNOZELO E DO PE',
  'T25.6 CORROSAO DE 2.GRAU DO TORNOZELO E DO PE',
  'T25.7 CORROSAO DE 3.GRAU DO TORNOZELO E DO PE',
  'T26.0 QUEIM DA PALPEBRA E DA REGIAO PERIOCULAR',
  'T26.1 QUEIM DA CORNEA E DO SACO CONJUNTIVAL',
  'T26.2 QUEIM C/RESULT RUPT E DESTRUIC GLOBO OCULAR',
  'T26.3 QUEIM DE OUTR PARTES DO OLHO E ANEXOS',
  'T26.4 QUEIM DO OLHO E ANEXOS PARTE NE',
  'T26.5 CORROSAO DA PALPEBRA E DA REGIAO PERIOCULAR',
  'T26.6 CORROSAO DA CORNEA E DO SACO CONJUNTIVAL',
  'T26.7 CORROSAO C/RESULT RUPT DESTRUIC GLOBO OCULAR',
  'T26.8 CORROSAO DE OUTR PARTES DO OLHO E ANEXOS',
  'T26.9 CORROSAO DO OLHO E ANEXOS PARTE NE',
  'T27.0 QUEIM DA LARINGE E TRAQUEIA',
  'T27.1 QUEIM DA LARINGE TRAQUEIA C/PULMAO',
  'T27.2 QUEIM DE OUTR PARTES DO TRATO RESPIRAT',
  'T27.3 QUEIM DO TRATO RESPIRAT PARTE NE',
  'T27.4 CORROSAO DA LARINGE E TRAQUEIA',
  'T27.5 CORROSAO LARINGE TRAQUEIA C/CORROSAO PULMAO',
  'T27.6 CORROSAO DE OUTR PARTES DO TRATO RESPIRAT',
  'T27.7 CORROSAO DO TRATO RESPIRAT PARTE NE',
  'T28.0 QUEIM DA BOCA E DA FARINGE',
  'T28.1 QUEIM DO ESOFAGO',
  'T28.2 QUEIM DE OUTR PARTES DO TRATO ALIMENTAR',
  'T28.3 QUEIM DOS ORGAOS GENITURINARIOS INTERNOS',
  'T28.4 QUEIM DE OUTR ORGAOS INTERNOS E DOS NE',
  'T28.5 CORROSAO DA BOCA E FARINGE',
  'T28.6 CORROSAO DO ESOFAGO',
  'T28.7 CORROSAO DE OUTR PARTES DO TRATO ALIMENTAR',
  'T28.8 CORROSAO DE ORGAOS GENITURINARIOS INTERNOS',
  'T28.9 CORROSAO DE OUTR ORGAOS INTERNOS E DOS NE',
  'T29.0 QUEIM MULT GRAU NE',
  'T29.1 QUEIM MULT S/MENC QUEIM ULTRAPASS 1.GRAU',
  'T29.2 QUEIM MULT S/MENC QUEIM ULTRAPASS 2.GRAU',
  'T29.3 QUEIM MULT MENC AO MENOS UMA QUEIM DE 3.GRAU',
  'T29.4 CORROSOES MULT GRAU NE',
  'T29.5 CORROSOES MULT S/MENC CORROS ULTRAPAS 1.GRAU',
  'T29.6 CORROSOES MULT S/MENC CORROS ULTRAPAS 2.GRAU',
  'T29.7 CORROSOES MULT MENC AO MENOS CORROS 3.GRAU',
  'T30.0 QUEIM PARTE DO CORPO NE GRAU NE',
  'T30.1 QUEIM DE 1.GRAU PARTE DO CORPO NE',
  'T30.2 QUEIM DE 2.GRAU PARTE DO CORPO NE',
  'T30.3 QUEIM DE 3.GRAU PARTE DO CORPO NE',
  'T30.4 CORROSAO PARTE DO CORPO NE GRAU NE',
  'T30.5 CORROSAO DE 1.GRAU PARTE DO CORPO NE',
  'T30.6 CORROSAO DE 2.GRAU PARTE DO CORPO NE',
  'T30.7 CORROSAO DE 3.GRAU PARTE DO CORPO NE',
  'T31.0 QUEIM ENVOLV MENOS DE 10% DA SUPERF CORPORAL',
  'T31.1 QUEIM ENVOLV DE 10-19% DA SUPERF CORPORAL',
  'T31.2 QUEIM ENVOLV DE 20-29% DA SUPERF CORPORAL',
  'T31.3 QUEIM ENVOLV DE 30-39% DA SUPERF CORPORAL',
  'T31.4 QUEIM ENVOLV DE 40-49% DA SUPERF CORPORAL',
  'T31.5 QUEIM ENVOLV DE 50-59% DA SUPERF CORPORAL',
  'T31.6 QUEIM ENVOLV DE 60-69% DA SUPERF CORPORAL',
  'T31.7 QUEIM ENVOLV DE 70-79% DA SUPERF CORPORAL',
  'T31.8 QUEIM ENVOLV DE 80-89% DA SUPERF CORPORAL',
  'T31.9 QUEIM ENVOLV 90% OU MAIS DA SUPERF CORPORAL',
  'T32.0 CORROSOES ENVOLV MENOS DE 10% SUPERF CORP',
  'T32.1 CORROSOES ENVOLV 10-19% DA SUPERF CORPORAL',
  'T32.2 CORROSOES ENVOLV 20-29% DA SUPERF CORPORAL',
  'T32.3 CORROSOES ENVOLV 30-39% DA SUPERF CORPORAL',
  'T32.4 CORROSOES ENVOLV 40-49% DA SUPERF CORPORAL',
  'T32.5 CORROSOES ENVOLV 50-59% DA SUPERF CORPORAL',
  'T32.6 CORROSOES ENVOLV 60-69% DA SUPERF CORPORAL',
  'T32.7 CORROSOES ENVOLV 70-79% DA SUPERF CORPORAL',
  'T32.8 CORROSOES ENVOLV 80-89% DA SUPERF CORPORAL',
  'T32.9 CORROSOES ENVOLV 90% OU MAIS SUPERF CORPORAL',
  'T33.0 GELADURA SUPERF DA CABECA',
  'T33.1 GELADURA SUPERF DO PESCOCO',
  'T33.2 GELADURA SUPERF DO TORAX',
  'T33.3 GELADURA SUPERF PARED ABD PART INF DORS PELV',
  'T33.4 GELADURA SUPERF DO BRACO',
  'T33.5 GELADURA SUPERF DO PUNHO E DA MAO',
  'T33.6 GELADURA SUPERF DO QUADRIL E DA COXA',
  'T33.7 GELADURA SUPERF DO JOELHO E DA PERNA',
  'T33.8 GELADURA SUPERF DO TORNOZELO E DO PE',
  'T33.9 GELADURA SUPERF DE OUTR LOCAIS E LOCAIS NE',
  'T34.0 GELADURA C/NECROSE DE TEC DA CABECA',
  'T34.1 GELADURA C/NECROSE DE TEC DO PESCOCO',
  'T34.2 GELADURA C/NECROSE DE TEC DO TORAX',
  'T34.3 GELAD NECR TEC PARED ABD PART INF DORS PELV',
  'T34.4 GELADURA C/NECROSE DE TEC DO BRACO',
  'T34.5 GELADURA C/NECROSE DE TEC DO PUNHO E DA MAO',
  'T34.6 GELADURA C/NECROSE DE TEC DO QUADRIL E COXA',
  'T34.7 GELADURA C/NECROSE DE TEC DO JOELHO E PERNA',
  'T34.8 GELADURA C/NECROSE DE TEC DO TORNOZELO E PE',
  'T34.9 GELADURA C/NECROSE DE TEC DE LOCALIZ NE',
  'T35.0 GELADURA SUPERF ENVOLV MULT PARTES DO CORPO',
  'T35.1 GELADURA C/NECROSE DE TEC MULT PARTES CORPO',
  'T35.2 GELADURA DE GRAU NE DA CABECA E DO PESCOCO',
  'T35.3 GELAD GRAU NE TORAX ABD PART INF DORSO PELVE',
  'T35.4 GELADURA DE GRAU NE DO MEMBRO SUPER',
  'T35.5 GELADURA DE GRAU NE DO MEMBRO INFER',
  'T35.6 GELADURA DE GRAU NE DE MULT PARTES DO CORPO',
  'T35.7 GELADURA DE GRAU NE DE LOCALIZ NE',
  'T36.0 PENICILINAS',
  'T36.1 CEFALOSPORINAS E OUTR BETALACTAMINAS',
  'T36.2 GRUPO DO CLORANFENICOL',
  'T36.3 MACROLIDIOS',
  'T36.4 TETRACICLINAS',
  'T36.5 AMINOGLICOSIDEOS',
  'T36.6 RIFAMICINAS',
  'T36.7 ANTIBIOTICOS ANTIFUNGICOS ADMIN P/VIA SISTEM',
  'T36.8 OUTR ANTIBIOTICOS SISTEMICOS',
  'T36.9 ANTIBIOTICOS SISTEMICOS NE',
  'T37.0 SULFONAMIDAS',
  'T37.1 DROGAS ANTIMICOBACTERIANAS',
  'T37.2 ANTIMALARICOS DROG Q ATUAM S/PROTOZ SANGUE',
  'T37.3 OUTR MEDICAMENTOS ANTIPROTOZOARIOS',
  'T37.4 ANTI-HELMINTICOS',
  'T37.5 DROGAS ANTIVIRAIS',
  'T37.8 OUTR AGENTES ANTIINFECC E ANTIPARASIT ESPEC',
  'T37.9 AGENTES ANTIINFECC E ANTIPARASIT SISTEM NE',
  'T38.0 GLICOCORTICOIDES E ANALOGOS SINTETICOS',
  'T38.1 HORMONIOS TIREOIDIANOS E SEUS DERIVADOS',
  'T38.2 MEDICAMENTOS ANTITIREOIDIANOS',
  'T38.3 INSULINA E DROGAS HIPOGLICEMIANTES ORAIS',
  'T38.4 ANTICONCEPCIONAIS ORAIS',
  'T38.5 OUTR ESTROGENOS E PROGESTAGENOS',
  'T38.6 ANTIGONADOTROFIN ANTIESTROG ANTIANDROG NCOP',
  'T38.7 ANDROGENOS E ANABOLIZANTES CONGENERES',
  'T38.8 OUTR HORMONIOS E SUBSTITUTOS SINTETICOS E NE',
  'T38.9 OUTR ANTAGONISTAS HORMONAIS E OS NE',
  'T39.0 SALICILATOS',
  'T39.1 DERIVADOS DO 4-AMINOFENOL',
  'T39.2 DERIVADOS PIRAZOLONICOS',
  'T39.3 OUTR ANTIINFLAMATORIOS NAO ESTEROIDES',
  'T39.4 ANTI-REUMATICOS NCOP',
  'T39.8 OUTR ANALGESICOS NAO OPIAC E ANTIPIRET NCOP',
  'T39.9 ANALGES N-OPIACEO ANTIPIRET E ANTI-REUMAT NE',
  'T40.0 OPIO',
  'T40.1 HEROINA',
  'T40.2 OUTR OPIACEOS',
  'T40.3 METADONA',
  'T40.4 OUTR NARCOTICOS SINTETICOS',
  'T40.5 COCAINA',
  'T40.6 OUTR NARCOTICOS E OS NE',
  'T40.7 CANNABIS',
  'T40.8 LISERGIDA',
  'T40.9 OUTR PSICODISLEPTICOS E OS NE',
  'T41.0 ANESTESICOS INALATORIOS',
  'T41.1 ANESTESICOS INTRAVENOSOS',
  'T41.2 OUTR ANESTESICOS GERAIS E OS NE',
  'T41.3 ANESTESICOS LOCAIS',
  'T41.4 ANESTESICO NE',
  'T41.5 GASES TERAPEUTICOS',
  'T42.0 DERIVADOS DA HIDANTOINA',
  'T42.1 IMINOSTILBENOS',
  'T42.2 SUCCINIMIDAS E OXAZOLIDINADIONAS',
  'T42.3 BARBITURICOS',
  'T42.4 BENZODIAZEPINAS',
  'T42.5 ANTIEPILETICOS EM ASSOCIACAO NCOP',
  'T42.6 OUTR DROGAS ANTIEPILEPTICAS SEDATIVOS-HIPNOT',
  'T42.7 DROGAS ANTIEPILEPTICOS SEDATIVOS-HIPNOT NE',
  'T42.8 DROG ANTIPARK OUTR DEPRESS CENTR TONUS MUSC',
  'T43.0 ANTIDEPRESSIVOS TRICICLICOS E TETRACICLICOS',
  'T43.1 ANTIDEPRESSIVOS INIBIDORES MONOAMINOOXIDASE',
  'T43.2 OUTR ANTIDEPRESSIVOS E OS NE',
  'T43.3 ANTIPSICOTICOS NEUROLEPT DERIV FENOTIAZINA',
  'T43.4 NEUROLEPTICOS TIPO BUTIROFENONA E TIOXANTENO',
  'T43.5 OUTR ANTIPSICOTICOS E NEUROLEPTICOS E OS NE',
  'T43.6 PSICOESTIMUL QUE POTENC PODEM PROV DEPEND',
  'T43.8 OUTR DROGAS PSICOTROPICAS NCOP',
  'T43.9 DROGA PSICOTROPICA NE',
  'T44.0 AGENTES ANTICOLINESTERASE',
  'T44.1 OUTR AGENTES PARASSIMPATICOMIMETICOS',
  'T44.2 BLOQUEADORES GANGLIONARES NCOP',
  'T44.3 OUTR PARASSIMPATICOLITICOS ESPASMOLIT NCOP',
  'T44.4 AGONISTAS PRINC ALFA-ADRENORRECEPTORES NCOP',
  'T44.5 AGONISTAS PRINC BETA-ADRENORRECEPTORES NCOP',
  'T44.6 ANTAGONISTAS ALFA-ADRENORRECEPTORES NCOP',
  'T44.7 ANTAGONISTAS BETA-ADRENORRECEPTORES NCOP',
  'T44.8 BLOQUEADOR NEURON ADRENERG ACAO CENTRAL NCOP',
  'T44.9 OUTR DROG Q AFET PRINC SIST NERV AUTONOMO NE',
  'T45.0 DROGAS ANTIALERGICAS E ANTIEMETICAS',
  'T45.1 DROGAS ANTINEOPLASICAS E IMUNOMUPRESSORAS',
  'T45.2 VITAMINAS NCOP',
  'T45.3 ENZIMAS NCOP',
  'T45.4 FERRO E SEUS COMPOSTOS',
  'T45.5 ANTICOAGULANTES',
  'T45.6 DROGAS QUE AFETAM A FIBRINOLISE',
  'T45.7 ANTAGONIST ANTICOAG VITAMINA K OUTR FAT COAG',
  'T45.8 OUTR SUBST PRIM SISTEMICAS E HEMATOLOGICAS',
  'T45.9 SUBST ESSENC SISTEMICA HEMATOLOGICA NE',
  'T46.0 GLICOSIDEOS ESTIM CORACAO SUBST ACAO SEMELH',
  'T46.1 BLOQUEADORES DOS CANAIS DE CALCIO',
  'T46.2 OUTR DROGAS ANTIDISRITMICAS NCOP',
  'T46.3 VASODILATADORES CORONARIANOS NCOP',
  'T46.4 INIBIDORES DA ENZIMA CONVERSAO ANGIOTENSINA',
  'T46.5 OUTR DROGAS ANTI-HIPERTENSIVAS NCOP',
  'T46.6 DROG ANTIHIPERLIPEMICAS ANTIARTERIOSCLEROTIC',
  'T46.7 VASODILATADORES PERIFERICOS',
  'T46.8 DROG ANTIVARICOSAS INCL SUBST ESCLEROSANTES',
  'T46.9 OUTR SUBST E NE QUE AGEM ESSENC S/AP CIRC',
  'T47.0 ANTAGONISTAS DOS RECEPTORES H2 DA HISTAMINA',
  'T47.1 OUTR ANTIACIDOS DROG Q INIBEM SECR GASTRICA',
  'T47.2 LAXATIVOS ESTIMULANTES',
  'T47.3 LAXATIVOS SALINOS E OSMOTICOS',
  'T47.4 OUTR LAXATIVOS',
  'T47.5 DIGESTIVOS',
  'T47.6 DROGAS ANTIDIARREICAS',
  'T47.7 EMETICOS',
  'T47.8 OUTR SUBST QUE ATUAM PRIM S/AP GASTRINTEST',
  'T47.9 SUBST NE QUE ATUA PRIM S/AP GASTROINTESTINAL',
  'T48.0 DROGAS OCITOCICAS',
  'T48.1 RELAXANTES MUSCULARES ESQUELETICOS',
  'T48.2 OUTR SUBST E AS NE QUE ATUAM SOBRE MUSCULOS',
  'T48.3 ANTITUSSIGENOS',
  'T48.4 EXPECTORANTES',
  'T48.5 DROGAS ANTI-RESFRIADO',
  'T48.6 ANTIASMATICOS NCOP',
  'T48.7 OUTR SUBST E NE QUE ATUAM PRIM S/AP RESPIRAT',
  'T49.0 ANTIFUNG ANTIINFEC ANTIINFLAMAT USO TOP NCOP',
  'T49.1 ANTIPRURIGINOSOS',
  'T49.2 ADSTRINGENTES E DETERGENTES DE USO LOCAL',
  'T49.3 EMOLIENTES DEMULCENTES E PROTETORES',
  'T49.4 CERATOL CERATOPL OUT DROG PREPAR TRAT CABELO',
  'T49.5 DROGAS E PREPARACOES DE USO OFTALMOLOGICO',
  'T49.6 DROGAS PREPARACOES USO OTORRINOLARINGOLOGICO',
  'T49.7 DROGAS DENTARIAS DE USO TOPICO',
  'T49.8 OUTR DROGAS DE USO TOPICO',
  'T49.9 PREPARADO DE USO TOPICO NE',
  'T50.0 MINERALOCORTICOIDES E SEUS ANTAGONISTAS',
  'T50.1 DIURETICOS DE ALCA',
  'T50.2 INIB ANIDRASE CARB BENZOTIAZIDAS OUTR DIURET',
  'T50.3 PROD QUE AGEM S/EQUIL ELETR CALORIC HIDRIC',
  'T50.4 DROGAS QUE AGEM SOBRE METAB ACIDO URICO',
  'T50.5 INIBIDORES DO APETITE',
  'T50.6 ANTIDOTOS E QUELANTES NCOP',
  'T50.7 ANALEPTICOS ANTAGONISTAS RECEPTORES OPIACEOS',
  'T50.8 AGENTES DE DIAGN',
  'T50.9 OUTR DROGAS MEDICAMENTOS SUBST BIOLOG E NE',
  'T51.0 ETANOL',
  'T51.1 METANOL',
  'T51.2 2-PROPANOL',
  'T51.3 OLEO DE FUSEL',
  'T51.8 OUTR ALCOOIS',
  'T51.9 ALCOOL NE',
  'T52.0 DERIVADOS DO PETROLEO',
  'T52.1 BENZENO',
  'T52.2 HOMOLOGOS DO BENZENO',
  'T52.3 GLICOIS',
  'T52.4 CETONAS',
  'T52.8 OUTR SOLVENTES ORGANICOS',
  'T52.9 SOLVENTE ORGANICO NE',
  'T53.0 TETRACLORETO DE CARBONO',
  'T53.1 CLOROFORMIO',
  'T53.2 TRICLOROETILENO',
  'T53.3 TETRACLOROETILENO',
  'T53.4 DICLOROMETANO',
  'T53.5 CLOROFLUORCARBONO',
  'T53.6 OUTR DERIV HALOG HIDROCARBONETOS ALIFATICOS',
  'T53.7 DERIV HALOGENICOS HIDROCARBONETOS AROMATICOS',
  'T53.9 DERIV HALOG HIDROCARBONETOS ALIFAT AROMAT NE',
  'T54.0 FENOL E HOMOLOGOS DO FENOL',
  'T54.1 OUTR COMPOSTOS CORROSIVOS ORGANICOS',
  'T54.2 ACIDOS CORROSIVOS E SUBST SEMELHANTES',
  'T54.3 BASES CAUSTICAS E SUBST SEMELHANTES',
  'T54.9 SUBST CORROSIVA NE',
  'T55   EFEITO TOXICO DE SABOES E DETERGENTES',
  'T56.0 CHUMBO E SEUS COMPOSTOS',
  'T56.1 MERCURIO E SEUS COMPOSTOS',
  'T56.2 CROMO E SEUS COMPOSTOS',
  'T56.3 CADMIO E SEUS COMPOSTOS',
  'T56.4 COBRE E SEUS COMPOSTOS',
  'T56.5 ZINCO E SEUS COMPOSTOS',
  'T56.6 ESTANHO E SEUS COMPOSTOS',
  'T56.7 BERILO E SEUS COMPOSTOS',
  'T56.8 OUTR METAIS',
  'T56.9 METAL NE',
  'T57.0 ARSENICO E SEUS COMPOSTOS',
  'T57.1 FOSFORO E SEUS COMPOSTOS',
  'T57.2 MANGANES E SEUS COMPOSTOS',
  'T57.3 CIANETO DE HIDROGENIO',
  'T57.8 OUTR SUBST INORGANICAS ESPEC',
  'T57.9 SUBST INORGANICA NE',
  'T58   EFEITO TOXICO DE MONOXIDO DE CARBONO',
  'T59.0 OXIDOS DE NITROGENIO',
  'T59.1 DIOXIDO DE ENXOFRE',
  'T59.2 FORMALDEIDO',
  'T59.3 GAS LACRIMOGENIO',
  'T59.4 CLORO GASOSO',
  'T59.5 FLUORETO GASOSO E ACIDO FLUORIDRICO',
  'T59.6 ACIDO SULFIDRICO',
  'T59.7 DIOXIDO DE CARBONO',
  'T59.8 OUTR GASES FUMACAS E VAPORES ESPEC',
  'T59.9 GASES FUMACAS E VAPORES NE',
  'T60.0 INSETICIDAS ORGANOFOSFORADOS E CARBAMATOS',
  'T60.1 INSETICIDAS HALOGENADOS',
  'T60.2 OUTR INSETICIDAS',
  'T60.3 HERBICIDAS E FUNGICIDAS',
  'T60.4 RODENTICIDAS',
  'T60.8 OUTR PESTICIDAS',
  'T60.9 PESTICIDA NE',
  'T61.0 INTOX P/PEIXE CIGUATERA',
  'T61.1 INTOX P/PEIXES ESCOMBRIDEOS',
  'T61.2 OUTR INTOX P/PEIXES E MARISCOS',
  'T61.8 EFEITO TOXICO DE OUTR FRUTOS DO MAR',
  'T61.9 EFEITO TOXICO DE FRUTOS DO MAR NE',
  'T62.0 INGESTAO DE COGUMELOS',
  'T62.1 INGESTAO DE FRUTOS',
  'T62.2 INGESTAO DE OUTR PLANTAS',
  'T62.8 OUTR SUBST NOCIVAS INGERIDAS COMO ALIMENTO',
  'T62.9 SUBST NOCIVAS INGERIDAS COMO ALIMENTO NE',
  'T63.0 VENENO DE SERPENTE',
  'T63.1 VENENO DE OUTR REPTEIS',
  'T63.2 VENENO DE ESCORPIAO',
  'T63.3 VENENO DE ARANHA',
  'T63.4 VENENO DE OUTR ARTROPODES',
  'T63.5 CONTATO C/PEIXE',
  'T63.6 CONTATO C/OUTR ANIMAIS MARINHOS',
  'T63.8 CONTATO C/OUTR ANIMAIS VENENOSOS',
  'T63.9 CONTATO C/ANIMAL VENENOSO NE',
  'T64   EFEITO TOX AFLATOX OUTR MICOTOX CONTAM ALIM',
  'T65.0 CIANETOS',
  'T65.1 ESTRICNINA E SEUS SAIS',
  'T65.2 TABACO E DA NICOTINA',
  'T65.3 NITRODERIV AMINODERIV BENZENO SEUS HOMOLOGOS',
  'T65.4 DISSULFETO DE CARBONO',
  'T65.5 NITROGLICERINA OUTR ACIDOS ESTERES NITRICOS',
  'T65.6 TINTURAS E CORANTES NCOP',
  'T65.8 OUTR SUBST ESPEC',
  'T65.9 SUBST NE',
  'T66   EFEITOS NE DE RADIACAO',
  'T67.0 GOLPE DE CALOR E INSOLACAO',
  'T67.1 SINCOPE DEV CALOR',
  'T67.2 CAIBRAS DEV CALOR',
  'T67.3 EXAUSTAO DEV CALOR E A PERDA HIDRICA',
  'T67.4 EXAUSTAO DEV CALOR E A PERDA DE SAL',
  'T67.5 EXAUSTAO DEV CALOR S/ESPECIFICACAO',
  'T67.6 FADIGA TRANSITORIA DEV CALOR',
  'T67.7 EDEMA DEV CALOR',
  'T67.8 OUTR EFEITOS DO CALOR E DA LUZ',
  'T67.9 EFEITO DO CALOR E DA LUZ NE',
  'T68   HIPOTERMIA',
  'T69.0 MAO E PE DE IMERSAO',
  'T69.1 CONGELAMENTO',
  'T69.8 OUTR EFEITOS ESPEC DE TEMPERATURA REDUZIDA',
  'T69.9 EFEITO NE DE TEMPERATURA REDUZIDA',
  'T70.0 OTITE BAROTRAUMATICA',
  'T70.1 SINUSITE BAROTRAUMATICA',
  'T70.2 OUTR EFEITOS DAS GRANDES ALTITUDE E OS NE',
  'T70.3 MAL DOS CAIXOES',
  'T70.4 EFEITO DOS FLUIDOS EM ALTA PRESSAO',
  'T70.8 OUTR EFEITOS PRESSAO ATMOSFERICA PRESS AGUA',
  'T70.9 EFEITOS NE PRESSAO ATMOSFERICA PRESSAO AGUA',
  'T71   ASFIXIA',
  'T73.0 EFEITOS DA FOME',
  'T73.1 EFEITOS DA SEDE',
  'T73.2 EXAUSTAO DEV INTEMPERIES',
  'T73.3 EXAUSTAO DEV UM ESFORCO INTENSO',
  'T73.8 OUTR EFEITOS DE PRIVACAO',
  'T73.9 EFEITO NE DE PRIVACAO',
  'T74.0 ABANDONO',
  'T74.1 SEVICIAS FISICAS',
  'T74.2 ABUSO SEXUAL',
  'T74.3 ABUSO PSICOLOGICO',
  'T74.8 OUTR SINDR ESPEC DE MAUS TRATOS',
  'T74.9 SINDR NE DE MAUS TRATOS',
  'T75.0 EFEITOS DO RAIO',
  'T75.1 AFOGAMENTO E SUBMERSAO NAO MORTAL',
  'T75.2 EFEITOS DA VIBRACAO',
  'T75.3 DOENC CAUSADA P/MOVIMENTO',
  'T75.4 EFEITOS DA CORRENTE ELETRICA',
  'T75.8 OUTR EFEITOS ESPEC DE CAUSAS EXTERNAS',
  'T78.0 CHOQUE ANAFILATICO DEV INTOLERANCIA ALIM',
  'T78.1 OUTR REACOES DE INTOLERANCIA ALIMENTAR NCOP',
  'T78.2 CHOQUE ANAFILATICO NE',
  'T78.3 EDEMA ANGIONEUROTICO',
  'T78.4 ALERGIA NE',
  'T78.8 OUTR EFEITOS ADVERSOS NCOP',
  'T78.9 EFEITO ADVERSO NE',
  'T79.0 EMBOLIA GASOSA',
  'T79.1 EMBOLIA GORDUROSA',
  'T79.2 HEMORRAGIA TRAUM SECUND E RECIDIVANTE',
  'T79.3 INFECC POS-TRAUM DE FERIM NCOP',
  'T79.4 CHOQUE TRAUM',
  'T79.5 ANURIA TRAUM',
  'T79.6 ISQUEMIA MUSCULAR TRAUM',
  'T79.7 ENFISEMA SUBCUTANEO ORIG TRAUM',
  'T79.8 OUTR COMPLIC PRECOCES DE TRAUM',
  'T79.9 COMPLIC PRECOCE NE DE TRAUM',
  'T80.0 EMBOLIA AEREA SUBS INFUS TRANSF INJEC TERAP',
  'T80.1 COMPLIC VASC SUBSEQ INFUS TRANSF INJEC TERAP',
  'T80.2 INFECC SUBSEQ INFUSAO TRANSFUS INJECAO TERAP',
  'T80.3 REACAO DE INCOMPATIBILIDADE ABO',
  'T80.4 REACAO DE INCOMPATIBILIDADE RH',
  'T80.5 CHOQUE ANAFILATICO DEV SORO',
  'T80.6 OUTR REACOES AO SORO',
  'T80.8 OUTR COMPLIC SUBSEQ INFUS TRANSF INJEC TERAP',
  'T80.9 COMPLIC NE SUBSEQ INFUS TRANSFUS INJEC TERAP',
  'T81.0 HEMORRAGIA E HEMATOMA COMPLIC PROCED NCOP',
  'T81.1 CHOQUE DURANTE OU RESULTANTE DE PROCED NCOP',
  'T81.2 PERFURACAO LACERAC ACID DURANTE PROCED NCOP',
  'T81.3 DEISCENCIA DE FERIDA CIRURGICA NCOP',
  'T81.4 INFECC SUBSEQUENTE A PROCED NCOP',
  'T81.5 CORP ESTR ACID CAV CORP FERID OPER SUBS PROC',
  'T81.6 REACAO AG SUBST ESTR DEIX ACID DURANTE PROC',
  'T81.7 COMPLIC VASC SUBSEQUENTES A UM PROCED NCOP',
  'T81.8 OUTR COMPLIC DE PROCED NCOP',
  'T81.9 COMPLIC NE DE PROCED',
  'T82.0 COMPLIC MECANICA PROTESE VALVULAR CARDIACA',
  'T82.1 COMPLIC MECANICA DE DISP ELETRONICO CARDIACO',
  'T82.2 COMPLIC MEC ENXERTO PONTE CORON IMPL VALVAS',
  'T82.3 COMPLIC MECANICAS OUTR ENXERTOS VASCULARES',
  'T82.4 COMPLIC MECANICA DE CATETER VASCULAR DIALISE',
  'T82.5 COMPLIC MEC OUTR DISP IMPLANTES CARDIOVASC',
  'T82.6 INFECC REAC INFLAM DEV PROTESE VALVULAR CARD',
  'T82.7 INF REAC INF DEV OUT DISP IMPL ENX CARD VASC',
  'T82.8 OUTR COMPLIC DISP PROT IMPL ENX CARD VASC',
  'T82.9 COMPLIC NE DISP PROT IMPLANT ENX CARD VASC',
  'T83.0 COMPLIC MECANICA DE CATETER URINARIO',
  'T83.1 COMPLIC MEC OUTR DISP IMPLANTES URINARIOS',
  'T83.2 COMPLIC MEC DE ENXERTO DE ORGAO URINARIO',
  'T83.3 COMPLIC MECANICA DE DISP INTRA-UTERINO',
  'T83.4 COMPLIC MEC OUT DISP PROT IMPL ENX TRAT GEN',
  'T83.5 INF REAC INFL DEV DISP PROT IMPL ENX AP URIN',
  'T83.6 INF REAC INF DEV DISP PROT IMPL ENX TRAT GEN',
  'T83.8 OUTR COMPLIC DISP PROT IMPL ENX GENITURI',
  'T83.9 COMPLIC NE PROTESE IMPL ENX GENITURINARIOS',
  'T84.0 COMPLIC MECANICA PROTESE ARTICULAR INTERNA',
  'T84.1 COMPLIC MEC DISP FIXACAO INT OSSOS MEMBROS',
  'T84.2 COMPLIC MEC DISP FIXACAO INTERNA OUTR OSSOS',
  'T84.3 COMPLIC MEC OUTR DISP IMPL ENXERTOS OSSEOS',
  'T84.4 COMPLIC MEC OUTR DISP IMPL ENX ORTOPED INT',
  'T84.5 INFECC REACAO INFLAM DEV PROTESE ARTIC INT',
  'T84.6 INFECC REACAO INFLAM DEV DISP FIXACAO INT',
  'T84.7 INF REAC INF OUT DISP PROT IMP ENX ORT INT',
  'T84.8 OUTR COMPLIC DISP PROT IMPL ENX ORTOP INT',
  'T84.9 COMPLIC NE DISP PROTETICO IMPL ENX ORTOP INT',
  'T85.0 COMPLIC MECANICA SHUNT VENTRIC INTRACRANIANO',
  'T85.1 COMPLIC MEC ESTIM ELETR IMPLANT SIST NERV',
  'T85.2 COMPLIC MECANICA DE LENTE INTRAOCULAR',
  'T85.3 COMPLIC MEC OUTR DISP PROT IMPL ENX OCULARES',
  'T85.4 COMPLIC MECANICA PROTESE IMPLANTE MAMARIOS',
  'T85.5 COMPLIC MEC DISP PROT IMPL ENX GASTROINTEST',
  'T85.6 COMPLIC MEC OUT DISP PROT IMPL ENX INT ESPEC',
  'T85.7 INF REAC INF DEV OUTR DISP PROT IMPL ENX INT',
  'T85.8 OUTR COMPLIC DISP PROT IMPL ENX INT NCOP',
  'T85.9 COMPLIC NE OUTR DISP PROT IMPL ENX INTERNOS',
  'T86.0 REJEICAO A TRANSPLANTE DE MEDULA OSSEA',
  'T86.1 FALENCIA OU REJEICAO DE TRANSPLANTE DE RIM',
  'T86.2 FALENCIA OU REJEICAO DE TRANSPLANTE CORACAO',
  'T86.3 FALENCIA REJEIC TRANSPL DUPLO CORACAO PULMAO',
  'T86.4 FALENCIA OU REJEICAO DE TRANSPLANTE FIGADO',
  'T86.8 OUTR INSUF REJEICAO OUTR ORG TEC TRANSPLANT',
  'T86.9 FALENCIA REJEICAO TRANSPLANTE ORGAO TEC NE',
  'T87.0 COMPLIC DE REIMPLANTE DE EXTREMIDADE SUPER',
  'T87.1 COMPLIC DE REIMPLANTE DE EXTREMIDADE INFER',
  'T87.2 COMPLIC DE OUTR PARTES REIMPLANTADAS CORPO',
  'T87.3 NEUROMA DE COTO DA AMPUT',
  'T87.4 INFECC DE COTO DA AMPUT',
  'T87.5 NECROSE DO COTO DA AMPUT',
  'T87.6 OUTR COMPLIC E AS NE DO COTO DE AMPUT',
  'T88.0 INFECC SUBSEQUENTE A IMUNIZACAO',
  'T88.1 OUTR COMPLIC SUBSEQUENTES A IMUNIZACAO NCOP',
  'T88.2 CHOQUE DEV ANESTESIA',
  'T88.3 HIPERTERMIA MALIGNA DEV ANESTESIA',
  'T88.4 ENTUBACAO FALHA OU DIFICIL',
  'T88.5 OUTR COMPLIC DE ANESTESIA',
  'T88.6 CHOQ ANAF DEV EF ADV DROG MED CORR ADM APR',
  'T88.7 EFEITO ADVERSO NE DE DROGA OU MEDICAMENTO',
  'T88.8 OUTR COMPLIC CUIDADOS MED CIRURG ESPEC NCOP',
  'T88.9 COMPLIC NE DE CUIDADOS MEDICOS E CIRURGICOS',
  'T90.0 SEQUELAS DE TRAUM SUPERF DA CABECA',
  'T90.1 SEQUELAS DE FERIM DA CABECA',
  'T90.2 SEQUELAS DE FRAT DE CRANIO E DE OSSOS FACE',
  'T90.3 SEQUELAS DE TRAUM DE NERVOS CRANIANOS',
  'T90.4 SEQUELAS DE TRAUM DO OLHO E DA ORBITA',
  'T90.5 SEQUELAS DE TRAUM INTRACRANIANO',
  'T90.8 SEQUELAS DE OUTR TRAUM ESPEC DA CABECA',
  'T90.9 SEQUELAS DE TRAUM NE DA CABECA',
  'T91.0 SEQUELAS TRAUM SUPERF E FERIM PESCOCO TRONCO',
  'T91.1 SEQUELAS DE FRAT DE COLUNA VERTEBRAL',
  'T91.2 SEQUELAS DE OUTR FRAT DO TORAX E DA PELVE',
  'T91.3 SEQUELAS DE TRAUM DE MEDULA ESPINHAL',
  'T91.4 SEQUELAS DE TRAUM DE ORGAOS INTRATORACICOS',
  'T91.5 SEQUELAS TRAUM ORG INTRA-ABDOMINAIS E PELV',
  'T91.8 SEQUELAS OUTR TRAUM ESPEC PESCOCO E TRONCO',
  'T91.9 SEQUELAS DE TRAUM NE DO PESCOCO E DO TRONCO',
  'T92.0 SEQUELAS DE FERIM DO MEMBRO SUPER',
  'T92.1 SEQUELAS DE FRAT DO BRACO',
  'T92.2 SEQUELAS DE FRAT AO NIVEL DO PUNHO E DA MAO',
  'T92.3 SEQUELAS LUXACAO ENTORSE DISTENSAO MEMBR SUP',
  'T92.4 SEQUELAS DE TRAUM DE NERVO DE MEMBRO SUPER',
  'T92.5 SEQUELAS TRAUM MUSCULO TENDAO MEMBRO SUPER',
  'T92.6 SEQUELAS DE ESMAG E AMPUT TRAUM MEMBRO SUPER',
  'T92.8 SEQUELAS DE OUTR TRAUM ESPEC DO MEMBRO SUPER',
  'T92.9 SEQUELAS DE TRAUM NE DO MEMBRO SUPER',
  'T93.0 SEQUELAS DE FERIM DO MEMBRO INFER',
  'T93.1 SEQUELAS DE FRAT DO FEMUR',
  'T93.2 SEQUELAS DE OUTR FRAT DO MEMBRO INFER',
  'T93.3 SEQUELAS LUXACAO ENTORSE DISTENSAO MEMBR INF',
  'T93.4 SEQUELAS DE TRAUM DE NERVO DO MEMBRO INFER',
  'T93.5 SEQUELAS TRAUM MUSCULO TENDAO MEMBRO INFER',
  'T93.6 SEQUELAS DE ESMAG E AMPUT TRAUM MEMBRO INFER',
  'T93.8 SEQUELAS DE OUTR TRAUM ESPEC DO MEMBRO INFER',
  'T93.9 SEQUELAS DE TRAUM NE DO MEMBRO INFER',
  'T94.0 SEQUELAS DE TRAUM ENVOLV REGIOES MULT CORPO',
  'T94.1 SEQUELAS DE TRAUM NE P/REGIOES DO CORPO',
  'T95.0 SEQUELAS QUEIM CORROSAO GELAD CABECA PESCOCO',
  'T95.1 SEQUELAS DE QUEIM CORROSAO E GELADURA TRONCO',
  'T95.2 SEQUELAS QUEIM CORROSAO GELADURA MEMBRO SUP',
  'T95.3 SEQUELAS QUEIM CORROSAO GELADURA MEMBRO INF',
  'T95.4 SEQ QUEIM CORROS CLASS EXT AREA CORP ENVOLV',
  'T95.8 SEQUELAS OUTR QUEIM CORROSOES GELAD ESPEC',
  'T95.9 SEQUELAS DE QUEIM CORROSAO GELADURA LOCAL NE',
  'T96   SEQUELAS INTOX DROG MEDIC SUBST BIOLOG',
  'T97   SEQUELAS EFEIT TOX SUBST ORIG PRED NAO-MEDIC',
  'T98.0 SEQUELAS EFEIT PENETR CORPO ESTRANH ORIF NAT',
  'T98.1 SEQUELAS OUTR EFEITOS CAUSAS EXTERNAS E NE',
  'T98.2 SEQUELAS ALGUMAS COMPLIC PRECOCES DE TRAUM',
  'T98.3 SEQUELAS DE COMPLIC CUIDADOS MED CIRURG NCOP',
  'U99   CID 10A REVISAO NAO DISPONIVEL',
  'V01.0 ACID N-TRANS',
  'V01.1 ACID TRANS',
  'V01.9 ACID NE SE TRANS OU NAO-TRANS',
  'V02.0 ACID N-TRANS',
  'V02.1 ACID TRANS',
  'V02.9 ACID NE SE TRANS OU NAO-TRANS',
  'V03.0 ACID N-TRANS',
  'V03.1 ACID TRANS',
  'V03.9 ACID NE SE TRANS OU NAO-TRANS',
  'V04.0 ACID N-TRANS',
  'V04.1 ACID TRANS',
  'V04.9 ACID NE SE TRANS OU NAO-TRANS',
  'V05.0 ACID N-TRANS',
  'V05.1 ACID TRANS',
  'V05.9 ACID NE SE TRANS OU NAO-TRANS',
  'V06.0 ACID N-TRANS',
  'V06.1 ACID TRANS',
  'V06.9 ACID NE SE TRANS OU NAO-TRANS',
  'V09.0 ACID N-TRANS ENVOLV OUTR VEIC MOTOR E NE',
  'V09.1 ACID N-TRANS NE',
  'V09.2 ACID TRANS ENVOLV OUTR VEIC E NE A MOTOR',
  'V09.3 ACID TRANS NE',
  'V09.9 ACID TRANSP NE',
  'V10.0 CONDUTOR EM ACID N-TRANS',
  'V10.1 PASSAGEIRO EM ACID N-TRANS',
  'V10.2 CICLISTA NE EM ACID N-TRANS',
  'V10.3 PESSOA TRAUM AO SUBIR OU DESCER DO VEIC',
  'V10.4 CONDUTOR EM ACID TRANS',
  'V10.5 PASSAGEIRO EM ACID TRANS',
  'V10.9 CICLISTA NE EM ACID TRANS',
  'V11.0 CONDUTOR EM ACID N-TRANS',
  'V11.1 PASSAGEIRO EM ACID N-TRANS',
  'V11.2 CICLISTA NE EM ACID N-TRANS',
  'V11.3 PESSOA TRAUM AO SUBIR OU DESCER DO VEIC',
  'V11.4 CONDUTOR EM ACID TRANS',
  'V11.5 PASSAGEIRO EM ACID TRANS',
  'V11.9 CICLISTA NE EM ACID TRANS',
  'V12.0 CONDUTOR EM ACID N-TRANS',
  'V12.1 PASSAGEIRO EM ACID N-TRANS',
  'V12.2 CICLISTA NE EM ACID N-TRANS',
  'V12.3 PESSOA TRAUM AO SUBIR OU DESCER DO VEIC',
  'V12.4 CONDUTOR EM ACID TRANS',
  'V12.5 PASSAGEIRO EM ACID TRANS',
  'V12.9 CICLISTA NE EM ACID TRANS',
  'V13.0 CONDUTOR EM ACID N-TRANS',
  'V13.1 PASSAGEIRO EM ACID N-TRANS',
  'V13.2 CICLISTA NE EM ACID N-TRANS',
  'V13.3 PESSOA TRAUM AO SUBIR OU DESCER DO VEIC',
  'V13.4 CONDUTOR EM ACID TRANS',
  'V13.5 PASSAGEIRO EM ACID TRANS',
  'V13.9 CICLISTA NE EM ACID TRANS',
  'V14.0 CONDUTOR EM ACID N-TRANS',
  'V14.1 PASSAGEIRO EM ACID N-TRANS',
  'V14.2 CICLISTA NE EM ACID N-TRANS',
  'V14.3 PESSOA TRAUM AO SUBIR OU DESCER DO VEIC',
  'V14.4 CONDUTOR EM ACID TRANS',
  'V14.5 PASSAGEIRO EM ACID TRANS',
  'V14.9 CICLISTA NE EM ACID TRANS',
  'V15.0 CONDUTOR EM ACID N-TRANS',
  'V15.1 PASSAGEIRO EM ACID N-TRANS',
  'V15.2 CICLISTA NE EM ACID N-TRANS',
  'V15.3 PESSOA TRAUM AO SUBIR OU DESCER DO VEIC',
  'V15.4 CONDUTOR EM ACID TRANS',
  'V15.5 PASSAGEIRO EM ACID TRANS',
  'V15.9 CICLISTA NE EM ACID TRANS',
  'V16.0 CONDUTOR EM ACID N-TRANS',
  'V16.1 PASSAGEIRO EM ACID N-TRANS',
  'V16.2 CICLISTA NE EM ACID N-TRANS',
  'V16.3 PESSOA TRAUM AO SUBIR OU DESCER DO VEIC',
  'V16.4 CONDUTOR EM ACID TRANS',
  'V16.5 PASSAGEIRO EM ACID TRANS',
  'V16.9 CICLISTA NE EM ACID TRANS',
  'V17.0 CONDUTOR EM ACID N-TRANS',
  'V17.1 PASSAGEIRO EM ACID N-TRANS',
  'V17.2 CICLISTA NE EM ACID N-TRANS',
  'V17.3 PESSOA TRAUM AO SUBIR OU DESCER DO VEIC',
  'V17.4 CONDUTOR EM ACID TRANS',
  'V17.5 PASSAGEIRO EM ACID TRANS',
  'V17.9 CICLISTA NE EM ACID TRANS',
  'V18.0 CONDUTOR EM ACID N-TRANS',
  'V18.1 PASSAGEIRO EM ACID N-TRANS',
  'V18.2 CICLISTA NE EM ACID N-TRANS',
  'V18.3 PESSOA TRAUM AO SUBIR OU DESCER DO VEIC',
  'V18.4 CONDUTOR EM ACID TRANS',
  'V18.5 PASSAGEIRO EM ACID TRANS',
  'V18.9 CICLISTA NE EM ACID TRANS',
  'V19.0 CONDUTOR COLIS OUTR VEIC NE MOT ACID N-TRANS',
  'V19.1 PASSAG COLIS OUTR VEIC NE MOT ACID N-TRANS',
  'V19.2 CICL NE COLIS OUTR VEIC NE MOT ACID N-TRANS',
  'V19.3 CICLISTA ACID NE E N-TRANS',
  'V19.4 CONDUTOR COLIS OUTR VEIC NE MOT ACID TRANS',
  'V19.5 PASSAGEIRO COLIS OUTR VEIC NE MOT ACID TRANS',
  'V19.6 CICL NE COLIS OUTR VEIC NE MOT ACID TRANS',
  'V19.8 CICLISTA OUTR ACID TRANSP ESPEC',
  'V19.9 CICLISTA ACID TRANS NE',
  'V20.0 CONDUTOR ACID N-TRANS',
  'V20.1 PASSAGEIRO ACID N-TRANS',
  'V20.2 MOTOCICLISTA NE TRAUM ACID N-TRANS',
  'V20.3 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V20.4 CONDUTOR ACID TRANS',
  'V20.5 PASSAGEIRO ACID TRANS',
  'V20.9 MOTOCICLISTA NE ACID TRANS',
  'V21.0 CONDUTOR ACID N-TRANS',
  'V21.1 PASSAGEIRO ACID N-TRANS',
  'V21.2 MOTOCICLISTA NE TRAUM ACID N-TRANS',
  'V21.3 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V21.4 CONDUTOR ACID TRANS',
  'V21.5 PASSAGEIRO ACID TRANS',
  'V21.9 MOTOCICLISTA NE ACID TRANS',
  'V22.0 CONDUTOR ACID N-TRANS',
  'V22.1 PASSAGEIRO ACID N-TRANS',
  'V22.2 MOTOCICLISTA NE TRAUM ACID N-TRANS',
  'V22.3 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V22.4 CONDUTOR ACID TRANS',
  'V22.5 PASSAGEIRO ACID TRANS',
  'V22.9 MOTOCICLISTA NE ACID TRANS',
  'V23.0 CONDUTOR ACID N-TRANS',
  'V23.1 PASSAGEIRO ACID N-TRANS',
  'V23.2 MOTOCICLISTA NE TRAUM ACID N-TRANS',
  'V23.3 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V23.4 CONDUTOR ACID TRANS',
  'V23.5 PASSAGEIRO ACID TRANS',
  'V23.9 MOTOCICLISTA NE ACID TRANS',
  'V24.0 CONDUTOR ACID N-TRANS',
  'V24.1 PASSAGEIRO ACID N-TRANS',
  'V24.2 MOTOCICLISTA NE TRAUM ACID N-TRANS',
  'V24.3 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V24.4 CONDUTOR ACID TRANS',
  'V24.5 PASSAGEIRO ACID TRANS',
  'V24.9 MOTOCICLISTA NE ACID TRANS',
  'V25.0 CONDUTOR ACID N-TRANS',
  'V25.1 PASSAGEIRO ACID N-TRANS',
  'V25.2 MOTOCICLISTA NE TRAUM ACID N-TRANS',
  'V25.3 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V25.4 CONDUTOR ACID TRANS',
  'V25.5 PASSAGEIRO ACID TRANS',
  'V25.9 MOTOCICLISTA NE ACID TRANS',
  'V26.0 CONDUTOR ACID N-TRANS',
  'V26.1 PASSAGEIRO ACID N-TRANS',
  'V26.2 MOTOCICLISTA NE TRAUM ACID N-TRANS',
  'V26.3 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V26.4 CONDUTOR ACID TRANS',
  'V26.5 PASSAGEIRO ACID TRANS',
  'V26.9 MOTOCICLISTA NE ACID TRANS',
  'V27.0 CONDUTOR ACID N-TRANS',
  'V27.1 PASSAGEIRO ACID N-TRANS',
  'V27.2 MOTOCICLISTA NE TRAUM ACID N-TRANS',
  'V27.3 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V27.4 CONDUTOR ACID TRANS',
  'V27.5 PASSAGEIRO ACID TRANS',
  'V27.9 MOTOCICLISTA NE ACID TRANS',
  'V28.0 CONDUTOR ACID N-TRANS',
  'V28.1 PASSAGEIRO ACID N-TRANS',
  'V28.2 MOTOCICLISTA NE TRAUM ACID N-TRANS',
  'V28.3 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V28.4 CONDUTOR ACID TRANS',
  'V28.5 PASSAGEIRO ACID TRANS',
  'V28.9 MOTOCICLISTA NE ACID TRANS',
  'V29.0 CONDUTOR COLIS OUTR VEIC NE MOT ACID N-TRANS',
  'V29.1 PASSAG COLIS OUTR VEIC NE MOT ACID N-TRANS',
  'V29.2 MOTOCIC NE COL OUT VEIC NE MOT ACID N-TRANS',
  'V29.3 MOTOCICLISTA ACID NE N-TRANS',
  'V29.4 CONDUTOR COLIS OUTR VEIC NE MOT ACID TRANS',
  'V29.5 PASSAGEIRO COLIS OUTR VEIC NE MOT ACID TRANS',
  'V29.6 MOTOCICL NE COLIS OUT VEIC NE MOT ACID TRANS',
  'V29.8 MOTOCICLISTA OUTR ACID TRANSP ESPEC',
  'V29.9 MOTOCICLISTA ACID TRANS NE',
  'V30.0 CONDUTOR ACID N-TRANS',
  'V30.1 PASSAGEIRO ACID N-TRANS',
  'V30.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V30.3 OCUPANTE NE ACID N-TRANS',
  'V30.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V30.5 CONDUTOR ACID TRANS',
  'V30.6 PASSAGEIRO ACID TRANS',
  'V30.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V30.9 OCUP NE ACID TRANS',
  'V31.0 CONDUTOR ACID N-TRANS',
  'V31.1 PASSAGEIRO ACID N-TRANS',
  'V31.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V31.3 OCUPANTE NE ACID N-TRANS',
  'V31.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V31.5 CONDUTOR ACID TRANS',
  'V31.6 PASSAGEIRO ACID TRANS',
  'V31.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V31.9 OCUP NE ACID TRANS',
  'V32.0 CONDUTOR ACID N-TRANS',
  'V32.1 PASSAGEIRO ACID N-TRANS',
  'V32.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V32.3 OCUPANTE NE ACID N-TRANS',
  'V32.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V32.5 CONDUTOR ACID TRANS',
  'V32.6 PASSAGEIRO ACID TRANS',
  'V32.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V32.9 OCUP NE ACID TRANS',
  'V33.0 CONDUTOR ACID N-TRANS',
  'V33.1 PASSAGEIRO ACID N-TRANS',
  'V33.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V33.3 OCUPANTE NE ACID N-TRANS',
  'V33.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V33.5 CONDUTOR ACID TRANS',
  'V33.6 PASSAGEIRO ACID TRANS',
  'V33.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V33.9 OCUP NE ACID TRANS',
  'V34.0 CONDUTOR ACID N-TRANS',
  'V34.1 PASSAGEIRO ACID N-TRANS',
  'V34.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V34.3 OCUPANTE NE ACID N-TRANS',
  'V34.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V34.5 CONDUTOR ACID TRANS',
  'V34.6 PASSAGEIRO ACID TRANS',
  'V34.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V34.9 OCUP NE ACID TRANS',
  'V35.0 CONDUTOR ACID N-TRANS',
  'V35.1 PASSAGEIRO ACID N-TRANS',
  'V35.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V35.3 OCUPANTE NE ACID N-TRANS',
  'V35.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V35.5 CONDUTOR ACID TRANS',
  'V35.6 PASSAGEIRO ACID TRANS',
  'V35.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V35.9 OCUP NE ACID TRANS',
  'V36.0 CONDUTOR ACID N-TRANS',
  'V36.1 PASSAGEIRO ACID N-TRANS',
  'V36.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V36.3 OCUPANTE NE ACID N-TRANS',
  'V36.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V36.5 CONDUTOR ACID TRANS',
  'V36.6 PASSAGEIRO ACID TRANS',
  'V36.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V36.9 OCUP NE ACID TRANS',
  'V37.0 CONDUTOR ACID N-TRANS',
  'V37.1 PASSAGEIRO ACID N-TRANS',
  'V37.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V37.3 OCUPANTE NE ACID N-TRANS',
  'V37.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V37.5 CONDUTOR ACID TRANS',
  'V37.6 PASSAGEIRO ACID TRANS',
  'V37.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V37.9 OCUP NE ACID TRANS',
  'V38.0 CONDUTOR ACID N-TRANS',
  'V38.1 PASSAGEIRO ACID N-TRANS',
  'V38.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V38.3 OCUPANTE NE ACID N-TRANS',
  'V38.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V38.5 CONDUTOR ACID TRANS',
  'V38.6 PASSAGEIRO ACID TRANS',
  'V38.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V38.9 OCUP NE ACID TRANS',
  'V39.0 CONDUTOR COLIS OUTR VEIC NE MOT ACID N-TRANS',
  'V39.1 PASSAGEIRO COLIS OUTR VEIC NE MOT AC N-TRANS',
  'V39.2 OCUPANTE NE COLIS OUT VEIC NE MOT AC N-TRANS',
  'V39.3 OCUPANTE ACID NE E N-TRANS',
  'V39.4 CONDUTOR COLIS OUTR VEIC NE MOT ACID TRANS',
  'V39.5 PASSAGEIRO COLIS OUTR VEIC NE MOT ACID TRANS',
  'V39.6 OCUPANTE NE COLIS OUT VEIC NE MOT ACID TRANS',
  'V39.8 OCUPANTE OUTR ACID TRANS ESPEC',
  'V39.9 OCUPANTE ACID TRANS NE',
  'V40.0 CONDUTOR ACID N-TRANS',
  'V40.1 PASSAGEIRO ACID N-TRANS',
  'V40.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V40.3 OCUPANTE NE ACID N-TRANS',
  'V40.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V40.5 CONDUTOR ACID TRANS',
  'V40.6 PASSAGEIRO ACID TRANS',
  'V40.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V40.9 OCUPANTE NE ACID TRANS',
  'V41.0 CONDUTOR ACID N-TRANS',
  'V41.1 PASSAGEIRO ACID N-TRANS',
  'V41.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V41.3 OCUPANTE NE ACID N-TRANS',
  'V41.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V41.5 CONDUTOR ACID TRANS',
  'V41.6 PASSAGEIRO ACID TRANS',
  'V41.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V41.9 OCUPANTE NE ACID TRANS',
  'V42.0 CONDUTOR ACID N-TRANS',
  'V42.1 PASSAGEIRO ACID N-TRANS',
  'V42.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V42.3 OCUPANTE NE ACID N-TRANS',
  'V42.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V42.5 CONDUTOR ACID TRANS',
  'V42.6 PASSAGEIRO ACID TRANS',
  'V42.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V42.9 OCUPANTE NE ACID TRANS',
  'V43.0 CONDUTOR ACID N-TRANS',
  'V43.1 PASSAGEIRO ACID N-TRANS',
  'V43.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V43.3 OCUPANTE NE ACID N-TRANS',
  'V43.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V43.5 CONDUTOR ACID TRANS',
  'V43.6 PASSAGEIRO ACID TRANS',
  'V43.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V43.9 OCUPANTE NE ACID TRANS',
  'V44.0 CONDUTOR ACID N-TRANS',
  'V44.1 PASSAGEIRO ACID N-TRANS',
  'V44.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V44.3 OCUPANTE NE ACID N-TRANS',
  'V44.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V44.5 CONDUTOR ACID TRANS',
  'V44.6 PASSAGEIRO ACID TRANS',
  'V44.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V44.9 OCUPANTE NE ACID TRANS',
  'V45.0 CONDUTOR ACID N-TRANS',
  'V45.1 PASSAGEIRO ACID N-TRANS',
  'V45.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V45.3 OCUPANTE NE ACID N-TRANS',
  'V45.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V45.5 CONDUTOR ACID TRANS',
  'V45.6 PASSAGEIRO ACID TRANS',
  'V45.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V45.9 OCUPANTE NE ACID TRANS',
  'V46.0 CONDUTOR ACID N-TRANS',
  'V46.1 PASSAGEIRO ACID N-TRANS',
  'V46.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V46.3 OCUPANTE NE ACID N-TRANS',
  'V46.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V46.5 CONDUTOR ACID TRANS',
  'V46.6 PASSAGEIRO ACID TRANS',
  'V46.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V46.9 OCUPANTE NE ACID TRANS',
  'V47.0 CONDUTOR ACID N-TRANS',
  'V47.1 PASSAGEIRO ACID N-TRANS',
  'V47.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V47.3 OCUPANTE NE ACID N-TRANS',
  'V47.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V47.5 CONDUTOR ACID TRANS',
  'V47.6 PASSAGEIRO ACID TRANS',
  'V47.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V47.9 OCUPANTE NE ACID TRANS',
  'V48.0 CONDUTOR ACID N-TRANS',
  'V48.1 PASSAGEIRO ACID N-TRANS',
  'V48.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V48.3 OCUPANTE NE ACID N-TRANS',
  'V48.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V48.5 CONDUTOR ACID TRANS',
  'V48.6 PASSAGEIRO ACID TRANS',
  'V48.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V48.9 OCUPANTE NE ACID TRANS',
  'V49.0 CONDUTOR COLIS OUTR VEIC NE MOT ACID N-TRANS',
  'V49.1 PASSAGEIRO COLIS OUTR VEIC NE MOT AC N-TRANS',
  'V49.2 OCUPANTE NE COLIS OUT VEIC NE MOT AC N-TRANS',
  'V49.3 OCUPANTE ACID NE E N-TRANS',
  'V49.4 CONDUTOR COLIS OUTR VEIC NE MOT ACID TRANS',
  'V49.5 PASSAGEIRO COLIS OUTR VEIC NE MOT ACID TRANS',
  'V49.6 OCUPANTE NE COLIS OUT VEIC NE MOT ACID TRANS',
  'V49.8 OCUPANTE OUTR ACID TRANS ESPEC',
  'V49.9 OCUPANTE ACID TRANS NE',
  'V50.0 CONDUTOR ACID N-TRANS',
  'V50.1 PASSAGEIRO ACID N-TRANS',
  'V50.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V50.3 OCUPANTE NE ACID N-TRANS',
  'V50.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V50.5 CONDUTOR ACID TRANS',
  'V50.6 PASSAGEIRO ACID TRANS',
  'V50.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V50.9 OCUPANTE NE ACID TRANS',
  'V51.0 CONDUTOR ACID N-TRANS',
  'V51.1 PASSAGEIRO ACID N-TRANS',
  'V51.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V51.3 OCUPANTE NE ACID N-TRANS',
  'V51.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V51.5 CONDUTOR ACID TRANS',
  'V51.6 PASSAGEIRO ACID TRANS',
  'V51.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V51.9 OCUPANTE NE ACID TRANS',
  'V52.0 CONDUTOR ACID N-TRANS',
  'V52.1 PASSAGEIRO ACID N-TRANS',
  'V52.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V52.3 OCUPANTE NE ACID N-TRANS',
  'V52.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V52.5 CONDUTOR ACID TRANS',
  'V52.6 PASSAGEIRO ACID TRANS',
  'V52.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V52.9 OCUPANTE NE ACID TRANS',
  'V53.0 CONDUTOR ACID N-TRANS',
  'V53.1 PASSAGEIRO ACID N-TRANS',
  'V53.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V53.3 OCUPANTE NE ACID N-TRANS',
  'V53.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V53.5 CONDUTOR ACID TRANS',
  'V53.6 PASSAGEIRO ACID TRANS',
  'V53.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V53.9 OCUPANTE NE ACID TRANS',
  'V54.0 CONDUTOR ACID N-TRANS',
  'V54.1 PASSAGEIRO ACID N-TRANS',
  'V54.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V54.3 OCUPANTE NE ACID N-TRANS',
  'V54.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V54.5 CONDUTOR ACID TRANS',
  'V54.6 PASSAGEIRO ACID TRANS',
  'V54.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V54.9 OCUPANTE NE ACID TRANS',
  'V55.0 CONDUTOR ACID N-TRANS',
  'V55.1 PASSAGEIRO ACID N-TRANS',
  'V55.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V55.3 OCUPANTE NE ACID N-TRANS',
  'V55.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V55.5 CONDUTOR ACID TRANS',
  'V55.6 PASSAGEIRO ACID TRANS',
  'V55.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V55.9 OCUPANTE NE ACID TRANS',
  'V56.0 CONDUTOR ACID N-TRANS',
  'V56.1 PASSAGEIRO ACID N-TRANS',
  'V56.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V56.3 OCUPANTE NE ACID N-TRANS',
  'V56.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V56.5 CONDUTOR ACID TRANS',
  'V56.6 PASSAGEIRO ACID TRANS',
  'V56.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V56.9 OCUPANTE NE ACID TRANS',
  'V57.0 CONDUTOR ACID N-TRANS',
  'V57.1 PASSAGEIRO ACID N-TRANS',
  'V57.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V57.3 OCUPANTE NE ACID N-TRANS',
  'V57.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V57.5 CONDUTOR ACID TRANS',
  'V57.6 PASSAGEIRO ACID TRANS',
  'V57.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V57.9 OCUPANTE NE ACID TRANS',
  'V58.0 CONDUTOR ACID N-TRANS',
  'V58.1 PASSAGEIRO ACID N-TRANS',
  'V58.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V58.3 OCUPANTE NE ACID N-TRANS',
  'V58.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V58.5 CONDUTOR ACID TRANS',
  'V58.6 PASSAGEIRO ACID TRANS',
  'V58.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V58.9 OCUPANTE NE ACID TRANS',
  'V59.0 CONDUTOR COLIS OUTR VEIC NE MOT ACID N-TRANS',
  'V59.1 PASSAGEIRO COLIS OUTR VEIC NE MOT AC N-TRANS',
  'V59.2 OCUPANTE NE COLIS OUT VEIC NE MOT AC N-TRANS',
  'V59.3 OCUPANTE ACID NE E N-TRANS',
  'V59.4 CONDUTOR COLIS OUTR VEIC NE MOT ACID TRANS',
  'V59.5 PASSAGEIRO COLIS OUTR VEIC NE MOT ACID TRANS',
  'V59.6 OCUPANTE NE COLIS OUT VEIC NE MOT ACID TRANS',
  'V59.8 OCUPANTE OUTR ACID TRANS ESPEC',
  'V59.9 OCUPANTE ACID TRANS NE',
  'V60.0 CONDUTOR ACID N-TRANS',
  'V60.1 PASSAGEIRO ACID N-TRANS',
  'V60.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V60.3 OCUPANTE NE ACID N-TRANS',
  'V60.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V60.5 CONDUTOR ACID TRANS',
  'V60.6 PASSAGEIRO ACID TRANS',
  'V60.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V60.9 OCUPANTE NE ACID TRANS',
  'V61.0 CONDUTOR ACID N-TRANS',
  'V61.1 PASSAGEIRO ACID N-TRANS',
  'V61.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V61.3 OCUPANTE NE ACID N-TRANS',
  'V61.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V61.5 CONDUTOR ACID TRANS',
  'V61.6 PASSAGEIRO ACID TRANS',
  'V61.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V61.9 OCUPANTE NE ACID TRANS',
  'V62.0 CONDUTOR ACID N-TRANS',
  'V62.1 PASSAGEIRO ACID N-TRANS',
  'V62.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V62.3 OCUPANTE NE ACID N-TRANS',
  'V62.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V62.5 CONDUTOR ACID TRANS',
  'V62.6 PASSAGEIRO ACID TRANS',
  'V62.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V62.9 OCUPANTE NE ACID TRANS',
  'V63.0 CONDUTOR ACID N-TRANS',
  'V63.1 PASSAGEIRO ACID N-TRANS',
  'V63.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V63.3 OCUPANTE NE ACID N-TRANS',
  'V63.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V63.5 CONDUTOR ACID TRANS',
  'V63.6 PASSAGEIRO ACID TRANS',
  'V63.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V63.9 OCUPANTE NE ACID TRANS',
  'V64.0 CONDUTOR ACID N-TRANS',
  'V64.1 PASSAGEIRO ACID N-TRANS',
  'V64.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V64.3 OCUPANTE NE ACID N-TRANS',
  'V64.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V64.5 CONDUTOR ACID TRANS',
  'V64.6 PASSAGEIRO ACID TRANS',
  'V64.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V64.9 OCUPANTE NE ACID TRANS',
  'V65.0 CONDUTOR ACID N-TRANS',
  'V65.1 PASSAGEIRO ACID N-TRANS',
  'V65.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V65.3 OCUPANTE NE ACID N-TRANS',
  'V65.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V65.5 CONDUTOR ACID TRANS',
  'V65.6 PASSAGEIRO ACID TRANS',
  'V65.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V65.9 OCUPANTE NE ACID TRANS',
  'V66.0 CONDUTOR ACID N-TRANS',
  'V66.1 PASSAGEIRO ACID N-TRANS',
  'V66.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V66.3 OCUPANTE NE ACID N-TRANS',
  'V66.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V66.5 CONDUTOR ACID TRANS',
  'V66.6 PASSAGEIRO ACID TRANS',
  'V66.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V66.9 OCUPANTE NE ACID TRANS',
  'V67.0 CONDUTOR ACID N-TRANS',
  'V67.1 PASSAGEIRO ACID N-TRANS',
  'V67.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V67.3 OCUPANTE NE ACID N-TRANS',
  'V67.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V67.5 CONDUTOR ACID TRANS',
  'V67.6 PASSAGEIRO ACID TRANS',
  'V67.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V67.9 OCUPANTE NE ACID TRANS',
  'V68.0 CONDUTOR ACID N-TRANS',
  'V68.1 PASSAGEIRO ACID N-TRANS',
  'V68.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V68.3 OCUPANTE NE ACID N-TRANS',
  'V68.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V68.5 CONDUTOR ACID TRANS',
  'V68.6 PASSAGEIRO ACID TRANS',
  'V68.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V68.9 OCUPANTE NE ACID TRANS',
  'V69.0 CONDUTOR COLIS OUTR VEIC NE MOT ACID N-TRANS',
  'V69.1 PASSAGEIRO COLIS OUTR VEIC NE MOT AC N-TRANS',
  'V69.2 OCUPANTE NE COLIS OUT VEIC NE MOT AC N-TRANS',
  'V69.3 OCUPANTE ACID NE E N-TRANS',
  'V69.4 CONDUTOR COLIS OUTR VEIC NE MOT ACID TRANS',
  'V69.5 PASSAGEIRO COLIS OUTR VEIC NE MOT ACID TRANS',
  'V69.6 OCUPANTE NE COLIS OUT VEIC NE MOT ACID TRANS',
  'V69.8 OCUPANTE OUTR ACID TRANS ESPEC',
  'V69.9 OCUPANTE ACID TRANS NE',
  'V70.0 CONDUTOR ACID N-TRANS',
  'V70.1 PASSAGEIRO ACID N-TRANS',
  'V70.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V70.3 OCUPANTE NE ACID N-TRANS',
  'V70.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V70.5 CONDUTOR ACID TRANS',
  'V70.6 PASSAGEIRO ACID TRANS',
  'V70.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V70.9 OCUPANTE NE ACID TRANS',
  'V71.0 CONDUTOR ACID N-TRANS',
  'V71.1 PASSAGEIRO ACID N-TRANS',
  'V71.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V71.3 OCUPANTE NE ACID N-TRANS',
  'V71.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V71.5 CONDUTOR ACID TRANS',
  'V71.6 PASSAGEIRO ACID TRANS',
  'V71.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V71.9 OCUPANTE NE ACID TRANS',
  'V72.0 CONDUTOR ACID N-TRANS',
  'V72.1 PASSAGEIRO ACID N-TRANS',
  'V72.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V72.3 OCUPANTE NE ACID N-TRANS',
  'V72.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V72.5 CONDUTOR ACID TRANS',
  'V72.6 PASSAGEIRO ACID TRANS',
  'V72.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V72.9 OCUPANTE NE ACID TRANS',
  'V73.0 CONDUTOR ACID N-TRANS',
  'V73.1 PASSAGEIRO ACID N-TRANS',
  'V73.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V73.3 OCUPANTE NE ACID N-TRANS',
  'V73.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V73.5 CONDUTOR ACID TRANS',
  'V73.6 PASSAGEIRO ACID TRANS',
  'V73.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V73.9 OCUPANTE NE ACID TRANS',
  'V74.0 CONDUTOR ACID N-TRANS',
  'V74.1 PASSAGEIRO ACID N-TRANS',
  'V74.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V74.3 OCUPANTE NE ACID N-TRANS',
  'V74.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V74.5 CONDUTOR ACID TRANS',
  'V74.6 PASSAGEIRO ACID TRANS',
  'V74.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V74.9 OCUPANTE NE ACID TRANS',
  'V75.0 CONDUTOR ACID N-TRANS',
  'V75.1 PASSAGEIRO ACID N-TRANS',
  'V75.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V75.3 OCUPANTE NE ACID N-TRANS',
  'V75.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V75.5 CONDUTOR ACID TRANS',
  'V75.6 PASSAGEIRO ACID TRANS',
  'V75.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V75.9 OCUPANTE NE ACID TRANS',
  'V76.0 CONDUTOR ACID N-TRANS',
  'V76.1 PASSAGEIRO ACID N-TRANS',
  'V76.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V76.3 OCUPANTE NE ACID N-TRANS',
  'V76.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V76.5 CONDUTOR ACID TRANS',
  'V76.6 PASSAGEIRO ACID TRANS',
  'V76.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V76.9 OCUPANTE NE ACID TRANS',
  'V77.0 CONDUTOR ACID N-TRANS',
  'V77.1 PASSAGEIRO ACID N-TRANS',
  'V77.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V77.3 OCUPANTE NE ACID N-TRANS',
  'V77.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V77.5 CONDUTOR ACID TRANS',
  'V77.6 PASSAGEIRO ACID TRANS',
  'V77.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V77.9 OCUPANTE NE ACID TRANS',
  'V78.0 CONDUTOR ACID N-TRANS',
  'V78.1 PASSAGEIRO ACID N-TRANS',
  'V78.2 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V78.3 OCUPANTE NE ACID N-TRANS',
  'V78.4 PESSOA AO SUBIR OU DESCER DO VEIC',
  'V78.5 CONDUTOR ACID TRANS',
  'V78.6 PASSAGEIRO ACID TRANS',
  'V78.7 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V78.9 OCUPANTE NE ACID TRANS',
  'V79.0 CONDUTOR COLIS OUTR VEIC NE MOT ACID N-TRANS',
  'V79.1 PASSAGEIRO COLIS OUTR VEIC NE MOT AC N-TRANS',
  'V79.2 OCUPANTE NE COLIS OUT VEIC NE MOT AC N-TRANS',
  'V79.3 OCUPANTE ACID NE E N-TRANS',
  'V79.4 CONDUTOR COLIS OUTR VEIC NE MOT ACID TRANS',
  'V79.5 PASSAGEIRO COLIS OUTR VEIC NE MOT ACID TRANS',
  'V79.6 OCUPANTE NE COLIS OUT VEIC NE MOT ACID TRANS',
  'V79.8 OCUPANTE OUTR ACID TRANS ESPEC',
  'V79.9 OCUPANTE ACID TRANS NE',
  'V80.0 ACID TRANS S/COLIS',
  'V80.1 COLIS PEDESTRE OU ANIMAL',
  'V80.2 COLIS OUTR VEIC A PEDAL',
  'V80.3 COLIS VEIC MOT DE 2 OU 3 RODAS',
  'V80.4 COLIS AUTOM CAMINHON VEIC TRANS PESAD ONIBUS',
  'V80.5 COLIS VEIC MOT ESPEC',
  'V80.6 COLIS TREM VEIC FERROVIARIO',
  'V80.7 COLIS OUTR VEIC N-MOT',
  'V80.8 COLIS OBJETO FIXO OU PARADO',
  'V80.9 OUTR ACID TRANSP E NE',
  'V81.0 COLIS VEIC MOT ACID N-TRANS',
  'V81.1 COLIS VEIC MOT ACID TRANS',
  'V81.2 COLIS OU ATINGIDO MATERIAL RODANTE',
  'V81.3 COLIS OUTR OBJETO',
  'V81.4 PESSOA AO SUBIR OU DESCER TREM VEIC FERROV',
  'V81.5 QUEDA NO INTERIOR TREM VEIC FERROVIARIO',
  'V81.6 QUEDA TREM VEIC FERROVIARIO',
  'V81.7 DESCARRILHAMENTO S/COLIS ANTECEDENTE',
  'V81.8 OUTR ACID FERROVIARIO ESPEC',
  'V81.9 ACID FERROVIARIO NE',
  'V82.0 COLIS VEIC MOT ACID N-TRANS',
  'V82.1 COLIS VEIC MOT ACID TRANS',
  'V82.2 COLIS OU ATINGIDO MATERIAL RODANTE',
  'V82.3 COLIS OUTR OBJETO',
  'V82.4 PESSOA AO SUBIR OU DESCER BONDE',
  'V82.5 QUEDA NO INTERIOR BONDE',
  'V82.6 QUEDA BONDE',
  'V82.7 DESCARRILHAMENTO S/COLIS ANTECEDENTE',
  'V82.8 OUTR ACID TRANSP ESPEC',
  'V82.9 ACID TRANS NE',
  'V83.0 CONDUTOR ACID TRANS',
  'V83.1 PASSAGEIRO ACID TRANS',
  'V83.2 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V83.3 OCUPANTE NE ACID TRANS',
  'V83.4 PESSOA AO SUBIR OU DESCER VEIC',
  'V83.5 CONDUTOR ACID N-TRANS',
  'V83.6 PASSAGEIRO ACID N-TRANS',
  'V83.7 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V83.9 OCUPANTE NE ACID N-TRANS',
  'V84.0 CONDUTOR ACID TRANS',
  'V84.1 PASSAGEIRO ACID TRANS',
  'V84.2 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V84.3 OCUPANTE NE ACID TRANS',
  'V84.4 PESSOA AO SUBIR OU DESCER VEIC',
  'V84.5 CONDUTOR ACID N-TRANS',
  'V84.6 PASSAGEIRO ACID N-TRANS',
  'V84.7 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V84.9 OCUPANTE NE ACID N-TRANS',
  'V85.0 CONDUTOR ACID TRANS',
  'V85.1 PASSAGEIRO ACID TRANS',
  'V85.2 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V85.3 OCUPANTE NE ACID TRANS',
  'V85.4 PESSOA AO SUBIR OU DESCER VEIC',
  'V85.5 CONDUTOR ACID N-TRANS',
  'V85.6 PASSAGEIRO ACID N-TRANS',
  'V85.7 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V85.9 OCUPANTE NE ACID N-TRANS',
  'V86.0 CONDUTOR ACID TRANS',
  'V86.1 PASSAGEIRO ACID TRANS',
  'V86.2 PESSOA VIAJANDO EXT VEIC ACID TRANS',
  'V86.3 OCUPANTE NE ACID TRANS',
  'V86.4 PESSOA AO SUBIR OU DESCER VEIC',
  'V86.5 CONDUTOR ACID N-TRANS',
  'V86.6 PASSAGEIRO ACID N-TRANS',
  'V86.7 PESSOA VIAJANDO EXT VEIC ACID N-TRANS',
  'V86.9 OCUPANTE NE ACID N-TRANS',
  'V87.0 COLIS AUTOMOVEL E VEIC A MOTOR 2 3 RODAS',
  'V87.1 COLIS OUTR VEIC MOT E VEIC A MOTOR 2 3 RODAS',
  'V87.2 COLIS ENTRE AUTOMOVEL E CAMINHONETE',
  'V87.3 COLIS ENTRE AUTOMOVEL E ONIBUS',
  'V87.4 COLIS ENTRE AUTOMOVEL E VEIC TRANSP PESADO',
  'V87.5 COLIS ENTRE VEIC TRANSP PESADO E ONIBUS',
  'V87.6 COLIS ENTRE TREM VEIC FERROVIARIO E AUTOMOV',
  'V87.7 COLIS ENTRE OUTR VEIC MOT ESP',
  'V87.8 OUTR ACID TRANSP ESPEC VEIC MOTOR S/COLIS',
  'V87.9 OUTR ACID TRANSP ESPEC ENVOLV VEIC N-MOT',
  'V88.0 COLIS AUTOMOVEL E VEIC A MOTOR 2 3 RODAS',
  'V88.1 COLIS OUTR VEIC MOT E VEIC A MOTOR 2 3 RODAS',
  'V88.2 COLIS ENTRE AUTOMOVEL E CAMINHONETE',
  'V88.3 COLIS ENTRE AUTOMOVEL E ONIBUS',
  'V88.4 COLIS ENTRE AUTOMOVEL E VEIC TRANSP PESADO',
  'V88.5 COLIS ENTRE VEIC TRANSP PESADO E ONIBUS',
  'V88.6 COLIS ENTRE TREM VEIC FERROVIARIO E AUTOMOV',
  'V88.7 COLIS ENTRE OUTR VEIC MOT ESP',
  'V88.8 OUTR ACID TRANSP ESPEC VEIC MOTOR S/COLIS',
  'V88.9 OUTR ACID TRANSP ESPEC ENVOLV VEIC N-MOT',
  'V89.0 ACID VEIC MOT NE N-TRANS',
  'V89.1 ACID VEIC N-MOT NE N-TRANS',
  'V89.2 ACID TRANS VEIC MOT NE',
  'V89.3 ACID TRANS VEIC N-MOT NE',
  'V89.9 ACID VEIC NE',
  'V90.0 NAVIO MERCANTE',
  'V90.1 NAVIO DE PASSAGEIROS',
  'V90.2 BARCO DE PESCA',
  'V90.3 OUTR EMBARCACOES A MOTOR',
  'V90.4 BARCO A VELA',
  'V90.5 CAIAQUE OU CANOA',
  'V90.6 BOTE INFLAVEL',
  'V90.7 ESQUI AQUATICO',
  'V90.8 OUTR EMBARCACOES S/MOTOR',
  'V90.9 EMBARCACAO NE',
  'V91.0 NAVIO MERCANTE',
  'V91.1 NAVIO DE PASSAGEIROS',
  'V91.2 BARCO DE PESCA',
  'V91.3 OUTR EMBARCACOES A MOTOR',
  'V91.4 BARCO A VELA',
  'V91.5 CAIAQUE OU CANOA',
  'V91.6 BOTE INFLAVEL',
  'V91.7 ESQUI AQUATICO',
  'V91.8 OUTR EMBARCACOES S/MOTOR',
  'V91.9 EMBARCACAO NE',
  'V92.0 NAVIO MERCANTE',
  'V92.1 NAVIO DE PASSAGEIROS',
  'V92.2 BARCO DE PESCA',
  'V92.3 OUTR EMBARCACOES A MOTOR',
  'V92.4 BARCO A VELA',
  'V92.5 CAIAQUE OU CANOA',
  'V92.6 BOTE INFLAVEL',
  'V92.7 ESQUI AQUATICO',
  'V92.8 OUTR EMBARCACOES S/MOTOR',
  'V92.9 EMBARCACAO NE',
  'V93.0 NAVIO MERCANTE',
  'V93.1 NAVIO DE PASSAGEIROS',
  'V93.2 BARCO DE PESCA',
  'V93.3 OUTR EMBARCACOES A MOTOR',
  'V93.4 BARCO A VELA',
  'V93.5 CAIAQUE OU CANOA',
  'V93.6 BOTE INFLAVEL',
  'V93.7 ESQUI AQUATICO',
  'V93.8 OUTR EMBARCACOES S/MOTOR',
  'V93.9 EMBARCACAO NE',
  'V94.0 NAVIO MERCANTE',
  'V94.1 NAVIO DE PASSAGEIROS',
  'V94.2 BARCO DE PESCA',
  'V94.3 OUTR EMBARCACOES A MOTOR',
  'V94.4 BARCO A VELA',
  'V94.5 CAIAQUE OU CANOA',
  'V94.6 BOTE INFLAVEL',
  'V94.7 ESQUI AQUATICO',
  'V94.8 OUTR EMBARCACOES S/MOTOR',
  'V94.9 EMBARCACAO NE',
  'V95.0 ACID DE HELICOPTERO CAUSANDO TRAUM A OCUP',
  'V95.1 ACID PLANADOR MOT ULTRALEVE CAUS TRAUM OCUP',
  'V95.2 ACID OUT AERON ASA FIX PART CAUS TRAUM OCUP',
  'V95.3 ACID AERON ASA FIXA COMERC CAUS TRAUM OCUP',
  'V95.4 ACID DE NAVE ESPACIAL CAUSANDO TRAUM A OCUP',
  'V95.8 OUTR ACID DE AERONAVES CAUSANDO TRAUM A OCUP',
  'V95.9 ACID DE AERONAVE NE CAUSANDO TRAUM A OCUP',
  'V96.0 ACID DE BALAO CAUSANDO TRAUM A OCUP',
  'V96.1 ACID DE ASA DELTA CAUSANDO TRAUM A OCUP',
  'V96.2 ACID DE PLANADOR CAUSANDO TRAUM A OCUP',
  'V96.8 OUTR ACID AERONAVES N-MOT CAUS TRAUM A OCUP',
  'V96.9 ACID NE AERONAVE N-MOT CAUS TRAUM A OCUP',
  'V97.0 OCUP AERON TRAUM OUT ACID TRANSP AEREO ESPEC',
  'V97.1 PESSOA TRAUM EMBARCAR DESEMBARCAR AERONAVE',
  'V97.2 PARAQUEDISTA TRAUM ACID TRANSP AEREO',
  'V97.3 PESSOA EM TERRA TRAUM ACID TRANSP AEREO',
  'V97.8 OUTR ACID TRANSP AEREO NCOP',
  'V98   OUTR ACID TRANSP ESPEC',
  'V99   ACID TRANSP NE',
  'W00.0 RESIDENCIA',
  'W00.1 HABITACAO COLETIVA',
  'W00.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W00.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W00.4 RUA E ESTRADA',
  'W00.5 AREAS DE COMERCIO E DE SERVICOS',
  'W00.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W00.7 FAZENDA',
  'W00.8 OUTR LOCAIS ESPEC',
  'W00.9 LOCAL NE',
  'W01.0 RESIDENCIA',
  'W01.1 HABITACAO COLETIVA',
  'W01.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W01.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W01.4 RUA E ESTRADA',
  'W01.5 AREAS DE COMERCIO E DE SERVICOS',
  'W01.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W01.7 FAZENDA',
  'W01.8 OUTR LOCAIS ESPEC',
  'W01.9 LOCAL NE',
  'W02.0 RESIDENCIA',
  'W02.1 HABITACAO COLETIVA',
  'W02.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W02.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W02.4 RUA E ESTRADA',
  'W02.5 AREAS DE COMERCIO E DE SERVICOS',
  'W02.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W02.7 FAZENDA',
  'W02.8 OUTR LOCAIS ESPEC',
  'W02.9 LOCAL NE',
  'W03.0 RESIDENCIA',
  'W03.1 HABITACAO COLETIVA',
  'W03.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W03.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W03.4 RUA E ESTRADA',
  'W03.5 AREAS DE COMERCIO E DE SERVICOS',
  'W03.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W03.7 FAZENDA',
  'W03.8 OUTR LOCAIS ESPEC',
  'W03.9 LOCAL NE',
  'W04.0 RESIDENCIA',
  'W04.1 HABITACAO COLETIVA',
  'W04.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W04.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W04.4 RUA E ESTRADA',
  'W04.5 AREAS DE COMERCIO E DE SERVICOS',
  'W04.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W04.7 FAZENDA',
  'W04.8 OUTR LOCAIS ESPEC',
  'W04.9 LOCAL NE',
  'W05.0 RESIDENCIA',
  'W05.1 HABITACAO COLETIVA',
  'W05.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W05.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W05.4 RUA E ESTRADA',
  'W05.5 AREAS DE COMERCIO E DE SERVICOS',
  'W05.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W05.7 FAZENDA',
  'W05.8 OUTR LOCAIS ESPEC',
  'W05.9 LOCAL NE',
  'W06.0 RESIDENCIA',
  'W06.1 HABITACAO COLETIVA',
  'W06.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W06.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W06.4 RUA E ESTRADA',
  'W06.5 AREAS DE COMERCIO E DE SERVICOS',
  'W06.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W06.7 FAZENDA',
  'W06.8 OUTR LOCAIS ESPEC',
  'W06.9 LOCAL NE',
  'W07.0 RESIDENCIA',
  'W07.1 HABITACAO COLETIVA',
  'W07.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W07.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W07.4 RUA E ESTRADA',
  'W07.5 AREAS DE COMERCIO E DE SERVICOS',
  'W07.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W07.7 FAZENDA',
  'W07.8 OUTR LOCAIS ESPEC',
  'W07.9 LOCAL NE',
  'W08.0 RESIDENCIA',
  'W08.1 HABITACAO COLETIVA',
  'W08.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W08.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W08.4 RUA E ESTRADA',
  'W08.5 AREAS DE COMERCIO E DE SERVICOS',
  'W08.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W08.7 FAZENDA',
  'W08.8 OUTR LOCAIS ESPEC',
  'W08.9 LOCAL NE',
  'W09.0 RESIDENCIA',
  'W09.1 HABITACAO COLETIVA',
  'W09.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W09.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W09.4 RUA E ESTRADA',
  'W09.5 AREAS DE COMERCIO E DE SERVICOS',
  'W09.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W09.7 FAZENDA',
  'W09.8 OUTR LOCAIS ESPEC',
  'W09.9 LOCAL NE',
  'W10.0 RESIDENCIA',
  'W10.1 HABITACAO COLETIVA',
  'W10.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W10.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W10.4 RUA E ESTRADA',
  'W10.5 AREAS DE COMERCIO E DE SERVICOS',
  'W10.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W10.7 FAZENDA',
  'W10.8 OUTR LOCAIS ESPEC',
  'W10.9 LOCAL NE',
  'W11.0 RESIDENCIA',
  'W11.1 HABITACAO COLETIVA',
  'W11.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W11.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W11.4 RUA E ESTRADA',
  'W11.5 AREAS DE COMERCIO E DE SERVICOS',
  'W11.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W11.7 FAZENDA',
  'W11.8 OUTR LOCAIS ESPEC',
  'W11.9 LOCAL NE',
  'W12.0 RESIDENCIA',
  'W12.1 HABITACAO COLETIVA',
  'W12.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W12.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W12.4 RUA E ESTRADA',
  'W12.5 AREAS DE COMERCIO E DE SERVICOS',
  'W12.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W12.7 FAZENDA',
  'W12.8 OUTR LOCAIS ESPEC',
  'W12.9 LOCAL NE',
  'W13.0 RESIDENCIA',
  'W13.1 HABITACAO COLETIVA',
  'W13.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W13.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W13.4 RUA E ESTRADA',
  'W13.5 AREAS DE COMERCIO E DE SERVICOS',
  'W13.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W13.7 FAZENDA',
  'W13.8 OUTR LOCAIS ESPEC',
  'W13.9 LOCAL NE',
  'W14.0 RESIDENCIA',
  'W14.1 HABITACAO COLETIVA',
  'W14.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W14.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W14.4 RUA E ESTRADA',
  'W14.5 AREAS DE COMERCIO E DE SERVICOS',
  'W14.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W14.7 FAZENDA',
  'W14.8 OUTR LOCAIS ESPEC',
  'W14.9 LOCAL NE',
  'W15.0 RESIDENCIA',
  'W15.1 HABITACAO COLETIVA',
  'W15.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W15.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W15.4 RUA E ESTRADA',
  'W15.5 AREAS DE COMERCIO E DE SERVICOS',
  'W15.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W15.7 FAZENDA',
  'W15.8 OUTR LOCAIS ESPEC',
  'W15.9 LOCAL NE',
  'W16.0 RESIDENCIA',
  'W16.1 HABITACAO COLETIVA',
  'W16.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W16.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W16.4 RUA E ESTRADA',
  'W16.5 AREAS DE COMERCIO E DE SERVICOS',
  'W16.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W16.7 FAZENDA',
  'W16.8 OUTR LOCAIS ESPEC',
  'W16.9 LOCAL NE',
  'W17.0 RESIDENCIA',
  'W17.1 HABITACAO COLETIVA',
  'W17.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W17.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W17.4 RUA E ESTRADA',
  'W17.5 AREAS DE COMERCIO E DE SERVICOS',
  'W17.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W17.7 FAZENDA',
  'W17.8 OUTR LOCAIS ESPEC',
  'W17.9 LOCAL NE',
  'W18.0 RESIDENCIA',
  'W18.1 HABITACAO COLETIVA',
  'W18.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W18.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W18.4 RUA E ESTRADA',
  'W18.5 AREAS DE COMERCIO E DE SERVICOS',
  'W18.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W18.7 FAZENDA',
  'W18.8 OUTR LOCAIS ESPEC',
  'W18.9 LOCAL NE',
  'W19.0 RESIDENCIA',
  'W19.1 HABITACAO COLETIVA',
  'W19.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W19.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W19.4 RUA E ESTRADA',
  'W19.5 AREAS DE COMERCIO E DE SERVICOS',
  'W19.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W19.7 FAZENDA',
  'W19.8 OUTR LOCAIS ESPEC',
  'W19.9 LOCAL NE',
  'W20.0 RESIDENCIA',
  'W20.1 HABITACAO COLETIVA',
  'W20.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W20.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W20.4 RUA E ESTRADA',
  'W20.5 AREAS DE COMERCIO E DE SERVICOS',
  'W20.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W20.7 FAZENDA',
  'W20.8 OUTR LOCAIS ESPEC',
  'W20.9 LOCAL NE',
  'W21.0 RESIDENCIA',
  'W21.1 HABITACAO COLETIVA',
  'W21.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W21.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W21.4 RUA E ESTRADA',
  'W21.5 AREAS DE COMERCIO E DE SERVICOS',
  'W21.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W21.7 FAZENDA',
  'W21.8 OUTR LOCAIS ESPEC',
  'W21.9 LOCAL NE',
  'W22.0 RESIDENCIA',
  'W22.1 HABITACAO COLETIVA',
  'W22.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W22.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W22.4 RUA E ESTRADA',
  'W22.5 AREAS DE COMERCIO E DE SERVICOS',
  'W22.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W22.7 FAZENDA',
  'W22.8 OUTR LOCAIS ESPEC',
  'W22.9 LOCAL NE',
  'W23.0 RESIDENCIA',
  'W23.1 HABITACAO COLETIVA',
  'W23.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W23.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W23.4 RUA E ESTRADA',
  'W23.5 AREAS DE COMERCIO E DE SERVICOS',
  'W23.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W23.7 FAZENDA',
  'W23.8 OUTR LOCAIS ESPEC',
  'W23.9 LOCAL NE',
  'W24.0 RESIDENCIA',
  'W24.1 HABITACAO COLETIVA',
  'W24.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W24.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W24.4 RUA E ESTRADA',
  'W24.5 AREAS DE COMERCIO E DE SERVICOS',
  'W24.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W24.7 FAZENDA',
  'W24.8 OUTR LOCAIS ESPEC',
  'W24.9 LOCAL NE',
  'W25.0 RESIDENCIA',
  'W25.1 HABITACAO COLETIVA',
  'W25.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W25.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W25.4 RUA E ESTRADA',
  'W25.5 AREAS DE COMERCIO E DE SERVICOS',
  'W25.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W25.7 FAZENDA',
  'W25.8 OUTR LOCAIS ESPEC',
  'W25.9 LOCAL NE',
  'W26.0 RESIDENCIA',
  'W26.1 HABITACAO COLETIVA',
  'W26.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W26.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W26.4 RUA E ESTRADA',
  'W26.5 AREAS DE COMERCIO E DE SERVICOS',
  'W26.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W26.7 FAZENDA',
  'W26.8 OUTR LOCAIS ESPEC',
  'W26.9 LOCAL NE',
  'W27.0 RESIDENCIA',
  'W27.1 HABITACAO COLETIVA',
  'W27.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W27.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W27.4 RUA E ESTRADA',
  'W27.5 AREAS DE COMERCIO E DE SERVICOS',
  'W27.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W27.7 FAZENDA',
  'W27.8 OUTR LOCAIS ESPEC',
  'W27.9 LOCAL NE',
  'W28.0 RESIDENCIA',
  'W28.1 HABITACAO COLETIVA',
  'W28.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W28.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W28.4 RUA E ESTRADA',
  'W28.5 AREAS DE COMERCIO E DE SERVICOS',
  'W28.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W28.7 FAZENDA',
  'W28.8 OUTR LOCAIS ESPEC',
  'W28.9 LOCAL NE',
  'W29.0 RESIDENCIA',
  'W29.1 HABITACAO COLETIVA',
  'W29.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W29.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W29.4 RUA E ESTRADA',
  'W29.5 AREAS DE COMERCIO E DE SERVICOS',
  'W29.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W29.7 FAZENDA',
  'W29.8 OUTR LOCAIS ESPEC',
  'W29.9 LOCAL NE',
  'W30.0 RESIDENCIA',
  'W30.1 HABITACAO COLETIVA',
  'W30.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W30.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W30.4 RUA E ESTRADA',
  'W30.5 AREAS DE COMERCIO E DE SERVICOS',
  'W30.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W30.7 FAZENDA',
  'W30.8 OUTR LOCAIS ESPEC',
  'W30.9 LOCAL NE',
  'W31.0 RESIDENCIA',
  'W31.1 HABITACAO COLETIVA',
  'W31.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W31.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W31.4 RUA E ESTRADA',
  'W31.5 AREAS DE COMERCIO E DE SERVICOS',
  'W31.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W31.7 FAZENDA',
  'W31.8 OUTR LOCAIS ESPEC',
  'W31.9 LOCAL NE',
  'W32.0 RESIDENCIA',
  'W32.1 HABITACAO COLETIVA',
  'W32.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W32.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W32.4 RUA E ESTRADA',
  'W32.5 AREAS DE COMERCIO E DE SERVICOS',
  'W32.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W32.7 FAZENDA',
  'W32.8 OUTR LOCAIS ESPEC',
  'W32.9 LOCAL NE',
  'W33.0 RESIDENCIA',
  'W33.1 HABITACAO COLETIVA',
  'W33.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W33.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W33.4 RUA E ESTRADA',
  'W33.5 AREAS DE COMERCIO E DE SERVICOS',
  'W33.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W33.7 FAZENDA',
  'W33.8 OUTR LOCAIS ESPEC',
  'W33.9 LOCAL NE',
  'W34.0 RESIDENCIA',
  'W34.1 HABITACAO COLETIVA',
  'W34.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W34.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W34.4 RUA E ESTRADA',
  'W34.5 AREAS DE COMERCIO E DE SERVICOS',
  'W34.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W34.7 FAZENDA',
  'W34.8 OUTR LOCAIS ESPEC',
  'W34.9 LOCAL NE',
  'W35.0 RESIDENCIA',
  'W35.1 HABITACAO COLETIVA',
  'W35.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W35.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W35.4 RUA E ESTRADA',
  'W35.5 AREAS DE COMERCIO E DE SERVICOS',
  'W35.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W35.7 FAZENDA',
  'W35.8 OUTR LOCAIS ESPEC',
  'W35.9 LOCAL NE',
  'W36.0 RESIDENCIA',
  'W36.1 HABITACAO COLETIVA',
  'W36.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W36.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W36.4 RUA E ESTRADA',
  'W36.5 AREAS DE COMERCIO E DE SERVICOS',
  'W36.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W36.7 FAZENDA',
  'W36.8 OUTR LOCAIS ESPEC',
  'W36.9 LOCAL NE',
  'W37.0 RESIDENCIA',
  'W37.1 HABITACAO COLETIVA',
  'W37.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W37.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W37.4 RUA E ESTRADA',
  'W37.5 AREAS DE COMERCIO E DE SERVICOS',
  'W37.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W37.7 FAZENDA',
  'W37.8 OUTR LOCAIS ESPEC',
  'W37.9 LOCAL NE',
  'W38.0 RESIDENCIA',
  'W38.1 HABITACAO COLETIVA',
  'W38.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W38.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W38.4 RUA E ESTRADA',
  'W38.5 AREAS DE COMERCIO E DE SERVICOS',
  'W38.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W38.7 FAZENDA',
  'W38.8 OUTR LOCAIS ESPEC',
  'W38.9 LOCAL NE',
  'W39.0 RESIDENCIA',
  'W39.1 HABITACAO COLETIVA',
  'W39.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W39.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W39.4 RUA E ESTRADA',
  'W39.5 AREAS DE COMERCIO E DE SERVICOS',
  'W39.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W39.7 FAZENDA',
  'W39.8 OUTR LOCAIS ESPEC',
  'W39.9 LOCAL NE',
  'W40.0 RESIDENCIA',
  'W40.1 HABITACAO COLETIVA',
  'W40.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W40.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W40.4 RUA E ESTRADA',
  'W40.5 AREAS DE COMERCIO E DE SERVICOS',
  'W40.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W40.7 FAZENDA',
  'W40.8 OUTR LOCAIS ESPEC',
  'W40.9 LOCAL NE',
  'W41.0 RESIDENCIA',
  'W41.1 HABITACAO COLETIVA',
  'W41.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W41.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W41.4 RUA E ESTRADA',
  'W41.5 AREAS DE COMERCIO E DE SERVICOS',
  'W41.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W41.7 FAZENDA',
  'W41.8 OUTR LOCAIS ESPEC',
  'W41.9 LOCAL NE',
  'W42.0 RESIDENCIA',
  'W42.1 HABITACAO COLETIVA',
  'W42.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W42.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W42.4 RUA E ESTRADA',
  'W42.5 AREAS DE COMERCIO E DE SERVICOS',
  'W42.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W42.7 FAZENDA',
  'W42.8 OUTR LOCAIS ESPEC',
  'W42.9 LOCAL NE',
  'W43.0 RESIDENCIA',
  'W43.1 HABITACAO COLETIVA',
  'W43.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W43.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W43.4 RUA E ESTRADA',
  'W43.5 AREAS DE COMERCIO E DE SERVICOS',
  'W43.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W43.7 FAZENDA',
  'W43.8 OUTR LOCAIS ESPEC',
  'W43.9 LOCAL NE',
  'W44.0 RESIDENCIA',
  'W44.1 HABITACAO COLETIVA',
  'W44.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W44.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W44.4 RUA E ESTRADA',
  'W44.5 AREAS DE COMERCIO E DE SERVICOS',
  'W44.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W44.7 FAZENDA',
  'W44.8 OUTR LOCAIS ESPEC',
  'W44.9 LOCAL NE',
  'W45.0 RESIDENCIA',
  'W45.1 HABITACAO COLETIVA',
  'W45.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W45.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W45.4 RUA E ESTRADA',
  'W45.5 AREAS DE COMERCIO E DE SERVICOS',
  'W45.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W45.7 FAZENDA',
  'W45.8 OUTR LOCAIS ESPEC',
  'W45.9 LOCAL NE',
  'W49.0 RESIDENCIA',
  'W49.1 HABITACAO COLETIVA',
  'W49.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W49.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W49.4 RUA E ESTRADA',
  'W49.5 AREAS DE COMERCIO E DE SERVICOS',
  'W49.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W49.7 FAZENDA',
  'W49.8 OUTR LOCAIS ESPEC',
  'W49.9 LOCAL NE',
  'W50.0 RESIDENCIA',
  'W50.1 HABITACAO COLETIVA',
  'W50.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W50.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W50.4 RUA E ESTRADA',
  'W50.5 AREAS DE COMERCIO E DE SERVICOS',
  'W50.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W50.7 FAZENDA',
  'W50.8 OUTR LOCAIS ESPEC',
  'W50.9 LOCAL NE',
  'W51.0 RESIDENCIA',
  'W51.1 HABITACAO COLETIVA',
  'W51.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W51.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W51.4 RUA E ESTRADA',
  'W51.5 AREAS DE COMERCIO E DE SERVICOS',
  'W51.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W51.7 FAZENDA',
  'W51.8 OUTR LOCAIS ESPEC',
  'W51.9 LOCAL NE',
  'W52.0 RESIDENCIA',
  'W52.1 HABITACAO COLETIVA',
  'W52.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W52.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W52.4 RUA E ESTRADA',
  'W52.5 AREAS DE COMERCIO E DE SERVICOS',
  'W52.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W52.7 FAZENDA',
  'W52.8 OUTR LOCAIS ESPEC',
  'W52.9 LOCAL NE',
  'W53.0 RESIDENCIA',
  'W53.1 HABITACAO COLETIVA',
  'W53.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W53.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W53.4 RUA E ESTRADA',
  'W53.5 AREAS DE COMERCIO E DE SERVICOS',
  'W53.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W53.7 FAZENDA',
  'W53.8 OUTR LOCAIS ESPEC',
  'W53.9 LOCAL NE',
  'W54.0 RESIDENCIA',
  'W54.1 HABITACAO COLETIVA',
  'W54.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W54.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W54.4 RUA E ESTRADA',
  'W54.5 AREAS DE COMERCIO E DE SERVICOS',
  'W54.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W54.7 FAZENDA',
  'W54.8 OUTR LOCAIS ESPEC',
  'W54.9 LOCAL NE',
  'W55.0 RESIDENCIA',
  'W55.1 HABITACAO COLETIVA',
  'W55.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W55.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W55.4 RUA E ESTRADA',
  'W55.5 AREAS DE COMERCIO E DE SERVICOS',
  'W55.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W55.7 FAZENDA',
  'W55.8 OUTR LOCAIS ESPEC',
  'W55.9 LOCAL NE',
  'W56.0 RESIDENCIA',
  'W56.1 HABITACAO COLETIVA',
  'W56.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W56.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W56.4 RUA E ESTRADA',
  'W56.5 AREAS DE COMERCIO E DE SERVICOS',
  'W56.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W56.7 FAZENDA',
  'W56.8 OUTR LOCAIS ESPEC',
  'W56.9 LOCAL NE',
  'W57.0 RESIDENCIA',
  'W57.1 HABITACAO COLETIVA',
  'W57.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W57.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W57.4 RUA E ESTRADA',
  'W57.5 AREAS DE COMERCIO E DE SERVICOS',
  'W57.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W57.7 FAZENDA',
  'W57.8 OUTR LOCAIS ESPEC',
  'W57.9 LOCAL NE',
  'W58.0 RESIDENCIA',
  'W58.1 HABITACAO COLETIVA',
  'W58.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W58.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W58.4 RUA E ESTRADA',
  'W58.5 AREAS DE COMERCIO E DE SERVICOS',
  'W58.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W58.7 FAZENDA',
  'W58.8 OUTR LOCAIS ESPEC',
  'W58.9 LOCAL NE',
  'W59.0 RESIDENCIA',
  'W59.1 HABITACAO COLETIVA',
  'W59.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W59.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W59.4 RUA E ESTRADA',
  'W59.5 AREAS DE COMERCIO E DE SERVICOS',
  'W59.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W59.7 FAZENDA',
  'W59.8 OUTR LOCAIS ESPEC',
  'W59.9 LOCAL NE',
  'W60.0 RESIDENCIA',
  'W60.1 HABITACAO COLETIVA',
  'W60.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W60.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W60.4 RUA E ESTRADA',
  'W60.5 AREAS DE COMERCIO E DE SERVICOS',
  'W60.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W60.7 FAZENDA',
  'W60.8 OUTR LOCAIS ESPEC',
  'W60.9 LOCAL NE',
  'W64.0 RESIDENCIA',
  'W64.1 HABITACAO COLETIVA',
  'W64.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W64.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W64.4 RUA E ESTRADA',
  'W64.5 AREAS DE COMERCIO E DE SERVICOS',
  'W64.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W64.7 FAZENDA',
  'W64.8 OUTR LOCAIS ESPEC',
  'W64.9 LOCAL NE',
  'W65.0 RESIDENCIA',
  'W65.1 HABITACAO COLETIVA',
  'W65.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W65.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W65.4 RUA E ESTRADA',
  'W65.5 AREAS DE COMERCIO E DE SERVICOS',
  'W65.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W65.7 FAZENDA',
  'W65.8 OUTR LOCAIS ESPEC',
  'W65.9 LOCAL NE',
  'W66.0 RESIDENCIA',
  'W66.1 HABITACAO COLETIVA',
  'W66.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W66.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W66.4 RUA E ESTRADA',
  'W66.5 AREAS DE COMERCIO E DE SERVICOS',
  'W66.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W66.7 FAZENDA',
  'W66.8 OUTR LOCAIS ESPEC',
  'W66.9 LOCAL NE',
  'W67.0 RESIDENCIA',
  'W67.1 HABITACAO COLETIVA',
  'W67.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W67.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W67.4 RUA E ESTRADA',
  'W67.5 AREAS DE COMERCIO E DE SERVICOS',
  'W67.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W67.7 FAZENDA',
  'W67.8 OUTR LOCAIS ESPEC',
  'W67.9 LOCAL NE',
  'W68.0 RESIDENCIA',
  'W68.1 HABITACAO COLETIVA',
  'W68.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W68.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W68.4 RUA E ESTRADA',
  'W68.5 AREAS DE COMERCIO E DE SERVICOS',
  'W68.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W68.7 FAZENDA',
  'W68.8 OUTR LOCAIS ESPEC',
  'W68.9 LOCAL NE',
  'W69.0 RESIDENCIA',
  'W69.1 HABITACAO COLETIVA',
  'W69.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W69.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W69.4 RUA E ESTRADA',
  'W69.5 AREAS DE COMERCIO E DE SERVICOS',
  'W69.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W69.7 FAZENDA',
  'W69.8 OUTR LOCAIS ESPEC',
  'W69.9 LOCAL NE',
  'W70.0 RESIDENCIA',
  'W70.1 HABITACAO COLETIVA',
  'W70.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W70.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W70.4 RUA E ESTRADA',
  'W70.5 AREAS DE COMERCIO E DE SERVICOS',
  'W70.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W70.7 FAZENDA',
  'W70.8 OUTR LOCAIS ESPEC',
  'W70.9 LOCAL NE',
  'W73.0 RESIDENCIA',
  'W73.1 HABITACAO COLETIVA',
  'W73.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W73.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W73.4 RUA E ESTRADA',
  'W73.5 AREAS DE COMERCIO E DE SERVICOS',
  'W73.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W73.7 FAZENDA',
  'W73.8 OUTR LOCAIS ESPEC',
  'W73.9 LOCAL NE',
  'W74.0 RESIDENCIA',
  'W74.1 HABITACAO COLETIVA',
  'W74.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W74.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W74.4 RUA E ESTRADA',
  'W74.5 AREAS DE COMERCIO E DE SERVICOS',
  'W74.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W74.7 FAZENDA',
  'W74.8 OUTR LOCAIS ESPEC',
  'W74.9 LOCAL NE',
  'W75.0 RESIDENCIA',
  'W75.1 HABITACAO COLETIVA',
  'W75.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W75.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W75.4 RUA E ESTRADA',
  'W75.5 AREAS DE COMERCIO E DE SERVICOS',
  'W75.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W75.7 FAZENDA',
  'W75.8 OUTR LOCAIS ESPEC',
  'W75.9 LOCAL NE',
  'W76.0 RESIDENCIA',
  'W76.1 HABITACAO COLETIVA',
  'W76.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W76.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W76.4 RUA E ESTRADA',
  'W76.5 AREAS DE COMERCIO E DE SERVICOS',
  'W76.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W76.7 FAZENDA',
  'W76.8 OUTR LOCAIS ESPEC',
  'W76.9 LOCAL NE',
  'W77.0 RESIDENCIA',
  'W77.1 HABITACAO COLETIVA',
  'W77.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W77.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W77.4 RUA E ESTRADA',
  'W77.5 AREAS DE COMERCIO E DE SERVICOS',
  'W77.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W77.7 FAZENDA',
  'W77.8 OUTR LOCAIS ESPEC',
  'W77.9 LOCAL NE',
  'W78.0 RESIDENCIA',
  'W78.1 HABITACAO COLETIVA',
  'W78.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W78.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W78.4 RUA E ESTRADA',
  'W78.5 AREAS DE COMERCIO E DE SERVICOS',
  'W78.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W78.7 FAZENDA',
  'W78.8 OUTR LOCAIS ESPEC',
  'W78.9 LOCAL NE',
  'W79.0 RESIDENCIA',
  'W79.1 HABITACAO COLETIVA',
  'W79.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W79.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W79.4 RUA E ESTRADA',
  'W79.5 AREAS DE COMERCIO E DE SERVICOS',
  'W79.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W79.7 FAZENDA',
  'W79.8 OUTR LOCAIS ESPEC',
  'W79.9 LOCAL NE',
  'W80.0 RESIDENCIA',
  'W80.1 HABITACAO COLETIVA',
  'W80.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W80.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W80.4 RUA E ESTRADA',
  'W80.5 AREAS DE COMERCIO E DE SERVICOS',
  'W80.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W80.7 FAZENDA',
  'W80.8 OUTR LOCAIS ESPEC',
  'W80.9 LOCAL NE',
  'W81.0 RESIDENCIA',
  'W81.1 HABITACAO COLETIVA',
  'W81.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W81.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W81.4 RUA E ESTRADA',
  'W81.5 AREAS DE COMERCIO E DE SERVICOS',
  'W81.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W81.7 FAZENDA',
  'W81.8 OUTR LOCAIS ESPEC',
  'W81.9 LOCAL NE',
  'W83.0 RESIDENCIA',
  'W83.1 HABITACAO COLETIVA',
  'W83.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W83.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W83.4 RUA E ESTRADA',
  'W83.5 AREAS DE COMERCIO E DE SERVICOS',
  'W83.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W83.7 FAZENDA',
  'W83.8 OUTR LOCAIS ESPEC',
  'W83.9 LOCAL NE',
  'W84.0 RESIDENCIA',
  'W84.1 HABITACAO COLETIVA',
  'W84.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W84.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W84.4 RUA E ESTRADA',
  'W84.5 AREAS DE COMERCIO E DE SERVICOS',
  'W84.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W84.7 FAZENDA',
  'W84.8 OUTR LOCAIS ESPEC',
  'W84.9 LOCAL NE',
  'W85.0 RESIDENCIA',
  'W85.1 HABITACAO COLETIVA',
  'W85.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W85.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W85.4 RUA E ESTRADA',
  'W85.5 AREAS DE COMERCIO E DE SERVICOS',
  'W85.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W85.7 FAZENDA',
  'W85.8 OUTR LOCAIS ESPEC',
  'W85.9 LOCAL NE',
  'W86.0 RESIDENCIA',
  'W86.1 HABITACAO COLETIVA',
  'W86.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W86.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W86.4 RUA E ESTRADA',
  'W86.5 AREAS DE COMERCIO E DE SERVICOS',
  'W86.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W86.7 FAZENDA',
  'W86.8 OUTR LOCAIS ESPEC',
  'W86.9 LOCAL NE',
  'W87.0 RESIDENCIA',
  'W87.1 HABITACAO COLETIVA',
  'W87.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W87.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W87.4 RUA E ESTRADA',
  'W87.5 AREAS DE COMERCIO E DE SERVICOS',
  'W87.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W87.7 FAZENDA',
  'W87.8 OUTR LOCAIS ESPEC',
  'W87.9 LOCAL NE',
  'W88.0 RESIDENCIA',
  'W88.1 HABITACAO COLETIVA',
  'W88.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W88.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W88.4 RUA E ESTRADA',
  'W88.5 AREAS DE COMERCIO E DE SERVICOS',
  'W88.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W88.7 FAZENDA',
  'W88.8 OUTR LOCAIS ESPEC',
  'W88.9 LOCAL NE',
  'W89.0 RESIDENCIA',
  'W89.1 HABITACAO COLETIVA',
  'W89.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W89.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W89.4 RUA E ESTRADA',
  'W89.5 AREAS DE COMERCIO E DE SERVICOS',
  'W89.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W89.7 FAZENDA',
  'W89.8 OUTR LOCAIS ESPEC',
  'W89.9 LOCAL NE',
  'W90.0 RESIDENCIA',
  'W90.1 HABITACAO COLETIVA',
  'W90.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W90.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W90.4 RUA E ESTRADA',
  'W90.5 AREAS DE COMERCIO E DE SERVICOS',
  'W90.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W90.7 FAZENDA',
  'W90.8 OUTR LOCAIS ESPEC',
  'W90.9 LOCAL NE',
  'W91.0 RESIDENCIA',
  'W91.1 HABITACAO COLETIVA',
  'W91.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W91.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W91.4 RUA E ESTRADA',
  'W91.5 AREAS DE COMERCIO E DE SERVICOS',
  'W91.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W91.7 FAZENDA',
  'W91.8 OUTR LOCAIS ESPEC',
  'W91.9 LOCAL NE',
  'W92.0 RESIDENCIA',
  'W92.1 HABITACAO COLETIVA',
  'W92.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W92.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W92.4 RUA E ESTRADA',
  'W92.5 AREAS DE COMERCIO E DE SERVICOS',
  'W92.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W92.7 FAZENDA',
  'W92.8 OUTR LOCAIS ESPEC',
  'W92.9 LOCAL NE',
  'W93.0 RESIDENCIA',
  'W93.1 HABITACAO COLETIVA',
  'W93.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W93.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W93.4 RUA E ESTRADA',
  'W93.5 AREAS DE COMERCIO E DE SERVICOS',
  'W93.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W93.7 FAZENDA',
  'W93.8 OUTR LOCAIS ESPEC',
  'W93.9 LOCAL NE',
  'W94.0 RESIDENCIA',
  'W94.1 HABITACAO COLETIVA',
  'W94.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W94.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W94.4 RUA E ESTRADA',
  'W94.5 AREAS DE COMERCIO E DE SERVICOS',
  'W94.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W94.7 FAZENDA',
  'W94.8 OUTR LOCAIS ESPEC',
  'W94.9 LOCAL NE',
  'W99.0 RESIDENCIA',
  'W99.1 HABITACAO COLETIVA',
  'W99.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'W99.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'W99.4 RUA E ESTRADA',
  'W99.5 AREAS DE COMERCIO E DE SERVICOS',
  'W99.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'W99.7 FAZENDA',
  'W99.8 OUTR LOCAIS ESPEC',
  'W99.9 LOCAL NE',
  'X00.0 RESIDENCIA',
  'X00.1 HABITACAO COLETIVA',
  'X00.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X00.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X00.4 RUA E ESTRADA',
  'X00.5 AREAS DE COMERCIO E DE SERVICOS',
  'X00.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X00.7 FAZENDA',
  'X00.8 OUTR LOCAIS ESPEC',
  'X00.9 LOCAL NE',
  'X01.0 RESIDENCIA',
  'X01.1 HABITACAO COLETIVA',
  'X01.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X01.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X01.4 RUA E ESTRADA',
  'X01.5 AREAS DE COMERCIO E DE SERVICOS',
  'X01.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X01.7 FAZENDA',
  'X01.8 OUTR LOCAIS ESPEC',
  'X01.9 LOCAL NE',
  'X02.0 RESIDENCIA',
  'X02.1 HABITACAO COLETIVA',
  'X02.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X02.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X02.4 RUA E ESTRADA',
  'X02.5 AREAS DE COMERCIO E DE SERVICOS',
  'X02.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X02.7 FAZENDA',
  'X02.8 OUTR LOCAIS ESPEC',
  'X02.9 LOCAL NE',
  'X03.0 RESIDENCIA',
  'X03.1 HABITACAO COLETIVA',
  'X03.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X03.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X03.4 RUA E ESTRADA',
  'X03.5 AREAS DE COMERCIO E DE SERVICOS',
  'X03.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X03.7 FAZENDA',
  'X03.8 OUTR LOCAIS ESPEC',
  'X03.9 LOCAL NE',
  'X04.0 RESIDENCIA',
  'X04.1 HABITACAO COLETIVA',
  'X04.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X04.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X04.4 RUA E ESTRADA',
  'X04.5 AREAS DE COMERCIO E DE SERVICOS',
  'X04.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X04.7 FAZENDA',
  'X04.8 OUTR LOCAIS ESPEC',
  'X04.9 LOCAL NE',
  'X05.0 RESIDENCIA',
  'X05.1 HABITACAO COLETIVA',
  'X05.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X05.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X05.4 RUA E ESTRADA',
  'X05.5 AREAS DE COMERCIO E DE SERVICOS',
  'X05.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X05.7 FAZENDA',
  'X05.8 OUTR LOCAIS ESPEC',
  'X05.9 LOCAL NE',
  'X06.0 RESIDENCIA',
  'X06.1 HABITACAO COLETIVA',
  'X06.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X06.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X06.4 RUA E ESTRADA',
  'X06.5 AREAS DE COMERCIO E DE SERVICOS',
  'X06.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X06.7 FAZENDA',
  'X06.8 OUTR LOCAIS ESPEC',
  'X06.9 LOCAL NE',
  'X08.0 RESIDENCIA',
  'X08.1 HABITACAO COLETIVA',
  'X08.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X08.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X08.4 RUA E ESTRADA',
  'X08.5 AREAS DE COMERCIO E DE SERVICOS',
  'X08.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X08.7 FAZENDA',
  'X08.8 OUTR LOCAIS ESPEC',
  'X08.9 LOCAL NE',
  'X09.0 RESIDENCIA',
  'X09.1 HABITACAO COLETIVA',
  'X09.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X09.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X09.4 RUA E ESTRADA',
  'X09.5 AREAS DE COMERCIO E DE SERVICOS',
  'X09.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X09.7 FAZENDA',
  'X09.8 OUTR LOCAIS ESPEC',
  'X09.9 LOCAL NE',
  'X10.0 RESIDENCIA',
  'X10.1 HABITACAO COLETIVA',
  'X10.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X10.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X10.4 RUA E ESTRADA',
  'X10.5 AREAS DE COMERCIO E DE SERVICOS',
  'X10.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X10.7 FAZENDA',
  'X10.8 OUTR LOCAIS ESPEC',
  'X10.9 LOCAL NE',
  'X11.0 RESIDENCIA',
  'X11.1 HABITACAO COLETIVA',
  'X11.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X11.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X11.4 RUA E ESTRADA',
  'X11.5 AREAS DE COMERCIO E DE SERVICOS',
  'X11.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X11.7 FAZENDA',
  'X11.8 OUTR LOCAIS ESPEC',
  'X11.9 LOCAL NE',
  'X12.0 RESIDENCIA',
  'X12.1 HABITACAO COLETIVA',
  'X12.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X12.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X12.4 RUA E ESTRADA',
  'X12.5 AREAS DE COMERCIO E DE SERVICOS',
  'X12.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X12.7 FAZENDA',
  'X12.8 OUTR LOCAIS ESPEC',
  'X12.9 LOCAL NE',
  'X13.0 RESIDENCIA',
  'X13.1 HABITACAO COLETIVA',
  'X13.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X13.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X13.4 RUA E ESTRADA',
  'X13.5 AREAS DE COMERCIO E DE SERVICOS',
  'X13.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X13.7 FAZENDA',
  'X13.8 OUTR LOCAIS ESPEC',
  'X13.9 LOCAL NE',
  'X14.0 RESIDENCIA',
  'X14.1 HABITACAO COLETIVA',
  'X14.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X14.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X14.4 RUA E ESTRADA',
  'X14.5 AREAS DE COMERCIO E DE SERVICOS',
  'X14.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X14.7 FAZENDA',
  'X14.8 OUTR LOCAIS ESPEC',
  'X14.9 LOCAL NE',
  'X15.0 RESIDENCIA',
  'X15.1 HABITACAO COLETIVA',
  'X15.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X15.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X15.4 RUA E ESTRADA',
  'X15.5 AREAS DE COMERCIO E DE SERVICOS',
  'X15.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X15.7 FAZENDA',
  'X15.8 OUTR LOCAIS ESPEC',
  'X15.9 LOCAL NE',
  'X16.0 RESIDENCIA',
  'X16.1 HABITACAO COLETIVA',
  'X16.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X16.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X16.4 RUA E ESTRADA',
  'X16.5 AREAS DE COMERCIO E DE SERVICOS',
  'X16.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X16.7 FAZENDA',
  'X16.8 OUTR LOCAIS ESPEC',
  'X16.9 LOCAL NE',
  'X17.0 RESIDENCIA',
  'X17.1 HABITACAO COLETIVA',
  'X17.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X17.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X17.4 RUA E ESTRADA',
  'X17.5 AREAS DE COMERCIO E DE SERVICOS',
  'X17.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X17.7 FAZENDA',
  'X17.8 OUTR LOCAIS ESPEC',
  'X17.9 LOCAL NE',
  'X18.0 RESIDENCIA',
  'X18.1 HABITACAO COLETIVA',
  'X18.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X18.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X18.4 RUA E ESTRADA',
  'X18.5 AREAS DE COMERCIO E DE SERVICOS',
  'X18.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X18.7 FAZENDA',
  'X18.8 OUTR LOCAIS ESPEC',
  'X18.9 LOCAL NE',
  'X19.0 RESIDENCIA',
  'X19.1 HABITACAO COLETIVA',
  'X19.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X19.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X19.4 RUA E ESTRADA',
  'X19.5 AREAS DE COMERCIO E DE SERVICOS',
  'X19.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X19.7 FAZENDA',
  'X19.8 OUTR LOCAIS ESPEC',
  'X19.9 LOCAL NE',
  'X20.0 RESIDENCIA',
  'X20.1 HABITACAO COLETIVA',
  'X20.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X20.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X20.4 RUA E ESTRADA',
  'X20.5 AREAS DE COMERCIO E DE SERVICOS',
  'X20.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X20.7 FAZENDA',
  'X20.8 OUTR LOCAIS ESPEC',
  'X20.9 LOCAL NE',
  'X21.0 RESIDENCIA',
  'X21.1 HABITACAO COLETIVA',
  'X21.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X21.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X21.4 RUA E ESTRADA',
  'X21.5 AREAS DE COMERCIO E DE SERVICOS',
  'X21.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X21.7 FAZENDA',
  'X21.8 OUTR LOCAIS ESPEC',
  'X21.9 LOCAL NE',
  'X22.0 RESIDENCIA',
  'X22.1 HABITACAO COLETIVA',
  'X22.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X22.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X22.4 RUA E ESTRADA',
  'X22.5 AREAS DE COMERCIO E DE SERVICOS',
  'X22.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X22.7 FAZENDA',
  'X22.8 OUTR LOCAIS ESPEC',
  'X22.9 LOCAL NE',
  'X23.0 RESIDENCIA',
  'X23.1 HABITACAO COLETIVA',
  'X23.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X23.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X23.4 RUA E ESTRADA',
  'X23.5 AREAS DE COMERCIO E DE SERVICOS',
  'X23.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X23.7 FAZENDA',
  'X23.8 OUTR LOCAIS ESPEC',
  'X23.9 LOCAL NE',
  'X24.0 RESIDENCIA',
  'X24.1 HABITACAO COLETIVA',
  'X24.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X24.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X24.4 RUA E ESTRADA',
  'X24.5 AREAS DE COMERCIO E DE SERVICOS',
  'X24.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X24.7 FAZENDA',
  'X24.8 OUTR LOCAIS ESPEC',
  'X24.9 LOCAL NE',
  'X25.0 RESIDENCIA',
  'X25.1 HABITACAO COLETIVA',
  'X25.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X25.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X25.4 RUA E ESTRADA',
  'X25.5 AREAS DE COMERCIO E DE SERVICOS',
  'X25.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X25.7 FAZENDA',
  'X25.8 OUTR LOCAIS ESPEC',
  'X25.9 LOCAL NE',
  'X26.0 RESIDENCIA',
  'X26.1 HABITACAO COLETIVA',
  'X26.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X26.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X26.4 RUA E ESTRADA',
  'X26.5 AREAS DE COMERCIO E DE SERVICOS',
  'X26.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X26.7 FAZENDA',
  'X26.8 OUTR LOCAIS ESPEC',
  'X26.9 LOCAL NE',
  'X27.0 RESIDENCIA',
  'X27.1 HABITACAO COLETIVA',
  'X27.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X27.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X27.4 RUA E ESTRADA',
  'X27.5 AREAS DE COMERCIO E DE SERVICOS',
  'X27.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X27.7 FAZENDA',
  'X27.8 OUTR LOCAIS ESPEC',
  'X27.9 LOCAL NE',
  'X28.0 RESIDENCIA',
  'X28.1 HABITACAO COLETIVA',
  'X28.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X28.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X28.4 RUA E ESTRADA',
  'X28.5 AREAS DE COMERCIO E DE SERVICOS',
  'X28.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X28.7 FAZENDA',
  'X28.8 OUTR LOCAIS ESPEC',
  'X28.9 LOCAL NE',
  'X29.0 RESIDENCIA',
  'X29.1 HABITACAO COLETIVA',
  'X29.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X29.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X29.4 RUA E ESTRADA',
  'X29.5 AREAS DE COMERCIO E DE SERVICOS',
  'X29.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X29.7 FAZENDA',
  'X29.8 OUTR LOCAIS ESPEC',
  'X29.9 LOCAL NE',
  'X30.0 RESIDENCIA',
  'X30.1 HABITACAO COLETIVA',
  'X30.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X30.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X30.4 RUA E ESTRADA',
  'X30.5 AREAS DE COMERCIO E DE SERVICOS',
  'X30.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X30.7 FAZENDA',
  'X30.8 OUTR LOCAIS ESPEC',
  'X30.9 LOCAL NE',
  'X31.0 RESIDENCIA',
  'X31.1 HABITACAO COLETIVA',
  'X31.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X31.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X31.4 RUA E ESTRADA',
  'X31.5 AREAS DE COMERCIO E DE SERVICOS',
  'X31.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X31.7 FAZENDA',
  'X31.8 OUTR LOCAIS ESPEC',
  'X31.9 LOCAL NE',
  'X32.0 RESIDENCIA',
  'X32.1 HABITACAO COLETIVA',
  'X32.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X32.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X32.4 RUA E ESTRADA',
  'X32.5 AREAS DE COMERCIO E DE SERVICOS',
  'X32.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X32.7 FAZENDA',
  'X32.8 OUTR LOCAIS ESPEC',
  'X32.9 LOCAL NE',
  'X33.0 RESIDENCIA',
  'X33.1 HABITACAO COLETIVA',
  'X33.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X33.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X33.4 RUA E ESTRADA',
  'X33.5 AREAS DE COMERCIO E DE SERVICOS',
  'X33.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X33.7 FAZENDA',
  'X33.8 OUTR LOCAIS ESPEC',
  'X33.9 LOCAL NE',
  'X34.0 RESIDENCIA',
  'X34.1 HABITACAO COLETIVA',
  'X34.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X34.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X34.4 RUA E ESTRADA',
  'X34.5 AREAS DE COMERCIO E DE SERVICOS',
  'X34.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X34.7 FAZENDA',
  'X34.8 OUTR LOCAIS ESPEC',
  'X34.9 LOCAL NE',
  'X35.0 RESIDENCIA',
  'X35.1 HABITACAO COLETIVA',
  'X35.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X35.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X35.4 RUA E ESTRADA',
  'X35.5 AREAS DE COMERCIO E DE SERVICOS',
  'X35.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X35.7 FAZENDA',
  'X35.8 OUTR LOCAIS ESPEC',
  'X35.9 LOCAL NE',
  'X36.0 RESIDENCIA',
  'X36.1 HABITACAO COLETIVA',
  'X36.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X36.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X36.4 RUA E ESTRADA',
  'X36.5 AREAS DE COMERCIO E DE SERVICOS',
  'X36.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X36.7 FAZENDA',
  'X36.8 OUTR LOCAIS ESPEC',
  'X36.9 LOCAL NE',
  'X37.0 RESIDENCIA',
  'X37.1 HABITACAO COLETIVA',
  'X37.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X37.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X37.4 RUA E ESTRADA',
  'X37.5 AREAS DE COMERCIO E DE SERVICOS',
  'X37.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X37.7 FAZENDA',
  'X37.8 OUTR LOCAIS ESPEC',
  'X37.9 LOCAL NE',
  'X38.0 RESIDENCIA',
  'X38.1 HABITACAO COLETIVA',
  'X38.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X38.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X38.4 RUA E ESTRADA',
  'X38.5 AREAS DE COMERCIO E DE SERVICOS',
  'X38.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X38.7 FAZENDA',
  'X38.8 OUTR LOCAIS ESPEC',
  'X38.9 LOCAL NE',
  'X39.0 RESIDENCIA',
  'X39.1 HABITACAO COLETIVA',
  'X39.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X39.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X39.4 RUA E ESTRADA',
  'X39.5 AREAS DE COMERCIO E DE SERVICOS',
  'X39.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X39.7 FAZENDA',
  'X39.8 OUTR LOCAIS ESPEC',
  'X39.9 LOCAL NE',
  'X40.0 RESIDENCIA',
  'X40.1 HABITACAO COLETIVA',
  'X40.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X40.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X40.4 RUA E ESTRADA',
  'X40.5 AREAS DE COMERCIO E DE SERVICOS',
  'X40.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X40.7 FAZENDA',
  'X40.8 OUTR LOCAIS ESPEC',
  'X40.9 LOCAL NE',
  'X41.0 RESIDENCIA',
  'X41.1 HABITACAO COLETIVA',
  'X41.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X41.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X41.4 RUA E ESTRADA',
  'X41.5 AREAS DE COMERCIO E DE SERVICOS',
  'X41.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X41.7 FAZENDA',
  'X41.8 OUTR LOCAIS ESPEC',
  'X41.9 LOCAL NE',
  'X42.0 RESIDENCIA',
  'X42.1 HABITACAO COLETIVA',
  'X42.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X42.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X42.4 RUA E ESTRADA',
  'X42.5 AREAS DE COMERCIO E DE SERVICOS',
  'X42.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X42.7 FAZENDA',
  'X42.8 OUTR LOCAIS ESPEC',
  'X42.9 LOCAL NE',
  'X43.0 RESIDENCIA',
  'X43.1 HABITACAO COLETIVA',
  'X43.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X43.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X43.4 RUA E ESTRADA',
  'X43.5 AREAS DE COMERCIO E DE SERVICOS',
  'X43.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X43.7 FAZENDA',
  'X43.8 OUTR LOCAIS ESPEC',
  'X43.9 LOCAL NE',
  'X44.0 RESIDENCIA',
  'X44.1 HABITACAO COLETIVA',
  'X44.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X44.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X44.4 RUA E ESTRADA',
  'X44.5 AREAS DE COMERCIO E DE SERVICOS',
  'X44.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X44.7 FAZENDA',
  'X44.8 OUTR LOCAIS ESPEC',
  'X44.9 LOCAL NE',
  'X45.0 RESIDENCIA',
  'X45.1 HABITACAO COLETIVA',
  'X45.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X45.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X45.4 RUA E ESTRADA',
  'X45.5 AREAS DE COMERCIO E DE SERVICOS',
  'X45.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X45.7 FAZENDA',
  'X45.8 OUTR LOCAIS ESPEC',
  'X45.9 LOCAL NE',
  'X46.0 RESIDENCIA',
  'X46.1 HABITACAO COLETIVA',
  'X46.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X46.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X46.4 RUA E ESTRADA',
  'X46.5 AREAS DE COMERCIO E DE SERVICOS',
  'X46.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X46.7 FAZENDA',
  'X46.8 OUTR LOCAIS ESPEC',
  'X46.9 LOCAL NE',
  'X47.0 RESIDENCIA',
  'X47.1 HABITACAO COLETIVA',
  'X47.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X47.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X47.4 RUA E ESTRADA',
  'X47.5 AREAS DE COMERCIO E DE SERVICOS',
  'X47.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X47.7 FAZENDA',
  'X47.8 OUTR LOCAIS ESPEC',
  'X47.9 LOCAL NE',
  'X48.0 RESIDENCIA',
  'X48.1 HABITACAO COLETIVA',
  'X48.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X48.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X48.4 RUA E ESTRADA',
  'X48.5 AREAS DE COMERCIO E DE SERVICOS',
  'X48.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X48.7 FAZENDA',
  'X48.8 OUTR LOCAIS ESPEC',
  'X48.9 LOCAL NE',
  'X49.0 RESIDENCIA',
  'X49.1 HABITACAO COLETIVA',
  'X49.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X49.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X49.4 RUA E ESTRADA',
  'X49.5 AREAS DE COMERCIO E DE SERVICOS',
  'X49.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X49.7 FAZENDA',
  'X49.8 OUTR LOCAIS ESPEC',
  'X49.9 LOCAL NE',
  'X50.0 RESIDENCIA',
  'X50.1 HABITACAO COLETIVA',
  'X50.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X50.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X50.4 RUA E ESTRADA',
  'X50.5 AREAS DE COMERCIO E DE SERVICOS',
  'X50.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X50.7 FAZENDA',
  'X50.8 OUTR LOCAIS ESPEC',
  'X50.9 LOCAL NE',
  'X51.0 RESIDENCIA',
  'X51.1 HABITACAO COLETIVA',
  'X51.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X51.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X51.4 RUA E ESTRADA',
  'X51.5 AREAS DE COMERCIO E DE SERVICOS',
  'X51.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X51.7 FAZENDA',
  'X51.8 OUTR LOCAIS ESPEC',
  'X51.9 LOCAL NE',
  'X52.0 RESIDENCIA',
  'X52.1 HABITACAO COLETIVA',
  'X52.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X52.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X52.4 RUA E ESTRADA',
  'X52.5 AREAS DE COMERCIO E DE SERVICOS',
  'X52.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X52.7 FAZENDA',
  'X52.8 OUTR LOCAIS ESPEC',
  'X52.9 LOCAL NE',
  'X53.0 RESIDENCIA',
  'X53.1 HABITACAO COLETIVA',
  'X53.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X53.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X53.4 RUA E ESTRADA',
  'X53.5 AREAS DE COMERCIO E DE SERVICOS',
  'X53.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X53.7 FAZENDA',
  'X53.8 OUTR LOCAIS ESPEC',
  'X53.9 LOCAL NE',
  'X54.0 RESIDENCIA',
  'X54.1 HABITACAO COLETIVA',
  'X54.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X54.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X54.4 RUA E ESTRADA',
  'X54.5 AREAS DE COMERCIO E DE SERVICOS',
  'X54.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X54.7 FAZENDA',
  'X54.8 OUTR LOCAIS ESPEC',
  'X54.9 LOCAL NE',
  'X57.0 RESIDENCIA',
  'X57.1 HABITACAO COLETIVA',
  'X57.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X57.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X57.4 RUA E ESTRADA',
  'X57.5 AREAS DE COMERCIO E DE SERVICOS',
  'X57.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X57.7 FAZENDA',
  'X57.8 OUTR LOCAIS ESPEC',
  'X57.9 LOCAL NE',
  'X58.0 RESIDENCIA',
  'X58.1 HABITACAO COLETIVA',
  'X58.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X58.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X58.4 RUA E ESTRADA',
  'X58.5 AREAS DE COMERCIO E DE SERVICOS',
  'X58.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X58.7 FAZENDA',
  'X58.8 OUTR LOCAIS ESPEC',
  'X58.9 LOCAL NE',
  'X59.0 RESIDENCIA',
  'X59.1 HABITACAO COLETIVA',
  'X59.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X59.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X59.4 RUA E ESTRADA',
  'X59.5 AREAS DE COMERCIO E DE SERVICOS',
  'X59.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X59.7 FAZENDA',
  'X59.8 OUTR LOCAIS ESPEC',
  'X59.9 LOCAL NE',
  'X60.0 RESIDENCIA',
  'X60.1 HABITACAO COLETIVA',
  'X60.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X60.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X60.4 RUA E ESTRADA',
  'X60.5 AREAS DE COMERCIO E DE SERVICOS',
  'X60.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X60.7 FAZENDA',
  'X60.8 OUTR LOCAIS ESPEC',
  'X60.9 LOCAL NE',
  'X61.0 RESIDENCIA',
  'X61.1 HABITACAO COLETIVA',
  'X61.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X61.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X61.4 RUA E ESTRADA',
  'X61.5 AREAS DE COMERCIO E DE SERVICOS',
  'X61.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X61.7 FAZENDA',
  'X61.8 OUTR LOCAIS ESPEC',
  'X61.9 LOCAL NE',
  'X62.0 RESIDENCIA',
  'X62.1 HABITACAO COLETIVA',
  'X62.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X62.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X62.4 RUA E ESTRADA',
  'X62.5 AREAS DE COMERCIO E DE SERVICOS',
  'X62.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X62.7 FAZENDA',
  'X62.8 OUTR LOCAIS ESPEC',
  'X62.9 LOCAL NE',
  'X63.0 RESIDENCIA',
  'X63.1 HABITACAO COLETIVA',
  'X63.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X63.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X63.4 RUA E ESTRADA',
  'X63.5 AREAS DE COMERCIO E DE SERVICOS',
  'X63.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X63.7 FAZENDA',
  'X63.8 OUTR LOCAIS ESPEC',
  'X63.9 LOCAL NE',
  'X64.0 RESIDENCIA',
  'X64.1 HABITACAO COLETIVA',
  'X64.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X64.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X64.4 RUA E ESTRADA',
  'X64.5 AREAS DE COMERCIO E DE SERVICOS',
  'X64.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X64.7 FAZENDA',
  'X64.8 OUTR LOCAIS ESPEC',
  'X64.9 LOCAL NE',
  'X65.0 RESIDENCIA',
  'X65.1 HABITACAO COLETIVA',
  'X65.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X65.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X65.4 RUA E ESTRADA',
  'X65.5 AREAS DE COMERCIO E DE SERVICOS',
  'X65.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X65.7 FAZENDA',
  'X65.8 OUTR LOCAIS ESPEC',
  'X65.9 LOCAL NE',
  'X66.0 RESIDENCIA',
  'X66.1 HABITACAO COLETIVA',
  'X66.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X66.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X66.4 RUA E ESTRADA',
  'X66.5 AREAS DE COMERCIO E DE SERVICOS',
  'X66.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X66.7 FAZENDA',
  'X66.8 OUTR LOCAIS ESPEC',
  'X66.9 LOCAL NE',
  'X67.0 RESIDENCIA',
  'X67.1 HABITACAO COLETIVA',
  'X67.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X67.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X67.4 RUA E ESTRADA',
  'X67.5 AREAS DE COMERCIO E DE SERVICOS',
  'X67.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X67.7 FAZENDA',
  'X67.8 OUTR LOCAIS ESPEC',
  'X67.9 LOCAL NE',
  'X68.0 RESIDENCIA',
  'X68.1 HABITACAO COLETIVA',
  'X68.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X68.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X68.4 RUA E ESTRADA',
  'X68.5 AREAS DE COMERCIO E DE SERVICOS',
  'X68.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X68.7 FAZENDA',
  'X68.8 OUTR LOCAIS ESPEC',
  'X68.9 LOCAL NE',
  'X69.0 RESIDENCIA',
  'X69.1 HABITACAO COLETIVA',
  'X69.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X69.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X69.4 RUA E ESTRADA',
  'X69.5 AREAS DE COMERCIO E DE SERVICOS',
  'X69.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X69.7 FAZENDA',
  'X69.8 OUTR LOCAIS ESPEC',
  'X69.9 LOCAL NE',
  'X70.0 RESIDENCIA',
  'X70.1 HABITACAO COLETIVA',
  'X70.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X70.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X70.4 RUA E ESTRADA',
  'X70.5 AREAS DE COMERCIO E DE SERVICOS',
  'X70.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X70.7 FAZENDA',
  'X70.8 OUTR LOCAIS ESPEC',
  'X70.9 LOCAL NE',
  'X71.0 RESIDENCIA',
  'X71.1 HABITACAO COLETIVA',
  'X71.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X71.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X71.4 RUA E ESTRADA',
  'X71.5 AREAS DE COMERCIO E DE SERVICOS',
  'X71.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X71.7 FAZENDA',
  'X71.8 OUTR LOCAIS ESPEC',
  'X71.9 LOCAL NE',
  'X72.0 RESIDENCIA',
  'X72.1 HABITACAO COLETIVA',
  'X72.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X72.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X72.4 RUA E ESTRADA',
  'X72.5 AREAS DE COMERCIO E DE SERVICOS',
  'X72.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X72.7 FAZENDA',
  'X72.8 OUTR LOCAIS ESPEC',
  'X72.9 LOCAL NE',
  'X73.0 RESIDENCIA',
  'X73.1 HABITACAO COLETIVA',
  'X73.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X73.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X73.4 RUA E ESTRADA',
  'X73.5 AREAS DE COMERCIO E DE SERVICOS',
  'X73.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X73.7 FAZENDA',
  'X73.8 OUTR LOCAIS ESPEC',
  'X73.9 LOCAL NE',
  'X74.0 RESIDENCIA',
  'X74.1 HABITACAO COLETIVA',
  'X74.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X74.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X74.4 RUA E ESTRADA',
  'X74.5 AREAS DE COMERCIO E DE SERVICOS',
  'X74.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X74.7 FAZENDA',
  'X74.8 OUTR LOCAIS ESPEC',
  'X74.9 LOCAL NE',
  'X75.0 RESIDENCIA',
  'X75.1 HABITACAO COLETIVA',
  'X75.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X75.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X75.4 RUA E ESTRADA',
  'X75.5 AREAS DE COMERCIO E DE SERVICOS',
  'X75.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X75.7 FAZENDA',
  'X75.8 OUTR LOCAIS ESPEC',
  'X75.9 LOCAL NE',
  'X76.0 RESIDENCIA',
  'X76.1 HABITACAO COLETIVA',
  'X76.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X76.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X76.4 RUA E ESTRADA',
  'X76.5 AREAS DE COMERCIO E DE SERVICOS',
  'X76.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X76.7 FAZENDA',
  'X76.8 OUTR LOCAIS ESPEC',
  'X76.9 LOCAL NE',
  'X77.0 RESIDENCIA',
  'X77.1 HABITACAO COLETIVA',
  'X77.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X77.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X77.4 RUA E ESTRADA',
  'X77.5 AREAS DE COMERCIO E DE SERVICOS',
  'X77.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X77.7 FAZENDA',
  'X77.8 OUTR LOCAIS ESPEC',
  'X77.9 LOCAL NE',
  'X78.0 RESIDENCIA',
  'X78.1 HABITACAO COLETIVA',
  'X78.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X78.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X78.4 RUA E ESTRADA',
  'X78.5 AREAS DE COMERCIO E DE SERVICOS',
  'X78.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X78.7 FAZENDA',
  'X78.8 OUTR LOCAIS ESPEC',
  'X78.9 LOCAL NE',
  'X79.0 RESIDENCIA',
  'X79.1 HABITACAO COLETIVA',
  'X79.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X79.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X79.4 RUA E ESTRADA',
  'X79.5 AREAS DE COMERCIO E DE SERVICOS',
  'X79.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X79.7 FAZENDA',
  'X79.8 OUTR LOCAIS ESPEC',
  'X79.9 LOCAL NE',
  'X80.0 RESIDENCIA',
  'X80.1 HABITACAO COLETIVA',
  'X80.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X80.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X80.4 RUA E ESTRADA',
  'X80.5 AREAS DE COMERCIO E DE SERVICOS',
  'X80.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X80.7 FAZENDA',
  'X80.8 OUTR LOCAIS ESPEC',
  'X80.9 LOCAL NE',
  'X81.0 RESIDENCIA',
  'X81.1 HABITACAO COLETIVA',
  'X81.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X81.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X81.4 RUA E ESTRADA',
  'X81.5 AREAS DE COMERCIO E DE SERVICOS',
  'X81.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X81.7 FAZENDA',
  'X81.8 OUTR LOCAIS ESPEC',
  'X81.9 LOCAL NE',
  'X82.0 RESIDENCIA',
  'X82.1 HABITACAO COLETIVA',
  'X82.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X82.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X82.4 RUA E ESTRADA',
  'X82.5 AREAS DE COMERCIO E DE SERVICOS',
  'X82.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X82.7 FAZENDA',
  'X82.8 OUTR LOCAIS ESPEC',
  'X82.9 LOCAL NE',
  'X83.0 RESIDENCIA',
  'X83.1 HABITACAO COLETIVA',
  'X83.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X83.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X83.4 RUA E ESTRADA',
  'X83.5 AREAS DE COMERCIO E DE SERVICOS',
  'X83.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X83.7 FAZENDA',
  'X83.8 OUTR LOCAIS ESPEC',
  'X83.9 LOCAL NE',
  'X84.0 RESIDENCIA',
  'X84.1 HABITACAO COLETIVA',
  'X84.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X84.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X84.4 RUA E ESTRADA',
  'X84.5 AREAS DE COMERCIO E DE SERVICOS',
  'X84.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X84.7 FAZENDA',
  'X84.8 OUTR LOCAIS ESPEC',
  'X84.9 LOCAL NE',
  'X85.0 RESIDENCIA',
  'X85.1 HABITACAO COLETIVA',
  'X85.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X85.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X85.4 RUA E ESTRADA',
  'X85.5 AREAS DE COMERCIO E DE SERVICOS',
  'X85.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X85.7 FAZENDA',
  'X85.8 OUTR LOCAIS ESPEC',
  'X85.9 LOCAL NE',
  'X86.0 RESIDENCIA',
  'X86.1 HABITACAO COLETIVA',
  'X86.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X86.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X86.4 RUA E ESTRADA',
  'X86.5 AREAS DE COMERCIO E DE SERVICOS',
  'X86.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X86.7 FAZENDA',
  'X86.8 OUTR LOCAIS ESPEC',
  'X86.9 LOCAL NE',
  'X87.0 RESIDENCIA',
  'X87.1 HABITACAO COLETIVA',
  'X87.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X87.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X87.4 RUA E ESTRADA',
  'X87.5 AREAS DE COMERCIO E DE SERVICOS',
  'X87.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X87.7 FAZENDA',
  'X87.8 OUTR LOCAIS ESPEC',
  'X87.9 LOCAL NE',
  'X88.0 RESIDENCIA',
  'X88.1 HABITACAO COLETIVA',
  'X88.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X88.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X88.4 RUA E ESTRADA',
  'X88.5 AREAS DE COMERCIO E DE SERVICOS',
  'X88.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X88.7 FAZENDA',
  'X88.8 OUTR LOCAIS ESPEC',
  'X88.9 LOCAL NE',
  'X89.0 RESIDENCIA',
  'X89.1 HABITACAO COLETIVA',
  'X89.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X89.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X89.4 RUA E ESTRADA',
  'X89.5 AREAS DE COMERCIO E DE SERVICOS',
  'X89.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X89.7 FAZENDA',
  'X89.8 OUTR LOCAIS ESPEC',
  'X89.9 LOCAL NE',
  'X90.0 RESIDENCIA',
  'X90.1 HABITACAO COLETIVA',
  'X90.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X90.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X90.4 RUA E ESTRADA',
  'X90.5 AREAS DE COMERCIO E DE SERVICOS',
  'X90.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X90.7 FAZENDA',
  'X90.8 OUTR LOCAIS ESPEC',
  'X90.9 LOCAL NE',
  'X91.0 RESIDENCIA',
  'X91.1 HABITACAO COLETIVA',
  'X91.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X91.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X91.4 RUA E ESTRADA',
  'X91.5 AREAS DE COMERCIO E DE SERVICOS',
  'X91.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X91.7 FAZENDA',
  'X91.8 OUTR LOCAIS ESPEC',
  'X91.9 LOCAL NE',
  'X92.0 RESIDENCIA',
  'X92.1 HABITACAO COLETIVA',
  'X92.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X92.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X92.4 RUA E ESTRADA',
  'X92.5 AREAS DE COMERCIO E DE SERVICOS',
  'X92.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X92.7 FAZENDA',
  'X92.8 OUTR LOCAIS ESPEC',
  'X92.9 LOCAL NE',
  'X93.0 RESIDENCIA',
  'X93.1 HABITACAO COLETIVA',
  'X93.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X93.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X93.4 RUA E ESTRADA',
  'X93.5 AREAS DE COMERCIO E DE SERVICOS',
  'X93.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X93.7 FAZENDA',
  'X93.8 OUTR LOCAIS ESPEC',
  'X93.9 LOCAL NE',
  'X94.0 RESIDENCIA',
  'X94.1 HABITACAO COLETIVA',
  'X94.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X94.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X94.4 RUA E ESTRADA',
  'X94.5 AREAS DE COMERCIO E DE SERVICOS',
  'X94.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X94.7 FAZENDA',
  'X94.8 OUTR LOCAIS ESPEC',
  'X94.9 LOCAL NE',
  'X95.0 RESIDENCIA',
  'X95.1 HABITACAO COLETIVA',
  'X95.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X95.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X95.4 RUA E ESTRADA',
  'X95.5 AREAS DE COMERCIO E DE SERVICOS',
  'X95.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X95.7 FAZENDA',
  'X95.8 OUTR LOCAIS ESPEC',
  'X95.9 LOCAL NE',
  'X96.0 RESIDENCIA',
  'X96.1 HABITACAO COLETIVA',
  'X96.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X96.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X96.4 RUA E ESTRADA',
  'X96.5 AREAS DE COMERCIO E DE SERVICOS',
  'X96.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X96.7 FAZENDA',
  'X96.8 OUTR LOCAIS ESPEC',
  'X96.9 LOCAL NE',
  'X97.0 RESIDENCIA',
  'X97.1 HABITACAO COLETIVA',
  'X97.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X97.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X97.4 RUA E ESTRADA',
  'X97.5 AREAS DE COMERCIO E DE SERVICOS',
  'X97.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X97.7 FAZENDA',
  'X97.8 OUTR LOCAIS ESPEC',
  'X97.9 LOCAL NE',
  'X98.0 RESIDENCIA',
  'X98.1 HABITACAO COLETIVA',
  'X98.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X98.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X98.4 RUA E ESTRADA',
  'X98.5 AREAS DE COMERCIO E DE SERVICOS',
  'X98.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X98.7 FAZENDA',
  'X98.8 OUTR LOCAIS ESPEC',
  'X98.9 LOCAL NE',
  'X99.0 RESIDENCIA',
  'X99.1 HABITACAO COLETIVA',
  'X99.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'X99.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'X99.4 RUA E ESTRADA',
  'X99.5 AREAS DE COMERCIO E DE SERVICOS',
  'X99.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'X99.7 FAZENDA',
  'X99.8 OUTR LOCAIS ESPEC',
  'X99.9 LOCAL NE',
  'Y00.0 RESIDENCIA',
  'Y00.1 HABITACAO COLETIVA',
  'Y00.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y00.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y00.4 RUA E ESTRADA',
  'Y00.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y00.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y00.7 FAZENDA',
  'Y00.8 OUTR LOCAIS ESPEC',
  'Y00.9 LOCAL NE',
  'Y01.0 RESIDENCIA',
  'Y01.1 HABITACAO COLETIVA',
  'Y01.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y01.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y01.4 RUA E ESTRADA',
  'Y01.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y01.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y01.7 FAZENDA',
  'Y01.8 OUTR LOCAIS ESPEC',
  'Y01.9 LOCAL NE',
  'Y02.0 RESIDENCIA',
  'Y02.1 HABITACAO COLETIVA',
  'Y02.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y02.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y02.4 RUA E ESTRADA',
  'Y02.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y02.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y02.7 FAZENDA',
  'Y02.8 OUTR LOCAIS ESPEC',
  'Y02.9 LOCAL NE',
  'Y03.0 RESIDENCIA',
  'Y03.1 HABITACAO COLETIVA',
  'Y03.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y03.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y03.4 RUA E ESTRADA',
  'Y03.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y03.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y03.7 FAZENDA',
  'Y03.8 OUTR LOCAIS ESPEC',
  'Y03.9 LOCAL NE',
  'Y04.0 RESIDENCIA',
  'Y04.1 HABITACAO COLETIVA',
  'Y04.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y04.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y04.4 RUA E ESTRADA',
  'Y04.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y04.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y04.7 FAZENDA',
  'Y04.8 OUTR LOCAIS ESPEC',
  'Y04.9 LOCAL NE',
  'Y05.0 RESIDENCIA',
  'Y05.1 HABITACAO COLETIVA',
  'Y05.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y05.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y05.4 RUA E ESTRADA',
  'Y05.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y05.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y05.7 FAZENDA',
  'Y05.8 OUTR LOCAIS ESPEC',
  'Y05.9 LOCAL NE',
  'Y06.0 NEGLIGENCIA E ABANDONO P/ESPOSO COMPANHEIRO',
  'Y06.1 NEGLIGENCIA E ABANDONO P/PAIS',
  'Y06.2 NEGLIGENCIA E ABANDONO P/CONHECIDO OU AMIGO',
  'Y06.8 NEGLIGENCIA E ABANDONO P/OUTR PESSOA ESPEC',
  'Y06.9 NEGLIGENCIA E ABANDONO P/PESSOA NE',
  'Y07.0 OUTR SINDR MAUS TRATOS P/ESPOSO COMPANHEIRO',
  'Y07.1 OUTR SINDR DE MAUS TRATOS P/PAIS',
  'Y07.2 OUTR SINDR DE MAUS TRATOS P/CONHECIDO AMIGO',
  'Y07.3 OUTR SINDR DE MAUS TRATOS P/AUTOR OFICIAIS',
  'Y07.8 OUTR SINDR MAUS TRATOS P/OUTR PESSOA ESPEC',
  'Y07.9 OUTR SINDR DE MAUS TRATOS P/PESSOA NE',
  'Y08.0 RESIDENCIA',
  'Y08.1 HABITACAO COLETIVA',
  'Y08.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y08.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y08.4 RUA E ESTRADA',
  'Y08.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y08.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y08.7 FAZENDA',
  'Y08.8 OUTR LOCAIS ESPEC',
  'Y08.9 LOCAL NE',
  'Y09.0 RESIDENCIA',
  'Y09.1 HABITACAO COLETIVA',
  'Y09.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y09.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y09.4 RUA E ESTRADA',
  'Y09.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y09.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y09.7 FAZENDA',
  'Y09.8 OUTR LOCAIS ESPEC',
  'Y09.9 LOCAL NE',
  'Y10.0 RESIDENCIA',
  'Y10.1 HABITACAO COLETIVA',
  'Y10.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y10.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y10.4 RUA E ESTRADA',
  'Y10.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y10.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y10.7 FAZENDA',
  'Y10.8 OUTR LOCAIS ESPEC',
  'Y10.9 LOCAL NE',
  'Y11.0 RESIDENCIA',
  'Y11.1 HABITACAO COLETIVA',
  'Y11.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y11.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y11.4 RUA E ESTRADA',
  'Y11.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y11.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y11.7 FAZENDA',
  'Y11.8 OUTR LOCAIS ESPEC',
  'Y11.9 LOCAL NE',
  'Y12.0 RESIDENCIA',
  'Y12.1 HABITACAO COLETIVA',
  'Y12.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y12.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y12.4 RUA E ESTRADA',
  'Y12.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y12.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y12.7 FAZENDA',
  'Y12.8 OUTR LOCAIS ESPEC',
  'Y12.9 LOCAL NE',
  'Y13.0 RESIDENCIA',
  'Y13.1 HABITACAO COLETIVA',
  'Y13.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y13.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y13.4 RUA E ESTRADA',
  'Y13.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y13.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y13.7 FAZENDA',
  'Y13.8 OUTR LOCAIS ESPEC',
  'Y13.9 LOCAL NE',
  'Y14.0 RESIDENCIA',
  'Y14.1 HABITACAO COLETIVA',
  'Y14.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y14.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y14.4 RUA E ESTRADA',
  'Y14.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y14.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y14.7 FAZENDA',
  'Y14.8 OUTR LOCAIS ESPEC',
  'Y14.9 LOCAL NE',
  'Y15.0 RESIDENCIA',
  'Y15.1 HABITACAO COLETIVA',
  'Y15.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y15.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y15.4 RUA E ESTRADA',
  'Y15.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y15.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y15.7 FAZENDA',
  'Y15.8 OUTR LOCAIS ESPEC',
  'Y15.9 LOCAL NE',
  'Y16.0 RESIDENCIA',
  'Y16.1 HABITACAO COLETIVA',
  'Y16.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y16.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y16.4 RUA E ESTRADA',
  'Y16.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y16.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y16.7 FAZENDA',
  'Y16.8 OUTR LOCAIS ESPEC',
  'Y16.9 LOCAL NE',
  'Y17.0 RESIDENCIA',
  'Y17.1 HABITACAO COLETIVA',
  'Y17.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y17.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y17.4 RUA E ESTRADA',
  'Y17.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y17.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y17.7 FAZENDA',
  'Y17.8 OUTR LOCAIS ESPEC',
  'Y17.9 LOCAL NE',
  'Y18.0 RESIDENCIA',
  'Y18.1 HABITACAO COLETIVA',
  'Y18.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y18.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y18.4 RUA E ESTRADA',
  'Y18.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y18.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y18.7 FAZENDA',
  'Y18.8 OUTR LOCAIS ESPEC',
  'Y18.9 LOCAL NE',
  'Y19.0 RESIDENCIA',
  'Y19.1 HABITACAO COLETIVA',
  'Y19.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y19.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y19.4 RUA E ESTRADA',
  'Y19.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y19.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y19.7 FAZENDA',
  'Y19.8 OUTR LOCAIS ESPEC',
  'Y19.9 LOCAL NE',
  'Y20.0 RESIDENCIA',
  'Y20.1 HABITACAO COLETIVA',
  'Y20.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y20.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y20.4 RUA E ESTRADA',
  'Y20.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y20.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y20.7 FAZENDA',
  'Y20.8 OUTR LOCAIS ESPEC',
  'Y20.9 LOCAL NE',
  'Y21.0 RESIDENCIA',
  'Y21.1 HABITACAO COLETIVA',
  'Y21.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y21.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y21.4 RUA E ESTRADA',
  'Y21.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y21.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y21.7 FAZENDA',
  'Y21.8 OUTR LOCAIS ESPEC',
  'Y21.9 LOCAL NE',
  'Y22.0 RESIDENCIA',
  'Y22.1 HABITACAO COLETIVA',
  'Y22.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y22.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y22.4 RUA E ESTRADA',
  'Y22.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y22.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y22.7 FAZENDA',
  'Y22.8 OUTR LOCAIS ESPEC',
  'Y22.9 LOCAL NE',
  'Y23.0 RESIDENCIA',
  'Y23.1 HABITACAO COLETIVA',
  'Y23.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y23.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y23.4 RUA E ESTRADA',
  'Y23.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y23.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y23.7 FAZENDA',
  'Y23.8 OUTR LOCAIS ESPEC',
  'Y23.9 LOCAL NE',
  'Y24.0 RESIDENCIA',
  'Y24.1 HABITACAO COLETIVA',
  'Y24.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y24.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y24.4 RUA E ESTRADA',
  'Y24.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y24.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y24.7 FAZENDA',
  'Y24.8 OUTR LOCAIS ESPEC',
  'Y24.9 LOCAL NE',
  'Y25.0 RESIDENCIA',
  'Y25.1 HABITACAO COLETIVA',
  'Y25.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y25.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y25.4 RUA E ESTRADA',
  'Y25.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y25.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y25.7 FAZENDA',
  'Y25.8 OUTR LOCAIS ESPEC',
  'Y25.9 LOCAL NE',
  'Y26.0 RESIDENCIA',
  'Y26.1 HABITACAO COLETIVA',
  'Y26.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y26.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y26.4 RUA E ESTRADA',
  'Y26.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y26.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y26.7 FAZENDA',
  'Y26.8 OUTR LOCAIS ESPEC',
  'Y26.9 LOCAL NE',
  'Y27.0 RESIDENCIA',
  'Y27.1 HABITACAO COLETIVA',
  'Y27.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y27.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y27.4 RUA E ESTRADA',
  'Y27.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y27.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y27.7 FAZENDA',
  'Y27.8 OUTR LOCAIS ESPEC',
  'Y27.9 LOCAL NE',
  'Y28.0 RESIDENCIA',
  'Y28.1 HABITACAO COLETIVA',
  'Y28.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y28.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y28.4 RUA E ESTRADA',
  'Y28.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y28.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y28.7 FAZENDA',
  'Y28.8 OUTR LOCAIS ESPEC',
  'Y28.9 LOCAL NE',
  'Y29.0 RESIDENCIA',
  'Y29.1 HABITACAO COLETIVA',
  'Y29.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y29.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y29.4 RUA E ESTRADA',
  'Y29.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y29.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y29.7 FAZENDA',
  'Y29.8 OUTR LOCAIS ESPEC',
  'Y29.9 LOCAL NE',
  'Y30.0 RESIDENCIA',
  'Y30.1 HABITACAO COLETIVA',
  'Y30.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y30.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y30.4 RUA E ESTRADA',
  'Y30.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y30.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y30.7 FAZENDA',
  'Y30.8 OUTR LOCAIS ESPEC',
  'Y30.9 LOCAL NE',
  'Y31.0 RESIDENCIA',
  'Y31.1 HABITACAO COLETIVA',
  'Y31.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y31.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y31.4 RUA E ESTRADA',
  'Y31.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y31.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y31.7 FAZENDA',
  'Y31.8 OUTR LOCAIS ESPEC',
  'Y31.9 LOCAL NE',
  'Y32.0 RESIDENCIA',
  'Y32.1 HABITACAO COLETIVA',
  'Y32.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y32.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y32.4 RUA E ESTRADA',
  'Y32.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y32.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y32.7 FAZENDA',
  'Y32.8 OUTR LOCAIS ESPEC',
  'Y32.9 LOCAL NE',
  'Y33.0 RESIDENCIA',
  'Y33.1 HABITACAO COLETIVA',
  'Y33.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y33.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y33.4 RUA E ESTRADA',
  'Y33.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y33.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y33.7 FAZENDA',
  'Y33.8 OUTR LOCAIS ESPEC',
  'Y33.9 LOCAL NE',
  'Y34.0 RESIDENCIA',
  'Y34.1 HABITACAO COLETIVA',
  'Y34.2 ESCOLAS OUTR INSTIT AREAS ADMIN PUBLICA',
  'Y34.3 AREA P/A PRATICA DE ESPORTES E ATLETISMO',
  'Y34.4 RUA E ESTRADA',
  'Y34.5 AREAS DE COMERCIO E DE SERVICOS',
  'Y34.6 AREAS INDUSTRIAIS E EM CONSTRUCAO',
  'Y34.7 FAZENDA',
  'Y34.8 OUTR LOCAIS ESPEC',
  'Y34.9 LOCAL NE',
  'Y35.0 INTERVENCAO LEGAL ENVOLV USO ARMAS DE FOGO',
  'Y35.1 INTERVENCAO LEGAL ENVOLV O USO DE EXPLOSIVOS',
  'Y35.2 INTERVENCAO LEGAL ENVOLV O USO DE GAS',
  'Y35.3 INTERVENCAO LEGAL ENVOLV USO OBJ CONTUND',
  'Y35.4 INTERVENCAO LEGAL ENVOLV USO OBJ CORT PENETR',
  'Y35.5 EXECUCAO LEGAL',
  'Y35.6 INTERVENCAO LEGAL ENVOLV USO OUTR MEIO ESPEC',
  'Y35.7 INTERVENCAO LEGAL MEIO NE',
  'Y36.0 OPER GUERRA ENVOLV EXPLOSAO ARMAMENTO NAVAL',
  'Y36.1 OPER GUERRA ENVOLV DESTRUICAO DE AERONAVE',
  'Y36.2 OPER GUERRA ENVOLV OUTR EXPLOSOES FRAGMENTOS',
  'Y36.3 OPER GUERRA ENVOLV INCEND FOGO SUBST QUENTES',
  'Y36.4 OPER GUER ENV DISP ARM FOG OUT FOR CONV COMB',
  'Y36.5 OPER GUERRA ENVOLV ARMAMENTO NUCLEAR',
  'Y36.6 OPER GUERRA ENVOLV ARMAS BIOLOGICAS',
  'Y36.7 OPER GUER ENV ARM QUIM OUT MEIOS COMB N-CONV',
  'Y36.8 OPER GUERRA OCORR APOS CESSACAO HOSTILIDADES',
  'Y36.9 OPERACOES DE GUERRA NE',
  'Y40.0 PENICILINAS',
  'Y40.1 CEFALOSPORINAS E OUTR BETALACTAMINAS',
  'Y40.2 GRUPO DO CLORANFENICOL',
  'Y40.3 MACROLIDEOS',
  'Y40.4 TETRACICLINAS',
  'Y40.5 AMINOGLICOSIDEOS',
  'Y40.6 RIFAMICINAS',
  'Y40.7 ANTIBIOTICOS ANTIFUNGICOS ADMIN P/VIA SISTEM',
  'Y40.8 OUTR ANTIBIOTICOS ADMIN P/VIA SISTEMICA',
  'Y40.9 ANTIBIOTICO SISTEMICO NE',
  'Y41.0 SULFONAMIDAS',
  'Y41.1 DROGAS ANTIMICOBACTERIANAS',
  'Y41.2 ANTIMALAR DROG ATUAM OUT PROTOZ PARASIT SANG',
  'Y41.3 OUTR DROGAS ANTIPROTOZOARIOS',
  'Y41.4 ANTI-HELMINTICOS',
  'Y41.5 DROGAS ANTIVIRAIS',
  'Y41.8 OUTR ANTIINFECC E ANTIPARASITARIOS ESPEC',
  'Y41.9 ANTIINFECC E ANTIPARASITARIOS SISTEMICOS NE',
  'Y42.0 GLICOCORTICOIDES E ANALOGOS SINTETICOS',
  'Y42.1 HORMONIOS E SUBSTITUTOS DA TIREOIDE',
  'Y42.2 DROGAS ANTITIREOIDIANAS',
  'Y42.3 INSULINA E HIPOGLICEMICOS ORAIS',
  'Y42.4 ANTICONCEPCIONAIS ORAIS',
  'Y42.5 OUTR ESTROGENOS E PROGESTAGENOS',
  'Y42.6 ANTIGONADOTROF ANTIESTROG ANTIANDROG NCOP',
  'Y42.7 ANDROGENOS E ANABOLIZANTES CONGENERES',
  'Y42.8 OUTR HORMONIOS E NE E SEUS SUBST SINTETICOS',
  'Y42.9 OUTR ANTOGONISTAS HORMONAIS E OS NE',
  'Y43.0 DROGAS ANTIALERGICAS E ANTIEMETICAS',
  'Y43.1 ANTIMETABOLICOS ANTINEOPLASICOS',
  'Y43.2 PRODUTOS NATURAIS ANTINEOPLASICOS',
  'Y43.3 OUTR DROGAS ANTINEOPLASICAS',
  'Y43.4 AGENTES IMUNOSSUPRESSORES',
  'Y43.5 ACIDIFICANTES E ALCALINIZANTES',
  'Y43.6 ENZIMAS NCOP',
  'Y43.8 OUTR SUBST FARMAC DE ACAO PRIM SISTEM NCOP',
  'Y43.9 SUBST FARMACOLOGICA DE ACAO SISTEMICA NE',
  'Y44.0 PREP BASE FERRO OUTR PREP PRESCR ANEM HIPOCR',
  'Y44.1 VIT B12 ACID FOLIC OUT PREP PRESC ANEM MEGAL',
  'Y44.2 ANTICOAGULANTES',
  'Y44.3 ANTAGONIST ANTICOAG VITAM K OUTR COAGULANTES',
  'Y44.4 DROGAS ANTITROMBOTICAS',
  'Y44.5 TROMBOLITICOS',
  'Y44.6 SANGUE NATURAL E PRODUTOS DO SANGUE',
  'Y44.7 SUBSTITUTOS DO PLASMA',
  'Y44.9 OUTR SUBST FARM ATUAM S/CONST SANGUE E AS NE',
  'Y45.0 OPIACEOS E ANALGESICOS CORRELATOS',
  'Y45.1 SALICILATOS',
  'Y45.2 DERIVADOS DO ACIDO PROPIONICO',
  'Y45.3 OUTR DROGAS ANTIINFLAMATORIAS NAO-ESTEROIDES',
  'Y45.4 ANTI-REUMATICOS',
  'Y45.5 DERIVADOS DO 4-AMINOFENOL',
  'Y45.8 OUTR ANALGESICOS E ANTIPIRETICOS',
  'Y45.9 SUBST ANALGESICAS E ANTIPIRETICAS NE',
  'Y46.0 SUCCIMIDAS',
  'Y46.1 OXAZOLIDINAS',
  'Y46.2 DERIVADOS DA HIDANTOINA',
  'Y46.3 DESOXIBARBITURICOS',
  'Y46.4 IMINOSTILBENOS',
  'Y46.5 ACIDO VALPROICO',
  'Y46.6 OUTR ANTICONVULSIVANTES E OS NE',
  'Y46.7 DROGAS ANTIPARKINSONIANAS',
  'Y46.8 SUBST MIORRELAXANTES',
  'Y47.0 BARBITURICOS NCOP',
  'Y47.1 BENZODIAZEPINICOS',
  'Y47.2 DERIVADOS DO CLORAL',
  'Y47.3 PARALDEIDO',
  'Y47.4 COMPOSTOS DE BROMO',
  'Y47.5 SEDATIVOS E HIPNOTICOS EM ASSOCIACOES NCOP',
  'Y47.8 OUTR SEDATIVOS HIPNOTICOS E TRANQUILIZANTES',
  'Y47.9 SEDATIVOS HIPNOTICOS E TRANQUILIZANTES NE',
  'Y48.0 ANESTESICOS P/INALACAO',
  'Y48.1 ANESTESICOS P/VIA PARENTERAL',
  'Y48.2 OUTR ANESTESICOS GERAIS E OS NE',
  'Y48.3 ANESTESICOS LOCAIS',
  'Y48.4 ANESTESICO NE',
  'Y48.5 GASES TERAPEUTICOS',
  'Y49.0 ANTIDEPRESSIVOS TRICICLOS E TETRACICLICOS',
  'Y49.1 ANTIDEPRESS INIBIDORES DA MONO-AMINO-OXIDASE',
  'Y49.2 OUTR ANTIDEPRESSIVOS E OS NE',
  'Y49.3 ANTIPSICOTICOS NEUROLEPTICOS FENOTIAZINICOS',
  'Y49.4 NEUROLEPTICOS BASE BUTIROFENONA TIOXANTENO',
  'Y49.5 OUTR ANTIPSICOTICOS E NEUROLEPTICOS',
  'Y49.6 PSICODISLEPTICOS',
  'Y49.7 PSICOESTIMUL QUE PODEM PROVOCAR DEPENDENCIA',
  'Y49.8 OUTR SUBST PSICOTROPICAS NCOP',
  'Y49.9 SUBST PSICOTROPICA NE',
  'Y50.0 ANALEPTICOS',
  'Y50.1 ANTAGONISTAS DE RECEPTORES DOS OPIACEOS',
  'Y50.2 METILXANTINAS NCOP',
  'Y50.8 OUTR ESTIMULANTES DO SIST NERVOSO CENTRAL',
  'Y50.9 ESTIMULANTE DO SIST NERVOSO CENTRAL NE',
  'Y51.0 AGENTES ANTICOLINESTERASICOS',
  'Y51.1 OUTR PARASIMPATICOMIMETICOS',
  'Y51.2 GANGLIOPLEGICOS NCOP',
  'Y51.3 OUTR PARASSIMPATICOLIT E ESPASMOLITICOS NCOP',
  'Y51.4 AGONISTAS PREDOM ALFA-ADRENORRECEPTORES NCOP',
  'Y51.5 AGONISTAS PREDOM BETA-ADRENORRECEPTORES NCOP',
  'Y51.6 ANTAGONISTAS ALFA-ADRENORRECEPTORES NCOP',
  'Y51.7 ANTAGONISTAS BETA-ADRENORRECEPTORES NCOP',
  'Y51.8 BLOQUEADORES NERV ACAO CENTR ADRENERG NCOP',
  'Y51.9 OUTR DROG ATUAM PRIM S/SIST NERV AUTON E NE',
  'Y52.0 GLICOSIDEOS ESTIM CORACAO SUBST ACAO SEMELH',
  'Y52.1 ANTAGONISTAS DO CALCIO',
  'Y52.2 OUTR DROGAS ANTIDISRRITMICAS NCOP',
  'Y52.3 VASODILATADORES CORONARIANOS NCOP',
  'Y52.4 INIBIDORES ENZIMA DE CONVERSAO ANGIOTENSINA',
  'Y52.5 OUTR DROGAS ANTI-HIPERTENSIVAS NCOP',
  'Y52.6 DROGAS ANTI-HIPERLIPIDEM ANTIARTERIOSCLEROT',
  'Y52.7 VASODILATADORES PERIFERICOS',
  'Y52.8 DROGAS ANTIVARICOSAS INCL AGENT ESCLEROSANT',
  'Y52.9 OUTR DROG ATUAM PRIM AP CARDIOVASC E AS NE',
  'Y53.0 ANTAGONISTAS DOS RECEPTORES H2 DA HISTAMINA',
  'Y53.1 OUTR MEDIC ANTIACIDOS ANTI-SECRECAO GASTRICA',
  'Y53.2 LAXATIVOS ESTIMULANTES',
  'Y53.3 LAXATIVOS SALINOS E OSMOTICOS',
  'Y53.4 OUTR LAXATIVOS',
  'Y53.5 MEDICAMENTOS QUE FACILITAM A DIGESTAO',
  'Y53.6 ANTIDIARREICOS',
  'Y53.7 EMETICOS',
  'Y53.8 OUTR SUBST QUE ATUAM PRIM AP GASTROINTEST',
  'Y53.9 SUBST ATUAM PRIM APARELHO GASTROINTEST NE',
  'Y54.0 MINERALOCORTICOIDES',
  'Y54.1 ANTAGONISTAS DOS MINERALOCORTICOIDES',
  'Y54.2 INIBIDORES DA ANIDRASE CARBONICA',
  'Y54.3 DERIVADOS DA BENZOTIADIAZINA',
  'Y54.4 DIURETICOS DE ALCA',
  'Y54.5 OUTR DIURETICOS',
  'Y54.6 AG ATUAM EQUILIBR ELETROLIT CALORICO HIDRICO',
  'Y54.7 MEDIC ATUAM SOBRE O METABOLISMO DE CALCIO',
  'Y54.8 DROGAS ATUAM SOBRE METABOLISMO ACIDO URICO',
  'Y54.9 SAIS MINERAIS NCOP',
  'Y55.0 OCITOCICOS',
  'Y55.1 RELAXANTES DA MUSCULATURA ESQUELETICA',
  'Y55.2 OUTR SUBST QUE ATUAM PRIM MUSCULOS E AS NE',
  'Y55.3 ANTITUSSICOS',
  'Y55.4 EXPECTORANTES',
  'Y55.5 DROGAS ANTI-RESFRIADO COMUM',
  'Y55.6 ANTIASMATICOS NCOP',
  'Y55.9 OUTR DROGAS QUE ATUAM APARELHO RESPIRAT E NE',
  'Y56.0 DROG ANTIFUNG ANTIINF ANTIINFL USO LOC NCOP',
  'Y56.1 ANTIPRURIGINOSOS',
  'Y56.2 ADSTRINGENTES E DETERGENTES LOCAIS',
  'Y56.3 EMOLIENTES SUAVIZANTES E PROTETORES DA PELE',
  'Y56.4 CERATOL CERATOPL OUTR MED PREP CAPILARES',
  'Y56.5 MEDIC E PREPARACOES DE USO OFTALMOLOGICO',
  'Y56.6 MEDIC PREP USADOS EM OTORRINOLARINGOLOGIA',
  'Y56.7 DROGAS DE USO DENTARIO APLICADAS TOPICAMENTE',
  'Y56.8 OUTR SUBST DE USO TOPICO',
  'Y56.9 SUBST NE DE USO TOPICO',
  'Y57.0 DEPRESSORES DO APETITE',
  'Y57.1 LIPOTROPICOS',
  'Y57.2 ANTIDOTOS E AGENTES QUELANTES NCOP',
  'Y57.3 SUBST P/RESTRINGIR A INGESTAO DE ALCOOL',
  'Y57.4 EXCEPIENTES FARMACEUTICOS',
  'Y57.5 SUBST UTILIZADAS COMO CONTRASTE RADIOLOGIA',
  'Y57.6 OUTR SUBST P/FINS DIAGN',
  'Y57.7 VITAMINAS NCOP',
  'Y57.8 OUTR DROGAS E MEDICAMENTOS',
  'Y57.9 DROGA E MEDICAMENTO NE',
  'Y58.0 VACINA BCG',
  'Y58.1 VACINAS ANTITIFOIDICA E ANTIPARAFOIDICA',
  'Y58.2 VACINA ANTICOLERICA',
  'Y58.3 VACINA ANTIPESTE',
  'Y58.4 VACINA ANTITETANICA',
  'Y58.5 VACINA ANTIDIFTERICA',
  'Y58.6 VACINA ANTIPERTUSSIS INCL COMBINACOES',
  'Y58.8 VACINAS BACTER MISTAS EXC INCL ANTIPERTUSSIS',
  'Y58.9 OUTR VACINAS BACTER E AS NE',
  'Y59.0 VACINAS ANTIVIRAIS',
  'Y59.1 VACINAS ANTI-RICKETTSIAS',
  'Y59.2 VACINAS ANTIPROTOZOARIOS',
  'Y59.3 IMUNOGLOBULINA',
  'Y59.8 OUTR VACINAS E SUBST BIOLOGICAS ESPEC',
  'Y59.9 VACINA OU SUBST BIOLOGICA NE',
  'Y60.0 DURANTE INTERVENCAO CIRURGICA',
  'Y60.1 DURANTE INFUSAO OU TRANSFUSAO',
  'Y60.2 DURANTE HEMODIALISE OU OUTR PERFUSOES',
  'Y60.3 DURANTE INJECAO OU VACINACAO',
  'Y60.4 DURANTE EXAME ENDOSCOPICO',
  'Y60.5 DURANTE CATETERISMO CARDIACO',
  'Y60.6 DURANTE ASPIRACAO PUNCAO OUTR TIPO CATETER',
  'Y60.7 DURANTE A ADMIN DE ENEMA',
  'Y60.8 DURANTE PREST OUTR CUIDADOS CIRURG E MEDIC',
  'Y60.9 DURANTE PREST CUIDADO CIRURGICO E MEDICO NE',
  'Y61.0 DURANTE INTERVENCAO CIRURGICA',
  'Y61.1 DURANTE INFUSAO OU TRANSFUSAO',
  'Y61.2 DURANTE HEMODIALISE OU OUTR PERFUSOES',
  'Y61.3 DURANTE INJECAO OU VACINACAO',
  'Y61.4 DURANTE EXAME ENDOSCOPICO',
  'Y61.5 DURANTE CATETERISMO CARDIACO',
  'Y61.6 DURANTE ASPIRACAO PUNCAO OUTR TIPO CATETER',
  'Y61.7 DURANTE EXTRACAO CATETER OU COMPRESSAS',
  'Y61.8 DURANTE PREST OUTR CUIDADO CIRURG E MEDICO',
  'Y61.9 DURANTE PREST CUIDADO CIRURGICO E MEDICO NE',
  'Y62.0 DURANTE INTERVENCAO CIRURGICA',
  'Y62.1 DURANTE INFUSAO OU TRANSFUSAO',
  'Y62.2 DURANTE HEMODIALISE OU OUTR PERFUSOES',
  'Y62.3 DURANTE INJECAO OU VACINACAO',
  'Y62.4 DURANTE EXAME ENDOSCOPICO',
  'Y62.5 DURANTE CATETERISMO CARDIACO',
  'Y62.6 DURANTE ASPIRACAO PUNCAO OUTR TIPO CATETER',
  'Y62.8 DURANTE PREST OUTR CUIDADO CIRURG E MEDICO',
  'Y62.9 DURANTE PREST CUIDADO CIRURGICO E MEDICO NE',
  'Y63.0 ADMIN QUANT EXCESS SANG OUTR LIQ TRANSF INF',
  'Y63.1 DILUICAO INCORR LIQUIDO USADO DURANTE INF',
  'Y63.2 SUPERDOSAGEM RADIACAO ADMIN DURANTE TERAPIA',
  'Y63.3 EXPOSICAO INADV RAD DURANTE PREST CUID MEDIC',
  'Y63.4 ERRO DOSAGEM ELETROCHOQUE CHOQUE INSULINICO',
  'Y63.5 TEMPERAT INADEQ DURANTE APLIC LOCAL CURATIVO',
  'Y63.6 NAO ADMIN DROGA MEDIC SUBST BIOLOG NECESS',
  'Y63.8 ERROS DOSAGEM PREST OUTR CUIDADO MED CIRURG',
  'Y63.9 ERROS DOSAGEM PREST CUID MEDICO CIRURGICO NE',
  'Y64.0 TRANSF INFUSAO MEDIC SUBST BIOLOG CONTAMIN',
  'Y64.1 INJECAO VACINAC MEDIC SUBST BIOLOG CONTAMIN',
  'Y64.8 ADMIN OUTR MEIOS MEDIC SUBST BIOLOG CONTAMIN',
  'Y64.9 MEDIC SUBST BIOLOG COMTAM ADMIN P/MEIOS NE',
  'Y65.0 USO DE SANGUE INCOMPATIVEL EM TRANSFUSAO',
  'Y65.1 USO DE LIQUIDO ERRADO EM INFUSAO',
  'Y65.2 FALHA SUTURA LIGADURA DURANTE INTERV CIRURG',
  'Y65.3 CANULA ENDOTRAQ MAL POSIC PROCED ANESTESICO',
  'Y65.4 FALHA INTROD REMOCAO OUTR CANULAS INSTRUM',
  'Y65.5 REALIZACAO DE UMA OPERACAO INAPROPRIADA',
  'Y65.8 OUTR ACID ESPEC PREST CUIDADO MEDIC CIRURG',
  'Y66   NAO ADMIN DE CUIDADO MEDICO E CIRURGICO',
  'Y69   ACID NE DURANTE PREST CUIDADO MEDIC CIRURG',
  'Y70.0 DISP P/FINS DIAGN OU DE MONITORIZACAO',
  'Y70.1 DISP P/FINS TERAP APARELHAGEM REABILITACAO',
  'Y70.2 PROTESES OUTR IMPL INCL MATERIAIS ACESSORIOS',
  'Y70.3 INSTRUMENTOS MATERIAIS APARELHOS CIRURGICOS',
  'Y70.8 DISP DIVERSOS INCL COMBINACOES NCOP',
  'Y71.0 DISP P/FINS DIAGN OU DE MONITORIZACAO',
  'Y71.1 DISP P/FINS TERAP APARELHAGEM REABILITACAO',
  'Y71.2 PROTESES OUTR IMPL INCL MATERIAIS ACESSORIOS',
  'Y71.3 INSTRUMENTOS MATERIAIS APARELHOS CIRURGICOS',
  'Y71.8 DISP DIVERSOS INCL COMBINACOES NCOP',
  'Y72.0 DISP P/FINS DIAGN OU DE MONITORIZACAO',
  'Y72.1 DISP P/FINS TERAP APARELHAGEM REABILITACAO',
  'Y72.2 PROTESES OUTR IMPL INCL MATERIAIS ACESSORIOS',
  'Y72.3 INSTRUMENTOS MATERIAIS APARELHOS CIRURGICOS',
  'Y72.8 DISP DIVERSOS INCL COMBINACOES NCOP',
  'Y73.0 DISP P/FINS DIAGN OU DE MONITORIZACAO',
  'Y73.1 DISP P/FINS TERAP APARELHAGEM REABILITACAO',
  'Y73.2 PROTESES OUTR IMPL INCL MATERIAIS ACESSORIOS',
  'Y73.3 INSTRUMENTOS MATERIAIS APARELHOS CIRURGICOS',
  'Y73.8 DISP DIVERSOS INCL COMBINACOES NCOP',
  'Y74.0 DISP P/FINS DIAGN OU DE MONITORIZACAO',
  'Y74.1 DISP P/FINS TERAP APARELHAGEM REABILITACAO',
  'Y74.2 PROTESES OUTR IMPL INCL MATERIAIS ACESSORIOS',
  'Y74.3 INSTRUMENTOS MATERIAIS APARELHOS CIRURGICOS',
  'Y74.8 DISP DIVERSOS INCL COMBINACOES NCOP',
  'Y75.0 DISP P/FINS DIAGN OU DE MONITORIZACAO',
  'Y75.1 DISP P/FINS TERAP APARELHAGEM REABILITACAO',
  'Y75.2 PROTESES OUTR IMPL INCL MATERIAIS ACESSORIOS',
  'Y75.3 INSTRUMENTOS MATERIAIS APARELHOS CIRURGICOS',
  'Y75.8 DISP DIVERSOS INCL COMBINACOES NCOP',
  'Y76.0 DISP P/FINS DIAGN OU DE MONITORIZACAO',
  'Y76.1 DISP P/FINS TERAP APARELHAGEM REABILITACAO',
  'Y76.2 PROTESES OUTR IMPL INCL MATERIAIS ACESSORIOS',
  'Y76.3 INSTRUMENTOS MATERIAIS APARELHOS CIRURGICOS',
  'Y76.8 DISP DIVERSOS INCL COMBINACOES NCOP',
  'Y77.0 DISP P/FINS DIAGN OU DE MONITORIZACAO',
  'Y77.1 DISP P/FINS TERAP APARELHAGEM REABILITACAO',
  'Y77.2 PROTESES OUTR IMPL INCL MATERIAIS ACESSORIOS',
  'Y77.3 INSTRUMENTOS MATERIAIS APARELHOS CIRURGICOS',
  'Y77.8 DISP DIVERSOS INCL COMBINACOES NCOP',
  'Y78.0 DISP P/FINS DIAGN OU DE MONITORIZACAO',
  'Y78.1 DISP P/FINS TERAP APARELHAGEM REABILITACAO',
  'Y78.2 PROTESES OUTR IMPL INCL MATERIAIS ACESSORIOS',
  'Y78.3 INSTRUMENTOS MATERIAIS APARELHOS CIRURGICOS',
  'Y78.8 DISP DIVERSOS INCL COMBINACOES NCOP',
  'Y79.0 DISP P/FINS DIAGN OU DE MONITORIZACAO',
  'Y79.1 DISP P/FINS TERAP APARELHAGEM REABILITACAO',
  'Y79.2 PROTESES OUTR IMPL INCL MATERIAIS ACESSORIOS',
  'Y79.3 INSTRUMENTOS MATERIAIS APARELHOS CIRURGICOS',
  'Y79.8 DISP DIVERSOS INCL COMBINACOES NCOP',
  'Y80.0 DISP P/FINS DIAGN OU DE MONITORIZACAO',
  'Y80.1 DISP P/FINS TERAP APARELHAGEM REABILITACAO',
  'Y80.2 PROTESES OUTR IMPL INCL MATERIAIS ACESSORIOS',
  'Y80.3 INSTRUMENTOS MATERIAIS APARELHOS CIRURGICOS',
  'Y80.8 DISP DIVERSOS INCL COMBINACOES NCOP',
  'Y81.0 DISP P/FINS DIAGN OU DE MONITORIZACAO',
  'Y81.1 DISP P/FINS TERAP APARELHAGEM REABILITACAO',
  'Y81.2 PROTESES OUTR IMPL INCL MATERIAIS ACESSORIOS',
  'Y81.3 INSTRUMENTOS MATERIAIS APARELHOS CIRURGICOS',
  'Y81.8 DISP DIVERSOS INCL COMBINACOES NCOP',
  'Y82.0 DISP P/FINS DIAGN OU DE MONITORIZACAO',
  'Y82.1 DISP P/FINS TERAP APARELHAGEM REABILITACAO',
  'Y82.2 PROTESES OUTR IMPL INCL MATERIAIS ACESSORIOS',
  'Y82.3 INSTRUMENTOS MATERIAIS APARELHOS CIRURGICOS',
  'Y82.8 DISP DIVERSOS INCL COMBINACOES NCOP',
  'Y83.0 INTERV CIRURG TRANSPLANTE TODO O ORGAO',
  'Y83.1 INTERV CIRURG IMPLANTE DE PROTESE INTERNA',
  'Y83.2 INTERV CIRURG ANASTOMOSE DERIV OU ENXERTO',
  'Y83.3 INTERV CIRURG FORMACAO DE ESTOMA EXTERNO',
  'Y83.4 OUTR CIRURGIAS REPARADORAS',
  'Y83.5 AMPUT DE MEMBROS',
  'Y83.6 REMOCAO DE UM OUTR ORGAO',
  'Y83.8 OUTR INTERVENCOES OU PROCED CIRURGICOS',
  'Y83.9 INTERVENCAO CIRURGICA NE',
  'Y84.0 CATETERISMO CARDIACO',
  'Y84.1 HEMODIALISE',
  'Y84.2 PROCED RADIOLOGICO E RADIOTERAPIA',
  'Y84.3 TERAPIA P/CHOQUE',
  'Y84.4 ASPIRACAO DE LIQUIDO',
  'Y84.5 COLOCACAO DE SONDA GASTRICA OU DUODENAL',
  'Y84.6 CATETERISMO URINARIO',
  'Y84.7 AMOSTRA DE SANGUE',
  'Y84.8 OUTR PROCED MEDICOS',
  'Y84.9 PROCED MEDICO NE',
  'Y85.0 SEQUELAS DE UM ACID DE VEIC A MOTOR',
  'Y85.9 SEQUELAS DE OUTR ACID TRANSP E DOS NE',
  'Y86   SEQUELAS DE OUTR ACID',
  'Y87.0 SEQUELAS DE LESOES AUTOPROVOCADAS INTENC',
  'Y87.1 SEQUELAS DE UMA AGRESSAO',
  'Y87.2 SEQUELAS FATO CUJA INTENCAO E INDETERMINADA',
  'Y88.0 SEQ EFEIT ADV DROG MEDIC SUBST BIOL TERAPEUT',
  'Y88.1 SEQUELAS ACID PREST CUIDADO MEDICO CIRURGICO',
  'Y88.2 SEQ INCID ADV USO DISP MED ATOS DIAGN TERAP',
  'Y88.3 SEQ REAC ANORM COMP TARD PROC MED CIR S/ACID',
  'Y89.0 SEQUELAS DE INTERVENCAO LEGAL',
  'Y89.1 SEQUELAS DE OPERACOES DE GUERRA',
  'Y89.9 SEQUELAS DE CAUSA EXTERNA NE',
  'Y90.0 ALCOOLEMIA INFER A 20 MG/100 ML',
  'Y90.1 ALCOOLEMIA DE 20-39 MG/100ML',
  'Y90.2 ALCOOLEMIA DE 40-59 MG/100ML',
  'Y90.3 ALCOOLEMIA DE 60-79 MG/100ML',
  'Y90.4 ALCOOLEMIA DE 80-99 MG/100ML',
  'Y90.5 ALCOOLEMIA DE 100-119 MG/100ML',
  'Y90.6 ALCOOLEMIA DE 120-199 MG/100ML',
  'Y90.7 ALCOOLEMIA DE 200-239 MG/100ML',
  'Y90.8 ALCOOLEMIA IGUAL OU SUPER A 240 MG/100ML',
  'Y90.9 PRESENCA DE ALCOOL NO SANGUE TAXA NE',
  'Y91.0 INTOX ALCOOLICA LEVE',
  'Y91.1 INTOX ALCOOLICA MODERADA',
  'Y91.2 INTOX ALCOOLICA GRAVE',
  'Y91.3 INTOX ALCOOLICA MUITO GRAVE',
  'Y91.9 ENVOLV C/ALCOOL NE DE OUTR FORM',
  'Y95   CIRCUNST RELAT CONDICOES NOSOCOMIAIS',
  'Y96   CIRCUNST RELAT CONDICOES DE TRABALHO',
  'Y97   CIRCUNST RELAT CONDICOES POLUICAO AMBIENTAL',
  'Y98   CIRCUNST RELAT CONDICOES DO MODO DE VIDA',
  'Z00.0 EXAME MEDICO GERAL',
  'Z00.1 EXAME DE ROTINA DE SAUDE DA CRIANCA',
  'Z00.2 EXAME PERIODO CRESCIMENTO RAPIDO NA INFANCIA',
  'Z00.3 EXAME ADOLESCENTE DURANTE CRESC PUBERDADE',
  'Z00.4 EXAME PSIQUIATRICO GERAL NCOP',
  'Z00.5 EXAME DE DOADOR POTENCIAL DE ORGAO E TEC',
  'Z00.6 EXAME P/COMP CONTROLE NORM PROGR INVEST CLIN',
  'Z00.8 OUTR EXAMES GERAIS',
  'Z01.0 EXAME DOS OLHOS E DA VISAO',
  'Z01.1 EXAME DOS OUVIDOS E DA AUDICAO',
  'Z01.2 EXAME DENTARIO',
  'Z01.3 EXAME DA PRESSAO ARTERIAL',
  'Z01.4 EXAME GINECOLOGICO',
  'Z01.5 TESTES CUTANEOS DE DIAGN E SENSIBILIZACAO',
  'Z01.6 EXAME RADIOLOGICO NCOP',
  'Z01.7 EXAME DE LABORATORIO',
  'Z01.8 OUTR EXAMES ESPECIAIS ESPEC',
  'Z01.9 EXAME ESPECIAL NE',
  'Z02.0 EXAME P/ADMISSAO A INSTITUICAO EDUCACIONAL',
  'Z02.1 EXAME PRE-EMPREGO',
  'Z02.2 EXAME P/ADMISSAO EM INSTITUICAO RESIDENCIAL',
  'Z02.3 EXAME P/A INCORPORACAO NAS FORCAS ARMADAS',
  'Z02.4 EXAME P/LICENCA P/A CONDUCAO DE VEIC',
  'Z02.5 EXAME P/PARTICIPACAO EM ESPORTE',
  'Z02.6 EXAME P/FINS DE SEGURO',
  'Z02.7 OBTENCAO DE ATESTADO MEDICO',
  'Z02.8 OUTR EXAMES P/PROPOSITOS ADMINISTRATIVOS',
  'Z02.9 EXAME NE C/FINALIDADES ADMINISTRATIVAS',
  'Z03.0 OBSERVACAO P/SUSPEITA DE TUBERC',
  'Z03.1 OBSERVACAO P/SUSPEITA DE NEOPL MALIG',
  'Z03.2 OBSERVACAO P/SUSP TRANST MENTAIS E COMPORT',
  'Z03.3 OBSERVACAO P/SUSPEITA DE TRANST SIST NERVOSO',
  'Z03.4 OBSERVACAO P/SUSPEITA DE INFARTO MIOCARDIO',
  'Z03.5 OBSERVACAO P/SUSPEITA OUTR DOENC CARDIOVASC',
  'Z03.6 OBSERV P/SUSP EFEITO TOXICO SUBST INGERIDA',
  'Z03.8 OBSERVACAO P/SUSPEITA DE OUTR DOENC AFECCOES',
  'Z03.9 OBSERVACAO P/SUSPEITA DE DOENC OU AFECCAO NE',
  'Z04.0 TESTE P/ALCOOL E P/DROGAS NO SANGUE',
  'Z04.1 EXAME E OBSERVACAO APOS ACID TRANSP',
  'Z04.2 EXAME E OBSERVACAO APOS ACID DE TRABALHO',
  'Z04.3 EXAME E OBSERVACAO APOS OUTR ACID',
  'Z04.4 EXAME E OBSERV APOS ALEGACAO ESTUPRO SEDUCAO',
  'Z04.5 EXAME E OBSERV APOS OUTR FERIM INFLIGIDOS',
  'Z04.6 EXAME PSIQUIATR GERAL REQUERIDO P/AUTORIDADE',
  'Z04.8 EXAME E OBSERVACAO P/OUTR RAZOES ESPEC',
  'Z04.9 EXAME E OBSERVACAO P/RAZAO NE',
  'Z08.0 EXAME SEGUIM APOS TRAT CIRURG NEOPL MALIG',
  'Z08.1 EXAME SEGUIM APOS RADIOTERAPIA P/NEOPL MALIG',
  'Z08.2 EXAME SEGUIM APOS QUIMIOTERAP P/NEOPL MALIG',
  'Z08.7 EXAME SEGUIM APOS TRAT MISTO P/NEOPL MALIG',
  'Z08.8 EXAME SEGUIM APOS OUTR TRAT P/NEOPL MALIG',
  'Z08.9 EXAME SEGUIM APOS TRAT NE P/NEOPL MALIG',
  'Z09.0 EXAME SEGUIM APOS CIRURGIA P/OUTR AFECCOES',
  'Z09.1 EXAME SEGUIM APOS RADIOTERAPIA P/OUTR AFECC',
  'Z09.2 EXAME SEGUIM APOS QUIMIOTERAP P/OUTR AFECC',
  'Z09.3 EXAME SEGUIM APOS PSICOTERAPIA',
  'Z09.4 EXAME SEGUIM APOS TRATAMENTO DE FRAT',
  'Z09.7 EXAME SEGUIM APOS TRAT COMBINADO OUTR AFECC',
  'Z09.8 EXAME SEGUIM APOS OUTR TRAT P/OUTR AFECCOES',
  'Z09.9 EXAME SEGUIM APOS TRAT NE P/OUTR AFECCOES',
  'Z10.0 EXAME DE SAUDE OCUPACIONAL',
  'Z10.1 EXAME GERAL DE ROTINA RESID DE INSTITUICOES',
  'Z10.2 EXAME GERAL DE ROTINA PESSOAS FORCAS ARMADAS',
  'Z10.3 EXAME GERAL DE ROTINA DE EQUIPE ESPORTIVA',
  'Z10.8 EXAME GERAL DE ROTINA OUTR SUBPOP DEFINIDA',
  'Z11.0 EXAME ESPECIAL RASTR DOENC INFECC INTEST',
  'Z11.1 EXAME ESPECIAL RASTR TUBERC PULMONAR',
  'Z11.2 EXAME ESPECIAL RASTR OUTR DOENC BACTER',
  'Z11.3 EXAME ESP RASTR INFECC TRANSM PREDOM SEXUAL',
  'Z11.4 EXAME ESPECIAL RASTR DE HIV',
  'Z11.5 EXAME ESPECIAL RASTR OUTR DOENC VIRAIS',
  'Z11.6 EXAME ESP RASTR OUTR DOENC PROTOZ HELMINTOS',
  'Z11.8 EXAME ESP RASTR OUTR DOENC INFECC E PARASIT',
  'Z11.9 EXAME ESP RASTR DOENC INFECC E PARASIT NE',
  'Z12.0 EXAME ESPECIAL RASTR NEOPLASIA DO ESTOMAGO',
  'Z12.1 EXAME ESPECIAL RASTR NEOPLASIA TRATO INTEST',
  'Z12.2 EXAME ESPECIAL RASTR NEOPLASIA ORG RESPIRAT',
  'Z12.3 EXAME ESPECIAL RASTR DE NEOPLASIA DE MAMA',
  'Z12.4 EXAME ESPECIAL RASTR DE NEOPLASIA COLO UTERO',
  'Z12.5 EXAME ESPECIAL RASTR DE NEOPLASIA PROSTATA',
  'Z12.6 EXAME ESPECIAL RASTR DE NEOPLASIA DA BEXIGA',
  'Z12.8 EXAME ESPECIAL RASTR NEOPLASIAS OUTR LOCALIZ',
  'Z12.9 EXAME ESPECIAL RASTR DE NEOPLASIA NE',
  'Z13.0 EXAME ESP RASTR DOENC SANG ORG HEM TRAN IMUN',
  'Z13.1 EXAME ESPECIAL RASTR DE DIABETES MELLITUS',
  'Z13.2 EXAME ESPECIAL RASTR DE TRANST NUTRICIONAIS',
  'Z13.3 EXAME ESPECIAL RASTR TRANST MENTAIS COMPORT',
  'Z13.4 EXAME ESP RASTR ALGUNS TRANST DESENV INFANC',
  'Z13.5 EXAME ESPECIAL RASTR DOENC OUVIDOS OLHOS',
  'Z13.6 EXAME ESPECIAL RASTR DOENC CARDIOVASCULARES',
  'Z13.7 EXAME ESP RASTR MALFORM CONG DEF ANOM CROMOS',
  'Z13.8 EXAME ESPECIAL RASTR OUTR DOENC TRANST ESPEC',
  'Z13.9 EXAME ESPECIAL RASTR NE',
  'Z20.0 CONTATO EXPOSICAO A DOENC INFECC INTEST',
  'Z20.1 CONTATO EXPOSICAO A TUBERC',
  'Z20.2 CONTATO EXPOSICAO INFECC TRANSM PRED SEXUAL',
  'Z20.3 CONTATO EXPOSICAO A RAIVA',
  'Z20.4 CONTATO EXPOSICAO A RUBEOLA',
  'Z20.5 CONTATO EXPOSICAO A HEPATITE VIRAL',
  'Z20.6 CONTATO EXPOSICAO AO HIV',
  'Z20.7 CONTATO EXPOS PEDICULOSE ACARIASE OUT INFEST',
  'Z20.8 CONTATO EXPOSICAO OUTR DOENC TRANSMISSIVEIS',
  'Z20.9 CONTATO EXPOSICAO A DOENC TRANSMISSIVEL NE',
  'Z21   ESTADO DE INFECC ASSINTOMATICA P/HIV',
  'Z22.0 PORTADOR DE FEBRE TIFOIDE',
  'Z22.1 PORTADOR DE OUTR DOENC INFECC INTESTINAIS',
  'Z22.2 PORTADOR DE DIFTERIA',
  'Z22.3 PORTADOR DE OUTR DOENC BACTER ESPEC',
  'Z22.4 PORTADOR DE INFECC MODO TRANSM PREDOM SEXUAL',
  'Z22.5 PORTADOR DE HEPATITE VIRAL',
  'Z22.6 PORTADOR INFECC VIRUS T-LINFOTROPICO TIPO 1',
  'Z22.8 PORTADOR DE OUTR DOENC INFECC',
  'Z22.9 PORTADOR DE DOENC INFECC NE',
  'Z23.0 NECESS IMUNIZACAO SOMENTE COLERA',
  'Z23.1 NECESS IMUNIZ SOMENTE FEBRE TIF PARATIFOIDE',
  'Z23.2 NECESS IMUNIZACAO CONTRA TUBERC',
  'Z23.3 NECESS IMUNIZACAO CONTRA A PESTE',
  'Z23.4 NECESS IMUNIZACAO CONTRA A TULAREMIA',
  'Z23.5 NECESS IMUNIZACAO SOMENTE CONTRA O TETANO',
  'Z23.6 NECESS IMUNIZACAO SOMENTE CONTRA A DIFTERIA',
  'Z23.7 NECESS IMUNIZACAO SOMENTE CONTRA COQUELUCHE',
  'Z23.8 VACINACAO CONTRA OUTR DOENC BACTER UNICAS',
  'Z24.0 NECESS IMUNIZ CONTRA A POLIOMIELITE',
  'Z24.1 NECESS IMUNIZ CONTRA ENCEF VIRAL TRANS ARTR',
  'Z24.2 NECESS IMUNIZ CONTRA A RAIVA',
  'Z24.3 NECESS IMUNIZ CONTRA A FEBRE AMARELA',
  'Z24.4 NECESS IMUNIZ SOMENTE CONTRA O SARAMPO',
  'Z24.5 NECESS IMUNIZ SOMENTE CONTRA A RUBEOLA',
  'Z24.6 NECESS IMUNIZ SOMENTE CONTRA HEPATITE VIRAL',
  'Z25.0 NECESS IMUNIZ SOMENTE CONTRA A CAXUMBA',
  'Z25.1 NECESS IMUNIZ SOMENTE CONTRA A INFLUENZA',
  'Z25.8 NECESS IMUNIZ OUTR DOENC VIRAIS UNICAS ESPEC',
  'Z26.0 NECESS IMUNIZ CONTRA A LEISHMANIOSE',
  'Z26.8 NECESS IMUNIZ OUTR DOENC INFECC ESPEC UNICAS',
  'Z26.9 NECESS IMUNIZ CONTRA DOENC INFECC NE',
  'Z27.0 NECESS IMUNIZ CONTRA COLERA FEBR TIF PARATIF',
  'Z27.1 NECESS IMUNIZ DIFTERIA-PERTUSSIS-TETANO COMB',
  'Z27.2 NECESS IMUNIZ DIFT-PERT-TET FEBR TIF PARATIF',
  'Z27.3 NECESS IMUNIZ DIFTER-PERTUSSIS-TETANO POLIO',
  'Z27.4 NECESS IMUNIZ SARAMPO-CAXUMBA-RUBEOLA',
  'Z27.8 NECESS IMUNIZ COMBINACOES DE DOENC INFECC',
  'Z27.9 NECESS IMUNIZ COMBINACOES NE DE DOENC INFECC',
  'Z28.0 IMUNIZ NAO REALIZADA P/CONTRA-INDICACAO',
  'Z28.1 IMUNIZ NAO REAL DECIS PAC CRENC GRUP PRESSAO',
  'Z28.2 IMUNIZ NAO REAL DECIS PAC RAZOES OUTR E NE',
  'Z28.8 IMUNIZ NAO REALIZADA P/OUTR RAZOES',
  'Z28.9 IMUNIZ NAO REALIZADA P/RAZOES NE',
  'Z29.0 ISOLAMENTO',
  'Z29.1 IMUNOTERAPIA PROFILATICA',
  'Z29.2 OUTR QUIMIOTERAPIAS PROFILATICAS',
  'Z29.8 OUTR MEDIDAS PROFILATICAS ESPEC',
  'Z29.9 MEDIDA PROFILATICA NE',
  'Z30.0 ACONSELHAMENTO GERAL SOBRE CONTRACEPCAO',
  'Z30.1 INSERCAO DE DISP ANTICONCEPCIONAL',
  'Z30.2 ESTERILIZACAO',
  'Z30.3 EXTRACAO MENSTRUAL',
  'Z30.4 SUPERVISAO USO MEDIC ANTICONCEPCIONAIS',
  'Z30.5 SUPERVISAO DE DISP ANTICONCEPCIONAL',
  'Z30.8 OUTR PROCED ANTICONCEPCIONAL',
  'Z30.9 PROCED ANTICONCEPCIONAL NE',
  'Z31.0 TUBOPLAST VASOPLAST APOS ESTERILIZ PREVIA',
  'Z31.1 INSEMINACAO ARTIFICIAL',
  'Z31.2 FECUNDACAO IN VITRO',
  'Z31.3 OUTR METODOS ASSISTIDOS DE FERTILIZACAO',
  'Z31.4 INVEST E TESTES C/RELACAO A PROCRIACAO',
  'Z31.5 ACONSELHAMENTO GENETICO',
  'Z31.6 ACONSELHAMENTO GERAL SOBRE A PROCRIACAO',
  'Z31.8 OUTR MEDIDA ESPEC DE PROCRIACAO',
  'Z31.9 MEDIDA PROCRIATIVA NE',
  'Z32.0 GRAVIDEZ NAO CONFIRMADA',
  'Z32.1 GRAVIDEZ CONFIRMADA',
  'Z33   GRAVIDEZ COMO ACHADO CASUAL',
  'Z34.0 SUPERVISAO DE PRIMEIRA GRAVIDEZ NORMAL',
  'Z34.8 SUPERVISAO DE OUTR GRAVIDEZ NORMAL',
  'Z34.9 SUPERVISAO DE GRAVIDEZ NORMAL NE',
  'Z35.0 SUPERVISAO GRAVIDEZ C/HISTORIA ESTERILIDADE',
  'Z35.1 SUPERVISAO DE GRAVIDEZ C/HISTORIA DE ABORTO',
  'Z35.2 SUPERV GRAV OUTR ANTEC PROCRIACAO PROBLEM',
  'Z35.3 SUPERV GRAV HISTORIA ASSIST PRE-NATAL INSUF',
  'Z35.4 SUPERVISAO GRAVIDEZ C/GRANDE MULTIPARIDADE',
  'Z35.5 SUPERVISAO DE PRIMIGESTA IDOSA',
  'Z35.6 SUPERVISAO DE PRIMIGESTA MUITO JOVEM',
  'Z35.7 SUPERVISAO GRAVIDEZ ALTO RISCO DEV PROBL SOC',
  'Z35.8 SUPERVISAO DE OUTR GRAVIDEZES DE ALTO RISCO',
  'Z35.9 SUPERVISAO NE DE GRAVIDEZ DE ALTO RISCO',
  'Z36.0 RASTR DE ANOMALIAS CROMOSSOMICAS',
  'Z36.1 RASTR PRE-NATAL TAXA ELEV ALFA-FETOPROTEINAS',
  'Z36.2 OUTR RASTR PRE-NATAIS P/AMNIOCENTESE',
  'Z36.3 RASTR PRE-NAT MALFORM ULTRASS OUTR MEIOS FIS',
  'Z36.4 RASTR PRE-NAT RET CRESC FET ULTR OUT MET FIS',
  'Z36.5 RASTR PRE-NATAL DE ISOIMUNIZACAO',
  'Z36.8 OUTR RASTR PRE-NATAIS',
  'Z36.9 RASTR PRE-NATAL NE',
  'Z37.0 NASCIMENTO UNICO NATIVIVO',
  'Z37.1 NASCIMENTO UNICO NATIMORTO',
  'Z37.2 NASCIMENTO GEMELAR GEMEOS NATIVIVOS',
  'Z37.3 NASC GEMELAR UM NATIVIVO O OUTR NATIMORTO',
  'Z37.4 NASCIMENTO GEMELAR GEMEOS NATIMORTOS',
  'Z37.5 OUTR NASCIMENTOS MULT TODOS NATIVIVOS',
  'Z37.6 OUTR NASCIMENTOS MULT ALGUNS NATIVIVOS',
  'Z37.7 OUTR NASCIMENTOS MULT TODOS NATIMORTOS',
  'Z37.9 NASCIMENTO NE',
  'Z38.0 CRIANCA UNICA NASCIDA EM HOSPITAL',
  'Z38.1 CRIANCA UNICA NASCIDA FORA DO HOSPITAL',
  'Z38.2 CRIANCA UNICA NE QUANTO AO LOCAL NASCIMENTO',
  'Z38.3 GEMEOS NASCIDOS EM HOSPITAL',
  'Z38.4 GEMEOS NASCIDOS FORA DE HOSPITAL',
  'Z38.5 GEMEOS NE QUANTO AO LOCAL DE NASCIMENTO',
  'Z38.6 OUTR NASCIMENTOS MULT EM HOSPITAL',
  'Z38.7 OUTR NASCIMENTOS MULT FORA DE HOSPITAL',
  'Z38.8 OUTR NASCIMENTOS MULT NE QUANTO LOCAL NASC',
  'Z39.0 ASSIST E EXAME IMEDIATAMENTE APOS O PARTO',
  'Z39.1 ASSIST E EXAME DA MAE NUTRIZ',
  'Z39.2 SEGUIMENTO POS-PARTO DE ROTINA',
  'Z40.0 CIRURG PROFILAT FAT RISCO REL NEOPL MALIG',
  'Z40.8 OUTR CIRURGIA PROFILATICA',
  'Z40.9 CIRURGIA PROFILATICA NE',
  'Z41.0 TRANSPLANTE DE CABELO',
  'Z41.1 OUTR INTERV CIRURG PLAST P/RAZOES ESTETICAS',
  'Z41.2 CIRCUNCISAO RITUAL E DE ROTINA',
  'Z41.3 PERFURACAO DO LOBO DA ORELHA',
  'Z41.8 OUTR PROCED OUTR PROP EXC CUIDADOS DE SAUDE',
  'Z41.9 PROCED NE P/OUTR PROP EXC CUIDADOS DE SAUDE',
  'Z42.0 SEGUIM ENVOLV CIRURG PLAST CABECA PESCOCO',
  'Z42.1 SEGUIMENTO ENVOLV CIRURGIA PLASTICA DE MAMA',
  'Z42.2 SEGUIM ENVOLV CIRURG PLAST OUTR PART TRONCO',
  'Z42.3 SEGUIM ENVOLV CIRURG PLAST EXTREM SUPER',
  'Z42.4 SEGUIM ENVOLV CIRURG PLAST EXTREM INFER',
  'Z42.8 SEGUIM ENVOLV CIRURG PLAST OUTR PARTES CORPO',
  'Z42.9 SEGUIMENTO ENVOLV CIRURGIA PLASTICA NE',
  'Z43.0 CUIDADOS A TRAQUEOSTOMIA',
  'Z43.1 CUIDADOS A GASTROSTOMIA',
  'Z43.2 CUIDADOS A ILEOSTOMIA',
  'Z43.3 CUIDADOS A COLOSTOMIA',
  'Z43.4 CUIDADOS OUTR ORIF ARTIFICIAIS TUBO DIGEST',
  'Z43.5 CUIDADOS A CISTOSTOMIA',
  'Z43.6 CUIDADOS OUTR ORIF ARTIFICAIS VIAS URINARIAS',
  'Z43.7 CUIDADOS A VAGINA ARTIFICIAL',
  'Z43.8 CUIDADOS A OUTR ORIFICIOS ARTIFICIAIS',
  'Z43.9 CUIDADOS A ORIFICIO ARTIFICIAL NE',
  'Z44.0 COLOCACAO E AJUSTAMENTO DE BRACO ARTIFICIAL',
  'Z44.1 COLOCACAO E AJUSTAMENTO DE PERNA ARTIFICIAL',
  'Z44.2 COLOCACAO E AJUSTAMENTO DE OLHO ARTIFICIAL',
  'Z44.3 COLOCACAO E AJUSTAMENTO PROTESE EXTERNA MAMA',
  'Z44.8 COLOCACAO E AJUSTAMENTO OUTR AP PROTESE EXT',
  'Z44.9 COLOCACAO E AJUSTAMENTO AP PROTESE EXT NE',
  'Z45.0 AJUSTAMENTO E MANUSEIO MARCA-PASSO CARDIACO',
  'Z45.1 AJUSTAMENTO E MANUSEIO DE BOMBA DE INFUSAO',
  'Z45.2 AJUSTAMENTO E MANUSEIO DISP ACESSO VASCULAR',
  'Z45.3 AJUSTAMENTO E MANUSEIO DISP IMPLANT AUDICAO',
  'Z45.8 AJUSTAMENTO E MANUSEIO OUTR DISP IMPLANTADOS',
  'Z45.9 AJUSTAMENTO E MANUSEIO DE DISP IMPLANTADO NE',
  'Z46.0 COLOCACAO AJUSTAMENTO OCULOS LENTES CONTATO',
  'Z46.1 COLOCACAO E AJUSTAMENTO DE APARELHO AUDITIVO',
  'Z46.2 COLOC AJUST OUTR AP REL SIST NERV ORG SENTID',
  'Z46.3 COLOCACAO AJUSTAMENTO DISP PROTESE DENTARIA',
  'Z46.4 COLOCACAO E AJUSTAMENTO APARELHO ORTODONTICO',
  'Z46.5 COLOC AJUST ILEOSTOMIA E OUTR DISP INTEST',
  'Z46.6 COLOCACAO E AJUSTAMENTO DE PROTESE URINARIA',
  'Z46.7 COLOCACAO E AJUSTAMENTO APARELHO ORTOPEDICO',
  'Z46.8 COLOCACAO E AJUSTAMENTO OUTR APARELHOS ESPEC',
  'Z46.9 COLOCACAO E AJUSTAMENTO DE APARELHO NE',
  'Z47.0 SEGUIM ENV REM PLACA FRAT OUTR DISP FIX INT',
  'Z47.8 OUTR SEGUIMENTOS ORTOPEDICOS ESPEC',
  'Z47.9 SEGUIMENTO ORTOPEDICO NE',
  'Z48.0 CUIDADOS A CURATIVOS E SUTURAS CIRURGICAS',
  'Z48.8 OUTR SEGUIMENTO CIRURGICO ESPEC',
  'Z48.9 SEGUIMENTO CIRURGICO NE',
  'Z49.0 CUIDADO PREPARATORIO P/DIALISE',
  'Z49.1 DIALISE EXTRACORPOREA',
  'Z49.2 OUTR DIALISES',
  'Z50.0 REABILITACAO CARDIACA',
  'Z50.1 OUTR FISIOTERAPIA',
  'Z50.2 REABILITACAO DE ALCOOLATRA',
  'Z50.3 REABILITACAO DE TOXICODEPENDENTES',
  'Z50.4 PSICOTERAPIA NCOP',
  'Z50.5 REABILITACAO DA LINGUAGEM',
  'Z50.6 TREINAMENTO ORTOPTICO',
  'Z50.7 TERAPIA OCUPACIONAL REABILIT VOCACIONAL NCOP',
  'Z50.8 CUIDADOS ENVOLV USO DE OUTR PROCED REABILIT',
  'Z50.9 CUIDADOS ENVOLV USO DE PROCED REABILIT NE',
  'Z51.0 SESSAO DE RADIOTERAPIA',
  'Z51.1 SESSAO DE QUIMIOTERAPIA P/NEOPLASIA',
  'Z51.2 OUTR QUIMIOTERAPIA',
  'Z51.3 TRANSFUSAO DE SANGUE S/DIAGN REGISTRADO',
  'Z51.4 CUIDADO PREPARATORIO P/TRAT SUBSEQUENTE NCOP',
  'Z51.5 CUIDADO PALIATIVO',
  'Z51.6 DESSENSIBILIZACAO A ALERGENOS',
  'Z51.8 OUTR CUIDADO MEDICO ESPEC',
  'Z51.9 CUIDADO MEDICO NE',
  'Z52.0 DOADOR DE SANGUE',
  'Z52.1 DOADOR DE PELE',
  'Z52.2 DOADOR DE OSSO',
  'Z52.3 DOADOR DE MEDULA OSSEA',
  'Z52.4 DOADOR DE RIM',
  'Z52.5 DOADOR DE CORNEA',
  'Z52.6 DOADOR DE FIGADO',
  'Z52.7 DOADOR DE CORACAO',
  'Z52.8 DOADOR DE OUTR ORGAOS OU TEC',
  'Z52.9 DOADOR DE ORGAO OU TEC NE',
  'Z53.0 PROCED NAO REALIZADO DEV CONTRA-INDICACAO',
  'Z53.1 PROC N REAL DEV DEC PAC RAZ CRENC GRUP PRESS',
  'Z53.2 PROCED N REALIZ DEV DEC PAC OUTR RAZOES E NE',
  'Z53.8 PROCED NAO REALIZADO P/OUTR RAZOES',
  'Z53.9 PROCED NAO REALIZADO P/RAZAO NE',
  'Z54.0 CONVALESCENCA APOS CIRURGIA',
  'Z54.1 CONVALESCENCA APOS RADIOTERAPIA',
  'Z54.2 CONVALESCENCA APOS QUIMIOTERAPIA',
  'Z54.3 CONVALESCENCA APOS PSICOTERAPIA',
  'Z54.4 CONVALESCENCA APOS TRATAMENTO DE FRAT',
  'Z54.7 CONVALESCENCA APOS TRATAMENTO COMBINADO',
  'Z54.8 CONVALESCENCA APOS OUTR TRATAMENTO',
  'Z54.9 CONVALESCENCA APOS TRATAMENTO NE',
  'Z55.0 ANALFABETISMO E BAIXA ESCOLARIDADE',
  'Z55.1 ESCOLARIZACAO NAO DISPONIVEL E IMPOSSIVEL',
  'Z55.2 REPROVACAO EM EXAMES',
  'Z55.3 MAUS RESULTADOS ESCOLARES',
  'Z55.4 MA ADAPTACAO ESCOLAR E DIFIC C/PROF COLEGAS',
  'Z55.8 OUTR PROBL RELAC EDUCACAO E ALFABETIZACAO',
  'Z55.9 PROBLEMAS NE RELAC EDUCACAO E ALFABETIZACAO',
  'Z56.0 DESEMPREGO NE',
  'Z56.1 MUDANCA DE EMPREGO',
  'Z56.2 AMEACA DE PERDA DE EMPREGO',
  'Z56.3 RITMO DE TRABALHO PENOSO',
  'Z56.4 DESACORDO C/PATRAO E COLEGAS DE TRABALHO',
  'Z56.5 MA ADAPTACAO AO TRABALHO',
  'Z56.6 OUTR DIFIC FISICAS MENTAIS RELAC AO TRABALHO',
  'Z56.7 OUTR PROBLEMAS E OS NE RELAC EMPREGO',
  'Z57.0 EXPOSICAO OCUPACIONAL AO RUIDO',
  'Z57.1 EXPOSICAO OCUPACIONAL A RADIACAO',
  'Z57.2 EXPOSICAO OCUPACIONAL A POEIRA',
  'Z57.3 EXPOSICAO OCUPACIONAL A OUTR CONTAM DO AR',
  'Z57.4 EXPOSICAO OCUPACIONAL AGENTES TOXICOS AGRIC',
  'Z57.5 EXPOSIC OCUPAC AGENTES TOXICOS OUTR INDUSTR',
  'Z57.6 EXPOSICAO OCUPACIONAL TEMPERATURAS EXTREMAS',
  'Z57.7 EXPOSICAO OCUPACIONAL A VIBRACAO',
  'Z57.8 EXPOSICAO OCUPACIONAL OUTR FATORES DE RISCO',
  'Z57.9 EXPOSICAO OCUPACIONAL A FATOR DE RISCO NE',
  'Z58.0 EXPOSICAO AO RUIDO',
  'Z58.1 EXPOSICAO A POLUICAO ATMOSFERICA',
  'Z58.2 EXPOSICAO A POLUICAO DA AGUA',
  'Z58.3 EXPOSICAO A POLUICAO DO SOLO',
  'Z58.4 EXPOSICAO A RADIACAO',
  'Z58.5 EXPOSICAO A OUTR POLUICOES',
  'Z58.6 AGUA POTAVEL INSUFICIENTE',
  'Z58.8 OUTR PROBLEMAS RELAC AMBIENTE FISICO',
  'Z58.9 PROBLEMA NE RELACIONADO AMBIENTE FISICO',
  'Z59.0 FALTA DE DOMICILIO FIXO',
  'Z59.1 HABITACAO INADEQUADA',
  'Z59.2 DESACORDO VIZINHOS LOCATARIOS PROPRIETARIOS',
  'Z59.3 PROBL RELAC VIDA EM INSTITUICAO RESIDENCIAL',
  'Z59.4 FALTA DE ALIMENTACAO ADEQUADA',
  'Z59.5 POBREZA EXTREMA',
  'Z59.6 BAIXO RENDIMENTO',
  'Z59.7 SEGURO SOCIAL MEDIDAS BEM-ESTAR SOCIAL INSUF',
  'Z59.8 OUTR PROBL RELAC HABITACAO E CIRCUNST ECONOM',
  'Z59.9 CIRCUNSTANCIAS NE ECONOMICAS OU DE HABITACAO',
  'Z60.0 PROBL ADAPTACAO TRANSICOES DO CICLO DE VIDA',
  'Z60.1 SITUACAO PARENTAL ATIPICA',
  'Z60.2 VIVER SO',
  'Z60.3 DIFICULDADE DE ACULTURACAO',
  'Z60.4 EXCLUSAO E REJEICAO SOCIAIS',
  'Z60.5 OBJETO DISCRIMINACAO PERSEGUICAO PERCEBIDAS',
  'Z60.8 OUTR PROBLEMAS RELACIONADOS C/O MEIO SOCIAL',
  'Z60.9 PROBLEMA NE RELACIONADO C/O MEIO SOCIAL',
  'Z61.0 PERDA DE RELACAO AFETIVA NA INFANCIA',
  'Z61.1 REMOCAO DO LAR NA INFANCIA',
  'Z61.2 PADRAO ALTERADO RELACOES FAMILIARES INFANCIA',
  'Z61.3 EVENTOS ORIG PERDA AUTO-ESTIMA NA INFANCIA',
  'Z61.4 PROBL REL ABUSO SEX ALEG CRIANCA P/PESS GRUP',
  'Z61.5 PROBL REL ABUS SEX CRIANCA P/PESS  FORA GRUP',
  'Z61.6 PROBL RELAC C/ABUSO FISICO ALEGADO CRIANCA',
  'Z61.7 EXPERIENCIA PESSOAL AMEDRONTADORA INFANCIA',
  'Z61.8 OUTR EVENTOS VIDA PESSOAL NEGATIVOS INFANCIA',
  'Z61.9 EVENTO PESSOAL NEGATIVO NE NA INFANCIA',
  'Z62.0 SUPERV CONTR INADEQ P/PARTE DOS PAIS',
  'Z62.1 SUPERPROTECAO P/PARTE DOS PAIS',
  'Z62.2 EDUCACAO NUMA INSTITUICAO',
  'Z62.3 HOSTIL C/REL CRIANCA TRANF BODE EXPIATORIO',
  'Z62.4 NEGLIGENCIA EMOCIONAL DA CRIANCA',
  'Z62.5 OUTR PROBL RELAC C/NEGL EDUCACAO DOS FILHOS',
  'Z62.6 PRESS NAO APROPR P/PAIS E OUTR FALHAS EDUC',
  'Z62.8 OUTR PROBL ESPEC RELAC EDUCACAO DOS FILHOS',
  'Z62.9 PROBLEMA NE RELAC C/A EDUCACAO DOS FILHOS',
  'Z63.0 PROBLEMAS NAS RELACOES C/CONJUGE OU PARCEIRO',
  'Z63.1 PROBLEMAS RELACOES C/OS PAIS OU C/OS SOGROS',
  'Z63.2 SUPORTE FAMILIAR INADEQUADO',
  'Z63.3 AUSENCIA DE UM DOS MEMBROS DA FAMILIA',
  'Z63.4 DESAPARECIMENTO FALECIMENTO MEMBRO FAMILIA',
  'Z63.5 ROMPIMENTO FAMILIA P/SEPARACAO OU DIVORCIO',
  'Z63.6 PARENTE DEPENDENTE CUIDADOS RESID DOMICILIO',
  'Z63.7 OUTR ACONTEC DIFICEIS INCID FAMILIA LAR',
  'Z63.8 OUTR PROBL ESPEC RELAC GRUPO PRIM DE SUPORTE',
  'Z63.9 PROBLEMA NE RELAC C/O GRUPO PRIM DE SUPORTE',
  'Z64.0 PROBLEMAS RELAC C/UMA GRAVIDEZ NAO DESEJADA',
  'Z64.1 PROBLEMAS RELACIONADOS C/MULTIPARIDADE',
  'Z64.2 PROC ACEIT INTERV FIS NUTR QUIM PERIG NOCIV',
  'Z64.3 PROC ACEIT INTERV COMPORT PSIC PERIG NOCIVAS',
  'Z64.4 DESACORDO C/CONSELHEIROS',
  'Z65.0 CONDENACAO S/PRISAO TRIBUNAL CIVIL CRIMINAL',
  'Z65.1 PRISAO OU ENCARCERAMENTO',
  'Z65.2 PROBLEMAS LIGADOS A LIBERTACAO DE PRISAO',
  'Z65.3 PROBLEMAS LIGADOS A OUTR CIRCUNST LEGAIS',
  'Z65.4 VITIMA DE CRIME OU DE ATOS TERRORISTAS',
  'Z65.5 EXPOSICAO CATASTROFE GUERRA E OUTR HOSTILID',
  'Z65.8 OUTR PROBL ESPEC RELAC CIRCUNST PSICOSSOC',
  'Z65.9 PROBL RELAC CIRCUNSTANCIAS PSICOSSOCIAIS NE',
  'Z70.0 ACOMPANHAMENT RELAT ATITUD MATERIA SEXUALID',
  'Z70.1 ACONSELH RELAT COMPORT ORIENT SEXUAL SUJEITO',
  'Z70.2 ACONSELH RELAT COMPORT ORIENT SEXUAL TERC',
  'Z70.3 ACONS REL PREOC ASS REL ATIT COMP ORIENT SEX',
  'Z70.8 OUTR ACONSELHAMENTOS EM MATERIA SEXUALIDADE',
  'Z70.9 ACONSELHAMENTO NE EM MATERIA DE SEXUALIDADE',
  'Z71.0 PESSOA QUE CONSULTA INTERESSE DE UM TERCEIRO',
  'Z71.1 PESSOA C/MEDO QUEIXA NAO FOI FEITO DIAGN',
  'Z71.2 PESSOA QUE CONSULTA P/EXPLIC ACHADOS EXAME',
  'Z71.3 ACONSELHAMENTO E SUPERVISAO DIETETICOS',
  'Z71.4 ACONSELHAMENTO E SUPERVISAO P/ABUSO ALCOOL',
  'Z71.5 ACONSELHAMENTO E SUPERVISAO P/ABUSO DROGAS',
  'Z71.6 ACONSELHAMENTO P/O ABUSO DE FUMO',
  'Z71.7 ACONSELHAMENTO A PROPOSITO DO HIV',
  'Z71.8 OUTR ACONSELHAMENTOS ESPEC',
  'Z71.9 ACONSELHAMENTO NE',
  'Z72.0 USO DO TABACO',
  'Z72.1 USO DE ALCOOL',
  'Z72.2 USO DE DROGA',
  'Z72.3 FALTA DE EXERCICIO FISICO',
  'Z72.4 REGIME E HABITOS ALIMENTARES INADEQUADOS',
  'Z72.5 COMPORT SEXUAL DE ALTO RISCO',
  'Z72.6 MANIA DE JOGO E APOSTAS',
  'Z72.8 OUTR PROBLEMAS RELACIONADOS C/O ESTILO VIDA',
  'Z72.9 PROBLEMA NE RELACIONADO C/O ESTILO DE VIDA',
  'Z73.0 ESGOTAMENTO',
  'Z73.1 ACENTUACAO DE TRACOS DE PERSONALIDADE',
  'Z73.2 FALTA DE REPOUSO E DE LAZER',
  'Z73.3 STRESS NCOP',
  'Z73.4 HABILIDADES SOCIAIS INADEQUADAS NCOP',
  'Z73.5 CONFLITO SOBRE O PAPEL SOCIAL NCOP',
  'Z73.6 LIMITES IMPOSTOS AS ATIVIDADES P/INVALIDEZ',
  'Z73.8 OUTR PROBL RELAC ORGANIZ MODO DE VIDA',
  'Z73.9 PROBL RELAC ORGANIZ MODO DE VIDA NE',
  'Z74.0 MOBILIDADE REDUZIDA',
  'Z74.1 NECESSIDADE DE ASSIST C/CUIDADOS PESSOAIS',
  'Z74.2 NECESS ASSIST DOMIC NENH PESSOA LAR',
  'Z74.3 NECESSIDADE DE SUPERVISAO CONTINUA',
  'Z74.8 OUTR PROBL RELAC DEP PESS Q OFER CUID SAUDE',
  'Z74.9 PROBL NE RELAC DEPEND PESS Q OFER CUID SAUDE',
  'Z75.0 FALTA DISPONIB SERVICOS MEDICOS NO DOMICILIO',
  'Z75.1 PESS ESPER SER ADMIT ESTAB MED ADEQ OUT PART',
  'Z75.2 OUTR PERIODO DE ESPERA P/INVEST E TRATAMENTO',
  'Z75.3 FACILID SAUDE NAO DISPONIVEIS OU NAO ACESS',
  'Z75.4 OUTR ORGANIZ CUIDADOS NAO DISP OU NAO ACESS',
  'Z75.5 CUID DISPENSADOS AJUDAR FAMILIAS FERIAS',
  'Z75.8 OUTR DIFIC ESP RELAC FACIL MED OUT CUID SAUD',
  'Z75.9 PROBL NE RELAC FACIL MED OUTR CUID SAUDE',
  'Z76.0 EMISSAO DE PRESCRICAO DE REPETICAO',
  'Z76.1 SUPERV CUID SAUDE DE CRIANCAS ASSISTIDAS',
  'Z76.2 SUPERV CUID SAUD OUT CRIANC REC-NASC SADIOS',
  'Z76.3 PESSOA EM BOA SAUDE ACOMPANH PESSOA DOENTE',
  'Z76.4 OUTR PENSIONISTAS ESTAB DE CUIDADOS DE SAUDE',
  'Z76.5 PESSOA FINGINDO SER DOENTE',
  'Z76.8 CONT SERV SAUDE P/OUTR CIRCUNSTANCIAS ESPEC',
  'Z76.9 PESSOA CONT SERV SAUDE EM CIRCUNSTANCIAS NE',
  'Z80.0 HISTORIA FAMILIAR NEOPL MALIG ORG DIGESTIVOS',
  'Z80.1 HISTORIA FAMIL NEOPL MALIG TRAQ BRONQ PULM',
  'Z80.2 HIST FAM NEOPL MALIG OUTR ORG RESP INTRATOR',
  'Z80.3 HISTORIA FAMILIAR DE NEOPLASIA DE MAMA',
  'Z80.4 HISTORIA FAMILIAR NEOPL MALIG ORG GENITAIS',
  'Z80.5 HISTORIA FAMILIAR NEOPL MALIG TRATO URINARIO',
  'Z80.6 HISTORIA FAMILIAR DE LEUCEMIA',
  'Z80.7 HIST FAM OUT NEOPL MALIG TEC LINF HEMAT CORR',
  'Z80.8 HIST FAMILIAR NEOPL MALIG OUTR ORG APARELHOS',
  'Z80.9 HISTORIA FAMILIAR DE NEOPL MALIG NE',
  'Z81.0 HISTORIA FAMILIAR DE RETARDO MENTAL',
  'Z81.1 HISTORIA FAMILIAR DE ABUSO DE ALCOOL',
  'Z81.2 HISTORIA FAMILIAR DE ABUSO DE FUMO',
  'Z81.3 HISTORIA FAMILIAR ABUSO OUTR SUBST PSICOATIV',
  'Z81.4 HISTORIA FAMILIAR DE ABUSO DE OUTR SUBST',
  'Z81.8 HIST FAMILIAR OUTR TRANST MENTAIS COMPORT',
  'Z82.0 HIST FAM EPILEPSIA OUTR DOENC SIST NERV',
  'Z82.1 HISTORIA FAMILIAR CEGUEIRA E PERDA DE VISAO',
  'Z82.2 HISTORIA FAMILIAR SURDEZ E PERDA DE AUDICAO',
  'Z82.3 HISTORIA FAMILIAR DE ACID VASCULAR CEREBRAL',
  'Z82.4 HIST FAM DOENC ISQ CORACAO OUT DOENC AP CIRC',
  'Z82.5 HIST FAM ASMA OUTR DOENC RESP INFER CRONICAS',
  'Z82.6 HIST FAM ARTR OUT DOENC SIST OSTEOM TEC CONJ',
  'Z82.7 HIST FAM MALFORM DEFORM CONGEN ANOM CROMOS',
  'Z82.8 HIST FAM OUTR INCAP DOENC CRON INCAPAC NCOP',
  'Z83.0 HISTORIA FAMILIAR DE DOENC P/HIV',
  'Z83.1 HISTORIA FAMILIAR OUTR DOENC INFECC PARASIT',
  'Z83.2 HIST FAM DOENC SANG ORG HEMAT TRANST IMUNIT',
  'Z83.3 HISTORIA FAMILIAR DE DIABETES MELLITUS',
  'Z83.4 HIST FAM OUTR DOENC ENDOCR NUTR E METAB',
  'Z83.5 HIST FAM OUTR TRANST DOS OLHOS E DOS OUVIDOS',
  'Z83.6 HISTORIA FAMILIAR DE DOENC APARELHO RESPIRAT',
  'Z83.7 HISTORIA FAMILIAR DOENC APARELHO DIGESTIVO',
  'Z84.0 HISTORIA FAMILIAR DOENC DA PELE E TEC SUBCUT',
  'Z84.1 HISTORIA FAMILIAR DE TRANST DO RIM E URETER',
  'Z84.2 HISTORIA FAMILIAR OUTR DOENC AP GENITURINAR',
  'Z84.3 HISTORIA FAMILIAR DE CONSANGUINIDADE',
  'Z84.8 HISTORIA FAMILIAR DE OUTR AFECCOES ESPEC',
  'Z85.0 HISTORIA PESSOAL NEOPL MALIG ORG DIGESTIVOS',
  'Z85.1 HIST PESS NEOPL MALIG TRAQ BRONQUIO E PULMAO',
  'Z85.2 HIST PESS NEOPL MALIG OUT ORG RESP INTRATOR',
  'Z85.3 HISTORIA PESSOAL DE NEOPL MALIG DE MAMA',
  'Z85.4 HISTORIA PESSOAL NEOPL MALIG ORGAOS GENITAIS',
  'Z85.5 HISTORIA PESSOAL NEOPL MALIG TRATO URINARIO',
  'Z85.6 HISTORIA PESSOAL DE LEUCEMIA',
  'Z85.7 HIST PESS OUT NEOP MAL TEC LINF HEM TEC CORR',
  'Z85.8 HIST PESS NEOPL MALIG OUTR ORGAOS APARELHOS',
  'Z85.9 HISTORIA PESSOAL DE NEOPL MALIG NE',
  'Z86.0 HISTORIA PESSOAL DE OUTR NEOPLASIAS',
  'Z86.1 HISTORIA PESSOAL DE DOENC INFECC E PARASIT',
  'Z86.2 HIST PESS DOENC SANG ORG HEMAT TRANST IMUNIT',
  'Z86.3 HIST PESS DOENC ENDOCR NUTRIC METABOLICAS',
  'Z86.4 HISTORIA PESSOAL DE ABUSO SUBST PSICOATIVAS',
  'Z86.5 HISTORIA PESSOAL OUTR TRANST MENTAIS COMPORT',
  'Z86.6 HIST PESS DOENC SIST NERV ORG SENTIDOS',
  'Z86.7 HISTORIA PESSOAL DOENC APARELHO CIRCULATORIO',
  'Z87.0 HISTORIA PESSOAL DE DOENC APARELHO RESPIRAT',
  'Z87.1 HISTORIA PESSOAL DE DOENC APARELHO DIGESTIVO',
  'Z87.2 HISTORIA PESSOAL DOENC PELE E TEC SUBCUTANEO',
  'Z87.3 HIST PESS DOENC SIST OSTEOMUSC E TEC CONJUNT',
  'Z87.4 HIST PESS DOENC DO APARELHO GENITURINARIO',
  'Z87.5 HIST PESS COMPLIC GRAVIDEZ PARTO E PUERPERIO',
  'Z87.6 HIST PESS AFECC ORIG PERIODO PERINATAL',
  'Z87.7 HIST PESS MALFORM DEFORM CONGEN ANOM CROMOS',
  'Z87.8 HISTORIA PESSOAL DE OUTR AFECCOES ESPEC',
  'Z88.0 HISTORIA PESSOAL DE ALERGIA A PENICILINA',
  'Z88.1 HIST PESSOAL ALERGIA OUTR AGENTE ANTIBIOTICO',
  'Z88.2 HISTORIA PESSOAL DE ALERGIA AS SULFONAMIDAS',
  'Z88.3 HIST PESS ALERGIA A OUTR AGENTES ANTIINFECC',
  'Z88.4 HIST PESS DE ALERGIA A AGENTE ANESTESICO',
  'Z88.5 HIST PESS DE ALERGIA A AGENTE NARCOTICO',
  'Z88.6 HIST PESSOAL DE ALERGIA A AGENTE ANALGESICO',
  'Z88.7 HIST PESSOAL DE ALERGIA A SORO E A VACINA',
  'Z88.8 HIST PESS ALERG OUTR DROG MEDIC SUBST BIOLOG',
  'Z88.9 HIST PESS ALERGIA DROG MEDIC SUBST BIOLOG NE',
  'Z89.0 AUSENCIA ADQUIR DE DEDOS DA MAO UNILATERAL',
  'Z89.1 AUSENCIA ADQUIR DE MAO E PUNHO',
  'Z89.2 AUSENCIA ADQUIR DE BRACO ACIMA DO PUNHO',
  'Z89.3 AUSENCIA ADQUIR DE AMBOS MEMBROS SUPER',
  'Z89.4 AUSENCIA ADQUIR DE PE E TORNOZELO',
  'Z89.5 AUSENCIA ADQUIR PERNA NIVEL ABAIXO JOELHO',
  'Z89.6 AUSENCIA ADQUIR DA PERNA ACIMA DO JOELHO',
  'Z89.7 AUSENCIA ADQUIR DE AMBOS MEMBROS INFER',
  'Z89.8 AUSENCIA ADQUIR DOS MEMBROS SUPER E INFER',
  'Z89.9 AUSENCIA ADQUIR DE MEMBRO NE',
  'Z90.0 AUSENCIA ADQUIR DE PARTE DA CABECA E PESCOCO',
  'Z90.1 AUSENCIA ADQUIR DE MAMAS',
  'Z90.2 AUSENCIA ADQUIR DO PULMAO',
  'Z90.3 AUSENCIA ADQUIR DE PARTE DO ESTOMAGO',
  'Z90.4 AUSENCIA ADQUIR OUTR PARTES TRATO DIGESTIVO',
  'Z90.5 AUSENCIA ADQUIR DO RIM',
  'Z90.6 AUSENCIA ADQUIR OUTR PARTES TRATO URINARIO',
  'Z90.7 AUSENCIA ADQUIR DE ORGAOS GENITAIS',
  'Z90.8 AUSENCIA ADQUIR DE OUTR ORGAOS',
  'Z91.0 HIST PESS ALERGIA EXC DROGAS SUBST BIOLOG',
  'Z91.1 HIST PESS NAO ADER TRAT OU REGIME MEDICO',
  'Z91.2 HISTORIA PESSOAL DE MA HIGIENE PESSOAL',
  'Z91.3 HISTORIA PESSOAL CICLO VIGILIA-SONO ANORMAL',
  'Z91.4 HISTORIA PESSOAL DE TRAUMA PSICOLOGICO NCOP',
  'Z91.5 HISTORIA PESSOAL DE AUTO AGRESSAO',
  'Z91.6 HISTORIA PESSOAL DE OUTR TRAUMAS FISICOS',
  'Z91.8 HIST PESS OUTR FATORES DE RISCO ESPEC NCOP',
  'Z92.0 HISTORIA PESSOAL DE ANTICONCEPCAO',
  'Z92.1 HIST PESS USO LONGO PRAZO DE ANTICOAGULANTES',
  'Z92.2 HIST PESS USO LONGO PRAZO OUTR MEDICAMENTOS',
  'Z92.3 HISTORIA PESSOAL DE IRRADIACAO',
  'Z92.4 HISTORIA PESSOAL CIRURGIA GRANDE PORTE NCOP',
  'Z92.5 HISTORIA PESSOAL DE MEDIDAS DE REABILITACAO',
  'Z92.8 HISTORIA PESSOAL DE OUTR TRATAMENTOS MEDICOS',
  'Z92.9 HISTORIA PESSOAL DE TRATAMENTO MEDICO NE',
  'Z93.0 TRAQUEOSTOMIA',
  'Z93.1 GASTROSTOMIA',
  'Z93.2 ILEOSTOMIA',
  'Z93.3 COLOSTOMIA',
  'Z93.4 OUTR ORIFIC ARTIFICIAIS TRATO GASTROINTEST',
  'Z93.5 CISTOSTOMIA',
  'Z93.6 OUTR ORIFICIOS ARTIFICIAIS APARELHO URINARIO',
  'Z93.8 OUTR ORIFICIOS ARTIFICIAIS',
  'Z93.9 ORIFICIO ARTIFICIAL NE',
  'Z94.0 RIM TRANSPLANTADO',
  'Z94.1 CORACAO TRANSPLANTADO',
  'Z94.2 PULMAO TRANSPLANTADO',
  'Z94.3 CORACAO E PULMOES TRANSPLANTADOS',
  'Z94.4 FIGADO TRANSPLANTADO',
  'Z94.5 PELE TRANSPLANTADA',
  'Z94.6 OSSO TRANSPLANTADO',
  'Z94.7 CORNEA TRANSPLANTADA',
  'Z94.8 OUTR ORGAOS E TEC TRANSPLANTADOS',
  'Z94.9 ORGAO E TEC NE TRANSPLANTADO',
  'Z95.0 PRESENCA DE MARCA-PASSO CARDIACO',
  'Z95.1 PRESENCA DE ENXERTO DE PONTE AORTOCORONARIA',
  'Z95.2 PRESENCA DE PROTESE DE VALVULA CARDIACA',
  'Z95.3 PRESENCA DE VALVA CARDIACA XENOGENA',
  'Z95.4 PRESENCA OUTR SUBST VALVARES CARDIACAS',
  'Z95.5 PRESENCA IMPL ENXERTO ANGIOPLASTIA CORONARIA',
  'Z95.8 PRESENCA OUTR IMPL ENXERTOS CARD VASCULARES',
  'Z95.9 PRESENCA IMPL ENXERTO CARDIACO E VASCULAR NE',
  'Z96.0 PRESENCA DE IMPLANTES UROGENITAIS',
  'Z96.1 PRESENCA DE LENTE INTRA-OCULAR',
  'Z96.2 PRESENCA DE IMPLANTE OTOLOGICOS AUDIOLOGICOS',
  'Z96.3 PRESENCA DE LARINGE ARTIFICIAL',
  'Z96.4 PRESENCA DE IMPLANTES ENDOCRINOS',
  'Z96.5 PRESENCA RAIZ DENTARIA IMPL MANDIBULARES',
  'Z96.6 PRESENCA IMPLANTES ARTICULARES ORTOPEDICOS',
  'Z96.7 PRESENCA DE OUTR IMPLANTES DE OSSO E TENDAO',
  'Z96.8 PRESENCA DE OUTR IMPLANTES FUNCIONAIS ESPEC',
  'Z96.9 PRESENCA DE IMPLANTE FUNCIONAL NE',
  'Z97.0 PRESENCA DE OLHO ARTIFICIAL',
  'Z97.1 PRESENCA DE MEMBRO ARTIFICIAL',
  'Z97.2 PRESENCA DE DISP PROTETICO DENTARIO',
  'Z97.3 PRESENCA DE OCULOS E DE LENTES DE CONTATO',
  'Z97.4 PRESENCA DE APARELHO EXTERNO DE SURDEZ',
  'Z97.5 PRESENCA DISP ANTICONCEPCIONAL INTRA-UTERINO',
  'Z97.8 PRESENCA DE OUTR DISP ESPEC',
  'Z98.0 DERIVACAO INTESTINAL OU ANASTOMOSE INTEST',
  'Z98.1 ARTRODESE',
  'Z98.2 PRESENCA DE DISP DE DRENAGEM DO LIQUOR',
  'Z98.8 OUTR ESTADOS POS-CIRURGICOS ESPEC',
  'Z99.0 DEPENDENCIA DE ASPIRADOR',
  'Z99.1 DEPENDENCIA DE RESPIRADOR',
  'Z99.2 DEPENDENCIA DE DIALISE RENAL',
  'Z99.3 DEPENDENCIA DE CADEIRA DE RODAS',
  'Z99.8 DEPEND OUTR MAQ E APARELHOS CAPACITANTES',
  'Z99.9 DEPEND MAQUINA E APARELHO CAPACITANTE NE',
];

export default DataCIDAutocomplete;
