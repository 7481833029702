import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { api } from '../utils/WebApiConfig';
import {
  handleError,
  handleSuccess,
  handleInfo,
  handleWarn,
} from '../utils/Toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { Edit, AngleDoubleLeft } from '../utils/Fontawesome';
import { Link, TablePagination } from '@material-ui/core';
import {
  removeAcento,
  mask_Numeros,
  calcularIdade,
  mask_data,
  filterBy,
} from '../utils/Mascara';
import axios from 'axios';
import ImprimirProtuario from './ImprimirProntuario';
import { Receita } from '../templates/receita/Receita';
//import { Prontuario } from '../templates/receita/Prontuario';
import { Prontuario } from '../templates/receita/Prontuario';
import { Exame } from '../templates/receita/Exame';
import { ReceitaControlada } from '../templates/receita/ReceitaControlada';
import NotificarSuporte from './NotificarSuporte';
import AlergiaCad from './AlergiaCad';
export const adicionaZero = (numero) => {
  if (numero <= 9) return '0' + numero;
  else return numero;
};

export const adicionaZeroMinutes = (numero) => {
  if (numero === 0) return '0' + numero;
  else return numero;
};
const Atendimento = () => {
  let history = useHistory();
  const location = useLocation();

  const [cons_codigo, setCons_codigo] = useState('');
  const [cons_data, setCons_data] = useState('');
  const [cons_hora, setCons_hora] = useState('');
  const [cons_alergia, setCons_alergia] = useState('');
  const [cons_medico, setCons_medico] = useState('');
  const [pac_data, setPac_data] = useState('');

  const [med_numero, setMed_numero] = useState('');
  const [med_endereco, setMed_endereco] = useState('');
  const [med_conselho, setMed_conselho] = useState('');
  const [med_conselho_codigo, setMed_conselho_codigo] = useState('');
  const [med_bairro, setMed_bairro] = useState('');
  const [med_img, setMed_img] = useState('');
  const [cid_municipio, setCid_municipio] = useState('');
  const [cid_uf, setCid_uf] = useState('');
  const [med_cep, setMed_cep] = useState('');
  const [med_telefone, setMed_telefone] = useState('');

  const [page_left, setPage_left] = useState(0);
  const [page_right, setPage_right] = useState(0);
  const [page_top, setPage_top] = useState(0);
  const [page_bottom, setPage_bottom] = useState(0);

  const [cons_paciente, setCons_paciente] = useState('');
  const [statusCons_paciente, setStatusCons_paciente] = useState([]);
  const [cons_convenio, setCons_convenio] = useState('');
  const [statusExame, setStatusExame] = useState([]);
  const [exame, setExame] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [atualizarPaciente, setAtualizarPaciente] = useState(false);
  const [alergiasSelecionadas, setAlergiasSelecionadas] = useState([]);
  const [consultas, setConsultas] = useState([]);
  const [statusCons_convenio, setStatusCons_convenio] = useState('');
  const [cons_texto, setCons_texto] = useState('');
  const [cons_tempo, setCons_tempo] = useState('');
  const [exame_texto, setExame_texto] = useState('');
  const [receita_texto, setReceita_texto] = useState('');
  const [cons_tipo, setCons_tipo] = useState('');
  const [statusCons_tipo, setStatusCons_tipo] = useState([
    { value: 'C', label: 'Consulta' },
    { value: 'P', label: '1. Consulta' },
    { value: 'R', label: 'Retorno' },
    { value: 'T', label: 'Receita' },
    { value: 'E', label: 'Exame' },
    { value: 'A', label: 'Ausente' },
  ]);

  /*const [statusCons_status, setStatusCons_status] = useState([
    { value: 0, label: "Agendado" },
    { value: 1, label: "Aguardando" },
    { value: 2, label: "Em atendimento" },
    { value: 3, label: "Atendido" },
    { value: 4, label: "Faltou " }, 
  ]);*/

  const [cons_status, setCons_status] = useState(0);
  const [pac_nascimento, setPac_nascimento] = useState('');
  const [pac_idade, setPac_idade] = useState('');
  const [memedButton, setMemedButton] = useState(true);
  const [medicoMemed, setMedicoMemed] = useState('');
  const [tempo_consulta, setTempo_consulta] = useState('');
  const [navForm, setNavForm] = useState('');

  const [idModal, setIdModal] = useState('');
  const [nomeModal, setNomeModal] = useState('');
  const [idStatusModal, setStatusModal] = useState('');

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [consultaPag, setConsultaPag] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
     "rowsPerPage:" +
       rowsPerPage +
       " newPage " +
       newPage +
       " " +
       inicio +
       " ---- " +
       fim
   );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setConsultaPag(cidadePag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setConsultaPag(cidadePag);
  };

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  const cadastrarPrescricao = (data) => {
    // console.info('====== Prescrição gerada com sucesso ======', data);
    // console.info('medicamentos ', data.prescricao.medicamentos);
    // console.info('nome ', data.prescricao.medicamentos[0].nome);
    let exame = '',
      receita = '';

    for (let i = 0; i < data.prescricao.medicamentos.length; i++) {
      if (data.prescricao.medicamentos[i].tipo === 'exame') {
        exame +=
          data.prescricao.medicamentos[i].nome +
          ' ' +
          data.prescricao.medicamentos[i].posologia +
          ';';
      } else {
        receita +=
          data.prescricao.medicamentos[i].nome +
          ' ' +
          data.prescricao.medicamentos[i].posologia +
          ';';
      }
    }
    // posologia

    //console.log('exame: ' + exame);
    // console.log('receita: ' + receita);

    let dataConsulta = {
      cons_codigo: parseInt(sessionStorage.getItem('cons_codigo')),
      cons_medico: parseInt(sessionStorage.getItem('med_id')),
      exame_texto: exame,
      receita_texto: receita,
    };
    //console.log(dataConsulta);

    api({
      method: 'PUT',
      url: 'api/consultas/' + sessionStorage.getItem('cons_codigo'),
      data: dataConsulta,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        //   history.push(`/atendimento`);
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const open = (
    module = 'plataforma.prescricao',
    callback = () => {
      document.getElementById('memed-prescricao').click();
    },
  ) => {
    window.MdHub.module.show(module);
    callback();
    definePacient(pac_data);
  };

  const logOutMemed = () => {
    try {
      // Parar os event listeners da Memed
      window.MdHub.server.unbindEvents();

      // Remover os objetos principais da Memed
      // @ts-expect-error
      delete window.MdHub;
      // @ts-expect-error
      delete window.MdSinapsePrescricao;

      const scripts = Array.from(document.getElementsByTagName('script'));
      if (scripts && scripts.length > 0) {
        scripts.forEach((script) => {
          if (script.src.includes('memed.com.br'))
            if (script.parentNode) {
              script.parentNode.removeChild(script);
            }
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  const initMemed = (token) => {
    var script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', 'memed_script');
    script.setAttribute('data-color', '#a7c4d2');
    script.setAttribute('data-token', token);
    script.setAttribute('data-container', 'memed-prescricao');

    script.src =
      'https://memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js';

    // teste
    /*   script.src =
      'https://integrations.memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js';*/
    script.onload = function () {
      initEventsMemed();
    };
    document.body.appendChild(script);
  };
  const initEventsMemed = () => {
    try {
      window.MdSinapsePrescricao.event.add('core:moduleHide', (module) => {
        if (module.moduleName === 'plataforma.prescricao') {
          //   console.info('====== Módulos da Memed encerrados ======');
        }
      });

      window.MdSinapsePrescricao.event.add('core:moduleInit', (module) => {
        if (module.name === 'plataforma.prescricao') {
          window.MdHub.command.send(
            'plataforma.prescricao',
            'setFeatureToggle',
            {
              // Desativa a opção de excluir um paciente
              deletePatient: false,
              // Desabilita a opção de remover/trocar o paciente
              removePatient: false,
              // Esconde o formulário de confirmação dos dados do paciente para receituário de controle especial
              // caso a cidade e o endereço tenham sido definidos com o comando `setPaciente`
              editPatient: false,
            },
          );

          window.MdHub.event.add('prescricaoImpressa', (prescriptionData) => {
            // No objeto "prescriptionData", é retornado as informações da prescrição gerada.
            // Implementar ações, callbacks, etc.
            /*   console.info(
              '====== Prescrição gerada com sucesso ======',
              prescriptionData,
            );*/
            cadastrarPrescricao(prescriptionData);
          });
          open();
          // Registrando o evento de click no elemento

          setMemedButton(false);
          document.getElementById('refrshbtn').textContent = 'Prescrição';
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    logOutMemed();

    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    var res = location.pathname.split('/');
    sessionStorage.setItem('pac_id', res[3]);
    sessionStorage.setItem('cons_codigo', res[2]);

    if (sessionStorage.getItem('conselho_memed') == 1) {
      getMedicoMemed();
    }

    getConsulta(res[3], res[2]);
    getAlergia(res[3]);
    getConvenio();
    getIngredientes('b');
    // getPagina();
    getExames();
  }, []);

  useEffect(() => {
    //  console.log('O TOKEN É ' + medicoMemed?.data?.attributes?.token);
    // console.log('O medicoMemed  É ' + medicoMemed);
    if (medicoMemed?.data?.attributes?.token) {
      if (sessionStorage.getItem('conselho_memed') == 1) {
        initMemed(medicoMemed?.data?.attributes?.token);
      }
    }
  }, [medicoMemed]);
  useEffect(() => {
    if (pac_data?.length > 0) {
      //setMemedButton(false);
      definePacient(pac_data);
    }
  }, [pac_data]);
  useEffect(() => {
    if (pac_data?.length > 0) {
      //setMemedButton(false);
      getAlergia(cons_paciente.value);
      definePacient(pac_data);
      setAtualizarPaciente(false);
    }
  }, [atualizarPaciente]);

  const definePacient = (pacient) => {
    if (pacient) {
      // console.log('data_de_nascimento ' + mask_data(pacient[0].pac_nascimento));
      /* console.log(
        'endereco ' + pacient[0].pac_endereco + ` ` + pacient[0].pac_bairro,
      );*/

    /*  let data = {
        idExterno: pacient[0].pac_id,
        nome:pacient[0].pac_nome,
        endereco: String(
          pacient[0].pac_endereco + ` , ` + pacient[0].pac_bairro,
        ),
        cidade: pacient[0].cid_municipio,
        data_nascimento: mask_data(pacient[0].pac_nascimento),
        telefone:  mask_Numeros(pacient[0].pac_celular),
        cpf: mask_Numeros(pacient[0].pac_cpf),
        dificuldade_locomocao: true,
      }
      console.log(data);
      console.log(pacient);*/
      return new Promise((resolve, reject) => {
        try {
          window.MdHub.command
            .send('plataforma.prescricao', 'setPaciente', {
              idExterno: pacient[0].pac_id,
              nome: pacient[0].pac_nome,
              endereco: String(
                pacient[0].pac_endereco + ` , ` + pacient[0].pac_bairro,
              ),
              cidade: pacient[0].cid_municipio,
              data_nascimento: mask_data(pacient[0].pac_nascimento),
              telefone: mask_Numeros(pacient[0].pac_celular),
              cpf: pacient[0].pac_cpf,
              dificuldade_locomocao: true,
            })
            .then(function success() {
              const principioAtivos = [];
              //   const principioAtivosNome = [];
              for (let i = 0; i < alergiasSelecionadas.length; i++) {
                principioAtivos.push(alergiasSelecionadas[i].id);
                //     principioAtivosNome.push(alergiasSelecionadas[i].name);
              }
              //    console.log('principioAtivos ' + principioAtivos);
              //   console.log('principioAtivosNome ' + principioAtivosNome);
              window.MdHub.command.send(
                'plataforma.prescricao',
                'setAllergy',
                principioAtivos,
              );
            });
        } catch (err) {
          //  console.log('erro ao definir paciente ' + JSON.stringify(err));
          console.log(err);
        }
      });
    }
  };

  const defineAlergia = async (arrayAlergia) => {
    const principioAtivos = [];
    //   const principioAtivosNome = [];
    for (let i = 0; i < arrayAlergia.length; i++) {
      principioAtivos.push(arrayAlergia[i].id);
      //     principioAtivosNome.push(alergiasSelecionadas[i].name);
    }
    //    console.log('principioAtivos ' + principioAtivos);
    //   console.log('principioAtivosNome ' + principioAtivosNome);
    window.MdHub.command.send(
      'plataforma.prescricao',
      'setAllergy',
      principioAtivos,
    );
  };
  const getConsulta = async (paciente, codigo) => {
    let parametros =
      '?medico=' + sessionStorage.getItem('med_id') + '&paciente=' + paciente;

    await api
      .get('api/consultas' + parametros, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        var filtrado = response.data.filter(function (obj) {
          return obj.cons_codigo == codigo;
        });
        //    console.log('result ' + JSON.stringify(filtrado));

        setConsultas(filtrado);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        const cidadePag = [];
        if (fim > response.data.length) {
          fim = response.data.length;
        }
        for (let i = inicio; i < fim; i++) {
          cidadePag.push(response.data[i]);
        }
        setConsultaPag(cidadePag);
        try {
          /*  { value: "C", label: "Consulta" },
                    { value: "P", label: "1. Consulta" },
                    { value: "R", label: "Retorno" },
                    { value: "T", label: "Receita" },
                    { value: "E", label: "Exame" }, */
          let tipo_nome = '';
          switch (filtrado[0].cons_tipo) {
            case 'C':
              tipo_nome = 'Consulta';
              break;
            case 'P':
              tipo_nome = '1. Consulta';
              break;
            case 'R':
              tipo_nome = 'Retorno';
              break;
            case 'T':
              tipo_nome = 'Receita';
              break;
            case 'A':
              tipo_nome = 'Ausente';
              break;
            default:
              tipo_nome = 'Exame';
          }

          setCons_codigo(filtrado[0].cons_codigo);
          /*   console.log(
            'pac_nascimento ' + JSON.stringify(filtrado[0].cons_paciente),
          );*/
          setData(filtrado[0].pac_nascimento);
          if (filtrado[0].cons_data) {
            setCons_data(filtrado[0].cons_data.substring(0, 10));
            setCons_hora(filtrado[0].cons_data.substring(11, 16));
          }

          setMed_bairro(filtrado[0].med_bairro);
          setMed_numero(filtrado[0].med_numero);
          setMed_endereco(filtrado[0].med_endereco);
          setMed_conselho(filtrado[0].med_conselho);
          setMed_conselho_codigo(filtrado[0].med_conselho_codigo);
          setCid_municipio(filtrado[0].cid_municipio);
          setCid_uf(filtrado[0].cid_uf);
          setMed_cep(filtrado[0].med_cep);
          setMed_telefone(filtrado[0].med_telefone);
          setMed_img(filtrado[0].med_img);

          setCons_paciente({
            value: filtrado[0].cons_paciente,
            label: filtrado[0].pac_nome,
          });
          setCons_convenio({
            value: filtrado[0].cons_convenio,
            label: filtrado[0].conv_nome,
          });
          setCons_texto(filtrado[0].cons_texto);
          setExame_texto(filtrado[0].cons_texto_exame);
          setReceita_texto(filtrado[0].cons_texto_receita);
          setCons_tipo({
            value: filtrado[0].cons_tipo,
            label: tipo_nome,
          });
        } catch (error) {
          console.log(error);
        } finally {
          getPaciente(paciente, filtrado[0]);
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getIngredientes = async (terms) => {
    await api
      .get('api/memed/ingredientes?terms=' + terms, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        //   console.log(response.data.data);
        const cons_alergia_array = [];
        // console.log(response.data.data);
        for (let i = 0; i < response.data.data.length; i++) {
          cons_alergia_array.push(
            response.data.data[i].id +
              ' ' +
              response.data.data[i].attributes.name,
          );
        }
        //   console.log(JSON.stringify(cons_alergia_array));
        setCons_alergia(cons_alergia_array);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getMedicoMemed = async (paciente) => {
    await api
      .get('api/memed?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        //   console.log('getMedicoMemed response é ' + JSON.stringify(response));

        setMedicoMemed(response.data);
        if (sessionStorage.getItem('conselho_memed') == 1) {
          if (response?.data?.errors[0]?.title) {
            handleInfo(response.data.errors[0].detail);
          }
          if (response?.data?.errors[0]?.title) {
            handleInfo('Contate o suporte!');
          }
        }

        /* if (response.data.data.attributes.token) {
          initMemed(
            response.data.data.attributes.token,
            response.data,
            paciente,
          )
          // initMemed(response.data);
        } else {
          console.log('falhou');
        };*/
      })
      .catch((error) => {
        //    handleError('Chegou aqui no erro!');
        //  console.log('Chegou aqui no erro! ' + JSON.stringify(error));
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getConvenio = () => {
    api
      .get('api/convenios?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayConvenios = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayConvenios.push({
            value: response.data[i].conv_id,
            label:
              response.data[i].conv_id + ' - ' + response.data[i].conv_nome,
          });
        }

        setStatusCons_convenio(arrayConvenios);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getAlergia = (pac_id, consulta) => {
    api
      .get('api/pacientes?select=2&pac_id=' + pac_id, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        try {
          const arrayAlergia = [];

          for (let i = 0; i < response.data.length; i++) {
            arrayAlergia.push({
              id: response.data[i].pa_alergia_id,
              name: response.data[i].pa_alergia,
            });
          }

          setAlergiasSelecionadas(arrayAlergia);

          //   getMedicoMemed(response.data);
        } catch (error) {
          console.log('falha ao preencher hooks de Alergias selecionadas');
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getPaciente = (pac_id, consulta) => {
    api
      .get('api/pacientes?pac_id=' + pac_id, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        try {
          const arrayPacientes = [];
          setPac_data(response.data);
          for (let i = 0; i < response.data.length; i++) {
            arrayPacientes.push({
              value: response.data[i].pac_id,
              label:
                response.data[i].pac_id + ' - ' + response.data[i].pac_nome,
              convenio: response.data[i].pac_convenio,
            });
          }

          setStatusCons_paciente(arrayPacientes);

          clearInterval(window.meuInterval);
          chronometer(consulta.cons_tempo);
          //   getMedicoMemed(response.data);
        } catch (error) {
          console.log('falha ao preencher hooks em Paciente');
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getPagina = () => {
    api
      .get('api/paginas?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setPage_bottom(response.data[0].page_bottom);
        setPage_top(response.data[0].page_top);
        setPage_left(response.data[0].page_left);
        setPage_right(response.data[0].page_right);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getExames = () => {
    api
      .get('api/exames?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayExames = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayExames.push({
            value: response.data[i].exa_codigo,
            label: response.data[i].exa_descricao,
          });
        }

        setStatusExame(arrayExames);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const handleChangePaciente = (paciente) => {
    setCons_paciente(paciente);

    let convenio = statusCons_convenio.filter(function (obj) {
      if (paciente.convenio == obj.value) {
        return obj.label;
      }
    });

    setCons_convenio(convenio[0]);
  };

  const handleChangeConvenio = (convenio) => {
    setCons_convenio(convenio);
  };

  const handleChangeTipo = (tipo) => {
    setCons_tipo(tipo);
  };
  const handleChangeExame = (exame) => {
    setExame(exame);
    setExame_texto(exame_texto + '\n' + exame.label);
    //setExame_texto(exame_texto.replace(/\r?\n/g, '<br />') + exame.label);
  };
  const RemoveAspasChaves = (value) => {
    value = value.replace(/"/gi, '');
    value = value.replace('}', '');
    value = value.replace('{', '');
    return value;
  };

  const save = (e, method, status) => {
    if (!cons_texto) {
      return false;
    }
    e.preventDefault();
    e.stopPropagation();

    let data, url;

    if (cons_codigo) {
      method = 'put';
    } else {
      handleInfo('Falha ao carregar o codigo');
      return false;
    }

    if (method === 'put') {
      data = {
        cons_tempo: tempo_consulta,
        cons_codigo: cons_codigo,
        cons_data: cons_data + 'T' + cons_hora + ':00',
        cons_medico: parseInt(sessionStorage.getItem('med_id')),
        cons_paciente: cons_paciente.value,
        cons_convenio: cons_convenio.value,
        cons_tipo: cons_tipo.value,
        cons_status: cons_status ? cons_status : 3,
        cons_texto: cons_texto,
      };

      url = 'api/consultas/' + cons_codigo;
    } else if (method === 'delete') {
      //    url = url + "/" + idModal + "/" + status;
      url = 'api/consultas/' + idModal;
      data = {
        id: idModal,
        status: status,
      };
      setNomeModal('');
      setIdModal('');
    }

    // return false;
    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        if (cons_status === 2) {
          history.push(`/atendimento`);
        }
      })
      .catch((error) => {
        //  catchResponse();
        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setPage_left(0);
    setPage_right(0);
    setPage_top(0);
    setPage_bottom(0);
    setMed_numero('');
    setCons_codigo('');
    setMed_endereco('');
    setMed_conselho('');
    setMed_bairro('');
    setCons_data('');
    setCid_municipio('');
    setMed_img('');
    setCons_hora('');
    setCons_medico('');
    setCons_paciente('');
    setCons_convenio('');
    setMed_telefone('');
    setMed_cep('');
    setCons_texto('');
    setCons_tipo('');
    setCons_status(0);
  };

  const editar = (consulta) => {
    setCons_medico(consulta.cons_medico);
    setCons_data(consulta.cons_data);
    setCons_paciente(consulta.cons_paciente);
    setCons_convenio(consulta.cons_convenio);
    setCons_texto(consulta.cons_texto);
    setCons_tipo(consulta.cons_tipo);
    setExame_texto(consulta.cons_texto_exame);
    setReceita_texto(consulta.cons_texto_receita);
    setCons_status(consulta.cons_status);
    setCons_codigo(consulta.cons_codigo);
    setCons_hora(consulta.cons_data.substring(11, 16));
  };

  const setData = (data) => {
    setPac_nascimento(data);
    if (data) {
      setPac_idade(calcularIdade(data));
    }
  };

  const VoltarHistory = () => {
    history.push('/atendimento');
  };

  function ImprimirReceitaControlada(e) {
    e.preventDefault();
    e.stopPropagation();
    var randNumber = Math.floor(Math.random() * 99);
    var mywindow = window.open(
      'https://tcdoc.viaconect.com?q=' + randNumber,
      'PRINT',
      'height=1200,width=1200',
      '_blank',
      'location = yes',
      'clearcache = yes',
    );
    var dataAtual = new Date();
    var dia = dataAtual.getDate();
    var mes = dataAtual.getMonth() + 1;
    var ano = dataAtual.getFullYear();
    var horas = dataAtual.getHours();
    var minutos = dataAtual.getMinutes();
    var segundos = dataAtual.getSeconds();

    var dataAtual =
      adicionaZero(dia) +
      '/' +
      adicionaZero(mes) +
      '/' +
      ano +
      ' ' +
      horas +
      ':' +
      adicionaZero(minutos) +
      ':' +
      adicionaZero(segundos);

    var logomarca = '';
    if (med_img) {
      logomarca =
        'https://tcdoc.viaconect.com/imagens/medico/' +
        sessionStorage.getItem('med_id') +
        '/' +
        med_img.substr(0, med_img.length - 1);
    }
    mywindow.document.write(
      // Receita(page_top, page_bottom, page_left, page_right, dataAtual)
      ReceitaControlada(
        page_top,
        page_bottom,
        page_left,
        page_right,
        dataAtual,
        cons_paciente.label.toLowerCase(),
        sessionStorage.getItem('med_nome').toLowerCase(),
        med_conselho + ' ' + med_conselho_codigo,
        med_endereco.toLowerCase() +
          ' n° ' +
          med_numero +
          ' - ' +
          med_bairro.toLowerCase() +
          ' - ' +
          cid_municipio.toLowerCase() +
          ' - ' +
          cid_uf,
        med_telefone,
        med_cep,
        receita_texto ? receita_texto.replace(/\r?\n/g, '<br />') : '',
        logomarca,
      ),
    );

    //  mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }
  function ImprimirExame(e) {
    e.preventDefault();
    e.stopPropagation();
    var randNumber = Math.floor(Math.random() * 99);
    var mywindow = window.open(
      'https://tcdoc.viaconect.com?q=' + randNumber,
      'PRINT',
      'height=1200,width=1200',
      '_blank',
      'location = yes',
      'clearcache = yes',
    );
    var dataAtual = new Date();
    var dia = dataAtual.getDate();
    var mes = dataAtual.getMonth() + 1;
    var ano = dataAtual.getFullYear();
    var horas = dataAtual.getHours();
    var minutos = dataAtual.getMinutes();
    var segundos = dataAtual.getSeconds();

    var dataAtual =
      adicionaZero(dia) +
      '/' +
      adicionaZero(mes) +
      '/' +
      ano +
      ' ' +
      horas +
      ':' +
      adicionaZero(minutos) +
      ':' +
      adicionaZero(segundos);

    var logomarca = '';
    if (med_img) {
      logomarca =
        'https://tcdoc.viaconect.com/imagens/medico/' +
        sessionStorage.getItem('med_id') +
        '/' +
        med_img.substr(0, med_img.length - 1);
    }
    mywindow.document.write(
      // Receita(page_top, page_bottom, page_left, page_right, dataAtual)
      Exame(
        page_top,
        page_bottom,
        page_left,
        page_right,
        dataAtual,
        cons_paciente.label.toLowerCase(),
        sessionStorage.getItem('med_nome').toLowerCase(),
        med_conselho + ' ' + med_conselho_codigo,
        med_endereco.toLowerCase() +
          ' n° ' +
          med_numero +
          ' - ' +
          med_bairro.toLowerCase() +
          ' - ' +
          cid_municipio.toLowerCase() +
          ' - ' +
          cid_uf,
        med_telefone,
        med_cep,
        exame_texto ? exame_texto.replace(/\r?\n/g, '<br />') : '',
        logomarca,
      ),
    );

    //  mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }
  function ImprimirReceita(e) {
    e.preventDefault();
    e.stopPropagation();
    var randNumber = Math.floor(Math.random() * 99);
    var mywindow = window.open(
      'https://tcdoc.viaconect.com?q=' + randNumber,
      'PRINT',
      'height=1200,width=1200',
      '_blank',
      'location = yes',
      'clearcache = yes',
    );
    var dataAtual = new Date();
    var dia = dataAtual.getDate();
    var mes = dataAtual.getMonth() + 1;
    var ano = dataAtual.getFullYear();
    var horas = dataAtual.getHours();
    var minutos = dataAtual.getMinutes();
    var segundos = dataAtual.getSeconds();

    var dataAtual =
      adicionaZero(dia) +
      '/' +
      adicionaZero(mes) +
      '/' +
      ano +
      ' ' +
      horas +
      ':' +
      adicionaZero(minutos) +
      ':' +
      adicionaZero(segundos);

    var logomarca = '';
    if (med_img) {
      logomarca =
        'https://tcdoc.viaconect.com/imagens/medico/' +
        sessionStorage.getItem('med_id') +
        '/' +
        med_img.substr(0, med_img.length - 1);
    }
    mywindow.document.write(
      // Receita(page_top, page_bottom, page_left, page_right, dataAtual)
      Receita(
        page_top,
        page_bottom,
        page_left,
        page_right,
        dataAtual,
        cons_paciente.label.toLowerCase(),
        sessionStorage.getItem('med_nome').toLowerCase(),
        med_conselho + ' ' + med_conselho_codigo,
        med_endereco.toLowerCase() +
          ' n° ' +
          med_numero +
          ' - ' +
          med_bairro.toLowerCase() +
          ' - ' +
          cid_municipio.toLowerCase() +
          ' - ' +
          cid_uf,
        med_telefone,
        med_cep,
        receita_texto ? receita_texto.replace(/\r?\n/g, '<br />') : '',
        logomarca,
      ),
    );

    //  mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }
  function ImprimirProtuario(e) {
    e.preventDefault();
    e.stopPropagation();
    var mywindow = window.open(
      'https://tcdoc.viaconect.com',
      'PRINT',
      'height=1200,width=1200',
    );
    var dataAtual = new Date();
    var dia = dataAtual.getDate();
    var mes = dataAtual.getMonth() + 1;
    var ano = dataAtual.getFullYear();
    var horas = dataAtual.getHours();
    var minutos = dataAtual.getMinutes();
    var segundos = dataAtual.getSeconds();

    var dataAtual =
      adicionaZero(dia) +
      '/' +
      adicionaZero(mes) +
      '/' +
      ano +
      ' ' +
      horas +
      ':' +
      adicionaZero(minutos) +
      ':' +
      adicionaZero(segundos);

    var logomarca = '';
    if (med_img) {
      logomarca =
        'https://tcdoc.viaconect.com/imagens/medico/' +
        sessionStorage.getItem('med_id') +
        '/' +
        med_img.substr(0, med_img.length - 1);
    }
    mywindow.document.write(
      // Receita(page_top, page_bottom, page_left, page_right, dataAtual)
      Prontuario(
        page_top,
        page_bottom,
        page_left,
        page_right,
        dataAtual,
        cons_paciente.label.toLowerCase(),
        sessionStorage.getItem('med_nome').toLowerCase(),
        med_conselho + ' ' + med_conselho_codigo,
        med_endereco.toLowerCase() +
          ' n° ' +
          med_numero +
          ' - ' +
          med_bairro.toLowerCase() +
          ' - ' +
          cid_municipio.toLowerCase() +
          ' - ' +
          cid_uf,
        med_telefone,
        med_cep,
        cons_texto ? cons_texto.replace(/\r?\n/g, '<br />') : '',
        logomarca,
      ),
    );

    //  mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }
  const visualizarProntuario = (consulta) => {
    limpar();
    getConsulta(consulta.cons_paciente, consulta.cons_codigo);

    getPaciente();
    getConvenio();
    //   getPagina();

    document.getElementById('pills-login-tab').click();
  };

  const telaAtestado = () => {
    var res = location.pathname.split('/');
    let paciente_id = res[2];
    let consulta_codigo = res[3];
    history.push(`/atestado/${paciente_id}/${consulta_codigo}`);
  };
  //document.querySelector("#addItemBtn")
  //#addItemBtn
  ///html/body/div[1]/div/div/div[1]/div/div[3]/div/button

  const showMemed = () => {
    open();
    document.getElementById('memed-prescricao').style.display = 'block';

    definePacient(pac_data);
  };
  const abrirAlergiaCad = () => {
    if (document.getElementById('btn_Cadastrar')) {
      document.getElementById('btn_Cadastrar').click();
    }
  };
  const parseTime = (horario) => {
    let [hora, minuto, segundo] = horario.split(':').map((v) => parseInt(v));
    if (!hora) {
      hora = 0;
    }
    if (!minuto) {
      minuto = 0;
    }
    if (!segundo) {
      segundo = 0;
    }
    return (hora * 60 * 60 + minuto * 60 + segundo) * 1000;
  };
  const chronometer = (time) => {
    var start = new Date();
    if (time) {
      time = parseTime(time);
    }

    start = start.getTime();
    var now, elapsed, d, h, m, s, ms, format;
    window.meuInterval = setInterval(function () {
      now = new Date().getTime();
      if (time) {
        now = now + time;
      }

      elapsed = now - start;
      d = Math.floor(elapsed / 86400000);
      h = Math.floor((elapsed % 86400000) / 3600000);
      m = Math.floor((elapsed % 3600000) / 60000);
      s = Math.floor((elapsed % 60000) / 1000);
      ms = Math.floor(elapsed % 1000);
      format =
        ('0' + h).slice(-2) +
        ':' +
        ('0' + m).slice(-2) +
        ':' +
        ('0' + s).slice(-2);
      setTempo_consulta(format);
    }, 1);
  };

  return (
    <>
      <div className="container-fluid">
        <div class="card" style={{ border: '0px solid rgba(0,0,0,.125)' }}>
          <div
            class="card-header"
            style={{ padding: '0px', borderTop: '0px', background: '#f8f9fa' }}
          >
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pills-login-tab"
                  data-toggle="pill"
                  href="#pills-login"
                  role="tab"
                  aria-controls="pills-login"
                  aria-selected="true"
                >
                  Atendimento do dia
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-registrar-tab"
                  data-toggle="pill"
                  href="#pills-registrar"
                  role="tab"
                  aria-controls="pills-registrar"
                  aria-selected="false"
                >
                  Consultas anteriores
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-login"
                onClick={(e) => setNavForm('login')}
                role="tabpanel"
                aria-labelledby="pills-login-tab"
              >
                <form
                  autoComplete="off"
                  id="form_paciente"
                  onSubmit={(e) => save(e)}
                >
                  <div className="row mb-3">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="cons_pacientes">
                        Paciente
                        <strong className="fild-required">*</strong>
                      </label>
                      <Select
                        id="cons_pacientes"
                        value={cons_paciente}
                        onChange={handleChangePaciente}
                        options={[] /*statusCons_paciente*/}
                        placeholder={<div>Selecione</div>}
                      />
                      <input
                        style={{
                          top: '43px',
                          position: 'absolute',
                          zIndex: '-1',
                          width: '10%',
                        }}
                        type="password"
                        id="cons_paciente_html5_validate"
                        value={cons_paciente}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="pac_nascimento">
                        Data de nascimento:
                        <strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="pac_nascimento"
                        placeholder="Data de nascimento"
                        onChange={(e) => setData(e.target.value)}
                        value={pac_nascimento}
                        required
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="pac_idade">
                        Idade:<strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="pac_idade"
                        readOnly
                        placeholder="Idade"
                        onChange={(e) => setPac_idade(e.target.value)}
                        value={pac_idade}
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label htmlFor="cons_convenio">
                        Convênio
                        <strong className="fild-required">*</strong>
                      </label>
                      <Select
                        id="cons_convenio"
                        value={cons_convenio}
                        onChange={handleChangeConvenio}
                        options={statusCons_convenio}
                        placeholder={<div>Selecione</div>}
                      />
                      <input
                        style={{
                          top: '43px',
                          position: 'absolute',
                          zIndex: '-1',
                          width: '10%',
                        }}
                        type="password"
                        id="cons_convenio_html5_validate"
                        value={cons_convenio}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="cons_data">
                        Data:<strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="cons_data"
                        readOnly
                        onChange={(e) =>
                          setCons_data(removeAcento(e.target.value))
                        }
                        value={cons_data}
                        required
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="cons_hora">
                        Horário:<strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        id="cons_hora"
                        readOnly
                        onChange={(e) =>
                          setCons_hora(removeAcento(e.target.value))
                        }
                        value={cons_hora}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="cons_tipo">
                        Tipo:
                        <strong className="fild-required">*</strong>
                      </label>
                      <Select
                        id="cons_tipo"
                        value={cons_tipo}
                        onChange={handleChangeTipo}
                        options={statusCons_tipo}
                        placeholder={<div>Selecione</div>}
                      />
                      <input
                        style={{
                          top: '43px',
                          position: 'absolute',
                          zIndex: '-1',
                          width: '10%',
                        }}
                        type="password"
                        id="cons_tipo_html5_validate"
                        value={cons_tipo}
                        required
                      />
                    </div>
                    <div
                      className="col-md-2 mb-3"
                      style={{ display: 'flex', flexFlow: 'column' }}
                    >
                      <span style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                        Tempo de consulta
                      </span>
                      <div id="cronometro" className="horario-prontuario">
                        {tempo_consulta}
                      </div>
                    </div>
                    <div className="col-md-4 col-12 mt-3 ">
                      {/*  <button
                        className="mb-3 mt-3 btn btn-secondary"
                        id="btn_atestado"
                        type="button"
                        style={{ borderRadius: '10px' }}
                        onClick={(e) => telaAtestado(e)}
                      >
                        Atestado
                      </button>*/}
                      {sessionStorage.getItem('conselho_memed') == 1 ? (
                        <>
                          <button
                            className="mb-3 mt-3  btn btn-secondary"
                            id="btn_alergia"
                            type="button"
                            disabled={memedButton}
                            style={{ borderRadius: '10px' }}
                            onClick={(e) => abrirAlergiaCad(e)}
                          >
                            Alergias
                          </button>

                          <button
                            className="mb-3 mt-3 ml-2 btn btn-secondary"
                            id="refrshbtn"
                            type="button"
                            disabled={memedButton}
                            onClick={(e) => showMemed()}
                            style={{
                              borderRadius: '10px',
                              width: 'fit-content',
                            }}
                          >
                            Carregando...
                          </button>
                        </>
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <label htmlFor="cons_texto">
                          <b>Descrição:</b>
                        </label>
                        <textarea
                          className="form-control textarea-responsive"
                          id="cons_texto"
                          required="required"
                          type="text"
                          value={cons_texto}
                          onChange={(e) => setCons_texto(e.target.value)}
                          rows="7"
                          cols="120"
                        ></textarea>
                      </div>{' '}
                    </div>
                  </div>
                  {sessionStorage.getItem('conselho_memed') == 1 ? (
                    <div
                      style={{ marginBottom: '50px', display: 'none' }}
                      id="memed-prescricao"
                    ></div>
                  ) : (
                    ''
                  )}
                  {/*
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="pills-prontuario-tab"
                        data-toggle="pill"
                        href="#pills-prontuario"
                        role="tab"
                        aria-controls="pills-prontuario"
                        aria-selected="true"
                      >
                        Prontuario
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="pills-exame-tab"
                        data-toggle="pill"
                        href="#pills-exame"
                        role="tab"
                        aria-controls="pills-exame"
                        aria-selected="false"
                      >
                        Exame
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="pills-receita-tab"
                        data-toggle="pill"
                        href="#pills-receita"
                        role="tab"
                        aria-controls="pills-receita"
                        aria-selected="false"
                      >
                        Receita
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-prontuario"
                      role="tabpanel"
                      aria-labelledby="pills-prontuario-tab"
                    >
                      {' '}
                      <div className="row mb-3">
                        <div className="col-md-12 mb-3">
                          <div className="form-group">
                            <label htmlFor="cons_texto">
                              <b>Descrição:</b>
                            </label>
                            <textarea
                              className="form-control textarea-responsive"
                              id="cons_texto"
                              required="required"
                              type="text"
                              value={cons_texto}
                              onChange={(e) => setCons_texto(e.target.value)}
                              rows="7"
                              cols="120"
                            ></textarea>
                          </div>
                          <div className="text-left mb-3 mt-3 ">
                            {page_left > 0 && cons_texto ? (
                              <button
                                className="btn btn-sm   btn_Cadastrar "
                                id="btnImprimirProntuario"
                                type="button"
                                onClick={(e) => ImprimirProtuario(e)}
                                style={{ height: '35px' }}
                              >
                                Imprimir Prontuario
                              </button>
                            ) : (
                              <button
                                className="btn btn-sm   btn_Cadastrar "
                                id="btnImprimirProntuario"
                                type="button"
                                disabled
                                onClick={(e) => ImprimirProtuario(e)}
                                style={{ height: '35px' }}
                              >
                                Imprimir Prontuario
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-exame"
                      role="tabpanel"
                      aria-labelledby="pills-exame-tab"
                    >
                      <div className="row mb-3">
                        <div className="col-md-4 mb-3">
                          <label htmlFor="exame">
                            Exame
                            <strong className="fild-required">*</strong>
                          </label>
                          <Select
                            id="exame"
                            value={exame}
                            onChange={handleChangeExame}
                            options={statusExame}
                            placeholder={<div>Selecione</div>}
                          />
                          <input
                            style={{
                              top: '43px',
                              position: 'absolute',
                              zIndex: '-1',
                              width: '10%',
                            }}
                            type="password"
                            id="exame_html5_validate"
                            
                            value={exame}
                            required
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="form-group">
                            <label htmlFor="exame_texto">
                              <b>Descrição:</b>
                            </label>
                            <textarea
                              className="form-control textarea-responsive"
                              id="exame_texto"
                              required="required"
                              type="text"
                              value={exame_texto}
                              onChange={(e) => setExame_texto(e.target.value)}
                              rows="7"
                              cols="120"
                            ></textarea>
                          </div>
                          <div className="text-left mb-3 mt-3 ">
                            {page_left > 0 && exame_texto ? (
                              <button
                                className="btn btn-sm   btn_Cadastrar "
                                id="btnImprimirExame"
                                type="button"
                                onClick={(e) => ImprimirExame(e)}
                                style={{ height: '35px' }}
                              >
                                Imprimir Exame
                              </button>
                            ) : (
                              <button
                                className="btn btn-sm   btn_Cadastrar "
                                id="btnImprimirExame"
                                type="button"
                                disabled
                                onClick={(e) => ImprimirExame(e)}
                                style={{ height: '35px' }}
                              >
                                Imprimir Exame
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-receita"
                      role="tabpanel"
                      aria-labelledby="pills-receita-tab"
                    >
                      <div className="row mb-3">
                        <div className="col-md-12 mb-3">
                          <div className="form-group">
                            <label htmlFor="receita_texto">
                              <b>Descrição:</b>
                            </label>
                            <textarea
                              className="form-control textarea-responsive"
                              id="receita_texto"
                              required="required"
                              type="text"
                              value={receita_texto}
                              onChange={(e) => setReceita_texto(e.target.value)}
                              rows="7"
                              cols="120"
                            ></textarea>
                          </div>
                          <div className="text-left mb-3 mt-3 ">
                            {page_left > 0 && receita_texto ? (
                              <>
                                <button
                                  className="btn btn-sm mr-3   btn_Cadastrar "
                                  id="btnImprimirReceita"
                                  type="button"
                                  onClick={(e) => ImprimirReceita(e)}
                                  style={{ height: '35px' }}
                                >
                                  Imprimir Receita Comum
                                </button>
                                <button
                                  className="btn btn-sm   btn_Cadastrar "
                                  id="btnImprimirReceitaespecial"
                                  type="button"
                                  onClick={(e) => ImprimirReceitaControlada(e)}
                                  style={{ height: '35px' }}
                                >
                                  Imprimir Receita Controlada
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-sm  mr-3   btn_Cadastrar "
                                  id="btnImprimirReceita"
                                  type="button"
                                  disabled
                                  onClick={(e) => ImprimirReceita(e)}
                                  style={{ height: '35px' }}
                                >
                                  Imprimir Receita Comum
                                </button>
                                <button
                                  className="btn btn-sm      btn_Cadastrar "
                                  id="btnImprimirReceitaespecial"
                                  type="button"
                                  disabled
                                  style={{ height: '35px' }}
                                >
                                  Imprimir Receita Controlada
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
*/}
                  <div className="text-left mb-3 mt-3 ">
                    <button
                      className="btn btn-sm mb-3 mr-3 btn_conculuir_conulta "
                      type="submit"
                      id="btnAlterar"
                      style={{ height: '35px', width: '108px' }}
                    >
                      {Edit} Concluir atendimento
                    </button>
                    {sessionStorage.getItem('med_conselho')?.toLowerCase() ==
                    'cbo' ? (
                      <button
                        className="btn btn-sm mb-3 mr-3 btn_andamento_conulta "
                        type="submit"
                        onClick={(e) => setCons_status(2)}
                        id="btnAndamento"
                        style={{
                          height: '35px',
                          width: '108px',
                        }}
                      >
                        {Edit} Atendimento em andamento
                      </button>
                    ) : (
                      ''
                    )}

                    <button
                      className="btn btn-sm  mb-3 mr-3  btn-secondary "
                      type="submit"
                      id="btnVoltar"
                      style={{ borderRadius: '10px' }}
                      onClick={(e) => VoltarHistory()}
                    >
                      {AngleDoubleLeft} Voltar
                    </button>
                  </div>
                </form>{' '}
                {page_left > 0 ? (
                  <input
                    className="btn btn-sm  mb-3 mr-3 btn-success "
                    id="btnImprimir"
                    onClick={(e) => ImprimirProtuario(e)}
                    type="hidden"
                    value="Imprimir Prontuario"
                  />
                ) : (
                  <input
                    className="btn btn-sm  mb-3 mr-3 btn-success "
                    id="btnImprimir"
                    onClick={(e) => ImprimirProtuario(e)}
                    disabled
                    type="hidden"
                    value="Imprimir Prontuario"
                  />
                )}
              </div>
              <div
                class="tab-pane fade"
                id="pills-registrar"
                role="tabpanel"
                onClick={(e) => setNavForm('registrar')}
                aria-labelledby="pills-registrar-tab"
              >
                <div className="table-responsive">
                  <table className="table table-hover table-striped table-light">
                    <thead className="text-white bg-dark">
                      <tr>
                        <th style={{ width: '95%' }} scope="col">
                          Prontuário
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {consultaPag.length > 0 &&
                        consultaPag.map((consulta, i) => {
                          // console.log(JSON.stringify(consultaPag));

                          return (
                            <>
                              <tr key={consulta.cons_codigo}>
                                <td
                                  onClick={(e) =>
                                    visualizarProntuario(consulta)
                                  }
                                >
                                  {consulta.cons_status === 3 ? (
                                    <>
                                      {' '}
                                      <span style={{ fontWeight: 'bold' }}>
                                        {' '}
                                        {mask_data(
                                          consulta.cons_data.substring(0, 10),
                                        ) +
                                          ' - ' +
                                          consulta.cons_data.substring(11, 16)}
                                      </span>
                                      <br />
                                      {consulta.cons_texto}
                                      {consulta.cons_texto_receita ? (
                                        <>
                                          {' '}
                                          <p>
                                            {' '}
                                            <b>Prescrição:</b>{' '}
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  consulta.cons_texto_receita,
                                              }}
                                            ></span>
                                          </p>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  ) : (
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {mask_data(
                                        consulta.cons_data?.substring(0, 10),
                                      ) + ' - Consulta em andamento'}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                  <TablePagination
                    component="div"
                    count={totalPages}
                    page={page}
                    onChangePage={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage={'Registros por página'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade bd-example-modal-md"
        tabIndex="-1"
        id="modalConvenio"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <AlergiaCad
              paciente={cons_paciente}
              pacienteAlergias={alergiasSelecionadas}
              atualizar={setAtualizarPaciente}
              definirAlergia={defineAlergia}
            />{' '}
          </div>
        </div>
      </div>{' '}
      <button
        type="button"
        className="btn  btn-success btn-block "
        id="btn_Cadastrar"
        data-toggle="modal"
        hidden
        data-target=".bd-example-modal-md"
      >
        Cadastrar
      </button>
    </>
  );
};
export default Atendimento;
