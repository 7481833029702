import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { api } from '../utils/WebApiConfig';
import { handleError, handleSuccess, handleInfo } from '../utils/Toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { AngleDoubleLeft } from '../utils/Fontawesome';
import { autocomplete_datalist } from '../utils/Autocomplete/Autocomplete';
import { removeAcento, calcularIdade } from '../utils/Mascara';
//import DataCID from '../data/DataCID';
import DataCIDAutocomplete from '../data/DataCIDAutocomplete';
import { Unimed } from '../templates/atestado/Unimed';
import { Padrao } from '../templates/atestado/Padrao';
import { ReceitaControlada } from '../templates/receita/ReceitaControlada';

export const adicionaZero = (numero) => {
  if (numero <= 9) return '0' + numero;
  else return numero;
};

export const adicionaZeroMinutes = (numero) => {
  if (numero === 0) return '0' + numero;
  else return numero;
};
const Atestado = () => {
  let history = useHistory();
  const location = useLocation();

  const [cons_codigo, setCons_codigo] = useState('');
  const [cons_data, setCons_data] = useState('');
  const [cons_hora, setCons_hora] = useState('');
  const [cons_hora_ini, setCons_hora_ini] = useState('');
  const [cons_hora_fim, setCons_hora_fim] = useState('');
  const [cons_hora_afastado_ini, setCons_hora_afastado_ini] = useState('');
  const [cons_hora_afastado_fim, setCons_hora_afastado_fim] = useState('');
  const [radio_consulta, setRadio_consulta] = useState('');
  const [radio_consulta_unimed, setRadio_consulta_unimed] = useState('');
  const [radio_periodo, setRadio_periodo] = useState('');
  const [radioUnimedAfastamento, setRadioUnimedAfastamento] = useState('');

  const [cons_medico, setCons_medico] = useState('');
  const [cons_event_text, setCons_event_text] = useState('consulta');

  const [med_numero, setMed_numero] = useState('');
  const [med_endereco, setMed_endereco] = useState('');
  const [med_conselho, setMed_conselho] = useState('');
  const [med_conselho_codigo, setMed_conselho_codigo] = useState('');
  const [med_bairro, setMed_bairro] = useState('');
  const [med_img, setMed_img] = useState('');
  const [cid_municipio, setCid_municipio] = useState('');
  const [cid_uf, setCid_uf] = useState('');
  const [med_cep, setMed_cep] = useState('');
  const [med_telefone, setMed_telefone] = useState('');

  const [page_left, setPage_left] = useState(0);
  const [page_right, setPage_right] = useState(0);
  const [page_top, setPage_top] = useState(0);
  const [page_bottom, setPage_bottom] = useState(0);

  const [cons_paciente, setCons_paciente] = useState('');
  const [statusCons_paciente, setStatusCons_paciente] = useState([]);
  const [cons_convenio, setCons_convenio] = useState('');
  const [statusCID, setStatusCID] = useState(DataCIDAutocomplete);
  const [CID, setCID] = useState([]);

  const [consultas, setConsultas] = useState([]);
  const [statusCons_convenio, setStatusCons_convenio] = useState('');
  const [cons_texto, setCons_texto] = useState('');
  const [exame_texto, setExame_texto] = useState('');
  const [receita_texto, setReceita_texto] = useState('');
  const [cons_tipo, setCons_tipo] = useState('');
  const [statusCons_tipo, setStatusCons_tipo] = useState([
    { value: 'C', label: 'Consulta' },
    { value: 'P', label: '1. Consulta' },
    { value: 'R', label: 'Retorno' },
    { value: 'T', label: 'Receita' },
    { value: 'E', label: 'Exame' },
    { value: 'A', label: 'Ausente' },
  ]);

  /*const [statusCons_status, setStatusCons_status] = useState([
    { value: 0, label: "Agendado" },
    { value: 1, label: "Aguardando" },
    { value: 2, label: "Em atendimento" },
    { value: 3, label: "Atendido" },
    { value: 4, label: "Faltou " }, 
  ]);*/

  const [cons_status, setCons_status] = useState(0);
  const [pac_nascimento, setPac_nascimento] = useState('');
  const [pac_idade, setPac_idade] = useState('');

  const [navForm, setNavForm] = useState('');

  const [idModal, setIdModal] = useState('');
  const [nomeModal, setNomeModal] = useState('');
  const [idStatusModal, setStatusModal] = useState('');

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [consultaPag, setConsultaPag] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
     "rowsPerPage:" +
       rowsPerPage +
       " newPage " +
       newPage +
       " " +
       inicio +
       " ---- " +
       fim
   );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setConsultaPag(cidadePag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setConsultaPag(cidadePag);
  };

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  useEffect(() => {
    document.getElementById('cons_hora_afastado_fim').readOnly = true;
    document.getElementById('cons_hora_afastado_ini').readOnly = true;
    document.getElementById('cons_hora_afastado_fim1').readOnly = true;
    document.getElementById('cons_hora_afastado_ini1').readOnly = true;
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }

    var res = location.pathname.split('/');

    getConsulta(res[3], res[2]);
    getPaciente();
    getConvenio();
    getPagina();
    //  getCID();
  }, []);

  const getConsulta = async (paciente, codigo) => {
    let parametros =
      '?medico=' + sessionStorage.getItem('med_id') + '&paciente=' + paciente;

    await api
      .get('api/consultas' + parametros, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setConsultas(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        const cidadePag = [];
        if (fim > response.data.length) {
          fim = response.data.length;
        }
        for (let i = inicio; i < fim; i++) {
          cidadePag.push(response.data[i]);
        }
        setConsultaPag(cidadePag);

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].cons_codigo == codigo) {
            /*  { value: "C", label: "Consulta" },
                    { value: "P", label: "1. Consulta" },
                    { value: "R", label: "Retorno" },
                    { value: "T", label: "Receita" },
                    { value: "E", label: "Exame" }, */
            let tipo_nome = '';
            switch (response.data[i].cons_tipo) {
              case 'C':
                tipo_nome = 'Consulta';
                break;
              case 'P':
                tipo_nome = '1. Consulta';
                break;
              case 'R':
                tipo_nome = 'Retorno';
                break;
              case 'T':
                tipo_nome = 'Receita';
                break;
              default:
                tipo_nome = 'Exame';
            }
            setCons_codigo(response.data[i].cons_codigo);
            setData(response.data[i].pac_nascimento);
            setCons_data(response.data[i].cons_data.substring(0, 10));
            setCons_hora(response.data[i].cons_data.substring(11, 16));
            setCons_hora_ini(response.data[i].cons_data.substring(11, 16));
            setMed_bairro(response.data[i].med_bairro);
            setMed_numero(response.data[i].med_numero);
            setMed_endereco(response.data[i].med_endereco);
            setMed_conselho(response.data[i].med_conselho);
            setMed_conselho_codigo(response.data[i].med_conselho_codigo);
            setCid_municipio(response.data[i].cid_municipio);
            setCid_uf(response.data[i].cid_uf);
            setMed_cep(response.data[i].med_cep);
            setMed_telefone(response.data[i].med_telefone);
            setMed_img(response.data[i].med_img);

            setCons_paciente({
              value: response.data[i].cons_paciente,
              label: response.data[i].pac_nome,
            });
            setCons_convenio({
              value: response.data[i].cons_convenio,
              label: response.data[i].conv_nome,
            });
            setCons_texto(response.data[i].cons_texto);
            setExame_texto(response.data[i].cons_texto_exame);
            setReceita_texto(response.data[i].cons_texto_receita);
            setCons_tipo({
              value: response.data[i].cons_tipo,
              label: tipo_nome,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getConvenio = () => {
    api
      .get('api/convenios?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayConvenios = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayConvenios.push({
            value: response.data[i].conv_id,
            label:
              response.data[i].conv_id + ' - ' + response.data[i].conv_nome,
          });
        }

        setStatusCons_convenio(arrayConvenios);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getPaciente = () => {
    api
      .get('api/pacientes', {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayPacientes = [];

        for (let i = 0; i < response.data.length; i++) {
          arrayPacientes.push({
            value: response.data[i].pac_id,
            label: response.data[i].pac_id + ' - ' + response.data[i].pac_nome,
            convenio: response.data[i].pac_convenio,
          });
        }

        setStatusCons_paciente(arrayPacientes);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getPagina = () => {
    api
      .get('api/paginas?med_id=' + sessionStorage.getItem('med_id'), {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        setPage_bottom(response.data[0].page_bottom);
        setPage_top(response.data[0].page_top);
        setPage_left(response.data[0].page_left);
        setPage_right(response.data[0].page_right);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const getCID = () => {
    api
      .get('api/cid', {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        const arrayCID = [];
        var b = '';
        for (let i = 0; i < response.data.length; i++) {
          arrayCID.push(response.data[i].descrabrev);
          /*  b =
            +" { value: '" +
            response.data[i].subcat +
            "', label: '" +
            response.data[i].descrabrev +
            "' },";*/
        }

        console.log(JSON.stringify(arrayCID));
        setStatusCID(arrayCID);
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };
  const handleChangePaciente = (paciente) => {
    setCons_paciente(paciente);

    let convenio = statusCons_convenio.filter(function (obj) {
      if (paciente.convenio == obj.value) {
        return obj.label;
      }
    });

    setCons_convenio(convenio[0]);
  };

  const handleChangeConvenio = (convenio) => {
    setCons_convenio(convenio);
  };

  const handleChangeTipo = (tipo) => {
    setCons_tipo(tipo);
  };
  const handleChangeCID = (CID) => {
    setCID(CID);
  };
  const RemoveAspasChaves = (value) => {
    value = value.replace(/"/gi, '');
    value = value.replace('}', '');
    value = value.replace('{', '');
    return value;
  };

  const save = (e, method, status) => {
    if (!cons_texto) {
      return false;
    }
    e.preventDefault();
    e.stopPropagation();

    let data, url;

    if (cons_codigo) {
      method = 'put';
    } else {
      handleInfo('Falha ao carregar o codigo');
      return false;
    }

    if (method === 'put') {
      data = {
        cons_codigo: cons_codigo,
        cons_data: cons_data + 'T' + cons_hora + ':00',
        cons_medico: parseInt(sessionStorage.getItem('med_id')),
        cons_paciente: cons_paciente.value,
        cons_convenio: cons_convenio.value,
        cons_tipo: cons_tipo.value,
        cons_status: 3,
        cons_texto: cons_texto,
        exame_texto: exame_texto,
        receita_texto: receita_texto,
      };

      url = 'api/consultas/' + cons_codigo;
    } else if (method === 'delete') {
      //    url = url + "/" + idModal + "/" + status;
      url = 'api/consultas/' + idModal;
      data = {
        id: idModal,
        status: status,
      };
      setNomeModal('');
      setIdModal('');
    }

    // return false;
    api({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'bearer ' + sessionStorage.getItem('token'),
        tcdocpw: sessionStorage.getItem('senha'),
        tcdocuserlogin:
          sessionStorage.getItem('cargo') === 'M'
            ? sessionStorage.getItem('med_email')
            : sessionStorage.getItem('secretaria_email'),
      },
    })
      .then((response) => {
        handleSuccess(response.headers.statusmessage);
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        history.push(`/atendimento`);
      })
      .catch((error) => {
        //  catchResponse();

        catchResponse(error.response.status);
        console.log(error);
      });
  };

  const limpar = () => {
    setPage_left(0);
    setPage_right(0);
    setPage_top(0);
    setPage_bottom(0);
    setMed_numero('');
    setCons_codigo('');
    setMed_endereco('');
    setMed_conselho('');
    setMed_bairro('');
    setCons_data('');
    setCid_municipio('');
    setMed_img('');
    setCons_hora('');
    setCons_hora_fim('');
    setCons_hora_ini('');
    setCons_hora_afastado_fim('');
    setCons_hora_afastado_ini('');
    setCons_medico('');
    setCons_paciente('');
    setCons_convenio('');
    setMed_telefone('');
    setMed_cep('');
    setCons_texto('');
    setCons_tipo('');
    setCons_status(0);
  };

  const editar = (consulta) => {
    setCons_medico(consulta.cons_medico);
    setCons_data(consulta.cons_data);
    setCons_paciente(consulta.cons_paciente);
    setCons_convenio(consulta.cons_convenio);
    setCons_texto(consulta.cons_texto);
    setCons_tipo(consulta.cons_tipo);
    setExame_texto(consulta.cons_texto_exame);
    setReceita_texto(consulta.cons_texto_receita);
    setCons_status(consulta.cons_status);
    setCons_codigo(consulta.cons_codigo);
    setCons_hora(consulta.cons_data.substring(11, 16));
    setCons_hora_ini(consulta.cons_data.substring(11, 16));
  };

  const setData = (data) => {
    setPac_nascimento(data);
    setPac_idade(calcularIdade(data));
  };

  const VoltarHistory = () => {
    //  history.push('/atendimento');
    var res = location.pathname.split('/');
    let paciente_id = res[2];
    let consulta_codigo = res[3];
    history.push(`/prontuario/${paciente_id}/${consulta_codigo}`);
  };

  function ImprimirAtestado(e, tipo) {
    e.preventDefault();
    e.stopPropagation();

    var dataAtual = new Date();
    var dia = dataAtual.getDate();
    var mes = dataAtual.getMonth() + 1;
    var ano = dataAtual.getFullYear();
    var horas = dataAtual.getHours();
    var minutos = dataAtual.getMinutes();
    var segundos = dataAtual.getSeconds();

    var dataAtual =
      adicionaZero(dia) +
      '/' +
      adicionaZero(mes) +
      '/' +
      ano +
      ' ' +
      horas +
      ':' +
      adicionaZero(minutos) +
      ':' +
      adicionaZero(segundos);

    var logomarca = '';
    if (med_img) {
      logomarca =
        'https://tcdoc.viaconect.com/imagens/medico/' +
        sessionStorage.getItem('med_id') +
        '/' +
        med_img.substr(0, med_img.length - 1);
    }
    if (tipo === 'padrao') {
      let valid = validaImpressao();
      if (!valid) {
        return false;
      } else {
        var randNumber = Math.floor(Math.random() * 99);
        var mywindow = window.open(
          'https://tcdoc.viaconect.com?q=' + randNumber,
          'PRINT',
          'height=1200,width=1200',
          '_blank',
          'location = yes',
          'clearcache = yes',
        );
        mywindow.document.write(
          // Receita(page_top, page_bottom, page_left, page_right, dataAtual)
          Padrao(
            page_top,
            page_bottom,
            page_left,
            page_right,
            dataAtual,
            cons_data,
            cons_paciente.label.toLowerCase(),
            sessionStorage.getItem('med_nome').toLowerCase(),
            med_conselho + ' ' + med_conselho_codigo,
            logomarca,
            cons_hora_ini,
            cons_hora_fim,
            document.getElementById('CID').value,
            radio_consulta,
            cons_event_text,
            cons_hora_afastado_ini,
            cons_hora_afastado_fim,
          ),
        );
      }
    } else {
      let valid = validaImpressaoUnimed();
      if (!valid) {
        return false;
      } else {
        var randNumber = Math.floor(Math.random() * 99);
        var mywindow = window.open(
          'https://tcdoc.viaconect.com?q=' + randNumber,
          'PRINT',
          'height=1200,width=1200',
          '_blank',
          'location = yes',
          'clearcache = yes',
        );

        mywindow.document.write(
          Unimed(
            page_top,
            page_bottom,
            page_left,
            page_right,
            dataAtual,
            cons_data,
            cons_paciente.label.toLowerCase(),
            sessionStorage.getItem('med_nome').toLowerCase(),
            med_conselho + ' ' + med_conselho_codigo,
            logomarca,
            cons_hora_ini,
            cons_hora_fim,
            document.getElementById('CID1').value,
            radio_consulta_unimed,
            radio_periodo,
            document.querySelector(
              'input[name=radio_unimed_afastamento]:checked',
            ).value,
            cons_event_text,
            cons_hora_afastado_ini,
            cons_hora_afastado_fim,
          ),
        );
      }
    }

    //  mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(function () {
      mywindow.print();
      mywindow.close();
    }, 1000);
    return true;
  }

  const getRadioConsultas = (event) => {
    switch (event.target.value) {
      case '1':
        setRadio_consulta(1);
        document.getElementById('cons_hora_afastado_fim').readOnly = true;
        document.getElementById('cons_hora_afastado_ini').readOnly = true;
        setCons_hora_afastado_fim('');
        setCons_hora_afastado_ini('');
        break;
      case '2':
        setRadio_consulta(2);
        document.getElementById('cons_hora_afastado_fim').readOnly = true;
        document.getElementById('cons_hora_afastado_ini').readOnly = true;
        setCons_hora_afastado_fim('');
        setCons_hora_afastado_ini('');
        break;
      case '3':
        setRadio_consulta(3);
        document.getElementById('cons_hora_afastado_fim').readOnly = false;
        document.getElementById('cons_hora_afastado_ini').readOnly = false;

        break;
      default:
        setRadio_consulta(3);
        document.getElementById('cons_hora_afastado_fim').readOnly = false;
        document.getElementById('cons_hora_afastado_ini').readOnly = false;
    }
  };

  const getRadioUnimedAfastamento = (event) => {
    switch (event.target.value) {
      case 'm':
        setRadio_periodo('m');
        setRadioUnimedAfastamento(3);
        document.getElementById('unimedAfastamento3').checked = true;
        break;
      case 't':
        setRadio_periodo('t');
        setRadioUnimedAfastamento(3);
        document.getElementById('unimedAfastamento3').checked = true;

        break;
      case 'n':
        setRadio_periodo('n');
        setRadioUnimedAfastamento(3);
        document.getElementById('unimedAfastamento3').checked = true;
        break;

      default:
        radioDefault(event.target.value);
    }
  };
  const radioDefault = (value) => {
    if (document.getElementById('periodo_manha')) {
      document.getElementById('periodo_manha').checked = false;
    }
    if (document.getElementById('periodo_tarde')) {
      document.getElementById('periodo_tarde').checked = false;
    }
    if (document.getElementById('periodo_noite')) {
      document.getElementById('periodo_noite').checked = false;
    }

    let tamanho = value.length;
    if (tamanho > 1) {
      setRadioUnimedAfastamento(4);
    } else {
      if (value == 4) {
        document.getElementById('cons_hora_afastado_fim1').readOnly = false;
        document.getElementById('cons_hora_afastado_ini1').readOnly = false;
      } else {
        document.getElementById('cons_hora_afastado_fim1').readOnly = true;
        document.getElementById('cons_hora_afastado_ini1').readOnly = true;
        setCons_hora_afastado_fim('');
        setCons_hora_afastado_ini('');
      }
      setRadioUnimedAfastamento(value);
    }
    console.log('RadioUnimedAfastamento ' + radioUnimedAfastamento);
  };
  const getRadioConsultaUnimeds = (event) => {
    setRadio_consulta_unimed(event.target.value);
  };

  const validaImpressao = () => {
    if (!cons_event_text) {
      handleInfo('Preencha o campo "Esteve em" que tipo de operação');
      return false;
    }

    if (!cons_hora_fim) {
      handleInfo('Preencha o horário de saida da consulta');
      return false;
    }
    if (!document.getElementById('CID').value) {
      handleInfo('Selecione uma CID');
      return false;
    }
    if (validaRadio()) {
      if (!page_left) {
        handleInfo(
          'Aguande um momemento para carregar as config de margens da pagina',
        );
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const validaRadio = () => {
    let radio = parseInt(radio_consulta);
    if (radio === 1 || radio === 2) {
      return true;
    } else if (
      radio === 3 &&
      cons_hora_afastado_fim &&
      cons_hora_afastado_ini
    ) {
      return true;
    } else {
      handleInfo('Selecione uma das 3 opções ');
      return false;
    }
  };

  const validaImpressaoUnimed = () => {
    if (!cons_hora_fim) {
      handleInfo('Preencha o horário de saida da consulta');
      return false;
    }
    if (!document.getElementById('CID1').value) {
      handleInfo('Selecione uma CID');
      return false;
    }
    if (validaRadioUnimed()) {
      if (!page_left) {
        handleInfo(
          'Aguande um momemento para carregar as config de margens da pagina',
        );
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const validaRadioUnimed = () => {
    let radio = parseInt(radio_consulta_unimed);
    let radioAfastamento = parseInt(radioUnimedAfastamento);

    if (radio > 0) {
      if (radioAfastamento > 0) {
        if (radioAfastamento === 3) {
          if (radio_periodo) {
            return true;
          } else {
            handleInfo('Selecione um dos periodos seja Manhã,Tarde ou Noite');
            return false;
          }
        } else if (radioAfastamento === 4) {
          if (cons_hora_afastado_ini && cons_hora_afastado_fim) {
            return true;
          } else {
            handleInfo('Preencha a o horário inicial e final de cobertura');
            return false;
          }
        } else {
          return true;
        }
      } else {
        handleInfo('Selecione uma das 6 opções do Tempo de afastamento:');
        return false;
      }
    } else {
      handleInfo('Selecione uma das 5 opções de PARA:');
      return false;
    }
  };
  const caracterLimit = (e) => {
    if (e.target.value.length > 2) {
      autocomplete_datalist(e.target.id, '#1eb1ff');
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div class="card" style={{ border: '0px solid rgba(0,0,0,.125)' }}>
          <div
            class="card-header"
            style={{ padding: '0px', borderTop: '0px', background: '#f8f9fa' }}
          >
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pills-login-tab"
                  data-toggle="pill"
                  href="#pills-login"
                  role="tab"
                  aria-controls="pills-login"
                  aria-selected="true"
                >
                  Atestado padrão
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-registrar-tab"
                  data-toggle="pill"
                  href="#pills-registrar"
                  role="tab"
                  aria-controls="pills-registrar"
                  aria-selected="false"
                >
                  Atestado Unimed
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-login"
                onClick={(e) => setNavForm('login')}
                role="tabpanel"
                aria-labelledby="pills-login-tab"
              >
                <form id="form_paciente" onSubmit={(e) => save(e)}>
                  <div className="row mb-3">
                    <div className="col-md-6 col-12 mb-3">
                      <label htmlFor="cons_paciente">
                        Atesto que o(a) Sr(a){' '}
                        {cons_paciente.value ? cons_paciente.label : ''}
                        <strong className="fild-required">*</strong>
                      </label>
                      <Select
                        id="cons_paciente"
                        readOnly
                        value={cons_paciente}
                        onChange={handleChangePaciente}
                        options={[] /*statusCons_paciente*/}
                        placeholder={<div>Selecione</div>}
                      />
                      <input
                        style={{
                          top: '43px',
                          position: 'absolute',
                          zIndex: '-1',
                          width: '10%',
                        }}
                        type="password"
                        id="cons_paciente_html5_validate"
                        autoComplete="new-password"
                        value={cons_paciente}
                        required
                      />
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                      <label htmlFor="cons_data3">
                        Esteve em {cons_event_text ? cons_event_text : ''}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="cons_data3"
                        onChange={(e) => setCons_event_text(e.target.value)}
                        value={cons_event_text}
                        required
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="cons_data2">
                        em{' '}
                        {cons_data
                          ? cons_data.split('-').reverse().join('/')
                          : ''}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="cons_data2"
                        readOnly
                        onChange={(e) =>
                          setCons_data(removeAcento(e.target.value))
                        }
                        value={cons_data}
                        required
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="cons_hora_padrao_ini">
                        das {cons_hora_ini ? cons_hora_ini : ''}
                        <strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        id="cons_hora_padrao_ini"
                        readOnly
                        onChange={(e) => setCons_hora_ini(e.target.value)}
                        value={cons_hora_ini}
                        required
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <label htmlFor="cons_hora_padrao_fim">
                        às {cons_hora_fim ? cons_hora_fim : ''}
                        <strong className="fild-required">*</strong>
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        id="cons_hora_padrao_fim"
                        onChange={(e) => setCons_hora_fim(e.target.value)}
                        value={cons_hora_fim}
                        required
                      />
                    </div>
                  </div>
                  <div className="row" onChange={getRadioConsultas.bind(this)}>
                    <div className="col-md-12 col-12 mb-3">
                      <input
                        id="r1"
                        type="radio"
                        name="radio_consulta"
                        value="1"
                      />
                      <label for="r1">Retornar ao trabalho</label>
                    </div>
                    <div className="col-md-12 col-12 mb-3">
                      <input
                        id="r2"
                        type="radio"
                        name="radio_consulta"
                        value="2"
                      />
                      <label for="r2">
                        Permanecer em repouso no dia de hoje
                      </label>
                    </div>
                    <div className="col-md-12 col-12 mb-3">
                      <input
                        id="r3"
                        type="radio"
                        name="radio_consulta"
                        value="3"
                      />
                      <label for="r3">
                        Permanecer afastado no periodo de{' '}
                        <input
                          type="time"
                          id="cons_hora_afastado_ini"
                          onChange={(e) =>
                            setCons_hora_afastado_ini(e.target.value)
                          }
                          value={cons_hora_afastado_ini}
                          required
                        />
                        {'  à  '}
                        <input
                          type="time"
                          id="cons_hora_afastado_fim"
                          onChange={(e) =>
                            setCons_hora_afastado_fim(e.target.value)
                          }
                          value={cons_hora_afastado_fim}
                          required
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-12 mb-3">
                      <label htmlFor="CID">CID:</label>
                      <input
                        className="input form-control"
                        id="CID"
                        onChange={(e) => caracterLimit(e)}
                        type="text"
                        placeholder="CID"
                        data-list={statusCID}
                      />
                      <span className="arrow-up arrow-up-CID"></span>
                      <ul className="list list-CID"></ul>
                    </div>
                    {/*<div className="col-md-6 col-12 mb-3">
                      <label htmlFor="CID">
                        CID
                        <strong className="fild-required">*</strong>
                      </label>
                      <Select
                        id="CID"
                        value={CID}
                        onChange={handleChangeCID}
                        options={statusCID}
                        placeholder={<div>Selecione</div>}
                      />
                      <input
                        style={{
                          top: '43px',
                          position: 'absolute',
                          zIndex: '-1',
                          width: '10%',
                          border: '0px',
                        }}
                        type="password"
                        id="CID_html5_validate"
                        autoComplete="new-password"
                        value={CID}
                        required
                      />
                    </div>
*/}
                  </div>{' '}
                  <div className="text-left mb-3 mt-3 ">
                    <button
                      className="btn btn-sm mb-3 mr-3 btn_Cadastrar "
                      type="button"
                      id="btnAlterar"
                      onClick={(e) => ImprimirAtestado(e, 'padrao')}
                      style={{ height: '35px' }}
                    >
                      Imprimir atestado
                    </button>{' '}
                    <button
                      className="btn btn-sm  mb-3 mr-3 btn-secondary "
                      type="button"
                      id="btnVoltar"
                      onClick={(e) => VoltarHistory()}
                    >
                      {AngleDoubleLeft} Voltar
                    </button>
                  </div>
                </form>{' '}
              </div>
              <div
                class="tab-pane fade"
                id="pills-registrar"
                role="tabpanel"
                onClick={(e) => setNavForm('registrar')}
                aria-labelledby="pills-registrar-tab"
              >
                <div className="row">
                  <div className="col-md-6 col-12 mb-3">
                    <label htmlFor="cons_paciente1">
                      O(a) Sr(a){' '}
                      {cons_paciente.value ? cons_paciente.label : ''}
                      <strong className="fild-required">*</strong>
                    </label>
                    <Select
                      id="cons_paciente1"
                      value={cons_paciente}
                      onChange={handleChangePaciente}
                      options={[] /*statusCons_paciente*/}
                      placeholder={<div>Selecione</div>}
                    />
                    <input
                      style={{
                        top: '43px',
                        position: 'absolute',
                        zIndex: '-1',
                        width: '10%',
                      }}
                      type="password"
                      id="cons_paciente1_html5_validate"
                      autoComplete="new-password"
                      value={cons_paciente}
                      required
                    />
                  </div>

                  <div className="col-md-6 col-12  mb-3">
                    <div className=" mt-3 ">
                      <b>
                        Compareceu a esta clínica e chegou às{' '}
                        <input
                          type="time"
                          id="cons_hora_ini"
                          style={{ background: '#e9ecef' }}
                          readOnly
                          onChange={(e) => setCons_hora_ini(e.target.value)}
                          value={cons_hora_ini}
                          required
                        />
                        {'  e saiu às  '}
                        <input
                          type="time"
                          id="cons_hora_fim"
                          onChange={(e) => setCons_hora_fim(e.target.value)}
                          value={cons_hora_fim}
                          required
                        />
                      </b>
                    </div>
                  </div>

                  <div className="col-md-6 col-12  mb-3">
                    <div className=" mt-3 ">
                      <p>
                        <b style={{ color: 'black' }}>Para:</b>
                      </p>
                      <br />
                      <div
                        className="row"
                        onChange={getRadioConsultaUnimeds.bind(this)}
                      >
                        <div className="col-md-12 col-12 mb-3">
                          <input
                            id="unimed"
                            type="radio"
                            name="radio_consulta_unimed"
                            value="1"
                          />
                          <label for="unimed">Consulta</label>
                        </div>
                        <div className="col-md-12 col-12 mb-3">
                          <input
                            id="unimed2"
                            type="radio"
                            name="radio_consulta_unimed"
                            value="2"
                          />
                          <label for="unimed2">Fisioterapia</label>
                        </div>
                        <div className="col-md-12 col-12 mb-3">
                          <input
                            id="unimed3"
                            type="radio"
                            name="radio_consulta_unimed"
                            value="3"
                          />
                          <label for="unimed3">Acompanhar familiar</label>
                        </div>
                        <div className="col-md-12 col-12 mb-3">
                          <input
                            id="unimed4"
                            type="radio"
                            name="radio_consulta_unimed"
                            value="4"
                          />
                          <label for="unimed4">
                            Retorno,entregar exames etc.
                          </label>
                        </div>
                        <div className="col-md-12 col-12 mb-3">
                          <input
                            id="unimed5"
                            type="radio"
                            name="radio_consulta_unimed"
                            value="5"
                          />
                          <label for="unimed5">
                            Realizar exames complementares
                          </label>
                        </div>
                      </div>
                      <b className=" mb-5" style={{ color: 'black' }}>
                        Tempo de afastamento:{' '}
                      </b>
                      <div
                        className="row mt-3"
                        onChange={getRadioUnimedAfastamento.bind(this)}
                      >
                        <div className="col-md-12 col-12 mb-3">
                          <input
                            id="unimedAfastamento1"
                            type="radio"
                            name="radio_unimed_afastamento"
                            value="1"
                          />
                          <label for="unimedAfastamento1">
                            Devendo retornar ao trabalho do dia de hoje
                          </label>
                        </div>
                        <div className="col-md-12 col-12 mb-3">
                          <input
                            id="unimedAfastamento2"
                            type="radio"
                            name="radio_unimed_afastamento"
                            value="2"
                          />
                          <label for="unimedAfastamento2">
                            Deverá ser dispensado do trabalho no dia de hoje
                          </label>
                        </div>
                        <div className="col-md-12 col-12 mb-3">
                          <input
                            id="unimedAfastamento3"
                            type="radio"
                            name="radio_unimed_afastamento"
                            value="3"
                          />
                          <label for="unimedAfastamento3">
                            Deverá ser dispensado no período
                            <div className="mt-3 ">
                              &nbsp;&nbsp;
                              <input
                                id="periodo_manha"
                                type="radio"
                                name="periodo"
                                value="m"
                              />
                              <label for="periodo_manha">Manhã</label>
                              &nbsp;&nbsp;
                              <input
                                id="periodo_tarde"
                                type="radio"
                                name="periodo"
                                value="t"
                              />
                              <label for="periodo_tarde">Tarde</label>
                              &nbsp;&nbsp;
                              <input
                                id="periodo_noite"
                                type="radio"
                                name="periodo"
                                value="n"
                              />
                              <label for="periodo_noite">Noite</label>
                            </div>
                          </label>
                        </div>
                        <div className="col-md-12 col-12 mb-3">
                          <input
                            id="unimedAfastamento4"
                            type="radio"
                            name="radio_unimed_afastamento"
                            value="4"
                          />
                          <label for="unimedAfastamento4">
                            Deverá ser dispensado no período de{' '}
                            <input
                              type="time"
                              id="cons_hora_afastado_ini1"
                              onChange={(e) =>
                                setCons_hora_afastado_ini(e.target.value)
                              }
                              value={cons_hora_afastado_ini}
                              required
                            />
                            {'  a  '}
                            <input
                              type="time"
                              id="cons_hora_afastado_fim1"
                              onChange={(e) =>
                                setCons_hora_afastado_fim(e.target.value)
                              }
                              value={cons_hora_afastado_fim}
                              required
                            />
                          </label>
                        </div>
                        <div className="col-md-12 col-12 mb-3">
                          <input
                            id="unimedAfastamento5"
                            type="radio"
                            name="radio_unimed_afastamento"
                            value="5"
                          />
                          <label for="unimedAfastamento5">
                            Deverá ser dispensado por período indeterminado
                          </label>
                        </div>
                        <div className="col-md-12 col-12 mb-3">
                          <input
                            id="unimedAfastamento6"
                            type="radio"
                            name="radio_unimed_afastamento"
                            value="6"
                          />
                          <label for="unimedAfastamento6">
                            É acidente de trabalho , deverá ser encaminhado ao
                            seguro
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mb-3">
                    <div className="row">
                      <div className="col-md-4 col-12 mb-3">
                        <label htmlFor="cons_data1">Data:</label>
                        <input
                          type="date"
                          className="form-control"
                          id="cons_data1"
                          readOnly
                          onChange={(e) => setCons_data(e.target.value)}
                          value={cons_data}
                          required
                        />
                      </div>
                      <div className="col-md-8 col-12 mb-3">
                        <label htmlFor="CID1">CID:</label>
                        <input
                          className="input form-control"
                          id="CID1"
                          onChange={(e) => caracterLimit(e)}
                          type="text"
                          placeholder="CID"
                          data-list={statusCID}
                        />
                        <span className="arrow-up arrow-up-CID1"></span>
                        <ul className="list list-CID1"></ul>
                      </div>
                    </div>
                    <div className="text-left mb-3 mt-3 ">
                      <button
                        className="btn btn-sm mb-3 mr-3 btn_Cadastrar "
                        type="submit"
                        id="btnAlterar"
                        onClick={(e) => ImprimirAtestado(e, 'unimed')}
                        style={{ height: '35px' }}
                      >
                        Imprimir atestado
                      </button>{' '}
                      <button
                        className="btn btn-sm  mb-3 mr-3 btn-secondary "
                        type="submit"
                        id="btnVoltar"
                        onClick={(e) => VoltarHistory()}
                      >
                        {AngleDoubleLeft} Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Atestado;
