/**************** DESCRIÇÃO DO PLUGIN**************************************************************************************************************************************************** */
/* é um autocomplate que filtra um array de valores sem index de um determinado campo input ao mesmo tempo que colore as palavras sugestivas de acordo com o que foi digitado no input */

/* INSERIR NO INPUT  ===> onKeyUp={e => autocomplete(e.target, premio,'#1199da',1)}   */
/* 1- parametro = input;
   2- parametro = array com os termos;
   3- parametro = cor para colorir as sugestões;
   obs:caso a cor não for setada,a cor azul (#1199da) sera a cor padrão
   4 - parametro = ordem que busca nas palavras, caso seja 
       1 vai procurar apartir da 1 letra da palavra,caso seja
       2 vai procurar em qualquer parte da palavra
 */
/*********************************************************************************************************************************************************************************/
import './autocomplete.css';

export const autocomplete = (inp, array, cor, ordem) => {
  let li,
    list,
    value,
    v_index,
    cont = 0;
  var currentFocus = 0,
    loop = 0;
  if (!cor) {
    cor = '#1199da';
  }
  if (!ordem) {
    ordem = 1;
  }

  inp.addEventListener('keyup', function (e) {
    value = e.target.value;
    clear();
    list = document.createElement('ul');

    list.className = 'list-autocomplete ';
    list.id = 'list-autocomplete';
    inp.parentNode.appendChild(list);

    array.forEach((v, i) => {
      currentFocus = -1;
      if (ordem == 1) {
        if (
          value &&
          v.substr(0, value.length).toUpperCase() == value.toUpperCase()
        ) {
          li = document.createElement('li');
          li.innerHTML =
            '<strong style="color:' +
            cor +
            ';">' +
            v.substr(0, value.length) +
            '</strong>' +
            v.substr(value.length) +
            '<input type="hidden" value="' +
            v +
            '" />';
          li.addEventListener('click', function () {
            inp.value = this.getElementsByTagName('input')[0].value;
            clear();
          });
          list.append(li);
        }
      } else if (ordem == 2) {
        v_index = v.indexOf(value.toUpperCase());
        if (v_index !== -1) {
          if (cont < 3) {
            li = document.createElement('li');
            li.innerHTML =
              v.substr(0, v_index) +
              '<strong style="color:' +
              cor +
              ';">' +
              v.substr(v_index, value.length) +
              '</strong>' +
              v.substr(v_index + value.length, v.length) +
              '<input type="hidden" value="' +
              v +
              '" />';
            li.addEventListener('click', function () {
              inp.value = this.getElementsByTagName('input')[0].value;
              clear();
            });
            list.append(li);

            cont++;
          }
        }
      }
    });
  });
};

const clear = () => {
  if (document.querySelector('.list-autocomplete')) {
    document
      .querySelector('.list-autocomplete')
      .parentNode.removeChild(document.querySelector('.list-autocomplete'));
  }
};
const colorir = (value, palavra, cor) => {
  let v_index = palavra.indexOf(value);
  if (v_index !== -1) {
    return (
      palavra.substr(0, v_index) +
      '<strong style="color:' +
      cor +
      ';">' +
      palavra.substr(v_index, value.length) +
      '</strong>' +
      palavra.substr(v_index + value.length, palavra.length)
    );
  }
};

export const autocomplete_datalist = (id, cor) => {
  console.log(id);
  document.getElementsByClassName('arrow-up-' + id)[0].style.display = 'block';
  document.getElementsByClassName('list-' + id)[0].style.display = 'block';

  if (!cor) {
    cor = 'red';
  }
  var input = document.querySelector('#' + id);
  var list = document.querySelector('.list-' + id);
  var arrow = document.querySelector('.arrow-up-' + id);
  var data_list = input.getAttribute('data-list');
  var selecting = -1;
  var list_li;
  //console.log(JSON.stringify(id));

  // Make data list array
  data_list = data_list.split(',');

  var typing_arr = [];

  input.addEventListener('keyup', function (e) {
    list.innerHTML = '';
    var typing = data_list;

    // Filter data-list array
    typing = typing.filter(function (item) {
      return item.toLowerCase().search(e.target.value.toLowerCase()) != -1;
    });

    // If value empty hide list and arrow
    if (e.target.value === '' || typing.length === 0) {
      list.classList.add('hide');
      list.classList.remove('show');

      arrow.classList.add('hide');
      arrow.classList.remove('show');
    } else {
      list.classList.remove('hide');
      list.classList.add('show');

      arrow.classList.remove('hide');
      arrow.classList.add('show');
    }

    typing_arr = typing;

    // Show filtered data-list
    typing_arr.map(function (list_item) {
      var li = document.createElement('li');
      list.appendChild(li);
      li.innerHTML = colorir(
        e.target.value.toUpperCase(),
        list_item.toUpperCase(),
        cor,
      );

      // Close list when click on li and make clicked li input value
      li.addEventListener('click', function (e) {
        input.value = e.target.textContent;
        list.classList.add('hide');
        arrow.classList.add('hide');
      });

      // Close list when click enter and make selecting li input value
      if (e.which == 13 || e.keyCode == 13) {
        if (selecting > -1) {
          input.value = list_li[selecting].textContent;
          list.classList.add('hide');
          arrow.classList.add('hide');
        }
      }
    });

    // Down and up buttons
    if (e.which == 40 || e.keyCode == 40) {
      selecting++;

      list_li = list.querySelectorAll('li');
      if (selecting + 1 > list_li.length) {
        selecting = 0;
      }

      list_li[selecting].classList.add('selecting');

      // When input value change, make selecting -1
      input.addEventListener('input', function (e) {
        selecting = -1;
      });
    } else if (e.which == 38 || e.keyCode == 38) {
      selecting--;

      list_li = list.querySelectorAll('li');
      if (selecting < 0) {
        selecting = list_li.length - 1;
      }

      list_li[selecting].classList.add('selecting');
    }

    // Keep selecting class when press a button
    list_li = list.querySelectorAll('li');
    // list_li[selecting].classList.add("selecting");
  });
  input.addEventListener('blur', function (e) {
    setTimeout(function () {
      document.getElementsByClassName('list-' + id)[0].style.display = 'none';
      document.getElementsByClassName('arrow-up-' + id)[0].style.display =
        'none';
    }, 200);
  });
};
