import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { api } from '../utils/WebApiConfig';
import {
  handleError,
  handleSuccess,
  handleInfo,
  handleWarn,
} from '../utils/Toastify';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Edit,
  AngleDoubleLeft,
  ExclamationTriangle,
} from '../utils/Fontawesome';
import { Link, TablePagination } from '@material-ui/core';
import {
  removeAcento,
  mask_Numeros,
  calcularIdade,
  mask_data,
} from '../utils/Mascara';
import axios from 'axios';
import ImprimirProtuario from './ImprimirProntuario';
import { Receita } from '../templates/receita/Receita';
//import { Prontuario } from '../templates/receita/Prontuario';
import { Prontuario } from '../templates/receita/Prontuario';
import { Exame } from '../templates/receita/Exame';
import { ReceitaControlada } from '../templates/receita/ReceitaControlada';
import NotificarSuporte from './NotificarSuporte';
import AlergiaCad from './AlergiaCad';
export const adicionaZero = (numero) => {
  if (numero <= 9) return '0' + numero;
  else return numero;
};

export const adicionaZeroMinutes = (numero) => {
  if (numero === 0) return '0' + numero;
  else return numero;
};
const Atendimento = () => {
  let history = useHistory();
  const location = useLocation();

  const [cons_codigo, setCons_codigo] = useState('');
  const [cons_data, setCons_data] = useState('');
  const [cons_hora, setCons_hora] = useState('');
  const [cons_alergia, setCons_alergia] = useState('');
  const [cons_medico, setCons_medico] = useState('');
  const [pac_data, setPac_data] = useState('');

  const [med_numero, setMed_numero] = useState('');
  const [med_endereco, setMed_endereco] = useState('');
  const [med_conselho, setMed_conselho] = useState('');
  const [med_conselho_codigo, setMed_conselho_codigo] = useState('');
  const [med_bairro, setMed_bairro] = useState('');
  const [med_img, setMed_img] = useState('');
  const [cid_municipio, setCid_municipio] = useState('');
  const [cid_uf, setCid_uf] = useState('');
  const [med_cep, setMed_cep] = useState('');
  const [med_telefone, setMed_telefone] = useState('');

  const [page_left, setPage_left] = useState(0);
  const [page_right, setPage_right] = useState(0);
  const [page_top, setPage_top] = useState(0);
  const [page_bottom, setPage_bottom] = useState(0);
  const [pac_nome, setPac_nome] = useState('');
  const [cons_paciente, setCons_paciente] = useState('');
  const [statusCons_paciente, setStatusCons_paciente] = useState([]);
  const [cons_convenio, setCons_convenio] = useState('');
  const [statusExame, setStatusExame] = useState([]);
  const [exame, setExame] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [atualizarPaciente, setAtualizarPaciente] = useState(false);
  const [alergiasSelecionadas, setAlergiasSelecionadas] = useState([]);
  const [consultas, setConsultas] = useState([]);
  const [statusCons_convenio, setStatusCons_convenio] = useState('');
  const [cons_texto, setCons_texto] = useState('');
  const [exame_texto, setExame_texto] = useState('');
  const [receita_texto, setReceita_texto] = useState('');
  const [cons_tipo, setCons_tipo] = useState('');
  const [statusCons_tipo, setStatusCons_tipo] = useState([
    { value: 'C', label: 'Consulta' },
    { value: 'P', label: '1. Consulta' },
    { value: 'R', label: 'Retorno' },
    { value: 'T', label: 'Receita' },
    { value: 'E', label: 'Exame' },
    { value: 'A', label: 'Ausente' },
  ]);

  /*const [statusCons_status, setStatusCons_status] = useState([
    { value: 0, label: "Agendado" },
    { value: 1, label: "Aguardando" },
    { value: 2, label: "Em atendimento" },
    { value: 3, label: "Atendido" },
    { value: 4, label: "Faltou " }, 
  ]);*/

  const [cons_status, setCons_status] = useState(0);
  const [pac_nascimento, setPac_nascimento] = useState('');
  const [pac_idade, setPac_idade] = useState('');

  // variaveis da paginaçãp
  const [arrayFiltrado, setArrayFiltrado] = useState('');
  const [busca, setBusca] = useState('');
  const [consultaPag, setConsultaPag] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    var inicio = newPage * rowsPerPage + 1;
    var fim = inicio + (rowsPerPage - 1);
    /*  console.log(
     "rowsPerPage:" +
       rowsPerPage +
       " newPage " +
       newPage +
       " " +
       inicio +
       " ---- " +
       fim
   );*/
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setConsultaPag(cidadePag);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    var inicio = 0 * event.target.value + 1;
    var fim = inicio + (event.target.value - 1);
    if (fim > arrayFiltrado.length) {
      fim = arrayFiltrado.length;
    }
    const cidadePag = [];
    for (let i = inicio - 1; i < fim; i++) {
      cidadePag.push(arrayFiltrado[i]);
    }
    setConsultaPag(cidadePag);
  };

  const catchResponse = (status) => {
    if (status) {
      if (status === 401) {
        if (document.getElementById('btn_limpar')) {
          document.getElementById('btn_limpar').click();
        }
        handleInfo('Sessão expirada.');
        history.push('/login');
      }
    }
    handleError('Ocorreu um erro inesperado na execução desta operação');
  };

  useEffect(() => {
    if (document.getElementsByClassName('modal-backdrop')[0]) {
      var no = document.getElementsByClassName('modal-backdrop')[0];
      if (no.parentNode) {
        no.parentNode.removeChild(no);
      }
    }
    var res = location.pathname.split('/');
    sessionStorage.setItem('pac_id', res[3]);

    getConsulta(res[2]);

    //   getConvenio();
  }, []);

  const getConsulta = async (paciente, codigo) => {
    let parametros =
      '?medico=' + sessionStorage.getItem('med_id') + '&paciente=' + paciente;

    await api
      .get('api/consultas' + parametros, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          if (document.getElementById('alertPaciente')) {
            document.getElementById('alertPaciente').style.display = 'none';
          }
        } else {
          if (document.getElementById('alertPaciente')) {
            document.getElementById('alertPaciente').style.display = '';
          }
        }

        setConsultas(response.data);
        setArrayFiltrado(response.data);
        setTotalPages(response.data.length);
        var inicio = 0;
        var fim = 10;
        const cidadePag = [];
        if (fim > response.data.length) {
          fim = response.data.length;
        }
        for (let i = inicio; i < fim; i++) {
          cidadePag.push(response.data[i]);
        }
        setConsultaPag(cidadePag);
        setPac_nome(response.data[0].pac_nome);
        try {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].cons_codigo == codigo) {
              /*  { value: "C", label: "Consulta" },
                    { value: "P", label: "1. Consulta" },
                    { value: "R", label: "Retorno" },
                    { value: "T", label: "Receita" },
                    { value: "E", label: "Exame" }, */
              let tipo_nome = '';
              switch (response.data[i].cons_tipo) {
                case 'C':
                  tipo_nome = 'Consulta';
                  break;
                case 'P':
                  tipo_nome = '1. Consulta';
                  break;
                case 'R':
                  tipo_nome = 'Retorno';
                  break;
                case 'T':
                  tipo_nome = 'Receita';
                  break;
                default:
                  tipo_nome = 'Exame';
              }

              setCons_codigo(response.data[i].cons_codigo);
              setData(response.data[i].pac_nascimento);
              setCons_data(response.data[i].cons_data.substring(0, 10));
              setCons_hora(response.data[i].cons_data.substring(11, 16));

              setMed_bairro(response.data[i].med_bairro);
              setMed_numero(response.data[i].med_numero);
              setMed_endereco(response.data[i].med_endereco);
              setMed_conselho(response.data[i].med_conselho);
              setMed_conselho_codigo(response.data[i].med_conselho_codigo);
              setCid_municipio(response.data[i].cid_municipio);
              setCid_uf(response.data[i].cid_uf);
              setMed_cep(response.data[i].med_cep);
              setMed_telefone(response.data[i].med_telefone);
              setMed_img(response.data[i].med_img);

              setCons_paciente({
                value: response.data[i].cons_paciente,
                label: response.data[i].pac_nome,
              });
              setCons_convenio({
                value: response.data[i].cons_convenio,
                label: response.data[i].conv_nome,
              });
              setCons_texto(response.data[i].cons_texto);
              setExame_texto(response.data[i].cons_texto_exame);
              setReceita_texto(response.data[i].cons_texto_receita);
              setCons_tipo({
                value: response.data[i].cons_tipo,
                label: tipo_nome,
              });
            }
          }
        } catch (error) {
          console.log(error);
          if (document.getElementById('alertPaciente')) {
            document.getElementById('alertPaciente').style.display = 'none';
          }
        } finally {
          //  getPaciente(paciente, response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const getPaciente = (pac_id, consulta) => {
    api
      .get('api/pacientes?pac_id=' + pac_id, {
        headers: {
          Authorization: 'bearer ' + sessionStorage.getItem('token'),
          tcdocpw: sessionStorage.getItem('senha'),
          tcdocuserlogin:
            sessionStorage.getItem('cargo') === 'M'
              ? sessionStorage.getItem('med_email')
              : sessionStorage.getItem('secretaria_email'),
        },
      })
      .then((response) => {
        try {
          const arrayPacientes = [];
          setPac_data(response.data);
          for (let i = 0; i < response.data.length; i++) {
            arrayPacientes.push({
              value: response.data[i].pac_id,
              label:
                response.data[i].pac_id + ' - ' + response.data[i].pac_nome,
              convenio: response.data[i].pac_convenio,
            });
          }

          setStatusCons_paciente(arrayPacientes);

          //   getMedicoMemed(response.data);
        } catch (error) {
          console.log('falha ao preencher hooks em Paciente');
        }
      })
      .catch((error) => {
        if (error.response) {
          catchResponse(error.response.status);
        }
      });
  };

  const limpar = () => {
    setPage_left(0);
    setPage_right(0);
    setPage_top(0);
    setPage_bottom(0);
    setMed_numero('');
    setCons_codigo('');
    setMed_endereco('');
    setMed_conselho('');
    setMed_bairro('');
    setCons_data('');
    setCid_municipio('');
    setMed_img('');
    setCons_hora('');
    setCons_medico('');
    setCons_paciente('');
    setCons_convenio('');
    setMed_telefone('');
    setMed_cep('');
    setCons_texto('');
    setCons_tipo('');
    setCons_status(0);
  };

  const editar = (consulta) => {
    setCons_medico(consulta.cons_medico);
    setCons_data(consulta.cons_data);
    setCons_paciente(consulta.cons_paciente);
    setCons_convenio(consulta.cons_convenio);
    setCons_texto(consulta.cons_texto);
    setCons_tipo(consulta.cons_tipo);
    setExame_texto(consulta.cons_texto_exame);
    setReceita_texto(consulta.cons_texto_receita);
    setCons_status(consulta.cons_status);
    setCons_codigo(consulta.cons_codigo);
    setCons_hora(consulta.cons_data.substring(11, 16));
  };

  const setData = (data) => {
    setPac_nascimento(data);
    setPac_idade(calcularIdade(data));
  };

  const VoltarHistory = () => {
    history.push('/paciente');
  };

  //document.querySelector("#addItemBtn")
  //#addItemBtn
  ///html/body/div[1]/div/div/div[1]/div/div[3]/div/button

  return (
    <>
      <div className="container-fluid">
        <div class="card" style={{ border: '0px solid rgba(0,0,0,.125)' }}>
          <div
            class="card-header"
            style={{ padding: '0px', borderTop: '0px', background: '#f8f9fa' }}
          ></div>
          <div class="card-body">
            <button
              className="btn btn-sm  mb-3 mr-3 btn-secondary "
              type="submit"
              id="btnVoltar"
              onClick={(e) => VoltarHistory()}
            >
              {AngleDoubleLeft} Voltar
            </button>
            <div>
              <b>Paciente:</b> {pac_nome}
            </div>
            <div
              class="alert alert-danger alert-dismissible fade show "
              role="alert"
              style={{ display: 'none' }}
              id="alertPaciente"
            >
              {ExclamationTriangle}
              <strong> Nenhuma consulta encontrada para este paciente!</strong>
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="table-responsive">
              <table className="table table-hover table-striped table-light">
                <thead className="text-white bg-dark">
                  <tr>
                    <th style={{ width: '95%' }} scope="col">
                      Prontuário
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {consultaPag.length > 0 &&
                    consultaPag.map((consulta, i) => {
                      console.log(JSON.stringify(consultaPag));

                      return (
                        <>
                          <tr key={consulta.cons_codigo}>
                            <td>
                              {consulta.cons_status === 3 ? (
                                <>
                                  {' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {' '}
                                    {mask_data(
                                      consulta.cons_data.substring(0, 10),
                                    ) +
                                      ' - ' +
                                      consulta.cons_data.substring(11, 16)}
                                  </span>
                                  <br />
                                  {consulta.cons_texto}
                                  {consulta.cons_texto_receita ? (
                                    <>
                                      {' '}
                                      <p>
                                        {' '}
                                        <b>Prescrição:</b>{' '}
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: consulta.cons_texto_receita,
                                          }}
                                        ></span>
                                      </p>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : (
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  }}
                                >
                                  {mask_data(
                                    consulta.cons_data.substring(0, 10),
                                  ) + ' - Consulta em andamento'}
                                </span>
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Registros por página'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Atendimento;
